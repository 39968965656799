import { gql } from '@apollo/client'

const getExperiments = {
  query: `
    experiments {
      id
      uuid
      name
      durationNumDays
      startTime
      stopTime
      config
      isCmsExperiment
      exclusive
      experimentSetupTemplateId
      variants {
        id
        uuid
        name
      }
      experimentStatus {
        id
        name
      }
      experimentType {
        id
        name
      }
      experimentMetrics {
        id
        observationalDays
      }
      experimentToProductFeatures {
        id
        product {
          id
          displayName
        }
        productFeature {
          id
          displayName
        }
      }
      experimentToUsers {
        id
        roleId
        user {
          id
          displayName
        }
      }
      notifications(limit: 5) {
        id
        subject
        body
        isRead
        notificationTime
        notificationDefinition {
          id
          name
        }
        environment {
          id
          name
          order
          color
        }
      }
      environmentSamplings {
        id
        sampleAllocation
        lastPublishRequestTime
        environmentId
        environment {
          id
          name
          color
          order
        }
        experimentStatus {
          id
          name
        }
        lastModifiedTime
        lastModifiedUser {
          id
          displayName
        }
        environmentSamplingToVariants {
          id
          weight
          variant {
            id
            name
          }
          lastModifiedTime
          lastModifiedUser {
            id
            displayName
          }
        }
        allocationHistory {
          id
          sampleAllocation
          variantConfig
          createdTime
          isReset
          uuid
          lastModifiedUser {
            id
            displayName
          }
        }
      }
      permissions {
        id
        name
      }
    }
    experimentCategories {
      id
      name
      displayName
      displayOrder
      isEnabled
    }`,
  variables: {},
}

export const GET_EXPERIMENTS = gql`
  query getExperiments {
    experiments {
      id
      uuid
      name
      durationNumDays
      startTime
      stopTime
      config
      isCmsExperiment
      variants {
        id
        uuid
        name
      }
      experimentStatus {
        id
        name
      }
      experimentType {
        id
        name
      }
      experimentToProductFeatures {
        id
        product {
          id
          displayName
        }
        productFeature {
          id
          displayName
        }
      }
      experimentToUsers {
        id
        roleId
        user {
          id
          displayName
        }
      }
      notifications(limit: 5) {
        id
        subject
        body
        isRead
        notificationTime
        notificationDefinition {
          id
          name
        }
        environment {
          id
          name
          order
          color
        }
      }
      environmentSamplings {
        id
        sampleAllocation
        lastPublishRequestTime
        environment {
          id
          name
          color
          order
        }
        experimentStatus {
          id
          name
        }
        lastModifiedTime
        lastModifiedUser {
          id
          displayName
        }
        environmentSamplingToVariants {
          id
          weight
          variant {
            id
            name
          }
          lastModifiedTime
          lastModifiedUser {
            id
            displayName
          }
        }
        allocationHistory {
          id
          sampleAllocation
          variantConfig
          createdTime
          isReset
          uuid
          lastModifiedUser {
            id
            displayName
          }
        }
      }
      permissions {
        id
        name
      }
    }
    experimentCategories {
      id
      name
      displayName
      displayOrder
      isEnabled
    }
  }
`

export default getExperiments
