import cloneDeep from 'lodash/cloneDeep';

const removeFilterOption = (filter, filters) => {
  const newFilters = cloneDeep(filters);
  const filterArray = filter.split('__');
  const category = filterArray[0];

  if (filterArray.length === 2) {
    const selectedFilter = filterArray[1];

    newFilters[category] = newFilters[category].filter(
      subFilter => subFilter !== selectedFilter,
    );
  } else {
    const subFilter = filterArray[1];
    const selectedFilter = filterArray[2];
    const newSelectedFilters = newFilters[category]
      .find(subCategory => subCategory.name === subFilter)
      .selectedFilters.filter(newFilter => newFilter !== selectedFilter);

    if (newSelectedFilters.length > 0) {
      newFilters[category] = newFilters[category].map((subCategory) => {
        if (subCategory.name === subFilter) {
          return {
            name: subCategory.name,
            selectedFilters: newSelectedFilters,
          };
        }
        return subCategory;
      });
    } else {
      newFilters[category] = newFilters[category].filter(
        subCategory => subCategory.name !== subFilter,
      );
    }
  }

  return newFilters;
};

export default removeFilterOption;
