import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

const UPDATE_EXPERIMENT_PLAN = gql`
  mutation updateExperimentPlan($input: UpdateExperimentPlan!) {
    updateExperimentPlan(input: $input) {
      id
      multipleVariantReason
    }
  }
`

const updateExperimentPlan = graphql(UPDATE_EXPERIMENT_PLAN, {
  name: 'updateExperimentPlan',
  withRef: true,
})

export default updateExperimentPlan
