const formatDate = datetimeString => {
  const tempDate = new Date(datetimeString)
  // const offset = tempDate.getTimezoneOffset() / 60
  // tempDate.setHours(tempDate.getHours() - offset)
  return tempDate.toLocaleDateString('fr-CA')
}

const formatTime = datetimeString => {
  const tempDate = new Date(datetimeString)
  // let offset = tempDate.getTimezoneOffset() / 60
  // tempDate.setHours(tempDate.getHours() - offset)
  return tempDate.toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
  })
}

const formatDateTime = (date, time) => {
  const datetime = new Date(date + ' ' + time)
  const datetimeArray = datetime.toISOString().split('T')
  const dateString = datetimeArray[0]
  let timeString = datetimeArray[1].split('.')[0]
  return dateString + ' ' + timeString
}

const formatTimeTo12Hour = time => {
  let hours = time.split(':')[0]
  let minutes = time.split(':')[1]
  const tempDate = new Date()
  tempDate.setHours(hours)
  tempDate.setMinutes(minutes)
  return tempDate.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
  })
}

export { formatDate, formatTime, formatDateTime, formatTimeTo12Hour }
