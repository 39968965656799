import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

/*
* Sample Input
*
* {
    overrideTypeId: Int!
    productIds: [Int]!
    name: CustomString!
    overrideIdentifier: CustomString!
    overrideGroupIds: [Int]
  }
*/

export const INSERT_OVERRIDE = gql`
  mutation insertOverride($input: InsertOverrideType!) {
    insertOverride(input: $input) {
      id
      name
      overrideIdentifier
      overrideType {
        id
        name
      }
      products {
        id
        displayName
      }
      overrideGroups {
        id
        name
      }
    }
  }
`

const insertOverride = graphql(INSERT_OVERRIDE, {
  name: 'insertOverride',
})

export default insertOverride
