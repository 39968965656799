import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

export const DELETE_EXPERIMENT = gql`
  mutation deleteExperiment($experimentId: Int!) {
    deleteExperiment(experimentId: $experimentId)
  }
`

const deleteExperiment = graphql(DELETE_EXPERIMENT, {
  name: 'deleteExperiment',
})

export default deleteExperiment
