import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

const DELETE_ROLE_WITH_USERS = gql`
  mutation deleteRoleWithUsers($input: DeleteRoleWithUsersType!) {
    deleteRoleWithUsers(input: $input) {
      id
      users {
        id
      }
      permissions {
        id
      }
    }
  }
`

const deleteRoleWithUsers = graphql(DELETE_ROLE_WITH_USERS, {
  name: 'deleteRoleWithUsers',
})

export default deleteRoleWithUsers
