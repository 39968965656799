const getExperimentEvaluationCards = {
  query: `
    experimentEvaluationCards {
      id
      experiment {
        id
        name
        experimentStatus {
          id
          name
        }
        experimentToProductFeatures {
          product {
            id
            displayName
          }
        }
        finalOutcome
        startTime
        stopTime
      }
      evaluationStatusId
      experimentQualityScore
      lastModifiedTime
      experimentEvaluationAudits {
        id
        changedComment
      }
    }
  `,
  variables: {},
  options: {
    fetchPolicy: 'cache-and-network',
  },
}

export default getExperimentEvaluationCards
