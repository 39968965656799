import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

/*
* Sample Input
*
* input {
  experimentId: Int!
  environmentSamplingId: Int!
  environmentId: Int!
  variantSamplings: [
    variantId: Int!
    weight: Int!
  ]
}
*/

const PUBLISH_UPDATE_VARIANT_TO_ENVIRONMENT = gql`
  mutation publishUpdateVariantToEnvironment(
    $input: InsertVariantToEnvironmentSampling!
  ) {
    publishUpdateVariantToEnvironment(input: $input) {
      id
      startTime
      durationNumDays
      variants {
        id
        name
        displayOrder
      }
      environmentSamplings {
        id
        tdcsUpdateRequired
        isDreSynced
        sampleAllocation
        environment {
          id
          name
          color
        }
        experimentStatus {
          id
          name
        }
        lastModifiedTime
        lastModifiedUser {
          id
          displayName
        }
        environmentSamplingToVariants {
          id
          weight
          variant {
            id
            name
          }
          lastModifiedTime
          lastModifiedUser {
            id
            displayName
          }
        }
        allocationHistory {
          id
          sampleAllocation
          variantConfig
          createdTime
          isReset
          uuid
          lastModifiedUser {
            id
            displayName
          }
        }
      }
    }
  }
`

const publishUpdateVariantToEnvironment = graphql(
  PUBLISH_UPDATE_VARIANT_TO_ENVIRONMENT,
  {
    name: 'publishUpdateVariantToEnvironment',
  }
)

export default publishUpdateVariantToEnvironment
