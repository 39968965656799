import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

const INSERT_ROLE_WITH_USERS = gql`
  mutation insertRoleWithUsers($input: insertRoleWithUsersType!) {
    insertRoleWithUsers(input: $input) {
      id
      users {
        id
      }
      permissions {
        id
      }
    }
  }
`

const insertRoleWithUsers = graphql(INSERT_ROLE_WITH_USERS, {
  name: 'insertRoleWithUsers',
})

export default insertRoleWithUsers
