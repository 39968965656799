import { decorate, observable, computed, transaction } from 'mobx'
import { client } from '../../configuration/configApiClient'
import { gql } from '@apollo/client'
import appModel from '../../components/App/AppModel'

class ORGuidLookupModel {
  fqAccountId = ''
  identityUsername = ''
  lookupAttemptedAt = null
  lookedUpAt = null
  inProgressLookupRequest = null
  results = []

  orEditorModelToLookup(orEditorModel) {
    const splitFQAccountId = ORGuidLookupModel.splitFQAccountId(
      this.fqAccountId
    )
    return {
      overrideTypeId: orEditorModel.type,
      accountGuid:
        orEditorModel.type === 2 &&
        ORGuidLookupModel.isValidGuid(orEditorModel.accountGuid)
          ? orEditorModel.accountGuid
          : null,
      clientGuid:
        orEditorModel.type !== 2 &&
        ORGuidLookupModel.isValidGuid(orEditorModel.clientGuid)
          ? orEditorModel.clientGuid
          : null,
      productId:
        orEditorModel.requiresProduct &&
        Number.isInteger(Number(orEditorModel.productId))
          ? Number(orEditorModel.productId)
          : null,
      overrideName: orEditorModel.name.length ? orEditorModel.name : null,
      billingID: splitFQAccountId ? splitFQAccountId.billingID : null,
      divisionID: splitFQAccountId ? splitFQAccountId.divisionID : null,
      accountNumber: splitFQAccountId ? splitFQAccountId.accountNumber : null,
      identityUsername: this.isValidIdentityUsername
        ? this.identityUsername
        : null,
    }
  }

  static keyForLookup(lookup) {
    const {
      accountGuid,
      clientGuid,
      billingID,
      divisionID,
      accountNumber,
      overrideTypeId,
      overrideName,
    } = lookup
    return `${accountGuid}${clientGuid}${billingID}${divisionID}${accountNumber}${overrideName}${overrideTypeId}`
  }

  static splitFQAccountId(fqAccountId) {
    if (fqAccountId?.indexOf(':') === -1) return null
    const parts = fqAccountId.split(':')
    if (parts[0].indexOf('.') === -1) return null
    const moreParts = parts[0].split('.')

    return {
      divisionID: moreParts[0],
      billingID: moreParts[1],
      accountNumber: parts[1],
    }
  }

  onChange = ({ target: { value, name } }) => {
    this[name] = value
  }

  static fqAccountIdFormat = 'AAA.000:AcctNumber'
  static fqAccountIdPlaceholder = 'e.g. BHN.003:280218909'
  static fqAccountIdTooltip = `Fully Qualified Account Id in ${ORGuidLookupModel.fqAccountIdFormat} format, ${ORGuidLookupModel.fqAccountIdPlaceholder}`
  get isValidFQAccountId() {
    return (
      this.fqAccountId.length > 9 &&
      this.fqAccountId.includes('.') &&
      this.fqAccountId.includes(':')
    )
  }
  get fqAccountIdErrorMessage() {
    return this.isValidFQAccountId
      ? null
      : `Valid format is ${ORGuidLookupModel.fqAccountIdFormat}`
  }

  get lookupFailed() {
    return (
      this.lookupAttemptedAt &&
      !this.inProgressLookupRequest &&
      !this.resultsFound?.length
    )
  }

  static identityUsernameFormat = 'dptest@charter.net'
  static identityUsernamePlaceholder = 'e.g. dptest@charter.net'
  static identityUsernameTooltip = `Please enter a Spectrum identity username, ${ORGuidLookupModel.identityUsernamePlaceholder}`
  get isValidIdentityUsername() {
    return this.identityUsername.length > 3
  }
  get identityUsernameErrorMessage() {
    return this.isValidIdentityUsername
      ? null
      : `Valid format is ${ORGuidLookupModel.identityUsernameFormat}`
  }

  static isValidGuid = guid => {
    return guid?.length === 36 && guid?.indexOf('-') === 8
  }

  static formatFQAccountId = ({ divisionID, billingID, accountNumber }) =>
    `${divisionID}.${billingID}:${accountNumber}`

  get resultsFound() {
    return this.results?.filter(
      ({ lookupSource }) => lookupSource && lookupSource.length > 0
    )
  }

  get resultsNotFound() {
    return this.results?.filter(({ lookupSource }) => !lookupSource)
  }

  get resultsFoundWithoutOverride() {
    return this.results?.filter(
      ({ lookupSource, overrideId }) =>
        lookupSource && lookupSource.length > 0 && !overrideId
    )
  }

  get resultsWithOverride() {
    return this.results?.filter(
      ({ overrideId }) => overrideId !== null && overrideId !== undefined
    )
  }

  get foundEditorModels() {
    if (!this.resultsWithOverride?.length) return null

    const editorModels = []
    this.resultsWithOverride.forEach(({ overrideId }) => {
      const key = `Override:${overrideId}`
      const model = appModel.modelsById.get(key)
      if (model) editorModels.push(model)
    })
    return editorModels
  }

  get firstMetadata() {
    if (!this.resultsFound?.length) return null
    return ORGuidLookupModel.formatMetadata(this.resultsFound[0])
  }

  static formatMetadata(guidMetadata) {
    const lines = []

    const {
      overrideTypeId,
      accountGuid,
      mso,
      accountName,
      accountType,
    } = guidMetadata

    if (overrideTypeId === 2) {
      lines.push(
        ...[
          {
            name: `Account GUID`,
            value: accountGuid,
          },
          {
            name: 'Account ID',
            value: ORGuidLookupModel.formatFQAccountId(guidMetadata),
          },
          { name: 'Account Name', value: accountName },
          { name: 'Account Type', value: accountType },
          { name: 'MSO', value: mso },
        ]
      )
    }
    return lines
  }

  async lookupOverride(orEditorModel) {
    return this.sendQuery(this.orEditorModelToLookup(orEditorModel))
  }

  async lookupBulkOverrides(lookups) {
    this.sendQuery(lookups)
  }

  sendQuery = async lookups => {
    try {
      transaction(() => {
        this.lookupAttemptedAt = new Date()
        this.lookedUpAt = null
        this.inLookup = lookups
        this.results = null
        this.inProgressLookupRequest = client.query({
          query: ORGuidLookupModel.query,
          variables: {
            lookups,
          },
          fetchPolicy: 'network-only',
        })
      })

      const res = await this.inProgressLookupRequest

      transaction(() => {
        if (!res.data.guidLookup) {
          return
        }

        this.lookedUpAt = new Date()

        const {
          data: { guidLookup },
        } = res

        this.results = guidLookup
      })
    } catch (error) {
      console.log(error)
      this.lookupErrorMessage = 'Unable to lookup value'
    } finally {
      this.inLookup = null
      this.inProgressLookupRequest = null
    }
  }

  static csvProductNameToId = {
    'STVA (Android)': 1,
    'STVA (Apple TV)': 16,
    'STVA (Chromecast)': 18,
    'STVA (iOS)': 8,
    'STVA (OVP)': 6,
    'STVA (Roku RSG)': 17,
    'STVA (Roku)': 2,
    'STVA (Samsung TV)': 3,
    'STVA (Xbox TVSDK)': 15,
    'STVA (Xbox)': 7,
    'SSPP (MSA Android)': 11,
    'SSPP (MSA iOS)': 12,
    'SSPP (SpecMobile Android)': 13,
    'SSPP (SpecMobile iOS)': 14,
    'SSPP (Spectrum.net)': 9,
    'SSPP (SMB.net)': 10,
  }

  static lineToLookup([
    overrideType,
    overrideName,
    clientType,
    clientGuid,
    accountGuid,
    fqAccountId,
  ]) {
    const splitFQAccountId = ORGuidLookupModel.splitFQAccountId(fqAccountId)

    return {
      overrideTypeId: ORGuidLookupModel.idForOverrideTypeString(overrideType),
      overrideName,
      accountGuid: accountGuid.length ? accountGuid : null,
      clientGuid,
      productId:
        clientType && ORGuidLookupModel.csvProductNameToId[clientType]
          ? ORGuidLookupModel.csvProductNameToId[clientType]
          : null,
      billingID: splitFQAccountId ? splitFQAccountId.billingID : null,
      divisionID: splitFQAccountId ? splitFQAccountId.divisionID : null,
      accountNumber: splitFQAccountId ? splitFQAccountId.accountNumber : null,
    }
  }

  static idForOverrideTypeString = type =>
    type.toLowerCase() === 'account'
      ? 2
      : type.toLowerCase() === 'device'
      ? 1
      : 3

  static query = gql`
    query guidLookup($lookups: [GuidLookup!]) {
      guidLookup(lookups: $lookups) {
        id
        overrideId
        overrideName
        overrideTypeId
        accountGuid
        clientGuid
        productId
        accountName
        accountType
        accountNumber
        billingID
        divisionID
        lookupSource
        mso
      }
    }
  `
}

export default decorate(ORGuidLookupModel, {
  fqAccountId: observable,
  isValidFQAccountId: computed,
  fqAccountIdErrorMessage: computed,
  identityUsername: observable,
  isValidIdentityUsername: computed,
  identityUsernameErrorMessage: computed,
  results: observable.ref,
  inLookup: observable.ref,
  resultsFound: computed,
  resultsWithOverride: computed,
  resultsFoundWithoutOverride: computed,
  foundEditorModels: computed,
  firstMetadata: computed,
  lookupAttemptedAt: observable.ref,
  lookedUpAt: observable.ref,
  inProgressLookupRequest: observable.ref,
  resultsNotFound: computed,
  lookupFailed: computed,
})
