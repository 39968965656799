import React, { useState, useEffect } from 'react'
import { array, func, bool, string } from 'prop-types'

import { KiteRadioButton } from '@kite/react-kite'
import { FilterSearch } from '@kite/react-kite-plus'

import './RadioSearchContainer.scss'

export default function RadioSearchContainer({
  id: containerId,
  radioBtnOpt,
  onChange,
  name,
  label = '',
  disabledContainer = false,
  searchPlaceholder = '',
  groupLabel = '',
  btnOrientation = 'column',
  width = '35%',
  height = '300px',
  className = '',
  noDataMessage = '',
  errorMessage = '',
}) {
  const [searchData, handleFilterSearchChange] = useState([...radioBtnOpt])

  useEffect(() => {
    handleFilterSearchChange([...radioBtnOpt])
  }, [radioBtnOpt, handleFilterSearchChange])
  const containerClass = errorMessage
    ? '__radio-search-error'
    : '__radio-search'

  return (
    <div id={containerId} key={`${containerId}__radioSearhContainer`} className={`${className} radio-search-container`}>
      <div className="radio-search-container__label">{label}</div>
      <FilterSearch
        initialData={radioBtnOpt}
        filterKeys={['key', 'value', 'label']}
        placeholder={searchPlaceholder}
        onChange={(filteredData, searchValue) =>
          handleFilterSearchChange(filteredData, searchValue)
        }
        showInvalidText={false}
        maxWidth="100%"
      />
      <div
        id={containerId}
        key={`${containerId}__dataContainer`}
        className={`radio-search-container${containerClass}`}
        style={{ height: height, width: width }}
      >
        <div id={containerId} key={`${containerId}__searchData`} className="radio-search-container__radio-container">
          {searchData.map(({ id, label, value, checked, disabled }, i) => (
            <div key={`${containerId}__${id}`}>
              <KiteRadioButton
                id={`${containerId}__${id}`}
                key={`${containerId}__${id}`}
                className="radio-search-container__radio-btns"
                buttonOrientation={btnOrientation}
                name={name}
                checked={checked}
                disabled={disabled || disabledContainer}
                label={label}
                onChange={onChange}
                value={value}
              />
            </div>
          ))}
          {searchData && searchData.length === 0 && (
            <div className="radio-search-container__no-data-message">
              {noDataMessage}
            </div>
          )}
        </div>
      </div>
      <div className="radio-search-container__error-message">
        {errorMessage ? errorMessage : ''}
      </div>
    </div>
  )
}

RadioSearchContainer.propTypes = {
  id: string.isRequired,
  radioBtnOpt: array.isRequired,
  onChange: func.isRequired,
  name: string.isRequired,
  label: string,
  disabledContainer: bool,
  btnOrientation: string,
  width: string,
  height: string,
  className: string,
  groupLabel: string,
}
