const getOverrideTypes = {
  query: `
    overrideTypes {
      id
      name
    }
  `,
  variables: {},
};

export default getOverrideTypes;
