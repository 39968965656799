import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

export const GET_EXPERIMENT = gql`
  query getExperiment($id: Int!) {
    experiment(id: $id) {
      id
      notifications(limit: 5) {
        id
        subject
        body
        isRead
        notificationTime
        notificationDefinition {
          id
          name
        }
        environment {
          id
          name
          order
          color
        }
      }
    }
  }
`

const getExperiment = graphql(GET_EXPERIMENT, {
  name: 'experimentData',
})

export default getExperiment
