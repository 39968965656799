import merge from 'lodash/merge'
import { ruRU } from '@material-ui/core/locale'

import MaterialConfig from 'react-awesome-query-builder/lib/config/material'
const InitialConfig = MaterialConfig

//////////////////////////////////////////////////////////////////////

const fields = {}

//////////////////////////////////////////////////////////////////////

const conjunctions = {
  AND: InitialConfig.conjunctions.AND,
  OR: InitialConfig.conjunctions.OR,
}

const operators = {
  ...InitialConfig.operators,
  between: {
    ...InitialConfig.operators.between,
    textSeparators: ['from', 'to'],
  },
  multiselect_equals: {
    // neccessary to get correct output of json logic needed for distillery rules
    ...InitialConfig.operators.multiselect_equals,
    label: 'includes',
    jsonLogic: (field, op, value, opDef, operatorOptions, fieldDef) => ({
      some: [field, { in: [{ var: '' }, value] }],
    }),
    sqlFormatOp: (
      field,
      op,
      value,
      valueSrc,
      valueType,
      opDef,
      operatorOptions,
      fieldDef
    ) => {
      return `${field} ${opDef.label} ${value}`
    },
  },
  multiselect_not_equals: {
    // neccessary to get correct output of json logic needed for distillery rules
    ...InitialConfig.operators.multiselect_not_equals,
    label: 'does not include',
    sqlFormatOp: (
      field,
      op,
      value,
      valueSrc,
      valueType,
      opDef,
      operatorOptions,
      fieldDef
    ) => {
      return `${field} ${opDef.label} ${value}`
    },
  },
}

const widgets = {
  ...InitialConfig.widgets,
  slider: {
    ...InitialConfig.widgets.slider,
    customProps: {
      width: '300px',
    },
  },
  rangeslider: {
    ...InitialConfig.widgets.rangeslider,
    customProps: {
      width: '300px',
    },
  },
  date: {
    ...InitialConfig.widgets.date,
    dateFormat: 'DD.MM.YYYY',
    valueFormat: 'YYYY-MM-DD',
  },
  time: {
    ...InitialConfig.widgets.time,
    timeFormat: 'HH:mm',
    valueFormat: 'HH:mm:ss',
  },
  datetime: {
    ...InitialConfig.widgets.datetime,
    timeFormat: 'HH:mm',
    dateFormat: 'DD.MM.YYYY',
    valueFormat: 'YYYY-MM-DD HH:mm:ss',
  },
  multiselect: {
    ...InitialConfig.widgets.text,
    sqlFormatValue: (val, fieldDef, wgtDef, op, opDef) => {
      return `${val}`
    },
  },
  treeselect: {
    ...InitialConfig.widgets.treeselect,
  },
}

const types = {
  ...InitialConfig.types,
  // examples of  overriding
  boolean: merge(InitialConfig.types.boolean, {
    widgets: {
      boolean: {
        widgetProps: {
          hideOperator: true,
          operatorInlineLabel: 'is',
        },
      },
    },
  }),
}

const localeSettings = {
  locale: {
    moment: 'ru',
    material: ruRU,
  },
  valueLabel: 'Value',
  valuePlaceholder: 'Value',
  fieldLabel: 'Field',
  operatorLabel: 'Operator',
  fieldPlaceholder: 'Select field',
  operatorPlaceholder: 'Select operator',
  deleteLabel: null,
  addGroupLabel: 'Add group',
  addRuleLabel: 'Add rule',
  delGroupLabel: null,
  notLabel: 'Not',
  valueSourcesPopupTitle: 'Select value source',
  removeRuleConfirmOptions: {
    title: 'Are you sure delete this rule?',
    okText: 'Yes',
    okType: 'danger',
  },
  removeGroupConfirmOptions: {
    title: 'Are you sure delete this group?',
    okText: 'Yes',
    okType: 'danger',
  },
}

const settings = {
  ...InitialConfig.settings,
  ...localeSettings,

  valueSourcesInfo: {
    value: {
      label: 'Value',
    },
    field: {
      label: 'Field',
      widget: 'field',
    },
    func: {
      label: 'Function',
      widget: 'func',
    },
  },
  maxNesting: 3,
  canLeaveEmptyGroup: true,
}

const funcs = {}

const config = {
  conjunctions,
  operators,
  widgets,
  types,
  settings,
  fields,
  funcs,
}

export default config
