import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

export const GET_ALL_CONTENT_AREAS = gql`
  query contentAreas {
    contentAreas {
      contentAreaId
      contentIds
    }
  }
`

const getContentAreas = graphql(GET_ALL_CONTENT_AREAS, {
  name: 'contentAreaData',
  withRef: true,
  options: {
    fetchPolicy: 'network-only',
  },
})

export default getContentAreas
