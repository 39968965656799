import { gql } from '@apollo/client'

/*
* Sample Input
* 1
*/
const PUBLISH_CONTENT_MAP = gql`
  mutation publishContentMap($id: Int!, $statusId: Int) {
    PublishContentMapToDre(id: $id, statusId: $statusId)
  }
`

export default PUBLISH_CONTENT_MAP
