const GET_EXPERIMENTS_VARIANTS_GQLBUILDER = {
  query: `
    experiment(id: $id) {
      id
      name
      uuid
      experimentTypeId
      startTime
      stopTime
      durationNumDays
      name
      summary
      hypothesis
      winnerVariantId
      finalOutcome
      experimentStatusId
      experimentMetrics {
        id
        createdUser {
          displayName
        }
        metricTypeId
        metricUuid
        observationalDays
      }
      variants {
        id
        uuid
        name
        displayOrder
        description
        displayOrder
        variantUploads {
          id
          fileUrl
        }
      }
      experimentToProductFeatures {
        product {
          id
          uuid
          isSspp,
          quantumApplicationName
          quantumApplicationType
          displayName
        }
        productFeature {
          id
          displayName
        }
      }
      environmentSamplings {
        id
        lastModifiedTime
        lastModifiedUser {
          id
          firstName
          lastName
        }
        environment {
          id
          name
        }
        experimentStatus {
          id
          name
        }
      }
    }
    retrieveQuantumToken(quantumEnum: { overrideTypeId: QUANTUM_METRIC })
  `,
  variables: {
    $id: 'Int!',
  },
}

export default GET_EXPERIMENTS_VARIANTS_GQLBUILDER
