import React, { useState, useEffect } from 'react'
import { KiteTabs, KiteCard } from '@kite/react-kite'

import ManageNetworkLockdown from './tabs/ManageNetworkLockdown/ManageNetworkLockdown'
import PastNetworkLockdowns from './tabs/PastNetworkLockdowns/PastNetworkLockdowns'

const tabs = ['Initiate Lockdown', 'Past Lockdowns (Coming Soon)']

export function NetworkLockdownAdmin({
  location: { pathname: path },
  location,
  onNavigate,
  history,
  user,
}) {
  const [currentTab] = useState('Initiate Lockdown')

  useEffect(() => {
    onNavigate(
      {
        path: '/network-lockdown-administration',
        title: 'Network Lockdown Administration',
      },
      true
    )
  }, [onNavigate])

  // will be updated once the history is enabled
  const handleSelectTab = () => {
    // setCurrentTab(tab)
  }

  return (
    <div className="network-lockdown-admin-page">
      <KiteCard>
        <KiteTabs
          className="network-lockdown-admin-page__tabs"
          currentTab={currentTab}
          tabs={tabs}
          onSelectTab={() => handleSelectTab()}
        />
        {currentTab === 'Initiate Lockdown' && (
          <ManageNetworkLockdown
            path={path}
            location={location}
            onNavigate={onNavigate}
            history={history}
            currentUser={user}
          />
        )}
        {currentTab === 'Past Lockdowns' && <PastNetworkLockdowns />}
      </KiteCard>
    </div>
  )
}

export default NetworkLockdownAdmin
