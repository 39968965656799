import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

const GET_SUBSCRIPTIONS_DATA = gql`
  query getSubscriptionsData {
    subscriptions {
      id
      endpoint
      subjectArn
      subscriptionTopicId
      subscriptionTopic {
        id
        environmentId
        subscriptionTypeId
        topicArn
      }
    }
    subscriptionTopics {
      id
      topicArn
      environment {
        id
        name
      }
      subscriptionType {
        id
        name
      }
    }
  }
`

const getSubscriptionsData = graphql(GET_SUBSCRIPTIONS_DATA, {
  name: 'subscriptionsData',
})

export default getSubscriptionsData
