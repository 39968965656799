export default {
  statusLabel: 'Status',
  startLabel: 'Experiment start',
  plannedEndLabel: 'Planned end',
  actualEndLabel: 'Actual end',
  dateSelectLabel: 'Select results end date',
  orderSelectLabel: 'Order to display results',
  metricNotSignificant: 'not significant',
  infoNoSig:
    'p-Values and indication of significance of metrics are not available until the experiment is Completed or Cancelled',
  errorQuantumQuery:
    'There was an error querying Quantum for metric results. If this continues, ',
  errorVariantsSame:
    'Please pick 2 different variants to compare to get experiment results.',
  errorQuantumApi:
    'There was an error querying Distillery for experiment data. If this continues, ',
  errorZeroDays:
    'An experiment has to be running for more than one day in order to have results.',
  errorQuantumNoResultsYet:
    'Results are not updated in Distillery for the current date. Please select a past date to view results.',
  errorExperimentNotStarted:
    'This experiment has no results in Quantum yet because it has not yet started running in production.',
  adHocEvaluation:
    'Ad hoc evaluation was selected for this experiment; please work with your Experimentation Data Science contact for results.',
  observationalInfo:
    (date) => `This experiment is within the Observation Window for its longest-term survival metric, which will complete on ${date}. Some metrics may be available already.`
}
