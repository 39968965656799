import { gql } from '@apollo/client'

/*
 * Sample Input
 *
 *
 * variables: {
          denverDates: ["2019-09-29"],
          experimentId: "80336f2352f74b6db141600efb8b7f91",
          subdimensions: [null],
          applicationCoords: [appCoords],
        },
 */
const GET_QUANTUM_METRIC_COUNT = gql`
  query getMetricCounts(
    $experimentId: String!
    $denverDates: [String!]
    $subdimensions: [String]
    $applicationCoords: [String!]
  ) {
    counts(
      experimentId: $experimentId
      denverDates: $denverDates
      subdimensions: $subdimensions
      applicationCoords: $applicationCoords
    ) {
      cumulativeUniqueAccounts
    }
    metricDefinitions {
      _id
      name
      description
      calculation
      displayName
    }
  }
`

export default GET_QUANTUM_METRIC_COUNT
