import React, { Fragment, useState, useRef, useEffect } from 'react'
import { array, number, object } from 'prop-types'
import dayjs from 'dayjs'
import AdvancedFormat from 'dayjs/plugin/advancedFormat'
import { ProgressBar } from '@kite/react-kite-plus'

import './AllocationHistory.scss'
import { KiteIcon } from '@kite/react-kite'

dayjs.extend(AdvancedFormat)

const formatHistory = allocation =>
  allocation.map(
    ({
      id,
      createdTime,
      sampleAllocation,
      variantConfig,
      comment,
      isReset,
      uuid,
      lastModifiedUser: { displayName },
    }) => {
      const variants = JSON.parse(variantConfig)

      const parsedTimestamp = dayjs(createdTime)
      const adjustedSampleAllocation = Math.floor(sampleAllocation * 100)
      const totalWeight = variants.reduce(
        (accumulator, variant) => accumulator + variant.weight,
        0
      )

      const formatObj = {
        id,
        fullName: displayName || 'Unknown User',
        dateLabel: parsedTimestamp.isSame(dayjs(), 'day')
          ? 'Today'
          : dayjs(createdTime).format('M/DD/YY'),
        sampleAllocationLabel: `${adjustedSampleAllocation}%`,
        sampleAllocation: adjustedSampleAllocation,
        timeLabel: parsedTimestamp.format('h:mm A'),
        isReset,
        comment,
        uuid,
        variantValues: variants.map(({ weight }, idx) => {
          const percentageOfTotal =
            totalWeight === 0
              ? weight
              : Math.round(
                  (weight / totalWeight) * adjustedSampleAllocation * 100
                ) / 100

          return `${idx === 0 ? 'C' : `V${idx}`}: ${percentageOfTotal}%`
        }),
      }
      return formatObj
    }
  )

const AllocationHistory = ({
  allocationHistory,
  limit,
  selectedEnvironment,
}) => {
  const { name: environmentName, color: environmentColor } = selectedEnvironment

  const [showCommentObj, setShowComment] = useState({})
  const [isVisible, setIsVisible] = useState(false)

  const updateShowCommentObj = (id, bool) => {
    const updateCommentObj = {
      ...showCommentObj,
      [id]: bool,
    }

    // Verify if another tool tip is open, if so close it
    Object.keys(showCommentObj).forEach(key => {
      if (key !== id && showCommentObj[key]) {
        updateCommentObj[key] = false
      }
    })

    if (bool) {
      setIsVisible(true)
    } else if (!Object.values(updateCommentObj).includes(true)) {
      setIsVisible(false)
    }

    setShowComment(updateCommentObj)
  }

  // An event listener for the allocation tooltip to close if clicked outside
  const useOutsideClick = () => {
    // Handles the outside click of container to close
    const handleClickOutside = event => {
      if (isVisible) {
        let isHittingDiv = false

        Object.keys(showCommentObj).forEach(key => {
          if (
            showCommentObj[key] &&
            (event.target.id === `comment-${key}` ||
              event.target.localName === 'svg' ||
              event.target.localName === 'use')
          ) {
            isHittingDiv = true
          }
        })

        if (!isHittingDiv) {
          updateShowCommentObj()
        }
      }
    }

    useEffect(() => {
      // Bind the event listener
      document.addEventListener('click', handleClickOutside, false)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('click', handleClickOutside, false)
      }
    })
  }

  const wrapperRef = useRef(null)
  useOutsideClick(wrapperRef)

  let formattedAllocationHistory = formatHistory(allocationHistory)

  if (limit) {
    formattedAllocationHistory = formattedAllocationHistory.slice(0, limit)
  }

  if (!formattedAllocationHistory || !formattedAllocationHistory.length) {
    return (
      <div className="allocation-history__empty">
        {`You do not have any experiment history${
          environmentName ? ` for ${environmentName}` : ''
        }`}
      </div>
    )
  }

  return (
    <div className="allocation-history">
      {formattedAllocationHistory.map(
        ({
          id,
          fullName,
          sampleAllocation,
          dateLabel,
          isReset,
          comment,
          timeLabel,
          variantValues,
          sampleAllocationLabel,
        }) => {
          if (isReset) {
            return (
              <div className="allocation-history__reset" key={id}>
                <div className="allocation-history__reset-row">
                  <KiteIcon
                    name="caution-alert-f"
                    size="16px"
                    color="#eb4b47"
                  />
                  Experiment Reset
                </div>
              </div>
            )
          }

          let allocationDateClassName = 'allocation-history__date'
          if (dateLabel === 'Today') {
            allocationDateClassName = 'allocation-history__date-today'
          }

          return (
            <div className="allocation-history__item" key={id}>
              <div className="allocation-history__item-row">
                <span
                  className="allocation-history__dot"
                  style={{
                    backgroundColor: environmentColor,
                  }}
                />
                <span className="allocation-history__full-name">
                  {fullName}
                </span>
                <div className="allocation-history__progress">
                  <ProgressBar
                    percentage={Number(sampleAllocation)}
                    showLabel={false}
                    percentageColor="#20c33a"
                  />
                </div>
                <span className={allocationDateClassName}>{dateLabel}</span>
              </div>
              <div className="allocation-history__item-row">
                <div className="allocation-history__variants">
                  {variantValues.map(variant => (
                    <Fragment key={variant}>
                      <span>{variant}</span>
                    </Fragment>
                  ))}
                </div>
                <span className="allocation-history__weight-label">
                  <span>{sampleAllocationLabel}</span>
                  <span>
                    {comment ? (
                      <span
                        className="allocation-history__comment-container"
                        ref={wrapperRef}
                      >
                        {showCommentObj[id] ? (
                          <div
                            id={`comment-${id}`}
                            className="allocation-history__comment-tooltip"
                          >
                            {comment}
                          </div>
                        ) : (
                          ''
                        )}
                        <KiteIcon
                          name="chat"
                          size="1rem"
                          color="#0073D1"
                          margin="0"
                          rotate="0"
                          transitionTime="0"
                          onClick={() =>
                            updateShowCommentObj(id, !showCommentObj[id])
                          }
                        />
                      </span>
                    ) : (
                      ''
                    )}
                    <span className="allocation-history__time-label">
                      {timeLabel}
                    </span>
                  </span>
                </span>
              </div>
            </div>
          )
        }
      )}
    </div>
  )
}

export default AllocationHistory

AllocationHistory.propTypes = {
  allocationHistory: array.isRequired,
  selectedEnvironment: object.isRequired,
  limit: number,
}

AllocationHistory.defaultProps = {
  limit: null,
}
