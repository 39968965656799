import { observer } from 'mobx-react'
import React, { useEffect, Fragment } from 'react'
import {
  KiteButton,
  KiteInput,
  KiteAlert,
  KiteTabs,
  KiteCheckbox,
  KiteIcon,
  KiteLoader,
} from '@kite/react-kite'
import { SortableTable, BasicPills } from '@kite/react-kite-plus'
import classnames from 'classnames'

import ORMainModel from './ORMainModel'
import './ORMain.scss'
import OREditor, { ORDetails } from './OREditor'
import ORGroupEditor from './ORGroupEditor'
import PageLinks from '../../componentLibrary/PageLinks/PageLinks'
import appModel from '../../components/App/AppModel'
import ORAddToGroups from './ORAddToGroups'

const ProductFilters = observer(function ProductFilterCol({
  model,
  label,
  products,
  flex = 1,
}) {
  return (
    <div className="orm-filter__products-col" style={{ flex }}>
      <div className="orm-filter__products-header">{label}</div>
      <div className="orm-filter__products-col-wrapper">
        {products.map(({ id, displayName }) => (
          <KiteCheckbox
            key={id}
            label={displayName}
            className={`orm-filter__product-${id}`}
            checked={model.isProductFilterChecked(id)}
            onChange={({ target: { checked } }) =>
              model.toggleProductFilter(id, checked)
            }
          ></KiteCheckbox>
        ))}
      </div>
    </div>
  )
})

const ORMainFilter = observer(function ORMainFilter({ model }) {
  const { filters } = model

  return (
    <div className="orm-filter__container">
      <KiteButton
        size="small"
        type="outline"
        leftIcon="filter-f"
        onClick={() => (model.isFilterModalOpen = !model.isFilterModalOpen)}
      >
        Filter
      </KiteButton>
      {model.isFilterModalOpen && (
        /* grid (2, rows) */

        <div className="orm-filter">
          {/* row */}
          <KiteCheckbox
            label="Account Overrides"
            className="orm-filter__account"
            checked={filters.accountFilter}
            onChange={({ target: { checked } }) =>
              (filters.accountFilter = checked)
            }
          ></KiteCheckbox>
          {/* col close button*/}
          <KiteIcon
            name="x"
            className="orm-filter__close"
            onClick={() => (model.isFilterModalOpen = false)}
          />

          {/* row */}
          <KiteCheckbox
            label="Device Overrides"
            checked={filters.deviceFilter}
            className="orm-filter__device"
            onChange={({ target: { checked } }) =>
              (filters.deviceFilter = checked)
            }
          ></KiteCheckbox>
          <div></div>

          {/* row */}
          <KiteCheckbox
            label="Account-Device Overrides"
            className="orm-filter__account-device"
            checked={filters.accountDeviceFilter}
            onChange={({ target: { checked } }) =>
              (filters.accountDeviceFilter = checked)
            }
          ></KiteCheckbox>
          <div></div>

          {/* row - product filters (layout is flexbox) */}
          <div className="orm-filter__products">
            {appModel.ssppProducts && (
              <ProductFilters
                model={model}
                label="SSPP"
                products={appModel.ssppProducts}
              />
            )}
            {appModel.videoProducts && (
              <ProductFilters
                model={model}
                label="Video"
                flex="1.5"
                products={appModel.videoProducts}
              />
            )}
          </div>
          <div></div>

          {/* row - clear all button */}
          <div className="orm-filter__footer">
            <KiteButton
              type="standalone-link"
              className="orm-filter__clear"
              onClick={model.clearAllFilters}
            >
              Clear All
            </KiteButton>
            <KiteButton
              type="primary"
              size="medium"
              onClick={() => (model.isFilterModalOpen = false)}
            >
              Apply
            </KiteButton>
          </div>
        </div>
      )}
    </div>
  )
})

const QuickViewOverride = observer(function QuickViewOverride({
  editorModel,
  model,
}) {
  return (
    /* grid - (5, 1) 3 sections plus 2 dividers */
    <div className="orm-list__quick-view">
      {/* col details */}
      <ORDetails editorModel={editorModel} />

      {/* col divider */}
      <div className="orm-list__quick-view-divider"></div>

      {/* col - experiments overrides are applied in */}
      <div className="orm-list__quick-view-exps disable-scrollbars">
        <div style={{ marginBottom: 10 }}>
          Currently applied in experiments:
        </div>
        {editorModel.inProgressCheckAppliedInExperiments ? (
          <KiteLoader />
        ) : editorModel.appliedInExperiments.length ? (
          editorModel.appliedInExperiments.map(({ id, name, url }) => (
            <a key={id} className="exp-link" href={url}>
              <div>{name}</div>
              <KiteIcon size="16px" name="arrow-up-right" />
            </a>
          ))
        ) : editorModel.hasCheckedAppliedInExperiments ? (
          <div>None yet</div>
        ) : (
          <div></div>
        )}
      </div>

      {/* col divider */}
      <div className="orm-list__quick-view-divider"></div>

      {/* col - add to groups, layout flex col */}
      <div className="orm-list__quick-view-section orm-list__quick-view-groups">
        {model.isQuickAddSuccess ? (
          <KiteAlert
            type="confirm"
            level="inline"
            className="orm-list__quick-view-groups-success"
            description="Saved!"
          />
        ) : null}
        <ORAddToGroups
          orEditorModel={editorModel}
          orGroupsListModel={model.orGroupListModel}
          showTooltip={false}
          saveAfterSelect={true}
          label="Add To Groups:"
          className="orm-list__quick-view-groups-input"
          onSaveSuccess={() => {
            model.isQuickAddSuccess = true
            setTimeout(() => {
              model.isQuickAddSuccess = false
            }, 2000)
          }}
        />
      </div>
    </div>
  )
})

const ORTable = observer(function ORTable({ model }) {
  const { columns, currentTab, tabs, listModel } = model
  const { data, isLoading } = listModel

  return (
    /* grid (1,rows) - list of overrides */
    <div className="orm-list">
      {/* row - search label */}
      <div>Search for overrides</div>

      {/* grid (cols,1) - search input */}
      <div className="orm-list__search-grid">
        {/* col - search input */}
        <KiteInput
          name="searchInput"
          id="searchInput"
          value={model.searchText}
          onChange={({ target: { value } }) => {
            model.searchText = value
          }}
          placeholder="Search by name, ID, or group"
          size="large"
        />

        {/* col - spacer */}
        <div></div>

        {/* col - filter (button plus modal) */}
        <ORMainFilter model={model} />

        {/* col - spacer */}
        <div></div>

        {/* col - add group button */}
        <KiteButton
          className="orm-list__add-group"
          size="medium"
          leftIcon="plus"
          type="outline"
          onClick={() => model.addNewInTab('Groups')}
        >
          Add Group
        </KiteButton>

        {/* col - spacer */}
        <div></div>

        {/* col - add override button */}
        <KiteButton
          className="orm-list__add-override"
          size="medium"
          leftIcon="plus"
          onClick={() => model.addNewInTab('Overrides')}
        >
          Add Override
        </KiteButton>
      </div>

      {/* row -spacer */}
      {model.filterPills && (
        <Fragment>
          {/* row - filter pills */}
          <BasicPills
            values={model.filterPills}
            onRemovePill={pillName => model.removePill(pillName)}
            clearAllLabel="Clear All"
            onClearAll={() => model.clearAllFilters()}
          />
          {/* row - spacer */}
          <div style={{ height: 20 }}></div>
        </Fragment>
      )}

      {/* row - spacer */}
      <div></div>

      {/* grid (cols, 1) - table header of tabs, pagination */}
      <div className="orm-list__table-header">
        {/* col - tabs to toggle Overrides/Groups  */}
        <KiteTabs
          className="orm-list__tabs"
          currentTab={currentTab}
          tabs={tabs}
          onSelectTab={model.selectTab}
        ></KiteTabs>
        {/* col - PageLinks component  */}
        {listModel.pageModel ? (
          <PageLinks model={listModel.pageModel} />
        ) : (
          <div></div>
        )}
      </div>

      {/* row - table of overrides or groups, depending on selected tab */}
      <SortableTable
        className={classnames({
          'orm-list__table': true,
          'orm-list__table-overrides': model.selectedTab === 'Overrides',
          'orm-list__table-groups': model.selectedTab === 'Groups',
        })}
        tableData={data}
        columns={columns}
        initialSortHeader="name"
        noDataMessage={'No Overrides'}
        loading={isLoading}
        onRowClick={row => {
          row.loadQuickView && row.loadQuickView()
        }}
        expansionRender={
          model.selectedTab === 'Overrides'
            ? row => <QuickViewOverride editorModel={row} model={model} />
            : null
        }
      />
    </div>
  )
})

export const model = new ORMainModel()
export default observer(function ORMain(props) {
  const { listModel } = model

  useEffect(() => {
    if (!model.orListModel.querySubmittedAt) model.start()
    return function cleanup() {
      model.reset()
    }
  }, [])

  const { isEditing, kiteAlert } = listModel

  return (
    <div className="orm">
      {/* row 1 - exit editing or empty */}
      {isEditing ? (
        <KiteButton
          className="orm__exit-back orm__left orm__bigger-icon"
          type="standalone-link"
          leftIcon="arrow-left"
          onClick={() => listModel.exitEditing()}
        >
          Back To Override Management
        </KiteButton>
      ) : (
        <div></div>
      )}

      {/* row 2 - banner error or empty */}
      {kiteAlert ? (
        <KiteAlert
          className="app__page-level-message"
          type={kiteAlert.type || 'info'}
          description={kiteAlert.description}
          level={kiteAlert.level || 'page'}
          linkText={kiteAlert.linkText}
        />
      ) : (
        <div></div>
      )}

      {/* row 3 - editor or list */}
      {isEditing ? (
        model.selectedTab === 'Overrides' ? (
          <OREditor
            orMainModel={model}
            editorModel={listModel.editorModel}
            orGroupsListModel={model.orGroupListModel}
          />
        ) : (
          <ORGroupEditor
            editorModel={listModel.editorModel}
            orListModel={model.orListModel}
          />
        )
      ) : (
        <ORTable model={model}></ORTable>
      )}
    </div>
  )
})
