export default {
  autoSuggestSearchLabel: 'Add override',
  autoSuggestSearchPlaceholder: 'Search by name or device ID',
  overrideManagementButton: 'Override Management',
  noOverrides:
    'There are no overrides on this experiment. Add one in the search bar above.',
  noOverridesToGroup1: 'No overrides associated with this group.',
  noOverridesToGroup2: 'Please check override management or remove this group.',
  errorAutoSuggestSearch: searchValue =>
    `No overrides or groups match "${searchValue}"`,
  groupViewDetails: 'View Details',
  groupHideDetails: 'Hide Details',
  submitButton: 'Save',
  submitAndPublishButton: 'Save & Publish',
  resetButton: 'Reset',
  successSubmitTitle: 'Overrides Updated',
  successSubmitDescription: name => `${name} successfully updated`,
  errorSubmitTitle: 'Unable to update your overrides',
  errorSubmitDescription:
    'Please try again in a few minutes. If this continues, ',
  errorSubmitLink: 'contact support.',
};
