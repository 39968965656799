const getEvaluationCategories = {
  query: `
    evaluationCategory {
      id
      displayName
      name
      displayOrder
    }

    evaluationSubCategory {
      id
      evaluationCategoryId
      name
      displayName
      displayOrder
    }
  `,
  variables: {},
};

export default getEvaluationCategories;
