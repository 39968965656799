import React, { useEffect, useState, Fragment, useCallback } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { isEmpty } from 'lodash'
import {
  KiteLoader,
  KiteLink,
  KiteCard,
  KiteInput,
  KiteIcon,
  KiteButton,
  KiteSelect,
  KiteCheckbox,
} from '@kite/react-kite'
import { AutoSuggest, BasicPills } from '@kite/react-kite-plus'
import { experimentStatusEnum } from '@charter/distillery-rules'
import { RadioSearchContainer } from '../../../../componentLibrary'
import { GET_CREATE_DATA } from '../../queries/getCreateData'

import {
  INSERT_CONTENT_MAP,
  UPDATE_CONTENT_MAP,
  INSERT_CONTENT_MAP_TO_USER,
  DELETE_CONTENT_MAP_TO_USER,
  PUBLISH_CONTENT_MAP,
} from '../../mutations'
import { scrollToNavigationPosition } from '../../../../shared/utilities'

import './Create.scss'

import copyContent from '../../data/copyContent'

const {
  createPage: {
    infoTitle,
    nameLabel,
    nameInput,
    mapContentLabel,
    nameError,
    nameDuplicateError,
    commentPlaceholder,
    commentLabel,
    saveBtn,
    personalizedContentBtn,
    audienceMangamentLabel,
    // experimentPreviewLabel,
    defaultLabel,
    defaultAudienceLabel,
    audienceErrorMessage,
    contentErrorMessage,
    productError,
    featureError,
    contentAreaIdError,
    noApproverError,
    approverHeader,
    approverTooltip,
    approverPlaceholder,
    jiraPlaceholder,
    jiraHeader,
  },
} = copyContent

const initError = {
  name: '',
  personalizedContent: '',
}

export function Create({
  id,
  disabled,
  isDirty,
  onChange,
  history,
  config,
  contentMap,
  setSuccess,
  handleSubmit,
  setErrorMessage,
  setModalOpen,
  permissionEnum,
  setIsCancelContentModalOpen,
  hasPermission,
  isCompleteMap,
}) {
  const [name, setName] = useState('')
  const [contentAreaId, setContentAreaId] = useState('')
  const [product, setProduct] = useState('')
  const [feature, setFeature] = useState(null)
  const [jiraTicket, setJiraTicket] = useState('')
  const [approverRole, setRoles] = useState([])
  const [userInput, setUserInput] = useState('')
  const [comment, setComment] = useState('')
  const [relatedContent, setRelatedContent] = useState([])
  const [personalizedContent, setPersonalizedContent] = useState([
    {
      defaultContent: false,
      contentId: '',
      audienceId: '',
      priority: 0,
      contentJson: null,
      isDirty: true,
    },
    {
      defaultContent: true,
      contentId: '',
      audienceId: '',
      priority: 1,
      contentJson: null,
      isDirty: true,
    },
  ])
  const [errorMessage, setError] = useState(initError)

  const { loading, data, refetch } = useQuery(GET_CREATE_DATA, {
    variables: {
      id: 2,
      permissionEnum,
    },
  })

  const [
    insertContentMapToUser,
    { loading: loadingInsertContentMap, error: insertContentMapUsersError },
  ] = useMutation(INSERT_CONTENT_MAP_TO_USER)
  const [
    deleteContentMapToUser,
    { loading: loadingDeleteContentMapUser, error: deleteContentMapUserError },
  ] = useMutation(DELETE_CONTENT_MAP_TO_USER)
  const [insertContentMap, { loading: insertLoading }] = useMutation(
    INSERT_CONTENT_MAP,
    {
      onCompleted: async data => {
        try {
          await updateUsers(data.insertContentMap.id)
          await publishContentMap({
            variables: {
              id: data.insertContentMap.id,
            },
          })
          handleSubmit(data.insertContentMap, 'Review')
        } catch (err) {
          setErrorMessage(oldErrs => ({
            ...oldErrs,
            submit: err.message,
          }))
        }
      },
    }
  )
  const [
    updateContentMap,
    { loading: loadingUpdateContentMap, error: updateContentMapError },
  ] = useMutation(UPDATE_CONTENT_MAP, {
    onCompleted: async data => {
      try {
        await updateUsers(data.updateContentMap.id)
        await publishContentMap({
          variables: {
            id: data.updateContentMap.id,
          },
        })
        handleSubmit(data.updateContentMap)
      } catch (err) {
        setErrorMessage(oldErrs => ({
          ...oldErrs,
          submit: err.message,
        }))
      }
    },
  })
  const [
    publishContentMap,
    { loading: loadingPublish, error: errorPublishing },
  ] = useMutation(PUBLISH_CONTENT_MAP, {
    onComplete: data => {
      if (errorPublishing) {
        setErrorMessage(oldErrs => ({
          ...oldErrs,
          submit: errorPublishing,
        }))
      }
    },
  })

  const formatContent = useCallback((content, contentSelected) => {
    return content.map(({ contentTitle, contentId }) => {
      const checked = contentId === contentSelected
      return {
        id: `${contentTitle} - ${contentId}`,
        label: `${contentTitle} - ${contentId}`,
        value: contentId,
        checked: checked,
        disabled: false,
      }
    })
  }, [])

  const formatAudience = useCallback((audience, audienceId, isDefault) => {
    if (isDefault) {
      return [{
        id: 'default',
        label: `${defaultAudienceLabel}`,
        value: null,
        checked: true,
        disabled: false,
      }]
    }
    return audience.map((audience) => {
      const checked = Number(audienceId) === audience.id
      // Checks for audience selected in different audiences
      const disabled = personalizedContent.find(({ audienceId: audId }) => (Number(audId) === Number(audience.id) && (audienceId !== Number(audience.id))))
      return ({ 
        id: `${audience.id} - ${audience.name}`,
        label: `${audience.name}`,
        value: audience.id,
        checked: checked,
        disabled: !!disabled && !checked,
      })
    })
  }, [personalizedContent])

  // Lifecycle methods
  useEffect(() => {
    const hasError =
      deleteContentMapUserError ||
      insertContentMapUsersError ||
      updateContentMapError

    if (contentMap && !hasError) {
      const {
        name: contentName,
        productId,
        featureId,
        contentAreaId: contAreaId,
        comment: comm,
        personalizedContent: persContent,
        jiraTicket: contentMapTicket,
        contentMapToUser,
      } = contentMap

      setName(contentName)
      setProduct(productId)
      setFeature(featureId)
      setContentAreaId(contAreaId)
      setComment(comm)
      setJiraTicket(contentMapTicket)
      // Grab all the related content
      const matchingContent = []
      const contentArea =
        data &&
        data.contentAreas.find(
          ({ contentAreaId: areaId }) => areaId === contAreaId
        )
      if (contentArea && contentArea.contentIds) {
        const { contentIds } = contentArea
        for (let contentId of contentIds) {
          const match = data.contents.find(
            content => content.contentId === contentId
          )

          if (match) {
            matchingContent.push(match)
          }
        }
        const contentUsers = contentMapToUser
          ? contentMapToUser.map(({ user }) => user)
          : []

        setRoles(contentUsers)
  
        setRelatedContent(matchingContent)
      }

      const formatPersonalizedContent = persContent.map(
        ({ id: personalizedId, audienceId, contentId, priority, contentJson }) => ({
          id: personalizedId,
          defaultContent: audienceId === null,
          contentId,
          audienceId: audienceId || '',
          priority,
          contentJson,
          isDirty: false,
        })
      )

      setPersonalizedContent(formatPersonalizedContent)
    }

    if (hasError) {
      setErrorMessage(oldErrs => ({
        ...oldErrs,
        submit:
          deleteContentMapUserError ||
          insertContentMapUsersError ||
          updateContentMapError,
      }))
    }
  }, [
    contentMap,
    config,
    data,
    updateContentMapError,
    setErrorMessage,
    insertContentMapUsersError,
    deleteContentMapUserError,    
  ])

  const handleChange = ({ target: { name, value, id } }) => {
    setError(initError)
    if (name === 'name') {
      setName(value)
    } else if (name === 'jiraTicket') {
      setJiraTicket(value)
    } else if (name === 'contentAreaId') {
      setContentAreaId(value)

      // Grab all the related content
      const matchingContent = []
      const { contentIds } = data.contentAreas.find(
        ({ contentAreaId: areaId }) => areaId === value
      )

      for (let contentId of contentIds) {
        const match = data.contents.find(
          content => content.contentId === contentId
        )

        if (match) {
          matchingContent.push(match)
        }
      }
    
      setPersonalizedContent(oldPersonalizedContent => {
        return oldPersonalizedContent.map(content => ({
          ...content,
          contentId: '',
          contentJson: null,
          isDirty: true,
        }))
      })

      setRelatedContent(matchingContent)
    } else if (name === 'product') {
      setProduct(value)
    } else if (name === 'feature') {
      setFeature(Number(id.split('-')[1]))
    } else if (name === 'comment') {
      setComment(value)
    } else if (name.includes('audience') || name.includes('content')) {
      setPersonalizedContent(oldPersonalizedContent => {
        // create copy of personalized content array
        const personalizedContentCopy = [...oldPersonalizedContent]
        // grab the index of content
        const contentIndex = Number(name.slice(-1))
        // grab name of update key
        const updateKey = name.slice(0, -1).replace('-', '')
        // grab content to update
        const contentToUpdate = personalizedContentCopy[contentIndex]
        // Update the content from the copy data
        personalizedContentCopy[contentIndex] = {
          ...contentToUpdate,
          contentJson: name.includes('content')
            ? data.contents.find(({ contentId }) => contentId === value)
            : contentToUpdate.contentJson,
          [updateKey]: value,
          isDirty: true,
        }
        return personalizedContentCopy
      })
    } else if (name.includes('Role')) {
      setUserInput(value)
    }

    onChange(true)
  }

  const handlePick = (type, suggestedData) => {
    if (type === 'approvers') {
      setRoles(oldApprovers => [...oldApprovers, suggestedData])
      setUserInput('')
    }

    onChange(true)
  }

  const handleRemovePick = (type, data) => {
    if (type === 'approvers') {
      const { id: userId } = data
      setRoles(oldApprovers => {
        return oldApprovers.filter(({ id }) => id !== userId)
      })
    }

    onChange(true)
  }

  const addPersonalizedContent = () => {
    setPersonalizedContent(oldPersonalizedContent => {
      const personalizedContentCopy = [...oldPersonalizedContent]
      const defaultContent = personalizedContentCopy.pop()
      personalizedContentCopy.push({
        defaultContent: false,
        contentId: '',
        audienceId: '',
        priority: personalizedContentCopy.length,
        contentJson: null,
        isDirty: true,
      })
      personalizedContentCopy.push({
        ...defaultContent,
        priority: personalizedContentCopy.length,
        isDirty: true,
      })
      return personalizedContentCopy
    })
  }

  const handlePriorityQueue = (action, index) => {
    setPersonalizedContent(oldPersonalizedContent => {
      const personalizedContentCopy = [...oldPersonalizedContent]

      if (action === 'top' && index !== 0) {
        const tempArray = [
          {
            ...oldPersonalizedContent[index],
            priority: 0,
          },
        ]
        // Update all other contents
        for (let i = 0; i < oldPersonalizedContent.length; i++) {
          if (i !== index) {
            tempArray.push({
              ...oldPersonalizedContent[i],
              priority: i < index ? i + 1 : i,
            })
          }
        }
        return tempArray
      } else if (action === 'up' && index !== 0) {
        const tempPrevContent = personalizedContentCopy[index - 1]
        // Update position of content to above other
        personalizedContentCopy[index - 1] = {
          ...personalizedContentCopy[index],
          priority: index - 1,
          isDirty: true,
        }
        // Update position of content to below the new content
        personalizedContentCopy[index] = {
          ...tempPrevContent,
          priority: index,
          isDirty: true,
        }
        return personalizedContentCopy
      } else if (action === 'down') {
        const tempPrevContent = personalizedContentCopy[index + 1]
        // Update position of content to above other
        personalizedContentCopy[index + 1] = {
          ...personalizedContentCopy[index],
          priority: index + 1,
          isDirty: true,
        }
        // Update position of content to below the new content
        personalizedContentCopy[index] = {
          ...tempPrevContent,
          priority: index,
          isDirty: true,
        }
        return personalizedContentCopy
      } else if (action === 'bottom') {
        // Grab tempContent
        const tempContent = oldPersonalizedContent[index]
        const tempArray = []
        // Grab default
        const tempDefault = oldPersonalizedContent.pop()
        // Update all other contents
        for (let i = 0; i < oldPersonalizedContent.length; i++) {
          if (i !== index) {
            tempArray.push({
              ...oldPersonalizedContent[i],
              priority: i > index ? i - 1 : i,
              isDirty: true,
            })
          }
        }
        // Push last content
        tempArray.push({
          ...tempContent,
          priority: tempArray.length,
          isDirty: true,
        })
        // Push default content
        tempArray.push({
          ...tempDefault,
          priority: tempArray.length,
          isDirty: true,
        })
        return tempArray
      } else if (action === 'delete') {
        const tempArray = []

        // Update all other contents
        for (let i = 0; i < oldPersonalizedContent.length; i++) {
          if (i !== index) {
            tempArray.push({
              ...oldPersonalizedContent[i],
              priority: i > index ? i - 1 : i,
              isDirty: true,
            })
          }
        }

        return tempArray
      }
    })
  }

  // Api Methods
  const onSubmit = async () => {
    if (id !== 'new') {
      await updateContentMapCall()
    } else {
      await insertNewContentMap()
    }
  }

  const insertNewContentMap = async () => {
    const newConfigSetUp = config.setUpSteps.map(step => {
      if (step.label === 'Create') {
        return {
          ...step,
          status: 'complete',
        }
      }
      return step
    })

    const newConfig = {
      setUpSteps: newConfigSetUp,
    }

    const input = {
      productId: Number(product),
      featureId: Number(feature),
      contentAreaId: contentAreaId,
      name,
      config: JSON.stringify(newConfig),
      comment,
      jiraTicket,
      personalizedContent: personalizedContent.map(
        ({ contentId, audienceId, priority, contentJson }) => ({
          contentId: contentId,
          audienceId: Number(audienceId),
          priority,
          contentJson: JSON.stringify(contentJson),
        })
      ),
    }

    try {
      await insertContentMap({
        variables: { input },
        ignoreResults: false,
      })
    } catch (err) {
      if (err.message.includes('duplicate name')) {
        const errorMessage = {}
        errorMessage.name = nameDuplicateError
        setError(oldErrs => ({
          ...oldErrs,
          ...errorMessage,
        }))
      }
      setErrorMessage(errors => ({
        ...errors,
        submit: err.message,
      }))
      scrollToNavigationPosition()
    }
  }

  const updateContentMapCall = async () => {
    const input = {}

    // Verify changes and add to input
    if (name !== contentMap.name) {
      input.name = name
    }

    if (jiraTicket !== contentMap.jiraTicket) {
      input.jiraTicket = jiraTicket
    }

    if (contentAreaId !== contentMap.contentAreaId) {
      input.contentAreaId = contentAreaId
    }

    if (Number(product) !== Number(contentMap.productId)) {
      input.productId = Number(product)
    }

    if (Number(feature) !== Number(contentMap.featureId)) {
      input.featureId = Number(feature)
    }

    if (comment !== contentMap.comment) {
      input.comment = comment
    }

    if (personalizedContent.filter(({ isDirty }) => isDirty).length) {
      input.personalizedContent = personalizedContent
        .filter(({ isDirty }) => isDirty)
        .map(({ id, contentId, audienceId, priority, contentJson }) => ({
          id,
          contentId: contentId,
          audienceId: Number(audienceId),
          priority,
          contentJson: JSON.stringify(contentJson),
        }))
    }

    try {
      if (Object.keys(input).length) {
        await updateContentMap({
          variables: {
            id: Number(id),
            input,
          },
          ignoreResults: false,
        })
      } else {
        await updateUsers(Number(id))
      }
    } catch (err) {
      if (err.message.includes('duplicate name')) {
        const errorMessage = {}
        errorMessage.name = nameDuplicateError
        setError(oldErrs => ({
          ...oldErrs,
          ...errorMessage,
        }))
      }
      setErrorMessage(errors => ({
        ...errors,
        submit: err.message,
      }))
    }
    setSuccess(true)
    scrollToNavigationPosition()
  }

  const updateUsers = async contentId => {
    const rolesAndUsers = []
    const deleteRoles = []

    if (contentMap && contentMap.contentMapToUser) {
      // Loop through roles and verify if a new approver added
      approverRole.forEach(({ id }) => {
        if (
          contentMap.contentMapToUser &&
          !contentMap.contentMapToUser.find(({ userId }) => id === userId)
        ) {
          rolesAndUsers.push({
            roleID: 39,
            userID: Number(id),
          })
        }
      })

      // Loop through and verify if user has been deleted from approver list
      contentMap.contentMapToUser.forEach(({ id, userId }) => {
        if (!approverRole.find(({ id: user }) => user === userId)) {
          deleteRoles.push(id)
        }
      })
    } else {
      // Loop through roles and add approver
      approverRole.forEach(({ id }) => {
        rolesAndUsers.push({
          roleID: 39,
          userID: Number(id),
        })
      })
    }

    if (rolesAndUsers.length) {
      try {
        const roleInput = {
          contentMapId: contentId,
          rolesAndUsers,
        }

        await insertContentMapToUser({
          variables: {
            input: roleInput,
          },
        })
      } catch (err) {
        setErrorMessage(oldErr => ({
          ...oldErr,
          submit: err,
        }))
      }
    }

    if (deleteRoles.length) {
      try {
        await deleteContentMapToUser({
          variables: {
            ids: deleteRoles,
          },
        })
      } catch (err) {
        setErrorMessage(oldErr => ({
          ...oldErr,
          submit: err,
        }))
      }
    }
  }

  const validateSubmission = () => {
    const errorMessage = {}
    if (!name || name.length < 0) {
      errorMessage.name = nameError
    }

    // Check for unselected audiences not in default
    const nullAudiences = personalizedContent.filter(
      ({ audienceId, defaultContent }) => audienceId === '' && !defaultContent
    )

    if (nullAudiences.length > 0) {
      nullAudiences.forEach(({ priority }) => {
        errorMessage[`audienceId-${priority}`] = audienceErrorMessage
      })
    }

    // Check for unselected content
    const nullContents = personalizedContent.filter(
      ({ contentId }) => contentId === ''
    )
    if (nullContents.length > 0) {
      nullContents.forEach(({ priority }) => {
        errorMessage[`contentId-${priority}`] = contentErrorMessage
      })
    }

    if (!product) {
      errorMessage.product = productError
    }

    if (!feature) {
      errorMessage.feature = featureError
    }

    if (!contentAreaId) {
      errorMessage.contentAreaId = contentAreaIdError
    }

    if (!approverRole.length) {
      errorMessage.approverRole = noApproverError
    }

    if (!isEmpty(errorMessage)) {
      setError(oldErrs => ({
        ...oldErrs,
        ...errorMessage,
      }))
      scrollToNavigationPosition()
      return false
    }

    return true
  }

  // Render methods
  const renderProductOptions = () => {
    const { productsByExperimentCategory, productCategoryTypes } = data

    return (
      <Fragment>
        <option value="" disabled>
          Select Product
        </option>

        {productCategoryTypes
          .slice()
          .sort((aType, bType) => aType.name > bType.name)
          .map(({ id: categoryId, name }) => {
            const productOpts = productsByExperimentCategory
              .slice()
              .filter(({ productCategoryType: { id } }) => id === categoryId)
              .sort((aProduct, bProduct) =>
                aProduct.displayName.toLowerCase() >
                bProduct.displayName.toLowerCase()
                  ? 1
                  : -1
              )
              .map(product => (
                <option key={product.id} value={product.id}>
                  {product.displayName}
                </option>
              ))
            if (productOpts.length) {
              return productOpts
            } else {
              return ''
            }
          })}
      </Fragment>
    )
  }

  const renderOptions = (options, value, title = '', optionLabel = '') => {
    return (
      <Fragment>
        <option value="" disabled>
          {optionLabel}
        </option>
        {options.map(item => (
          <option key={item[title]} value={item[value]}>
            {item[title]}
          </option>
        ))}
      </Fragment>
    )
  }

  const renderBusinessUseCase = () => {
    const { productsByExperimentCategory } = data
    if (productsByExperimentCategory.length) {
      const { features } = productsByExperimentCategory[0]

      return (
        <Fragment>
          <div className="create__business-use-case-label">
            Business Use Case
          </div>
          {features.map(({ id, displayName }) => (
            <KiteCheckbox
              disabled={disabled || (contentMap && contentMap.isApprovalReady)}
              id={`check-${id}`}
              key={`check-${id}`}
              className={`create__check-${id}`}
              label={displayName}
              checked={id === Number(feature)}
              name="feature"
              onChange={handleChange}
            />
          ))}
          {errorMessage.feature ? (
            <div className="create__business-case-error">{featureError}</div>
          ) : (
            ''
          )}
        </Fragment>
      )
    }
  }

  if (loading)
    return (
      <div className="app__loader">
        <KiteLoader size="7rem" />
      </div>
    )

  const publishedDisabled =
    contentMap && Number(contentMap.statusId) === experimentStatusEnum.PUBLISHED

  const { contentAreas, audiences, users } = data

  const usersByName = {}
  approverRole.forEach(user => {
    usersByName[user.displayName] = user
  })

  const isLoading =
    insertLoading ||
    loadingUpdateContentMap ||
    loadingInsertContentMap ||
    loadingDeleteContentMapUser ||
    loadingPublish

  return (
    <div className="create">
      <h4> {infoTitle} </h4>
      <KiteCard>
        <div className="create__info-main">
          <div className="create__left-data">
            <KiteSelect
              className="create__product"
              name="product"
              label="Product"
              maxWidth="453px"
              value={product}
              disabled={disabled || (contentMap && contentMap.isApprovalReady)}
              onChange={handleChange}
              errorMessage={
                errorMessage.product || errorMessage.getProductFeatures
                  ? `${errorMessage.product || ''} ${
                      errorMessage.getProductFeatures || ''
                    }`
                  : false
              }
            >
              {renderProductOptions()}
            </KiteSelect>

            <KiteSelect
              className="create__content-area"
              name="contentAreaId"
              label="Content Area"
              value={contentAreaId}
              maxWidth="453px"
              disabled={disabled || (contentMap && contentMap.isApprovalReady)}
              onChange={handleChange}
              errorMessage={errorMessage.contentAreaId || false}
            >
              {renderOptions(
                contentAreas,
                'contentAreaId',
                'contentAreaId',
                'Select Content Area'
              )}
            </KiteSelect>

            <KiteInput
              disabled={disabled || (contentMap && contentMap.isApprovalReady)}
              id="name"
              label={nameLabel}
              margin=""
              max="453px"
              maxWidth="453px"
              min="453px"
              name="name"
              onChange={handleChange}
              placeholder={nameInput}
              type="text"
              value={name}
              errorMessage={errorMessage.name}
            />

            <KiteInput
              label={jiraHeader}
              disabled={isCompleteMap}
              id="jiraTicket"
              name="jiraTicket"
              placeholder={jiraPlaceholder}
              value={jiraTicket}
              maxWidth="453px"
              onChange={handleChange}
            />
          </div>

          <div className="create__right-data">
            {renderBusinessUseCase()}

            <AutoSuggest
              key="role-approver"
              label={approverHeader}
              value={userInput}
              name="approverRole"
              errorMessage={errorMessage.approverRole || false}
              disabled={disabled || (contentMap && contentMap.isApprovalReady)}
              initialData={users
                .filter(({ userToRoles }) => {
                  if (userToRoles.length) {
                    return userToRoles.find(
                      ({ roleId }) => roleId === 1 || roleId === 37
                    )
                  } else {
                    return false
                  }
                })
                .sort((usera, userb) => {
                  if (
                    usera.displayName.toLowerCase() >
                    userb.displayName.toLowerCase()
                  ) {
                    return 1
                  } else if (
                    usera.displayName.toLowerCase() <
                    userb.displayName.toLowerCase()
                  ) {
                    return -1
                  }
                  return 0
                })}
              suggestionKey="displayName"
              minCharacters={3}
              showIcon={false}
              maxWidth="491px"
              className="review-personalization__approver-input"
              tooltip={approverTooltip}
              showExpandButton
              onChange={handleChange}
              onSuggestionSelected={(event, suggestedData) => {
                event.preventDefault()
                handlePick('approvers', suggestedData.suggestion)
              }}
              placeholder={approverPlaceholder}
            />
            <div className="role-multiple__pills">
              <BasicPills
                values={usersByName}
                onRemovePill={
                  disabled || (contentMap && contentMap.isApprovalReady)
                    ? null
                    : userToRemove =>
                        handleRemovePick('approvers', userToRemove)
                }
              />
            </div>
            <KiteInput
              className="create__comment"
              id="comment"
              label={commentLabel}
              disabled={isCompleteMap}
              margin=""
              max="491px"
              maxWidth="491px"
              min=""
              name="comment"
              onChange={handleChange}
              placeholder={commentPlaceholder}
              type="text"
              value={comment}
              errorMessage={errorMessage.comment}
            />
          </div>
        </div>
      </KiteCard>

      <div className="create__map-audiences-content">
        <h4>{mapContentLabel}</h4>
      </div>

      <KiteCard className="create__audience-priority-content-container">
        <div className="create__personalized-content-btn-container">
          <KiteButton
            size="large"
            disabled={
              disabled ||
              isLoading ||
              (contentMap && contentMap.isApprovalReady)
            }
            onClick={addPersonalizedContent}
            type="outline"
          >
            {personalizedContentBtn}
          </KiteButton>
          <div className="create__audience-management-link-container">
            <div className="create__audience-question">
              {audienceMangamentLabel}
            </div>
            <div className="create__audience-management-link">
              <KiteLink
                className="create__audience-management-link"
                newTab
                href={`/audience-management`}
                type="standalone"
              >
                <KiteIcon margin="5px" name="linkout" size="1.2rem" />
                Audience Management
              </KiteLink>
              <KiteButton
                className="create__refresh-btn"
                size="small"
                disabled={
                  disabled ||
                  isLoading ||
                  (contentMap && contentMap.isApprovalReady)
                }
                onClick={() => refetch()}
                type="standalone-link"
              >
                Refresh Audiences
              </KiteButton>
            </div>
          </div>
        </div>
        {personalizedContent
          .sort((contentA, contentB) => {
            if (contentA.priority < contentB.priority) {
              return -1
            } else {
              return 1
            }
          })
          .map(({ contentId, audienceId, defaultContent, priority }) => {
            let classExtension = 'personalized-content-container'
            let disabledDownBtns =
              disabled || priority === personalizedContent.length - 2

            if (defaultContent) {
              classExtension = 'default-container'
            }
            return (
              <div
                id={`content-container-${priority}`}
                key={`content-container-${priority}`}
              >
                <div className={`create__${classExtension}`}>
                  {!defaultContent ? (
                    <Fragment>
                      <div className="create__up-btn-container">
                        <KiteButton
                          key="top-btn"
                          className={
                            disabled || priority === 0
                              ? 'create__btn-disabled'
                              : ''
                          }
                          size="small"
                          disabled={
                            disabled ||
                            priority === 0 ||
                            (contentMap && contentMap.isApprovalReady)
                          }
                          leftIcon="arrow-up-circle"
                          onClick={() => handlePriorityQueue('top', priority)}
                          type="standalone-link"
                        >
                          Top
                        </KiteButton>
                        <KiteButton
                          key="up-btn"
                          size="small"
                          className={
                            disabled || priority === 0
                              ? 'create__btn-disabled'
                              : ''
                          }
                          disabled={
                            disabled ||
                            priority === 0 ||
                            (contentMap && contentMap.isApprovalReady)
                          }
                          leftIcon="arrow-up"
                          onClick={() => handlePriorityQueue('up', priority)}
                          type="standalone-link"
                        >
                          Up
                        </KiteButton>
                      </div>
                      <div className="create__down-btn-container">
                        <KiteButton
                          key="down-btn"
                          size="small"
                          className={
                            disabledDownBtns ? 'create__btn-disabled' : ''
                          }
                          disabled={
                            disabled ||
                            disabledDownBtns ||
                            (contentMap && contentMap.isApprovalReady)
                          }
                          rightIcon="arrow-down"
                          onClick={() => handlePriorityQueue('down', priority)}
                          type="standalone-link"
                        >
                          Down
                        </KiteButton>
                        <KiteButton
                          key="bottom-btn"
                          size="small"
                          className={
                            disabledDownBtns ? 'create__btn-disabled' : ''
                          }
                          disabled={
                            disabled ||
                            disabledDownBtns ||
                            (contentMap && contentMap.isApprovalReady)
                          }
                          rightIcon="arrow-down-circle"
                          onClick={() =>
                            handlePriorityQueue('bottom', priority)
                          }
                          type="standalone-link"
                        >
                          Bottom
                        </KiteButton>
                      </div>
                    </Fragment>
                  ) : (
                    <div className="create__default-label-container">
                      {defaultLabel}
                    </div>
                  )}
                  <div className="create__experiment-link-audience-container">
                    {<RadioSearchContainer
                      id={`audienceId-${priority}`}
                      radioBtnOpt={formatAudience(audiences, audienceId, defaultContent) || []}
                      onChange={handleChange}
                      width="100%"
                      height="100px"
                      name={`audienceId-${priority}`}
                      disabledContainer={disabled ||
                        defaultContent ||
                        (contentMap && contentMap.isApprovalReady)}
                      label="Audience"
                      searchPlaceholder="Type or scroll to find Audience..."
                      noDataMessage={relatedContent ? 'No audience(s)' : ''}
                      errorMessage={errorMessage[`audienceId-${priority}`] && !defaultContent
                        ? errorMessage[`audienceId-${priority}`]
                        : false}
                    />}
                  </div>
                  <div className="create__experiment-link-audience-container">
                    <RadioSearchContainer
                      id={`contentId-${priority}`}
                      radioBtnOpt={formatContent(relatedContent, contentId) || []}
                      onChange={handleChange}
                      width="100%"
                      height="100px"
                      name={`contentId-${priority}`}
                      disabledContainer={disabled}
                      label="Content"
                      searchPlaceholder="Type or scroll to find Content..."
                      noDataMessage={relatedContent ? 'No related content' : ''}
                      errorMessage={errorMessage[`contentId-${priority}`] || ''}
                    />
                  </div>
                  {!defaultContent && (
                    <KiteButton
                      size="small"
                      disabled={
                        disabled ||
                        personalizedContent.length === 2 ||
                        (contentMap && contentMap.isApprovalReady)
                      }
                      rightIcon="trash-f"
                      minWidth="30px"
                      maxWidth="30px"
                      className={
                        personalizedContent.length === 2
                          ? 'create__delete-btn-disabled'
                          : 'create__delete-btn'
                      }
                      onClick={() => handlePriorityQueue('delete', priority)}
                      type="standalone-link"
                    />
                  )}
                </div>
              </div>
            )
          })}
      </KiteCard>

      <div className="create__btn-container">
        <div className="create__btn-save-cancel">
          <KiteButton
            size="large"
            loading={isLoading}
            disabled={
              isLoading || !hasPermission || publishedDisabled || !isDirty
            }
            onClick={() => {
              if (validateSubmission()) {
                onSubmit()
              }
            }}
          >
            {saveBtn}
          </KiteButton>

          <KiteButton
            className="create__exit-btn"
            size="small"
            type="standalone-link"
            disabled={
              isLoading ||
              !hasPermission ||
              publishedDisabled ||
              (contentMap && contentMap.isApprovalReady)
            }
            onClick={() => {
              isDirty ? setModalOpen(true) : history.push('/content-maps/draft')
            }}
          >
            Discard Changes
          </KiteButton>
        </div>

        <KiteButton
          size="large"
          leftIcon="x-circle"
          type="outline"
          className="create__cancel-content-btn"
          disabled={
            isLoading ||
            !hasPermission ||
            !contentMap ||
            (contentMap &&
              (contentMap.stopTime < new Date().toISOString() ||
                !contentMap.startTime))
          }
          onClick={() => {
            setIsCancelContentModalOpen(true)
          }}
        >
          Cancel Content Map
        </KiteButton>
      </div>
    </div>
  )
}

export default Create
