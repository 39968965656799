import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import isEqual from 'lodash/isEqual'
import { isEmpty } from 'lodash'
import {
  KiteButton,
  KiteCard,
  KiteInput,
  KiteAlert,
  KiteLoader,
  KiteLink,
  KiteIcon,
} from '@kite/react-kite'
import { TextArea } from '@kite/react-kite-plus'
import { QueryBuilder } from '../../../components'
import { ExpansionPanel, Modal } from '../../../componentLibrary'
import copyContent from '../data/copyContent'
import { GET_AUDIENCE } from '../queries/getAudience'
import {
  INSERT_AUDIENCE,
  CLONE_AUDIENCE,
  UPDATE_AUDIENCE,
} from '../../../shared/mutations'
import { GET_USER_PROFILE } from '../../../shared/queries'
import {
  scrollToNavigationPosition,
  formatUserProfileFields,
} from '../../../shared/utilities'
import './Audience.scss'

const { audienceContent } = copyContent
const initialErrorMessage = {
  name: null,
  description: null,
  rules: null,
  submit: null,
  getAudience: null,
}

export function Audience({
  location: { pathname: path },
  onNavigate,
  history,
  disabled,
  match: {
    params: { id },
  },
}) {
  const [audienceName, setAudienceName] = useState('')
  const [content, setAudienceContent] = useState('')
  const [currentlyPersonalizing, setCurrentlyPersonalizing] = useState(false)
  const [description, setDescription] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [rules, setRules] = useState({})
  const [userProfileFields, setUserProfileFields] = useState({})
  const [success, setSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState(initialErrorMessage)
  const [insertAudience, { loading: mutationLoading }] = useMutation(
    INSERT_AUDIENCE,
    {
      onCompleted: data => {
        history.push(`${data.insertAudience.id}`)
      },
    }
  )
  const [
    cloneAudience,
    { cloneLoading: cloneMutationLoading, cloneError: cloneMutationError },
  ] = useMutation(CLONE_AUDIENCE, {
    onCompleted: data => {
      history.push(`${data.cloneAudience.id}`)
    },
  })
  const [updateAudience, { updateLoading: updateMutationLoading }] =
    useMutation(UPDATE_AUDIENCE)

  const { loading, error, data } = useQuery(GET_AUDIENCE, {
    variables: { id: Number(id) },
    skip: id === 'new',
  })

  const { loading: userProfileLoading, data: userProfileData } =
    useQuery(GET_USER_PROFILE)

  useEffect(() => {
    if (id && data && data.audience) {
      const {
        name,
        description: audienceDescription,
        rules: audienceRules,
        content,
        isPersonalizing,
      } = data.audience

      setAudienceName(name)
      setDescription(audienceDescription)
      // NECESSARY CODE BELOW FOR MARKETING PROFILES TO DISPLAY PROPERLY FROM JSON LOGIC
      // 'some' needs to be in place of 'all' for jsonlogic to work properly on the
      // distillery rules engine side but 'all' needs to be in place of 'some' for
      // the rules to display properly on the query builder
      const updateAllToSomeAudience = audienceRules.replaceAll('some', 'all')
      setRules(JSON.parse(updateAllToSomeAudience))
      setAudienceContent(content)
      setCurrentlyPersonalizing(isPersonalizing)
    }

    if (userProfileData && userProfileData.userProfileSchema) {
      const profileFields = formatUserProfileFields(
        userProfileData.userProfileSchema
      )
      setUserProfileFields(profileFields)
    }

    if (error) {
      setErrorMessage(oldErrs => ({
        ...oldErrs,
        getAudience: error,
      }))
    }
  }, [id, data, error, userProfileData])

  const onChange = ({ target: { name, value } }) => {
    setErrorMessage(initialErrorMessage)
    if (name === 'audienceName') {
      setAudienceName(value)
    } else if (name === 'description') {
      setDescription(value)
    }
  }

  const handleClone = () => {
    const input = {
      name: audienceName + '- Copy',
      id: parseInt(id),
    }

    cloneAudience({ variables: { input } })
    if (cloneMutationError) {
      setErrorMessage(oldErrs => ({
        ...oldErrs,
        submit: cloneMutationError,
      }))
    } else {
      setSuccess(true)
    }

    scrollToNavigationPosition()
  }

  async function handleUpdate() {
    const { name, description: oldDescription, rules: oldRules } = data.audience

    const input = {}

    if (audienceName !== name) {
      input.name = audienceName
    }
    if (description !== oldDescription) {
      input.description = description
    }
    if (!isEqual(rules, JSON.parse(oldRules))) {
      input.rules = JSON.stringify(rules)
    }
    if (Object.keys(input).length) {
      try {
        await updateAudience({
          variables: {
            input,
            id: Number(id),
          },
        })
        setSuccess(true)
      } catch {
        const errors = {}
        errors.name = audienceContent.audienceNameDuplicate
        setErrorMessage(oldErrs => ({
          ...oldErrs,
          ...errors,
        }))
      }
      scrollToNavigationPosition()
    }
  }

  async function handleSave() {
    const input = {
      name: audienceName,
      description,
      rules: JSON.stringify(rules),
    }
    try {
      await insertAudience({ variables: { input } })
      setSuccess(true)
    } catch {
      const errors = {}
      errors.name = audienceContent.audienceNameDuplicate
      setErrorMessage(oldErrs => ({
        ...oldErrs,
        ...errors,
      }))
    }
    scrollToNavigationPosition()
  }

  const handleRuleChange = newRules => {
    setRules(newRules)
    setErrorMessage(initialErrorMessage)
  }

  const validateSubmission = () => {
    const errors = {}

    if (!audienceName || audienceName.length === 0) {
      errors.name = audienceContent.audienceNameEmpty
    }

    if (rules.length && !rules[0].fieldSpecification && !rules[0].fieldType) {
      errors.rules = audienceContent.audienceNameDuplicate
    }

    if (
      !rules.data ||
      (rules && rules.data && !Object.keys(rules.data).length)
    ) {
      errors.ruleBuilder = audienceContent.ruleBuilderError
    }

    if (!isEmpty(errors)) {
      setErrorMessage(oldErrs => ({
        ...oldErrs,
        ...errors,
      }))
      scrollToNavigationPosition()
      return false
    }
    setErrorMessage(initialErrorMessage)
    return true
  }

  if (userProfileLoading)
    return (
      <div className="app__loader">
        <KiteLoader size="7rem" />
      </div>
    )

  return (
    <div className="new-audience">
      <div className="new-audience__back-button">
        <KiteButton
          type="standalone-link"
          leftIcon="chevron-left"
          disabled={loading}
          onClick={() => history.push('/audience-management')}
        >
          {audienceContent.backBtn}
        </KiteButton>
      </div>
      <div className="learn-article-tip">
        Tips on how to create and interpret audiences:{' '}
        <KiteLink newTab href={'/learn/home/'} type="standalone">
          <div className="learn-article-tip__link">Learn: Audiences</div>
          <KiteIcon margin="5px" name="arrow-up-right" size="1rem" />
        </KiteLink>
      </div>

      <ExpansionPanel type="minimal" isExpanded={!!success}>
        <KiteAlert
          className="app__page-level-message"
          type="confirm"
          title={'Successful'}
          description={success && 'Audience saved sucessfully.'}
        />
      </ExpansionPanel>

      <ExpansionPanel
        type="minimal"
        isExpanded={!!errorMessage.submit || !!errorMessage.getAudience}
      >
        <KiteAlert
          className="app__page-level-message"
          type="alert"
          description={`Error occurred: ${
            errorMessage.submit || errorMessage.getAudience
          }. If this continues `}
          onClose={() => this.setState({ errorMessage: initialErrorMessage })}
        />
      </ExpansionPanel>

      <KiteCard className="audience-card">
        <div className="audience-card__body">
          <div className="audience-card__name-description" key="role-approver">
            <KiteInput
              errorMessage={errorMessage.name}
              id="audienceName"
              label="Audience Name"
              disabled={currentlyPersonalizing || loading}
              max="30rem"
              maxWidth="30rem"
              name="audienceName"
              onChange={onChange}
              placeholder="Enter Audience Name"
              type="text"
              value={audienceName}
            />

            <TextArea
              name="description"
              label="Description (optional)"
              disabled={currentlyPersonalizing || loading}
              value={description}
              onChange={onChange}
              placeholder={audienceContent.descriptionPlaceholder}
              height="8.125rem"
              width="30rem"
              maxLength="60000"
            />
          </div>
          {currentlyPersonalizing && (
            <div className="audience-card__currently-personalizing">
              This audience is currently personalizing:{' '}
              <div className="audience-card__currently-personalizing-title">
                {content &&
                  content.map(personalization => personalization.contentTitle)}
              </div>
            </div>
          )}
        </div>
      </KiteCard>

      <KiteCard>
        <QueryBuilder
          rules={rules}
          handleChange={handleRuleChange}
          fields={userProfileFields}
          disabled={currentlyPersonalizing}
          error={errorMessage.ruleBuilder}
        />
      </KiteCard>

      <div className="save-edit-buttons">
        <KiteButton
          className="save-edit-buttons__save"
          disabled={
            currentlyPersonalizing ||
            mutationLoading ||
            updateMutationLoading ||
            loading
          }
          loading={mutationLoading}
          onClick={() => {
            if (validateSubmission() && id === 'new') {
              handleSave()
            } else if (validateSubmission() && id !== 'new') {
              handleUpdate()
            }
          }}
          size="medium"
          type="primary"
        >
          Save
        </KiteButton>
        {currentlyPersonalizing && (
          <KiteButton
            className="save-edit-buttons__clone"
            type="outline"
            size="medium"
            loading={cloneMutationLoading}
            disabled={cloneMutationLoading || loading}
            onClick={handleClone}
          >
            Clone & Edit
          </KiteButton>
        )}
        {!currentlyPersonalizing && (
          <KiteButton
            className="create-personalization__exit-btn"
            size="small"
            type="standalone-link"
            onClick={() => {
              setModalOpen(true)
            }}
          >
            Exit without Saving
          </KiteButton>
        )}

        {modalOpen && (
          <Modal
            message={audienceContent.modalContent}
            onConfirm={() => {
              history.push('/audience-management')
            }}
            onDeny={() => setModalOpen(false)}
            buttonProps={{
              confirm: {
                value: 'Cancel Changes',
                type: 'warning-outline',
              },
              deny: { type: 'outline' },
            }}
          />
        )}
      </div>
    </div>
  )
}

export default Audience
