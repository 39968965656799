import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

/*
* Sample Input
* {
  input: [
    {
      notificationId: 1,
      userId: 11,
      inAppOn: false
    },
    {
      notificationId: 2,
      userId: 11,
      onlyMine: true
    }
  ]
}
*/

const UPDATE_NOTIFICATION_PREFERENCES = gql`
  mutation updateNotificationPreferences(
    $input: [UpdateNotificationPreferences!]
  ) {
    updateNotificationPreferences(input: $input) {
      id
      environmentId
      productId
      userId
      emailOn
      inAppOn
      onlyMine
      notificationDefinition {
        id
      }
    }
  }
`

const updateNotificationPreferences = graphql(UPDATE_NOTIFICATION_PREFERENCES, {
  name: 'updateNotificationPreferences',
})

export default updateNotificationPreferences
