import React, { Component, Fragment } from 'react'
import flowRight from 'lodash/flowRight'
import { object } from 'prop-types'

import { client } from '../../../../../configuration/configApiClient'
import { LOG_ERROR } from '../../../../../shared/mutations'
import { formatLoggingError } from '../../../../../shared/utilities'
import { ListBuilder } from '../../../../../componentLibrary'
import { getUsers } from '../../queries'
import { deleteRoleWithUsers, insertRoleWithUsers } from '../../mutations'

class UsersPanel extends Component {
  static propTypes = {
    allUsersData: object,
    selectedRole: object,
  }

  static defaultProps = {
    allUsersData: {},
    selectedRole: {},
  }

  state = {
    usersStatus: undefined,
  }

  filterUsersToUpdate = (assignedUsers, updatedUsers) => {
    const mergedUsers = [...assignedUsers, ...updatedUsers]
    return {
      usersToDeleteIds: mergedUsers.filter(
        userId => !updatedUsers.includes(userId)
      ),
      usersToInsertIds: mergedUsers.filter(
        userId => !assignedUsers.includes(userId)
      ),
    }
  }

  updateAssignedUsers = async updatedUsers => {
    await this.setState({ usersStatus: 'saving' })
    const {
      deleteRoleWithUsers: deleteUsers,
      insertRoleWithUsers: insertUsers,
      selectedRole: { id, users: roleUsers },
    } = await this.props

    const updatedUserIds = updatedUsers.map(user => user.id)
    const roleAssignedUserIds = roleUsers.map(roleUser => roleUser.id)

    const { usersToDeleteIds, usersToInsertIds } = this.filterUsersToUpdate(
      roleAssignedUserIds,
      updatedUserIds
    )

    try {
      if (usersToDeleteIds.length > 0) {
        await deleteUsers({
          variables: {
            input: {
              roleId: id,
              userIds: usersToDeleteIds,
            },
          },
        })
      }

      if (usersToInsertIds.length > 0) {
        await insertUsers({
          variables: {
            input: {
              roleId: id,
              userIds: usersToInsertIds,
            },
          },
        })
      }

      await this.setState({ usersStatus: 'success' })
    } catch (error) {
      const input = formatLoggingError(error)

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input,
        },
      })

      await this.setState({ usersStatus: 'error' })
    }
  }

  render() {
    const {
      allUsersData: { users: allUsers },
      selectedRole,
    } = this.props
    const { usersStatus } = this.state

    return (
      <Fragment>
        <ListBuilder
          assignedOptions={selectedRole.users}
          assignedOptionsTitle="Assigned Users"
          allOptions={selectedRole.id ? allUsers : []}
          availableOptionsTitle="Available Users"
          selectedId={selectedRole.id}
          onSave={this.updateAssignedUsers}
          status={usersStatus}
        />
      </Fragment>
    )
  }
}

export default flowRight(
  getUsers,
  deleteRoleWithUsers,
  insertRoleWithUsers
)(UsersPanel)
