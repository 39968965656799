import React, { Component } from 'react'
import { func, object, bool, array, number } from 'prop-types'
import flowRight from 'lodash/flowRight'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import {
  KiteLoader,
  KiteInput,
  KiteRadio,
  KiteCheckbox,
} from '@kite/react-kite'
import { LabeledSwitch } from '@kite/react-kite-plus'
import {
  hasPermission,
  permissionEnum,
  checkTechSettings,
  experimentTypeEnum,
  experimentStatusEnum,
} from '@charter/distillery-rules'

import { client } from '../../../../configuration/configApiClient'
import {
  LOG_ERROR,
  APPROVE_TECH_SETTINGS,
  SET_NEEDS_TECH_APPROVAL,
} from '../../../../shared/mutations'
import { formatLoggingError } from '../../../../shared/utilities'
import { getExperimentTDCSFilters } from './queries'
import updateExperimentTechSettings from './mutations/updateExperimentTechSettings'
import copyContent from '../../data/copyContent'
import './TechSettings.scss'

const {
  EXPERIMENT_UPDATE_VERSION_RUNNING,
  TECH_UPDATE,
  EXPERIMENT_UPDATE_RUNNING,
} = permissionEnum
const { RUNNING } = experimentStatusEnum
const { CANARY } = experimentTypeEnum

const initialErrors = {
  productMinVersionRequired: null,
  productMinVersionFormatError: null,
  productMaxVersionRequired: null,
  productMaxVersionFormatError: null,
  updateExperimentTechSettingsError: null,
  approveTechSettings: null,
}

export class TechSettings extends Component {
  static propTypes = {
    id: number.isRequired,
    disabled: bool.isRequired,
    onChange: func.isRequired,
    canApproveTechSettings: bool.isRequired,
    experimentTDCSFiltersData: object.isRequired,
    updateExperimentTechSettings: func.isRequired,
    applicationPermissions: array,
    experimentPermissions: array,
    environmentSamplings: array,
  }

  static defaultProps = {
    applicationPermissions: [],
    experimentPermissions: [],
    environmentSamplings: [],
  }

  state = {
    techSettings: null,
    isRunningCanaryExperiment: false,
    errorMessage: initialErrors,
  }

  // LIFECYCLE METHODS
  componentDidMount() {
    const { experimentTDCSFiltersData } = this.props

    if (experimentTDCSFiltersData && experimentTDCSFiltersData.TDCSExperiment) {
      this.handleSetExperiment()
    }
  }

  componentDidUpdate(prevProps) {
    const { experimentTDCSFiltersData } = this.props
    const { experimentTDCSFiltersData: prevData } = prevProps

    if (
      prevData &&
      experimentTDCSFiltersData &&
      !isEqual(
        prevData.TDCSExperiment,
        experimentTDCSFiltersData.TDCSExperiment
      )
    ) {
      this.handleSetExperiment()
    }
  }

  // REF Methods
  getErrorMessage = () => {
    const { errorMessage } = this.state

    const messages = Object.values(errorMessage).reduce(
      (accumulator, error) => {
        if (!accumulator && error) {
          return `${error}`
        }
        if (error) {
          return `${accumulator}; ${error}`
        }
        return accumulator
      },
      ''
    )
    return messages
  }

  validateSubmission = async () => {
    const { techSettings } = this.state

    await this.setState({ errorMessage: initialErrors })

    const { errors } = checkTechSettings(techSettings)

    if (!isEmpty(errors)) {
      this.setState(({ errorMessage }) => ({
        errorMessage: {
          ...errorMessage,
          ...errors,
        },
      }))

      return false
    }

    return true
  }

  validatePermissions = permId => {
    const {
      applicationPermissions,
      experimentPermissions,
      environmentSamplings,
      experimentTDCSFiltersData: {
        experiment: { experimentTypeId },
      },
    } = this.props

    const canUpdateRunning =
      experimentPermissions &&
      hasPermission({
        experimentTypeId,
        applicationPermissions,
        experimentPermissions,
        environmentSamplings,
        permissionId: permId,
      })

    return canUpdateRunning
  }

  validateDisable = () => {
    const { disabled } = this.props
    const { isRunningCanaryExperiment } = this.state
    const canUpdateTech = this.validatePermissions(TECH_UPDATE)
    const canUpdateRunning = this.validatePermissions(EXPERIMENT_UPDATE_RUNNING)
    const canUpdateVersionRunning = this.validatePermissions(
      EXPERIMENT_UPDATE_VERSION_RUNNING
    )
    const updateAbility =
      canUpdateTech || canUpdateRunning || canUpdateVersionRunning
    let disabledField = disabled

    // Verify if the disabled prop needs to be
    // overridden due to user having ability to update
    // and experiment being a running canary experiment
    if (updateAbility && disabled && isRunningCanaryExperiment) {
      disabledField = !updateAbility
    }

    return disabledField
  }

  isComplete = () => {
    const { id, canApproveTechSettings } = this.props
    const experimentId = Number(id)

    if (canApproveTechSettings) {
      client.mutate({
        mutation: APPROVE_TECH_SETTINGS,
        variables: {
          experimentId,
        },
      })

      return true
    }

    client.mutate({
      mutation: SET_NEEDS_TECH_APPROVAL,
      variables: {
        experimentId,
      },
    })

    return false
  }

  // LOCAL STATE CHANGES/TOGGLES
  handleSetExperiment = async () => {
    const {
      experimentTDCSFiltersData,
      experimentTDCSFiltersData: {
        experiment: { experimentStatusId, experimentTypeId },
      },
      onChange,
    } = this.props
    const { TDCSExperiment } = experimentTDCSFiltersData

    const techSettings = {
      ...{ tdcsInformCallId: TDCSExperiment.tdcsInformCall.id },
      ...TDCSExperiment,
    }
    let isRunningCanaryExperiment = false
    if (experimentStatusId === RUNNING && experimentTypeId === CANARY) {
      isRunningCanaryExperiment = true
    }

    await this.setState({
      techSettings,
      isRunningCanaryExperiment,
    })
    onChange(false)
  }

  handleProductVersionChange = async ({ target: { name, value } }) => {
    const { onChange } = this.props
    const { techSettings } = this.state

    techSettings[name] = value

    await this.setState(({ errorMessage }) => ({
      techSettings,
      errorMessage: {
        ...errorMessage,
        [`${name}Required`]: null,
        [`${name}FormatError`]: null,
      },
    }))

    onChange(true)
  }

  handleInformCallFilterChange = async event => {
    const { onChange } = this.props
    const { techSettings } = this.state

    techSettings.tdcsInformCallId = Number(event.target.value)

    await this.setState({ techSettings })

    onChange(true)
  }

  handleBooleanFilterChange = async (
    filter,
    fieldName = 'isEnabled',
    value = undefined
  ) => {
    const { onChange } = this.props
    const { techSettings } = this.state

    // eslint-disable-next-line no-param-reassign
    filter[fieldName] = value !== undefined ? value : !filter[fieldName]
    await this.setState({ techSettings })

    onChange(true)
  }

  handleTextFilterChange = async (event, filter) => {
    const { onChange } = this.props
    const { techSettings } = this.state

    // eslint-disable-next-line no-param-reassign
    filter.textValue = event.target.value
    await this.setState({ techSettings })

    onChange(true)
  }

  // FILTER METHODS
  getTDCSFiltersOfType = filterTypeId => {
    const {
      techSettings: { tdcsExperimentFilters },
    } = this.state
    return tdcsExperimentFilters.filter(
      ({ tdcsFilter: { tdcsFilterType } }) => tdcsFilterType.id === filterTypeId
    )
  }

  // API Methods
  handleSubmit = async () => {
    const { techSettings, errorMessage } = this.state
    const { id, updateExperimentTechSettings: updateTechSettings } = this.props

    // Reset any previous submission errors
    if (errorMessage.updateExperimentTechSettingsError) {
      this.setState({
        errorMessage: {
          ...errorMessage,
          updateExperimentTechSettingsError: null,
        },
      })
    }

    try {
      const input = {
        tdcsExperimentId: techSettings.id,
        experimentId: id,
        productMinVersion: techSettings.productMinVersion,
        productMaxVersion: techSettings.productMaxVersion,
        tdcsInformCallId: techSettings.tdcsInformCallId,
        tdcsExperimentFilters: [],
      }

      const updateBooleanFilters = filterTypeId => {
        this.getTDCSFiltersOfType(filterTypeId).forEach(filter => {
          input.tdcsExperimentFilters.push({
            tdcsExperimentFilterId: filter.id,
            isEnabled: filter.isEnabled,
          })
        })
      }

      const updateTextFilters = filterTypeId => {
        this.getTDCSFiltersOfType(filterTypeId).forEach(filter => {
          input.tdcsExperimentFilters.push({
            tdcsExperimentFilterId: filter.id,
            isIncluded: filter.isIncluded,
            textValue: filter.textValue === null ? '' : filter.textValue,
          })
        })
      }

      updateBooleanFilters(1)
      updateBooleanFilters(2)
      updateBooleanFilters(3)
      updateTextFilters(4)

      await updateTechSettings({
        variables: {
          input,
        },
      })

      return true
    } catch (error) {
      const input = formatLoggingError(error, { id })

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input,
        },
      })

      this.setState(({ oldErrorMessage }) => ({
        errorMessage: {
          ...oldErrorMessage,
          updateExperimentTechSettingsError:
            copyContent.techSettings.updateExperimentTechSettingsError,
        },
      }))
    }
    return true
  }

  // RENDER METHODS
  renderBooleanFilter = (filterName, filterTypeId) => {
    const filters = this.getTDCSFiltersOfType(filterTypeId)

    return (
      <div
        className="experiment-tech-settings__filter-category"
        role="presentation"
      >
        <div className="experiment-tech-settings__filter-category-title">
          {filterName}
        </div>
        {filters.map(filter => (
          <KiteCheckbox
            label={filter.tdcsFilter.displayName}
            key={`filter-${filter.id}`}
            checked={filter.isEnabled}
            onChange={() => this.handleBooleanFilterChange(filter)}
            className="experiment-tech-settings__filter-checkbox"
            disabled={this.validateDisable()}
          />
        ))}
      </div>
    )
  }

  renderTextFilter = filter => (
    <div
      className="experiment-tech-settings__input-wrapper"
      key={`filter-${filter.id}`}
    >
      <LabeledSwitch
        className="experiment-tech-settings__include-exclude-switch"
        id={`${filter.id}__include-exclude-switch`}
        name={`${filter.id}__include-exclude-switch`}
        options={['Include', 'Exclude']}
        checked={!filter.isIncluded}
        disabled={this.validateDisable()}
        onChange={() =>
          this.handleBooleanFilterChange(
            filter,
            'isIncluded',
            !filter.isIncluded
          )
        }
        backgroundColor="#63738A"
        maxWidth="10rem"
        minWidth="10rem"
      />
      <KiteInput
        label={filter.tdcsFilter.displayName}
        placeholder="Enter comma delimited values"
        value={filter.textValue || ''}
        onChange={event => this.handleTextFilterChange(event, filter)}
        disabled={this.validateDisable()}
      />
    </div>
  )

  render() {
    const {
      disabled,
      experimentTDCSFiltersData: { loading: techSettingsLoading },
    } = this.props
    const { techSettings, errorMessage } = this.state

    const {
      productMinVersionRequired,
      productMinVersionFormatError,
      productMaxVersionRequired,
      productMaxVersionFormatError,
    } = errorMessage

    if (!techSettings || techSettingsLoading) {
      return (
        <div className="app__loader">
          <KiteLoader size="7rem" />
        </div>
      )
    }

    const {
      productMinVersion,
      productMaxVersion,
      tdcsInformCallId,
    } = techSettings

    const textFilters = this.getTDCSFiltersOfType(4)

    const canUpdateVersionRunning = this.validatePermissions(
      EXPERIMENT_UPDATE_VERSION_RUNNING
    )

    return (
      <div className="experiment-tech-settings">
        <h2 className="experiment-tech-settings__title">
          Enter Your (TDCS) Settings
        </h2>
        <h4 className="experiment-tech-settings__subtext">
          Work with your Technical Contact to complete this step.
        </h4>
        <h3 className="experiment-tech-settings__group-label">
          Product Versions
        </h3>
        <div className="experiment-tech-settings__version-wrapper">
          <KiteInput
            type="text"
            name="productMinVersion"
            label="Min"
            value={productMinVersion || ''}
            errorMessage={
              productMinVersionRequired || productMinVersionFormatError
            }
            onChange={this.handleProductVersionChange}
            margin="0 20px 0 0"
            maxWidth="155px"
            disabled={canUpdateVersionRunning ? false : disabled}
          />

          <KiteInput
            type="text"
            name="productMaxVersion"
            label="Max"
            value={productMaxVersion || ''}
            errorMessage={
              productMaxVersionRequired || productMaxVersionFormatError
            }
            onChange={this.handleProductVersionChange}
            margin="0 20px 0 0"
            maxWidth="155px"
            disabled={canUpdateVersionRunning ? false : disabled}
          />
        </div>
        <h3 className="experiment-tech-settings__group-label">
          Customers to Include
        </h3>
        <div className="experiment-tech-settings__subtext">
          TDCS Inform Call Filters
        </div>
        <KiteRadio
          radioButtons={[
            {
              label: 'Authenticated Customers by Account',
              value: '1',
              checked: tdcsInformCallId === 1,
            },
            {
              label: 'Authenticated Customers by Device',
              value: '2',
              checked: tdcsInformCallId === 2,
            },
            {
              label: 'Unauthenticated Customers by Device',
              value: '3',
              checked: tdcsInformCallId === 3,
            },
          ]}
          name="includedCustomers"
          onChange={this.handleInformCallFilterChange}
          margin="18px 0 0 0"
          disabled={disabled}
        />
        <h3 className="experiment-tech-settings__group-label">
          Filter to Specific Customers <span>(optional)</span>
        </h3>
        <div className="experiment-tech-settings__subtext">
          TDCS Advanced Filters
        </div>
        <div
          className="experiment-tech-settings__filter-categories-wrapper"
          role="presentation"
        >
          {this.renderBooleanFilter('MSO', 1)}
          {this.renderBooleanFilter('Classification', 2)}
          {this.renderBooleanFilter('Account Type', 3)}
        </div>
        <div className="experiment-tech-settings__custom-filters-wrapper">
          {textFilters.map(this.renderTextFilter)}
        </div>
      </div>
    )
  }
}

export default flowRight(
  getExperimentTDCSFilters,
  updateExperimentTechSettings
)(TechSettings)
