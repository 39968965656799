const formatPillValues = (filters, displayName) => {
  const pillValues = Object.keys(filters).reduce((accumulator, filter) => {
    if (filters[filter]) {
      filters[filter].forEach((subFilter) => {
        if (typeof subFilter === 'string') {
          if (subFilter === displayName) {
            accumulator['My Experiments'] = `${filter}__${subFilter}`;
          } else {
            accumulator[subFilter] = `${filter}__${subFilter}`;
          }
        } else {
          subFilter.selectedFilters.forEach((selected) => {
            const value = `${filter}__${subFilter.name}__${selected}`;
            if (selected === 'Other') {
              accumulator[`${selected}: ${subFilter.name}`] = value;
            } else if (subFilter.name === 'CMS') {
              accumulator[`${subFilter.name} - ${selected}`] = value;
            } else {
              accumulator[selected] = value;
            }
          });
        }
      });
    }

    return accumulator;
  }, {});

  return pillValues;
};

export default formatPillValues;
