import React, { useEffect, useState, useReducer } from 'react'
import { useQuery } from '@apollo/client'

import { permissionEnum, hasPermission } from '@charter/distillery-rules'
import { FilterSearch, SortableTable } from '@kite/react-kite-plus'
import { KiteLoader, KiteButton, KitePagination } from '@kite/react-kite'
import { formatUserProfileFields } from '../../shared/utilities'
import { QuerySummary } from '../../components'
import { GET_ALL_AUDIENCES } from './queries/getAllAudiences'
import copyContent from './data/copyContent'
import './AudienceManagement.scss'

const PAGE_LENGTH = 5

export function AudienceManagement({
  location: { pathname: path },
  onNavigate,
  applicationPermissions,
  history,
}) {
  const initAudienceState = {
    filteredAudiences: [],
    initialAudiences: [],
    searchValue: '',
    currentPage: 1,
    pageNumbers: 1,
  }
  const { loading, error, data } = useQuery(GET_ALL_AUDIENCES, {
    fetchPolicy: 'network-only',
  })
  const reducer = (state, action) => {
    if (action.type === 'setInitAudience') {
      const firstSlice = (state.currentPage - 1) * 5
      const lastSlice = state.currentPage * 5
      const pageNum =
        action.state.audiences.length % PAGE_LENGTH === 0
          ? action.state.audiences.length / PAGE_LENGTH
          : Math.trunc(action.state.audiences.length / PAGE_LENGTH) + 1

      return {
        initialAudiences: action.state.audiences,
        filteredAudiences: action.state.audiences.slice(firstSlice, lastSlice),
        pageNumbers: pageNum,
        currentPage: state.currentPage,
      }
    } else if (action.type === 'setAudience') {
      const firstSlice = (state.currentPage - 1) * 5
      const lastSlice = state.currentPage * 5
      const pageNum =
        action.state.audiences.length % PAGE_LENGTH === 0
          ? action.state.audiences.length / PAGE_LENGTH
          : Math.trunc(action.state.audiences.length / PAGE_LENGTH) + 1
      return {
        ...state,
        filteredAudiences: action.state.audiences.slice(firstSlice, lastSlice),
        pageNumbers: pageNum,
        currentPage: state.currentPage,
      }
    } else if (action.type === 'goToNextPage') {
      const firstSlice = state.currentPage * 5
      const lastSlice = (state.currentPage + 1) * 5
      return {
        ...state,
        currentPage: state.currentPage + 1,
        filteredAudiences: state.initialAudiences.slice(firstSlice, lastSlice),
      }
    } else if (action.type === 'goToLastPage') {
      const firstSlice = (state.currentPage - 2) * 5
      const lastSlice = (state.currentPage - 1) * 5

      return {
        ...state,
        currentPage: state.currentPage - 1,
        filteredAudiences: state.initialAudiences.slice(firstSlice, lastSlice),
      }
    } else if (action.type === 'clickedNumber') {
      const firstSlice = (action.state.currentPage - 1) * 5
      const lastSlice = action.state.currentPage * 5

      return {
        ...state,
        currentPage: action.state.currentPage,
        filteredAudiences: state.initialAudiences.slice(firstSlice, lastSlice),
      }
    } else {
      return state
    }
  }
  const [audiencesState, dispatch] = useReducer(reducer, initAudienceState)
  const [selectedAudience, setSelectedAudience] = useState(null)
  const [userProfileFields, setUserProfileFields] = useState({})

  useEffect(() => {
    if (data && data.audiences) {
      dispatch({
        type: 'setInitAudience',
        state: { audiences: data.audiences },
      })
    }

    if (data && data.userProfileSchema) {
      const profileFields = formatUserProfileFields(data.userProfileSchema)
      setUserProfileFields(profileFields)
    }
  }, [data])

  const handlePageChange = action => {
    const { type } = action
    if (type === 'nextPage') {
      dispatch({
        type: 'goToNextPage',
      })
    } else if (type === 'lastPage') {
      dispatch({
        type: 'goToLastPage',
      })
    } else if (type === 'clickedNumber') {
      dispatch({
        type: 'clickedNumber',
        state: { currentPage: action.page },
      })
    }
  }

  const filterSearch = audiences => {
    dispatch({
      type: 'setAudience',
      state: {
        audiences: audiences,
      },
    })
  }

  if (loading)
    return (
      <div className="app__loader">
        <KiteLoader size="7rem" />
      </div>
    )

  if (!data || (data && !data.audiences) || error) {
    return <div>There was an issue grabbing the audiences.</div>
  }

  const {
    initialAudiences,
    filteredAudiences,
    searchValue,
    currentPage,
    pageNumbers,
  } = audiencesState

  return (
    <div className="audience-management">
      <div className="experiment-list__controls">
        <div className="experiment-list__filter">
          <FilterSearch
            label={copyContent.searchHeader}
            initialData={initialAudiences}
            filterKeys={['name', 'description']}
            placeholder={copyContent.searchPlaceholder}
            onChange={filterSearch}
            showInvalidText={false}
            value={searchValue}
            maxWidth="100%"
          />
        </div>
        <KiteButton
          disabled={
            !hasPermission({
              applicationPermissions,
              permissionId: permissionEnum.AUDIENCE_CREATE,
            })
          }
          onClick={() => {
            history.push('/audience-management/new')
          }}
        >
          {copyContent.audienceBtn}
        </KiteButton>
      </div>

      <div className="audience-management__table">
        <SortableTable
          tableData={filteredAudiences}
          loader={<KiteLoader />}
          loading={loading}
          onRowClick={setSelectedAudience}
          columns={[
            {
              label: 'Audience Name',
              sortKey: 'name',
              sortEnabled: false,
              size: 1,
            },
            {
              label: 'Description',
              sortKey: 'description',
              sortEnabled: false,
              size: 1.7,
            },
            {
              label: <div className="audience-management__page-header"></div>,
              sortKey: 'itemControls',
              sortEnabled: false,
              className: 'orm-list__edit-controls-cell',
              size: 0.6,
              render: row => (
                <div className="orm-list__edit-controls">
                  <KiteButton
                    className="detailsAndEdit"
                    key="2"
                    type="standalone-link"
                    size="small"
                    rightIcon="arrow-right-circle"
                    onClick={() => {
                      history.push(`/audience-management/${row.id}`)
                    }}
                  >
                    View
                  </KiteButton>

                  <KiteButton
                    className="orm-list__quick-view-link"
                    key="1"
                    type="standalone-link"
                    size="small"
                    style={{ marginLeft: '8px' }}
                  >
                    Details
                  </KiteButton>
                </div>
              ),
            },
          ]}
          noDataMessage={
            searchValue
              ? copyContent.noSearchValue(searchValue)
              : copyContent.noAudiences
          }
          expansionRender={row => {
            if (selectedAudience) {
              return (
                <QuickViewAudience
                  row={row}
                  userProfileFields={userProfileFields}
                />
              )
            }

            return null
          }}
        />
        <KitePagination
          currentPage={currentPage}
          onNextPage={() => {
            handlePageChange({
              type: 'nextPage',
            })
          }}
          onPageSelect={event => {
            handlePageChange({
              type: 'clickedNumber',
              page: event,
            })
          }}
          onPrevPage={() => {
            handlePageChange({
              type: 'lastPage',
            })
          }}
          totalPages={pageNumbers > 1 ? pageNumbers : null}
        />
      </div>
    </div>
  )
}

const QuickViewAudience = ({
  row: { id, name, description, rules, content },
  userProfileFields,
}) => {
  // NECESSARY CODE BELOW FOR MARKETING PROFILES TO DISPLAY PROPERLY FROM JSON LOGIC
  // 'some' needs to be in place of 'all' for jsonlogic to work properly on the
  // distillery rules engine side but 'all' needs to be in place of 'some' for
  // the rules to display properly on the query builder
  const updatedRules = rules.replaceAll('some', 'all')

  return (
    <div className="audience-management__quick-view">
      <div className="audience-management__quick-view-titles">
        Audience currently personalizing:
        <div className="audience-management__content-info">
          {content.length
            ? content.map(({ contentTitle }) => <div>{contentTitle}</div>)
            : 'Not being personalized on.'}
        </div>
      </div>
      <div className="audience-management__quick-view-divider"></div>
      <div className="audience-management__quick-view-details disable-scrollbars">
        <div className="audience-management__quick-view-titles">
          Audience Description:
        </div>
        <div className="audience-management__quick-view-values">
          {description}
        </div>
        <div className="audience-management__quick-view-titles">
          Audience Summary:
        </div>
        <QuerySummary
          rules={JSON.parse(updatedRules)}
          fields={userProfileFields}
        />
      </div>
    </div>
  )
}

export default AudienceManagement
