import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

/*
* Sample Input
*  {
    "experimentId": 159,
    "experimentMetrics": [{"metricId": 35, "isEnabled": false}]
  }
*/
const UPDATE_EXPERIMENT_TO_METRICS = gql`
  mutation updateExperimentToMetrics($input: ExperimentMetricUpdate!) {
    updateExperimentToMetrics(input: $input) {
      id
      isEnabled
      metric {
        id
        name
        displayName
      }
    }
  }
`

const updateExperimentToMetrics = graphql(UPDATE_EXPERIMENT_TO_METRICS, {
  name: 'updateExperimentToMetrics',
  withRef: true,
})

export default updateExperimentToMetrics
