export default function formatSupportRequestBody (form) {
    return Object.entries(form).reduce(
        (accumulator, [key,value]) => {
            return `${accumulator}${camelCaseToSpaces(key)}:\n${value}\n\n`
        },
        ''
      )
}

function camelCaseToSpaces(text) {
    text = text.replace(
      /([A-Z](?=[A-Z][a-z])|[^A-Z](?=[A-Z])|[a-zA-Z](?=[^a-zA-Z]))/g,
      '$1 '
    )
    text = text.charAt(0).toUpperCase() + text.slice(1)
    return text
  }