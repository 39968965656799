import React, { useState } from 'react'
import { string, func, object, bool } from 'prop-types'
import { KiteIcon, KiteLoader, KiteInput } from '@kite/react-kite'
import DialogContainer from '../DialogContainer/DialogContainer'
import Button from '../Button/Button'

import './Modal.scss'

const Modal = ({
  message,
  subMessage,
  usePreInSubMessage,
  onConfirm,
  showLoader,
  onDeny,
  buttonProps,
  loading,
  errorMessage,
  className,
  onClose,
}) => {
  const [validateValue, setValidateValue] = useState('')
  const [error, setError] = useState(null)
  const confirmButtonValue =
    (buttonProps && buttonProps.confirm && buttonProps.confirm.value) ||
    'Delete'
  const confirmButtonType =
    (buttonProps && buttonProps.confirm && buttonProps.confirm.type) ||
    'primary'
  const denyButtonValue =
    (buttonProps && buttonProps.deny && buttonProps.deny.value) || 'Never mind'
  const denyButtonType =
    (buttonProps && buttonProps.deny && buttonProps.deny.type) || 'outline'
  const denyButtonClassName =
    (buttonProps && buttonProps.deny && buttonProps.deny.className) || ''
  const validateDeny =
    (buttonProps && buttonProps.deny && buttonProps.deny.validate) || null
  const validateConfirm =
    (buttonProps && buttonProps.confirm && buttonProps.confirm.validate) || null
  let modalClass = 'modal-dialog-container'

  if (className) {
    modalClass += ` ${className}`
  }

  const handleChange = ({ target: { value } }) => {
    setError(null)
    setValidateValue(value)
  }

  return (
    <DialogContainer>
      <div className={modalClass}>
        {onClose && (
          <KiteIcon size="14px" name="x" color="#000" onClick={onClose} />
        )}
        {message && (
          <span className={`message ${subMessage ? 'with-sub-message' : ''}`}>
            {message}
          </span>
        )}
        {subMessage && (
          <span
            style={usePreInSubMessage ? { whiteSpace: 'pre' } : null}
            className="sub-message"
          >
            {subMessage}
          </span>
        )}
        {(validateDeny || validateConfirm) && (
          <KiteInput
            className="modal-dialog-container__validate-input"
            disabled={false}
            errorId=""
            errorMessage={error}
            id="validate"
            inputProps={null}
            label="Acknowledge irregular sampling rate"
            max="436px"
            maxWidth="436px"
            min=""
            name="validate"
            onChange={handleChange}
            type="text"
            value={validateValue}
          />
        )}
        <div className="button-wrapper">
          {showLoader && <KiteLoader size="7rem" />}
          {onDeny && (
            <Button
              className={`modal__deny-button ${denyButtonClassName}`}
              type={denyButtonType}
              onClick={() => {
                if (validateDeny && validateDeny !== validateValue) {
                  setError('Please enter correct validation to continue')
                  return false
                }
                onDeny()
              }}
              value={denyButtonValue}
              disabled={loading}
            />
          )}
          {onConfirm && (
            <Button
              className="modal__confirm-button"
              type={confirmButtonType}
              onClick={() => {
                if (validateConfirm && validateConfirm !== validateValue) {
                  setError('Please enter correct validation to continue')
                  return false
                }
                onConfirm()
              }}
              value={confirmButtonValue}
              loading={loading}
            />
          )}
        </div>
        {errorMessage && <span className="error-message">{errorMessage}</span>}
      </div>
    </DialogContainer>
  )
}

export default Modal

Modal.propTypes = {
  message: string.isRequired,
  onConfirm: func,
  onDeny: func,
  usePreInSubMessage: bool,
  subMessage: string,
  buttonProps: object,
  loading: bool,
  showLoader: bool,
  errorMessage: string,
  className: string,
}

Modal.defaultProps = {
  onDeny: null,
  onConfirm: null,
  subMessage: null,
  buttonProps: null,
  loading: false,
  showLoader: false,
  errorMessage: null,
  className: null,
  usePreInSubMessage: false,
}
