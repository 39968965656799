import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

/*
* Sample Input
* {
   "input": {
	 		"ids": [466]
  }
}
*/
const DELETE_EXPERIMENT_TO_USER = gql`
  mutation deleteExperimentToUser($input: DeleteExperimentToUserEntry!) {
    deleteExperimentToUser(input: $input)
  }
`

const deleteExperimentToUser = graphql(DELETE_EXPERIMENT_TO_USER, {
  name: 'deleteExperimentToUser',
  withRef: true,
})

export default deleteExperimentToUser
