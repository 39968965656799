import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

export const GET_EXPERIMENT_ACTIVATION = gql`
  query getExperimentActivation($id: Int!) {
    experiment(id: $id) {
      id
      activationEventUuid
      activationEvent {
        id
        name
        yaml
      }
      experimentToProductFeatures {
        id
        product {
          id
          displayName
          quantumApplicationName
          quantumApplicationType
        }
      }
    }
    activationEvents(experimentId: $id) {
      id
      name
      yaml
    }
    TdcsExperimentSettings(experimentId: $id) {
      tdcsSessionType {
        name
      }
    }
  }
`

const getExperimentActivation = graphql(GET_EXPERIMENT_ACTIVATION, {
  name: 'experimentActivationData',
  withRef: true,
  options: {
    fetchPolicy: 'network-only',
  },
})

export default getExperimentActivation
