import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

export const GET_EXPERIMENT_TDCS_INFORM_TYPES = gql`
  query {
    tdcsInformCallTypes {
      id
      name
      displayName
    }
  }
`

const getExperimentTDCSInformTypes = graphql(GET_EXPERIMENT_TDCS_INFORM_TYPES, {
  name: 'experimentTDCSInformTypes',
  withRef: true,
})

export default getExperimentTDCSInformTypes
