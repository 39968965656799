import {
  decorate,
  observable,
  computed,
  action,
  transaction,
  runInAction,
} from 'mobx'

import { client } from '../../configuration/configApiClient'
import appModel from '../../components/App/AppModel'

class EditorModel {
  start() {}

  startEditing() {
    if (this.wasCreated) this.wasCreated = null
    this.startObject = this.updatedAPIObject
    this.apiError = null
    this.saveAttemptedAt = null
    this.done = false
    this.savedAt = null
    this.showExitModal = false
  }

  apiError = null
  wasCreated = null
  saveAttemptedAt = null
  savedAt = null
  isSaving = false
  isDeleting = false
  showExitModal = false
  startObject = null
  apiObject = null
  apiTime = null

  updateFromAPI({ listModel, apiObject }) {
    //implement in subclasses
  }

  onChange = ({ target: { value, name } }) => {
    this[name] = value
  }

  get errorMessages() {
    // implement in subclasses
    return {}
  }

  toAPI = () => {
    return {}
  }

  get updatedAPIObject() {
    return this.toAPI()
  }

  get isDirty() {
    const newState = JSON.stringify(this.updatedAPIObject)
    const oldState = JSON.stringify(this.startObject)
    return newState !== oldState
  }

  get wasSaved() {
    return this.done && this.savedAt
  }

  get displayName() {
    return this.constructor.displayName ?? this.constructor.typeName
  }

  standardKiteAlerts() {
    return this.apiError
      ? {
          type: 'alert',
          description: `There was an error saving your ${this.displayName}.  Distillery support has been notified`,
        }
      : this.wasSaved
      ? {
          type: 'confirm',
          description: `Successfully ${
            this.isDeleting ? 'deleted' : this.wasCreated ? 'created' : 'edited'
          } ${this.displayName}`,
        }
      : null
  }

  get kiteAlert() {
    return this.standardKiteAlerts()
  }

  get isSaveDisabled() {
    if (this.shouldDisableSave) return this.shouldDisableSave()
    return !this.isDirty
  }

  get shouldShowErrorMessage() {
    const answer = this.saveAttemptedAt !== null || this.overrideAlreadyExists
    return answer
  }

  errorMessage(name) {
    return this.shouldShowErrorMessage ? this.errorMessages[name] : null
  }

  get isValid() {
    const inValidItem = Object.values(this.errorMessages).find(v => v !== null)
    return inValidItem === undefined
  }

  save = async () => {
    runInAction(() => {
      this.apiError = null
      this.saveAttemptedAt = new Date()
    })

    if (!this.isValid && !this.isDeleting) {
      return
    }

    this.isSaving = true
    if (this.preSave) await this.preSave()
    await this.sendMutation()
    if (!this.apiError && this.postSave) await this.postSave()
    this.isSaving = false
    if (!this.apiError) {
      transaction(() => {
        this.savedAt = new Date()
        this.finish()
      })
    }
  }

  exit = () => {
    if (this.isDirty) {
      this.showExitModal = true
    } else {
      // clear edits if any
      this.clearAndFinish()
    }
  }

  cancelExit = () => {
    this.showExitModal = false
  }

  clearAndFinish = () => {
    if (this.id) appModel.updateFromCache(this)
    this.finish()
  }

  finish() {
    this.searchDisposer && this.searchDisposer()
    this.done = true
  }

  get cacheKey() {
    return `${this.constructor.typeName}:${this.id}`
  }

  get mutation() {
    return this.isDeleting
      ? this.deleteMutation
      : this.id
      ? this.updateMutation
      : this.insertMutation
  }

  get variables() {
    return this.isDeleting
      ? this.deleteMutationVariables
        ? this.deleteMutationVariables
        : { id: this.id }
      : { input: this.updatedAPIObject }
  }

  async sendMutation() {
    try {
      this.apiError = null

      const res = await client.mutate({
        mutation: this.mutation,
        variables: this.variables,
      })
      if (res.errors && res.errors.length) {
        this.apiError = res.errors[0]
        return
      }
      if (!this.id) this.wasCreated = new Date()
      if (res.data && !this.id) this.id = Object.values(res.data)[0].id
    } catch (error) {
      console.log(error)
      this.apiError = error
    }
  }
}

EditorModel = decorate(EditorModel, {
  done: observable,
  apiError: observable,
  saveAttemptedAt: observable,
  savedAt: observable,
  isSaving: observable,
  updateFromAPI: action,
  shouldShowErrorMessage: computed,
  errorMessages: computed,
  isValid: computed,
  kiteAlert: computed,
  isSaveDisabled: computed,
  wasCreated: observable,
  showExitModal: observable,
  cancelExit: action,
  finish: action,
  mutation: computed,
  wasSaved: computed,
  startObject: observable.ref,
  updatedAPIObject: computed({ keepAlive: true }),
  apiTime: observable.ref,
  isDeleting: observable,
})

export default EditorModel
