/* eslint-disable */
import * as React from 'react';
import classNames from 'classnames';

import KiteTooltip from '@kite/react-kite';
import StackedRadioButton from './StackedRadioButton';
import { oneWord } from './oneWord';

import './StackedRadio.scss';

/**
 * A controlled radio input. The `onChange` function should update the `checked` prop.  [View the design specifications](https://company-14496.frontify.com/d/xETwq0XBaQWU/kite-web-ui-guidelines#/components/selection-controls)
 */
const StackedRadio = ({
  radioButtons,
  name,
  onChange,
  disabled,
  className,
  groupLabel,
  buttonOrientation,
  tooltip,
  margin,
}) => {
  const anyOthersChecked = radioButtons.slice(1).some(btn => btn.checked);
  const displayRadioButtons = radioButtons.map(
    ({ id, value, checked, label, subtext, disabled: buttonDisabled }, i) => {
      const isFirstButton = i === 0;

      const className = classNames({
        ['stacked-radio']: true,
        ['stacked-radio__selected']: checked,
      });
      return (
        <React.Fragment key={id || label}>
          <div className={className}>
            <div className="stacked-radio__selected-indicator"></div>
            <div className="stacked-radio__wrapper">
              <StackedRadioButton
                id={id || oneWord(value) || oneWord(label)}
                label={label}
                name={name}
                buttonOrientation={buttonOrientation}
                value={value}
                checked={checked}
                onChange={onChange}
                disabled={disabled || buttonDisabled}
                anyOthersChecked={anyOthersChecked}
                subtext={subtext}
                isFirstButton={isFirstButton}
              />
            </div>
          </div>
        </React.Fragment>
      );
    }
  );

  let styles = {};
  styles = margin ? { ...styles, margin } : styles;

  return (
    <div
      className={`kite-radio kite-form-group ${className}`}
      style={styles}
      role="group"
      aria-labelledby={oneWord(groupLabel || name)}
    >
      {groupLabel && (
        <div className="kite-radio__group-label">
          <span id={oneWord(groupLabel || name)}>{groupLabel}</span>
          {tooltip && (
            <KiteTooltip ariaLabel={`${groupLabel} tooltip`}>
              {tooltip}
            </KiteTooltip>
          )}
        </div>
      )}

      <div
        className="kite-radio__button-container"
        style={buttonOrientation === 'row' ? { flexDirection: 'row' } : {}}
      >
        {displayRadioButtons}
      </div>
    </div>
  );
};

StackedRadio.defaultProps = {
  groupLabel: '',
  disabled: false,
  className: '',
  label: '',
  buttonOrientation: 'column',
  radioButtons: [],
  tooltip: '',
  margin: '',
};

export default StackedRadio;
