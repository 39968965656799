import React, { Component } from 'react'
import dayjs from 'dayjs'
import { gql } from '@apollo/client'
import { object, func, string, array, bool } from 'prop-types'
import flowRight from 'lodash/flowRight'
import classNames from 'classnames'
import { KiteLoader, KiteModal } from '@kite/react-kite'
import { FilterSearch, BasicPills, SortableTable } from '@kite/react-kite-plus'
import {
  experimentEnvironmentEnum,
  isFeatureFlagActive,
  featureFlagEnum,
} from '@charter/distillery-rules'

import { client } from '../../configuration/configApiClient'
import {
  GET_PRODUCTS_GQLBUILDER,
  getExperiments,
  getFeatures,
  GET_ACTIVE_LOCKDOWNS,
} from '../../shared/queries'
import { LOG_ERROR, UPDATE_USER_CONFIG } from '../../shared/mutations'

import {
  defaultSort,
  formatFilterOptions,
  formatLoggingError,
  formatPillValues,
  formatSetUpSteps,
  removeFilterOption,
  GqlBuilder,
} from '../../shared/utilities'

import applyUserFilters from './utilities/applyUserFilters'
import { FilterControls, Modal } from '../../componentLibrary'
import { ExperimentDetails, CreateDraftButton } from '../../components'
import { intialSetUpStepsCategories } from '../../shared/data/initialSetUpSteps'
import copyContent from './data/copyContent'
import './ExperimentList.scss'

const gqlHOCQuery = new GqlBuilder().compileHOCQuery([
  getExperiments,
  GET_PRODUCTS_GQLBUILDER,
  getFeatures,
])

export class ExperimentList extends Component {
  state = {
    currentSearchValue: null,
    searchedExperiments: null,
    selectedExperiment: null,
    errorMessage: {
      applyFilters: null,
    },
    isViewedDeletedModalOpen: false,
    isNetworkLockdownActive: false,
    isNextExperimentModalOpen: false,
  }

  static propTypes = {
    history: object.isRequired,
    location: object.isRequired,
    user: object.isRequired,
    applicationPermissions: array.isRequired,
    onNavigate: func.isRequired,
    onSortClick: func.isRequired,
    sortHeader: string.isRequired,
    reverseSort: bool.isRequired,
    currentlyViewing: object.isRequired,
    queryData: object.isRequired,
    statusFilter: string,
    networkLockdownPermission: bool,
  }

  static defaultProps = {
    statusFilter: null,
  }

  // LIFECYCLE METHODS
  async componentDidMount() {
    const {
      location: { pathname: path },
      statusFilter,
      onNavigate,
      currentlyViewing: { backPath },
      networkLockdownPermission,
    } = this.props

    // only check for active lockdowns if user does not have network lockdown permissions
    if (!networkLockdownPermission) {
      await this.isLockdownActive()
    }

    if (backPath.includes('deleted')) {
      this.handleCheckDeletedExperiment()
    }

    const title = `${statusFilter} Experiments`

    onNavigate({
      path,
      title,
    })
  }

  componentDidUpdate(prevProps) {
    const { selectedExperiment } = this.state
    const { statusFilter } = this.props
    const { statusFilter: prevFilter } = prevProps

    if (selectedExperiment && statusFilter !== prevFilter) {
      this.handleClearSelectedExperiment()
    }
  }

  isLockdownActive = async () => {
    const res = await client.query({
      query: GET_ACTIVE_LOCKDOWNS,
    })

    if (
      res.data &&
      res.data.activeNetworkLockdowns &&
      res.data.activeNetworkLockdowns.length
    ) {
      await this.setState({ isNetworkLockdownActive: true })
    }
  }

  // LOCAL STATE CHANGE/TOGGLE METHODS
  handleResetFiltersError = () => {
    const { errorMessage } = this.state

    this.setState({ errorMessage: { ...errorMessage, applyFilters: null } })
  }

  handleCheckDeletedExperiment = () => {
    this.setState(prevState => ({
      isViewedDeletedModalOpen: !prevState.isViewedDeletedModalOpen,
    }))
  }

  handleNextExperimentModal = () => {
    this.setState(prevState => ({
      isNextExperimentModalOpen: !prevState.isNextExperimentModalOpen,
    }))
  }

  handleSearchExperiments = async (filteredExperiments, newSearchValue) => {
    const { sortHeader, reverseSort } = this.props
    const { currentSearchValue } = this.state

    if (newSearchValue < currentSearchValue) {
      newSearchValue = ''
    }
    this.setState({
      currentSearchValue: newSearchValue || null,
      searchedExperiments: newSearchValue
        ? defaultSort(filteredExperiments, sortHeader, reverseSort)
        : null,
    })
  }

  handleSelectExperiment = selectedExperiment => {
    this.setState({
      selectedExperiment,
    })
  }

  handleClearSelectedExperiment = () => {
    const { selectedExperiment } = this.state
    const { activeRowIndex } = this.table.state

    this.table.handleRowClick(selectedExperiment, activeRowIndex)
  }

  handleOpenExperiment = () => {
    const { history } = this.props
    const {
      selectedExperiment: { id, activeStep, isSetUpComplete, isNextExperiment },
    } = this.state

    if (isNextExperiment) {
      this.handleNextExperimentModal()
    } else {
      const baseUrl = `/experiments/${id}`
      const redirect = isSetUpComplete
        ? `${baseUrl}/rollout/overrides`
        : `${baseUrl}/set-up/${activeStep}`

      history.push(redirect)
    }
  }

  // FORMATTING METHODS
  handleFilterExperiments = (experiments, config) => {
    const { statusFilter } = this.props

    const userFilters =
      config && config.filters
        ? config.filters
        : {
            product: [],
            feature: [],
            experimenter: [],
            types: [],
          }

    const filteredExperiments = applyUserFilters(
      experiments,
      userFilters,
      statusFilter
    )

    return filteredExperiments
  }

  handleFormatExperiments = experiments =>
    experiments.map(experiment => {
      const {
        id,
        uuid,
        name,
        durationNumDays,
        startTime,
        stopTime,
        config,
        experimentToUsers,
        experimentToProductFeatures,
        environmentSamplings,
        permissions,
        notifications,
        experimentType,
        variants,
        isCmsExperiment,
        experimentStatus,
        exclusive,
        experimentMetrics,
        experimentSetupTemplateId,
      } = experiment

      const { PRODUCTION } = experimentEnvironmentEnum
      const { setUpSteps: steps } = isCmsExperiment
        ? intialSetUpStepsCategories.cms
        : intialSetUpStepsCategories.traditional
      const { activeStep, setUpSteps, isSetUpComplete } = formatSetUpSteps(
        JSON.parse(config),
        steps
      )

      let isNextExperiment = false
      if (experimentSetupTemplateId || !setUpSteps?.length) {
        isNextExperiment = true
      }

      const hasUnreadNotifications = notifications
        .filter(notification => notification.environment.id === PRODUCTION)
        .find(notification => !notification.isRead)

      const experimenter = experimentToUsers.find(user => user.roleId === 2)
        .user.displayName
      const publishedTime = environmentSamplings
        .filter(sampling => sampling.lastPublishRequestTime)
        .map(sampling => sampling.lastPublishRequestTime)
        .sort((aSampling, bSampling) => (aSampling > bSampling ? 1 : -1))[0]
      const productionEnvironmentStatus =
        experimentStatus && experimentStatus.name.toLowerCase()

      const variantUuids = variants
        ? variants.map(({ uuid: variantUuid }) => variantUuid).join('')
        : ''

      return {
        id,
        uuid,
        name,
        durationNumDays,
        variants,
        variantUuids,
        startTime,
        stopTime,
        publishedTime,
        experimenter,
        experimentToUsers,
        notifications,
        isCmsExperiment,
        exclusive,
        environmentSamplings,
        permissions,
        activeStep,
        setUpSteps,
        isSetUpComplete,
        hasUnreadNotifications,
        type: experimentType.name,
        config: config ? JSON.parse(config) : null,
        status: productionEnvironmentStatus,
        product: experimentToProductFeatures[0].product.displayName,
        feature: experimentToProductFeatures[0]?.productFeature?.displayName,
        startDate: dayjs(startTime).isValid()
          ? dayjs(startTime).format('MM/DD/YY')
          : '-',
        stopDate: dayjs(stopTime).isValid()
          ? dayjs(stopTime).format('MM/DD/YY')
          : '-',
        publishedDate: dayjs(publishedTime).isValid()
          ? dayjs(publishedTime).format('MM/DD/YY')
          : '-',
        experimentMetrics,
        isNextExperiment,
      }
    })

  handleFormatTableColumns = () => {
    const { statusFilter } = this.props
    const notificationIndicator = {
      sortKey: '',
      label: '\xa0',
      sortEnabled: false,
      size: 0.1,
      render: item => {
        const indicatorClassNames = classNames({
          'experiment-list__read-indicator': true,
          'experiment-list__read-indicator-read': !item.hasUnreadNotifications,
        })

        return <div className={indicatorClassNames} />
      },
    }

    const baseColumns = [
      {
        sortKey: 'name',
        label: 'Name',
        size: 1.3,
      },
      {
        sortKey: 'product',
        label: 'Product',
        size: 0.8,
      },
      {
        sortKey: 'feature',
        label: 'Feature',
        size: 0.8,
      },
      {
        sortKey: 'experimenter',
        label: 'Experimenter',
      },
    ]

    const columnObject = {
      draft: baseColumns,
      testing: [
        notificationIndicator,
        ...baseColumns,
        { sortKey: 'publishedDate', label: 'Published', size: 0.75 },
      ],
      running: [
        notificationIndicator,
        ...baseColumns,
        {
          sortKey: 'durationNumDays',
          sortEnabled: false,
          label: 'Days Run/Planned',
          render: item =>
            item.startTime ? this.renderDaysRunPlanned(item) : '-',
        },
        { sortKey: 'startDate', label: 'Launched', size: 0.75 },
      ],
      cancelled: [
        ...baseColumns,
        {
          sortKey: 'durationNumDays',
          sortEnabled: false,
          label: 'Days Run/Planned',
          size: 1.25,
          render: item =>
            item.startTime ? this.renderDaysRunPlanned(item) : '-',
        },
        { sortKey: 'startDate', label: 'Launched', size: 0.75 },
        { sortKey: 'stopDate', label: 'Cancelled', size: 0.75 },
      ],
      observational: [
        notificationIndicator,
        ...baseColumns,
        {
          sortKey: 'durationNumDays',
          sortEnabled: false,
          label: 'Days Run/Planned',
          render: item =>
            item.startTime ? this.renderDaysRunPlanned(item) : '-',
        },
        { sortKey: 'startDate', label: 'Launched', size: 0.75 },
        { sortKey: 'stopDate', label: 'Completed', size: 0.75 },
      ],
      completed: [
        ...baseColumns,
        {
          sortKey: 'durationNumDays',
          sortEnabled: false,
          label: 'Days Run/Planned',
          size: 1.25,
          render: item =>
            item.startTime ? this.renderDaysRunPlanned(item) : '-',
        },
        { sortKey: 'startDate', label: 'Launched', size: 0.75 },
        { sortKey: 'stopDate', label: 'Completed', size: 0.75 },
      ],
      all: [
        notificationIndicator,
        ...baseColumns,
        {
          sortKey: 'durationNumDays',
          sortEnabled: false,
          label: 'Days Run/Planned',
          size: 1.25,
          render: item =>
            item.startTime ? this.renderDaysRunPlanned(item) : '-',
        },
        {
          sortKey: 'status',
          label: 'Status',
          render: ({ status }) => (
            <span
              className={`experiment-list__item-status experiment-list__item-status-${status}`}
            >
              {status}
            </span>
          ),
        },
      ],
    }

    return columnObject[statusFilter]
  }

  // API METHODS
  handleApplyFilters = async userFilters => {
    const { errorMessage } = this.state
    const { user } = this.props

    try {
      const cleanedFilters = Object.keys(userFilters).reduce(
        (accumulator, filter) => {
          if (typeof userFilters[filter][0] === 'object') {
            const strippedFilter = userFilters[filter].filter(
              subFilter => subFilter.selectedFilters.length > 0
            )

            accumulator[filter] = strippedFilter
          } else {
            accumulator[filter] = userFilters[filter]
          }

          return accumulator
        },
        {}
      )
      const existingConfig = JSON.parse(user.config)

      const config = JSON.stringify({
        ...existingConfig,
        filters: cleanedFilters,
      })
      const input = {
        userId: user.id,
        config,
      }

      await client.mutate({
        mutation: UPDATE_USER_CONFIG,
        variables: {
          input,
        },
      })

      if (window.dxLoadCurrentUser) {
        await window.dxLoadCurrentUser()
      }

      return true
    } catch (error) {
      const input = formatLoggingError(error)

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input,
        },
      })

      this.setState({
        errorMessage: {
          ...errorMessage,
          applyFilters:
            'We were unable to save your filters. Please try again.',
        },
      })

      return false
    }
  }

  handleRemoveFilter = async filter => {
    const {
      user: { config },
    } = this.props

    const { filters } = JSON.parse(config)

    const newFilters = removeFilterOption(filter, filters)

    await this.handleApplyFilters(newFilters)
  }

  handleClearAllFilters = async () => {
    const clearedFilters = {
      product: [],
      feature: [],
      experimenter: [],
      types: [],
    }

    await this.handleApplyFilters(clearedFilters)
  }

  // RENDER METHODS
  renderDaysRunPlanned = ({ startTime, stopTime, durationNumDays }) => {
    if (!startTime) {
      return '-'
    }
    const DATE_FORMAT = 'YYYY-MM-DD'

    const stopDate = stopTime
      ? dayjs(stopTime).format(DATE_FORMAT)
      : dayjs().format(DATE_FORMAT)
    const startDate = dayjs(startTime).format(DATE_FORMAT)

    const startDateObj = dayjs(startDate, DATE_FORMAT)
    const stopDateObj = dayjs(stopDate, DATE_FORMAT)

    const timeComplete = stopDateObj.diff(startDateObj, 'day')
    const overtime = Math.floor(timeComplete) > durationNumDays

    return (
      <span className="experiment-list__days-run-container">
        <span
          className={`experiment-list__days-run ${
            overtime ? 'experiment-list__overtime' : ''
          }`}
        >
          {Math.floor(timeComplete)}
        </span>
        {`/${Math.floor(durationNumDays)}`}
      </span>
    )
  }

  render() {
    const {
      searchedExperiments,
      selectedExperiment,
      errorMessage,
      currentSearchValue,
      isViewedDeletedModalOpen,
      isNetworkLockdownActive,
      isNextExperimentModalOpen,
    } = this.state

    const {
      user,
      userRoles,
      history,
      statusFilter,
      sortHeader,
      reverseSort,
      onSortClick,
      onNavigate,
      applicationPermissions,
      queryData: {
        loading: experimentsLoading,
        experiments,
        productCategoryTypes,
        featureTypes,
        experimentCategories,
      },
    } = this.props

    if (!experimentsLoading) {
      const { featureFlags } = client.readQuery({
        query: gql`
          query getCurrentUser {
            featureFlags {
              id
              name
              active
              lastModifiedTime
            }
          }
        `,
      })

      const isMEOn = isFeatureFlagActive({
        featureFlags: featureFlags,
        featureFlagId: featureFlagEnum.EXCLUSIVE_EXPERIMENTS,
        applicationPermissions,
      })
      const filterOptions = formatFilterOptions({
        productCategoryTypes,
        featureTypes,
        experimentsByExperimenter: experiments,
        experimentsByType: experiments,
        isMEOn,
      })

      const config = JSON.parse(user.config)
      const cleanedExperiments = this.handleFormatExperiments(experiments || [])
      const filteredExperiments = defaultSort(
        this.handleFilterExperiments(cleanedExperiments, config),
        sortHeader,
        reverseSort
      )
      const { filterSearchPlaceholder, noExperiments, noSearchResults } =
        copyContent

      const initData = searchedExperiments
        ? searchedExperiments
        : filteredExperiments

      return (
        <div className="experiment-list">
          <div className="experiment-list__controls">
            <div className="experiment-list__filter">
              <FilterSearch
                initialData={initData}
                filterKeys={[
                  'name',
                  'experimenter',
                  'product',
                  'feature',
                  'uuid',
                  'variantUuids',
                ]}
                placeholder={filterSearchPlaceholder}
                onChange={this.handleSearchExperiments}
                showInvalidText={false}
                maxWidth="100%"
                ref={element => {
                  this.search = element
                }}
              />

              <FilterControls
                filterCategory="Experiments"
                filterOptions={filterOptions}
                selectedFilters={config ? config.filters : {}}
                user={user.displayName}
                userFields={['experimenter']}
                onApplyFilters={this.handleApplyFilters}
                errorMessage={errorMessage.applyFilters}
                onClearError={this.handleResetFiltersError}
              />
            </div>
            <CreateDraftButton
              applicationPermissions={applicationPermissions}
              history={history}
              experimentCategories={experimentCategories}
              userRoles={userRoles}
            />
          </div>

          <BasicPills
            values={
              config && config.filters
                ? formatPillValues(config.filters, user.displayName)
                : []
            }
            onClearAll={this.handleClearAllFilters}
            onRemovePill={this.handleRemoveFilter}
          />

          <div className="experiment-list__table">
            {initData && (
              <div className="experiment-list__count">
                {searchedExperiments
                  ? searchedExperiments.length
                  : filteredExperiments.length}{' '}
                experiments
              </div>
            )}
            <SortableTable
              key={`sortable-${currentSearchValue || ''}-${
                statusFilter || ''
              }-${sortHeader || ''}-${
                config && config.filters ? JSON.stringify(config.filters) : ''
              }`}
              tableData={initData}
              sortHeader={sortHeader}
              reverseSort={reverseSort}
              loader={<KiteLoader />}
              loading={experimentsLoading}
              onSortClick={event => {
                onSortClick(event)
                if (currentSearchValue) {
                  const newReverse = !reverseSort
                  defaultSort(initData, event, newReverse)
                }
              }}
              onRowClick={this.handleSelectExperiment}
              columns={this.handleFormatTableColumns()}
              noDataMessage={
                currentSearchValue
                  ? noSearchResults(currentSearchValue)
                  : noExperiments
              }
              ref={element => {
                this.table = element
              }}
              expansionRender={row => {
                if (selectedExperiment && row.id === selectedExperiment.id) {
                  return (
                    <ExperimentDetails
                      user={user}
                      id={selectedExperiment.id}
                      experiment={selectedExperiment}
                      applicationPermissions={applicationPermissions}
                      userRoles={userRoles}
                      history={history}
                      onNavigate={onNavigate}
                      onOpenExperiment={this.handleOpenExperiment}
                      onSuccess={this.handleClearSelectedExperiment}
                      activeNetworkLockdown={isNetworkLockdownActive}
                      handleNextExperiments={this.handleNextExperimentModal}
                    />
                  )
                }
                return null
              }}
            />
          </div>
          {isViewedDeletedModalOpen && (
            <Modal
              message="Deleted Experiment"
              subMessage="Looks like that link was out of date and led you to a deleted experiment. We will take you back to the dashboard to find current experiments."
              onDeny={this.handleCheckDeletedExperiment}
              className="experiment-list__delete-experiment-modal"
              buttonProps={{
                deny: {
                  value: 'Ok ',
                  className: 'experiment-list__delete-experiment-btn',
                },
              }}
            />
          )}
          <KiteModal
            title="Distillery Next Experiment"
            canShow={isNextExperimentModalOpen}
            onHide={this.handleNextExperimentModal}
          >
            <p>
              This experiment was created in Distillery Next. Please navigate to
              the{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://distillery-next.spectrumtoolbox.com/experiment/${selectedExperiment?.id}/set-up/details-&-roles`}
              >
                experiment
              </a>{' '}
              in Distillery Next to view and/or edit this experiment.
            </p>
          </KiteModal>
        </div>
      )
    }

    return (
      <div className="app__loader">
        <KiteLoader size="7rem" />
      </div>
    )
  }
}

export default flowRight(gqlHOCQuery)(ExperimentList)
