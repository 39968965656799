import React, { Component } from 'react'
import isEqual from 'lodash/isEqual'
import { FilterSearch } from '@kite/react-kite-plus'
import { arrayOf, func, object, oneOfType, string } from 'prop-types'

import './ListBox.scss'

class ListBox extends Component {
  static propTypes = {
    getClickedItem: func.isRequired,
    values: oneOfType([arrayOf(object), arrayOf(string)]).isRequired,
    selectedOptions: oneOfType([arrayOf(object), arrayOf(string)]),
    title: string,
  }

  static defaultProps = {
    selectedOptions: [],
    title: '',
  }

  state = {
    filterSearchValue: '',
    searchedData: [],
    selectedOptions: [],
    getClickedItem: null,
    title: '',
  }

  componentDidMount() {
    this.handleSetInitialState()
  }

  componentDidUpdate(prevProps) {
    const { selectedOptions, values } = this.props

    if (
      !isEqual(selectedOptions, prevProps.selectedOptions) ||
      !isEqual(values, prevProps.values)
    ) {
      this.handleUpdateValues(values)
      this.handleUpdateSelectedOptions(selectedOptions)
    }
  }

  handleSetInitialState = () => {
    const { selectedOptions, values, getClickedItem, title } = this.props

    const loaded = selectedOptions && values && title

    if (loaded) {
      const sortedValues =
        typeof values[0] === 'string'
          ? this.sortArrayStrings(
              values.map(value => ({ id: value, name: value }))
            )
          : this.sortArrayObjects([...values])

      this.setState({
        selectedOptions,
        getClickedItem,
        title,
        searchedData: sortedValues,
      })
    }
  }

  handleUpdateValues = newValues => {
    const sortedValues =
      typeof newValues[0] === 'string'
        ? this.sortArrayStrings(
            newValues.map(value => ({ id: value, name: value }))
          )
        : this.sortArrayObjects([...newValues])
    return this.setState({ searchedData: sortedValues })
  }

  handleUpdateSelectedOptions = newSelectedOptions =>
    this.setState({ selectedOptions: newSelectedOptions })

  // TODO Refactor
  handleFilterChange = (searchedData, searchValue) => {
    const { values } = this.props
    const {
      filterSearchValue: prevFilterSearchValue,
      searchedData: prevSearchedData,
    } = this.state

    const sortedValues =
      typeof values[0] === 'string'
        ? this.sortArrayStrings(
            values.map(value => ({ id: value, name: value }))
          )
        : this.sortArrayObjects([...values])

    if (!isEqual(searchValue, prevFilterSearchValue)) {
      this.setState({
        filterSearchValue: searchValue,
        searchedData: sortedValues,
      })
    }

    if (!isEqual(searchedData, prevSearchedData)) {
      this.setState({ searchedData })
    }
  }

  sortArrayStrings = array =>
    array
      .slice()
      .sort((left, right) =>
        left.localeCompare(right, 'en', { sensitivity: 'base' })
      )

  sortArrayObjects = array =>
    array.slice().sort((left, right) =>
      left.name
        ? left.name.localeCompare(right.name, 'en', {
            sensitivity: 'base',
          })
        : left.displayName.localeCompare(right.displayName, 'en', {
            sensitivity: 'base',
          })
    )

  render() {
    const {
      filterSearchValue,
      searchedData,
      selectedOptions,
      getClickedItem,
      title,
    } = this.state

    return (
      <div className="list-box">
        {title && <span className="list-box__title">{title}</span>}
        {searchedData && (
          <FilterSearch
            className="list-box__filter-search"
            maxWidth="100%"
            showInvalidText={false}
            filterKeys={['displayName', 'name']}
            initialData={searchedData}
            onChange={(filteredData, searchValue) =>
              this.handleFilterChange(filteredData, searchValue)
            }
            value={filterSearchValue}
          />
        )}
        <div className="list-box__values-container">
          {searchedData.length < 1 && (
            <span className="list-box__empty-state">
              Make a selection
              <br />
              &
              <br />
              Add items
            </span>
          )}
          {searchedData &&
            searchedData.map((value, index) => (
              <span
                className={`list-box__values ${
                  selectedOptions.includes(value) ? 'selected' : ''
                }`}
                key={value.id}
                onClick={() => getClickedItem(value)}
                role="button"
                tabIndex={index}
                onKeyDown={() => getClickedItem(value)}
              >
                {value.name
                  ? value.name.toLowerCase()
                  : value.displayName.toLowerCase()}
              </span>
            ))}
        </div>
      </div>
    )
  }
}

export default ListBox
