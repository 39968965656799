import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

export const GET_EXPERIMENT_ROLLOUT_DETAILS = gql`
  query getExperimentRolloutDetails($id: Int!) {
    TDCSExperiment(experimentId: $id) {
      id
      tdcsInformCall {
        id
        overrideTypeId
      }
    }
    TdcsExperimentSettings(experimentId: $id) {
      id
      tdcsSessionType {
        id
        overrideTypeId
      }
    }
    experiment(id: $id) {
      variants {
        id
        uuid
        name
      }
      activationEvent {
        id
        name
        yaml
      }
      id
      name
      config
      isOverridesDeleted
      isCmsExperiment
      experimentStatus {
        id
        name
      }
      startTime
      experimentToProductFeatures {
        id
        productId
        product {
          id
          displayName
        }
        productFeature {
          id
          displayName
        }
      }
      environmentSamplings {
        id
        sampleAllocation
        tdcsUpdateRequired
        isDreSynced
        experimentId
        environment {
          id
          name
          order
          color
        }
        experimentStatus {
          id
          name
        }
        environmentSamplingToVariants {
          id
          weight
          variant {
            id
            name
          }
        }
      }
      permissions {
        id
        name
      }
    }
    experimentPermissions(experimentId: $id) {
      id
      name
    }
    dremExperimentSettings(experimentId: $id) {
      id
      rule
      sessionTypeId
      sessionType {
        id
        name
        displayName
        overrideTypeId
      }
    }
  }
`

const getExperimentRolloutDetails = graphql(GET_EXPERIMENT_ROLLOUT_DETAILS, {
  options: ({
    match: {
      params: { id },
    },
  }) => ({
    variables: {
      id: Number(id),
    },
  }),
  name: 'rolloutData',
})

export default getExperimentRolloutDetails
