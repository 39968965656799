import React from 'react'
import { array, object, string, bool } from 'prop-types'
import { experimentStatusEnum } from '@charter/distillery-rules'
import { KiteAlert } from '@kite/react-kite'

import { capitalizeString } from '../../shared/utilities'
import { ExpansionPanel } from '../../componentLibrary'
import copyContent from './data/copyContent'

const { TESTING, RUNNING } = experimentStatusEnum

const TdcsUpdateNotification = ({
  id,
  environmentSampling,
  history,
  linkHidden,
  isCmsExperiment = false,
  activeNetworkLockdown,
}) => {
  const {
    tdcsUpdateTitle,
    tdcsUpdateDescription,
    tdcsUpdateLinkText,
    dreUpdateLinkText,
    dreUpdateDescription,
    dreUpdateTitle,
  } = copyContent

  let redirectEnvironmentId

  const environmentNames = environmentSampling
    .filter(
      ({ experimentStatus, environment, tdcsUpdateRequired, isDreSynced }) => {
        const isRunningTesting =
          experimentStatus.id === TESTING || experimentStatus.id === RUNNING

        if (isRunningTesting && tdcsUpdateRequired && !isCmsExperiment) {
          if (
            !redirectEnvironmentId ||
            redirectEnvironmentId > environment.id
          ) {
            redirectEnvironmentId = environment.id
          }

          return true
        }

        if (isRunningTesting && isCmsExperiment && !isDreSynced) {
          if (
            !redirectEnvironmentId ||
            redirectEnvironmentId > environment.id
          ) {
            redirectEnvironmentId = environment.id
          }

          return true
        }

        return false
      }
    )
    .map(({ environment }) => capitalizeString(environment.name.toLowerCase()))

  const linkTest = !isCmsExperiment
    ? !linkHidden && !activeNetworkLockdown && tdcsUpdateLinkText
    : !linkHidden && dreUpdateLinkText
  const description = !isCmsExperiment
    ? tdcsUpdateDescription(environmentNames, activeNetworkLockdown)
    : dreUpdateDescription(environmentNames)
  const title = !isCmsExperiment ? tdcsUpdateTitle : dreUpdateTitle

  return (
    <ExpansionPanel type="minimal" isExpanded={environmentNames.length > 0}>
      <KiteAlert
        className="app__page-level-message"
        type="caution"
        title={title}
        description={description}
        linkText={linkTest}
        onLinkClick={() =>
          history.push(
            `/experiments/${id}/rollout/sample-launch?environment=${redirectEnvironmentId}`
          )
        }
      />
    </ExpansionPanel>
  )
}

export default TdcsUpdateNotification

TdcsUpdateNotification.propTypes = {
  history: object.isRequired,
  environmentSampling: array.isRequired,
  id: string,
  linkHidden: bool,
}

TdcsUpdateNotification.defaultProps = {
  id: null,
  linkHidden: false,
}
