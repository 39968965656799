import {
  decorate,
  observable,
  computed,
  transaction,
  runInAction,
  action,
} from 'mobx'
import {
  isFeatureFlagActive,
  featureFlagEnum,
  permissionEnum,
  hasPermission,
} from '@charter/distillery-rules'

import keycloak from '../../configuration/keycloak'
import { client } from '../../configuration/configApiClient'
import { UPDATE_USER_CONFIG } from '../../shared/mutations'
import { GET_CURRENT_USER } from '../../shared/queries'
import { gql } from '@apollo/client'

const {
  SAMPLE_SIZE_CALCULATOR,
  EXPERIMENT_EVALUATION,
  LEARN_WIKI,
  CONTENT_MAP,
} = featureFlagEnum

const {
  USER_ADMINISTRATION,
  SUBSCRIPTION_ADMINISTRATION,
  OVERRIDE_CREATE,
  EVALUATE_EXPERIMENT,
  EXPERIMENT_READ,
  AUDIENCE_CREATE,
  MANAGE_NETWORK_LOCKDOWN,
} = permissionEnum

class AppModel {
  token = null
  currentUser = null
  currentlyViewing = {
    path: '/experiments/all',
    title: 'All Experiments',
    backPath: '/experiments/all',
    backTitle: 'All Experiments',
  }
  userRoles = null
  applicationPermissions = null
  featureFlags = null
  hasInAppPreferences = false
  error = null
  productsByCategory = null
  modelsById = new Map()
  isNetworkLockdownActive = false

  get isAuthenticated() {
    return this.token !== null && this.currentUser !== null
  }

  get ssppProducts() {
    if (!this.productsByCategory?.length) return null
    return this.productsByCategory[0].products
  }

  get videoProducts() {
    if (!this.productsByCategory?.length) return null
    return this.productsByCategory[1].products
  }

  get hasNotifications() {
    const currentUser = this.currentUser
    if (!currentUser || !currentUser.inAppNotifications) return false
    return currentUser.inAppNotifications.find(
      notification => notification.isRead === false
    )
  }

  hasFeatureFlag(enumValue) {
    const { featureFlags, applicationPermissions } = this
    if (!featureFlags) return false

    return isFeatureFlagActive({
      featureFlags,
      featureFlagId: enumValue,
      applicationPermissions,
    })
  }

  get isCalculatorActive() {
    return this.hasFeatureFlag(SAMPLE_SIZE_CALCULATOR)
  }

  get isExperimentEvaluationActive() {
    return this.hasFeatureFlag(EXPERIMENT_EVALUATION)
  }

  get isLearnWikiActive() {
    return this.hasFeatureFlag(LEARN_WIKI)
  }

  get isContentMapsActive() {
    return this.hasFeatureFlag(CONTENT_MAP)
  }

  hasPermission(permissionId) {
    const { applicationPermissions } = this
    return hasPermission({ permissionId, applicationPermissions })
  }

  get hasEvaluateExperimentPermission() {
    return this.hasPermission(EVALUATE_EXPERIMENT)
  }

  get shouldShowExperimentEval() {
    return (
      this.hasEvaluateExperimentPermission && this.isExperimentEvaluationActive
    )
  }

  get hasUserAdminPermission() {
    return this.hasPermission(USER_ADMINISTRATION)
  }

  get hasOverrideCreatePermission() {
    return this.hasPermission(OVERRIDE_CREATE)
  }

  get hasAudienceCreatePermission() {
    return this.hasPermission(AUDIENCE_CREATE)
  }

  get hasSubscriptionAdminPermission() {
    return this.hasPermission(SUBSCRIPTION_ADMINISTRATION)
  }

  get hasExperimentReadPermission() {
    return this.hasPermission(EXPERIMENT_READ)
  }

  get hasNetworkLockdownAdminPermission() {
    return this.hasPermission(MANAGE_NETWORK_LOCKDOWN)
  }

  async saveUserConfig(isCollapsed) {
    const { currentUser } = this
    const existingConfig = JSON.parse(currentUser.config)

    const config = JSON.stringify({
      ...existingConfig,
      isSideNavCollapsed: isCollapsed,
    })

    const input = {
      userId: currentUser.id,
      config,
    }

    await client.mutate({
      mutation: UPDATE_USER_CONFIG,
      variables: {
        input,
      },
    })
  }

  async loadCurrentUser(groups = []) {
    try {
      const res = await client.query({
        query: GET_CURRENT_USER,
        fetchPolicy: 'network-only',
        variables: { groups: groups },
      })

      const {
        data: {
          currentUser,
          applicationPermissions,
          featureFlags,
          hasInAppPreferences,
        },
      } = res

      transaction(() => {
        this.currentUser = currentUser
        this.userRoles = currentUser.userToRoles
        this.applicationPermissions = applicationPermissions
        this.featureFlags = featureFlags
        this.hasInAppPreferences = hasInAppPreferences
        this.loadProducts()
      })
    } catch (err) {
      console.error(err)
      this.error =
        err && err.networkError && err.networkError.statusCode === 403
          ? new Error('Forbidden error')
          : new Error('Failed to fetch')
    }
  }

  async authenticate() {
    const localToken = window.localStorage.getItem('dxKcToken')
    let token = localToken || keycloak.token
    if (!token) {
      try {
        await keycloak.init({
          onLoad: 'login-required',
          checkLoginIframe: false,
          promiseType: 'native',
        })
      } catch (err) {
        console.error(err)
        this.error = new Error(`Keycloak error${err ? `: ${err.message}` : ''}`)
        return
      }
      // eslint-disable-next-line prefer-destructuring
      token = keycloak.token
    }
    this.token = token

    if (token && !this.currentUser) {
      const groups =
        keycloak.idTokenParsed && keycloak.idTokenParsed.groups
          ? keycloak.idTokenParsed.groups
          : []
      //send groups as param so that we can assign permissions on first login
      await this.loadCurrentUser(groups)
      return
    }

    if (token) {
      return
    } else if (!this.currentUser) {
      // trigger error page
      this.error = new Error('Keycloak error')
    }
  }

  navigate(toView, shouldMerge, history) {
    this.updateCurrentlyViewing(toView, shouldMerge)
    history && history.push(toView.path)
  }

  updateCurrentlyViewing(toView, shouldMerge) {
    const { backPath, backTitle } = this.currentlyViewing

    if (!toView.backPath && backPath) {
      // eslint-disable-next-line no-param-reassign
      toView.backPath = backPath
    }

    if (!toView.backTitle && backTitle) {
      // eslint-disable-next-line no-param-reassign
      toView.backTitle = backTitle
    }

    this.currentlyViewing = shouldMerge
      ? {
          ...this.currentlyViewing,
          ...toView,
        }
      : toView
  }

  resolveFromCache = (obj, objMap = {}) => {
    if (Array.isArray(obj))
      return obj.map(o => this.resolveFromCache(o, objMap))
    else if (obj && typeof obj === 'object') {
      if (obj.__typename) return obj

      if (
        obj.id !== undefined &&
        obj.typename !== undefined &&
        obj.generated !== undefined
      ) {
        // A link to another object
        if (objMap[obj.id]) return objMap[obj.id]
        return this.resolveFromCache(client.cache.data.get(obj.id), objMap)
      }

      const resolved = {}
      Object.entries(obj).forEach(([key, value]) => {
        resolved[key] = this.resolveFromCache(value)
      })
      objMap[obj.id] = resolved
      return resolved
    } else {
      return obj
    }
  }

  resolveObject = key => {
    const obj = client.cache.data.get(key)
    if (!obj) return null
    const resolved = {}
    Object.entries(obj).forEach(([key, value]) => {
      resolved[key] = this.resolveFromCache(value)
    })
    return resolved
  }

  getEditorModel = ({ EditorModelClass, id, apiTime }) => {
    if (!id) throw Error('Must have id at this point')
    const key = `${EditorModelClass.typeName}:${id}`
    if (!this.modelsById.get(key)) {
      const editorModel = new EditorModelClass()
      editorModel.id = id
      editorModel.start()
      this.setEditorModel(editorModel)
    }
    const editorModel = this.modelsById.get(key)
    const apiObject = this.resolveObject(key)
    if (apiObject && editorModel.apiTime !== apiTime) {
      runInAction(() => {
        editorModel.updateFromAPI({
          apiObject,
        })
        editorModel.apiTime = apiTime
      })
    }

    return editorModel
  }

  setEditorModel = editorModel => {
    const { id, cacheKey } = editorModel
    if (!id) throw Error('editorModel must have id at this point')
    if (this.modelsById.has(cacheKey))
      throw Error(`editorModel for cacheKey ${cacheKey} already exists`)
    this.modelsById.set(cacheKey, editorModel)
  }

  updateFromCache(editorModel) {
    if (!editorModel.id) throw Error('editorModel must have an id')
    const key = `${editorModel.constructor.typeName}:${editorModel.id}`
    const apiObject = this.resolveObject(key)
    if (apiObject) editorModel.updateFromAPI({ apiObject })
  }

  async loadProducts() {
    const {
      data: { productCategoryTypes },
    } = await client.query({
      query: AppModel.productsGQL,
      variables: { permissionEnum: 'EXPERIMENT_CREATE' },
    })
    this.productsByCategory = productCategoryTypes
  }

  static productsGQL = gql`
    query getProducts($permissionEnum: PermissionEnum!) {
      productCategoryTypes(permissionEnum: $permissionEnum) {
        id
        name
        products(permissionEnum: $permissionEnum) {
          id
          name
          displayName
          quantumApplicationName
          quantumApplicationType
          activeAccounts
          activeDevices
          sampleCalculatorEnabled
        }
      }
    }
  `
}

AppModel = decorate(AppModel, {
  token: observable,
  currentUser: observable,
  isAuthenticated: computed,
  currentlyViewing: observable,
  error: observable,
  productsByCategory: observable.ref,
  hasNotifications: computed,
  isCalculatorActive: computed,
  isExperimentEvaluationActive: computed,
  isLearnWikiActive: computed,
  isContentMapsActive: computed,
  hasEvaluateExperimentPermission: computed,
  hasExperimentReadPermission: computed,
  hasOverrideCreatePermission: computed,
  hasAudienceCreatePermission: computed,
  hasSubscriptionAdminPermission: computed,
  hasUserAdminPermission: computed,
  shouldShowExperimentEval: computed,
  ssppProducts: computed,
  videoProducts: computed,
  addEditorModel: action,
})

const appModel = new AppModel()
export default appModel
