import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

/*
 *  Input: the id of the experiment
 */
export const GET_EXPERIMENT_TDCS_FILTERS = gql`
  query getExperimentTDCSFilters($id: Int!) {
    experiment(id: $id) {
      id
      isTechPocApproved
      experimentTypeId
      experimentStatusId
      isOverridesDeleted
    }
    TDCSExperiment(experimentId: $id) {
      id
      experimentId
      productMinVersion
      productMaxVersion
      tdcsInformCall {
        id
        name
        displayName
      }
      tdcsExperimentFilters {
        id
        textValue
        isEnabled
        isIncluded
        tdcsFilter {
          id
          name
          displayName
          displayOrder
          tdcsFilterType {
            id
            name
            displayName
            isBoolean
          }
        }
      }
    }
  }
`

const getExperimentTDCSFilters = graphql(GET_EXPERIMENT_TDCS_FILTERS, {
  name: 'experimentTDCSFiltersData',
  withRef: true,
})

export default getExperimentTDCSFilters
