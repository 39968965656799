import React, { Component, Fragment } from 'react'
import flowRight from 'lodash/flowRight'
import isEqual from 'lodash/isEqual'
import { object } from 'prop-types'
import { KiteSelect } from '@kite/react-kite'

import { client } from '../../../../../configuration/configApiClient'
import { LOG_ERROR } from '../../../../../shared/mutations'
import { formatLoggingError } from '../../../../../shared/utilities'
import { ListBuilder } from '../../../../../componentLibrary'
import { getPermissionTypes, getPermissions } from '../../queries'
import {
  deleteRoleWithPermissions,
  insertRoleWithPermissions,
} from '../../mutations'

class PermissionsPanel extends Component {
  static propTypes = {
    allPermissionsData: object,
    allPermissionTypesData: object,
    selectedRole: object,
  }

  static defaultProps = {
    allPermissionsData: {},
    allPermissionTypesData: {},
    selectedRole: {},
    updateAssignedPermissions: null,
    updatePermissionsStatus: null,
  }

  state = {
    allPermissionsFiltered: [],
    assignedPermissionsFiltered: [],
    selectedPermissionType: {},
    permissionsStatus: null,
  }

  componentDidUpdate(prevProps) {
    const { selectedRole } = this.props
    if (!isEqual(selectedRole, prevProps.selectedRole)) {
      this.handleSelectedRoleChange()
    }
  }

  filterPermissionsToUpdate = (assignedPermissions, updatedPermissions) => {
    const mergedPermissions = [
      ...new Set(assignedPermissions.concat(updatedPermissions)),
    ]
    return {
      permissionsToDeleteIds: mergedPermissions.filter(
        permissionId => !updatedPermissions.includes(permissionId)
      ),
      permissionsToInsertIds: mergedPermissions.filter(
        permissionId => !assignedPermissions.includes(permissionId)
      ),
    }
  }

  handleSelectedPermissionType = event => {
    const {
      allPermissionsData: { permissions: allPermissions },
      allPermissionTypesData: { permissionTypes },
      selectedRole,
    } = this.props
    const assignedPermissions = selectedRole.permissions

    let permissionType
    let allPermissionsFiltered
    let assignedPermissionsFiltered

    if (event.target.value === 'ALL') {
      permissionType = { id: 0, name: 'ALL' }
      allPermissionsFiltered = allPermissions
      assignedPermissionsFiltered = assignedPermissions
    } else {
      permissionType = permissionTypes.find(
        obj => obj.name === event.target.value
      )
      allPermissionsFiltered = allPermissions.filter(
        obj => obj.permissionTypeId === permissionType.id
      )
      assignedPermissionsFiltered = assignedPermissions.filter(
        obj => obj.permissionTypeId === permissionType.id
      )
    }

    this.setState({
      allPermissionsFiltered,
      assignedPermissionsFiltered,
      selectedPermissionType: permissionType,
    })
  }

  handleSelectedRoleChange = () => {
    const { selectedRole } = this.props
    const { selectedPermissionType } = this.state

    let assignedPermissionsFiltered
    const assignedPermissions = selectedRole.permissions

    if (selectedPermissionType.name === 'ALL') {
      assignedPermissionsFiltered = assignedPermissions
    } else {
      assignedPermissionsFiltered = assignedPermissions.filter(
        obj => obj.permissionTypeId === selectedPermissionType.id
      )
    }

    this.setState({
      assignedPermissionsFiltered,
      // selectedPermissionType: {},
    })
  }

  updateAssignedPermissions = async updatedPermissions => {
    this.setState({ permissionsStatus: 'saving' })
    const {
      deleteRoleWithPermissions: deletePermissions,
      insertRoleWithPermissions: insertPermissions,
      selectedRole: { id, permissions: rolePermissions },
    } = await this.props

    const updatedPermissionIds = updatedPermissions.map(
      permission => permission.id
    )
    const roleAssignedPermissionIds = rolePermissions.map(
      rolePermission => rolePermission.id
    )

    const {
      permissionsToDeleteIds,
      permissionsToInsertIds,
    } = this.filterPermissionsToUpdate(
      roleAssignedPermissionIds,
      updatedPermissionIds
    )

    try {
      if (permissionsToDeleteIds.length > 0) {
        await deletePermissions({
          variables: {
            input: {
              roleId: id,
              permissionIds: permissionsToDeleteIds,
            },
          },
        })
      }

      if (permissionsToInsertIds.length > 0) {
        await insertPermissions({
          variables: {
            input: {
              roleId: id,
              permissionIds: permissionsToInsertIds,
            },
          },
        })
      }

      await this.setState({ permissionsStatus: 'success' })
      return true
    } catch (error) {
      const input = formatLoggingError(error)

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input,
        },
      })

      await this.setState({ permissionsStatus: 'error' })
      return false
    }
  }

  render() {
    const {
      allPermissionsFiltered,
      assignedPermissionsFiltered,
      selectedPermissionType,
      permissionsStatus,
    } = this.state

    const {
      allPermissionTypesData: { permissionTypes },
      selectedRole,
    } = this.props

    return (
      <Fragment>
        <KiteSelect
          id="permissionTypes-select"
          name="permissionTypes"
          label="permissionTypes: "
          value={selectedPermissionType.name || ''}
          onChange={this.handleSelectedPermissionType}
          disabled={!selectedRole.id}
        >
          <option key="-1" value="" disabled>
            Select One
          </option>
          <option key="0" value="ALL">
            ALL
          </option>
          {permissionTypes &&
            permissionTypes.map(option => (
              <option key={option.id} value={option.name}>
                {option.name.toLowerCase()}
              </option>
            ))}
        </KiteSelect>
        <ListBuilder
          assignedOptions={assignedPermissionsFiltered}
          assignedOptionsTitle="Assigned Permissions"
          allOptions={allPermissionsFiltered}
          availableOptionsTitle="Available Permissions"
          selectedId={selectedRole.id}
          onSave={this.updateAssignedPermissions}
          status={permissionsStatus}
        />
      </Fragment>
    )
  }
}

export default flowRight(
  getPermissions,
  getPermissionTypes,
  deleteRoleWithPermissions,
  insertRoleWithPermissions
)(PermissionsPanel)
