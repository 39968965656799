import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

const REPUBLISH_UPDATE_TO_DRE = gql`
  mutation republishUpdateToDre($input: republishUpdateInput!) {
    republishUpdateToDre(input: $input) {
      id
      startTime
      durationNumDays
      variants {
        id
        name
        displayOrder
      }
      environmentSamplings {
        id
        tdcsUpdateRequired
        isDreSynced
        sampleAllocation
        environment {
          id
          name
          color
        }
        experimentStatus {
          id
          name
        }
        lastModifiedTime
        lastModifiedUser {
          id
          displayName
        }
        environmentSamplingToVariants {
          id
          weight
          variant {
            id
            name
          }
          lastModifiedTime
          lastModifiedUser {
            id
            displayName
          }
        }
        allocationHistory {
          id
          sampleAllocation
          variantConfig
          createdTime
          lastModifiedUser {
            id
            displayName
          }
        }
      }
    }
  }
`

const republishUpdateToDre = graphql(REPUBLISH_UPDATE_TO_DRE, {
  name: 'republishUpdateToDre',
})

export default republishUpdateToDre
