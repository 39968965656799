const getPortfolioCategories = {
  query: `
    portfolioCategory {
      id
      displayName
      name
    }
  `,
  variables: {},
};

export default getPortfolioCategories;
