import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

/*
 * Sample Input
 *
 *
 * variables: {
          denverDateRange: { start: "2019-09-24", end: "2019-09-29"},
          experimentId: "80336f2352f74b6db141600efb8b7f91",
          subdimensions: [null],
          applicationCoords: [appCoords],
        },
 */
export const GET_QUANTUM_METRIC_COUNTS = gql`
  query getMetricCounts(
    $experimentId: String!
    $denverDateRange: DenverDateRangeInput
    $subdimensions: [String]
    $applicationCoords: [String!]
  ) {
    counts(
      experimentId: $experimentId
      denverDateRange: $denverDateRange
      subdimensions: $subdimensions
      applicationCoords: $applicationCoords
    ) {
      denverDate
      experimentId
      variantId
      dailyNewAccounts
      dailyNewDevices
      cumulativeUniqueAccounts
      cumulativeUniqueDevices
      dailyNewAccountsAllocated
      dailyNewDevicesAllocated
      cumulativeUniqueAccountsAllocated
      cumulativeUniqueDevicesAllocated
      dailyNewAccountsActivated
      dailyNewDevicesActivated
      cumulativeUniqueAccountsActivated
      cumulativeUniqueDevicesActivated
    }
  }
`

const getQuantumMetricCounts = graphql(GET_QUANTUM_METRIC_COUNTS, {
  name: 'quantumMetricCounts',
})

export default getQuantumMetricCounts
