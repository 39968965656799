export default {
  cloneButton: 'Clone',
  cloneDialogHeader: name => `Clone "${name}"`,
  cloneInputLabel: 'New experiment name',
  cloneInputPlaceholder: 'Enter a unique name...',
  cloneSubmit: 'Submit',
  cloneCancel: 'Never mind',
  errorDuplicateName: 'An experiment with this name already exists. Please try another one.',
  errorResponse: 'Something went wrong when cloning your experiment. Please try again in a few minutes.',
};
