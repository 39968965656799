import { observer } from 'mobx-react'
import React, { Fragment, useEffect } from 'react'
import {
  KiteButton,
  KiteCard,
  KiteSelect,
  KiteInput,
  KiteIcon,
  KiteLoader,
  KiteModal,
} from '@kite/react-kite'

import './OREditor.scss'
import { titleCaseString } from '../../shared/utilities'
import OverrideTypesModel from './OverrideTypesModel'
import appModel from '../../components/App/AppModel'
import ORAddToGroups from './ORAddToGroups'
import SaveOrExit from '../../components/SaveOrExit/SaveOrExit'
import { DialogContainer } from '../../componentLibrary'
import ORGuidLookupModel from './ORGuidLookupModel'

const GuidLookupModal = observer(({ editorModel }) => {
  return (
    <DialogContainer>
      <div className="lookup-modal">
        <div className="lookup-modal__title">Guid Lookup Tool</div>
        <div className="lookup-modal__form">
          <KiteInput
            label="fqAccountId"
            name="fqAccountId"
            placeholder={ORGuidLookupModel.fqAccountIdPlaceholder}
            tooltip={ORGuidLookupModel.fqAccountIdTooltip}
            value={editorModel.guidLookup.fqAccountId}
            onChange={editorModel.guidLookup.onChange}
          ></KiteInput>
          <KiteInput
            label="Identity Username"
            name="identityUsername"
            placeholder={ORGuidLookupModel.identityUsernamePlaceholder}
            tooltip={ORGuidLookupModel.identityUsernameTooltip}
            value={editorModel.guidLookup.identityUsername}
            onChange={editorModel.guidLookup.onChange}
          ></KiteInput>
          {editorModel.guidLookup?.firstMetadata && (
            <div
              className="or-editor__guid-lookup-success"
              style={{ marginBottom: 10 }}
            >
              <KiteIcon name="checkmark" />
              <span>Found the GUID!</span>
            </div>
          )}
          {editorModel.guidLookup?.lookupFailed && (
            <div
              className="or-editor__guid-lookup-failed"
              style={{ marginBottom: 10 }}
            >
              <KiteIcon name="x-circle" />
              <span>Guid not found</span>
            </div>
          )}
          <div className="lookup-modal__controls">
            <KiteButton
              type="primary"
              size="medium"
              onClick={() => editorModel.performGuidLookup()}
              disabled={
                !editorModel.guidLookup.isValidFQAccountId &&
                !editorModel.guidLookup.isValidIdentityUsername
              }
              loading={editorModel.guidLookup?.inProgressLookupRequest}
            >
              Lookup GUID
            </KiteButton>
            <KiteButton
              type="standalone-link"
              size="small"
              onClick={() => (editorModel.shouldShowLookupModal = false)}
            >
              Exit
            </KiteButton>
          </div>
        </div>
      </div>
    </DialogContainer>
  )
})

const overrideTypesModel = new OverrideTypesModel()

export const ORDetails = observer(({ editorModel }) => {
  if (editorModel.guidLookup?.inProgressLookupRequest) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <KiteLoader />
      </div>
    )
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {editorModel.metadata &&
        editorModel.metadata.map(({ name, value }) => {
          return (
            <div
              key={name}
              style={{
                display: 'flex',
                alignItems: 'center',
                maxHeight: '40px',
              }}
            >
              <div
                style={{
                  fontWeight: 500,
                  flex: 0.4,
                  marginRight: '15',
                  padding: 5,
                }}
              >
                {name}:
              </div>
              <div style={{ flex: 1 }}>{value}</div>
            </div>
          )
        })}
    </div>
  )
})

const OverrideTypeOptions = observer(({ editorModel }) => {
  const { type } = editorModel
  const { objects: types } = overrideTypesModel

  return (
    <KiteSelect
      id="overrideType"
      label="Type of Override"
      value={type}
      onChange={event => (editorModel.type = Number(event.currentTarget.value))}
      maxWidth="100%"
    >
      <option value="" disabled>
        Select a type
      </option>

      {types.map(({ id, name }) => (
        <option key={id} value={id}>
          {titleCaseString(name, '_')}
        </option>
      ))}
    </KiteSelect>
  )
})

const ProductOptions = observer(({ editorModel }) => {
  const { productId } = editorModel
  const { productsByCategory } = appModel

  return (
    <KiteSelect
      label="Product"
      value={productId}
      name="product"
      onChange={event => (editorModel.productId = event.currentTarget.value)}
      maxWidth="100%"
      errorMessage={editorModel.errorMessage('product')}
    >
      <option value="" disabled>
        Pick a product
      </option>
      {productsByCategory
        .sort((aType, bType) => aType.name > bType.name)
        .map(category => (
          <optgroup key={category.id} label={category.name}>
            {category.products
              .sort((aProduct, bProduct) =>
                aProduct.displayName.toLowerCase() >
                bProduct.displayName.toLowerCase()
                  ? 1
                  : -1
              )
              .map(product => (
                <option key={product.id} value={product.id}>
                  {product.displayName}
                </option>
              ))}
          </optgroup>
        ))}
      ))
    </KiteSelect>
  )
})

export default observer(function OREditor({
  orMainModel,
  editorModel,
  orGroupsListModel,
}) {
  useEffect(() => {
    if (editorModel.needsLookup) editorModel.lookupIdentity()
  }, [editorModel])

  return (
    /* grid (2,many) - edit a override */
    <div className="or-editor">
      {/* row - title */}
      <div className="or-editor__title">
        {editorModel.id ? 'Edit' : 'Add New '} Override
      </div>
      <div></div>

      {/* row - spacer */}
      <div></div>
      <div></div>

      {/* row - bulk import button */}
      <div>
        <KiteButton
          className="or-editor__bigger-icon"
          type="outline"
          size="medium"
          leftIcon="arrow-up-circle"
          onClick={() => orMainModel.gotoBulkImport()}
        >
          Bulk Import
        </KiteButton>
      </div>
      <div></div>

      {/* row - spacer */}
      <div></div>
      <div></div>

      {/* grid (2,many) - main editing card */}
      <KiteCard className="or-editor__main">
        {/* row - override types */}
        <OverrideTypeOptions editorModel={editorModel}></OverrideTypeOptions>
        <div></div>

        {/* row - accountGuid */}
        {[2, 3].includes(editorModel.type) && (
          <Fragment>
            <KiteInput
              id="accountGuid"
              name="accountGuid"
              label="Account ID (GUID)"
              value={editorModel.accountGuid}
              errorMessage={editorModel.errorMessage('accountGuid')}
              onChange={({ currentTarget: { value: accountGuid } }) =>
                (editorModel.accountGuid = accountGuid)
              }
            />
            {/* col - lookup accountGuid button */}
            <div className="or-editor__guid-lookup">
              <div>
                {editorModel.guidLookup?.firstMetadata ? (
                  <div className="or-editor__guid-lookup-success">
                    <KiteIcon name="checkmark" />
                    <span>Found the GUID!</span>
                  </div>
                ) : editorModel.guidLookup?.lookupFailed ? (
                  <div className="or-editor__guid-lookup-failed">
                    <KiteIcon name="x-circle" />
                    <span>Guid not found</span>
                  </div>
                ) : (
                  "Don't know the GUID?"
                )}
              </div>
              <div onClick={() => editorModel.showLookupModal()}>
                GUID Lookup <KiteIcon name="arrow-right-circle"></KiteIcon>
              </div>
              {editorModel.shouldShowLookupModal ? (
                <GuidLookupModal editorModel={editorModel} />
              ) : null}
            </div>
          </Fragment>
        )}

        {/* row - clientGuid */}
        {[1, 3].includes(editorModel.type) && (
          <Fragment>
            <KiteInput
              id="clientGuid"
              name="clientGuid"
              label="Client/Device ID (GUID)"
              value={editorModel.clientGuid}
              errorMessage={editorModel.errorMessage('clientGuid')}
              onChange={({ currentTarget: { value: clientGuid } }) =>
                (editorModel.clientGuid = clientGuid)
              }
            />
            <div></div>
          </Fragment>
        )}

        {/* row - override name */}
        <KiteInput
          id="overrideName"
          name="overrideName"
          label="Name your Override"
          value={editorModel.name}
          errorMessage={editorModel.errorMessage('name')}
          onChange={({ currentTarget: { value: name } }) =>
            (editorModel.name = name)
          }
        />
        <div></div>

        {/* row - product */}
        {editorModel.requiresProduct ? (
          <Fragment>
            <ProductOptions editorModel={editorModel} />
            <div></div>
          </Fragment>
        ) : null}

        {/* row - add to group */}
        <ORAddToGroups
          orEditorModel={editorModel}
          orGroupsListModel={orGroupsListModel}
          isOptional={true}
        />
        <div></div>
      </KiteCard>

      {/* grid (1,many) - override details */}
      <div className="or-editor__detail-container">
        <KiteCard className="or-editor__detail">
          <div>Override Metadata</div>
          <ORDetails editorModel={editorModel} />
        </KiteCard>
        {editorModel.id && (
          <div className="or-editor__delete-container">
            <KiteButton
              className="or-editor__delete"
              leftIcon="trash-f"
              size="medium"
              type="outline"
              loading={editorModel.isDeleting}
              onClick={() => (editorModel.isDeleting = true)}
            >
              Delete Override
            </KiteButton>
            <KiteModal
              className="confirm-modal"
              canShow={editorModel.isDeleting}
              ctaCopy="Yes, Delete Override"
              secondaryCtaCopy="Nevermind"
              title=""
              ctaLoading={editorModel.isSaving}
              secondaryCtaAction={() => (editorModel.isDeleting = false)}
              ctaAction={editorModel.save}
            >
              <p>Are you sure you want to delete this override?</p>
            </KiteModal>
          </div>
        )}
      </div>

      {/* row - spacer */}
      <div></div>
      <div></div>

      {/* row - save button and exit */}
      <SaveOrExit editorModel={editorModel} />
      <div></div>
    </div>
  )
})
