const getMetrics = {
  query: `
    experiment(id: $id) {
      experimentMetrics {
        id
        createdUser {
          displayName
        }
        metricTypeId
        metricUuid
        experimentId
      }
    }
    retrieveQuantumToken(quantumEnum: { overrideTypeId: QUANTUM_METRIC })
  `,
  variables: { $id: 'Int!' },
  options: {
    fetchPolicy: 'cache-and-network',
  },
}

export default getMetrics
