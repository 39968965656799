import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

/*
* Sample Input
* [
  {experimentID: 1, userID: 1, roleID: 4},
    {experimentID: 1, userID: 2, roleID: 3}
  ]
*/
const INSERT_EXPERIMENT_TO_USERS = gql`
  mutation insertExperimentToUsers($input: ExperimentToUserEntry!) {
    insertExperimentToUsers(input: $input) {
      id
      userId
      user {
        id
        firstName
        displayName
      }
      roleId
      role {
        id
        name
      }
      experimentId
      experiment {
        id
        config
        name
      }
    }
  }
`

const insertExperimentToUsers = graphql(INSERT_EXPERIMENT_TO_USERS, {
  name: 'insertExperimentToUsers',
  withRef: true,
})

export default insertExperimentToUsers
