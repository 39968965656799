import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

/*
* Sample Input
* {
  input: {
    userId: 11,
    notificationIds: [1,2,3]
  }
}
*/

const DELETE_NOTIFICATION_PREFERENCES = gql`
  mutation deleteNotificationPreferences(
    $input: DeleteNotificationPreferences!
  ) {
    deleteNotificationPreferences(input: $input)
  }
`

const deleteNotificationPreferences = graphql(DELETE_NOTIFICATION_PREFERENCES, {
  name: 'deleteNotificationPreferences',
})

export default deleteNotificationPreferences
