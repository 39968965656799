import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

const GET_ENVIRONMENTS = gql`
  query getEnvironments {
    environments {
      id
      name
      order
      color
    }
  }
`

const getEnvironments = graphql(GET_ENVIRONMENTS, {
  name: 'environmentsData',
  withRef: true,
})

export default getEnvironments
