import { gql } from '@apollo/client'

const CLONE_AUDIENCE = gql`
  mutation cloneAudience($input: AudienceCloneInput!) {
    cloneAudience(input: $input) {
      id
      name
      description
      rules
    }
  }
`

export default CLONE_AUDIENCE
