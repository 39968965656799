import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

export const GET_EXPERIMENT_SAMPLING = gql`
  query getExperimentSampling($id: Int!) {
    experiment(id: $id) {
      id
      uuid
      name
      experimentTypeId
      experimentStatusId
      startTime
      durationNumDays
      isCmsExperiment
      crqRequestNumber
      expsiTicketNumber
      experimentMetrics {
        id
        metricTypeId
        metricUuid
        experimentId
        observationalDays
      }
      variants {
        id
        uuid
        name
        displayOrder
        variantToOverrides {
          id
        }
        variantToGroupOverrides {
          id
        }
      }
      experimentToProductFeatures {
        id
        product {
          id
          displayName
          quantumApplicationName
          quantumApplicationType
        }
      }
      metrics(isEnabled: true) {
        id
        displayName
        aggName
        isEnabled
      }
      environmentSamplings {
        id
        sampleAllocation
        tdcsUpdateRequired
        isDreSynced
        environment {
          id
          name
          color
        }
        experimentStatus {
          id
          name
        }
        lastModifiedTime
        lastModifiedUser {
          id
          displayName
        }
        environmentSamplingToVariants {
          id
          weight
          variant {
            id
            name
          }
          lastModifiedTime
          lastModifiedUser {
            id
            displayName
          }
        }
        allocationHistory {
          id
          sampleAllocation
          variantConfig
          createdTime
          isReset
          comment
          uuid
          lastModifiedUser {
            id
            displayName
          }
        }
      }
      permissions {
        id
        name
      }
    }
    retrieveQuantumToken(quantumEnum: { overrideTypeId: QUANTUM_METRIC })
  }
`

export const GET_QUANTUM_METRICS = gql`
  query getMetrics {
    metricDefinitions {
      _id
      name
      description
      sourceTable
      calculation
      displayName
    }
  }
`

const getExperimentSampling = graphql(GET_EXPERIMENT_SAMPLING, {
  name: 'samplingData',
  options: {
    fetchPolicy: 'cache-and-network',
  },
})

export default getExperimentSampling
