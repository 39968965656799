const formatProducts = productCategoryTypes => {
  const product = productCategoryTypes.reduce((accumulator, categoryType) => {
    const productKey =
      categoryType.name.toLowerCase() === 'cms'
        ? categoryType.name
        : categoryType.name.toLowerCase()
    if (!accumulator[productKey]) {
      accumulator[productKey] = categoryType.products.map(
        productFilter => productFilter.displayName
      )
    }

    return accumulator
  }, {})

  return product
}

const formatFeatures = featureTypes =>
  featureTypes.reduce((accumulator, featureType) => {
    if (!accumulator[featureType.name]) {
      accumulator[featureType.name] = featureType.features.map(
        featureFilter => featureFilter.displayName
      )
    }

    return accumulator
  }, {})

const formatByExperimenter = experiments =>
  experiments
    .reduce((accumulator, experiment) => {
      const experimenter = experiment.experimentToUsers.find(
        user => user.roleId === 2
      ).user.displayName

      if (experimenter) {
        const match = accumulator.find(name => name === experimenter)

        if (!match) {
          accumulator.push(experimenter)
        }
      }

      return accumulator
    }, [])
    .sort()

const formatByExperimentName = experiments =>
  experiments.map(experiment => experiment.name)

const formatEnvironments = environmentsArray =>
  environmentsArray.map(environment => {
    if (environment.name.includes('ENG')) {
      return `eng ${environment.name.split('ENG').join(' ').toLowerCase()}`
    }

    return environment.name.toLowerCase()
  })

const formatByExperimentType = experimentsByType =>
  experimentsByType
    .reduce((accumulator, experiment) => {
      const type = experiment.experimentType.name

      if (!accumulator.includes(type)) {
        return [...accumulator, type]
      }

      return accumulator
    }, [])
    .sort()

const formatReturnedFilters = filterObject =>
  Object.keys(filterObject).reduce((accumulator, key) => {
    if (filterObject[key]) {
      return { ...accumulator, [key]: filterObject[key] }
    }

    return accumulator
  }, {})

const formatFilterOptions = filterOptions => {
  const {
    productCategoryTypes,
    featureTypes,
    experimentsByExperimenter,
    experimentsByName,
    environmentsArray,
    experimentsByType,
  } = filterOptions

  const product = productCategoryTypes
    ? formatProducts(productCategoryTypes)
    : false

  const feature = featureTypes ? formatFeatures(featureTypes) : false
  const experimenter = experimentsByExperimenter
    ? formatByExperimenter(experimentsByExperimenter)
    : false
  const experiment = experimentsByName
    ? formatByExperimentName(experimentsByName)
    : false
  const environments = environmentsArray
    ? formatEnvironments(environmentsArray)
    : false
  const types = experimentsByType
    ? formatByExperimentType(experimentsByType)
    : false

  return formatReturnedFilters({
    product,
    feature,
    experimenter,
    experiment,
    environments,
    types,
  })
}

export default formatFilterOptions
