import React, { Component } from 'react'
import { func, object, bool, number, string } from 'prop-types'
import flowRight from 'lodash/flowRight'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import dayjs from 'dayjs'
import { gql } from '@apollo/client'
import { KiteCard, KiteLoader, KiteAlert } from '@kite/react-kite'
import { LabeledToggle } from '@kite/react-kite-plus'
import {
  isFeatureFlagActive,
  featureFlagEnum,
  createQuantumApolloClient,
  checkMetrics,
} from '@charter/distillery-rules'

import { client } from '../../../../configuration/configApiClient'
import { LOG_ERROR } from '../../../../shared/mutations'
import { formatLoggingError, GqlBuilder } from '../../../../shared/utilities'
import copyContent from '../../data/copyContent'
import { GET_QUANTUM_METRICS } from './queries/getQuantumMetricDefinitions'
import { getMetrics } from '../../../../shared/queries'
import deleteAndInsertExperimentMetrics from './mutations/deleteAndInsertExperimentMetrics'
import OldMetrics from './OldMetrics'
import { ExpansionPanel } from '../../../../componentLibrary'
import './Metrics.scss'
import { MetricDefModal } from '../../../../components'

const gqlHOCQuery = new GqlBuilder().compileHOCQuery([getMetrics])

export class Metrics extends Component {
  static propTypes = {
    onChange: func.isRequired,
    handleSetErrorMessages: func,
    id: number,
    queryData: object,
    deleteAndInsertExperimentMetrics: func,
    disabled: bool,
    queryQuantum: func,
    productUuid: string,
    experimentStartDate: string,
  }

  static defaultProps = {
    id: null,
    queryData: null,
    deleteAndInsertExperimentMetrics: null,
    disabled: false,
    queryQuantum: null,
    productUuid: null,
    experimentStartDate: null,
  }

  state = {
    metricsById: {},
    relatedMetricsById: {},
    isLoadingQuantum: true,
    metricWithJointMetrics: null,
    errorMessage: {
      mustHaveOneLevelOne: null,
      deleteAndInsertExperimentMetrics: null,
      hasJustOneLevelOne: null,
      queryQuantumError: null,
    },
  }

  // LIFECYCLE METHODS
  componentDidMount() {
    const { queryData } = this.props
    if (queryData && queryData.experiment && queryData.retrieveQuantumToken) {
      this.handleSetExperiment()
    }
  }

  componentDidUpdate(prevProps) {
    const {
      queryData: { experiment },
      productUuid,
    } = this.props
    const {
      queryData: { experiment: prevData },
      productUuid: prevProduct,
    } = prevProps

    if (
      (experiment && !isEqual(prevData, experiment)) ||
      (experiment && productUuid !== prevProduct)
    ) {
      this.handleSetExperiment()
    }
  }

  // REF Methods
  getErrorMessage = () => {
    const { errorMessage } = this.state

    const messages = Object.values(errorMessage).reduce(
      (accumulator, error) => {
        if (!accumulator && error) {
          return `${error}`
        }
        if (error) {
          return `${accumulator}; ${error}`
        }
        return accumulator
      },
      ''
    )
    return messages
  }

  // Needed due to the old tech settings needing a validation step
  // Keep or error will be thrown on UI
  validateSubmission = async () => {
    const { metricsById, relatedMetricsById } = this.state
    const experimentMetricsInput = []

    Object.values(metricsById).forEach(metric => {
      experimentMetricsInput.push({
        id: metric.uuid,
        isEnabled: metric.isEnabled,
        isJointMetric: metric.isJointMetric,
        metricType: {
          id: 1,
        },
      })
    })

    Object.values(relatedMetricsById).forEach(metric => {
      experimentMetricsInput.push({
        id: metric.uuid,
        isEnabled: metric.isEnabled,
        isJointMetric: metric.isJointMetric,
        metricType: {
          id: 2,
        },
      })
    })

    const { errors } = checkMetrics(experimentMetricsInput)

    if (!isEmpty(errors)) {
      this.setState(({ errorMessage }) => ({
        errorMessage: {
          ...errorMessage,
          ...errors,
        },
      }))

      return false
    }

    return true
  }

  // FILTER/FORMAT METHODS
  getEnabledMetrics = metrics => metrics.filter(({ isEnabled }) => isEnabled)

  isEnabled = ({ _id: uuid }, metricType) => {
    const {
      queryData: {
        experiment: { experimentMetrics },
      },
    } = this.props

    const result = experimentMetrics.find(
      ({ metricUuid, metricTypeId }) =>
        uuid === metricUuid && metricType === metricTypeId
    )

    if (result) {
      return true
    }
    return false
  }

  isJointMetric = (metric, metricDefinitions) => {
    // Checks if the metric has joint metrics and if 
    // metric should be disabled. Note only way to keep the ability to remove one
    // or the other I had to choose final to not be allowed to be de-selected
    let isJoint = false
    metricDefinitions.forEach((metr) => {
      if (metr.jointMetrics.length) {
        metr.jointMetrics.forEach((jointMetric) => {
          if (jointMetric._id === metric._id) {
            isJoint= true
          }
        })
      }
    })
    return (isJoint && metric.displayName.toLowerCase().includes('final'))
  }

  formatRelatedMetrics = (metricDefinitions) => {
    const metricsById = {}
    metricDefinitions.forEach(metric => {
      const {
        _id: uuid,
        name,
        description,
        calculation,
        displayName,
        followUpDays,
        relatedMetrics,
        jointMetrics,
      } = metric
      const metricType = relatedMetrics ? 1 : 2
      const isJointMetric = this.isJointMetric(metric, metricDefinitions)
      const isEnabled = this.isEnabled(metric, metricType)

      metricsById[uuid] = {
        uuid,
        name,
        displayName,
        metricType,
        followUpDays,
        isEnabled: isEnabled,
        description,
        calculation,
        jointMetrics,
        isJointMetric: isEnabled && isJointMetric
      }
      
      // Verify related metrics are passed in, if not it is a related metric
      if (relatedMetrics) {
        metricsById[uuid].relatedMetrics = this.formatRelatedMetrics(
          relatedMetrics
        )
      }
    })

    return metricsById
  }

  // LOCAL STATE CHANGES/TOGGLES
  handleSetExperiment = async () => {
    const { onChange, handleSetErrorMessages } = this.props
    const { errorMessage } = this.state

    let { queryQuantum } = this.props
    // eslint-disable-next-line prefer-destructuring
    if (!queryQuantum) queryQuantum = this.queryQuantum

    const {
      metrics: { queryQuantumError, noQuantumMetricsError },
    } = copyContent

    // Reset any previous query error
    if (errorMessage.queryQuantumError) {
      await this.setState({
        errorMessage: { ...errorMessage, queryQuantumError: null },
      })
    }

    try {
      await this.setState({
        isLoadingQuantum: true,
      })

      const { metricDefinitions } = await queryQuantum(GET_QUANTUM_METRICS)
      const metricsById = this.formatRelatedMetrics(metricDefinitions)
      const metricEnabled = Object.values(metricsById).find(
        ({ isEnabled }) => isEnabled
      )
      // Grab enabled joint metrics if any
      const enabledMetricsWithJointMetric = Object.values(metricsById).find(
        ({ isEnabled, jointMetrics, isJointMetric }) => (isEnabled && jointMetrics.length && !isJointMetric)
      )

      let relatedMetricsById = {}

      if (metricEnabled) {
        relatedMetricsById = metricEnabled.relatedMetrics
      }

      if (enabledMetricsWithJointMetric) {
        // Find all joint metrics
        const metricName = Object.values(enabledMetricsWithJointMetric?.relatedMetrics).filter(({ isEnabled, jointMetrics, isJointMetric }) => (isEnabled && jointMetrics.length && !isJointMetric)).map(({ displayName }) => displayName)
        await this.setState({
          metricWithJointMetrics: `${enabledMetricsWithJointMetric.displayName}, ${metricName.join(', ')}`
        })
      }

      if (!Object.values(metricsById).length) {
        handleSetErrorMessages(noQuantumMetricsError)
      }

      await this.setState({
        metricsById,
        relatedMetricsById,
        isLoadingQuantum: false,
      })

      onChange(false)
    } catch (error) {
      const input = formatLoggingError(error)

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input,
        },
      })

      handleSetErrorMessages(queryQuantumError)

      await this.setState({
        isLoadingQuantum: false,
        errorMessage: {
          ...errorMessage,
          queryQuantumError,
        },
      })
    }
  }

  handleChange = async (event, metric, metricTypeId) => {
    const { onChange } = this.props
    const { metricsById, relatedMetricsById } = this.state
    let updatedRelatedMetrics = relatedMetricsById

    if (event) {
      event.preventDefault()
    }

    const newMetrics = { ...metricsById }

    if (metricTypeId === 1) {
      // Grabs isEnabled boolean from metric for joint metric check
      const isMetricSelectedEnabled = !newMetrics[metric.uuid].isEnabled
      newMetrics[metric.uuid].isEnabled = !newMetrics[metric.uuid].isEnabled
      // Checks if metric has joint metrics
      if (metric.jointMetrics.length) {
        // Goes through each joint metric and enables based on 
        // the parent enablement above
        metric.jointMetrics.forEach((jointMetric) => {
          newMetrics[jointMetric._id].isEnabled = isMetricSelectedEnabled
          newMetrics[jointMetric._id].isJointMetric = isMetricSelectedEnabled
        })

        await this.setState(({ metricWithJointMetrics: prevJointMetric }) => {
          let metricName = metric.displayName
          if (prevJointMetric) {
            metricName = `${prevJointMetric}, ${metric.displayName}`
          }
          return ({ metricWithJointMetrics: isMetricSelectedEnabled ? metricName : prevJointMetric })
        })
      }
      updatedRelatedMetrics = this.handleGetAllRelatedMetrics(
        Object.values(metricsById)
      )
    } else if (metricTypeId === 2) {
      // Grabs isEnabled boolean from metric for joint metric check
      const isMetricSelectedEnabled = !updatedRelatedMetrics[metric.uuid].isEnabled
      updatedRelatedMetrics[metric.uuid].isEnabled = isMetricSelectedEnabled
      // Checks if metric has joint metrics
      if (metric.jointMetrics.length) {
        // Goes through each joint metric and enables based on 
        // the parent enablement above
        metric.jointMetrics.forEach((jointMetric) => {
          // Check if enabled as secondary metric and if that metric is even present in metrics set
          if (updatedRelatedMetrics[jointMetric._id] && !updatedRelatedMetrics[jointMetric._id]?.isEnabled) {
            updatedRelatedMetrics[jointMetric._id].isEnabled = isMetricSelectedEnabled
            updatedRelatedMetrics[jointMetric._id].isJointMetric = isMetricSelectedEnabled
          }
        })
        await this.setState(({ metricWithJointMetrics: prevJointMetric }) => {
          let metricName = metric.displayName
          if (prevJointMetric) {
            metricName = `${prevJointMetric}, ${metric.displayName}`
          }
          return ({ metricWithJointMetrics: isMetricSelectedEnabled ? metricName : prevJointMetric })
        })
      }
    }

    await this.setState(({ errorMessage }) => ({
      metricsById: newMetrics,
      relatedMetricsById: updatedRelatedMetrics,
      errorMessage: {
        ...errorMessage,
        mustHaveOneLevelOne: null,
        hasJustOneLevelOne: null,
      },
    }))

    onChange(true)
  }

  handleGetAllRelatedMetrics = metrics => {
    let updatedRelatedMetrics = {}

    metrics.forEach(({ isEnabled, relatedMetrics }) => {
      if (isEnabled) {
        updatedRelatedMetrics = {
          ...updatedRelatedMetrics,
          ...relatedMetrics,
        }
      }
    })

    return updatedRelatedMetrics
  }

  // API Methods
  handleSubmit = async () => {
    const { metricsById, relatedMetricsById, errorMessage } = this.state
    const {
      id: experimentId,
      deleteAndInsertExperimentMetrics: updateMetrics,
    } = this.props

    const experimentMetricsInput = []

    Object.values(metricsById).forEach(metric => {
      if (metric.isEnabled) {
        experimentMetricsInput.push({
          experimentId: Number(experimentId),
          metricUuid: metric.uuid,
          metricTypeId: 1,
          observationalDays: metric.followUpDays,
        })
      }
    })

    Object.values(relatedMetricsById).forEach(metric => {
      if (metric.isEnabled) {
        experimentMetricsInput.push({
          experimentId: Number(experimentId),
          metricUuid: metric.uuid,
          metricTypeId: 2,
          observationalDays: metric.followUpDays,
        })
      }
    })

    // Reset any previous submission errors
    if (errorMessage.deleteAndInsertExperimentMetrics) {
      this.setState({
        errorMessage: {
          ...errorMessage,
          deleteAndInsertExperimentMetrics: null,
        },
      })
    }
    try {
      await updateMetrics({
        variables: {
          VALUES: experimentMetricsInput,
        },
      })

      await this.setState({ metricsById, relatedMetricsById })
    } catch (error) {
      const input = formatLoggingError(error, { id: experimentId })

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input,
        },
      })

      this.setState(({ oldErrorMessage }) => ({
        errorMessage: {
          ...oldErrorMessage,
          deleteAndInsertExperimentMetrics:
            copyContent.metrics.deleteAndInsertExperimentMetrics,
        },
      }))
    }
    return true
  }

  queryQuantum = async query => {
    const {
      queryData: { retrieveQuantumToken: quantumToken },
      productUuid,
      experimentStartDate,
    } = this.props

    const quantumClient = createQuantumApolloClient({
      quantumToken,
      fetch,
    })

    const res = await quantumClient.query({
      query,
      variables: {
        applicationID: productUuid,
        startDate: experimentStartDate || dayjs().format('YYYY-MM-DD'),
      },
    })

    return res.data
  }

  // RENDER METHODS
  renderMetric = metric => {
    const { disabled } = this.props
    const { metricsById, relatedMetricsById } = this.state

    const enabledMetrics = this.getEnabledMetrics(
      Object.values(metricsById)
    ).concat(this.getEnabledMetrics(Object.values(relatedMetricsById)))

    const metricIsEnabledInOtherCategory = enabledMetrics.find(
      ({ uuid, isEnabled, metricType }) =>
        isEnabled && uuid === metric.uuid && metricType !== metric.metricType
    )

    return (
      <div key={metric.uuid} className="experiment-metrics__metric">
        <LabeledToggle
          label={metric.displayName}
          active={metric.isEnabled}
          maxWidth="100%"
          onClick={
            disabled || metricIsEnabledInOtherCategory || metric.isJointMetric
              ? null
              : event => this.handleChange(event, metric, metric.metricType)
          }
        />
        <MetricDefModal metricDef={metric} />
      </div>
    )
  }

  renderLevelOneTwoMetrics = () => {
    const {
      errorMessage: { mustHaveOneLevelOne, hasJustOneLevelOne },
      metricsById,
      relatedMetricsById,
    } = this.state
    const { disabled } = this.props
    const {
      metrics: {
        levelOneTitle,
        levelOneDescription,
        levelTwoTitle,
        levelTwoDescription,
      },
    } = copyContent
    let error = false

    let levelOneMetrics = Object.values(metricsById).sort(
      ({ name: metricAName }, { name: metricBName }) => {
        if (metricAName < metricBName) {
          return -1
        }
        if (metricAName > metricBName) {
          return 1
        }
        return 0
      }
    )

    let levelTwoMetrics = Object.values(relatedMetricsById).sort(
      ({ name: metricAName }, { name: metricBName }) => {
        if (metricAName < metricBName) {
          return -1
        }
        if (metricAName > metricBName) {
          return 1
        }
        return 0
      }
    )

    if (disabled) {
      levelOneMetrics = levelOneMetrics.filter(
        metric => metric && metric.isEnabled
      )
      levelTwoMetrics = levelTwoMetrics.filter(
        metric => metric && metric.isEnabled
      )
    }

    if (mustHaveOneLevelOne || hasJustOneLevelOne) {
      error = true
    }

    return (
      <KiteCard
        className={`experiment-metrics__card ${
          error ? 'experiment-metrics__card-error' : ''
        }`}
      >
        <div className="part experiment-metrics__level-one">
          <h5>{levelOneTitle}</h5>
          <p>{levelOneDescription}</p>
          <div className="experiment-metrics__pill-container">
            {levelOneMetrics.map(metric => metric && this.renderMetric(metric))}
          </div>
        </div>

        {levelTwoMetrics.length > 0 && (
          <div className="part experiment-metrics__level-two">
            <h5>{levelTwoTitle}</h5>
            <p>{levelTwoDescription}</p>
            <div className="experiment-metrics__pill-container">
              {levelTwoMetrics.map(
                metric => metric && this.renderMetric(metric)
              )}
            </div>
          </div>
        )}
      </KiteCard>
    )
  }

  render() {
    const {
      queryData: { loading },
    } = this.props

    const {
      errorMessage: { mustHaveOneLevelOne, hasJustOneLevelOne },
      isLoadingQuantum,
      metricWithJointMetrics
    } = this.state
    let error

    if (loading || isLoadingQuantum) {
      return (
        <div className="app__loader">
          <KiteLoader size="7rem" />
        </div>
      )
    }

    if (mustHaveOneLevelOne || hasJustOneLevelOne) {
      error = (
        <React.Fragment>
          {!mustHaveOneLevelOne ? '' : <div>{mustHaveOneLevelOne || ''}</div>}
          {!hasJustOneLevelOne ? '' : <div>{hasJustOneLevelOne || ''}</div>}
        </React.Fragment>
      )
    }

    return (
      <div className="experiment-metrics">
        <div className="experiment-metrics__header">
          <h4 className="experiment-metrics__title">Manage Metrics</h4>
        </div>
        <ExpansionPanel
          className="set-up-experiment__error-message experiment-metrics__error-message"
          type="minimal"
          isExpanded={!!error}
        >
          {error}
        </ExpansionPanel>
        <ExpansionPanel
          className="set-up-experiment__error-message experiment-metrics__error-message"
          type="minimal"
          isExpanded={!!metricWithJointMetrics}
        >
          <KiteAlert
            className="experiment-metrics__info-banner"
            description={`[${metricWithJointMetrics}] require calculation of an initial and a final metric, which must be paired and therefore are selected for you. Please reach out to your Data Science contact with any questions.`}
            level="page"
            title="Additional Metric(s) Enforced"
            type="info"
            useDark={true}
          />
        </ExpansionPanel>
        {this.renderLevelOneTwoMetrics()}
      </div>
    )
  }
}

const NewMetrics = flowRight(
  gqlHOCQuery,
  deleteAndInsertExperimentMetrics
)(Metrics)

class MetricsSwitcher extends Component {
  ref = null

  static propTypes = {
    experimentCreatedDate: string,
  }

  static defaultProps = {
    experimentCreatedDate: null,
  }

  // Ref methods
  getErrorMessage = () => this.ref.getErrorMessage()

  validateSubmission = async () => this.ref.validateSubmission()

  handleSubmit = () => this.ref.handleSubmit()

  render() {
    const { featureFlags, applicationPermissions } = client.readQuery({
      query: gql`
        query getCurrentUser {
          applicationPermissions {
            id
            name
          }
          featureFlags {
            id
            name
            active
            lastModifiedTime
          }
        }
      `,
    })

    const { experimentCreatedDate } = this.props

    const isQuantumMetricsOn = isFeatureFlagActive({
      featureFlags,
      featureFlagId: featureFlagEnum.QUANTUM_METRICS,
      applicationPermissions,
    })
    const { lastModifiedTime } = featureFlags.find(
      ({ id }) => id === featureFlagEnum.QUANTUM_METRICS
    )

    // Need to check for feature flag and day experiment was created
    // Comparing to last modified time of feature flag
    if (
      !isQuantumMetricsOn ||
      Date.parse(experimentCreatedDate) < Date.parse(lastModifiedTime)
    ) {
      return (
        <OldMetrics
          {...this.props}
          ref={component => {
            this.ref =
              component && component.getWrappedInstance().getWrappedInstance()
          }}
        />
      )
    }

    return (
      <NewMetrics
        {...this.props}
        ref={component => {
          this.ref =
            component && component.getWrappedInstance().getWrappedInstance()
        }}
      />
    )
  }
}

export default MetricsSwitcher
