import { gql } from '@apollo/client'

export const GET_AUDIENCE = gql`
  query getAudience($id: Int!) {
    audience(id: $id) {
      id
      name
      description
      rules
      content {
        contentId
        contentTitle
      }
      isPersonalizing
    }
  }
`
