export const KNOWN_ROLES = {
  2: {
    id: 2,
    order: 0,
    isRequired: true,
    displayName: 'Experimenter',
  },
  5: {
    id: 5,
    order: 1,
    isRequired: true,
    displayName: 'Product Owner',
  },
  4: {
    id: 4,
    order: 2,
    isRequired: true,
    displayName: 'Technical Contact',
  },
  3: {
    id: 3,
    order: 3,
    isRequired: false,
    displayName: 'Administrators',
    allowMultipleUsers: true,
  },
  6: {
    id: 6,
    order: 4,
    isRequired: true,
    displayName: 'Data Science Contact',
    allowMultipleUsers: false,
  },
}
