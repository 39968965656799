import { REACT_APP_DE_EXPERIMENT_CONFIG_API_URL as API_URL } from '../../../../../configuration/configApiClient'
import uuidv4 from 'uuid/v4'
const axios = require('axios')

export const saveVariantUpload = async (experimentId, file) => {
  const fileUuid = uuidv4().replace(/-/g, '')
  const formData = new FormData()
  formData.append("file", file);
  formData.append("fileUuid", fileUuid);
  formData.append("fileName", file.name);
  try {
    const data = await axios.post(`${API_URL}/file`, formData, { headers: {'Content-Type': 'multipart/form-data'}})
    //variants page expects to have s3 data in this data return
    return data
  }
  catch (error) {
    return error
  }
}

export const deleteVariantUploadFromS3 = async (fileUuid) => {
  try {
    const data = await axios.delete(`${API_URL}/file/${fileUuid}`)
    return data
  }
  catch (error) {
    return error
  }
}
