import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

/*
 *  Input: the id of the experiment
 */
export const GET_EXPERIMENT_METRICS = gql`
  query getExperimentMetrics($id: Int!) {
    metricsForExperiment(experimentId: $id) {
      id
      name
      uuid
      displayName
      isEnabled
      metricType {
        id
        name
      }
      metricUnitType {
        id
        name
        abbreviation
      }
      metricImpactDirectionType {
        id
        name
      }
    }
    retrieveQuantumToken(quantumEnum: { overrideTypeId: QUANTUM_METRIC })
  }
`

export const GET_EXPERIMENT_METRICS_GQLBUILDER = {
  query: `
    metricsForExperiment(experimentId: $id) {
      id
      name
      uuid
      displayName
      isEnabled
      metricType {
        id
        name
      }
      metricUnitType {
        id
        name
        abbreviation
      }
      metricImpactDirectionType {
        id
        name
      }
    }
    retrieveQuantumToken(quantumEnum: { overrideTypeId: QUANTUM_METRIC })
  `,
  variables: {
    $id: 'Int!',
  },
  options: {
    fetchPolicy: 'cache-and-network',
  },
}

const getExperimentMetrics = graphql(GET_EXPERIMENT_METRICS, {
  name: 'experimentMetricsData',
  options: {
    fetchPolicy: 'cache-and-network',
  },
  withRef: true,
})

export default getExperimentMetrics
