import React, { Component, Fragment } from 'react'
import { KiteButton, KiteSelect } from '@kite/react-kite'
import { TextArea } from '@kite/react-kite-plus'
import { object, func, string, number, oneOfType, bool } from 'prop-types'
import {
  experimentEnvironmentEnum,
  experimentStatusEnum,
  willTerminateCancelExperiment,
} from '@charter/distillery-rules'
import flowRight from 'lodash/flowRight'

import { client } from '../../configuration/configApiClient'
import { LOG_ERROR } from '../../shared/mutations'
import { formatLoggingError } from '../../shared/utilities'
import { DialogContainer, Modal } from '../../componentLibrary'
import terminateExperiment from './mutations/terminateExperiment'
import copyContent from './data/copyContent'
import './CancelCompleteButton.scss'

const { PRODUCTION, DEVELOPMENT, ENGNEW, ENGPROD } = experimentEnvironmentEnum

const { RUNNING } = experimentStatusEnum

const FINAL_OUTCOME_MIN = 30
const FINAL_OUTCOME_MAX = 500

// TODO: move to distillery-rules
const experimentEnvironmentEnumReverse = {
  [DEVELOPMENT]: 'Development',
  [ENGNEW]: 'Engnew',
  [ENGPROD]: 'Engprod',
  [PRODUCTION]: 'Production',
}

export class CancelComplete extends Component {
  initialState = {
    shouldShowConfirmModal: false,
    isCallingMutation: false,
    finalOutcome: '',
    winnerVariantId: '',
    errorMessage: {
      terminateError: null,
      finalOutcome: null,
      finalOutcomeMaxError: null,
      finalOutcomeMinError: null,
    },
  }

  static propTypes = {
    id: oneOfType([string, number]).isRequired,
    experiment: object.isRequired,
    selectedEnvironment: object.isRequired,
    startWithModal: bool,
    className: string,
    terminateExperiment: func,
    onSuccess: func,
    onNevermind: func,
    onError: func,
    activeNetworkLockdown: bool,
  }

  static defaultProps = {
    terminateExperiment: null,
    className: null,
    onSuccess: null,
    onError: null,
    onNevermind: null,
    startWithModal: false,
  }

  state = {
    ...this.initialState,
    shouldShowConfirmModal: this.props.startWithModal,
  }

  // Lifecycle methods

  // LOCAL STATE CHANGES/TOGGLES

  handleWinnerVariantChange = async ({ target: { value } }) => {
    await this.setState({
      winnerVariantId: Number(value),
    })
  }

  handleFinalOutcomeChange = async ({ target: { value } }) => {
    await this.setState({
      finalOutcome: value,
    })
    this.handleValidate()
  }

  handleNeverMind = () => {
    const { onNevermind } = this.props
    if (onNevermind) {
      onNevermind()
    }
    this.setState({ ...this.initialState })
  }

  handleValidate = async () => {
    const { errorMessage, finalOutcome } = this.state

    if (
      errorMessage.finalOutcomeMaxError ||
      errorMessage.finalOutcomeMinError
    ) {
      await this.setState({
        errorMessage: {
          ...errorMessage,
          finalOutcomeMinError: null,
          finalOutcomeMaxError: null,
        },
      })
    }

    if (
      finalOutcome === '' ||
      (finalOutcome !== '' && finalOutcome.length < FINAL_OUTCOME_MIN)
    ) {
      await this.setState({
        errorMessage: {
          ...errorMessage,
          finalOutcomeMinError: copyContent.finalOutcomeMinError,
        },
      })

      return false
    }

    if (finalOutcome.length > FINAL_OUTCOME_MAX) {
      await this.setState({
        errorMessage: {
          ...errorMessage,
          finalOutcomeMaxError: copyContent.finalOutcomeMaxError,
        },
      })

      return false
    }
    return true
  }

  // API METHODS
  submitTerminate = async cancelCompleteText => {
    const {
      id: experimentId,
      onSuccess,
      onError,
      selectedEnvironment: { id: environmentId },
      terminateExperiment: terminate,
    } = this.props
    const { errorMessage, winnerVariantId, finalOutcome } = this.state
    const status = cancelCompleteText === 'Cancel' ? 'CANCELLED' : 'COMPLETED'

    try {
      await this.setState({ isCallingMutation: true })
      await terminate({
        variables: {
          id: Number(experimentId),
          environmentId,
          finalOutcome,
          winnerVariantId: winnerVariantId || null,
          experimentStatus: status,
        },
      })

      await this.setState({
        isCallingMutation: false,
        shouldShowConfirmModal: false,
      })

      if (onSuccess) {
        onSuccess(status)
      }
    } catch (error) {
      const input = formatLoggingError(error, { id: experimentId })

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input,
        },
      })

      await this.setState({
        isCallingMutation: false,
        errorMessage: {
          ...errorMessage,
          terminateError: copyContent.terminateError,
        },
      })

      if (onError) {
        onError(error)
      }
    }

    return true
  }

  // RENDER METHODS

  renderFinalOutcome({ submitButtonText, cancelCompleteText }) {
    const {
      experiment: { variants },
    } = this.props

    const {
      finalOutcome,
      winnerVariantId,
      isCallingMutation,
      errorMessage: { finalOutcomeMinError, finalOutcomeMaxError },
    } = this.state

    const isDisabled = isCallingMutation

    return (
      <DialogContainer>
        <div className="cancel-complete-modal">
          <div className="cancel-complete-modal__title">
            Experiment Final Outcome
          </div>
          <KiteSelect
            className="cancel-complete-modal__winner-variant"
            placeholder={copyContent.winnerVariantPlaceholder}
            value={winnerVariantId}
            width="100%"
            onChange={this.handleWinnerVariantChange}
          >
            {variants.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </KiteSelect>

          <TextArea
            className="cancel-complete-modal__final-outcome"
            name="final-outcome"
            label=""
            tooltip=""
            value={finalOutcome}
            onChange={this.handleFinalOutcomeChange}
            placeholder={copyContent.finalOutcomePlaceholder}
            width="100%"
            height="170px"
            maxLength={FINAL_OUTCOME_MAX}
            errorMessage={finalOutcomeMinError || finalOutcomeMaxError}
          />
          <div className="cancel-complete-modal__button-wrapper">
            <KiteButton
              disabled={isDisabled}
              onClick={async () => {
                if (await this.handleValidate()) {
                  this.submitTerminate(cancelCompleteText)
                }
              }}
            >
              {submitButtonText}
            </KiteButton>
            <KiteButton
              style={{ color: '#0073d1' }}
              type="standalone-link"
              size="small"
              onClick={this.handleNeverMind}
            >
              {copyContent.cancelTerminateText}
            </KiteButton>
          </div>
        </div>
      </DialogContainer>
    )
  }

  renderModal({ isDisabled, cancelCompleteText, submitButtonText }) {
    const {
      selectedEnvironment: { id: environmentId },
    } = this.props

    return (
      <Modal
        message={`Are you sure you want to ${cancelCompleteText.toLowerCase()} this experiment?`}
        subMessage={`This ${cancelCompleteText.toLowerCase()}s the experiment only in ${
          experimentEnvironmentEnumReverse[environmentId]
        }.`}
        onConfirm={this.submitTerminate}
        onDeny={this.handleNeverMind}
        loading={isDisabled}
        buttonProps={{
          confirm: { value: submitButtonText },
          cancel: { value: copyContent.cancelTerminateText },
        }}
      />
    )
  }

  render() {
    const {
      className,
      experiment: { startTime, durationNumDays },
      selectedEnvironment: {
        id: environmentId,
        sampleAllocation,
        experimentStatusId,
      },
      activeNetworkLockdown,
    } = this.props

    const { shouldShowConfirmModal, isCallingMutation } = this.state

    const isProd = environmentId === PRODUCTION
    const isRunning = experimentStatusId === RUNNING

    const showCancel =
      isProd && sampleAllocation > 0
        ? willTerminateCancelExperiment({
            startTime,
            durationNumDays,
          })
        : false
    const cancelCompleteText = showCancel ? 'Cancel' : 'Complete'

    const submitButtonText = isCallingMutation
      ? `${showCancel ? 'Cancelling...' : 'Completing...'}`
      : `${cancelCompleteText} Experiment`

    const modalParams = {
      cancelCompleteText,
      submitButtonText,
    }

    return (
      <Fragment>
        {shouldShowConfirmModal &&
          (isProd && isRunning
            ? this.renderFinalOutcome(modalParams)
            : this.renderModal(modalParams))}
        <KiteButton
          style={
            showCancel
              ? { color: '#f57878', display: 'flex', margin: 0 }
              : { display: 'flex', margin: 0 }
          }
          type="standalone-link"
          size="small"
          className={className}
          onClick={() => {
            this.setState({ shouldShowConfirmModal: true })
          }}
          disabled={activeNetworkLockdown}
        >
          {cancelCompleteText}
        </KiteButton>
      </Fragment>
    )
  }
}

export default flowRight(terminateExperiment)(CancelComplete)
