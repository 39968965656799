import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

const UPDATE_NOTIFICATIONS_AS_READ = gql`
  mutation updateNotificationsAsRead($input: ReadUserNotifications!) {
    updateNotificationsAsRead(input: $input)
  }
`

const updateNotificationsAsRead = graphql(UPDATE_NOTIFICATIONS_AS_READ, {
  name: 'updateNotificationsAsRead',
})

export default updateNotificationsAsRead
