import React, { useState } from 'react'
import { object, string, func } from 'prop-types'
import flowRight from 'lodash/flowRight'
import { KiteButton } from '@kite/react-kite'
import { CounterInput } from '@kite/react-kite-plus'

import { client } from '../../configuration/configApiClient'
import { LOG_ERROR } from '../../shared/mutations'
import { GET_EXPERIMENTS } from '../../shared/queries'
import { formatLoggingError } from '../../shared/utilities'
import { DialogContainer } from '../../componentLibrary'
import cloneExperiment from './mutations/cloneExperiment'
import copyContent from './data/copyContent'
import './CloneButton.scss'

const initialErrors = {
  name: '',
  response: '',
}

export const CloneButtonComponent = ({
  className,
  history,
  experiment,
  cloneExperiment: clone,
}) => {
  const [isCloneDialogShown, handleToggleCloneDialog] = useState(false)
  const [newExperimentName, handleChangeExperimentName] = useState('')
  const [isLoading, handleSetLoading] = useState(false)
  const [errorMessage, handleSetErrorMessage] = useState(initialErrors)

  const { id, name, isCmsExperiment } = experiment

  const {
    cloneButton,
    cloneDialogHeader,
    cloneInputLabel,
    cloneInputPlaceholder,
    cloneSubmit,
    cloneCancel,
    errorDuplicateName,
    errorResponse,
  } = copyContent

  const handleSubmit = async () => {
    handleSetLoading(true)
    try {
      const input = {
        experimentId: id,
        name: newExperimentName.trim(),
      }

      const response = await clone({
        variables: {
          input,
        },
        update: (proxy, { data: { cloneExperiment: clonedExperiment } }) => {
          const data = proxy.readQuery({ query: GET_EXPERIMENTS })

          proxy.writeQuery({
            query: GET_EXPERIMENTS,
            data: { experiments: [...data.experiments, clonedExperiment] },
          })
        },
      })

      const {
        data: {
          cloneExperiment: { id: newExperimentId },
        },
      } = response
      const path = !isCmsExperiment
        ? `/experiments/${newExperimentId}/set-up/variants`
        : `/experiments/${newExperimentId}/set-up/variants&content`
      history.push(path)
    } catch (error) {
      const errorInput = formatLoggingError(error)

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input: errorInput,
        },
      })

      if (error.message.includes('duplicated experiment name')) {
        handleSetErrorMessage({
          ...errorMessage,
          name: errorDuplicateName,
        })
      } else {
        handleSetErrorMessage({
          ...errorMessage,
          response: errorResponse,
        })
      }
    } finally {
      handleSetLoading(false)
    }
  }

  const cancelClone = () => {
    handleToggleCloneDialog(false)
    handleSetErrorMessage(initialErrors)
    handleChangeExperimentName('')
  }

  const hasError = Object.keys(errorMessage).find(key => errorMessage[key])

  return (
    <div className="clone-button">
      <KiteButton
        className={`clone-button__button ${className}`}
        type="standalone-link"
        onClick={() => handleToggleCloneDialog(true)}
      >
        {cloneButton}
      </KiteButton>

      {isCloneDialogShown && (
        <DialogContainer>
          <div className="clone-button__dialog">
            <h2 className="clone-button__dialog-header">
              {cloneDialogHeader(name)}
            </h2>

            <CounterInput
              className="clone-button__dialog-input"
              label={cloneInputLabel}
              value={newExperimentName}
              placeholder={cloneInputPlaceholder}
              maxCharCount={35}
              noSpecialCharacters
              onChange={({ target: { value } }) => {
                handleChangeExperimentName(value)

                if (hasError) {
                  handleSetErrorMessage({})
                }
              }}
              inputProps={{
                autoFocus: true,
                onKeyDown: ({ key }) => {
                  if (key === 'Escape') {
                    cancelClone()
                  }
                },
              }}
              errorMessage={errorMessage.name || errorMessage.response}
              maxWidth="100%"
            />

            <KiteButton
              className="clone-button__dialog-submit"
              onClick={handleSubmit}
              loading={isLoading}
              disabled={newExperimentName === '' || isLoading}
            >
              {cloneSubmit}
            </KiteButton>

            <KiteButton
              className="clone-button__dialog-cancel"
              type="standalone-link"
              onClick={cancelClone}
            >
              {cloneCancel}
            </KiteButton>
          </div>
        </DialogContainer>
      )}
    </div>
  )
}

CloneButtonComponent.propTypes = {
  history: object.isRequired,
  experiment: object.isRequired,
  cloneExperiment: func.isRequired,
  className: string,
}

CloneButtonComponent.defaultProps = {
  className: '',
}

export default flowRight(cloneExperiment)(CloneButtonComponent)
