import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

export const GET_DREM_EXPERIMENT_SETTINGS = gql`
  query dremExperimentSettings($id: Int!) {
    dremExperimentSettings(experimentId: $id) {
      id
      rule
      sessionTypeId
      sessionType {
        id
        name
        displayName
        overrideTypeId
      }
    }
  }
`

const getDremExperimentSettings = graphql(GET_DREM_EXPERIMENT_SETTINGS, {
  name: 'dremExperimentSettings',
  withRef: true,
  options: {
    fetchPolicy: 'network-only',
  },
})

export default getDremExperimentSettings
