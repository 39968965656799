import { gql } from '@apollo/client'

/*
* Sample Input
*{
  input: {
    applicationName: "DistilleryUI",
    logLevel: "ERROR",
    logMessages: "Unknown Error Occurred",
    experimentId: 1,
    experimentName: "Best Experiment",
  }
}
*/

const LOG_ERROR = gql`
  mutation logError($input: DistilleryLog!) {
    distilleryLog(input: $input)
  }
`

export default LOG_ERROR
