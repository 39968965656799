export default {
  tdcsUpdateTitle: 'TDCS Update Required',
  tdcsUpdateDescription: (environmentNames, isNetworkLockdownActive) => {
    if (!isNetworkLockdownActive || !environmentNames.includes('Production')) {
      return `The following environments require republication to TDCS: ${environmentNames.join(
        ', '
      )}.`
    } else {
      return `The following environments require republication to TDCS: ${environmentNames.join(
        ', '
      )}. However, there is currently a network lockdown in place. Please perform republication after the lockdown has ended.`
    }
  },
  tdcsUpdateLinkText: 'Click here to view "Sample & Launch"',
  dreUpdateTitle: 'DRE Update Required',
  dreUpdateDescription: environmentNames =>
    `The following environments require republication to DRE: ${environmentNames.join(
      ', '
    )}.`,
}
