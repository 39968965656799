import { gql } from '@apollo/client'

const SET_NEEDS_TECH_APPROVAL = gql`
  mutation setNeedsTechApproval($experimentId: Int!) {
    setNeedsTechApproval(experimentId: $experimentId) {
      id
      isTechPocApproved
    }
  }
`

export default SET_NEEDS_TECH_APPROVAL
