import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

export const GET_USERS = gql`
  query getUsers {
    users {
      id
      displayName
    }
  }
`

export const GET_USERS_GQLBUILDER = {
  query: `
    users {
      id
      displayName
      userToRoles {
        id
        roleId
      }
    }
  `,
  variables: {},
}

const getUsers = graphql(GET_USERS, {
  name: 'usersData',
  withRef: true,
})

export default getUsers
