import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

/* Sample Input
*
* {
  name: String!
  summary: String!
  hypothesis: String!
  targetSampleSize: String
  durationNumDays: Int!
  experimentTypeId: Int!
  config: String!
  productToFeature: {
    productId: Int!
    productFeatureId: Int!
  },
}
*/

const INSERT_EXPERIMENT_PLAN = gql`
  mutation insertExperimentPlan($input: InsertExperimentPlan!) {
    insertExperimentPlan(input: $input) {
      id
      uuid
      config
      name
      summary
      hypothesis
      targetSampleSize
      durationNumDays
      experimentTypeId
      isTechPocApproved
      isCmsExperiment
      experimentStatus {
        id
        name
      }
      experimentToProductFeatures {
        id
        productId
        productFeatureId
        product {
          id
          displayName
        }
        productFeature {
          id
          displayName
        }
      }
    }
  }
`

const insertExperimentPlan = graphql(INSERT_EXPERIMENT_PLAN, {
  name: 'insertExperimentPlan',
  withRef: true,
})

export default insertExperimentPlan
