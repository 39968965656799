import { gql } from '@apollo/client'

/*
* Sample Input
* [
    1,
    2, 
  ]
*/
const DELETE_CONTENT_MAP_TO_USER = gql`
  mutation deleteContentMapToUser(
    $ids: [Int]!
  ) {
    deleteContentMapToUser(ids: $ids)
  }
`

export default DELETE_CONTENT_MAP_TO_USER
