// different cases: 300+d, 57d, 6-7d, add missing data case
// const sampleDataXL = require('./data/exampleCountsExtraLarge.json')
// const sampleDataL = require('./data/exampleCountsLarge.json')
// const sampleDataS = require('./data/exampleCountsSmall.json')
// const sampleDataManyVariants = require('./data/lotsaVariants.json')
// const sampleDataLAllo = require('./data/exampleCountsLargeWithAllo.json')

//in cases where we have anomalous data, we can add those dates to the array here to remove them from graphs
const faultyDataDates = ['2020-11-09', '2021-05-17']

const removeFaultyData = countsByDate => {
  faultyDataDates.forEach(date => {
    const { [date]: dateToRemove } = countsByDate

    if (countsByDate[date]) {
      dateToRemove[0].newAccountsActivated = 0
      dateToRemove[0].newAccountsAllocated = 0
      dateToRemove[0].newDevicesActivated = 0
      dateToRemove[0].newDevicesAllocated = 0
    }
  })
  return countsByDate
}

const formatLineGraphData = data => {
  let countsByDate = data.reduce((countsByDate, entry) => {
    const {
      denverDate,
      variantId: variantUuid,
      cumulativeUniqueDevices,
      cumulativeUniqueAccounts,
    } = entry

    if (denverDate in countsByDate) {
      let [date] = countsByDate[denverDate]
      date[variantUuid + '-Device'] = cumulativeUniqueDevices
      date[variantUuid + '-Account'] = cumulativeUniqueAccounts
      date['totalByAccount'] += cumulativeUniqueAccounts
      date['totalByDevice'] += cumulativeUniqueDevices
    } else {
      countsByDate[denverDate] = [
        {
          date: denverDate,
          truncatedDate: denverDate.slice(-5),
          [variantUuid + '-Device']: cumulativeUniqueDevices,
          [variantUuid + '-Account']: cumulativeUniqueAccounts,
          totalByAccount: cumulativeUniqueAccounts,
          totalByDevice: cumulativeUniqueDevices,
        },
      ]
    }
    return countsByDate
  }, {})

  return formatRechartsArray(countsByDate)
}

const formatRechartsArray = countsByDate => {
  return Object.values(countsByDate).reduce((rechartsArray, [value]) => {
    rechartsArray.push(value)
    return rechartsArray
  }, [])
}

const formatBarChartData = data => {
  const countsByDate = data.reduce((countsByDate, entry) => {
    const {
      dailyNewAccountsActivated,
      dailyNewDevicesAllocated,
      denverDate,
      dailyNewDevicesActivated,
      dailyNewAccountsAllocated,
    } = entry

    if (denverDate in countsByDate) {
      let [date] = countsByDate[denverDate]

      date.newAccountsActivated +=
        dailyNewAccountsActivated >= 0 ? dailyNewAccountsActivated : 0
      date.newAccountsAllocated +=
        dailyNewAccountsAllocated >= 0 ? dailyNewAccountsAllocated : 0
      date.newDevicesActivated +=
        dailyNewDevicesActivated >= 0 ? dailyNewDevicesActivated : 0
      date.newDevicesAllocated +=
        dailyNewDevicesAllocated >= 0 ? dailyNewDevicesAllocated : 0
    } else {
      countsByDate[denverDate] = [
        {
          date: denverDate,
          truncatedDate: denverDate.slice(-5),
          newAccountsActivated:
            dailyNewAccountsActivated >= 0 ? dailyNewAccountsActivated : 0,
          newAccountsAllocated:
            dailyNewAccountsAllocated >= 0 ? dailyNewAccountsAllocated : 0,
          newDevicesActivated:
            dailyNewDevicesActivated >= 0 ? dailyNewDevicesActivated : 0,
          newDevicesAllocated:
            dailyNewDevicesAllocated >= 0 ? dailyNewDevicesAllocated : 0,
        },
      ]
    }
    return countsByDate
  }, {})
  const sanitizedCountsByDate = removeFaultyData(countsByDate)
  return formatRechartsArray(sanitizedCountsByDate)
}

const formatDataForRecharts = data => {
  return {
    barChartData: formatBarChartData(data),
    lineChartData: formatLineGraphData(data),
  }
}

export default formatDataForRecharts
