/* eslint-disable react/no-unused-state */
import React, { Component } from 'react'
import { object, array, func } from 'prop-types'
import flowRight from 'lodash/flowRight'
import isEqual from 'lodash/isEqual'
import {
  roleEnum,
  permissionEnum,
  evaluationStatusEnum,
  evaluationCategoryEnum,
  calculateQualityScore,
} from '@charter/distillery-rules'

import {
  KiteTabs,
  KiteLoader,
  KiteLink,
  KiteIcon,
  KiteButton,
  KiteSelect,
  KiteAlert,
} from '@kite/react-kite'
import { TextArea } from '@kite/react-kite-plus'

import copyContent from './data/copyContent'
import { client } from '../../configuration/configApiClient'

import {
  LOG_ERROR,
  insertExperimentToUsers,
  deleteExperimentToUser,
} from '../../shared/mutations'
import {
  capitalizeString,
  GqlBuilder,
  formatLoggingError,
  scrollToNavigationPosition,
} from '../../shared/utilities'
import updateExperimentEvaluationCard from './mutations/updateExperimentEvaluationCard'

import { ExpansionPanel, DialogContainer } from '../../componentLibrary'

import { GeneralStep, EvaluationFormStep } from './steps'

import getExperimentEvaluationCard from './queries/getExperimentEvaluationCard'
import getEvaluationCategories from './queries/getEvaluationCategories'

import './EvaluationCard.scss'

const gqlHOCQuery = new GqlBuilder().compileHOCQuery([
  getExperimentEvaluationCard,
  getEvaluationCategories,
])

export class EvaluationCard extends Component {
  static propTypes = {
    match: object.isRequired,
    queryData: object.isRequired,
    onNavigate: func.isRequired,
    location: object.isRequired,
    applicationPermissions: array.isRequired,
    updateExperimentEvaluationCard: func.isRequired,
    insertExperimentToUsers: func.isRequired,
    deleteExperimentToUser: func.isRequired,
    history: object.isRequired,
  }

  state = {
    tabs: ['general'],
    selectedStep: 'general',
    quantumEvaluator: false,
    experimentEvaluationStatus: 1,
    errorMessage: null,
    sectionScore: 0,
    sectionTotal: 0,
    changedComment: '',
    loading: {
      submit: false,
    },
    success: null,
    shouldShowModal: false,
    disabled: false,
    filteredEvaluationItems: [],
    filteredSubCategories: [],
    inputValues: {
      rolesAndUsers: [],
      ids: [],
      experimentQualityScore: 0,
      evaluationItems: {},
    },
    isDirty: false,
  }

  // LIFECYCLE METHODS
  componentDidMount() {
    const {
      queryData: { loading, experimentEvaluationCard },
    } = this.props

    this.setState({
      quantumEvaluator: this.checkExperimentEvaluatorPermission(),
    })

    if (!loading && experimentEvaluationCard) {
      this.handleSetExperimentEvaluationCard()
    }
  }

  componentDidUpdate(prevProps) {
    const { queryData } = this.props
    const { queryData: prevData } = prevProps

    if (!isEqual(queryData, prevData)) {
      this.handleSetExperimentEvaluationCard()
    }
  }

  checkExperimentEvaluatorPermission = () => {
    const { applicationPermissions } = this.props
    const perm = applicationPermissions.find(
      permission => permission.id === permissionEnum.EVALUATE_EXPERIMENT
    )

    return perm && perm.id === permissionEnum.EVALUATE_EXPERIMENT
  }

  // LOCAL STATE CHANGES/TOGGLES
  handleSetExperimentEvaluationCard = async () => {
    const {
      queryData: {
        experimentEvaluationCard: {
          id,
          evaluationStatusId,
          experimentQualityScore,
          generalComments,
          portfolioCategoryId,
        },
        evaluationCategory,
      },
      history,
      location: { pathname: currentPath },
    } = this.props
    const { tabs } = this.state

    const newTabs = [...tabs]
    const selectedPath = currentPath.split('/')
    const selectedStep = selectedPath.slice(-1).pop().replace('-', ' ')

    evaluationCategory.forEach(tab => {
      newTabs.push(tab.displayName.toLowerCase())
    })

    await this.setState(({ inputValues }) => ({
      experimentEvaluationStatus: evaluationStatusId,
      tabs: [...new Set(newTabs)],
      inputValues: {
        ...inputValues,
        experimentQualityScore,
        generalComments,
        portfolioCategoryId,
      },
      selectedStep,
    }))
    const path = `/experiment-evaluation/${id}/${selectedStep}`

    // Updated the evaluation items for the selected step
    await this.handleChange(selectedStep)
    history.replace(path)
  }

  handleChange = async element => {
    const { history } = this.props
    const {
      inputValues: { evaluationItems },
    } = this.state
    let name = 'selectedStep'
    let value = element
    let filteredSubCategories = []
    let sectionScore = 0
    let sectionTotal = 0
    const updatedFilteredEvaluationItems = []

    if (element.target) {
      const {
        target: { name: targetName, value: targetValue },
      } = element
      name = targetName
      value = targetValue
    } else {
      const {
        queryData: {
          experimentEvaluationCard: { experimentEvaluationItems },
          evaluationSubCategory,
        },
      } = this.props

      const filteredEvaluationItems = experimentEvaluationItems.filter(
        ({
          evaluationItem: {
            evaluationSubCategory: { evaluationCategoryId },
          },
        }) =>
          evaluationCategoryId ===
          evaluationCategoryEnum[element.replace(' ', '_').toUpperCase()]
      )
      sectionTotal = 5 * filteredEvaluationItems.length

      // Go through evaluated filters and if eval state has item, need to update the items to pass back to child
      filteredEvaluationItems.forEach(item => {
        const { id, score } = item
        // Verify item is in evaluation items
        if (evaluationItems[id]) {
          sectionScore += evaluationItems[id].score
          // Update evaluation item
          updatedFilteredEvaluationItems.push({
            ...item,
            score: evaluationItems[id].score,
            commentary: evaluationItems[id].commentary,
          })
        } else {
          sectionScore += score
          updatedFilteredEvaluationItems.push(item)
        }
      })

      filteredSubCategories = evaluationSubCategory.filter(
        ({ evaluationCategoryId }) =>
          evaluationCategoryId ===
          evaluationCategoryEnum[element.replace(' ', '_').toUpperCase()]
      )
    }

    await this.setState(
      ({
        filteredEvaluationItems: prevItems,
        filteredSubCategories: prevCategory,
      }) => ({
        [name]: value,
        filteredEvaluationItems: updatedFilteredEvaluationItems.length
          ? updatedFilteredEvaluationItems
          : prevItems,
        filteredSubCategories: filteredSubCategories.length
          ? filteredSubCategories
          : prevCategory,
        sectionScore,
        sectionTotal,
      })
    )

    if (name === 'selectedStep') {
      const pathArray = history.location.pathname.split('/')
      const newPath = `${pathArray
        .splice(0, pathArray.length - 1)
        .join('/')}/${value.replace(' ', '-')}`

      history.replace(newPath)
    }
  }

  showModal = bool => {
    this.setState({ shouldShowModal: bool })
  }

  handleShowSuccessMessage = () => {
    scrollToNavigationPosition()

    this.setState(({ loading }) => ({
      loading: {
        ...loading,
        submit: false,
      },
      success: {
        title: 'Success',
        description: 'Changes successfully updated.',
      },
    }))

    setTimeout(() => this.setState({ success: null }), 5000)
  }

  // Used by any 'step' component to inform this component of any changes
  handleSetDirty = async (input, typeOfInsert) => {
    const {
      inputValues: { rolesAndUsers, ids, evaluationItems },
    } = this.state
    const {
      queryData: {
        experimentEvaluationCard: {
          experiment: { experimentToUsers },
          experimentEvaluationItems,
        },
      },
    } = this.props
    let completedEvaluation = false
    const newInputValue = {}

    // Verify what is being added to input
    if (typeOfInsert === 'newUser') {
      // Make a copy of the current roles and ids
      newInputValue.rolesAndUsers = [...rolesAndUsers, input]
    } else if (typeOfInsert === 'deleteUser') {
      // Make a copy of the current roles and ids
      const updatedRolesAndUsers = [...rolesAndUsers]

      // Grab the user to delete from the roles of the experiment
      const userToDelete = experimentToUsers.find(
        ({ user: { id: userId }, roleId }) =>
          userId === Number(input) &&
          (roleId === roleEnum.PORTFOLIO_OWNER ||
            roleId === roleEnum.AD_HOC_EXPERIMENT_EVALUATOR)
      )

      // If exists, push the experiment to user ids to delete
      if (userToDelete) {
        newInputValue.ids = [...ids, Number(userToDelete.id)]
      } else {
        // Update the roles to no longer include the new user role
        newInputValue.rolesAndUsers = updatedRolesAndUsers.filter(
          ({ userId }) => userId !== Number(input)
        )
      }
    } else if (typeOfInsert === 'newItems') {
      newInputValue.evaluationItems = {
        ...evaluationItems,
      }
      Object.keys(input).forEach(itemId => {
        newInputValue.evaluationItems[itemId] = {
          ...evaluationItems[itemId],
          ...input[itemId],
        }
      })
      completedEvaluation =
        experimentEvaluationItems.find(
          ({ id, score }) =>
            score === null && !newInputValue.evaluationItems[id]
        ) === undefined
    } else {
      newInputValue[typeOfInsert] = input
    }

    await this.setState(({ inputValues, experimentEvaluationStatus }) => ({
      inputValues: {
        ...inputValues,
        ...newInputValue,
      },
      experimentEvaluationStatus: completedEvaluation
        ? evaluationStatusEnum.COMPLETE
        : experimentEvaluationStatus,
      isDirty: true,
    }))
  }

  // API METHODS
  handleSubmit = async () => {
    const {
      experimentEvaluationStatus,
      inputValues: { rolesAndUsers, ids, evaluationItems },
      inputValues,
      changedComment,
      isDirty,
    } = this.state

    const {
      queryData: {
        experimentEvaluationCard: {
          id: evaluationCardId,
          experiment: { id: experimentId },
          experimentEvaluationItems,
        },
      },
      updateExperimentEvaluationCard: updateEvaluationCard,
      insertExperimentToUsers: insertUsers,
      deleteExperimentToUser: deleteUsers,
      history,
    } = this.props

    await this.setState({
      loading: {
        submit: true,
      },
      errorMessage: null,
    })
    let updatingExperimentToNotEvaluating = false

    if (
      Number(experimentEvaluationStatus) &&
      Number(experimentEvaluationStatus) === evaluationStatusEnum.NOT_EVALUATING
    ) {
      updatingExperimentToNotEvaluating = true
    }

    let wasSuccessful = false
    let err
    const statusName = Object.keys(evaluationStatusEnum).filter(
      key => evaluationStatusEnum[key] === Number(experimentEvaluationStatus)
    )[0]

    try {
      const experimentEvalInput = {
        evaluationCardId,
        evaluationCardInput: {
          evaluationStatusId: Number(experimentEvaluationStatus),
          experimentQualityScore: calculateQualityScore(
            evaluationItems,
            experimentEvaluationItems
          ),
        },
        evaluationItems: [],
        changedComment:
          (changedComment.length > 0 && isDirty) ||
          evaluationStatusEnum.NOT_EVALUATING ===
            Number(experimentEvaluationStatus)
            ? changedComment
            : `Set status to '${capitalizeString(
                statusName.replace('_', ' ').toLowerCase()
              )}'`,
      }

      // Update experiment eval inputs based on inputs
      Object.keys(inputValues).forEach(name => {
        if (
          name !== 'rolesAndUsers' &&
          name !== 'ids' &&
          name !== 'evaluationItems' &&
          name !== 'experimentQualityScore'
        ) {
          experimentEvalInput.evaluationCardInput[name] = inputValues[name]
        } else if (name === 'evaluationItems') {
          Object.keys(evaluationItems).forEach(itemId => {
            experimentEvalInput.evaluationItems.push({
              experimentEvaluationId: Number(itemId),
              score: Number(evaluationItems[itemId].score),
              commentary: evaluationItems[itemId].commentary || '',
            })
          })
        }
      })

      await updateEvaluationCard({
        variables: {
          input: experimentEvalInput,
        },
      })

      if (rolesAndUsers && rolesAndUsers.length) {
        await insertUsers({
          variables: {
            input: {
              experimentID: Number(experimentId),
              rolesAndUsers,
            },
          },
        })
      }

      if (ids && ids.length) {
        await deleteUsers({
          variables: {
            input: {
              ids,
            },
          },
        })
      }

      wasSuccessful = true
    } catch (error) {
      err = error
      const errorInput = formatLoggingError(error, { id: experimentId })

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input: errorInput,
        },
      })
    }
    if (err) {
      // eslint-disable-next-line no-console
      console.log(err)
    }

    await this.setState(({ loading }) => ({
      loading: {
        ...loading,
        submit: false,
      },
      inputValues: {
        ...inputValues,
        evaluationItems: [],
      },
      shouldShowModal: false,
      changedComment: '',
      errorMessage: wasSuccessful
        ? null
        : 'An error occurred when saving the data.',
      isDirty:
        experimentEvaluationStatus !== evaluationStatusEnum.COMPLETE && isDirty,
    }))

    if (wasSuccessful && !updatingExperimentToNotEvaluating) {
      await this.handleShowSuccessMessage()
    } else if (wasSuccessful && updatingExperimentToNotEvaluating) {
      history.replace('/experiment-evaluation/not-evaluating')
    }
  }

  render() {
    const {
      match: {
        params: { id },
      },
      queryData: { loading, experimentEvaluationCard, retrieveQuantumToken },
      onNavigate,
    } = this.props

    const {
      selectedStep,
      quantumEvaluator,
      experimentEvaluationStatus,
      tabs,
      errorMessage,
      loading: { submit },
      changedComment,
      success,
      disabled,
      shouldShowModal,
      filteredEvaluationItems,
      filteredSubCategories,
      inputValues: { generalComments },
      inputValues,
      isDirty,
    } = this.state

    if (loading) {
      return (
        <div>
          <div className="app__loader">
            <KiteLoader size="7rem" />
          </div>
        </div>
      )
    }

    const {
      evaluationStatusId,
      experiment: { id: experimentId, name },
      evaluationStatusId: currentEvalStatus,
      experiment,
      experimentEvaluationAudits,
    } = experimentEvaluationCard

    const callShowModal =
      (isDirty &&
        experimentEvaluationAudits.length > 1 &&
        Number(experimentEvaluationStatus) === evaluationStatusEnum.COMPLETE) ||
      Number(experimentEvaluationStatus) === evaluationStatusEnum.NOT_EVALUATING
    const isDisabled =
      Number(currentEvalStatus) === evaluationStatusEnum.COMPLETE
    const experimentLabel =
      quantumEvaluator &&
      (evaluationStatusId === evaluationStatusEnum.IN_PROGRESS ||
        evaluationStatusId === evaluationStatusEnum.PENDING)
        ? 'Experiment:'
        : 'Evaluating: '

    return (
      <div className="evaluation-card">
        <div className="evaluation-card__header">
          <div className="evaluation-card__experiment-links">
            <div className="evaluation-card__experiment-link">
              <h3>{experimentLabel}</h3>
              <KiteLink
                newTab
                href={`/experiments/${experimentId}/results`}
                type="standalone"
              >
                {name}
                <KiteIcon margin="5px" name="arrow-up-right" size="1rem" />
              </KiteLink>
            </div>
            <hr />
            <KiteLink
              newTab
              href={'/learn/en/experimentation/scoring'}
              type="standalone"
            >
              Experiment Scoring Guidelines
              <KiteIcon margin="5px" name="arrow-up-right" size="1rem" />
            </KiteLink>
          </div>
          {quantumEvaluator && (
            <div className="evaluation-card__experiment-progress-save-btn">
              <KiteSelect
                id="experiment-evaluation-status"
                name="experimentEvaluationStatus"
                value={experimentEvaluationStatus}
                onChange={this.handleChange}
              >
                {Object.keys(evaluationStatusEnum).map(key => (
                  <option key={`opt-${key}`} value={evaluationStatusEnum[key]}>
                    {capitalizeString(key.replace('_', ' ').toLowerCase())}
                  </option>
                ))}
              </KiteSelect>
              <KiteButton
                className="evaluation-card__save-btn"
                loading={submit}
                onClick={() => {
                  if (callShowModal) {
                    this.showModal(true)
                  } else {
                    this.handleSubmit()
                  }
                }}
                size="medium"
                type="primary"
              >
                Save
              </KiteButton>
            </div>
          )}
        </div>
        <ExpansionPanel type="minimal" isExpanded={!!errorMessage}>
          <KiteAlert
            className="app__page-level-message"
            type="alert"
            title="ERROR: "
            description={errorMessage}
            onClose={() => this.setState({ errorMessage: null })}
          />
        </ExpansionPanel>
        <ExpansionPanel type="minimal" isExpanded={!!success}>
          <KiteAlert
            className="app__page-level-message"
            type="confirm"
            title={success ? success.title : ''}
            description={success ? success.description : ''}
          />
        </ExpansionPanel>
        <div className="evaluation-card__accent-color-container">
          <div className="evaluation-card__accent-color">
            <div className="evaluation-card__accent-color-children">
              <KiteTabs
                className="evaluation-card__steps-tabs"
                currentTab={selectedStep}
                tabs={tabs}
                name="selectedStep"
                onSelectTab={this.handleChange}
              />
              {selectedStep === 'general' && (
                <GeneralStep
                  id={Number(id)}
                  onChange={this.handleSetDirty}
                  onNavigate={onNavigate}
                  disabled={disabled}
                  quantumEvaluator={quantumEvaluator}
                  experimentEvaluationCard={experimentEvaluationCard}
                  retrieveQuantumToken={retrieveQuantumToken}
                  newInputs={inputValues}
                  isDisabled={isDisabled}
                  ref={component => {
                    this.general = component
                  }}
                />
              )}

              {selectedStep !== 'general' && (
                <EvaluationFormStep
                  id={Number(id)}
                  onChange={this.handleSetDirty}
                  onNavigate={onNavigate}
                  disabled={disabled}
                  quantumEvaluator={quantumEvaluator}
                  experiment={experiment}
                  generalComments={generalComments}
                  evaluationItems={filteredEvaluationItems}
                  category={selectedStep}
                  isDisabled={isDisabled}
                  retrieveQuantumToken={retrieveQuantumToken}
                  subCategories={filteredSubCategories}
                  ref={component => {
                    this.evaluationform = component
                  }}
                />
              )}
            </div>
          </div>
        </div>

        {shouldShowModal && (
          <DialogContainer>
            <div className="evaluation-card__complete-not-evaluating-modal">
              <div className="evaluation-card__complete-not-evaluating-modal__close-btn">
                <KiteIcon
                  size="14px"
                  name="x"
                  color="#000"
                  onClick={() => {
                    this.showModal(false)
                  }}
                />
              </div>
              <div className="evaluation-card__complete-not-evaluating-modal__title">
                Updating Evaluation
              </div>
              <TextArea
                name="changedComment"
                label={
                  Number(experimentEvaluationStatus) ===
                  evaluationStatusEnum.NOT_EVALUATING
                    ? copyContent.notEvaluatingHeader
                    : copyContent.completingHeader
                }
                value={changedComment}
                onChange={this.handleChange}
                placeholder={
                  Number(experimentEvaluationStatus) ===
                  evaluationStatusEnum.NOT_EVALUATING
                    ? copyContent.notEvaluatingDescription
                    : copyContent.completingDescription
                }
                width="100%"
                height="170px"
                maxLength="1000"
              />
              <div className="evaluation-card__complete-not-evaluating-modal__button-wrapper">
                <KiteButton
                  disabled={changedComment.length < 1 || submit}
                  onClick={this.handleSubmit}
                  loading={submit}
                >
                  Save
                </KiteButton>
                <KiteButton
                  style={{ color: '#0073d1' }}
                  type="standalone-link"
                  size="small"
                  onClick={() => {
                    this.showModal(false)
                  }}
                >
                  Nevermind
                </KiteButton>
              </div>
            </div>
          </DialogContainer>
        )}
      </div>
    )
  }
}

export default flowRight(
  gqlHOCQuery,
  updateExperimentEvaluationCard,
  insertExperimentToUsers,
  deleteExperimentToUser
)(EvaluationCard)
