import React, { Component } from 'react'
import classNames from 'classnames'
import { any, string, bool, func } from 'prop-types'
import './ExpansionPanel.scss'

class ExpansionPanel extends Component {
  state = {
    isExpanded: this.props.isExpanded,
  }

  static propTypes = {
    children: any,
    isExpanded: bool,
    className: string,
    titleClassName: string,
    title: string,
    type: string,
    smallTitle: bool,
    onClick: func,
  }

  static defaultProps = {
    children: null,
    isExpanded: false,
    className: null,
    title: null,
    titleClassName: null,
    type: null,
    smallTitle: null,
    onClick: null,
  }

  componentDidUpdate(prevProps) {
    const { isExpanded } = this.props
    const { isExpanded: prevExpanded } = prevProps

    if (!prevExpanded && isExpanded) {
      setTimeout(() => this.getExpansionChildrenHeight(), 300)
      this.handleToggleExpansion()
    } else if (prevExpanded && !isExpanded) {
      this.handleToggleExpansion()
    }
  }

  getExpansionChildrenHeight = () =>
    this.expansionChildren && this.expansionChildren.clientHeight

  handleToggleExpansion = () => {
    this.setState(({ isExpanded }) => ({ isExpanded: !isExpanded }))
  }

  render() {
    const {
      className,
      title,
      titleClassName,
      children,
      type,
      smallTitle,
      onClick,
    } = this.props
    const { isExpanded } = this.state

    const containerClassNames = classNames({
      'expansion-container': true,
      [className]: className,
      show: isExpanded,
      hide: !isExpanded,
      'no-title': !title,
      [type]: type,
    })

    const childWrapperClassName = classNames({
      'expansion-child-wrapper': true,
      'show-children': isExpanded,
      'hide-children': !isExpanded,
      primary: !type,
      [type]: type,
    })

    const headerClassNames = classNames({
      'expansion-header': true,
      show: isExpanded,
      hide: !isExpanded,
      [type]: type,
      'small-title': smallTitle,
    })

    const iconClassNames = classNames({
      'material-icons': true,
      'expansion-icon': true,
      show: isExpanded,
      hide: !isExpanded,
      [type]: type,
    })

    return (
      <div className={containerClassNames}>
        {title && (
          <div
            className={headerClassNames}
            role="button"
            tabIndex="0"
            onClick={onClick || this.handleToggleExpansion}
            onKeyDown={onClick || this.handleToggleExpansion}
          >
            <h4 className={titleClassName || 'sub-title'}>{title}</h4>
            <i className={iconClassNames}>
              {type === 'light' ? 'arrow_drop_down' : 'keyboard_arrow_down'}
            </i>
          </div>
        )}

        <div
          className={childWrapperClassName}
          ref={element => {
            this.expansionChildren = element
          }}
        >
          {children}
        </div>
      </div>
    )
  }
}

export default ExpansionPanel
