import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

/*
* Sample Input
*
* {
  overrideId: Int!
  overrideTypeId: Int
  productIds: [Int]
  name: CustomString
  overrideIdentifier: CustomString
  overrideGroupIds: [Int]
}
*/

export const updateOverrideMutation = {
  mutation: `
    updateOverride(input: $updateOverrideInput) {
      id
      name
      overrideIdentifier
      overrideType {
        id
        name
      }
      products {
        id
        displayName
      }
      overrideGroups {
        id
        name
      }
    }
  `,
  name: 'updateOverride',
  variables: {
    $updateOverrideInput: 'UpdateOverrideType!',
  },
}

const UPDATE_OVERRIDE = gql`
  mutation updateOverride($input: UpdateOverrideType!) {
    updateOverride(input: $input) {
      id
      name
      overrideIdentifier
      overrideType {
        id
        name
      }
      products {
        id
        displayName
      }
      overrideGroups {
        id
        name
      }
    }
  }
`

const updateOverride = graphql(UPDATE_OVERRIDE, {
  name: 'updateOverride',
})

export default updateOverride
