import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

const DELETE_OVERRIDE_GROUP = gql`
  mutation deleteOverrideGroup($overrideGroupId: Int!) {
    deleteOverrideGroup(overrideGroupId: $overrideGroupId)
  }
`

const deleteOverrideGroup = graphql(DELETE_OVERRIDE_GROUP, {
  name: 'deleteOverrideGroup',
})

export default deleteOverrideGroup
