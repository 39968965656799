import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import { object, func } from 'prop-types'
import flowRight from 'lodash/flowRight'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import queryString from 'query-string'
import {
  checkOverride,
  checkOverrideGroup,
  overrideTypeEnum,
} from '@charter/distillery-rules'

import {
  KiteAlert,
  KiteButton,
  KiteIcon,
  KiteLoader,
  KiteSelect,
} from '@kite/react-kite'

import {
  FilterSearch,
  SortableTable,
  MultiSelectSearch,
  CounterInput,
  AutoSuggest,
} from '@kite/react-kite-plus'

import { client } from '../../configuration/configApiClient'
import { LOG_ERROR } from '../../shared/mutations'
import { GET_PRODUCTS_GQLBUILDER } from '../../shared/queries'
import {
  formatLoggingError,
  GqlBuilder,
  titleCaseString,
} from '../../shared/utilities'
import { ExpansionPanel, Modal } from '../../componentLibrary'
import copyContent from './data/copyContent'

import { getOverrideTypes, getOverrides, getOverrideGroups } from './queries'

import {
  insertOverride,
  updateOverride,
  deleteOverride,
  insertOverrideGroup,
  updateOverrideGroup,
  deleteOverrideGroup,
} from './mutations'

import './OverrideManagementOld.scss'

const gqlHOCQuery = new GqlBuilder().compileHOCQuery([
  GET_PRODUCTS_GQLBUILDER,
  getOverrideGroups,
  getOverrideTypes,
  getOverrides,
])

export const initialFormValues = {
  accountFormValues: {
    name: '',
    overrideTypeId: '',
    overrideIdentifier: '',
    products: [],
    groups: [],
  },
  groupFormValues: {
    name: '',
    overrideIds: [],
  },
}

const { ACCOUNT, DEVICE, ACCOUNT_DEVICE } = overrideTypeEnum

export class OverrideManagement extends Component {
  static propTypes = {
    user: object.isRequired,
    location: object.isRequired,
    history: object.isRequired,
    match: object.isRequired,
    onNavigate: func.isRequired,
    queryData: object.isRequired,
    insertOverride: func.isRequired,
    updateOverride: func.isRequired,
    deleteOverride: func.isRequired,
    insertOverrideGroup: func.isRequired,
    updateOverrideGroup: func.isRequired,
    deleteOverrideGroup: func.isRequired,
  }

  state = {
    currentTab: null,
    experimentProps: null,
    filterSearchValue: null,
    filteredOverrides: null,
    areControlsOpen: false,
    productSearchValue: '',
    groupSearchValue: '',
    idSearchValue: '',
    viewingDetailsIds: [],
    isDeleteOverrideModalOpen: false,
    overrideToDelete: null,
    success: null,
    loading: {},
    errorMessage: {},
    ...initialFormValues,
  }

  // LIFECYCLE METHODS
  componentDidMount() {
    this.handleSetCurrentlyViewing()
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props
    const { location: prevLocation } = prevProps

    if (
      location &&
      prevLocation &&
      location.pathname !== prevLocation.pathname
    ) {
      this.handleSetCurrentlyViewing()
    }
  }

  // LOCAL STATE CHANGES/TOGGLES
  handleSearchOverrides = (filteredOverrides, searchValue) => {
    this.setState({
      filteredOverrides: searchValue ? filteredOverrides : null,
      filterSearchValue: searchValue || null,
    })
  }

  handleToggleOverrideControls = () => {
    this.setState(({ areControlsOpen }) => ({
      areControlsOpen: !areControlsOpen,
      errorMessage: {},
      productSearchValue: '',
      groupSearchValue: '',
      idSearchValue: '',
      ...initialFormValues,
    }))
  }

  handleFormChange = ({ target: { name, value } }) => {
    const formNameArray = name.split('__')
    const form = formNameArray[0]
    const formField = formNameArray[1]

    this.setState(prevState => ({
      [form]: {
        ...prevState[form],
        [formField]: value,
      },
      errorMessage: {
        ...prevState.errorMessage,
        [formField]: null,
      },
    }))
  }

  handleFormSearch = ({ target: { name } }, value) => {
    this.setState({ [name]: value })
  }

  handleAccountDeviceIdChange = (overrides, value, type = 'Account') => {
    const { errorAccountDeviceSearch } = copyContent

    const override = overrides.find(
      ({ overrideIdentifier }) => overrideIdentifier === value
    )

    if (override) {
      return this.handleAccountDeviceIdChangeFromOverride(override, type)
    } else
      this.setState(existingState => {
        const {
          errorMessage: existingErrorMessage,
          accountFormValues,
        } = existingState

        const shouldError = !value || value.length < 3

        const errorMessage = shouldError
          ? existingErrorMessage
          : {
              ...existingErrorMessage,
              [`${type.toLowerCase()}Id`]: errorAccountDeviceSearch(
                value,
                type
              ),
            }
        const {
          overrideIdentifier: existingOverrideIdentifier,
        } = accountFormValues

        const newState = {
          accountFormValues: {
            ...accountFormValues,
            overrideIdentifier: this.formatOverrideIdentifier({
              type,
              value,
              existingOverrideIdentifier,
            }),
          },
          errorMessage,
        }

        return newState
      })
  }

  splitOverrideIdentifier = overrideIdentifier => {
    // In this case the overrideIdentifier is concatenated with an underscore
    // account-0000-0000-0000_device-0000-0000-0000
    if (
      overrideIdentifier &&
      overrideIdentifier.length &&
      !(overrideIdentifier.split && overrideIdentifier.split('_').length === 2)
    ) {
      throw Error(
        `Unable to split overrideIdentifier ${overrideIdentifier} by an underscore _.`
      )
    }
    return overrideIdentifier ? overrideIdentifier.split('_') : ['', '']
  }

  formatOverrideIdentifier = ({ existingOverrideIdentifier, value, type }) => {
    const [existingAccountId, existingDeviceId] = this.splitOverrideIdentifier(
      existingOverrideIdentifier
    )

    const isAccount = type === 'Account'
    const accountId = isAccount ? value : existingAccountId
    const deviceId = !isAccount ? value : existingDeviceId

    return `${accountId}_${deviceId}`
  }

  handleAccountDeviceIdChangeFromOverride = (override, type = 'Account') => {
    this.setState(
      ({ accountFormValues, errorMessage: existingErrorMessage }) => {
        const {
          overrideIdentifier: existingOverrideIdentifier,
        } = accountFormValues

        let idKey = `${type.toLowerCase()}Id`

        const overrideIdentifier = this.formatOverrideIdentifier({
          existingOverrideIdentifier,
          type,
          value: override.overrideIdentifier,
        })

        const newState = {
          accountFormValues: {
            ...accountFormValues,
            overrideIdentifier,
          },
          errorMessage: {
            ...existingErrorMessage,
            [idKey]: '',
          },
        }
        return newState
      }
    )
  }

  handleUpdateProductSelections = products => {
    this.setState(({ accountFormValues, errorMessage }) => ({
      productSearchValue: '',
      accountFormValues: {
        ...accountFormValues,
        products,
      },
      errorMessage: {
        ...errorMessage,
        productIds: null,
      },
    }))
  }

  handleUpdateGroupSelections = groups => {
    this.setState(({ accountFormValues }) => ({
      groupSearchValue: '',
      accountFormValues: {
        ...accountFormValues,
        groups,
      },
    }))
  }

  handleUpdateIDSelection = overrideIds => {
    this.setState(({ groupFormValues, errorMessage }) => ({
      idSearchValue: '',
      groupFormValues: {
        ...groupFormValues,
        overrideIds,
      },
      errorMessage: {
        ...errorMessage,
        overrideIds: null,
      },
    }))
  }

  handleToggleIsViewingDetails = id => {
    this.setState(({ viewingDetailsIds }) => ({
      viewingDetailsIds: viewingDetailsIds.includes(id)
        ? viewingDetailsIds.filter(selected => selected !== id)
        : [...viewingDetailsIds, id],
    }))
  }

  handleSelectItemToEdit = selectedItem => {
    const { currentTab } = this.state

    const formValues =
      currentTab === 'groups'
        ? {
            groupFormValues: selectedItem,
          }
        : {
            accountFormValues: selectedItem,
          }

    this.setState({
      areControlsOpen: true,
      ...formValues,
    })
  }

  handleSelectItemToDelete = overrideToDelete => {
    this.setState({ isDeleteOverrideModalOpen: true, overrideToDelete })
  }

  handleShowSuccessMessage = (action, name) => {
    const { currentTab } = this.state
    const { successSubmitTitle, successSubmitDescription } = copyContent

    this.setState({
      success: {
        title: successSubmitTitle(action, currentTab),
        description: successSubmitDescription(action, name.replace(/\\/g, '')),
      },
    })

    setTimeout(() => this.setState({ success: null }), 5000)
  }

  handleShowSubmitErrorMessage = (action, name) => {
    const {
      errorSubmitTitle,
      errorSubmitDescription,
      errorSubmitLink,
    } = copyContent

    this.setState(({ errorMessage }) => ({
      errorMessage: {
        ...errorMessage,
        submit: {
          title: errorSubmitTitle(action, name.replace(/\\/g, '')),
          description: errorSubmitDescription,
          linkText: errorSubmitLink,
        },
      },
    }))
  }

  handleShowSubmitInfoMessage = type => {
    const { errorNoChanges } = copyContent

    this.setState(({ errorMessage }) => ({
      errorMessage: {
        ...errorMessage,
        noChangesMade: errorNoChanges(type),
      },
    }))

    setTimeout(
      () =>
        this.setState(({ errorMessage }) => ({
          errorMessage: {
            ...errorMessage,
            noChangesMade: null,
          },
        })),
      5000
    )
  }

  // API METHODS
  handleSubmitOverride = async () => {
    const { accountFormValues } = this.state
    const input = this.formatOverrideInput(accountFormValues)

    if (input.id) {
      this.handleUpdateOverride(input)
    } else {
      this.handleInsertOverride(input)
    }
  }

  handleInsertOverride = async input => {
    const { insertOverride: insertNewOverride } = this.props
    const { errorDuplicateID } = copyContent

    try {
      await insertNewOverride({
        variables: {
          input,
        },
        update: this.handleRefreshData,
      })

      this.handleToggleOverrideControls()
      this.handleShowSuccessMessage('create', input.name)
    } catch (error) {
      if (error.message.includes('Duplicate entry')) {
        this.setState(({ errorMessage }) => ({
          errorMessage: {
            ...errorMessage,
            overrideIdentifier: errorDuplicateID,
          },
        }))
      } else {
        this.handleShowSubmitErrorMessage('create', input.name)
      }

      const errorInput = formatLoggingError(error)

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input: errorInput,
        },
      })
    }
  }

  handleRefreshData = proxy => {
    const {
      queryData: { refetch },
    } = this.props

    this.setState({ filteredOverrides: null, filterSearchValue: null })

    refetch()
  }

  handleUpdateOverride = async input => {
    const { updateOverride: updateExistingOverride } = this.props

    const updateInput = this.formatUpdateInput(input)

    if (Object.keys(updateInput).length === 1) {
      this.handleShowSubmitInfoMessage('override')

      return
    }

    try {
      await updateExistingOverride({
        variables: {
          input: updateInput,
        },
        update: this.handleRefreshData,
      })

      this.handleToggleOverrideControls()
      this.handleShowSuccessMessage('update', input.name)
    } catch (error) {
      this.handleShowSubmitErrorMessage('update', input.name)

      const errorInput = formatLoggingError(error)

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input: errorInput,
        },
      })
    }
  }

  handleSubmitGroup = async () => {
    const { groupFormValues } = this.state

    const input = this.formatOverrideGroupInput(groupFormValues)

    if (input.id) {
      this.handleUpdateGroup(input)
    } else {
      this.handleInsertGroup(input)
    }
  }

  handleInsertGroup = async input => {
    const { insertOverrideGroup: insertNewGroup } = this.props
    const { errorDuplicateGroup } = copyContent

    try {
      await insertNewGroup({
        variables: {
          input,
        },
        update: this.handleRefreshData,
      })

      this.handleToggleOverrideControls()
      this.handleShowSuccessMessage('create', input.name)
    } catch (error) {
      if (error.message.includes('Duplicate entry')) {
        this.setState(({ errorMessage }) => ({
          errorMessage: {
            ...errorMessage,
            name: errorDuplicateGroup,
          },
        }))
      } else {
        this.handleShowSubmitErrorMessage('create', input.name)
      }

      const errorInput = formatLoggingError(error)

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input: errorInput,
        },
      })
    }
  }

  handleUpdateGroup = async input => {
    const { updateOverrideGroup: updateExistingGroup } = this.props

    const updateInput = this.formatUpdateInput(input)

    if (Object.keys(updateInput).length === 1) {
      this.handleShowSubmitInfoMessage('group')

      return
    }

    try {
      await updateExistingGroup({
        variables: {
          input: updateInput,
        },
        update: this.handleRefreshData,
      })

      this.handleToggleOverrideControls()
      this.handleShowSuccessMessage('update', input.name)
    } catch (error) {
      this.handleShowSubmitErrorMessage('update', input.name)

      const errorInput = formatLoggingError(error)

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input: errorInput,
        },
      })
    }
  }

  handleDeleteOverride = async () => {
    const { overrideToDelete, areControlsOpen } = this.state
    const { deleteOverride: deleteExistingOverride } = this.props

    try {
      await deleteExistingOverride({
        variables: {
          overrideId: overrideToDelete.id,
        },
        update: this.handleRefreshData,
      })

      if (areControlsOpen) {
        this.handleToggleOverrideControls()
      }

      this.handleShowSuccessMessage('delete', overrideToDelete.name)
    } catch (error) {
      this.handleShowSubmitErrorMessage('delete', overrideToDelete.name)

      const errorInput = formatLoggingError(error)

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input: errorInput,
        },
      })
    } finally {
      this.setState({ isDeleteOverrideModalOpen: false })
    }
  }

  handleDeleteGroup = async () => {
    const { overrideToDelete, areControlsOpen } = this.state
    const { deleteOverrideGroup: deleteExistingGroup } = this.props

    try {
      await deleteExistingGroup({
        variables: {
          overrideGroupId: overrideToDelete.id,
        },
        update: this.handleRefreshData,
      })

      if (areControlsOpen) {
        this.handleToggleOverrideControls()
      }

      this.handleShowSuccessMessage('delete', overrideToDelete.name)
    } catch (error) {
      this.handleShowSubmitErrorMessage('delete', overrideToDelete.name)

      const errorInput = formatLoggingError(error)

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input: errorInput,
        },
      })
    } finally {
      this.setState({ isDeleteOverrideModalOpen: false })
    }
  }

  // FORMATTING METHODS
  formatViewingProps = () => {
    const { currentTab } = this.state
    const {
      queryData: { overrides, overrideGroups },
    } = this.props

    const {
      headerGroup,
      headerID,
      filterSearchPlaceholderGroup,
      filterSearchPlaceholderID,
      createGroup,
      cancelCreateGroup,
      addId,
      cancelAddId,
      noOverrides,
      noOverrideGroups,
    } = copyContent

    const header = currentTab === 'groups' ? headerGroup : headerID
    const createButtonValue = currentTab === 'groups' ? createGroup : addId
    const discardButtonValue =
      currentTab === 'groups' ? cancelCreateGroup : cancelAddId
    const noDataMessage =
      currentTab === 'groups' ? noOverrideGroups : noOverrides

    const filterKeys =
      currentTab === 'groups'
        ? ['name', 'values']
        : ['name', 'overrideIdentifier']
    const searchPlaceholder =
      currentTab === 'groups'
        ? filterSearchPlaceholderGroup
        : filterSearchPlaceholderID

    const viewingOverrides =
      currentTab === 'groups'
        ? this.formatOverrideGroups(overrideGroups)
        : this.formatOverrides(overrides)

    const renderedControls =
      currentTab === 'groups'
        ? this.renderGroupControls()
        : this.renderAccountControls(viewingOverrides)

    const viewingOverrideColumns =
      currentTab === 'groups'
        ? [
            {
              label: 'Group Name',
              sortKey: 'name',
              sortEnabled: false,
              size: 1.25,
            },
            {
              label: 'Type',
              sortKey: 'type',
              sortEnabled: false,
              size: 0.75,
            },
            {
              label: 'Override IDs',
              sortKey: 'values',
              sortEnabled: false,
              size: 1.7,
            },
            {
              label: '\xa0',
              sortKey: 'itemControls',
              sortEnabled: false,
              size: 0.5,
            },
          ]
        : [
            {
              label: 'ID',
              sortKey: 'idNameContainer',
              sortEnabled: false,
              size: 1.25,
            },
            {
              label: 'ID Type',
              sortKey: 'type',
              sortEnabled: false,
              size: 0.75,
            },
            {
              label: 'Product(s)',
              sortKey: 'productValues',
              sortEnabled: false,
              size: 1.7,
            },
            {
              label: '\xa0',
              sortKey: 'itemControls',
              sortEnabled: false,
              size: 0.5,
            },
          ]

    return {
      header,
      filterKeys,
      searchPlaceholder,
      createButtonValue,
      discardButtonValue,
      noDataMessage,
      viewingOverrides,
      viewingOverrideColumns,
      renderedControls,
    }
  }
  formatOverrides = overrides => {
    const {
      accountFormValues: { id: selectedId },
    } = this.state

    const formattedOverrides = overrides.map(override => {
      const {
        id,
        name,
        overrideIdentifier,
        overrideType,
        products,
        overrideGroups,
      } = override

      const isSelected = selectedId === id

      const itemValues = {
        id,
        name,
        overrideIdentifier,
        products,
        idNameContainer: this.renderOverrideIdName(override),
        productValues: products
          .map(({ displayName, quantumApplicationType }) => {
            if (
              quantumApplicationType === 'CMS' &&
              displayName.slice(0, 3) !== 'CMS'
            ) {
              return `${quantumApplicationType} - ${displayName}`
            }
            return displayName
          })
          .join(', '),
        groups: overrideGroups,
        overrideTypeId: overrideType.id,
        type: overrideType.name.toLowerCase(),
      }

      return {
        ...itemValues,
        itemControls: this.renderItemControls(itemValues, isSelected),
      }
    })

    return formattedOverrides
  }

  formatOverrideGroups = overrideGroups => {
    const {
      groupFormValues: { id: selectedId },
    } = this.state

    const formattedOverrideGroups = overrideGroups.map(group => {
      const { id, name, overrides } = group
      const isSelected = selectedId === id

      const itemValues = {
        id,
        name,
        type: 'group',
        overrideIds: this.formatOverrideSearchValues(overrides),
        values: this.renderGroupValues(id, overrides),
      }

      return {
        ...itemValues,
        itemControls: this.renderItemControls(itemValues, isSelected),
      }
    })

    return formattedOverrideGroups
  }

  formatOverrideInput = values => {
    const {
      id,
      name,
      overrideIdentifier,
      overrideTypeId,
      products,
      groups,
    } = values

    let input = {
      overrideIdentifier,
      name,
      overrideTypeId: overrideTypeId ? Number(overrideTypeId) : null,
      productIds:
        products.length > 0 ? products.map(product => product.id) : [],
      overrideGroupIds: groups.length > 0 ? groups.map(group => group.id) : [],
    }

    if (id) {
      input = { ...input, id }
    }

    return input
  }

  formatOverrideGroupInput = values => {
    const { id, name, overrideIds } = values

    let input = {
      name,
      overrideIds:
        overrideIds.length > 0 ? overrideIds.map(override => override.id) : [],
    }

    if (id) {
      input = { ...input, id }
    }

    return input
  }

  formatUpdateInput = input => {
    const { currentTab } = this.state
    const {
      queryData: { overrideGroups, overrides },
    } = this.props

    const matchingType =
      currentTab === 'groups'
        ? this.formatOverrideGroupInput(
            ...this.formatOverrideGroups([
              overrideGroups.find(group => group.id === input.id),
            ])
          )
        : this.formatOverrideInput(
            ...this.formatOverrides([
              overrides.find(override => override.id === input.id),
            ])
          )

    const baseInput =
      currentTab === 'groups'
        ? { overrideGroupId: input.id }
        : { overrideId: input.id }

    const updatedValues = Object.keys(input).reduce((accumulator, key) => {
      const isValueUpdated = !isEqual(input[key], matchingType[key])

      if (isValueUpdated) {
        accumulator[key] = input[key]
      }

      return accumulator
    }, baseInput)

    return updatedValues
  }

  formatOverrideSearchValues = overrides =>
    overrides.map(override => ({
      ...override,
      searchValue: `${override.name} (${override.overrideIdentifier})`,
    }))

  formatOverrideSuggestions = overrides => {
    const overridesWithSearchValue = this.formatOverrideSearchValues(overrides)

    const formattedSuggestions = overridesWithSearchValue.reduce(
      (accumulator, override) => {
        if (!accumulator[override.overrideType.name]) {
          accumulator[override.overrideType.name] = []
        }

        accumulator[override.overrideType.name] = [
          ...accumulator[override.overrideType.name],
          override,
        ]

        return accumulator
      },
      {}
    )

    return this.formatSearchValuesAlphabetically(
      formattedSuggestions,
      'searchValue'
    )
  }

  formatSearchValuesAlphabetically = (searchObject, sortKey) =>
    Object.keys(searchObject)
      .sort((aKey, bKey) => (aKey > bKey ? 1 : -1))
      .reduce((accumulator, key) => {
        accumulator[key] = searchObject[key].sort((aOverride, bOverride) =>
          aOverride[sortKey] > bOverride[sortKey] ? 1 : -1
        )

        return accumulator
      }, {})

  // VALIDATION METHODS
  validateForm = () => {
    const { currentTab, accountFormValues, groupFormValues } = this.state

    const { errors } =
      currentTab === 'groups'
        ? checkOverrideGroup(this.formatOverrideGroupInput(groupFormValues))
        : checkOverride(this.formatOverrideInput(accountFormValues))

    if (!isEmpty(errors)) {
      this.setState(({ errorMessage }) => ({
        errorMessage: {
          ...errorMessage,
          ...errors,
        },
      }))

      return false
    }

    return true
  }

  // REF METHODS
  handleSetCurrentlyViewing = () => {
    const {
      location: { pathname: path, search },
      match: {
        params: { overrideType },
      },
      onNavigate,
    } = this.props

    let experimentProps = null
    let queryPath = null

    if (search) {
      const values = queryString.parse(search)

      if (values.id && values.status) {
        const { id, status } = values

        experimentProps = { id, status }
        queryPath = `?id=${id}&status=${status}`
      }
    }

    const currentlyViewing = {
      path,
      title: 'Override Management',
      backPath: '/experiments/all',
      backTitle: 'All Experiments',
      tabs: {
        currentTab: overrideType,
        tabs: [
          {
            label: 'id',
            path: experimentProps
              ? `/override-management/id/${queryPath}`
              : '/override-management/id',
          },
          {
            label: 'groups',
            path: experimentProps
              ? `/override-management/groups/${queryPath}`
              : '/override-management/groups',
          },
        ],
      },
    }

    onNavigate(currentlyViewing)

    this.setState({
      experimentProps,
      currentTab: overrideType,
      errorMessage: {},
      areControlsOpen: false,
      viewingDetailsIds: [],
      success: null,
      ...initialFormValues,
    })
  }

  // RENDER METHODS
  renderGroupControls = () => {
    const {
      queryData: { overrides },
    } = this.props
    const {
      groupFormValues: { name, overrideIds },
      idSearchValue,
      errorMessage,
    } = this.state

    const {
      groupNameLabel,
      groupIDLabel,
      groupIDPlaceholder,
      groupIDEmpty,
      groupIDTooltip,
    } = copyContent

    return (
      <div className="override-management__group-controls">
        <CounterInput
          className="override-management__form-input override-management__counter-input"
          name="groupFormValues__name"
          label={groupNameLabel}
          value={name}
          maxCharCount={35}
          onChange={this.handleFormChange}
          errorMessage={errorMessage.name}
          maxWidth="100%"
        />

        <MultiSelectSearch
          className="override-management__form-input"
          name="idSearchValue"
          label={groupIDLabel}
          placeholder={overrides.length > 0 ? groupIDPlaceholder : groupIDEmpty}
          tooltip={groupIDTooltip}
          initialData={this.formatOverrideSuggestions(overrides)}
          suggestionKey="searchValue"
          minCharacters={3}
          value={idSearchValue}
          onChange={this.handleFormSearch}
          defaultSelections={overrideIds}
          onSelectionsChange={this.handleUpdateIDSelection}
          errorMessage={errorMessage.overrideIds}
          maxWidth="100%"
        />
      </div>
    )
  }

  renderAccountDeviceId = (viewingOverrides, type = 'Account') => {
    const { accountFormValues, errorMessage } = this.state

    const { overrideIdentifier } = accountFormValues
    const [accountId, deviceId] = this.splitOverrideIdentifier(
      overrideIdentifier
    )
    const isAccount = type === 'Account'
    const idValue = isAccount ? accountId : deviceId

    let idKey = `${type.toLowerCase()}Id`
    const idLabel = copyContent[`${idKey}Label`]
    const idTooltip = copyContent[`${idKey}Tooltip`]
    const idPlaceholder = copyContent[`${idKey}Placeholder`]

    const overrides = viewingOverrides
      .filter(override => {
        return type === 'Account'
          ? Number(override.overrideTypeId) === ACCOUNT
          : Number(override.overrideTypeId) === DEVICE
      })
      .map(override => {
        const { name, overrideIdentifier } = override
        return {
          ...override,
          searchValue: `${name} (${overrideIdentifier})`,
        }
      })
      .sort((aOverride, bOverride) =>
        aOverride.searchValue > bOverride.searchValue ? 1 : -1
      )

    const dataKey = `${type} ID`

    const error = errorMessage[idKey]

    const notSuccess = !idValue || idValue.length < 3 || error

    const idSuccessClassNames = classNames({
      'override-management__success-icon': true,
      'override-management__success-icon-hidden': notSuccess,
    })

    return (
      <Fragment>
        <AutoSuggest
          className="override-management__form-input"
          name={idKey}
          value={idValue}
          suggestionKey="searchValue"
          initialData={{ [dataKey]: overrides }}
          label={idLabel}
          placeholder={idPlaceholder}
          tooltip={idTooltip}
          minCharacters={3}
          onChange={(event, value) =>
            this.handleAccountDeviceIdChange(overrides, value, type)
          }
          onSuggestionSelected={(event, { suggestion: override }) =>
            this.handleAccountDeviceIdChangeFromOverride(override, type)
          }
          maxWidth="100%"
          errorMessage={error}
        />
        <KiteIcon
          className={idSuccessClassNames}
          name="checkmark"
          size="1.5rem"
        />
      </Fragment>
    )
  }

  renderAccountControls = viewingOverrides => {
    const {
      queryData: { productCategoryTypes, overrideTypes, overrideGroups },
    } = this.props
    const {
      productSearchValue,
      groupSearchValue,
      errorMessage,
      accountFormValues: {
        name,
        overrideIdentifier,
        overrideTypeId,
        products,
        groups,
      },
    } = this.state

    const {
      accountProductLabel,
      accountProductPlaceholder,
      accountProductTooltip,
      accountGroupLabel,
      accountGroupEmpty,
      accountGroupPlaceholder,
      accountGroupTooltip,
    } = copyContent

    const productOptions = productCategoryTypes.reduce(
      (accumulator, category) => {
        accumulator[category.name] = category.products.map(product => (product))

        return accumulator
      },
      {}
    )

    const shouldShowAccountDeviceLine =
      Number(overrideTypeId) === ACCOUNT_DEVICE

    return (
      <div className="override-management__account-controls">
        <div className="override-management__account-input-container">
          <CounterInput
            className="override-management__form-input override-management__counter-input"
            name="accountFormValues__name"
            label="ID Name"
            value={name}
            maxCharCount={35}
            onChange={this.handleFormChange}
            errorMessage={errorMessage.name}
            maxWidth="100%"
          />

          <KiteSelect
            className="override-management__form-input"
            name="accountFormValues__overrideTypeId"
            label="ID Type"
            value={overrideTypeId}
            onChange={this.handleFormChange}
            errorMessage={errorMessage.overrideTypeId}
            maxWidth="100%"
          >
            <option value="" disabled>
              Select a type
            </option>

            {overrideTypes.map(type => (
              <option key={`${type.id}__${type.name}`} value={type.id}>
                {titleCaseString(type.name, '_')}
              </option>
            ))}
          </KiteSelect>

          {!shouldShowAccountDeviceLine ? (
            <CounterInput
              className="override-management__form-input override-management__counter-input"
              name="accountFormValues__overrideIdentifier"
              label="ID"
              value={overrideIdentifier}
              maxCharCount={50}
              onChange={this.handleFormChange}
              errorMessage={errorMessage.overrideIdentifier}
              maxWidth="100%"
            />
          ) : (
            <div style={{ flex: 1 }}></div>
          )}
        </div>

        {shouldShowAccountDeviceLine && (
          <div className="override-management__account-input-container override-management__account-device-row">
            {this.renderAccountDeviceId(viewingOverrides, 'Account')}
            <div className="override-management__plus">
              <KiteIcon name="plus" size="16px" />
            </div>
            {this.renderAccountDeviceId(viewingOverrides, 'Device')}
          </div>
        )}

        <div className="override-management__account-input-container">
          <MultiSelectSearch
            className="override-management__form-input"
            name="productSearchValue"
            label={accountProductLabel}
            placeholder={accountProductPlaceholder}
            tooltip={accountProductTooltip}
            initialData={this.formatSearchValuesAlphabetically(
              productOptions,
              'displayName'
            )}
            suggestionKey="displayName"
            minCharacters={3}
            value={productSearchValue}
            onChange={this.handleFormSearch}
            defaultSelections={products}
            onSelectionsChange={this.handleUpdateProductSelections}
            errorMessage={errorMessage.productIds}
            maxWidth="100%"
          />

          <MultiSelectSearch
            className="override-management__form-input"
            name="groupSearchValue"
            label={accountGroupLabel}
            placeholder={
              overrideGroups.length > 0
                ? accountGroupPlaceholder
                : accountGroupEmpty
            }
            tooltip={accountGroupTooltip}
            initialData={overrideGroups}
            suggestionKey="name"
            minCharacters={3}
            value={groupSearchValue}
            onChange={this.handleFormSearch}
            defaultSelections={groups}
            onSelectionsChange={this.handleUpdateGroupSelections}
            maxWidth="100%"
          />
        </div>
      </div>
    )
  }

  renderOverrideIdName = ({ overrideIdentifier, name }) => (
    <div className="override-management__account-id-container">
      <div className="override-management__account-id">
        {overrideIdentifier}
      </div>
      <div className="override-management__account-name">{name}</div>
    </div>
  )

  renderGroupValues = (id, overrides) => {
    const { viewingDetailsIds } = this.state

    const { groupViewDetails, groupHideDetails } = copyContent

    const isViewingDetails = viewingDetailsIds.includes(id)

    return (
      <div
        className={`override-management__group-values-container ${
          isViewingDetails
            ? 'override-management__group-values-container-expanded'
            : ''
        }`}
      >
        {isViewingDetails ? (
          overrides.map(override => (
            <span
              key={override.id}
              className="override-management__group-expanded-values"
            >
              <span className="override-management__group-expanded-name-container">
                <span className="override-management__group-expanded-name">
                  {override.name}
                </span>
                {` (${override.overrideIdentifier})`}
              </span>
            </span>
          ))
        ) : (
          <span className="override-management__group-collapsed-names">
            {overrides.map(override => override.name).join(',\xa0')}
          </span>
        )}

        <KiteButton
          className={`override-management__group-view-more ${
            isViewingDetails
              ? 'override-management__group-view-more-expanded'
              : ''
          }`}
          type="standalone-link"
          onClick={() => this.handleToggleIsViewingDetails(id)}
        >
          {isViewingDetails ? groupHideDetails : groupViewDetails}
        </KiteButton>
      </div>
    )
  }

  renderItemControls = (selectedItem, isSelected) => (
    <div className="override-management__controls">
      <KiteIcon
        className="override-management__control-button"
        name="edit-f"
        color={isSelected ? '#0073d1' : 'unset'}
        onClick={
          isSelected
            ? this.handleToggleOverrideControls
            : () => this.handleSelectItemToEdit(selectedItem)
        }
      />

      <KiteIcon
        className="override-management__control-button"
        name="trash-f"
        onClick={() => this.handleSelectItemToDelete(selectedItem)}
      />
    </div>
  )

  render() {
    const {
      user,
      history,
      queryData: { loading: dataLoading },
    } = this.props

    const {
      currentTab,
      experimentProps,
      filterSearchValue,
      filteredOverrides,
      areControlsOpen,
      isDeleteOverrideModalOpen,
      overrideToDelete,
      success,
      loading,
      errorMessage,
    } = this.state

    const {
      backToExperiment,
      filterSearchLabel,
      errorFilterSearch,
      saveOverrideGroup,
      deleteConfirmMessage,
      deleteConfirmSubMessage,
    } = copyContent

    if (dataLoading) {
      return (
        <div className="app__loader">
          <KiteLoader size="7rem" />
        </div>
      )
    }

    const {
      header,
      filterKeys,
      searchPlaceholder,
      createButtonValue,
      discardButtonValue,
      noDataMessage,
      viewingOverrides,
      viewingOverrideColumns,
      renderedControls,
    } = this.formatViewingProps()

    return (
      <div className="override-management">
        <div className="override-management__page-controls">
          <FilterSearch
            name="overrideSearch"
            value={filterSearchValue}
            label={filterSearchLabel}
            placeholder={searchPlaceholder}
            filterKeys={filterKeys}
            initialData={viewingOverrides}
            onChange={this.handleSearchOverrides}
            showInvalidText={false}
          />

          {experimentProps && (
            <KiteButton
              className="override-management__experiment-button"
              type="standalone-link"
              size="medium"
              onClick={() =>
                history.push(
                  `/experiments/${experimentProps.id}/rollout/overrides`
                )
              }
              leftIcon="chevron-left"
              status=""
            >
              {backToExperiment}
            </KiteButton>
          )}
        </div>

        <ExpansionPanel type="minimal" isExpanded={!!success}>
          <KiteAlert
            className="app__page-level-message"
            type="confirm"
            title={success && success.title}
            description={success && success.description}
          />
        </ExpansionPanel>

        <ExpansionPanel
          type="minimal"
          isExpanded={!!errorMessage.noChangesMade}
        >
          <KiteAlert
            className="app__page-level-message"
            type="info"
            description={errorMessage.noChangesMade}
          />
        </ExpansionPanel>

        <ExpansionPanel type="minimal" isExpanded={!!errorMessage.submit}>
          <KiteAlert
            className="app__page-level-message"
            type="alert"
            title={errorMessage.submit && errorMessage.submit.title}
            description={errorMessage.submit && errorMessage.submit.description}
            linkText={errorMessage.submit && errorMessage.submit.linkText}
            onLinkClick={() => {
              window.location = `mailto:DL-Distillery-Support@charter.com?subject=Distillery Support for Override Management - ${user.displayName}`
            }}
            onClose={() =>
              this.setState(prevState => ({
                errorMessage: {
                  ...prevState.errorMessage,
                  submit: null,
                },
              }))
            }
          />
        </ExpansionPanel>

        <div className="override-management__header-container">
          <h4 className="override-management__table-header">{header}</h4>

          <KiteButton
            className="override-management__create-button"
            type="standalone-link"
            size="medium"
            onClick={this.handleToggleOverrideControls}
            leftIcon={areControlsOpen ? 'minus' : 'plus'}
          >
            {areControlsOpen ? discardButtonValue : createButtonValue}
          </KiteButton>
        </div>

        <ExpansionPanel
          type="minimal"
          isExpanded={areControlsOpen}
          className="override-management__expansion-container"
        >
          {renderedControls}

          <KiteButton
            className="override-management__submit-button"
            size="large"
            onClick={() => {
              if (this.validateForm()) {
                if (currentTab === 'groups') {
                  this.handleSubmitGroup()
                } else {
                  this.handleSubmitOverride()
                }
              }
            }}
          >
            {saveOverrideGroup}
          </KiteButton>
        </ExpansionPanel>

        <SortableTable
          className="override-management__table"
          tableData={filteredOverrides || viewingOverrides}
          columns={viewingOverrideColumns}
          initialSortHeader="name"
          noDataMessage={
            filterSearchValue
              ? errorFilterSearch(currentTab, filterSearchValue)
              : noDataMessage
          }
        />

        {isDeleteOverrideModalOpen && (
          <Modal
            message={deleteConfirmMessage(overrideToDelete.name)}
            subMessage={deleteConfirmSubMessage}
            onConfirm={
              currentTab === 'groups'
                ? this.handleDeleteGroup
                : this.handleDeleteOverride
            }
            onDeny={() =>
              this.setState({
                isDeleteOverrideModalOpen: false,
                overrideToDelete: null,
              })
            }
            buttonProps={{ confirm: { value: 'Delete' } }}
            loading={loading.delete}
            errorMessage={errorMessage.delete}
          />
        )}
      </div>
    )
  }
}

export default flowRight(
  gqlHOCQuery,
  insertOverride,
  updateOverride,
  deleteOverride,
  insertOverrideGroup,
  updateOverrideGroup,
  deleteOverrideGroup
)(OverrideManagement)
