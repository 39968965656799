import React, { Component } from 'react'
import { func, object, number, array, bool } from 'prop-types'
import flowRight from 'lodash/flowRight'

import { getExperimentContent } from '../queries'
import { getContents } from '../../../../shared/queries'
import getDremExperimentSettings from './queries/getDremExperimentSettings'
import updateDremExperimentSettings from './mutations/updateDremExperimentSettings'
import { hasPermission, permissionEnum } from '@charter/distillery-rules'

import { ExpansionPanel } from '../../../../componentLibrary'
import {
  KiteLoader,
  KiteCard,
  KiteLink,
  KiteRadio,
  KiteAlert,
} from '@kite/react-kite'

import './Audience.scss'
import { formatLoggingError } from '../../../../shared/utilities'
import { client } from '../../../../configuration/configApiClient'
import {
  LOG_ERROR,
  APPROVE_TECH_SETTINGS,
  SET_NEEDS_TECH_APPROVAL,
} from '../../../../shared/mutations'
import copyContent from '../../data/copyContent'

const {
  variantSummaryHeader,
  authOrUnathRuleHeader,
  authSelector,
  unauthSelector,
} = copyContent.audience

const {
  EXPERIMENT_UPDATE_VERSION_RUNNING,
  EXPERIMENT_UPDATE_RUNNING,
  TECH_UPDATE,
} = permissionEnum

const initialErrors = {
  updateDremExperimentSettingsError: null,
  errorAlert: null,
}

export class Audience extends Component {
  static propTypes = {
    id: number,
    applicationPermissions: array.isRequired,
    experimentPermissions: array.isRequired,
    environmentSamplings: array.isRequired,
    contentsData: object.isRequired,
    experimentContentData: object.isRequired,
    onChange: func.isRequired,
    disabled: bool,
    insertExperimentVariants: func,
    updateExperimentVariants: func,
    deleteExperimentVariant: func,
  }

  static defaultProps = {
    id: null,
    contentsData: {},
    disabled: false,
    insertExperimentVariants: null,
    updateExperimentVariants: null,
    deleteExperimentVariant: null,
  }

  state = {
    sessionTypeId: 1,
    isLoading: false,
    errorMessage: initialErrors,
  }

  // LIFECYCLE METHODS
  async componentDidUpdate(prevProps) {
    const {
      dremExperimentSettings: { dremExperimentSettings },
    } = this.props
    const {
      dremExperimentSettings: {
        dremExperimentSettings: prevDremExperimentSettings,
      },
    } = prevProps

    if (!prevDremExperimentSettings && dremExperimentSettings) {
      await this.setState({
        isLoading: true,
      })

      this.handleSetAudience()
    }
  }

  handleSetAudience = async () => {
    const {
      onChange,
      dremExperimentSettings: {
        dremExperimentSettings: { rule, sessionTypeId },
      },
      contentsData,
      experimentConfig,
    } = this.props

    if (rule.includes('authStatus')) {
      const authStat = rule.replace('authStatus=', '').replace(/'/g, '')

      await this.setState({
        sessionTypeId: authStat === 'AUTH' ? 1 : 3,
        isLoading: false,
      })
      await onChange(true)
      return
    } else {
      await this.setState({
        sessionTypeId,
        isLoading: false,
      })

      this.setState({ isLoading: false })
    }

    // Verify if errors occurred in querying content
    if (contentsData.error) {
      this.setState(({ prevErrorMessage }) => ({
        errorMessage: {
          ...prevErrorMessage,
          errorAlert: copyContent.cmsVariants.dreConnectionError,
        },
      }))
    }

    const audienceStep = JSON.parse(experimentConfig).audience
    if (audienceStep && audienceStep.status) {
      await onChange(audienceStep.status === 'initial')
    }

    this.setState({ isLoading: false })
  }

  // Used by parent and sends any existing error messages
  getErrorMessage = () => {
    const { errorMessage } = this.state

    const messages = Object.values(errorMessage).reduce(
      (accumulator, error) => {
        if (!accumulator && error) {
          return `${error}`
        }
        if (error) {
          return `${accumulator}; ${error}`
        }
        return accumulator
      },
      ''
    )

    return messages
  }

  validateSubmission = async () => {
    const { sessionTypeId } = this.state

    await this.setState({ errorMessage: initialErrors })

    if (!sessionTypeId) {
      return false
    }

    return true
  }

  isComplete = () => {
    const { id, canApproveTechSettings } = this.props
    const experimentId = Number(id)

    if (canApproveTechSettings) {
      client.mutate({
        mutation: APPROVE_TECH_SETTINGS,
        variables: {
          experimentId,
        },
      })

      return true
    }

    client.mutate({
      mutation: SET_NEEDS_TECH_APPROVAL,
      variables: {
        experimentId,
      },
    })

    return false
  }

  handleValidatePermissions = permId => {
    const {
      applicationPermissions,
      experimentPermissions,
      environmentSamplings,
      experimentContentData: {
        experiment: { experimentTypeId },
      },
    } = this.props

    const canUpdateRunning =
      experimentPermissions &&
      hasPermission({
        experimentTypeId,
        applicationPermissions,
        experimentPermissions,
        environmentSamplings,
        permissionId: permId,
      })

    return canUpdateRunning
  }

  handleValidateDisable = () => {
    const { disabled } = this.props
    const { isRunningCanaryExperiment } = this.state
    const canUpdateRunning = this.handleValidatePermissions(
      EXPERIMENT_UPDATE_RUNNING
    )
    const canUpdateVersionRunning = this.handleValidatePermissions(
      EXPERIMENT_UPDATE_VERSION_RUNNING
    )
    const techUpdate = this.handleValidatePermissions(TECH_UPDATE)

    let disabledField = disabled

    if (disabled && techUpdate) {
      disabledField = !techUpdate
    }

    // Verify if the disabled prop needs to be
    // overridden due to user having ability to update
    // and experiment being a running canary experiment
    if (
      (canUpdateRunning || canUpdateVersionRunning) &&
      disabled &&
      isRunningCanaryExperiment &&
      techUpdate
    ) {
      disabledField = !(canUpdateRunning || canUpdateVersionRunning)
    }

    return disabledField
  }

  handleChange = async ({ target: { name, value } }) => {
    const { onChange } = this.props

    this.setState({
      [name]: value,
    })

    onChange(true)
  }

  camelCaseToSpaces(text) {
    text = text.replace(
      /([A-Z](?=[A-Z][a-z])|[^A-Z](?=[A-Z])|[a-zA-Z](?=[^a-zA-Z]))/g,
      '$1 '
    )
    text = text.charAt(0).toUpperCase() + text.slice(1)
    return text
  }

  formatObjects(arrayOfObjects) {
    return arrayOfObjects.map(this.formatObject).join(' or ')
  }

  formatObject(obj) {
    return JSON.stringify(obj)
  }

  renderValues(values) {
    if (typeof values === 'string') {
      return values
    }

    if (Array.isArray(values)) {
      if (typeof values[0] === 'object') {
        return this.formatObjects(values)
      }
      return values.join(' or ')
    }

    if (typeof values === 'object') {
      if (values.from && values.to) {
        if (values.from === values.to) {
          return values.from
        }
        return values.from + '-' + values.to
      }
    }

    return ''
  }

  /**
   * Switch the link to Drupal based on the environment. Uses the current
   * Distillery URL from the browser to switch, following these patterns:
   *
   * localhost -> drupal-cms.dev-spectrum.net
   * dev-beta.distillery.spectrumtoolbox.com -> drupal-cms.dev-spectrum.net
   * staging-beta.distillery.spectrumtoolbox.com -> drupal-cms.stage-spectrum.net
   * distillery.spectrumtoolbox.com -> drupal-cms.spectrum.net
   */
  generateCmsLink() {
    const {
      experimentContentData: { experimentContent },
    } = this.props

    if (!experimentContent) {
      return ''
    }

    const currentUrl = window.location.href
    let envPrefix = ''

    const baseUrl = currentUrl.split('//')[1].split('/')[0]

    if (baseUrl.startsWith('localhost') || baseUrl.startsWith('dev')) {
      envPrefix = 'dev-'
    } else if (baseUrl.startsWith('staging')) {
      envPrefix = 'stage-'
    }

    return `https://drupal-cms.${envPrefix}spectrum.net/node/${experimentContent.contentId}`
  }

  // API METHODS
  handleSubmit = async () => {
    const { id, updateDremExperimentSettings } = this.props
    const { sessionTypeId } = this.state

    try {
      await updateDremExperimentSettings({
        variables: {
          input: {
            experimentId: id,
            sessionTypeId: Number(sessionTypeId),
          },
        },
      })

      return true
    } catch (error) {
      const input = formatLoggingError(error, { id })

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input,
        },
      })

      this.setState(prevState => ({
        errorMessage: {
          ...prevState.errorMessage,
          updateDremExperimentSettingsError: 'Error',
        },
      }))

      return false
    }
  }

  renderPersonalization() {
    const {
      experimentContentData: { experimentContent },
    } = this.props

    if (!experimentContent) {
      return
    }

    return (
      <KiteCard className="personalization">
        <div>
          Review the user population for your experiment:
          <ul>{this.renderPersonalizationListEntries()}</ul>
        </div>
        <div>
          <div>
            If this needs to be modified, please make edits in Drupal (VPN
            only):
          </div>
          <div>
            <KiteLink href={this.generateCmsLink()} newTab={true}>
              Edit in Drupal
            </KiteLink>
          </div>
        </div>
      </KiteCard>
    )
  }

  renderPersonalizationListEntries() {
    const {
      experimentContentData: {
        experimentContent: { contentId: experimentContentId },
      },
      contentsData: { contents },
    } = this.props
    const content =
      contents &&
      contents.find(({ contentId }) => contentId === experimentContentId)

    if (content && !content.personalization) {
      return (
        <li className="default-personalization-copy" key="default">
          No personalization set; will act as default content and be visible to
          all users allocated into the experiment
        </li>
      )
    }

    const personalization = content && JSON.parse(content.personalization)

    return (
      personalization &&
      Object.entries(personalization).map(([key, value]) => {
        return (
          <li key={key}>
            {this.camelCaseToSpaces(key)}: {this.renderValues(value)}
          </li>
        )
      })
    )
  }

  renderAuthUnAuth() {
    const { sessionTypeId } = this.state
    const { disabled } = this.props

    return (
      <div>
        <h4>{authOrUnathRuleHeader}</h4>
        <KiteCard className="personalization">
          <KiteRadio
            buttonOrientation="column"
            className=""
            disabled={disabled}
            label=""
            margin=""
            name="sessionTypeId"
            onChange={this.handleChange}
            radioButtons={[
              {
                id: 'auth',
                key: 'auth',
                label: authSelector,
                value: '1',
                checked: sessionTypeId === 1 ? true : null,
              },
              {
                id: 'unauth',
                key: 'unauth',
                label: unauthSelector,
                value: '3',
                checked: sessionTypeId === 3 ? true : null,
              },
            ]}
          />
        </KiteCard>
      </div>
    )
  }

  render() {
    const {
      contentsData: { loading: contentsLoading },
      experimentContentData: {
        loading: experimentContentDataLoading,
        experiment,
      },
      dremExperimentSettings: { loading: experimentSettingsLoading },
    } = this.props
    const {
      isLoading,
      errorMessage: { errorAlert },
    } = this.state

    if (
      isLoading ||
      contentsLoading ||
      experimentContentDataLoading ||
      experimentSettingsLoading
    ) {
      return (
        <div className="app__loader">
          <KiteLoader size="7rem" />
        </div>
      )
    }

    const { variants } = experiment

    const variantNames = variants.map(({ name, displayOrder }) => {
      const variantDisplay =
        displayOrder === 0 ? 'control' : `variant ${displayOrder}`

      return (
        <span key={`variantName-${displayOrder}`}>
          {displayOrder === 0 ? '' : ' + '}
          <span className="audience__variant-name">{name}</span>
          <span>({variantDisplay})</span>
        </span>
      )
    })
    return (
      <div className="audience">
        <ExpansionPanel type="minimal" isExpanded={!!errorAlert}>
          <KiteAlert
            className="app__page-level-message"
            type="alert"
            description={errorAlert}
            linkText="Contact us."
            onLinkClick={() => {
              window.location = copyContent.cmsVariants.errorEmailSubject
            }}
          />
        </ExpansionPanel>
        <h4>{variantSummaryHeader}</h4>
        <div className="audience__variants-container">
          {variantNames.map(variantName => variantName)}
        </div>

        {this.renderPersonalization()}

        {this.renderAuthUnAuth()}
      </div>
    )
  }
}

export default flowRight(
  getContents,
  getExperimentContent,
  updateDremExperimentSettings,
  getDremExperimentSettings
)(Audience)
