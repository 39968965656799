import React, { Component } from 'react'
import flowRight from 'lodash/flowRight'
import { object } from 'prop-types'
import isEqual from 'lodash/isEqual'
import { KiteSelect, KiteSingleLinePanel } from '@kite/react-kite'

import PermissionsPanel from './PermissionsPanel/PermissionsPanel'
import UsersPanel from './UsersPanel/UsersPanel'
import { getRoles } from '../queries'
import './Roles.scss'

class Roles extends Component {
  static propTypes = {
    allRolesData: object,
  }

  static defaultProps = {
    allRolesData: {},
  }

  state = {
    expansionPanelOpen: [],
    selectedRole: {},
  }

  componentDidUpdate(prevProps) {
    const {
      allRolesData: { roles },
    } = this.props
    const {
      allRolesData: { roles: prevRoles },
    } = prevProps

    if (!isEqual(roles, prevRoles)) this.handleUpdateSelectedRole(roles)
  }

  handleSelectedRole = event => {
    const {
      allRolesData: { roles },
    } = this.props
    const role = roles.find(obj => obj.name === event.target.value)

    this.setState({
      expansionPanelOpen: ['permissions-panel', 'users-panel'],
      selectedRole: role || {},
    })
  }

  handleUpdateSelectedRole = roles => {
    const { selectedRole } = this.state
    const role = roles
      ? roles.find(obj => obj.name === selectedRole.name)
      : selectedRole

    this.setState({
      selectedRole: role || {},
    })
  }

  handlePanelClick = panel => {
    const { expansionPanelOpen } = this.state
    const index = expansionPanelOpen.indexOf(panel)

    if (index > -1) {
      expansionPanelOpen.splice(index, 1)
    } else {
      expansionPanelOpen.push(panel)
    }

    this.setState({
      expansionPanelOpen,
    })
  }

  sortRoles = rolesArray =>
    rolesArray.slice().sort((left, right) =>
      left.name.localeCompare(right.name, 'en', {
        sensitivity: 'base',
      })
    )

  render() {
    const {
      allRolesData: { roles },
    } = this.props
    const { expansionPanelOpen, selectedRole } = this.state

    return (
      <div className="roles-tab">
        <KiteSelect
          id="role-select"
          name="role"
          label="Role: "
          value={selectedRole.name || ''}
          onChange={this.handleSelectedRole}
        >
          <option value="" disabled>
            Select Role
          </option>
          {roles &&
            this.sortRoles(roles).map(option => (
              <option key={option.id} value={option.name}>
                {option.name.toLowerCase()}
              </option>
            ))}
        </KiteSelect>

        <div className="roles-tab__expansion-panels-container">
          <KiteSingleLinePanel
            className="roles-tab__permissions-panel"
            title="Permissions"
            isOpen={expansionPanelOpen.includes('permissions-panel')}
            onClick={() => this.handlePanelClick('permissions-panel')}
          >
            <div className="roles-tab__panel-data">
              <PermissionsPanel selectedRole={selectedRole} />
            </div>
          </KiteSingleLinePanel>

          <KiteSingleLinePanel
            className="roles-tab__users-panel"
            title="Users"
            isOpen={expansionPanelOpen.includes('users-panel')}
            onClick={() => this.handlePanelClick('users-panel')}
          >
            <div className="roles-tab__panel-data">
              <UsersPanel selectedRole={selectedRole} />
            </div>
          </KiteSingleLinePanel>
        </div>
      </div>
    )
  }
}

export default flowRight(getRoles)(Roles)
