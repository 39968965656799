import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

const UPDATE_EXPERIMENT_PLAN = gql`
  mutation updateExperimentPlan($input: UpdateExperimentPlan!) {
    updateExperimentPlan(input: $input) {
      id
      name
      summary
      hypothesis
      targetSampleSize
      durationNumDays
      activationEventUuid
      experimentTypeId
      isTechPocApproved
      isCmsExperiment
      experimentToProductFeatures {
        id
        productId
        productFeatureId
        product {
          id
          displayName
        }
        productFeature {
          id
          displayName
        }
      }
    }
  }
`

const updateExperimentPlan = graphql(UPDATE_EXPERIMENT_PLAN, {
  name: 'updateExperimentPlan',
  withRef: true,
})

export default updateExperimentPlan
