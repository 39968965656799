import React, { Component, Fragment } from 'react'
import {
  object,
  func,
  string,
  number,
  oneOfType,
  array,
  bool,
} from 'prop-types'
import flowRight from 'lodash/flowRight'
import cloneDeep from 'lodash/clone'
import { hasPermission, permissionEnum } from '@charter/distillery-rules'
import { KiteButton } from '@kite/react-kite'

import { client } from '../../configuration/configApiClient'
import { Modal } from '../../componentLibrary'
import { GET_EXPERIMENTS } from '../../shared/queries'
import { LOG_ERROR } from '../../shared/mutations'
import { formatLoggingError } from '../../shared/utilities'
import deleteExperiment from './mutations/deleteExperiment'
import copyContent from './data/copyContent'

const { EXPERIMENT_DELETE, EXPERIMENT_DELETE_TESTING } = permissionEnum

export class DeleteExperiment extends Component {
  static propTypes = {
    id: oneOfType([string, number]).isRequired,
    experiment: object.isRequired,
    className: string,
    deleteExperiment: func,
    onSuccess: func,
    onError: func,
    applicationPermissions: array.isRequired,
    activeNetworkLockdown: bool,
  }

  static defaultProps = {
    className: null,
    deleteExperiment: null,
    onSuccess: null,
    onError: null,
  }

  state = {
    shouldShowConfirmModal: false,
    isCallingMutation: false,
    deleteError: null,
  }

  // API METHODS
  submitDelete = async () => {
    const { id: experimentId, onSuccess, onError } = this.props
    const { deleteExperiment: deleteMutation } = this.props

    try {
      await this.setState({ isCallingMutation: true })
      await deleteMutation({
        variables: {
          experimentId: Number(experimentId),
        },
        update: proxy => {
          const experimentsData = cloneDeep(
            proxy.readQuery({
              query: GET_EXPERIMENTS,
            })
          )

          const newExperiments = experimentsData.experiments.filter(
            experiment => experiment.id !== Number(experimentId)
          )

          experimentsData.experiments = newExperiments

          proxy.writeQuery({
            query: GET_EXPERIMENTS,
            data: experimentsData,
          })
        },
      })

      if (onSuccess) {
        onSuccess()
      }
      await this.setState({
        isCallingMutation: false,
        shouldShowConfirmModal: false,
      })
    } catch (error) {
      const errorInput = formatLoggingError(error, { id: experimentId })

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input: errorInput,
        },
      })

      if (onError) {
        onError(error)
      }
    }
  }

  // RENDER METHODS
  render() {
    const {
      applicationPermissions,
      className,
      experiment,
      activeNetworkLockdown,
    } = this.props

    const {
      shouldShowConfirmModal,
      isCallingMutation,
      deleteError,
    } = this.state

    let showDelete = false
    if (experiment) {
      showDelete =
        hasPermission({
          applicationPermissions,
          experimentPermissions: experiment.permissions,
          environmentSamplings: experiment.environmentSamplings,
          permissionId: EXPERIMENT_DELETE,
        }) ||
        hasPermission({
          applicationPermissions,
          experimentPermissions: experiment.permissions,
          environmentSamplings: experiment.environmentSamplings,
          permissionId: EXPERIMENT_DELETE_TESTING,
        })
    }

    if (showDelete) {
      return (
        <Fragment>
          {shouldShowConfirmModal && (
            <Modal
              message={`Are you sure you want to delete ${experiment.name} ?`}
              subMessage={deleteError || copyContent.deleteSubMessage}
              onConfirm={() => {
                this.submitDelete()
              }}
              onDeny={() => this.setState({ shouldShowConfirmModal: false })}
              loading={isCallingMutation}
              buttonProps={{
                confirm: {
                  value: isCallingMutation
                    ? 'Deleting...'
                    : `Delete ${experiment.name}`,
                  type: showDelete ? 'warning-outline' : 'outline',
                },
              }}
            />
          )}
          {showDelete && (
            <KiteButton
              style={{
                color: '#f57878',
                display: 'flex',
                margin: 0,
              }}
              type="standalone-link"
              size="small"
              className={className}
              onClick={() => {
                this.setState({ shouldShowConfirmModal: true })
              }}
              disabled={activeNetworkLockdown}
            >
              Delete
            </KiteButton>
          )}
        </Fragment>
      )
    }
    return null
  }
}

export default flowRight(deleteExperiment)(DeleteExperiment)
