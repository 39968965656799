import { gql } from '@apollo/client'

export const GET_SCHEDULED_LOCKDOWNS = gql`
  query getScheduledNetworkLockdowns {
    scheduledNetworkLockdowns {
      id
      startDate
      endDate
      isImmediate
      preMessage
      duringMessage
    }
  }
`
