import React, { useState, useEffect } from 'react'
import { KiteButton, KiteSelect, KiteInput } from '@kite/react-kite'
import { TextArea } from '@kite/react-kite-plus'
import axios from 'axios'

import isRequestFormComplete from './utilities/isRequestFormComplete'
import formatSupportRequestBody from './utilities/formatSupportRequestBody'

import './support-request-form.scss'

const { REACT_APP_GITLAB_API_AUTH: GITLAB_API_AUTH } = process.env

const supportRequestOptions = [
  'Select Support Request Type',
  'Experiment Support',
  'Bugfix',
  'Feature Request/Enhancement',
  'Other',
]

export default function SupportRequestForm() {
  const [requestFormComplete, setRequestFormComplete] = useState()
  const [supportRequestContent, setSupportRequestContent] = useState({
    email: '',
    supportRequestType: '',
  })
  const [requestFormError, setRequestFormError] = useState('Initial')
  const [requestFormStatus, setRequestFormStatus] = useState()

  async function handleSubmitSupportRequest() {
    if (!isRequestFormComplete(supportRequestContent)) {
      setRequestFormStatus('Incomplete')
    } else {
      try {
        await axios.post(
          'https://gitlab.spectrumflow.net/api/v4/projects/19554/issues',
          {
            title: supportRequestContent.supportRequestType,
            labels: 'Support Request',
            description: formatSupportRequestBody(supportRequestContent),
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${GITLAB_API_AUTH}`,
            },
          }
        )
        setRequestFormStatus('Successful')
      } catch (err) {
        await setRequestFormStatus('Error')
      }
    }
  }

  useEffect(() => {
    if (isRequestFormComplete(supportRequestContent)) {
      setRequestFormComplete(true)
    }
  }, [supportRequestContent, requestFormComplete, requestFormStatus])

  return (
    <div>
      {!(requestFormStatus === 'Successful') && (
        <div className="request-form-container">
          <KiteSelect
            id="supportRequestSelector"
            key="'supportRequestSelector"
            name="kiteSelect"
            className="support-request-selector"
            value={supportRequestContent.supportRequestType}
            label="Support Request Type"
            onChange={event => {
              setSupportRequestContent({
                email: supportRequestContent.email,
                supportRequestType: event.target.value,
              })
              setRequestFormError(false)
              setRequestFormStatus('Initial')
            }}
          >
            {supportRequestOptions.map(requestType => {
              return (
                <option value={requestType} key={requestType}>
                  {requestType}
                </option>
              )
            })}
          </KiteSelect>
          {supportRequestContent.supportRequestType ===
            'Experiment Support' && (
            <div className="experiment-support-container">
              <KiteInput
                className="experiment-link"
                label="Experiment Link"
                placeholder="Please enter a link to your experiment"
                value={supportRequestContent.experimentLink}
                onChange={({ target: { value } }) => {
                  setSupportRequestContent({
                    ...supportRequestContent,
                    experimentLink: value,
                  })

                }}
              ></KiteInput>
              {requestFormStatus === 'Incomplete' &&
                !supportRequestContent.experimentLink && (
                  <p className="experiment-link-error">
                    Please enter an experiment link
                  </p>
                )}
              <KiteInput
                className="experiment-step"
                label="Experiment Step"
                placeholder="What step is causing issues?"
                value={supportRequestContent.experimentStep}
                onChange={({ target: { value } }) => {
                  setSupportRequestContent({
                    ...supportRequestContent,
                    experimentStep: value,
                  })
                }}
              ></KiteInput>
              {requestFormStatus === 'Incomplete' &&
                !supportRequestContent.experimentStep && (
                  <p className="experiment-step-error">
                    Please enter an experiment setup step
                  </p>
                )}
              <TextArea
                className="description"
                label="Description"
                id="description"
                placeholder="Please describe your issue"
                value={supportRequestContent.description}
                onChange={({ target: { value } }) => {
                  setSupportRequestContent({
                    ...supportRequestContent,
                    description: value,
                  })
                }}
              ></TextArea>
              {requestFormStatus === 'Incomplete' &&
                !supportRequestContent.description && (
                  <p className="experiment-description-error">
                    Please enter a description of your issue
                  </p>
                )}
            </div>
          )}
          {supportRequestContent.supportRequestType === 'Bugfix' && (
            <div className="bugfix-container">
              <KiteInput
                className="where-bug-encountered"
                label="Where Bug Encountered"
                placeholder="Where did you encounter the bug?"
                type="multiline"
                value={supportRequestContent.whereBugEncountered}
                onChange={({ target: { value } }) => {
                  setSupportRequestContent({
                    ...supportRequestContent,
                    whereBugEncountered: value,
                  })
                }}
              ></KiteInput>
              {requestFormStatus === 'Incomplete' &&
                !supportRequestContent.whereBugEncountered && (
                  <p className="bug-encountered-error">
                    Please decribe where you encountered the bug
                  </p>
                )}
              <TextArea
                className="steps-to-reproduce"
                label="Steps to Reproduce"
                id="steps-to-reproduce"
                placeholder="How to reproduce issue"
                type="multiline"
                value={supportRequestContent.stepsToReproduce}
                onChange={({ target: { value } }) => {
                  setSupportRequestContent({
                    ...supportRequestContent,
                    stepsToReproduce: value,
                  })
                }}
              ></TextArea>
              {requestFormStatus === 'Incomplete' &&
                !supportRequestContent.stepsToReproduce && (
                  <p className="steps-to-reproduce-error">
                    Please describe how to recreate bug
                  </p>
                )}
            </div>
          )}
          {supportRequestContent.supportRequestType ===
            'Feature Request/Enhancement' && (
            <div className="feature-request-container">
              <TextArea
                className="feature-description"
                label="Feature Description"
                id="feature-description"
                placeholder="Please describe proposed feature"
                type="multiline"
                value={supportRequestContent.featureDescription}
                onChange={({ target: { value } }) => {
                  setSupportRequestContent({
                    ...supportRequestContent,
                    featureDescription: value,
                  })
                }}
              ></TextArea>
              {requestFormStatus === 'Incomplete' &&
                !supportRequestContent.featureDescription && (
                  <p className="feature-description-error">
                    Please describe your proposed feature
                  </p>
                )}
            </div>
          )}
          {supportRequestContent.supportRequestType === 'Other' && (
            <div className="other-container">
              <TextArea
                className="other-details"
                label="Details"
                id="other-details"
                placeholder="Please describe issue"
                type="multiline"
                value={supportRequestContent.other}
                onChange={({ target: { value } }) => {
                  setSupportRequestContent({
                    ...supportRequestContent,
                    other: value,
                  })
                }}
              ></TextArea>
              {requestFormStatus === 'Incomplete' &&
                !supportRequestContent.other && (
                  <p className="other-error">Please describe the issue</p>
                )}
            </div>
          )}
          {supportRequestContent.supportRequestType !== '' && (
            <div>
              <KiteInput
                className="contact"
                label="Contact Email"
                placeholder="Contact Email"
                value={supportRequestContent.email}
                onChange={({ target: { value } }) => {
                  setSupportRequestContent({
                    ...supportRequestContent,
                    email: value,
                  })
                }}
              ></KiteInput>
              {requestFormStatus === 'Incomplete' &&
                !supportRequestContent.email && (
                  <p className="email-error">Please enter a contact email</p>
                )}
              <KiteButton
                onClick={handleSubmitSupportRequest}
                className="submit-button"
                size="md"
              >
                Submit
              </KiteButton>
            </div>
          )}
          {requestFormStatus === 'Error' && (
            <div className="request-submission-error">
              There was an issue submitting your ticket. Please get in touch
              through the Distillery Support channel or email{' '}
              <a href="mailto:DL-DPDATA-Distillery@charter.com?subject=Distillery Support  - Support Request">
                Distillery Support
              </a>
            </div>
          )}
        </div>
      )}
      {requestFormStatus === 'Successful' && !requestFormError && (
        <div className="request-complete-container">
          Thank you for submitting your request! The team will follow up with
          you shortly.
        </div>
      )}
    </div>
  )
}
