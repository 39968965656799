import React, { Component, Fragment } from 'react'
import { object, string, bool, func, array, number } from 'prop-types'
import flowRight from 'lodash/flowRight'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import { CounterInput, TextArea } from '@kite/react-kite-plus'

import { KiteLoader, KiteSelect, KiteInput, KiteRadio } from '@kite/react-kite'

import {
  checkPlan,
  hasPermission,
  permissionEnum,
  experimentTypeEnum,
} from '@charter/distillery-rules'

import { client } from '../../../../configuration/configApiClient'
import { getProducts, GET_EXPERIMENTS } from '../../../../shared/queries'
import {
  LOG_ERROR,
  UPDATE_EXPERIMENT_CONFIG,
} from '../../../../shared/mutations'
import { formatLoggingError } from '../../../../shared/utilities'
import { getExperimentPlan, getProductFeaturesById } from '../../queries'
import {
  insertExperimentPlan,
  updateExperimentPlan,
  updateVariantJsonPayload,
} from '../../mutations'
import copyContent from '../../data/copyContent'
import { CopyButton, ExpansionPanel } from '../../../../componentLibrary'
import './Plan.scss'

const { EXPERIMENT_UPDATE_RUNNING } = permissionEnum

const experimentLengthOptions = [7, 14, 28, 35, 60, 'Other']
const initialErrors = {
  name: null,
  product: null,
  feature: null,
  summary: null,
  hypothesis: null,
  targetSampleSize: null,
  durationNumDays: null,
  getProductFeatures: null,
  duplicateName: null,
  insertPlan: null,
  updatePlan: null,
}

export class Plan extends Component {
  static propTypes = {
    id: number,
    applicationPermissions: array.isRequired,
    experimentPermissions: array,
    environmentSamplings: array,
    experimentPlanData: object,
    productsData: object,
    disabled: bool,
    experimentConfig: string,
    currentlyViewing: object.isRequired,
    onChange: func.isRequired,
    onNavigate: func.isRequired,
    onUpdateProgress: func.isRequired,
    formatBlankExperimentConfig: func.isRequired,
    insertExperimentPlan: func.isRequired,
    updateExperimentPlan: func.isRequired,
    updateVariantJsonPayload: func.isRequired,
    handleUpdateOverrideBanner: func.isRequired,
  }

  static defaultProps = {
    id: null,
    experimentPlanData: null,
    experimentPermissions: null,
    environmentSamplings: null,
    productsData: null,
    disabled: false,
    experimentConfig: null,
  }

  state = {
    uuid: null,
    product: '',
    productDisplayName: '',
    feature: '',
    name: '',
    summary: '',
    hypothesis: '',
    targetSampleSize: '',
    length: '28',
    otherLength: '',
    type: 2,
    productFeatures: null,
    variantIds: [],
    isLoading: false,
    hasMatchingProductPermissions: true,
    errorMessage: initialErrors,
  }

  // LIFECYCLE METHODS
  componentDidMount() {
    const { experimentPlanData } = this.props

    // Set the experiment to state if one exists
    // CDM used when navigating to Plan inside the app
    if (experimentPlanData && experimentPlanData.experiment) {
      this.handleSetExperiment()
    }
  }

  componentDidUpdate(prevProps) {
    const { uuid, isLoading } = this.state
    const { experimentPlanData, productsData } = this.props
    const { experimentPlanData: prevPlanData } = prevProps

    // Set the experiment to state if one exists
    // CDU used when hitting the 'plan' URL directly
    if (
      prevPlanData &&
      productsData.productCategoryTypes &&
      experimentPlanData &&
      experimentPlanData.experiment &&
      isEqual(prevPlanData.experiment, experimentPlanData.experiment) &&
      !uuid &&
      !isLoading
    ) {
      this.handleSetExperiment()
      return
    }

    if (
      prevPlanData &&
      productsData.productCategoryTypes &&
      experimentPlanData &&
      !isEqual(prevPlanData.experiment, experimentPlanData.experiment)
    ) {
      this.handleSetExperiment()
    }
  }

  // LOCAL STATE CHANGES/TOGGLES
  handleSetExperiment = async () => {
    const {
      onChange,
      experimentPlanData: { experiment },
      productsData: { productCategoryTypes },
    } = this.props
    const {
      uuid,
      durationNumDays,
      experimentToProductFeatures,
      experimentTypeId,
      hypothesis,
      targetSampleSize,
      name,
      summary,
      variants,
    } = experiment
    const {
      productId,
      productFeatureId,
      product: { displayName: productDisplayName },
    } = experimentToProductFeatures[0]

    await this.setState({ isLoading: true })
    // Fetch associated features from API to select appropriate feature
    await this.handleGetProductFeatures(productId)

    const hasMatchingProductPermissions =
      productCategoryTypes &&
      productCategoryTypes.filter(category =>
        category.products.find(product => product.id === productId)
      ).length !== 0

    // Check length options for matching length; if no match, otherLength is used
    const match = experimentLengthOptions.find(
      option => option === durationNumDays
    )
    const variantIds = variants.map(({ id }) => id)

    await this.setState({
      uuid,
      name,
      summary,
      hypothesis,
      targetSampleSize,
      variantIds,
      productDisplayName,
      hasMatchingProductPermissions,
      product: productId,
      feature: productFeatureId,
      length: match ? durationNumDays : 'Other',
      otherLength: !match ? durationNumDays : '',
      type: experimentTypeId,
      isLoading: false,
    })

    // Pass 'false' to inform parent that the form does not have changes
    //  and is only loading
    onChange(false)
  }

  handleChange = async ({ target: { name, value } }) => {
    const { onChange } = this.props
    const { feature, otherLength } = this.state

    if (name === 'product') {
      // Product id must be parsed for API
      this.handleGetProductFeatures(JSON.parse(value))

      // If changing product and a feature is selected, reset the feature
      if (feature !== '') {
        await this.setState({ feature: '' })
      }
    }

    if (name === 'length' && otherLength !== '') {
      // Reset otherLength if switching to a provided length
      await this.setState({ otherLength: '' })
    }

    if (name === 'otherLength') {
      const isNumber = /^[0-9\b]+$/

      if (isNumber.test(value) || value === '') {
        // Limit values to only numbers
        await this.setState(({ errorMessage }) => ({
          [name]: value,
          errorMessage: {
            ...errorMessage,
            durationNumDays: null,
          },
        }))
      }
    } else if (name === 'summary' || name === 'hypothesis') {
      await this.setState(({ errorMessage }) => ({
        [name]: value,
        errorMessage: {
          ...errorMessage,
          [name]:
            value.length < 35
              ? `Please add ${name} with minimum of 35 characters.`
              : null,
        },
      }))
    } else {
      // Handles change of all other values
      await this.setState(({ errorMessage }) => ({
        [name]: value,
        errorMessage: {
          ...errorMessage,
          [name]: null,
        },
      }))
    }

    // Pass 'true' to inform parent that changes have been made
    onChange(true)
  }

  // REF METHODS
  // Used by parent and sends any existing error messages
  getErrorMessage = () => {
    const { errorMessage } = this.state

    const messages = Object.values(errorMessage).reduce(
      (accumulator, error) => {
        if (!accumulator && error) {
          return `${error}`
        }
        if (error) {
          return `${accumulator}; ${error}`
        }
        return accumulator
      },
      ''
    )

    return messages
  }

  validateSubmission = async () => {
    const {
      product,
      feature,
      name,
      summary,
      hypothesis,
      targetSampleSize,
      length,
      otherLength,
      type,
    } = this.state

    await this.setState({ errorMessage: initialErrors })

    let durationNumDays

    if (otherLength === '' && length === 'Other') {
      durationNumDays = null
    } else {
      durationNumDays =
        otherLength !== '' ? JSON.parse(otherLength) : JSON.parse(length)
    }

    const input = {
      name,
      summary,
      hypothesis,
      targetSampleSize:
        targetSampleSize !== null &&
        targetSampleSize !== undefined &&
        targetSampleSize !== ''
          ? Number(targetSampleSize)
          : null,
      durationNumDays,
      experimentTypeId: Number(type),
      productToFeature: {
        productId: product ? JSON.parse(product) : null,
        productFeatureId: feature ? JSON.parse(feature) : null,
      },
    }

    const { errors } = checkPlan(input)

    if (!isEmpty(errors)) {
      this.setState(({ errorMessage }) => ({
        errorMessage: {
          ...errorMessage,
          ...errors,
        },
      }))

      return false
    }

    return true
  }

  // API METHODS
  handleSubmit = async () => {
    const { id, experimentConfig } = this.props
    const {
      product,
      feature,
      summary,
      hypothesis,
      targetSampleSize,
      length,
      otherLength,
      type,
      errorMessage,
    } = this.state

    const durationNumDays =
      otherLength !== '' ? JSON.parse(otherLength) : JSON.parse(length)

    const input = {
      summary,
      hypothesis,
      targetSampleSize:
        targetSampleSize !== undefined &&
        targetSampleSize !== null &&
        targetSampleSize !== ''
          ? Number(targetSampleSize)
          : null,
      durationNumDays,
      experimentTypeId: Number(type),
      productToFeature: {
        productId: JSON.parse(product),
        productFeatureId: JSON.parse(feature),
      },
      config: experimentConfig,
    }

    // Reset any previous submission errors
    if (errorMessage.insertPlan || errorMessage.updatePlan) {
      this.setState({
        errorMessage: {
          ...errorMessage,
          insertPlan: null,
          updatePlan: null,
          duplicateName: null,
        },
      })
    }

    if (id) {
      return this.handleUpdatePlan(input)
    }

    return this.handleInsertPlan(input)
  }

  handleInsertPlan = async input => {
    const { name } = this.state
    const {
      onChange,
      formatBlankExperimentConfig,
      insertExperimentPlan: insertPlan,
    } = this.props

    const config = formatBlankExperimentConfig()

    try {
      const response = await insertPlan({
        variables: {
          input: {
            ...input,
            name: name.trim(),
            config,
            isCMSExperiment: false,
          },
        },
        update: (
          proxy,
          { data: { insertExperimentPlan: newExperimentData } }
        ) => {
          // Read data from our cache for insert query
          const data = proxy.readQuery({ query: GET_EXPERIMENTS })

          // Add new experiment from mutation to existing experiments cache
          data.experiments.push(newExperimentData)

          // Write data back to the cache
          proxy.writeQuery({ query: GET_EXPERIMENTS, data })
        },
      })

      const newExperiment = response.data.insertExperimentPlan
      // Return the new experiment to update the URL from parent component
      return newExperiment
    } catch (error) {
      const errorInput = formatLoggingError(error)

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input: errorInput,
        },
      })

      const isDuplicateName =
        error.message === 'GraphQL error: Duplicate Experiment Name'

      if (isDuplicateName) {
        onChange(false, true)
      }

      this.setState(({ errorMessage }) => ({
        errorMessage: {
          ...errorMessage,
          insertPlan: copyContent.plan.insertPlanError,
          duplicateName: isDuplicateName
            ? copyContent.plan.duplicateNameError
            : null,
        },
      }))

      // Informs parent that the insert failed
      return false
    }
  }

  handleUpdatePlan = async updateInput => {
    const { name, variantIds, type, product, feature } = this.state
    const {
      id,
      currentlyViewing,
      onNavigate,
      onChange,
      experimentPlanData: { experiment },
      experimentConfig,
      updateExperimentPlan: updatePlan,
      updateVariantJsonPayload: updateJsonPayload,
      handleUpdateOverrideBanner,
    } = this.props

    let input = updateInput

    const experimentToProductFeatureId = Number(
      experiment.experimentToProductFeatures[0].id
    )

    const productIsChanging =
      experiment.experimentToProductFeatures.length > 0 &&
      product !== experiment.experimentToProductFeatures[0].productId
    const featureIsChanging =
      experiment.experimentToProductFeatures.length > 0 &&
      feature !== experiment.experimentToProductFeatures[0].productFeatureId
    const parsedConfig = JSON.parse(experimentConfig)

    if (name !== experiment.name) {
      const trimmedName = name.trim()

      input = {
        ...input,
        name: trimmedName,
      }

      onNavigate({ ...currentlyViewing, title: trimmedName })
    }

    try {
      input = {
        ...input,
        experimentId: Number(id),
        productToFeature:
          productIsChanging || featureIsChanging
            ? {
                ...input.productToFeature,
                experimentToProductFeatureId,
              }
            : undefined,
        activationEventUuid:
          productIsChanging || featureIsChanging
            ? undefined
            : experiment.activationEventUuid,
      }

      await updatePlan({
        variables: {
          input,
        },
        update: async cache => {
          if (productIsChanging || featureIsChanging) {
            // Invalidate metrics and tech settings
            Object.keys(cache.data.data).forEach(
              key =>
                (key.match(/^Metric/) ||
                  key.match(/^TDCS/) ||
                  key.match(/^Tdcs/)) &&
                cache.data.delete(key)
            )

            const {
              setUpSteps: {
                metrics: { status: metricStatus },
                'tech settings': { status: techStatus },
                activation: { status: activationStatus },
              },
            } = parsedConfig

            parsedConfig.setUpSteps.metrics.status =
              metricStatus === 'initial' ? 'initial' : 'incomplete'
            parsedConfig.setUpSteps['tech settings'].status =
              techStatus === 'initial' ? 'initial' : 'incomplete'
            parsedConfig.setUpSteps.activation.status =
              activationStatus === 'initial' ? 'initial' : 'incomplete'

            // Mark metrics step as incomplete
            await this.handleUpdateExperimentConfig({
              experimentConfig: JSON.stringify(parsedConfig),
              step: 'metrics',
              status: metricStatus === 'initial' ? 'initial' : 'incomplete',
              areAllStepsComplete: false,
            })
            await this.handleUpdateExperimentConfig({
              experimentConfig: JSON.stringify(parsedConfig),
              step: 'tech settings',
              status: techStatus === 'initial' ? 'initial' : 'incomplete',
              areAllStepsComplete: false,
            })
            await this.handleUpdateExperimentConfig({
              experimentConfig: JSON.stringify(parsedConfig),
              step: 'activation',
              status: activationStatus === 'initial' ? 'initial' : 'incomplete',
              areAllStepsComplete: false,
            })
          }
        },
      })

      // DYNEXP-2366
      // Update the display banner for caution on overrides
      const isExperimentSetUpComplete =
        Object.keys(parsedConfig.setUpSteps).filter(
          key => parsedConfig.setUpSteps[key].status === 'initial'
        ).length === 0

      if (productIsChanging && isExperimentSetUpComplete) {
        await handleUpdateOverrideBanner(true)
      }

      // If the experiment type has changed
      if (Number(type) !== experiment.experimentTypeId) {
        // Update config if going to AB and variants is complete
        if (
          Number(type) !== experimentTypeEnum.AA &&
          parsedConfig.setUpSteps.variants.status === 'complete'
        ) {
          parsedConfig.setUpSteps.variants.status = 'incomplete'

          await this.handleUpdateExperimentConfig({
            experimentConfig: JSON.stringify(parsedConfig),
            step: 'variants',
            status: 'incomplete',
            areAllStepsComplete: false,
          })
        }

        // Remove jsonPayloads if going to AA and variants is complete
        if (variantIds.length > 0 && Number(type) === experimentTypeEnum.AA) {
          await updateJsonPayload({
            variables: {
              input: {
                experimentId: Number(id),
                variants: variantIds.map(variantId => ({
                  id: variantId,
                  jsonPayload: '',
                })),
              },
            },
          })

          if (parsedConfig.setUpSteps.variants.status === 'incomplete') {
            parsedConfig.setUpSteps.variants.status = 'complete'

            const areAllStepsComplete =
              Object.keys(parsedConfig.setUpSteps).filter(
                step =>
                  parsedConfig.setUpSteps[step].status === 'incomplete' ||
                  parsedConfig.setUpSteps[step].status === 'initial'
              ).length === 0

            await this.handleUpdateExperimentConfig({
              areAllStepsComplete,
              experimentConfig: JSON.stringify(parsedConfig),
              step: 'variants',
              status: 'complete',
            })
          }
        }
      }

      return true
    } catch (error) {
      const errorInput = formatLoggingError(error, { id })

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input: errorInput,
        },
      })

      const isDuplicateName =
        error.message === 'GraphQL error: Experiment name already exists'

      if (isDuplicateName) {
        onChange(false, true)
      }

      this.setState(({ errorMessage }) => ({
        errorMessage: {
          ...errorMessage,
          updatePlan: copyContent.plan.updatePlanError,
          duplicateName: isDuplicateName
            ? copyContent.plan.duplicateNameError
            : null,
        },
      }))

      // Informs parent that the update failed
      return false
    }
  }

  handleUpdateExperimentConfig = async progressConfig => {
    const { id, onUpdateProgress } = this.props
    const { experimentConfig: config } = progressConfig

    const configInput = {
      id: Number(id),
      config,
    }

    await client.mutate({
      mutation: UPDATE_EXPERIMENT_CONFIG,
      variables: {
        input: configInput,
      },
    })

    await onUpdateProgress(progressConfig)
  }

  handleGetProductFeatures = async id => {
    const { errorMessage } = this.state

    // Reset any error messages
    if (errorMessage.getProductFeatures) {
      this.setState({
        errorMessage: { ...errorMessage, getProductFeatures: null },
      })
    }

    try {
      const response = await client.query({
        query: getProductFeaturesById,
        variables: {
          id,
        },
      })

      const productFeatures = response.data.product.features

      this.setState({ productFeatures })
    } catch (error) {
      const input = formatLoggingError(error)

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input,
        },
      })
      this.setState({
        errorMessage: {
          ...errorMessage,
          getProductFeatures: copyContent.getFeaturesError,
        },
      })
    }
  }

  // RENDER METHODS
  renderProductOptions = () => {
    const {
      productsData: { productCategoryTypes },
    } = this.props

    return (
      <Fragment>
        <option value="" disabled>
          Select One
        </option>

        {productCategoryTypes
          .sort((aType, bType) => aType.name > bType.name)
          .map(category => (
            <optgroup key={category.id} label={category.name}>
              {category.products
                .filter(
                  ({ quantumApplicationType }) =>
                    quantumApplicationType !== 'CMS'
                )
                .sort((aProduct, bProduct) =>
                  aProduct.displayName.toLowerCase() >
                  bProduct.displayName.toLowerCase()
                    ? 1
                    : -1
                )
                .map(product => (
                  <option key={product.id} value={product.id}>
                    {product.displayName}
                  </option>
                ))}
            </optgroup>
          ))}
      </Fragment>
    )
  }

  renderFeatureOptions = () => {
    const { productFeatures } = this.state

    const featuresByType = productFeatures.reduce((accumulator, feature) => {
      if (!accumulator[feature.featureType.name]) {
        accumulator[feature.featureType.name] = []
      }

      accumulator[feature.featureType.name].push(feature)

      return accumulator
    }, {})

    return (
      <Fragment>
        <option value="" disabled>
          Select One
        </option>
        {Object.keys(featuresByType)
          .sort((aType, bType) => (aType > bType ? 1 : -1))
          .map(type => (
            <optgroup key={type} label={type}>
              {featuresByType[type]
                .sort((aFeature, bFeature) =>
                  aFeature.displayName > bFeature.displayName ? 1 : -1
                )
                .map(feature => (
                  <option key={feature.id} value={feature.id}>
                    {feature.displayName}
                  </option>
                ))}
            </optgroup>
          ))}
      </Fragment>
    )
  }

  render() {
    const {
      disabled,
      applicationPermissions,
      experimentPermissions,
      environmentSamplings,
      productsData: { loading: productsLoading },
      experimentPlanData,
    } = this.props

    const {
      uuid,
      product,
      productDisplayName,
      feature,
      name,
      hypothesis,
      targetSampleSize,
      summary,
      length,
      otherLength,
      type,
      productFeatures,
      hasMatchingProductPermissions,
      isLoading,
      errorMessage,
    } = this.state

    const planLoading = experimentPlanData && experimentPlanData.loading

    if (productsLoading || planLoading || isLoading) {
      return (
        <div className="app__loader">
          <KiteLoader size="7rem" />
        </div>
      )
    }

    const canUpdateRunning =
      experimentPermissions &&
      hasPermission({
        applicationPermissions,
        experimentPermissions,
        environmentSamplings,
        permissionId: EXPERIMENT_UPDATE_RUNNING,
      })

    return (
      <div className="experiment-plan">
        <h4>Make Your Plan</h4>
        {uuid && (
          <div className="experiment-plan__uuid-container">
            <KiteInput
              className="experiment-plan__uuid"
              label="UUID"
              value={uuid}
              disabled
            />

            <CopyButton textToCopy={uuid} />
          </div>
        )}
        {!hasMatchingProductPermissions ? (
          <KiteInput
            className="experiment-plan__uuid"
            label="Product"
            value={productDisplayName}
            disabled
          />
        ) : (
          <KiteSelect
            className="experiment-plan__product"
            name="product"
            label="Product"
            value={product}
            disabled={disabled}
            onChange={this.handleChange}
            errorMessage={
              errorMessage.product || errorMessage.getProductFeatures
                ? `${errorMessage.product || ''} ${
                    errorMessage.getProductFeatures || ''
                  }`
                : false
            }
          >
            {this.renderProductOptions()}
          </KiteSelect>
        )}

        <ExpansionPanel
          isExpanded={productFeatures && product !== ''}
          type="minimal"
        >
          <KiteSelect
            className="experiment-plan__feature"
            name="feature"
            label="Feature"
            value={feature}
            disabled={disabled}
            onChange={this.handleChange}
            errorMessage={errorMessage.feature}
          >
            {productFeatures && this.renderFeatureOptions()}
          </KiteSelect>
        </ExpansionPanel>

        <CounterInput
          className="experiment-plan__name"
          name="name"
          label="Descriptive Name"
          value={name}
          disabled={disabled}
          maxCharCount={35}
          onChange={this.handleChange}
          noSpecialCharacters
          errorMessage={
            errorMessage.duplicateName || errorMessage.name
              ? `${errorMessage.duplicateName || ''} ${errorMessage.name || ''}`
              : false
          }
        />

        <TextArea
          className="experiment-plan__description"
          name="summary"
          label="Problem Statement/Objective"
          value={summary}
          disabled={disabled && !canUpdateRunning}
          onChange={this.handleChange}
          placeholder={copyContent.plan.summary}
          tooltip={copyContent.plan.summary}
          height="8.125rem"
          width="50rem"
          maxLength="60000"
          errorMessage={errorMessage.summary}
        />

        <TextArea
          className="experiment-plan__hypothesis"
          name="hypothesis"
          label="Hypothesis"
          value={hypothesis}
          disabled={disabled}
          onChange={this.handleChange}
          placeholder={copyContent.plan.hypothesis}
          tooltip={copyContent.plan.hypothesis}
          height="8.125rem"
          width="50rem"
          maxLength="425"
          errorMessage={errorMessage.hypothesis}
        />

        <div className="experiment-plan__length-wrapper">
          <div className="experiment-plan__length-input-wrapper">
            <KiteSelect
              className="experiment-plan__length"
              name="length"
              label="Planned Experiment Length (days)"
              value={length}
              disabled={disabled}
              tooltip={
                <div>
                  The{' '}
                  <a href="mailto://DL-DPDATA-Experimentation@charter.com">
                    Experimentation Team
                  </a>{' '}
                  can calculate Target Sample Sizes and Experiment durations for
                  platforms and metrics not yet available in the Distillery
                  Suggested Sample Size Calculator.
                </div>
              }
              placeholder="Select One"
              onChange={this.handleChange}
              errorMessage={errorMessage.durationNumDays}
            >
              {experimentLengthOptions.map(experimentLength => (
                <option value={experimentLength} key={experimentLength}>
                  {experimentLength}
                </option>
              ))}
            </KiteSelect>

            {length === 'Other' && (
              <KiteInput
                className="experiment-plan__other-length"
                name="otherLength"
                value={otherLength}
                disabled={disabled}
                onChange={this.handleChange}
              />
            )}
          </div>
        </div>

        <KiteInput
          label="Target Sample Size"
          name="targetSampleSize"
          value={targetSampleSize === null ? '' : targetSampleSize}
          onChange={this.handleChange}
          disabled={disabled}
          placeholder="Optional from calculator"
          errorMessage={errorMessage.targetSampleSize}
          tooltip={
            <div>
              The{' '}
              <a href="mailto://DL-DPDATA-Experimentation@charter.com">
                Experimentation Team
              </a>{' '}
              can calculate Target Sample Sizes and Experiment durations for
              platforms and metrics not yet available in the Distillery
              Suggested Sample Size Calculator.
            </div>
          }
        />

        <KiteRadio
          className="experiment-plan__type"
          name="type"
          groupLabel="Experiment Type"
          disabled={disabled}
          tooltip={copyContent.plan.type}
          onChange={this.handleChange}
          radioButtons={[
            {
              id: 'AB',
              label: 'A/B',
              value: 2,
              checked: type && JSON.parse(type) === 2,
            },
            {
              id: 'AA',
              label: 'A/A',
              value: 1,
              checked: type && JSON.parse(type) === 1,
            },
            {
              label: 'Canary',
              value: 3,
              checked: type && JSON.parse(type) === 3,
            },
          ]}
        />
      </div>
    )
  }
}

export default flowRight(
  getExperimentPlan,
  getProducts,
  insertExperimentPlan,
  updateExperimentPlan,
  updateVariantJsonPayload
)(Plan)
