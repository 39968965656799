import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

/*
* Sample Input
* {
    input: [
      {
        environmentId: 1,
        productId: 1,
        notificationDefinitionId: 1,
        userId: 11,
        emailOn: true,
        inAppOn: true
      },
      {
        environmentId: 1,
        productId: 1,
        notificationDefinitionId: 2,
        userId: 11,
        inAppOn: false
      },
    ]
  }
*/

const INSERT_NOTIFICATION_PREFERENCES = gql`
  mutation insertNotificationPreferences(
    $input: [InsertNotificationPreferences!]
  ) {
    insertNotificationPreferences(input: $input) {
      id
      environmentId
      productId
      userId
      emailOn
      inAppOn
      onlyMine
      notificationDefinition {
        id
      }
    }
  }
`

const insertNotificationPreferences = graphql(INSERT_NOTIFICATION_PREFERENCES, {
  name: 'insertNotificationPreferences',
})

export default insertNotificationPreferences
