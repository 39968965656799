const getFeatures = {
  query: `
    featureTypes {
      id
      name
      features {
        id
        displayName
      }
    }`,
  variables: {},
};

export default getFeatures;
