import { gql } from '@apollo/client'

const INSERT_AUDIENCE = gql`
  mutation insertAudience($input: AudienceInsertInput!) {
    insertAudience(input: $input) {
      id
      name
      description
      rules
    }
  }
`

export default INSERT_AUDIENCE
