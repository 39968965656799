import React, { Component } from 'react'
import { object, bool, func, array, number } from 'prop-types'
import flowRight from 'lodash/flowRight'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import { KiteLoader } from '@kite/react-kite'
import { AutoSuggest, BasicPills } from '@kite/react-kite-plus'
import {
  checkRoles,
  hasPermission,
  permissionEnum,
  roleEnum,
} from '@charter/distillery-rules'

import { client } from '../../../../configuration/configApiClient'
import {
  LOG_ERROR,
  insertExperimentToUsers,
  deleteExperimentToUser,
} from '../../../../shared/mutations'
import { formatLoggingError } from '../../../../shared/utilities'
import { getUsers } from '../../../../shared/queries'
import { GET_EXPERIMENT_ROLES, getExperimentRoles } from '../../queries'
import copyContent from '../../data/copyContent'
import { KNOWN_ROLES } from '../../data'

import './Roles.scss'

const { EXPERIMENT_UPDATE_RUNNING } = permissionEnum
const { EXPERIMENTER_ADMIN } = roleEnum

export class Roles extends Component {
  static propTypes = {
    id: number,
    applicationPermissions: array.isRequired,
    experimentPermissions: array.isRequired,
    environmentSamplings: array.isRequired,
    experimentRolesData: object,
    insertExperimentToUsers: func,
    deleteExperimentToUser: func,
    usersData: object,
    disabled: bool,
    onChange: func,
  }

  state = {
    errorMessage: {},
    rolesById: {},
    inputValuesById: {},
    originalRolesById: {},
  }

  static defaultProps = {
    id: null,
    disabled: false,
    onChange: null,
    insertExperimentToUsers: null,
    deleteExperimentToUser: null,
    usersData: null,
    experimentRolesData: null,
  }

  componentDidMount() {
    const { experimentRolesData } = this.props

    if (experimentRolesData && experimentRolesData.experiment) {
      this.handleSetExperiment()
    }
  }

  // LIFECYCLE METHODS
  componentDidUpdate(prevProps) {
    const { experimentRolesData } = this.props
    const { experimentRolesData: prevRoleData } = prevProps

    if (
      prevRoleData &&
      experimentRolesData &&
      !isEqual(prevRoleData.experiment, experimentRolesData.experiment)
    ) {
      this.handleSetExperiment()
    }
  }

  // LOCAL STATE CHANGES/TOGGLES
  handleSetExperiment = async () => {
    const {
      onChange,
      experimentRolesData: { experiment },
    } = this.props

    const { experimentToUsers = [] } = experiment

    const rolesById = {}
    const inputValuesById = {}

    Object.values(KNOWN_ROLES).forEach(knownRole => {
      const usersById = {}
      rolesById[knownRole.id] = { ...knownRole, usersById }
      experimentToUsers.forEach(({ role, user, id: experimentToUserId }) => {
        if (Number(role.id) === knownRole.id) {
          const theUser = { ...user, ...{ experimentToUserId } }
          rolesById[knownRole.id].usersById[user.id] = theUser
          inputValuesById[knownRole.id] = theUser.displayName
        }
      })
      if (
        knownRole.allowMultipleUsers ||
        Object.values(usersById).length === 0
      ) {
        inputValuesById[knownRole.id] = ''
      }
    })

    await this.setState({
      rolesById,
      originalRolesById: cloneDeep(rolesById),
      inputValuesById,
    })

    // Pass 'false' to inform parent that the form does not have changes
    // and is only loading
    onChange(false)
  }

  handleChange = async (roleId, value) => {
    const { onChange } = this.props
    let { inputValuesById } = this.state
    inputValuesById = { ...inputValuesById }
    inputValuesById[roleId] = value

    // Also clear the role for this user in local state
    // to force the user to make a selection
    const rolesById = this.assignUserToRole(roleId, null)
    await this.setState(({ errorMessage }) => ({
      inputValuesById,
      rolesById,
      errorMessage: { ...errorMessage, [`role${roleId}Required`]: null },
    }))

    onChange(true)
  }

  handlePick = (roleId, user) => {
    const { onChange } = this.props

    this.setState({ rolesById: this.assignUserToRole(roleId, user) })
    const role = KNOWN_ROLES[roleId]
    if (role.allowMultipleUsers) {
      this.handleChange(roleId, '')
    }
    onChange(true)
  }

  handleRemovePick = (userToRemove, roleId) => {
    const { onChange } = this.props
    const { rolesById } = this.state

    const newRolesById = cloneDeep(rolesById)
    const role = newRolesById[roleId]
    if (role.usersById && role.usersById[userToRemove.id]) {
      delete role.usersById[userToRemove.id]
    }
    this.setState({ rolesById: newRolesById })
    onChange(true)
  }

  assignUserToRole = (roleId, user) => {
    const rolesById = { ...this.state.rolesById }
    const role = rolesById[roleId]
    if (!user) {
      if (!role.allowMultipleUsers) role.usersById = {}
    } else if (role.allowMultipleUsers) {
      role.usersById[user.id] = user
    } else role.usersById = { [user.id]: user }
    return rolesById
  }

  // REF METHODS
  getErrorMessage = () => {
    const { errorMessage } = this.state

    const messages = Object.values(errorMessage).reduce(
      (accumulator, error) => {
        if (!accumulator && error) {
          return `${error}`
        }
        if (error) {
          return `${accumulator}; ${error}`
        }
        return accumulator
      },
      ''
    )

    return messages
  }

  validateSubmission = async () => {
    const { rolesById } = this.state

    await this.setState({ errorMessage: {} })

    const experimentToUsers = Object.keys(rolesById).reduce(
      (accumulator, id) => {
        if (!isEmpty(rolesById[id].usersById)) {
          return [...accumulator, { role: { id: Number(id) } }]
        }

        return accumulator
      },
      []
    )

    const { errors } = checkRoles({ experimentToUsers })

    if (!isEmpty(errors)) {
      this.setState(({ errorMessage }) => ({
        errorMessage: {
          ...errorMessage,
          ...errors,
        },
      }))

      return false
    }

    return true
  }

  handleSubmit = async () => {
    const {
      id,
      insertExperimentToUsers: insertUsers,
      deleteExperimentToUser: deleteUser,
    } = this.props
    const { rolesById, originalRolesById, errorMessage } = this.state

    // Reset any previous submission errors
    if (errorMessage.insertRoles) {
      this.setState({
        errorMessage: { ...errorMessage, insertRoles: null },
      })
    }

    const input = { experimentID: Number(id), rolesAndUsers: [] }
    const deleteInput = { ids: [] }

    Object.values(rolesById).forEach(role => {
      Object.values(role.usersById).forEach(user => {
        input.rolesAndUsers.push({
          roleID: Number(role.id),
          userID: Number(user.id),
        })
      })

      if (role.id === 3) {
        // Take the original admin roles, filter to users who are not found in the new
        // admin roles, to build a delete list
        const usersToDelete = Object.values(
          originalRolesById[3].usersById
        ).filter(
          user =>
            Object.values(role.usersById).find(
              otherUser => otherUser.id === user.id
            ) === undefined
        )
        usersToDelete.forEach(userToDelete =>
          deleteInput.ids.push(userToDelete.experimentToUserId)
        )
      }
    })

    try {
      await insertUsers({
        variables: {
          input,
        },
        refetchQueries: [{ query: GET_EXPERIMENT_ROLES, variables: { id } }],
      })

      if (deleteInput.ids.length) {
        await deleteUser({ variables: { input: deleteInput } })
      }

      return true
    } catch (error) {
      const errorInput = formatLoggingError(error, { id })

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input: errorInput,
        },
      })

      this.setState({
        errorMessage: {
          ...errorMessage,
          insertRoles: copyContent.roles.insertRolesError,
        },
      })

      return false
    }
  }

  render() {
    const {
      usersData: { loading: usersLoading, users },
      experimentRolesData: { loading: rolesLoading },
      disabled,
      applicationPermissions,
      experimentPermissions,
      environmentSamplings,
    } = this.props
    const { rolesById, inputValuesById, errorMessage } = this.state

    if (usersLoading || rolesLoading) {
      return (
        <div className="app__loader">
          <KiteLoader size="7rem" />
        </div>
      )
    }

    const canUpdateRunning =
      experimentPermissions &&
      hasPermission({
        applicationPermissions,
        experimentPermissions,
        environmentSamplings,
        permissionId: EXPERIMENT_UPDATE_RUNNING,
      })

    const roleInputs = []

    const sortedRoles = Object.values(rolesById).sort(
      (firstRole, secondRole) => firstRole.order - secondRole.order
    )

    sortedRoles.forEach(role => {
      const filteredUsers = users.filter(
        user =>
          Object.values(role.usersById).find(
            otherUser => otherUser.id === user.id
          ) === undefined
      )

      const placeholder = role.allowMultipleUsers
        ? `Names of the ${role.displayName}.`
        : `Name of the ${role.displayName}.`

      const enableField = role.id === EXPERIMENTER_ADMIN && canUpdateRunning

      const autoSuggest = (
        <AutoSuggest
          key={`role-${role.id}`}
          label={role.displayName}
          value={inputValuesById[role.id]}
          name={role.id}
          disabled={disabled && !enableField}
          initialData={filteredUsers}
          suggestionKey="displayName"
          minCharacters={3}
          showIcon={false}
          maxWidth="288px"
          tooltip={copyContent.roles[`role${role.id}Tooltip`]}
          errorMessage={errorMessage[`role${role.id}Required`] || false}
          onChange={event => this.handleChange(role.id, event.target.value)}
          onSuggestionSelected={(event, data) => {
            event.preventDefault()
            this.handlePick(role.id, data.suggestion)
          }}
          placeholder={placeholder}
        />
      )

      if (!role.allowMultipleUsers) {
        roleInputs.push(autoSuggest)
      } else {
        // A little needed formatting for pills.  Per input, so can't hoist.
        const usersByName = {}
        Object.values(role.usersById).forEach(user => {
          usersByName[user.displayName] = user
        })

        roleInputs.push(
          <div className="role-multiple" key={`role-${role.id}`}>
            {autoSuggest}
            <div className="role-multiple__pills">
              <BasicPills
                values={usersByName}
                onRemovePill={
                  disabled
                    ? null
                    : userToRemove =>
                        this.handleRemovePick(userToRemove, role.id)
                }
              />
            </div>
          </div>
        )
      }
    })

    return (
      <div className="experiment-roles">
        <h4 className="experiment-roles__title">Assign Roles</h4>
        {roleInputs}
      </div>
    )
  }
}

export default flowRight(
  getExperimentRoles,
  getUsers,
  insertExperimentToUsers,
  deleteExperimentToUser
)(Roles)
