import React, { Component, Fragment } from 'react'
import Chart from 'react-apexcharts'
import { object, func, number, array } from 'prop-types'
import flowRight from 'lodash/flowRight'
import isEqual from 'lodash/isEqual'
import { range } from 'lodash'
import dayjs from 'dayjs'
import { gql } from '@apollo/client'

import {
  queryQuantumMetrics,
  experimentEnvironmentEnum,
  experimentTypeEnum,
  experimentStatusEnum,
  experimentStatusEnumReverse,
  featureFlagEnum,
  isFeatureFlagActive,
  willTerminateCancelExperiment,
} from '@charter/distillery-rules'
import { DatePicker, SortableTable } from '@kite/react-kite-plus'

import {
  KiteSelect,
  KiteLoader,
  KiteIcon,
  KiteAlert,
  KiteTabs,
} from '@kite/react-kite'

import { client } from '../../configuration/configApiClient'
import { LOG_ERROR } from '../../shared/mutations'
import {
  formatLoggingError,
  GqlBuilder,
  capitalizeString,
} from '../../shared/utilities'
import { GET_EXPERIMENT_METRICS_GQLBUILDER } from '../../shared/queries'
import GET_EXPERIMENTS_VARIANTS_GQLBUILDER from './queries/getExperimentVariants'
import { ExpansionPanel } from '../../componentLibrary'
import FinalOutcomePDF from './FinalOutcomePDF'
import { copyContent } from './data'
import './ExperimentResults.scss'
import { MetricDefModal } from '../../components'
import graphSelectedIcon from './CI_graph_icon_color.png'
import graphIcon from './CI_graph_icon.png'

const { PRODUCTION } = experimentEnvironmentEnum

const DATE_FORMAT = 'YYYY-MM-DD'
const DATE_FORMAT_2 = 'MMM D, YYYY'
const MAX_RUN_TIME = 62
const adHocUuid = [
  '5f079353c3b95d39978bebdf',
  '5f079353c3b95d39978bebde',
  '5f079353c3b95d39978bebe0',
  '5eb33636e99cef74db16c441',
  '5f723a4693c6582ef0cbb355',
]

const { CI_IN_RESULTS } = featureFlagEnum

const gqlHOCQuery = new GqlBuilder().compileHOCQuery([
  GET_EXPERIMENTS_VARIANTS_GQLBUILDER,
  GET_EXPERIMENT_METRICS_GQLBUILDER,
])

const endsInPercent = theValue =>
  typeof theValue === 'string' && theValue.indexOf('%') === theValue.length - 1

const convertToNum = theValue =>
  endsInPercent(theValue) ? Number(theValue.slice(0, -1)) : Number(theValue)

export class ExperimentResults extends Component {
  static propTypes = {
    id: number.isRequired,
    history: object.isRequired,
    onNavigate: func.isRequired,
    queryData: object.isRequired,
    queryQuantum: func,
    applicationPermissions: array.isRequired,
  }

  static defaultProps = {
    queryQuantum: queryQuantumMetrics,
  }

  state = {
    selectedControl: null,
    selectedVariant1: null,
    quantumMetrics: [],
    startDate: null,
    stopDate: dayjs().format(DATE_FORMAT),
    maxDate: dayjs().format(DATE_FORMAT),
    currentTab: null,
    selectedMetric: null,
    isQueryingQuantum: false,
    stopDateUpdated: false,
    shouldShowMeanDifferenceGraph: true,
    shouldShowMeanEffectGraph: true,
    errorMessage: {
      quantumQueryError: null,
      variantsSame: null,
    },
  }

  // LIFECYCLE METHODS
  componentDidMount() {
    const {
      queryData: { experiment },
    } = this.props

    if (experiment) {
      this.handleSetExperiment()
    }
  }

  componentDidUpdate(prevProps) {
    const {
      queryData,
      queryData: { experiment },
    } = this.props
    const { queryData: prevData } = prevProps

    if (experiment && !isEqual(queryData, prevData)) {
      this.handleSetExperiment()
    }
  }

  // LOCAL STATE CHANGES/TOGGLES
  handleSetExperiment = async () => {
    const {
      queryData: {
        experiment: { startTime, stopTime, variants, experimentMetrics },
        retrieveQuantumToken: quantumToken,
        metricsForExperiment,
      },
    } = this.props

    await this.handleSetCurrentlyViewing()

    if (startTime) {
      let stopDate = dayjs().subtract(1, 'day').format(DATE_FORMAT)
      const startDate = dayjs(startTime).format(DATE_FORMAT)
      const startDateObj = dayjs(startDate, DATE_FORMAT)

      // If the experiment has stopped, update end date of date picker
      if (dayjs(stopTime).isValid()) {
        stopDate = dayjs(stopTime).format(DATE_FORMAT)
      }

      const stopDateObj = dayjs(stopDate, DATE_FORMAT)
      const numDays = stopDateObj.diff(startDateObj, 'day')

      if (numDays > MAX_RUN_TIME) {
        stopDate = startDateObj.add(MAX_RUN_TIME, 'day').format(DATE_FORMAT)
      }

      // Verify the follow on period for experiment metrics
      if (dayjs(stopTime).isValid() && experimentMetrics.length && dayjs(stopTime).isBefore(dayjs())) {
        const maxExperimentObservDay = this.handleCheckObservationalDays(experimentMetrics);
        if (maxExperimentObservDay > 0) {
          const observationObj = stopDateObj.add(maxExperimentObservDay, 'day')
          if (observationObj.isBefore(dayjs())) {
            stopDate = stopDateObj.add(maxExperimentObservDay, 'day').format(DATE_FORMAT)
          } else {
            stopDate = dayjs().subtract(1, 'day').format(DATE_FORMAT)
          }
        }
      }

      await this.setState({
        startDate,
        stopDate,
        maxDate: stopDate,
      })
    }

    if (
      startTime &&
      metricsForExperiment &&
      quantumToken &&
      variants.length > 1
    ) {
      await this.setState({
        selectedControl: variants[0],
        selectedVariant1: variants[1],
      })
      await this.queryQuantum()
    }
  }

  handleSetCurrentlyViewing = () => {
    const {
      onNavigate,
      queryData: { experiment },
      history,
    } = this.props

    const { id, name, experimentToProductFeatures, environmentSamplings } =
      experiment
    const { product, productFeature } = experimentToProductFeatures[0]

    const status = environmentSamplings
      .find(sampling => sampling.environment.id === PRODUCTION)
      .experimentStatus.name.toLowerCase()

    const basePath = `/experiments/${id}`
    const path = `${basePath}/results`

    let currentlyViewing = {
      path,
      title: name,
      subTitle: `${product.displayName} - ${productFeature.displayName}`,
      backPath: `/experiments/${status}`,
      backTitle: `${status} Experiments`,
      tabs: {
        currentTab: 'results',
        tabs: [
          { label: 'set up', path: `${basePath}/set-up/plan` },
          {
            label: 'rollout',
            path: `${basePath}/rollout/overrides`,
          },
          {
            label: 'results',
            path: `${basePath}/results`,
          },
        ],
      },
    }

    if (status === 'deleted') {
      currentlyViewing = {
        path: '/experiments/all',
        backPath: path,
        backTitle: name,
      }

      history.push('/experiments/all')
    }

    onNavigate(currentlyViewing)
  }

  handleEndDateChange = async (dateString, date) => {
    await this.setState({
      stopDate: dayjs(date).format(DATE_FORMAT),
    })
    await this.queryQuantum()
  }

  handleCheckObservationalDays = (experimentMetrics) => {
    return Math.max.apply(Math, experimentMetrics.map((metric) => metric.observationalDays ))
  }

  handleControlSelection = async ({ target: { value: selectedUuid } }) => {
    const {
      queryData: {
        experiment: { variants },
      },
    } = this.props

    const selectedVariant = variants.find(
      ({ id }) => Number(id) === Number(selectedUuid)
    )

    await this.setState({
      selectedControl: selectedVariant,
    })

    if (await this.validateSelections()) {
      await this.queryQuantum()
    }
  }

  handleVariant1Selection = async ({ target: { value: selectedUuid } }) => {
    const {
      queryData: {
        experiment: { variants },
      },
    } = this.props

    const selectedVariant = variants.find(
      ({ id }) => Number(id) === Number(selectedUuid)
    )

    await this.setState({
      selectedVariant1: selectedVariant,
    })

    if (await this.validateSelections()) {
      await this.queryQuantum()
    }
  }

  handleSelectTab = currentTab => {
    const { selectedMetric } = this.state

    if (selectedMetric && this.table) {
      this.handleClearSelectedMetric()
    }

    this.setState({ currentTab })
  }

  handleClearSelectedMetric = () => {
    const { selectedMetric } = this.state
    const { activeRowIndex } = this.table.state

    this.table.handleRowClick(selectedMetric, activeRowIndex)
    this.setState({ selectedMetric: null })
  }

  handleSelectMetric = selectedMetric => {
    this.setState(prevState => ({
      selectedMetric: isEqual(prevState.selectedMetric, selectedMetric)
        ? null
        : selectedMetric,
    }))
  }

  validateSelections = async () => {
    const { errorMessage, selectedControl, selectedVariant1 } = this.state
    const { errorVariantsSame } = copyContent

    if (errorMessage.variantsSame) {
      await this.setState(prevState => ({
        errorMessage: {
          ...prevState.errorMessage,
          variantsSame: null,
        },
      }))
    }

    if (selectedControl.id === selectedVariant1.id) {
      await this.setState(prevState => ({
        errorMessage: {
          ...prevState.errorMessage,
          variantsSame: errorVariantsSame,
        },
      }))
      return false
    }

    return true
  }

  // FILTER METHODS

  shouldShowSig = () => {
    let res = true
    const {
      queryData: {
        experiment: {
          environmentSamplings,
          startTime,
          durationNumDays,
          experimentTypeId,
          experimentToProductFeatures: [
            {
              product: { isSspp },
            },
          ],
        },
      },
    } = this.props

    if (isSspp === true) {
      const experimentStatus = environmentSamplings.find(
        sampling => sampling.environment.id === PRODUCTION
      ).experimentStatus.id

      res =
        experimentStatus === experimentStatusEnum.COMPLETED ||
        experimentStatus === experimentStatusEnum.CANCELLED ||
        !willTerminateCancelExperiment({ durationNumDays, startTime }) ||
        experimentTypeId === experimentTypeEnum.CANARY
    }

    return res
  }

  sortedMetricDefinitionsWithType = typeId => {
    const {
      queryData: { metricsForExperiment },
    } = this.props
    return metricsForExperiment
      ? metricsForExperiment
          .filter(
            ({ metricType, isEnabled }) => isEnabled && metricType.id === typeId
          )
          .sort((metricDefA, metricDefB) => {
            if (metricDefA.name < metricDefB.name) {
              return -1
            }
            if (metricDefA.name > metricDefB.name) {
              return 1
            }
            return 0
          })
      : []
  }

  metricsForDefinitions = metricDefinitions => {
    const { quantumMetrics } = this.state
    return metricDefinitions.map(({ uuid }) =>
      quantumMetrics.find(({ uuid: toCompare }) => toCompare === uuid)
    )
  }

  metricCategoryNameFromDefinitions = (metricDefinitions, defaultName) =>
    metricDefinitions && metricDefinitions.length
      ? metricDefinitions[0].metricType.name
      : defaultName

  // API METHODS
  queryQuantum = async () => {
    const {
      errorMessage,
      stopDate,
      startDate,
      selectedControl,
      selectedVariant1,
      stopDateUpdated,
    } = this.state

    const {
      queryData: {
        experiment,
        experiment: {
          uuid: experimentId,
          experimentStatusId,
          stopTime,
          experimentMetrics,
          environmentSamplings,
          experimentToProductFeatures: [
            {
              product: {
                uuid: applicationId,
                quantumApplicationName,
                quantumApplicationType,
              },
            },
          ],
          variants,
        },
        retrieveQuantumToken: quantumToken,
        metricsForExperiment: metrics,
      },
      queryQuantum,
    } = this.props

    const { errorQuantumNoResultsYet, errorQuantumQuery } = copyContent

    // Verify if we need to add an extra day to check for data
    const status = environmentSamplings.find(
      sampling => sampling.environment.id === PRODUCTION
    ).experimentStatus.id

    const completedOrCanceledStatus =
      status === experimentStatusEnum.COMPLETED ||
      status === experimentStatusEnum.CANCELLED

    if (errorMessage.quantumQueryError) {
      await this.setState(prevState => ({
        errorMessage: {
          ...prevState.errorMessage,
          quantumQueryError: null,
        },
      }))
    }
    // Set saving state for uploads
    await this.setState({
      isQueryingQuantum: true,
      quantumMetrics: [],
    })

    try {
      let updatedDate = stopDate
      if (stopDateUpdated) {
        updatedDate = dayjs(stopDate, DATE_FORMAT)
          .add(2, 'day')
          .format(DATE_FORMAT)
      }

      // Add a hook to load mockData from a url by specifying a `mockQuantum=<quantum_url>` parsing ability
      // This is to aid in automation testing scenarios
      let mockData = null
      try {
        const mockUrl = new URL(document.location).searchParams.get(
          'mockQuantum'
        )
        if (mockUrl) {
          const mockRes = await fetch(mockUrl, { mode: 'cors' })
          mockData = await mockRes.json()
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error downloading mock')
        // eslint-disable-next-line no-console
        console.error(error)
      }

      const res = await queryQuantum({
        experimentId,
        quantumToken,
        quantumApplicationName,
        quantumApplicationType,
        metricDefinitions: metrics,
        experimentMetrics,
        asOfDenverDate: updatedDate,
        observationStartDate: Number(experimentStatusId) === experimentStatusEnum.OBSERVATIONAL ? stopTime : '',
        applicationId,
        numOfVariants: variants ? variants.length : 2,
        variantAUuid: selectedControl.uuid,
        variantBUuid: selectedVariant1.uuid,
        mockData,
        numDaysBackToSearch: completedOrCanceledStatus ? 3 : 2,
      })

      const { date, metrics: quantumMetrics } = res

      await this.setState({
        quantumMetrics,
        stopDate: date,
        stopDateUpdated: false,
        isQueryingQuantum: false,
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)

      let errorToDisplay = errorQuantumQuery

      const startDateObj = dayjs(startDate, DATE_FORMAT)
      const stopDateObj = dayjs(stopDate, DATE_FORMAT)
      const numberOfDays = stopDateObj.diff(startDateObj, 'day')

      if (error.message && error.message.indexOf('results found') !== -1) {
        errorToDisplay = errorQuantumNoResultsYet
      } else if (numberOfDays > 0 && !stopDateUpdated) {
        const updateDate = dayjs(stopDate, DATE_FORMAT)
          .subtract(2, 'day')
          .format(DATE_FORMAT)

        await this.setState({
          stopDate: updateDate,
          stopDateUpdated: true,
        })
      }

      const input = formatLoggingError(error, experiment)
      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input,
        },
      })

      await this.setState(prevState => ({
        isQueryingQuantum: false,
        errorMessage: {
          ...prevState.errorMessage,
          quantumQueryError: errorToDisplay,
        },
      }))
    }
  }

  // FORMATTING METHODS
  formatMetricTabs = () => {
    const { currentTab, quantumMetrics } = this.state

    const displayedTabs = quantumMetrics
      .reduce((accumulator, { metricTypeName }) => {
        if (!accumulator.includes(metricTypeName)) {
          return [...accumulator, metricTypeName]
        }

        return accumulator
      }, [])
      .sort((typeName1, typeName2) => {
        if (typeName2 === 'Success') {
          return 1
        }
        return -1
      })

    if (!currentTab) {
      this.handleSelectTab(displayedTabs[0])
    }

    return displayedTabs
  }

  formatVariantName = ({ name, displayOrder }) =>
    displayOrder === 0 ? `${name} (C)` : `${name} (V${displayOrder})`

  formatTableColumns = () => {
    const {
      queryData: {
        experiment: {
          experimentTypeId,
          experimentStatusId,
          stopTime,
        },
      },
      applicationPermissions,
    } = this.props

    const {
      selectedControl,
      selectedVariant1,
      shouldShowMeanDifferenceGraph,
      shouldShowMeanEffectGraph,
    } = this.state
    let stopDateObj = dayjs(stopTime, DATE_FORMAT)
    const { featureFlags } = client.readQuery({
      query: gql`
        query getCurrentUser {
          featureFlags {
            id
            name
            active
          }
        }
      `,
    })

    const isCiInResultsActive = isFeatureFlagActive({
      featureFlags,
      featureFlagId: CI_IN_RESULTS,
      applicationPermissions,
    })

    const isNotCanary = experimentTypeId !== experimentTypeEnum.CANARY

    let columns = [
      {
        sortKey: 'name',
        label: 'Metric',
        sortEnabled: false,
        size: 1.75,
        render: metric => {
          const { name } = metric
          return (
            <div style={{ display: 'flex' }}>
              <div style={{ marginRight: 10, width: '80%' }}>{name}</div>
              <MetricDefModal metricDef={metric} />
            </div>
          )
        },
      },
      {
        sortKey: 'variantAMean',
        label: this.formatVariantName(selectedControl),
        sortEnabled: false,
      },
      {
        sortKey: 'variantBMean',
        label: this.formatVariantName(selectedVariant1),
        sortEnabled: false,
      },
    ]

    if (isNotCanary) {
      columns = [
        ...columns,
        {
          sortKey: 'differenceInMeans',
          label: !isCiInResultsActive ? (
            'Mean Difference'
          ) : (
            <div style={{ display: 'flex' }}>
              <div style={{ marginRight: 8 }}>Mean Difference</div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div
                  role="button"
                  tabIndex="0"
                  onKeyDown={() =>
                    this.setState({
                      shouldShowMeanDifferenceGraph: false,
                    })
                  }
                  onClick={() =>
                    this.setState({
                      shouldShowMeanDifferenceGraph: false,
                    })
                  }
                  style={{
                    marginRight: 5,
                    color: !shouldShowMeanDifferenceGraph ? '#000' : '#0073d1',
                  }}
                >
                  #
                </div>
                <div
                  role="button"
                  tabIndex="0"
                  className="no-focus"
                  onKeyDown={() =>
                    this.setState({
                      shouldShowMeanDifferenceGraph: true,
                    })
                  }
                  onClick={() =>
                    this.setState({
                      shouldShowMeanDifferenceGraph: true,
                    })
                  }
                >
                  <img
                    width={32}
                    height={7}
                    alt="View graph"
                    src={
                      shouldShowMeanDifferenceGraph
                        ? graphIcon
                        : graphSelectedIcon
                    }
                  />
                </div>
              </div>
            </div>
          ),
          sortEnabled: false,
          size: 1.8,
          render: (item) => {
            if ((experimentStatusId === experimentStatusEnum.OBSERVATIONAL) && (item.observationalDays > 0) && stopDateObj.add(item.observationalDays, 'day').isAfter(dayjs())) {
              return ('Under calculation')
            } else if (
              item.meanDifferenceConfidenceInterval &&
              isCiInResultsActive &&
              shouldShowMeanDifferenceGraph
            ) {
              return this.renderConfidenceInterval({
                ...item,
                value: item.differenceInMeans,
                lowerConfidenceInterval:
                  item.meanDifferenceConfidenceInterval.lower,
                upperConfidenceInterval:
                  item.meanDifferenceConfidenceInterval.upper,
              })
            } else {
              return this.renderMeanValue(item, 'difference')
            }
          },
        },
        {
          sortKey: 'meanEffect',
          size: 2.2,
          label: !isCiInResultsActive ? (
            'Mean Effect'
          ) : (
            <div style={{ display: 'flex' }}>
              <div style={{ marginRight: 8 }}>Mean Effect</div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div
                  role="button"
                  tabIndex="0"
                  style={{
                    marginRight: 5,
                    color: !shouldShowMeanEffectGraph ? '#000' : '#0073d1',
                  }}
                  onKeyDown={() =>
                    this.setState({
                      shouldShowMeanEffectGraph: false,
                    })
                  }
                  onClick={() =>
                    this.setState({
                      shouldShowMeanEffectGraph: false,
                    })
                  }
                >
                  #
                </div>
                <div
                  role="button"
                  tabIndex="0"
                  className="no-focus"
                  onKeyDown={() =>
                    this.setState({
                      shouldShowMeanEffectGraph: true,
                    })
                  }
                  onClick={() =>
                    this.setState({
                      shouldShowMeanEffectGraph: true,
                    })
                  }
                >
                  <img
                    alt="View as number"
                    width={32}
                    height={7}
                    src={
                      shouldShowMeanEffectGraph ? graphIcon : graphSelectedIcon
                    }
                  />
                </div>
              </div>
            </div>
          ),
          sortEnabled: false,
          render: item => {
            if ((experimentStatusId === experimentStatusEnum.OBSERVATIONAL) && (item.observationalDays > 0) && stopDateObj.add(item.observationalDays, 'day').isAfter(dayjs())) {
              return ('Under calculation')
            } else if (item.meanEffectConfidenceInterval &&
            isCiInResultsActive &&
            shouldShowMeanEffectGraph) {
              return this.renderConfidenceInterval({
                  ...item,
                  type: 'effect',
                  value: item.meanEffect,
                  lowerConfidenceInterval:
                    item.meanEffectConfidenceInterval.lower,
                  upperConfidenceInterval:
                    item.meanEffectConfidenceInterval.upper,
                })
            } else {
              return this.renderMeanValue(item)
            }
          }
        },
        {
          sortKey: 'pValue',
          label: (
            <div
              className={`${
                !this.shouldShowSig() ? 'experiment-results__dim-header' : ''
              }`}
            >
              p-Value
            </div>
          ),
          sortEnabled: false,
          size: 0.85,
          render: item => {
            if ((experimentStatusId === experimentStatusEnum.OBSERVATIONAL) && (item.observationalDays > 0) && stopDateObj.add(item.observationalDays, 'day').isAfter(dayjs())) {
              return ('Under calculation')
            }
            return this.renderPValue(item)
          },
        },
      ]

      if (isCiInResultsActive) {
        columns.push({
          sortKey: 'confidenceInterval',
          label: (
            <div
              className={`${
                !this.shouldShowSig() ? 'experiment-results__dim-header' : ''
              }`}
            >
              Confidence Interval
            </div>
          ),
          sortEnabled: false,
          size: 2.4,
          render: item =>
            item.meanEffectConfidenceInterval && this.shouldShowSig() ? (
              this.renderConfidenceInterval({
                ...item,
                type: 'effect',
                value: item.meanEffect,
                lowerConfidenceInterval:
                  item.meanEffectConfidenceInterval.lower,
                upperConfidenceInterval:
                  item.meanEffectConfidenceInterval.upper,
              })
            ) : (
              <div>{this.shouldShowSig() ? 'N/A' : '--'}</div>
            ),
        })
      }
    }

    return columns
  }

  // REF Methods
  getErrorMessage = () => {
    const { errorMessage } = this.state

    const messages = Object.values(errorMessage).reduce(
      (accumulator, error) => {
        if (!accumulator && error) {
          return `${error}`
        }
        if (error) {
          return `${accumulator}; ${error}`
        }
        return accumulator
      },
      ''
    )
    return messages
  }

  // RENDER METHODS
  renderTable = () => {
    // TODO: Pull selectedMetric from state when sub-dimensions are ready
    const { currentTab, quantumMetrics, selectedMetric } = this.state
    const {
      queryData: {
        metricsForExperiment,
        experiment: { experimentMetrics, experimentTypeId },
      },
    } = this.props

    const isNotCanary = experimentTypeId !== experimentTypeEnum.CANARY

    const displayedTabs = this.formatMetricTabs()
    let tableData = []

    // Verify if there are no experiment metrics or they dont exist && metrics are avaliable for experiment
    if (
      ((experimentMetrics && experimentMetrics.length === 0) ||
        !experimentMetrics) &&
      metricsForExperiment
    ) {
      tableData = metricsForExperiment
        .filter(
          ({ metricType, isEnabled }) =>
            isEnabled && metricType.name === currentTab
        )
        .map(metric => quantumMetrics.find(({ uuid }) => uuid === metric.uuid))
        .sort(({ name: aName }, { name: bName }) => (aName > bName ? 1 : -1))
    } else {
      tableData = quantumMetrics
        .filter(({ metricTypeName }) => metricTypeName === currentTab)
        .sort(({ name: aName }, { name: bName }) => (aName > bName ? 1 : -1))
    }

    const tableGridDisplay = isNotCanary
      ? 'experiment-results__not-canary-results'
      : 'experiment-results__canary-results'

    return (
      <div className="experiment-results__metric-type-container">
        <div className="experiment-results__tab-container">
          <KiteTabs
            currentTab={currentTab}
            tabs={displayedTabs}
            onSelectTab={this.handleSelectTab}
          />
        </div>

        <div className="experiment-results__table-container">
          <SortableTable
            className={tableGridDisplay}
            tableData={tableData}
            columns={this.formatTableColumns()}
            onRowClick={metric => {
              this.setState({ selectedMetric: metric })
            }}
            expansionRender={() =>
              selectedMetric && this.renderSubmetrics(selectedMetric)
            }
            ref={element => {
              this.table = element
            }}
          />
        </div>
      </div>
    )
  }

  renderMeanValue = (
    { meanEffect, differenceInMeans, showRed, isSignificant },
    type
  ) => {
    const displayValue = type === 'difference' ? differenceInMeans : meanEffect
    const extendedClass = showRed ? '-neg-impact' : '-pos-impact'
    const differenceClassExt =
      (!isSignificant && type === 'difference') || type !== 'difference'
        ? ' experiment-results__not-significant'
        : ''

    return (
      <div className="experiment-results__mean-effect-container">
        {isSignificant && type === 'difference' && (
          <KiteIcon
            size="1rem"
            name={showRed ? 'cancel' : 'checkmark'}
            color="#000"
          />
        )}

        <span
          className={`experiment-results__mean-effect${
            isSignificant ? extendedClass : ''
          } ${differenceClassExt}`}
        >
          {displayValue}
        </span>
      </div>
    )
  }

  renderPValue = ({ isSignificant, pValue }) => {
    let pValueClass = `experiment-results__p-value-${
      isSignificant ? 'significant' : 'not-significant'
    }`

    const shouldShow = this.shouldShowSig()
    if (!shouldShow) pValueClass += ' experiment-results__p-value-not-display'

    return <span className={pValueClass}>{shouldShow ? pValue : '--'}</span>
  }

  renderConfidenceInterval = params => {
    const {
      value: theValue,
      lowerConfidenceInterval,
      upperConfidenceInterval,
      isSignificant,
      showRed,
    } = params

    const mean = convertToNum(theValue)
    const lowerInterval = convertToNum(lowerConfidenceInterval)
    const upperInterval = convertToNum(upperConfidenceInterval)
    const isPercent = endsInPercent(theValue)

    if (lowerInterval === 0 && upperInterval === 0)
      return <div style={{ paddingLeft: '1.5rem' }}>0</div>

    const series = [
      {
        name: 'confidence intervals',
        data: [
          [lowerInterval, 0],
          [mean, 0],
          [upperInterval, 0],
        ],
      },
    ]

    let colors = ['#63738A']
    if (isSignificant && showRed) {
      colors = ['#FF0000']
    } else if (isSignificant && !showRed) {
      colors = ['#02bf20']
    }

    const min = Math.round(lowerInterval) + -1
    const max = Math.round(upperInterval) + 1

    const categories = range(min, max, 1)
    const tickAmount = categories.length

    return (
      <div style={{ display: 'flex', alignItems: 'left' }}>
        <Chart
          type="line"
          series={series}
          height="70px"
          width="95%"
          options={{
            chart: {
              type: 'line',
              animations: { enabled: false },
              curve: 'straight',
              zoom: {
                enabled: false,
              },
              toolbar: {
                show: false,
              },
            },
            markers: {
              size: 0,
              colors,
              strokeColors: colors[0],
              hover: {
                sizeOffset: 1,
              },
              discrete: [
                {
                  seriesIndex: 0,
                  dataPointIndex: 1,
                  size: 1,
                  fillColor: colors[0],
                  strokeColor: colors[0],
                },
              ],
            },
            colors,
            tooltip: {
              enabled: lowerInterval ? true : false,
              shared: false,
              // eslint-disable-next-line id-length
              x: {
                show: true,
                formatter: value => (isPercent ? `${Number(value)}%` : value),
              },
              // eslint-disable-next-line id-length
              y: {
                show: false,
                formatter: () => null,
                title: {
                  formatter: () => null,
                },
              },
              marker: {
                show: false,
              },
            },
            xaxis: {
              categories,
              type: 'numeric',
              axisBorder: {
                show: true,
                color: '#e9ebf1',
              },
              labels: {
                show: true,
                rotate: 0,
                trim: true,
                rotateAlways: false,
                style: {
                  fontSize: '14px',
                },
                formatter: value => {
                  let val = `${Math.round(Number(value))}%`
                  if (val.includes('e')) {
                    val = '0.0%'
                  }
                  return val
                },
              },
              axisTicks: {
                show: true,
              },
              tickAmount: tickAmount >= 3 ? 3 : tickAmount,
              tickPlacement: 'on',
              position: 'bottom',
              min,
              max,
              tooltip: {
                enabled: false,
              },
            },
            yaxis: {
              show: false,
              tooltip: {
                enabled: false,
              },
            },
            grid: {
              borderColor: '#e9ebf1',
              show: true,
              yaxis: {
                lines: {
                  show: false,
                },
              },
            },
          }}
        />
      </div>
    )
  }

  renderSubmetrics(metric) {
    const { submetrics: submetricCategories } = metric
    const {
      queryData: {
        experiment: { experimentTypeId },
      },
    } = this.props

    const isNotCanary = experimentTypeId !== experimentTypeEnum.CANARY

    return (
      <div>
        {submetricCategories &&
          Object.keys(submetricCategories).map((categoryName, index) => {
            let borderTopClass = 'submetric-container'

            if (index !== 0) borderTopClass += ' submetric-container__borderTop'

            const submetrics = submetricCategories[categoryName]

            return (
              <div className={borderTopClass} key={`submetric-${categoryName}`}>
                <div className="submetric-container__subdimension-name">
                  {categoryName}
                </div>
                {submetrics.map(
                  ({
                    variantAMean,
                    variantBMean,
                    isSignificant,
                    showRed,
                    meanEffect,
                    differenceInMeans,
                    pValue,
                    submetricValue,
                  }) => {
                    let submetricContainerClass =
                      'submetric-container__submetric-canary-layout'

                    let columns = [
                      {
                        value: variantAMean,
                      },
                      {
                        value: variantBMean,
                      },
                    ]

                    if (isNotCanary) {
                      submetricContainerClass =
                        'submetric-container__submetric-standard-layout'
                      columns = [
                        ...columns,
                        {
                          value: this.renderMeanValue(
                            {
                              meanEffect,
                              differenceInMeans,
                              showRed,
                              isSignificant,
                            },
                            'difference'
                          ),
                        },
                        {
                          value: this.renderMeanValue(
                            {
                              meanEffect,
                              differenceInMeans,
                              showRed,
                              isSignificant,
                            },
                            'effect'
                          ),
                        },
                        {
                          value: this.renderPValue({
                            pValue,
                            isSignificant,
                          }),
                        },
                      ]
                    }

                    if (isSignificant && showRed && isNotCanary) {
                      submetricContainerClass +=
                        ' submetric-container__submetric-significant-red'
                    } else if (isSignificant && !showRed && isNotCanary) {
                      submetricContainerClass +=
                        ' submetric-container__submetric-significant-green'
                    }

                    return (
                      <div
                        className={submetricContainerClass}
                        key={`submetric-${submetricValue}-${variantAMean}`}
                      >
                        <div className="submetric-container__submetric-name">
                          <div>{submetricValue}</div>
                        </div>
                        {columns.map((col, i) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <div key={`col-${i}`}>{col.value}</div>
                        ))}
                      </div>
                    )
                  }
                )}
              </div>
            )
          })}
      </div>
    )
  }

  render() {
    const {
      startDate,
      stopDate,
      maxDate,
      isQueryingQuantum,
      selectedControl,
      selectedVariant1,
      errorMessage,
      quantumMetrics,
    } = this.state

    const {
      queryData: { experiment, loading, error: quantumVariantsError },
    } = this.props

    const {
      statusLabel,
      startLabel,
      plannedEndLabel,
      actualEndLabel,
      dateSelectLabel,
      orderSelectLabel,
      errorQuantumApi,
      infoNoSig,
      errorExperimentNotStarted,
      errorZeroDays,
      adHocEvaluation,
      observationalInfo
    } = copyContent

    if (loading) {
      return (
        <div>
          <h4>View Results</h4>
          <div className="app__loader">
            <KiteLoader size="7rem" />
          </div>
        </div>
      )
    }

    if (quantumVariantsError) {
      return (
        <KiteAlert
          className="app__page-level-message"
          type="alert"
          description={errorQuantumApi}
          linkText="contact support."
          onLinkClick={() => {
            window.location = `mailto:DL-Distillery-Support@charter.com?subject=Distillery Support for Results - ${experiment.name} (${experiment.uuid})`
          }}
        />
      )
    }

    if (!startDate) {
      return (
        <KiteAlert
          className="app__page-level-message"
          type="info"
          description={errorExperimentNotStarted}
        />
      )
    }

    const startDateObj = dayjs(startDate, DATE_FORMAT)
    let stopDateObj = dayjs(stopDate, DATE_FORMAT)
    let numberOfDays = stopDateObj.diff(startDateObj, 'day')
    const hasZeroDays = numberOfDays <= 0 && !quantumMetrics.length ? errorZeroDays : null
    if (hasZeroDays) {
      numberOfDays = 0
      stopDateObj = dayjs(startDate, DATE_FORMAT)
    }

    const resultsError =
      hasZeroDays || errorMessage.variantsSame || errorMessage.quantumQueryError

    const {
      variants,
      finalOutcome,
      experimentMetrics,
      experimentStatusId,
    } =
      experiment

    let status = experimentStatusEnumReverse[experimentStatusId]

    status = capitalizeString(status)

    // eslint-disable-next-line no-nested-ternary
    const statusColor =
      status === 'Completed'
        ? '#0073d1'
        : status === 'Cancelled'
        ? '#f57878'
        : '#0d6031'
    const shouldShowSummaryDates = ['Running', 'Observational', 'Completed', 'Cancelled'].includes(status)
    const shouldShowActualEndDate = ['Observational', 'Completed', 'Cancelled'].includes(status)
    let plannedEndDate = null
    let actualEndDate = null
    let observationalEnd = null

    let isAdHocEvaluation = false

    if (quantumMetrics.length) {
      quantumMetrics.forEach(({ name, _id }) => {
        if (adHocUuid.includes(_id) || name.includes('Ad Hoc')) {
          isAdHocEvaluation = true
        }
      })
    } else if (experimentMetrics.length) {
      experimentMetrics.forEach(({ metricUuid }) => {
        if (adHocUuid.includes(metricUuid)) {
          isAdHocEvaluation = true
        }
      })
    }

    // if in observational period will grab and display the end date of the follw on time
    if ((experimentStatusId === experimentStatusEnum.OBSERVATIONAL) && experimentMetrics.length) {
      const endDateObj = dayjs(experiment.stopTime)
      const maxExperimentObservDay =  this.handleCheckObservationalDays(experimentMetrics);

      observationalEnd = endDateObj.add(maxExperimentObservDay, 'day').format('MM/DD/YY')
    }

    if (shouldShowSummaryDates) {
      const {
        queryData: {
          experiment: { startTime, durationNumDays, stopTime },
        },
      } = this.props
      plannedEndDate = dayjs(startTime, DATE_FORMAT)
        .add(durationNumDays, 'day')
        .format(DATE_FORMAT_2)

      if (shouldShowActualEndDate) {
        actualEndDate = dayjs(stopTime, DATE_FORMAT).format(DATE_FORMAT_2)
      }
    }

    const willRenderTable =
      !resultsError &&
      selectedControl &&
      quantumMetrics &&
      quantumMetrics.length > 0

    return (
      <div className="experiment-results">
        <div className="experiment-results__controls">
          <div className="experiment-results__summary">
            <div className="experiment-results__summary-block">
              <div>{`${statusLabel}:`}</div>
              <div style={{ fontWeight: 500, color: statusColor }}>
                {status}
              </div>
            </div>
            <div className="experiment-results__summary-block">
              <div>{`${startLabel}:`}</div>
              <div>{dayjs(startDate).format(DATE_FORMAT_2)}</div>
            </div>

            {shouldShowSummaryDates && (
              <div className="experiment-results__summary-dates">
                <div className="experiment-results__summary-vert-block">
                  <div>{`${plannedEndLabel}:`}</div>
                  <div>{plannedEndDate}</div>
                </div>
                {shouldShowActualEndDate && (
                  <div className="experiment-results__summary-vert-block">
                    <div>{`${actualEndLabel}:`}</div>
                    <div>{actualEndDate}</div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="experiment-results__date-selection">
            <DatePicker
              label={dateSelectLabel}
              minDate={dayjs(startDate).format(DATE_FORMAT)}
              maxDate={dayjs(maxDate).format(DATE_FORMAT)}
              value={dayjs(stopDateObj).format(DATE_FORMAT)}
              onDateChange={this.handleEndDateChange}
            />

            <div className="experiment-results__dates">
              {`${dayjs(startDate).format('MMM D, YYYY')} - ${dayjs(
                stopDateObj
              ).format('MMM D, YYYY')} (${numberOfDays} day${
                numberOfDays > 1 ? 's' : ''
              })`}
            </div>
          </div>

          <div className="experiment-results__variant-selection">
            {selectedControl && (
              <Fragment>
                <div className="experiment-results__variants">
                  <div className="experiment-results__select-container">
                    <KiteSelect
                      label={orderSelectLabel}
                      value={selectedControl.id}
                      onChange={this.handleControlSelection}
                    >
                      {variants.map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </KiteSelect>

                    <div className="experiment-results__select-sub-title">
                      {selectedControl.displayOrder === 0
                        ? 'Control'
                        : `Variant ${selectedControl.displayOrder}`}
                    </div>
                  </div>

                  <span className="experiment-results__variant-vs">vs.</span>

                  <div className="experiment-results__select-container">
                    <KiteSelect
                      width={150}
                      label={'\xa0'}
                      value={selectedVariant1.id}
                      onChange={this.handleVariant1Selection}
                    >
                      {variants.map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </KiteSelect>

                    <div className="experiment-results__select-sub-title">
                      {selectedVariant1.displayOrder === 0
                        ? 'Control'
                        : `Variant ${selectedVariant1.displayOrder}`}
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        </div>

        <ExpansionPanel
          type="minimal"
          isExpanded={!!errorMessage.quantumQueryError && !isAdHocEvaluation}
        >
          <KiteAlert
            className="app__page-level-message"
            type="alert"
            description={errorMessage.quantumQueryError}
            linkText="contact support."
            onLinkClick={() => {
              window.location = `mailto:DL-Distillery-Support@charter.com?subject=Distillery Support for Results - ${experiment.name} (${experiment.uuid})`
            }}
          />
        </ExpansionPanel>

        <ExpansionPanel
          type="minimal"
          isExpanded={(experimentStatusId === experimentStatusEnum.OBSERVATIONAL) && (observationalEnd > dayjs())}
        >
          <KiteAlert
            className="app__page-level-message"
            type="info"
            description={observationalInfo(observationalEnd)}
          />
        </ExpansionPanel>

        <ExpansionPanel
          type="minimal"
          isExpanded={!!errorMessage.variantsSame && !isAdHocEvaluation}
        >
          <KiteAlert
            className="app__page-level-message"
            type="caution"
            description={errorMessage.variantsSame}
          />
        </ExpansionPanel>

        <ExpansionPanel
          type="minimal"
          isExpanded={hasZeroDays && !isAdHocEvaluation && !willRenderTable}
        >
          <KiteAlert
            className="app__page-level-message"
            type="info"
            description={hasZeroDays}
          />
        </ExpansionPanel>

        <ExpansionPanel type="minimal" isExpanded={isAdHocEvaluation}>
          <KiteAlert
            className="app__page-level-message"
            type="info"
            description={adHocEvaluation}
          />
        </ExpansionPanel>

        {!resultsError && isQueryingQuantum && (
          <div className="app__loader">
            <KiteLoader size="7rem" />
          </div>
        )}

        {finalOutcome && (
          <FinalOutcomePDF
            className="experiment-results__final-outcome"
            experiment={experiment}
          />
        )}

        <ExpansionPanel
          type="minimal"
          isExpanded={willRenderTable && !this.shouldShowSig()}
        >
          <KiteAlert
            className="app__page-level-message experiment-results__no-sig-banner"
            type="info"
            description={infoNoSig}
          />
        </ExpansionPanel>

        {willRenderTable && this.renderTable()}
      </div>
    )
  }
}

export default flowRight(gqlHOCQuery)(ExperimentResults)
