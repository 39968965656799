export default {
  terminateSubMessage: 'This action is irreversible.',
  terminateError:
    'There was a problem terminating your experiment. Distillery support has been contacted.  Please try again.',
  winnerVariantPlaceholder: 'Select winning variant (optional)',
  finalOutcomePlaceholder:
    'End Experiment in All Environments',
  finalOutcomeMinError:
    'Please add a final outcome with minimum of 30 characters',
  finalOutcomeMaxError:
    'Please keep your final outcome less than 250 characters',
  cancelTerminateText: 'Never mind',
};
