import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

export const GET_EXPERIMENTS_VARIANTS = gql`
  query getExperimentVariants($id: Int!) {
    experiment(id: $id) {
      id
      experimentTypeId
      experimentStatusId
      config
      multipleVariantReason
      variants {
        id
        uuid
        name
        description
        url
        jsonPayload
        displayOrder
        variantUploads {
          id
          fileUrl
        }
      }
    }
  }
`

const getExperimentVariants = graphql(GET_EXPERIMENTS_VARIANTS, {
  name: 'experimentVariantsData',
  withRef: true,
  options: {
    fetchPolicy: 'cache-and-network',
  },
})

export default getExperimentVariants
