import { gql } from '@apollo/client'

export const GET_CREATE_DATA = gql`
  query getCreateData($id: Int!, $permissionEnum: PermissionEnum!) {
    contents {
      contentId
      contentTitle
      contentAreaId
      personalization
    }
    contentAreas {
      contentAreaId
      contentIds
    }
    audiences {
      id
      name
      description
      rules
    }
    productsByExperimentCategory(id: $id) {
      id
      name
      displayName
      quantumApplicationName
      quantumApplicationType
      activeAccounts
      activeDevices
      sampleCalculatorEnabled
      productCategoryType {
        id
        name
      }
      features {
        id
        name
        displayName
        featureType {
          id
          name
        }
      }
    }
    productCategoryTypes(permissionEnum: $permissionEnum) {
      id
      name
    }
    users {
      id
      displayName
      userToRoles {
        id
        roleId
      }
    }
  }
`
