import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

/*
* Sample Input
* {
   input: {
       subscriptionTopic: 'arn:aws:sns:us-west-2:369361176069:dx-experiment-status-development',
       endpoint: 'https://development-distillery-subscription-service.distillery.spectrumtoolbox.com/sns',
  }
}
*/

const SUBSCRIBE_TO_SNS = gql`
  mutation subscribeToSns($input: InsertSubscription!) {
    subscribeToSns(input: $input)
  }
`

const subscribeToSns = graphql(SUBSCRIBE_TO_SNS, {
  name: 'subscribeToSns',
})

export default subscribeToSns
