import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

/*
* Sample Input
*
* {
    overrideGroupId: Int!
    name: CustomString
    description: CustomString
    overrideIds: [Int]
  }
*/

const UPDATE_OVERRIDE_GROUP = gql`
  mutation updateOverrideGroup($input: UpdateOverrideGroupType!) {
    updateOverrideGroup(input: $input) {
      id
      name
      overrides {
        id
        name
        overrideIdentifier
      }
    }
  }
`

const updateOverrideGroup = graphql(UPDATE_OVERRIDE_GROUP, {
  name: 'updateOverrideGroup',
})

export default updateOverrideGroup
