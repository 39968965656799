import React, { useState, Fragment, useEffect } from 'react'
import { KiteContextualMenu, KiteButton } from '@kite/react-kite'
import { CounterInput } from '@kite/react-kite-plus'
import { useMutation } from '@apollo/client'
import {
  experimentStatusEnum,
  willTerminateCancelExperiment,
} from '@charter/distillery-rules'
import { DialogContainer, Modal } from '../../componentLibrary'
import CancelCompleteModal from './CancelCompleteModal/CancelCompleteModal'
import { CLONE_EXPERIMENT } from '../CloneButton/mutations/cloneExperiment'
import { DELETE_EXPERIMENT } from '../DeleteExperimentButton/mutations/deleteExperiment'

import './EndExperimentButton.scss'

const { RUNNING, COMPLETED, CANCELLED, OBSERVATIONAL } = experimentStatusEnum

export default function EndExperimentButton({
  experiment: {
    id,
    name,
    startTime,
    experimentMetrics,
  },
  selectedEnvironment: {
    id: environmentId,
    name: environmentName,
    sampleAllocation,
    experimentStatusId,
  },
  canTerminate,
  hasCloneExperimentPermission,
  hasDeletePermissions,
  history,
  activeNetworkLockdown,
  onSuccess,
}) {
  const [actionTaken, setActionTaken] = useState(null);
  const [experimentCloneName, setExperimentCloneName] = useState('')
  const [errorMessage, setErrorMessage] = useState({})
  const runLessThanDay = willTerminateCancelExperiment({ durationNumDays: 1, startTime })
  const [disabledStates, setDisabledStates] = useState({
    cloneDisabled: false,
    endDisabled: false,
    deleteDisabled: false,
  })
  const [
    cloneExperiment,
    { loading: cloneLoading, error: cloneMutationError },
  ] = useMutation(CLONE_EXPERIMENT, {
    onCompleted: ({ cloneExperiment: { id: newExperimentId, isCmsExperiment } }) => {
      const path = !isCmsExperiment
        ? `/experiments/${newExperimentId}/set-up/variants`
        : `/experiments/${newExperimentId}/set-up/variants&content`
      history.push(path)
    },
  })
  const [
    deleteExperiment,
    { loading: deleteLoading, error: deleteMutationError },
  ] = useMutation(DELETE_EXPERIMENT, {
    onCompleted: () => {
      history.push('/experiments/all')
    },
  })

  useEffect(() => {
    if (cloneMutationError) {
      setErrorMessage((prevErrors) => ({
        ...prevErrors,
        cloneError: 'There was a problem cloning your experiment. If this continues, contact distillery support.'
      }))
    }

    if (deleteMutationError) {
      setErrorMessage((prevErrors) => ({
        ...prevErrors,
        deleteError: 'There was a problem deleting your experiment. If this continues, contact distillery support.'
      }))
    }

    if (!canTerminate || ([COMPLETED, CANCELLED, OBSERVATIONAL].includes(experimentStatusId))) {
      setDisabledStates((disabled) => ({
        ...disabled,
        endDisabled: true,
      }))
    }

    if (!hasCloneExperimentPermission) {
      setDisabledStates((disabled) => ({
        ...disabled,
        cloneDisabled: true,
      }))
    }

    if (!hasDeletePermissions) {
      setDisabledStates((disabled) => ({
        ...disabled,
        deleteDisabled: true,
      }))
    }
  }, [
    deleteMutationError,
    cloneMutationError,
    canTerminate,
    hasCloneExperimentPermission,
    hasDeletePermissions,
    experimentStatusId,
  ])

  const {
    cloneDisabled,
    endDisabled,
    deleteDisabled,
  } = disabledStates

  const handleExperimentActionSelect = (menuItem, event) => {
    const { label } = menuItem
    if (label === 'Clone Experiment' && !cloneDisabled) {
      setActionTaken('clone')
    } else if (label === 'End Experiment' && !endDisabled) {
      setActionTaken('end')
    } else if (label === 'Delete Experiment' && !deleteDisabled) {
      setActionTaken('delete')
    }
  }

  const handleCancelAction = () => {
    setExperimentCloneName('')
    setActionTaken(null)
  }

  const handleSubmit = async (action) => {
    if (action === 'clone') {
      await cloneExperiment({
        variables: {
          input: {
            experimentId: id,
            name: experimentCloneName.trim(),
          }
        }
      })
    } else {
      await deleteExperiment({
        variables: {
          experimentId: id,
        }
      })
    }
  }

  const experimentActions = [{
    label: 'Clone Experiment',
    render: (<div className={cloneDisabled ? `end-experiment-btn__disabled-btn`: ''}>
      Clone Experiment
    </div>)
  }, {
    label: 'End Experiment',
    render: (<div className={endDisabled ? `end-experiment-btn__disabled-btn`: ''}>
      End Experiment
    </div>)
  }, {
    label: 'Delete Experiment',
    render: (<div className={deleteDisabled ? `end-experiment-btn__disabled-btn`: 'end-experiment-btn__delete-btn'}>
      Delete Experiment
    </div>)
  }]

  const canComplete = willTerminateCancelExperiment({ durationNumDays: 1, startTime })
  const needsObservation = experimentMetrics.filter(({ observationalDays }) => observationalDays > 0).length > 0
  const isCloning = actionTaken && actionTaken === 'clone'
  const isDeleting = actionTaken && actionTaken === 'delete'
  const isEnding = actionTaken && actionTaken === 'end'
  const headerName = environmentName === 'production' ? 'All Environments': `${environmentName.toUpperCase()} Only`;
  
  return (
    <Fragment>
      {isCloning && (
        <DialogContainer>
          <div className="clone-button__dialog">
            <h2 className="clone-button__dialog-header">
              {`Clone "${name}"`}
            </h2>

            <CounterInput
              className="clone-button__dialog-input"
              label="Enter a unique name..."
              value={experimentCloneName}
              placeholder="New experiment name"
              maxCharCount={35}
              noSpecialCharacters
              onChange={({ target: { value } }) => {
                setExperimentCloneName(value)

                if (errorMessage) {
                  setErrorMessage({})
                }
              }}
              inputProps={{
                autoFocus: true,
                onKeyDown: ({ key }) => {
                  if (key === 'Escape') {
                    handleCancelAction()
                  }
                },
              }}
              errorMessage={errorMessage.name || errorMessage.cloneError}
              maxWidth="100%"
            />

            <KiteButton
              className="clone-button__dialog-submit"
              onClick={() => handleSubmit('clone')}
              loading={cloneLoading}
              disabled={experimentCloneName === '' || cloneLoading}
            >
              Clone
            </KiteButton>

            <KiteButton
              className="clone-button__dialog-cancel"
              type="standalone-link"
              disabled={cloneLoading}
              onClick={() => {
                handleCancelAction()
              }}
            >
              Cancel
            </KiteButton>
          </div>
        </DialogContainer>
      )}

      {isDeleting && (
        <Modal
          message="Are you sure you want to delete this experiment?"
          onConfirm={() => handleSubmit('delete')}
          onDeny={handleCancelAction}
          buttonProps={{
            confirm: {
              value: deleteLoading
                ? 'Deleting...' 
                : `Delete ${name}`,
              type: 'warning-outline',
            },
            deny: { value: 'Cancel' },
          }}
          loading={deleteLoading}
          errorMessage={errorMessage.deleteError}
        />
      )}

      {isEnding && (
        <CancelCompleteModal 
          headerName={headerName} 
          canComplete={canComplete}
          needsObservation={needsObservation}
          handleCancelAction={handleCancelAction}
          onSuccess={onSuccess}
          experimentId={id}
          environmentId={environmentId}
          runLessThanDay={runLessThanDay}
          isRunning={experimentStatusId === RUNNING}
        />
      )}

      <div className="end-experiment-btn">
        <KiteContextualMenu
          buttonContent="Experiment Actions"
          items={experimentActions}
          menuAlignment="left"
          onClick={handleExperimentActionSelect}
        />
      </div>
    </Fragment>
  )
}