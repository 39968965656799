import React, { Component } from 'react'
import { array, object, string, bool, func } from 'prop-types'
import dayjs from 'dayjs'
import flowRight from 'lodash/flowRight'
import isEqual from 'lodash/isEqual'

import { createQuantumApolloClient } from '@charter/distillery-rules'

import { KiteIcon, KiteRadio, KiteLoader } from '@kite/react-kite'
import { TextArea } from '@kite/react-kite-plus'

import { formatLoggingError, GqlBuilder } from '../../../../shared/utilities'
import { client } from '../../../../configuration/configApiClient'
import { LOG_ERROR } from '../../../../shared/mutations'
import GET_QUANTUM_METRIC_COUNT from './queries/getQuantumMetricCount'
import { ExpansionPanel } from '../../../../componentLibrary'
import copyContent from './data/copyContent'
import getSimultaneousExperiments from './queries/getSimulatenousExperiments'
import './EvaluationForm.scss'

const oppositeValueEvaluationItem = [12, 15, 16, 20]

const gqlHOCQuery = new GqlBuilder().compileHOCQuery([
  getSimultaneousExperiments,
])

export class EvaluationForm extends Component {
  static propTypes = {
    evaluationItems: array.isRequired,
    subCategories: array.isRequired,
    experiment: object.isRequired,
    quantumEvaluator: bool.isRequired,
    retrieveQuantumToken: string.isRequired,
    onChange: func.isRequired,
    queryData: func.isRequired,
    generalComments: string.isRequired,
    isDisabled: bool,
  }

  static defaultProps = {
    isDisabled: false,
  }

  state = {
    expandedStates: {},
    metricCounts: [],
    isLoadingResults: true,
    generalComments: '',
    evaluationItemInformation: {},
    metricDefinitions: [],
  }

  // LIFECYCLE METHODS
  componentDidMount() {
    const { evaluationItems } = this.props

    if (evaluationItems && evaluationItems.length) {
      this.handleSetEvaluationCard()
    }
  }

  componentDidUpdate(prevProps) {
    const { evaluationItems } = this.props
    const { evaluationItems: prevData } = prevProps

    if (evaluationItems && !isEqual(evaluationItems, prevData)) {
      this.handleSetEvaluationCard()
    }
  }

  handleSetEvaluationCard = async () => {
    const { evaluationItems, generalComments } = this.props

    const evaluationItemInformation = {}

    evaluationItems.forEach(({ id, score, commentary, evaluationItem }) => {
      evaluationItemInformation[id] = {
        id,
        score,
        commentary,
        isComplete: score > 0 || (score === 0 && commentary?.length > 0),
        evaluationItem,
      }
    })

    await this.setState({ evaluationItemInformation, generalComments })

    await this.queryQuantum()
  }

  queryQuantum = async () => {
    const {
      experiment: { uuid: experimentId, stopTime, experimentToProductFeatures },
      retrieveQuantumToken: quantumToken,
    } = this.props

    try {
      const quantumMetricsApiClient = createQuantumApolloClient({
        quantumToken,
      })

      // Grab the app coords for quantum to filter search
      let {
        product: { quantumApplicationName },
      } = experimentToProductFeatures[0]
      const {
        product: { quantumApplicationType },
      } = experimentToProductFeatures[0]

      if (quantumApplicationName.toLowerCase() === 'spectrummobile') {
        quantumApplicationName = 'SpecU'
      }
      const appCoords =
        quantumApplicationName && quantumApplicationType
          ? `${quantumApplicationName}@${quantumApplicationType}`
          : null

      // Grab the end date
      const end = dayjs(stopTime).format('YYYY-MM-DD')

      const quantumQuery = quantumMetricsApiClient.query({
        query: GET_QUANTUM_METRIC_COUNT,
        variables: {
          quantumToken,
          denverDates: [end],
          experimentId,
          subdimensions: [null],
          applicationCoords: [appCoords],
        },
      })

      const response = await quantumQuery

      const {
        data: { counts, metricDefinitions },
      } = response

      await this.setState({
        metricCounts: counts,
        metricDefinitions,
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)

      const input = formatLoggingError(error)
      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input,
        },
      })
    } finally {
      this.setState({ isLoadingResults: false })
    }
  }

  handleChange = async (element, id) => {
    const { onChange } = this.props
    const { evaluationItemInformation } = this.state
    const {
      target: { name, value },
    } = element

    if (name !== 'generalComments') {
      let updatedName = 'commentary'
      let updatedValue = value
      // Verify if its the score being updated
      if (name.includes('score')) {
        updatedName = 'score'
        updatedValue = Number(value)
      }

      const objectToAdd = {
        [id]: {
          ...evaluationItemInformation[id],
          [updatedName]: updatedValue,
        },
      }

      const updatedEvaluationItem = {
        ...evaluationItemInformation,
        ...objectToAdd,
      }

      // Verify if item is compelete
      updatedEvaluationItem[id].isComplete =
        updatedEvaluationItem[id].score > 0 ||
        (updatedEvaluationItem[id].score === 0 &&
          updatedEvaluationItem[id].commentary?.length > 0)

      await this.setState({ evaluationItemInformation: updatedEvaluationItem })
      await onChange(objectToAdd, 'newItems')
    } else {
      await this.setState({ [name]: value })
      await onChange(value, 'generalComments')
    }
  }

  renderEvaluationItemEvaluator = ({
    id,
    evaluationItem: { id: evalItemId, displayName },
  }) => {
    const { isDisabled } = this.props
    const { evaluationItemInformation } = this.state
    let scoreOfItem = null
    let commentaryOfItem = ''
    let isCompleteValue = false
    let yesScoreValue = '5'
    let noScoreValue = '0'
    let textAreaWidth = '60rem'
    if (evaluationItemInformation[id]) {
      scoreOfItem = evaluationItemInformation[id].score
      commentaryOfItem = evaluationItemInformation[id].commentary
      isCompleteValue = evaluationItemInformation[id].isComplete
    }

    if (evalItemId === 8 || evalItemId === 9) {
      textAreaWidth = '25rem'
    }
    // Verify if item should have the opposite values
    if (oppositeValueEvaluationItem.includes(evalItemId)) {
      yesScoreValue = '0'
      noScoreValue = '5'
    }

    return (
      <div key={`evaluation-item-${id}`}>
        <div>
          {isCompleteValue && (
            <KiteIcon margin="5px" name="checkmark" size="1rem" />
          )}
          {displayName}
        </div>
        <div className="evaluation-form__input-container">
          <KiteRadio
            id={`radio-btns-${id}`}
            key={`radio-btns-${id}`}
            buttonOrientation="column"
            className="evaluation-form__radio-btns"
            disabled={isDisabled}
            name={`score-${id}`}
            onChange={event => {
              this.handleChange(event, id)
            }}
            radioButtons={[
              {
                id: `yes-${id}`,
                label: 'Yes',
                value: yesScoreValue,
                checked: scoreOfItem === Number(yesScoreValue),
              },
              {
                id: `no-${id}`,
                label: 'No',
                value: noScoreValue,
                checked: scoreOfItem === Number(noScoreValue),
              },
            ]}
          />
          <TextArea
            className="experiment-plan__description"
            name={`commentary-${id}`}
            value={commentaryOfItem || ''}
            disabled={isDisabled}
            errorMessage={
              scoreOfItem === 0 && !commentaryOfItem?.length
                ? 'Required to comment on reasoning of score.'
                : null
            }
            onChange={event => {
              this.handleChange(event, id)
            }}
            placeholder="Enter comment here (required if scoring equates to a zero on the selected criterion)"
            height="4.5rem"
            width={textAreaWidth}
            maxLength="1000"
          />
        </div>
      </div>
    )
  }

  renderEvaluationItem = ({
    id,
    score,
    commentary,
    evaluationItem: { displayName },
  }) => {
    const iconName = score > 0 || score ? 'checkmark' : 'x'
    const iconColor = score > 0 || score ? 'green' : '#eb4b47'

    return (
      <div
        key={`evaluation-item-${id}`}
        className="evaluation-form__evaluation-item"
      >
        <div>
          <KiteIcon
            margin="5px"
            name={iconName}
            color={iconColor}
            size="1rem"
          />
          <span className="evaluation-form__display-item">{displayName}</span>
        </div>
        <div className="evaluation-form__evaluation-item-commentary">
          {commentary}
        </div>
      </div>
    )
  }

  renderSubCategory = ({ id, displayName, name }) => {
    const {
      experiment,
      experiment: { experimentMetrics, metrics },
      quantumEvaluator,
      queryData: { findExperimentsRunSimultaneously },
      isDisabled,
    } = this.props
    const {
      expandedStates,
      metricCounts,
      evaluationItemInformation,
      metricDefinitions,
      generalComments,
    } = this.state

    const subCatEvalItems = Object.values(evaluationItemInformation)
      .filter(
        ({
          evaluationItem: {
            evaluationSubCategory: { id: subCatId },
          },
        }) => subCatId === id
      )
      .sort(
        (
          { evaluationItem: { displayOrder: firstOrder } },
          { evaluationItem: { displayOrder: secondOrder } }
        ) => firstOrder - secondOrder
      )

    let isCompleteSubCategories = true
    subCatEvalItems.forEach(({ score, commentary }) => {
      if (score === null || (!score && !commentary?.length)) {
        isCompleteSubCategories = false
      }
    })
    const experimentInfo = Object.keys(copyContent[name]).map(key => {
      let displayInfo = experiment[key]
      if (key === 'metricCounts') {
        displayInfo = metricCounts
      } else if (key === 'durationNumDays') {
        const startDateObj = dayjs(
          dayjs(experiment.startTime).format('YYYY-MM-DD'),
          'YYYY-MM-DD'
        )
        const stopDateObj = dayjs(
          dayjs(experiment.stopTime).format('YYYY-MM-DD'),
          'YYYY-MM-DD'
        )
        const actualDurationDays = Math.floor(
          stopDateObj.diff(startDateObj, 'day')
        )
        displayInfo = { durationNumDays: experiment[key], actualDurationDays }
      } else if (
        key === 'multipleVariantReason' &&
        experiment.variants.length <= 4
      ) {
        // Verify if needs to display a reason
        return ''
      } else if (key === 'simultaneouslyRunningExperiment') {
        displayInfo = findExperimentsRunSimultaneously || []
      } else if (key === 'metrics') {
        displayInfo = {
          metrics,
          experimentMetrics,
          metricDefinitions,
        }
      }
      return copyContent[name][key].displayItem(
        displayInfo,
        `evaluation-form__${key}-experiment-info`
      )
    })

    const className = `evaluation-form__experiment-information-${name}`
    const classNameExt = `evaluation-form__sub-cat-${name}`
    const displayTitle = (
      <div
        key={`sub-cateogories-${id}`}
        className="evaluation-form__sub-category-header"
      >
        <div>
          {isCompleteSubCategories && quantumEvaluator && (
            <KiteIcon margin="5px" name="checkmark" size="1rem" />
          )}
          {displayName}
        </div>
      </div>
    )

    return (
      <ExpansionPanel
        type="minimal"
        id={`subcategory-${id}`}
        key={`subcategory-${id}`}
        title={displayTitle}
        titleClassName="evaluation-form__subcategory-title"
        isExpanded={expandedStates[id]}
      >
        <div className="evaluation-form__expansion-panel">
          <div className={className}>{experimentInfo}</div>
          <div className={`evaluation-form__subcategory-items ${classNameExt}`}>
            {subCatEvalItems.map(item => {
              if (quantumEvaluator) {
                return this.renderEvaluationItemEvaluator(item)
              }
              return this.renderEvaluationItem(item)
            })}
            {id === 6 && quantumEvaluator && (
              <TextArea
                label="General comments:"
                className="experiment-plan__description"
                name="generalComments"
                value={generalComments || ''}
                disabled={isDisabled}
                onChange={event => {
                  this.handleChange(event, id)
                }}
                placeholder="Record any general comments (optional)."
                height="4.5rem"
                maxLength="2500"
              />
            )}

            {id === 6 && !quantumEvaluator && (
              <div
                key={`evaluation-item-general-comments`}
                className="evaluation-form__evaluation-item"
              >
                <div>
                  <span className="evaluation-form__display-item">
                    General Comments:
                  </span>
                </div>
                <div className="evaluation-form__evaluation-item-commentary">
                  {generalComments || 'No comments were added.'}
                </div>
              </div>
            )}
          </div>
        </div>
      </ExpansionPanel>
    )
  }

  render() {
    const {
      subCategories,
      queryData: { loading },
    } = this.props

    const { isLoadingResults } = this.state

    if (loading || isLoadingResults) {
      return (
        <div className="app__loader">
          <KiteLoader size="7rem" />
        </div>
      )
    }

    return (
      <div className="evaluation-form">
        {subCategories
          .sort(
            ({ displayOrder: firstOrder }, { displayOrder: secondOrder }) =>
              firstOrder - secondOrder
          )
          .map(subCategory => this.renderSubCategory(subCategory))}
      </div>
    )
  }
}

export default flowRight(gqlHOCQuery)(EvaluationForm)
