import React, { useEffect, useState, useRef, createRef } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { isEmpty } from 'lodash'
import { QuerySummary } from '../../../../components'
import {
  KiteCard,
  KiteInput,
  KiteLoader,
  KiteButton,
  KiteCheckbox,
  KiteLink,
} from '@kite/react-kite'
import { DatePicker } from '@kite/react-kite-plus'
import { experimentStatusEnum } from '@charter/distillery-rules'
import { GET_REVIEW_DATA } from '../../queries/getReviewData'
import { UPDATE_CONTENT_MAP, PUBLISH_CONTENT_MAP } from '../../mutations'
import { GET_CONTENT_MAP_APPROVERS } from '../../queries/getContentMapApprovers'
import {
  scrollToNavigationPosition,
  formatUserProfileFields,
} from '../../../../shared/utilities'

import './Review.scss'

import copyContent from '../../data/copyContent'

const { REACT_APP_USER_RESI_PREVIEW_URL, REACT_APP_USER_SMB_PREVIEW_URL } =
  process.env

const {
  reviewPage: {
    startDate,
    stopDate,
    timePlaceholder,
    dateError,
    timeError,
    approvalMessage,
    approvalBtn,
    experimentContentLabel,
    experimentPreviewLabel,
  },
  dateChangeWarning,
  activeContentWarning,
} = copyContent

const initError = {
  approverRole: '',
}

export function Review({
  id,
  disabled,
  isDirty,
  onChange,
  history,
  config,
  contentMap,
  setSuccess,
  handleSubmit,
  setErrorMessage,
  handleWarningDisplays,
  setModalOpen,
  currentUser,
  setIsCancelContentModalOpen,
  hasPermission,
  isCompleteMap,
}) {
  const [activeDate, setActiveDate] = useState('')
  const [activeTime, setActiveTime] = useState('')
  const [deactiveDate, setDeactiveDate] = useState('')
  const [deactiveTime, setDeactiveTime] = useState('')
  const [hasOtherActiveMap, setHasOtherActiveMap] = useState(false)
  const [isReadyForApproval, setIsReadyForApproval] = useState(false)
  const [isRunningOrComplete, setIsRunningOrComplete] = useState(false)
  const [error, setError] = useState(initError)
  const itemsRef = useRef([])
  const [status, setStatus] = useState(contentMap.statusId)
  const { loading, data } = useQuery(GET_REVIEW_DATA, {
    variables: { id: Number(id) },
  })
  const [
    updateContentMap,
    { loading: loadingUpdateContentMap, error: updateContentMapError },
  ] = useMutation(UPDATE_CONTENT_MAP, {
    onCompleted: data => {
      handleSubmit(data.updateContentMap)
    },
  })
  const [
    publishContentMap,
    { loading: loadingPublish, error: errorPublishing },
  ] = useMutation(PUBLISH_CONTENT_MAP)
  const { data: contentMapApprovers } = useQuery(GET_CONTENT_MAP_APPROVERS, {
    variables: { id: Number(id) },
    fetchPolicy: 'network-only',
  })
  const formatTime = (date, time) => {
    if (date && time) {
      const newDate = new Date()
      const dateSplit = date.split('-')
      newDate.setFullYear(dateSplit[0], dateSplit[1] - 1, dateSplit[2])
      const timeSplit = time.split(':')
      newDate.setHours(timeSplit[0], timeSplit[1])

      return newDate.toISOString()
    }
    return null
  }

  useEffect(() => {
    const hasError = updateContentMapError || errorPublishing

    if (contentMap && !hasError) {
      const { startTime, stopTime, isApprovalReady } = contentMap
      if (startTime) {
        setActiveDate(startTime.split('T')[0])
      }

      if (stopTime) {
        setDeactiveDate(stopTime.split('T')[0])
      }

      // Grab time from dates
      const startHour =
        new Date(startTime).getHours() >= 10
          ? new Date(startTime).getHours()
          : `0${new Date(startTime).getHours()}`
      const startMin =
        new Date(startTime).getMinutes() >= 10
          ? new Date(startTime).getMinutes()
          : `0${new Date(startTime).getMinutes()}`
      setActiveTime(startTime ? `${startHour}:${startMin}` : startTime)

      const stopHour =
        new Date(stopTime).getHours() >= 10
          ? new Date(stopTime).getHours()
          : `0${new Date(stopTime).getHours()}`
      const stopMin =
        new Date(stopTime).getMinutes() >= 10
          ? new Date(stopTime).getMinutes()
          : `0${new Date(stopTime).getMinutes()}`

      // add or remove refs
      itemsRef.current = Array(contentMap.personalizedContent.length)
        .fill()
        .map((_, i) => itemsRef.current[i] || createRef())

      setDeactiveTime(stopTime ? `${stopHour}:${stopMin}` : stopTime)
      setIsReadyForApproval(isApprovalReady)

      const conflictingMaps = contentMap.hasActiveContentArea.filter(
        ({ stopTime: activeStop }) => !activeStop || activeStop > startTime
      )

      if (contentMap.isReadyForApproval && startTime < new Date()) {
        setIsRunningOrComplete(true)
      }

      if (
        contentMap.isApprovalReady &&
        contentMap.statusId !== experimentStatusEnum.APPROVED &&
        contentMap.hasActiveContentArea.length &&
        conflictingMaps.length
      ) {
        setHasOtherActiveMap(true)
        setError(oldErrs => ({
          ...oldErrs,
          activeDate: ' ',
          activeTime: ' ',
        }))
      }
    }

    if (hasError) {
      setErrorMessage(oldErrs => ({
        ...oldErrs,
        submit: updateContentMapError || errorPublishing,
      }))
    }
  }, [
    data,
    contentMap,
    errorPublishing,
    updateContentMapError,
    setErrorMessage,
    contentMapApprovers,
  ])

  const handleChange = ({ target: { name, value } }) => {
    // Re-initialize error
    setError(initError)

    if (name === 'deactiveTime') {
      setDeactiveTime(value)
    } else if (name === 'activeTime') {
      setActiveTime(value)
    } else if (name === 'readyForApproval') {
      const scheduledStart = formatTime(activeDate, activeTime)
      if (
        scheduledStart < new Date().toISOString() &&
        !value &&
        contentMap.statusId !== experimentStatusEnum.APPROVED
      ) {
        handleWarningDisplays('date', dateChangeWarning)
      } else {
        handleWarningDisplays('date', '')
      }

      if (!value && contentMap.hasActiveContentArea.length) {
        const conflictingMaps = contentMap.hasActiveContentArea.filter(
          ({ stopTime: activeStop }) =>
            !activeStop || activeStop > scheduledStart
        )

        if (conflictingMaps.length) {
          setHasOtherActiveMap(true)
          handleWarningDisplays('activeContent', activeContentWarning)
          setError(oldErrs => ({
            ...oldErrs,
            activeDate: ' ',
            activeTime: ' ',
          }))
        }
      } else {
        handleWarningDisplays('activeContent', '')
      }
      // Check if they are updating when in approved status to take out of scheduled state
      if (!value && status === experimentStatusEnum.APPROVED) {
        setStatus(experimentStatusEnum.DRAFT)
      }
      setIsReadyForApproval(prevApproval => !prevApproval)
    }
    onChange(true)
  }

  const setToScheduled = async () => {
    try {
      await updateContentMapFn(false)
      await publishContentMap({
        variables: {
          id: Number(id),
          statusId: experimentStatusEnum.APPROVED,
        },
      })
      const updateStatusContentMap = {
        ...contentMap,
        statusId: experimentStatusEnum.APPROVED,
      }
      await handleSubmit(updateStatusContentMap)
      setSuccess(true)
    } catch (err) {
      setErrorMessage(oldErr => ({
        ...oldErr,
        submit: err,
      }))
    }
  }

  const handlePreviewLink = refId => {
    if (itemsRef && itemsRef.current[refId]) {
      itemsRef.current[refId].submit()
    }
  }

  const setReferences = (elem, id) => {
    itemsRef.current[id] = elem
  }

  const getApprovers = users => {
    return users.reduce((acc, userEntry) => {
      acc.push({
        email: userEntry.user.email,
        name: userEntry.user.displayName,
      })
      return acc
    }, [])
  }

  const updateContentMapFn = async shouldPublish => {
    try {
      const newConfigSetUpSteps = config.setUpSteps.map(step => {
        if (step.label === 'Review') {
          return {
            ...step,
            status: 'complete',
          }
        }
        return step
      })

      const newConfig = {
        ...config,
        setUpSteps: newConfigSetUpSteps,
      }

      const input = {
        statusId: Number(status),
        id: Number(id),
        config: JSON.stringify(newConfig),
        startTime: formatTime(activeDate, activeTime),
        stopTime: formatTime(deactiveDate, deactiveTime),
        isApprovalReady: isReadyForApproval,
        contentMapName: contentMap.name,
        contentMapArea: contentMap.contentAreaId,
        contentMapApprovers: getApprovers(
          contentMapApprovers.contentMap.contentMapToUser
        ),
        userDisplayName: currentUser.displayName,
        productName: contentMap.product.displayName,
      }

      await updateContentMap({
        variables: {
          id: Number(id),
          input,
        },
        ignoreResults: false,
      })

      if (shouldPublish) {
        await publishContentMap({
          variables: {
            id: Number(id),
          },
        })
      }
      setSuccess(true)
    } catch (err) {
      setErrorMessage(oldErr => ({
        ...oldErr,
        submit: err,
      }))
      scrollToNavigationPosition()
    }
  }

  const onSubmit = async () => {
    await updateContentMapFn(true)
    scrollToNavigationPosition()
  }

  const validateSumbission = () => {
    const errorMessage = {}
    if (!activeDate) {
      errorMessage.activeDate = dateError
    }

    if (!activeTime) {
      errorMessage.activeTime = timeError
    }

    if (!isEmpty(errorMessage)) {
      setError(oldErrs => ({
        ...oldErrs,
        ...errorMessage,
      }))
      scrollToNavigationPosition()
      return false
    }

    return true
  }

  const isLoading = loadingPublish || loadingUpdateContentMap || loading

  if (loading) {
    return (
      <div className="app__loader">
        <KiteLoader size="7rem" />
      </div>
    )
  }

  let userProfileFields = {}
  if (data) {
    userProfileFields = formatUserProfileFields(data.userProfileSchema)
  }

  const publishBtnLabel = Number(status) === 9 ? 'Publish' : 'Save'
  const canApprove = contentMap.contentMapToUser.find(
    user => user.userId === currentUser.id
  )

  const canPublishToDre =
    formatTime(activeDate, activeTime) >= new Date().toISOString() &&
    isReadyForApproval

  let previewUrl
  if (contentMap.productId === 20 || contentMap.productId === 21) {
    previewUrl =
      contentMap.productId === 20
        ? REACT_APP_USER_RESI_PREVIEW_URL
        : REACT_APP_USER_SMB_PREVIEW_URL
  }

  const sortedContent = [...contentMap.personalizedContent].sort((a, b) => {
    if (a.priority > b.priority) {
      return 1
    } else if (a.priority < b.priority) {
      return -1
    }
    return 0
  })

  return (
    <div className="review">
      <div className="review__map-info-container">
        <div className="review__map-info-left">
          <h4>Content Map Info</h4>
          <KiteCard className="review__kite-card-info">
            <div>
              <div className="review__content-map-info-container">
                <div className="review__content-map-info-container-left">
                  <div>Product:</div>
                  <div className="review__content-map-info">
                    {contentMap.product.displayName}
                  </div>
                  <div>Content Area:</div>
                  <div className="review__content-map-info">
                    {contentMap.contentAreaId}
                  </div>
                </div>
                <div className="review__content-map-info-container-right">
                  <div>Business Use Case:</div>
                  <div className="review__content-map-info">
                    {contentMap.feature.displayName}
                  </div>
                  <div>Approver(s):</div>
                  <div className="review__content-map-info">
                    {!contentMapApprovers
                      ? ''
                      : contentMapApprovers.contentMap.contentMapToUser.map(
                          ({ user }) => (
                            <div key={`key-${user.id}`}>{user.displayName}</div>
                          )
                        )}
                  </div>
                </div>
              </div>
              <div>Content Map Name:</div>
              <div className="review__content-map-info">{contentMap.name}</div>
              <div>JIRA ticket link:</div>
              <div className="review__content-map-info">
                <KiteLink
                  className=""
                  href={contentMap.jiraTicket}
                  newTab
                  type=""
                >
                  {contentMap.jiraTicket}
                </KiteLink>
              </div>
              <div>Note/Description:</div>
              <div className="review__content-map-info">
                {contentMap.comment}
              </div>
            </div>
          </KiteCard>
        </div>
        <div className="review__map-info-right">
          <h4>Scheduling & Approval</h4>
          <KiteCard className="review__kite-card-info">
            <div className="create__date-time-container">
              <div className="create__date-time">
                <DatePicker
                  label={startDate}
                  minDate={new Date().toLocaleDateString()}
                  maxDate={null}
                  maxWidth="160px"
                  id="activeDate"
                  errorMessage={error.activeDate}
                  disabled={disabled || isReadyForApproval}
                  className={
                    disabled || isReadyForApproval
                      ? 'review__no-change-date'
                      : ''
                  }
                  value={activeDate}
                  onDateChange={event => {
                    if (!(disabled || isReadyForApproval)) {
                      setActiveDate(event)
                      setError(initError)
                      onChange(true)
                    }
                  }}
                  inputProps={{ disabled: disabled || isReadyForApproval }}
                />
                <KiteInput
                  disabled={disabled || isReadyForApproval}
                  id="activeTime"
                  label="Active Time"
                  margin=""
                  maxWidth="200px"
                  name="activeTime"
                  onChange={handleChange}
                  placeholder={timePlaceholder}
                  type="time"
                  value={activeTime}
                  errorMessage={error.activeTime}
                />
              </div>

              <div className="create__date-time">
                <DatePicker
                  label={stopDate}
                  minDate={activeDate || new Date().toLocaleDateString()}
                  maxDate={null}
                  id="deactiveDate"
                  maxWidth="160px"
                  value={deactiveDate}
                  errorMessage={error.deactiveDate}
                  inputProps={{ disabled: isCompleteMap }}
                  onDateChange={event => {
                    if (!isCompleteMap) {
                      setDeactiveDate(event)
                      setError(initError)
                      onChange(true)
                    }
                  }}
                  className={isCompleteMap ? 'review__no-change-date' : ''}
                />
                <KiteInput
                  id="deactiveTime"
                  label="Deactive Time"
                  margin=""
                  disabled={isCompleteMap}
                  maxWidth="200px"
                  name="deactiveTime"
                  onChange={handleChange}
                  placeholder={timePlaceholder}
                  type="time"
                  value={deactiveTime}
                  errorMessage={error.deactiveTime}
                />
              </div>
            </div>
            <div className="review__approval-btns-container">
              <KiteCheckbox
                disabled={isRunningOrComplete}
                id="readyForApproval"
                key="readyForApproval"
                label={approvalMessage}
                checked={isReadyForApproval}
                name="readyForApproval"
                onChange={handleChange}
              />
              <KiteButton
                size="small"
                className="review__approval-btn"
                type="outline"
                disabled={
                  isLoading ||
                  disabled ||
                  !canApprove ||
                  !canPublishToDre ||
                  hasOtherActiveMap
                }
                onClick={() => {
                  setToScheduled()
                }}
              >
                {approvalBtn}
              </KiteButton>
            </div>
          </KiteCard>
        </div>
      </div>
      <h4>Content Map</h4>
      <KiteCard>
        <div
          key="content-init"
          className="review__personalized-content-container-header"
        >
          <div className="review__personalized-content-header">
            Audience Summary
          </div>
          <div className="review__personalized-content-header">Content</div>
        </div>
        {sortedContent.map(
          (
            { id: personalizedContentId, content, audience, contentJson },
            index
          ) => {
            // NECESSARY CODE BELOW FOR MARKETING PROFILES TO DISPLAY PROPERLY FROM JSON LOGIC
            // 'some' needs to be in place of 'all' for jsonlogic to work properly on the
            // distillery rules engine side but 'all' needs to be in place of 'some' for
            // the rules to display properly on the query builder
            const updatedRules = audience.rules
              ? audience.rules.replaceAll('some', 'all')
              : null
            let rules = audience ? JSON.parse(updatedRules) : null
            let contentTitle = content
              ? content.contentTitle
              : JSON.parse(contentJson).contentTitle

            let contentId = content
              ? content.contentId
              : JSON.parse(contentJson).contentId
            const contentData = content ? content : JSON.parse(contentJson)
            let classNameExt = 'review__personalized-content-container'
            if (rules && index === 0) {
              classNameExt = 'review__personalized-content-container-first'
            } else if (!rules) {
              classNameExt = 'review__personalized-default-content-container'
            }
            return (
              <div
                key={`content-${personalizedContentId}`}
                className={classNameExt}
              >
                <div className="review__personalized-content-info">
                  {rules && Object.keys(userProfileFields).length > 0 ? (
                    <QuerySummary
                      id={personalizedContentId}
                      key={`content-summary-${contentId}`}
                      contentMapId={id}
                      content={contentData}
                      contentAreaId={contentMap.contentAreaId}
                      rules={rules}
                      fields={userProfileFields}
                      itemsRef={itemsRef}
                      previewURL={previewUrl}
                      index={index}
                      setReferences={setReferences}
                    />
                  ) : (
                    <div id={id} key={`key-${id}`}>
                      {previewUrl ? (
                        <form
                          id={id}
                          key={`key-${id}`}
                          ref={el => {
                            if (id) {
                              setReferences(el, index)
                            }
                          }}
                          action={`${previewUrl}/api/pub/cms/v1/user-profile/override`}
                          target="_blank"
                          method="POST"
                          name="search"
                        >
                          <input
                            type="hidden"
                            name="userProfile"
                            value={JSON.stringify({
                              txnId: `forceContentMap:${contentMap.contentAreaId}:${contentId}`,
                            })}
                          />
                          <input type="hidden" name="redirectPath" value="/" />
                        </form>
                      ) : (
                        ''
                      )}
                      Default
                    </div>
                  )}
                </div>
                <div>
                  <div>{contentTitle}</div>
                  {previewUrl ? (
                    <KiteButton
                      onClick={() => handlePreviewLink(index)}
                      type="standalone-link"
                      rightIcon="linkout"
                    >
                      {experimentPreviewLabel}
                    </KiteButton>
                  ) : (
                    ''
                  )}
                  {contentMap.productId === 19 ? (
                    <QuerySummary
                      id={personalizedContentId}
                      key={`content-summary-${contentId}`}
                      contentMapId={id}
                      content={contentData}
                      contentAreaId={contentMap.contentAreaId}
                      rules={rules}
                      fields={userProfileFields}
                      itemsRef={itemsRef}
                      index={index}
                      setReferences={setReferences}
                      productId={contentMap.productId}
                    />
                  ) : (
                    ''
                  )}
                </div>
                <KiteLink
                  newTab
                  href={`/experiments/new-cms#product=${
                    contentMap ? contentMap.productId : ''
                  }&contentAreaId=${
                    contentMap ? contentMap.contentAreaId : ''
                  }&contentId=${contentId}`}
                  type="standalone"
                  className="review__personalized-experiment-btn"
                >
                  {experimentContentLabel}
                </KiteLink>
              </div>
            )
          }
        )}
      </KiteCard>

      <div className="review__btn-container">
        <div className="create__btn-save-cancel">
          <KiteButton
            size="large"
            loading={loading}
            disabled={isLoading || !isDirty || !hasPermission}
            onClick={() => {
              if (validateSumbission()) {
                onSubmit()
              }
            }}
          >
            {publishBtnLabel}
          </KiteButton>

          <KiteButton
            className="review__exit-btn"
            size="small"
            type="standalone-link"
            disabled={isLoading || !hasPermission}
            onClick={() => {
              isDirty ? setModalOpen(true) : history.push('/content-maps/draft')
            }}
          >
            Discard Changes
          </KiteButton>
        </div>

        <KiteButton
          size="large"
          leftIcon="x-circle"
          type="outline"
          className="create__cancel-content-btn"
          disabled={
            isLoading ||
            !hasPermission ||
            !contentMap ||
            (contentMap &&
              (contentMap.stopTime < new Date().toISOString() ||
                !contentMap.startTime))
          }
          onClick={() => {
            setIsCancelContentModalOpen(true)
          }}
        >
          Cancel Content Map
        </KiteButton>
      </div>
    </div>
  )
}

export default Review
