import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

/*
* Sample Input
*  {
    "VALUES":[
      {
        "experimentId": 1,
        "metricTypeId": 1,
        "metricUuid": "testing"
      },
      {
        "experimentId": 1,
        "metricTypeId": 2,
        "metricUuid": "testing 2"
      },
      {
        "experimentId": 1,
        "metricTypeId": 2,
        "metricUuid": "testing 3"
      },
      {
        "experimentId": 1,
        "metricTypeId": 2,
        "metricUuid": "testing 4"
      }
    ]
  }
*/
const DELETE_AND_INSERT_EXPERIMENT_METRICS = gql`
  mutation deleteAndInsertExperimentMetrics(
    $VALUES: [ExperimentMetricsAssignment!]
  ) {
    deleteAndInsertExperimentMetrics(input: $VALUES) {
      id
      createdUser {
        displayName
      }
      metricType {
        name
      }
      metricUuid
      experimentId
    }
  }
`

const deleteAndInsertExperimentMetrics = graphql(
  DELETE_AND_INSERT_EXPERIMENT_METRICS,
  {
    name: 'deleteAndInsertExperimentMetrics',
    withRef: true,
  }
)

export default deleteAndInsertExperimentMetrics
