import { gql } from '@apollo/client'

export const GET_EXPERIMENTS_DETAILS = gql`
  query getExperimentDetails($id: Int!) {
    experiment(id: $id) {
      id
      uuid
      startTime
      experimentToProductFeatures {
        product {
          id
          uuid
          quantumApplicationName
          quantumApplicationType
        }
      }
      environmentSamplings {
        id
        sampleAllocation
        environmentSamplingToVariants {
          id
          weight
          variant {
            id
            name
          }
        }
        allocationHistory {
          id
          sampleAllocation
          variantConfig
          createdTime
          isReset
          comment
          uuid
          lastModifiedUser {
            id
            displayName
          }
        }
      }
      variants {
        id
        uuid
        name
      }
    }
    retrieveQuantumToken(quantumEnum: { overrideTypeId: QUANTUM_METRIC })
  }
`

export default GET_EXPERIMENTS_DETAILS
