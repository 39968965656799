import 'react-awesome-query-builder/lib/css/styles.css'
import 'react-awesome-query-builder/lib/css/compact_styles.css'
import './QueryBuilder.scss'

import React, { useReducer, useEffect, useRef, useState } from 'react'
import { Utils as QbUtils } from 'react-awesome-query-builder'
import loadedConfig from './config'

import { KiteButton, KiteTooltip, KiteIcon } from '@kite/react-kite'

import { createUserProfile, copyTextToClipboard } from '../../shared/utilities'

const { loadFromJsonLogic, loadTree, checkTree, uuid } = QbUtils

const { REACT_APP_USER_MSA_PREVIEW_URL } = process.env

export default function QuerySummary({
  id,
  key,
  contentMapId,
  content,
  contentAreaId,
  fields,
  rules,
  previewURL,
  setReferences,
  index,
  productId,
}) {
  const reducer = (state, action) => {
    if (action.type === 'setFieldsInit') {
      return {
        ...state,
        ...action.state,
        config: {
          ...state.config,
          ...action.state.config,
        },
      }
    }
  }

  const initQueryState = {
    config: {
      ...loadedConfig,
      fields,
    },
    tree:
      rules && rules.logic && Object.keys(rules.logic).length > 0
        ? checkTree(
            loadFromJsonLogic(rules.logic, { ...loadedConfig, fields }),
            { ...loadedConfig, fields }
          )
        : checkTree(loadTree({ id: uuid(), type: 'group' }), {
            ...loadedConfig,
            fields,
          }),
  }

  const [queryState, dispatch] = useReducer(reducer, initQueryState)
  const { config, tree } = queryState

  const [wasSuccessful, handleToggleCopySuccessful] = useState(false)
  const msaPreviewTextRef = useRef(null)

  useEffect(() => {
    if (fields && Object.keys(fields).length) {
      dispatch({
        type: 'setFieldsInit',
        state: {
          config: { fields },
          tree:
            rules && rules.logic && Object.keys(rules.logic).length > 0
              ? checkTree(
                  loadFromJsonLogic(rules.logic, { ...loadedConfig, fields }),
                  { ...loadedConfig, fields }
                )
              : checkTree(loadTree({ id: uuid(), type: 'group' }), {
                  ...loadedConfig,
                  fields,
                }),
        },
      })
    }
  }, [fields, rules])

  const jsonTree = QbUtils.mongodbFormat(tree, config)
  const userProfile = createUserProfile(fields, jsonTree, {
    contentMapId,
    content,
    contentAreaId,
  })

  return (
    <div id={key} key={key}>
      {previewURL ? (
        <form
          id={id}
          key={`key-${id}`}
          ref={el => setReferences(el, index)}
          action={`${previewURL}/api/pub/cms/v1/user-profile/override`}
          target="_blank"
          method="POST"
          name="search"
        >
          <input
            type="hidden"
            name="userProfile"
            value={JSON.stringify(userProfile)}
          />
          <input type="hidden" name="redirectPath" value="/" />
        </form>
      ) : (
        ''
      )}
      {productId && productId === 19 ? (
        <form className="review__personalized-content-preview-link-form">
          <input
            type="hidden"
            className="review__personalized-content-preview-link"
            ref={msaPreviewTextRef}
            value={`${REACT_APP_USER_MSA_PREVIEW_URL}/navigation/Home?userProfile=${JSON.stringify(
              userProfile
            )}`}
          />
          <KiteButton
            size="small"
            onClick={e => {
              e.preventDefault()
              copyTextToClipboard(msaPreviewTextRef.current.value)
              handleToggleCopySuccessful(true)
              setTimeout(() => handleToggleCopySuccessful(false), 3000)
            }}
            type="standalone-link"
          >
            Copy Mobile Preview Link
          </KiteButton>
          <KiteIcon
            name="checkmark"
            size="1.5rem"
            color={wasSuccessful ? '#02BF20' : 'transparent'}
          />
          <KiteTooltip isLight={false}>
            After copying the link address, send it to your mobile device to
            preview
          </KiteTooltip>
        </form>
      ) : (
        ''
      )}
      {productId ? '' : QbUtils.sqlFormat(tree, config, true)}
    </div>
  )
}
