/* eslint react/prop-types: 0 */
import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { observer } from 'mobx-react'
import { useLocation } from 'react-router-dom'

import appModel from '../App/AppModel'
import { HeaderMenu, SideNavigation } from '../../componentLibrary'
import { KiteAlert, KiteButton, KiteIcon, KiteModal } from '@kite/react-kite'
import './NavigationContainer.scss'

import SupportRequestForm from '../SupportRequestForm/SupportRequestForm'
import { GET_SCHEDULED_LOCKDOWNS } from '../../shared/queries/index'
import {
  getCurrentDate,
  getCurrentTime,
  formatDate,
  formatTime,
} from '../../shared/utilities/index'

const shouldShowBackButton = location => {
  const { pathname } = location
  const pathArray = pathname.split('/')
  pathArray.splice(0, 1)
  if (
    pathArray.includes('experiments') ||
    pathArray.includes('audience-management') ||
    pathArray.includes('content-maps')
  ) {
    return pathArray.length > 2 || pathArray.includes('new')
    // eslint-disable-next-line no-else-return
  } else if (pathArray.includes('experiment-evaluation')) {
    return pathArray.length > 2
  } else if (pathArray.includes('learn')) {
    return false
  }

  return true
}

const initialErrorMessage = {
  getScheduledLockdown: null,
}

let NavigationContainer = function NavigationContainer({
  children,
  dxNavigate,
  fillSpace = false,
}) {
  const location = useLocation()

  const {
    currentUser: user,
    currentlyViewing,
    shouldShowExperimentEval,
    isContentMapsActive,
    isCalculatorActive,
    isSideNavCollapsed,
    isLearnWikiActive,
  } = appModel

  const showBackButton = shouldShowBackButton(location)

  const sideNavigationMenuOptions = {
    experiments: {
      icon: 'assignment',
      titleType: 'subOption option',
      hasAccess: true,
      subOptions: [
        {
          name: 'draft',
          label: 'Draft',
          hasAccess: true,
        },
        {
          name: 'testing',
          label: 'Testing',
          hasAccess: true,
        },
        {
          name: 'running',
          label: 'Running',
          hasAccess: true,
        },
        {
          name: 'cancelled',
          label: 'Cancelled',
          hasAccess: true,
        },
        {
          name: 'observational',
          label: 'Observation',
          hasAccess: true,
        },
        {
          name: 'completed',
          label: 'Completed',
          hasAccess: true,
        },
        {
          name: 'all',
          label: 'All',
          hasAccess: true,
        },
      ],
    },
    'content-maps': {
      kiteIcon: 'account-f',
      titleType: 'subOption',
      hasAccess: isContentMapsActive,
      onNavigate: {
        path: '/content-maps/draft',
        title: 'Content Maps',
      },
      subOptions: [],
    },
    'experiment-evaluation': {
      kiteIcon: 'star-half',
      titleType: 'subOption',
      hasAccess: shouldShowExperimentEval,
      subOptions: [
        {
          name: 'to-evaluate',
          label: 'To Evaluate',
          path: '/experiment-evaluation/to-evaluate',
          hasAccess: shouldShowExperimentEval,
        },
        {
          name: 'in-progress',
          label: 'In Progress',
          path: '/experiment-evaluation/in-progress',
          hasAccess: shouldShowExperimentEval,
        },
        {
          name: 'completed',
          label: 'Completed',
          path: '/experiment-evaluation/completed',
          hasAccess: shouldShowExperimentEval,
        },
        {
          name: 'not-evaluating',
          label: 'Not Evaluating',
          path: '/experiment-evaluation/not-evaluating',
          hasAccess: shouldShowExperimentEval,
        },
      ],
    },
    learn: {
      icon: 'live_help',
      titleType: 'subOption',
      hasAccess: isLearnWikiActive,
      onNavigate: {
        path: '/learn/home',
        title: 'Learn Wiki',
      },
      subOptions: [],
    },
  }

  const headerMenuOptions = {
    'Suggested Sample Size Calculator': {
      icon: 'calculator',
      path: '/sample-calculator',
      isActive: currentlyViewing.path === '/sample-calculator',
      hasAccess: isCalculatorActive,
    },
    settings: {
      subTitle: user.displayName,
      icon: 'settings-f',
      hasAccess: true,
      subOptions: [
        {
          icon: 'bell',
          iconType: 'kite',
          label: 'Notification Preferences',
          path: '/notifications/preferences',
          hasAccess: true,
        },
        {
          icon: 'racks-f',
          iconType: 'kite-font',
          label: 'Override Management',
          path: '/override-management/id',
          hasAccess: appModel.hasOverrideCreatePermission,
        },
        {
          icon: 'profile-card-f',
          iconType: 'kite-font',
          label: 'Audience Management',
          hasAccess:
            isContentMapsActive && appModel.hasAudienceCreatePermission,
          path: '/audience-management',
        },
        {
          icon: 'mail',
          iconType: 'kite',
          label: 'Feature Request',
          href: `mailto:DL-Distillery-Feature-Request@charter.com?subject=Distillery Feature Request - ${user.displayName}`,
          hasAccess: true,
        },
        {
          icon: 'info-circle-f',
          iconType: 'kite',
          label: 'Support',
          href: `mailto:DL-Distillery-Support@charter.com?subject=Distillery Support - ${user.displayName}`,
          hasAccess: true,
        },
        {
          icon: 'users-f',
          iconType: 'kite-font',
          label: 'Administration',
          path: '/administration',
          hasAccess: appModel.hasUserAdminPermission,
        },
        {
          icon: 'ivr-chat',
          iconType: 'kite',
          label: 'Subscriptions',
          path: '/subscriptions',
          hasAccess: appModel.hasSubscriptionAdminPermission,
        },
        {
          icon: 'lock-f',
          iconType: 'kite',
          label: 'Network Lockdown Administration',
          path: '/network-lockdown-administration',
          hasAccess: appModel.hasNetworkLockdownAdminPermission,
        },
      ],
    },
    notifications: {
      icon: 'bell',
      path: '/notifications',
      indicator: appModel.hasNotifications,
      hasAccess: true,
      isActive: currentlyViewing.path === '/notifications',
    },
  }

  let childrenClass = 'navigation-content__children-wrapper'
  if (fillSpace) {
    childrenClass += ' navigation-content__children-wrapper-fill'
  }

  const { error, data } = useQuery(GET_SCHEDULED_LOCKDOWNS, {
    pollInterval: process.env.REACT_APP_LOCKDOWN_POLLING_TIME,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })

  const [showLockdownAlert, setShowLockdownAlert] = useState(true)
  const [supportRequestModalOpen, setSupportRequestModalOpen] = useState(false)
  const [preLockdownMessage, setPreLockdownMessage] = useState('')
  const [duringLockdownMessage, setDuringLockdownMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState(initialErrorMessage)

  function handleSupportRequestModal() {
    setSupportRequestModalOpen(!supportRequestModalOpen)
  }

  useEffect(() => {
    if (
      data &&
      data.scheduledNetworkLockdowns &&
      data.scheduledNetworkLockdowns.length
    ) {
      const upcomingLockdown = data.scheduledNetworkLockdowns[0]

      const currentDate = new Date(getCurrentDate() + ' ' + getCurrentTime())
      const upcomingLockdownStartDate = new Date(
        formatDate(upcomingLockdown.startDate) +
          ' ' +
          formatTime(upcomingLockdown.startDate)
      )
      const sevenDaysBeforeStartDate = new Date(upcomingLockdownStartDate)
      sevenDaysBeforeStartDate.setDate(sevenDaysBeforeStartDate.getDate() - 7)

      if (
        upcomingLockdownStartDate > currentDate &&
        currentDate > sevenDaysBeforeStartDate
      ) {
        setPreLockdownMessage(upcomingLockdown.preMessage)
        setDuringLockdownMessage('')
      } else if (upcomingLockdownStartDate <= currentDate) {
        setDuringLockdownMessage(upcomingLockdown.duringMessage)
        setPreLockdownMessage('')
      }
    } else {
      setPreLockdownMessage('')
      setDuringLockdownMessage('')
    }

    if (error) {
      setErrorMessage(oldErrs => ({
        ...oldErrs,
        getScheduledLockdown: error,
      }))
    }
    else {
      setErrorMessage(oldErrs => ({
        ...oldErrs,
        getScheduledLockdown: null,
      }))
    }
  }, [data, error])

  return (
    <div className="navigation-container">
      <SideNavigation
        currentlyViewing={currentlyViewing}
        logoTitle="Distillery"
        logoAssetPath="/assets/images/favicon/apple-touch-icon.png"
        onGoBack={dxNavigate}
        showBackButton={showBackButton}
        menuOptions={sideNavigationMenuOptions}
        onNavigate={dxNavigate}
        onCollapse={isCollapsed => {
          appModel.saveUserConfig(
            isCollapsed !== undefined ? isCollapsed : true
          )
        }}
        isCollapsed={isSideNavCollapsed}
        defaultSelected={{
          option: 'experiments',
          subOption: 'all',
        }}
      />
      <div className="navigation-content__wrapper">
        <HeaderMenu
          currentlyViewing={currentlyViewing}
          menuOptions={headerMenuOptions}
          onNavigate={dxNavigate}
          defaultTitle="Dashboard"
        />
        {showLockdownAlert && preLockdownMessage && (
          <KiteAlert
            className="navigation-content__alert"
            type="info"
            level="global"
            description={preLockdownMessage}
            onClose={() => {
              setShowLockdownAlert(false)
            }}
          />
        )}
        {showLockdownAlert && duringLockdownMessage && (
          <KiteAlert
            className="navigation-content__alert"
            type="caution"
            level="global"
            description={duringLockdownMessage}
            onClose={() => {
              setShowLockdownAlert(false)
            }}
            linkText="Contact Support"
            onLinkClick={() =>
              (window.location.href = `mailto:DL-Distillery-Support@charter.com?subject=Distillery Support`)
            }
          />
        )}
        {appModel.hasNetworkLockdownAdminPermission &&
          errorMessage &&
          errorMessage.getScheduledLockdown && (
            <KiteAlert
              className="navigation-content__alert"
              type="alert"
              level="global"
              description="Unable to connect to Distillery"
              onClose={() => {
                setShowLockdownAlert(false)
              }}
            />
          )}
        <div className={childrenClass}>{children}</div>
      </div>
      <div className="distillery-support-container">
            <KiteButton size={'md'} className='distillery-support-button' onClick={() => handleSupportRequestModal()}> <KiteIcon className="compose-icon" name="compose" size="12px" />Support</KiteButton>
              <KiteModal
                allowClickAway={true}
                useSmall={true}
                canShow={supportRequestModalOpen}
                onHide={() => handleSupportRequestModal()}
                primaryBtnLabel={''}
                title={'Distillery Support'}     
              >{supportRequestModalOpen && (<SupportRequestForm></SupportRequestForm>)}</KiteModal>
        </div>
    </div>
  )
}
NavigationContainer = observer(NavigationContainer)
export default NavigationContainer
