import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import { object, func, string } from 'prop-types'
import { KiteTabs, KiteIcon, KiteFontIcon } from '@kite/react-kite'
import './HeaderMenu.scss'

import calculatorIcon from './data/calculatorIcon.png'

class HeaderMenu extends Component {
  state = {
    selectedOption: null,
  }

  static propTypes = {
    currentlyViewing: object.isRequired,
    menuOptions: object,
    onNavigate: func,
    defaultTitle: string,
  }

  static defaultProps = {
    menuOptions: null,
    onNavigate: null,
    defaultTitle: null,
  }

  // LOCAL STATE CHANGE/TOGGLE METHODS
  handleSelectOption = (name, optionObject) => {
    const { selectedOption } = this.state
    const { onNavigate } = this.props

    const { subOptions, path } = optionObject

    if (subOptions) {
      if (!selectedOption) {
        document.addEventListener(
          'click',
          this.handleHeaderMenuOutsideClick,
          false
        )
      }

      this.setState({
        selectedOption: name,
      })
    } else if (path) {
      onNavigate({
        title: name,
        path,
      })
    }
  }

  handleHeaderMenuOutsideClick = event => {
    if (this.subOptionsMenu && !this.subOptionsMenu.contains(event.target)) {
      document.removeEventListener(
        'click',
        this.handleHeaderMenuOutsideClick,
        false
      )
      this.setState({ selectedOption: null })
    }
  }

  handleSelectSubOption = (title, path) => {
    const { onNavigate } = this.props

    if (title && path && onNavigate) {
      onNavigate({
        path,
        title,
      })
    }

    if (this.subOptionsMenu) {
      document.removeEventListener(
        'click',
        this.handleHeaderMenuOutsideClick,
        false
      )
    }

    this.setState({ selectedOption: null })
  }

  handleSelectTab = currentTab => {
    const { onNavigate, currentlyViewing } = this.props
    const { path, disabled } = currentlyViewing.tabs.tabs.find(
      tab => tab.label === currentTab
    )

    if (!disabled && onNavigate) {
      onNavigate({
        ...currentlyViewing,
        path,
        tabs: {
          ...currentlyViewing.tabs,
          currentTab,
        },
      })
    }
  }

  // RENDER METHODS
  renderCurrentlyViewingHeader = ({ title, subTitle }) => {
    const { defaultTitle } = this.props
    const titleClassNames = classNames({
      'header-menu__location-title': true,
      'header-menu__location-title-with-subtitle': subTitle,
    })

    return (
      <Fragment>
        <h2 className={titleClassNames}>{title || defaultTitle}</h2>
        {subTitle && (
          <h4 className="header-menu__location-sub-title">{subTitle}</h4>
        )}
      </Fragment>
    )
  }

  renderMenuOptions = () => {
    const { selectedOption } = this.state
    const { menuOptions } = this.props

    return (
      <div className="header-menu__menu-icon-container">
        {Object.keys(menuOptions).map((option, index) => {
          const key = `${option}__${index}`
          const {
            icon,
            subOptions,
            subTitle,
            indicator,
            isActive,
            hasAccess,
          } = menuOptions[option]
          const menuOptionIconClassNames = classNames({
            'material-icons': true,
            'header-menu__menu-icon': true,
            [`header-menu__menu-icon-${option}`]: true,
            'header-menu__menu-icon-selected':
              selectedOption === option || isActive,
          })
          if (hasAccess) {
            return (
              <Fragment key={key}>
                <div className="header-menu__icon-container">
                  {icon === 'calculator' ? (
                    <img
                      src={calculatorIcon}
                      alt="calculatorIcon"
                      className={
                        isActive
                          ? 'header-menu__calculator-icon-active'
                          : 'header-menu__calculator-icon-inactive'
                      }
                      onClick={() =>
                        !selectedOption &&
                        this.handleSelectOption(option, menuOptions[option])
                      }
                    />
                  ) : (
                    <KiteIcon
                      className={menuOptionIconClassNames}
                      name={icon}
                      size="1.5rem"
                      role="menuitem"
                      tabIndex="0"
                      onClick={() =>
                        !selectedOption &&
                        this.handleSelectOption(option, menuOptions[option])
                      }
                      onKeyDown={() =>
                        !selectedOption &&
                        this.handleSelectOption(option, menuOptions[option])
                      }
                    />
                  )}
                  {indicator && (
                    <div
                      className={`header-menu__icon-indicator ${
                        indicator.value
                          ? 'header-menu__icon-indicator-value'
                          : ''
                      }`}
                      role="menuitem"
                      tabIndex={index}
                      onClick={() =>
                        !selectedOption &&
                        this.handleSelectOption(option, menuOptions[option])
                      }
                      onKeyDown={() =>
                        !selectedOption &&
                        this.handleSelectOption(option, menuOptions[option])
                      }
                    >
                      {indicator.value}
                    </div>
                  )}
                </div>
                {subOptions && selectedOption === option && (
                  <div
                    className="header-menu__sub-options"
                    ref={element => {
                      this.subOptionsMenu = element
                    }}
                  >
                    <div className="header-menu__sub-options-header">
                      <span className="header-menu__sub-options-title">
                        {option}
                      </span>
                      {subTitle && (
                        <span className="header-menu__sub-options-sub-title">
                          {subTitle}
                        </span>
                      )}
                    </div>
                    {this.renderSubOptions(subOptions)}
                  </div>
                )}
              </Fragment>
            )
            // eslint-disable-next-line no-else-return
          } else {
            return <div key="rando" />
          }
        })}
      </div>
    )
  }

  renderSubOptions = subOptions => {
    const { currentlyViewing } = this.props

    return subOptions
      .filter(subOption => subOption.hasAccess)
      .map(({ label, icon, iconType, path, href }, index) => {
        const key = `${label}__${index}`
        const isSelected = path === currentlyViewing.path
        const subOptionClassNames = classNames({
          'header-menu__sub-option-link': true,
          [`header-menu__sub-option-link-${label
            .toLowerCase()
            .split(' ')
            .join('-')}`]: true,
          'header-menu__sub-option-link-selected': isSelected,
        })

        let subOptionIcon = false

        if (icon) {
          subOptionIcon =
            iconType === 'kite' ? (
              <KiteIcon
                className="material-icons header-menu__sub-option-icon"
                name={icon}
                size="1.5rem"
              />
            ) : (
              <KiteFontIcon
                className="material-icons header-menu__sub-option-icon"
                name={icon}
                size="1.5rem"
              />
            )
        }

        if (href) {
          return (
            <a
              key={key}
              className={subOptionClassNames}
              href={href}
              onClick={this.handleSelectSubOption}
              onKeyDown={this.handleSelectSubOption}
            >
              {subOptionIcon}
              {label}
            </a>
          )
        }

        return (
          <div
            key={key}
            role="link"
            tabIndex={index}
            className={subOptionClassNames}
            onClick={() =>
              !isSelected && this.handleSelectSubOption(label, path)
            }
            onKeyDown={() =>
              !isSelected && this.handleSelectSubOption(label, path)
            }
          >
            {subOptionIcon}
            {label}
          </div>
        )
      })
  }

  render() {
    const {
      currentlyViewing,
      menuOptions,
      logoAssetPath,
      logoTitle,
    } = this.props

    return (
      <div className="header-menu">
        <div className="header-menu__left">
          {!logoTitle && !logoAssetPath && (
            <div className="header-menu__location-container">
              {currentlyViewing &&
                this.renderCurrentlyViewingHeader(currentlyViewing)}
            </div>
          )}
          {logoTitle && logoAssetPath && (
            <div className="header-menu__logo-container">
              <img
                className="side-navigation__logo-image"
                alt="distillery-logo"
                src={`${process.env.PUBLIC_URL}${logoAssetPath}`}
              />
              <span className="side-navigation__logo-text">{logoTitle}</span>
            </div>
          )}
          {currentlyViewing && currentlyViewing.tabs && (
            <KiteTabs
              currentTab={currentlyViewing.tabs.currentTab}
              tabs={currentlyViewing.tabs.tabs.map(tab => tab.label)}
              onSelectTab={this.handleSelectTab}
            />
          )}
        </div>
        <div className="header-menu__right">
          {menuOptions && this.renderMenuOptions()}
        </div>
      </div>
    )
  }
}

export default HeaderMenu
