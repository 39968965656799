/* eslint react/prop-types: 0 */
import { KiteAlert } from '@kite/react-kite'
import React, { useState, useEffect, useCallback, Component } from 'react'
import { observer } from 'mobx-react'

import { ApolloProvider } from '@apollo/client'

import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useHistory,
} from 'react-router-dom'

import {
  permissionEnum,
  featureFlagEnum,
  permissionEnumReverse,
} from '@charter/distillery-rules'

import appModel from './AppModel'
import PrivateRoute from '../PrivateRoute/PrivateRoute'
import { HeaderMenu, DialogContainer, ErrorPage } from '../../componentLibrary'
import NavigationContainer from '../NavigationContainer/NavigationContainer'
import { client } from '../../configuration/configApiClient'

import {
  AdministrationPage,
  ExperimentListPage,
  NotificationsPage,
  NotificationPreferencesPage,
  OverrideManagementPage,
  OverrideManagementOldPage,
  RolloutExperimentPage,
  SetUpExperimentPage,
  SubscriptionsPage,
  ExperimentResultsPage,
  ExperimentCalculatorPage,
  ExperimentEvaluationListPage,
  EvaluationCardPage,
  AudienceManagementPage,
  AudiencePage,
  ContentMapList,
  ContentMap,
  NetworkLockdownAdmin,
} from '../../pages'

import './App.scss'
import copyContent from './data/copyContent'

import { Modal } from '../../componentLibrary'

const sessionStore = window.sessionStorage

let LearnWiki = function LearnWiki({ location, dxNavigate }) {
  const learnUrl =
    process.env.REACT_APP_LEARN_WIKI_URL ||
    'https://wiki.distillery.spectrumtoolbox.com'

  const { pathname: originalPath } = location
  const wikiPath = originalPath.substring(6)
  const iframeSrc = `${learnUrl}${wikiPath}`

  useEffect(() => {
    dxNavigate({ path: originalPath, title: 'Learn' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <iframe
      title="Distillery Learn"
      style={{
        height: '80rem',
        width: '100%',
        border: 'none',
      }}
      src={iframeSrc}
    />
  )
}

LearnWiki = observer(LearnWiki)

let Routes = function Routes({ dxNavigate }) {
  const {
    currentlyViewing,
    applicationPermissions,
    currentUser,
    isCalculatorActive,
    isLearnWikiActive,
    hasOverrideCreatePermission,
    hasAudienceCreatePermission,
    hasSubscriptionAdminPermission,
    hasUserAdminPermission,
    hasNetworkLockdownAdminPermission,
    shouldShowExperimentEval,
    hasInAppPreferences,
    userRoles,
  } = appModel

  const [sortState, setSortState] = useState({
    sortHeader: 'name',
    reverseSort: false,
  })

  const sortCallback = useCallback(
    sortHeader => {
      setSortState({
        sortHeader,
        reverseSort:
          sortHeader === sortState.sortHeader ? !sortState.reverseSort : false,
      })
    },
    [sortState, setSortState]
  )

  const { sortHeader, reverseSort } = sortState

  return (
    <Switch>
      <PrivateRoute
        path="/network-lockdown-administration"
        redirectPath="/experiments/all"
        condition={hasNetworkLockdownAdminPermission}
        component={NetworkLockdownAdmin}
        user={currentUser}
        onNavigate={dxNavigate}
        permissionEnum={permissionEnumReverse[permissionEnum.EXPERIMENT_READ]}
      />
      <PrivateRoute
        exact
        path="/subscriptions"
        redirectPath="/experiments/all"
        condition={hasSubscriptionAdminPermission}
        component={SubscriptionsPage}
        user={currentUser}
        onNavigate={dxNavigate}
      />
      <PrivateRoute
        path="/administration"
        redirectPath="/experiments/all"
        condition={hasUserAdminPermission}
        applicationPermissions={applicationPermissions}
        component={AdministrationPage}
        onNavigate={dxNavigate}
      />
      <PrivateRoute
        path="/override-management/:overrideType"
        redirectPath="/experiments/all"
        condition={hasOverrideCreatePermission}
        component={
          appModel.hasFeatureFlag(featureFlagEnum.ORM_2)
            ? OverrideManagementPage
            : OverrideManagementOldPage
        }
        user={currentUser}
        onNavigate={dxNavigate}
        permissionEnum={permissionEnumReverse[permissionEnum.EXPERIMENT_READ]}
      />
      <PrivateRoute
        path="/audience-management/:id"
        redirectPath="/experiments/all"
        condition={hasAudienceCreatePermission}
        component={routerProps => (
          <AudiencePage
            {...routerProps}
            user={currentUser}
            applicationPermissions={applicationPermissions}
            statusFilter={routerProps.match.params.status}
            currentlyViewing={currentlyViewing}
            onNavigate={dxNavigate}
            onSortClick={sortCallback}
            sortHeader={sortHeader}
            reverseSort={reverseSort}
            permissionEnum={
              permissionEnumReverse[permissionEnum.EXPERIMENT_READ]
            }
          />
        )}
        user={currentUser}
        onNavigate={dxNavigate}
        permissionEnum={permissionEnumReverse[permissionEnum.EXPERIMENT_READ]}
      />
      <PrivateRoute
        path="/audience-management"
        redirectPath="/experiments/all"
        condition={hasAudienceCreatePermission}
        component={routerProps => (
          <AudienceManagementPage
            {...routerProps}
            user={currentUser}
            applicationPermissions={applicationPermissions}
            statusFilter={routerProps.match.params.status}
            currentlyViewing={currentlyViewing}
            onNavigate={dxNavigate}
            onSortClick={sortCallback}
            sortHeader={sortHeader}
            reverseSort={reverseSort}
            permissionEnum={
              permissionEnumReverse[permissionEnum.EXPERIMENT_READ]
            }
          />
        )}
        user={currentUser}
        onNavigate={dxNavigate}
        permissionEnum={permissionEnumReverse[permissionEnum.EXPERIMENT_READ]}
      />
      <Route
        exact
        redirectPath="/experiments/all"
        path="/experiments/:id/rollout/:step"
        render={routerProps => (
          <RolloutExperimentPage
            {...routerProps}
            onNavigate={dxNavigate}
            hasInAppPreferences={hasInAppPreferences}
            applicationPermissions={applicationPermissions}
            user={currentUser}
            networkLockdownPermission={hasNetworkLockdownAdminPermission}
          />
        )}
      />
      <Route
        exact
        path={[
          '/experiments/:id/set-up/:step',
          '/experiments/new',
          '/experiments/new-cms',
        ]}
        render={routerProps => (
          <SetUpExperimentPage
            {...routerProps}
            currentlyViewing={currentlyViewing}
            onNavigate={dxNavigate}
            applicationPermissions={applicationPermissions}
            user={currentUser}
            networkLockdownPermission={hasNetworkLockdownAdminPermission}
          />
        )}
      />
      <Route
        exact
        path="/experiments/:status"
        render={routerProps => (
          <ExperimentListPage
            {...routerProps}
            user={currentUser}
            applicationPermissions={applicationPermissions}
            statusFilter={routerProps.match.params.status}
            currentlyViewing={currentlyViewing}
            onNavigate={dxNavigate}
            onSortClick={sortCallback}
            sortHeader={sortHeader}
            reverseSort={reverseSort}
            permissionEnum={
              permissionEnumReverse[permissionEnum.EXPERIMENT_READ]
            }
            userRoles={userRoles}
            networkLockdownPermission={hasNetworkLockdownAdminPermission}
          />
        )}
      />
      <Route
        exact
        path="/experiments/:id/results"
        onNavigate={dxNavigate}
        currentlyViewing={currentlyViewing}
        render={routerProps => {
          const {
            match: {
              params: { id },
            },
          } = routerProps

          return (
            <ExperimentResultsPage
              {...routerProps}
              id={Number(id)}
              applicationPermissions={applicationPermissions}
              user={currentUser}
              onNavigate={dxNavigate}
            />
          )
        }}
      />
      <Route
        exact
        path="/notifications"
        render={routerProps => (
          <NotificationsPage
            {...routerProps}
            user={currentUser}
            applicationPermissions={applicationPermissions}
            hasInAppPreferences={hasInAppPreferences}
            currentlyViewing={currentlyViewing}
            onNavigate={dxNavigate}
          />
        )}
      />
      <Route
        exact
        path="/notifications/preferences"
        render={routerProps => (
          <NotificationPreferencesPage
            {...routerProps}
            user={currentUser}
            applicationPermissions={applicationPermissions}
            onNavigate={dxNavigate}
            permissionEnum={
              permissionEnumReverse[permissionEnum.EXPERIMENT_READ]
            }
          />
        )}
      />
      <Route
        exact
        path="/content-maps/:id/:step"
        render={routerProps => {
          const {
            match: {
              params: { id },
            },
          } = routerProps
          return (
            <ContentMap
              {...routerProps}
              id={Number(id)}
              user={currentUser}
              applicationPermissions={applicationPermissions}
              statusFilter={routerProps.match.params.status}
              currentlyViewing={currentlyViewing}
              onNavigate={dxNavigate}
              onSortClick={sortCallback}
              sortHeader={sortHeader}
              reverseSort={reverseSort}
              permissionEnum={
                permissionEnumReverse[permissionEnum.EXPERIMENT_READ]
              }
            />
          )
        }}
      />
      <Route
        exact
        path="/content-maps/:status"
        render={routerProps => (
          <ContentMapList
            {...routerProps}
            user={currentUser}
            applicationPermissions={applicationPermissions}
            statusFilter={routerProps.match.params.status}
            currentlyViewing={currentlyViewing}
            onNavigate={dxNavigate}
            onSortClick={sortCallback}
            sortHeader={sortHeader}
            reverseSort={reverseSort}
            permissionEnum={
              permissionEnumReverse[permissionEnum.EXPERIMENT_READ]
            }
          />
        )}
      />
      {isCalculatorActive && (
        <Route
          exact
          path="/sample-calculator"
          render={routerProps => (
            <ExperimentCalculatorPage
              {...routerProps}
              user={currentUser}
              applicationPermissions={applicationPermissions}
              onNavigate={dxNavigate}
              permissionEnum={
                permissionEnumReverse[permissionEnum.EXPERIMENT_CREATE]
              }
            />
          )}
        />
      )}
      {isLearnWikiActive && (
        <Route
          exact
          path="/learn/*"
          render={routerProps => {
            const { location } = routerProps

            return <LearnWiki location={location} dxNavigate={dxNavigate} />
          }}
        />
      )}

      {shouldShowExperimentEval && (
        <Route
          exact
          path="/experiment-evaluation/:status"
          render={routerProps => (
            <ExperimentEvaluationListPage
              {...routerProps}
              user={currentUser}
              statusFilter={routerProps.match.params.status}
              applicationPermissions={applicationPermissions}
              currentlyViewing={currentlyViewing}
              onNavigate={dxNavigate}
            />
          )}
        />
      )}
      {shouldShowExperimentEval && (
        <Route
          exact
          path="/experiment-evaluation/:id/:step"
          render={routerProps => {
            const {
              match: {
                params: { id },
              },
            } = routerProps
            return (
              <EvaluationCardPage
                {...routerProps}
                user={currentUser}
                id={Number(id)}
                applicationPermissions={applicationPermissions}
                currentlyViewing={currentlyViewing}
                onNavigate={dxNavigate}
              />
            )
          }}
        />
      )}
      <Route
        exact
        path="/experiments/:status/:id/*"
        render={routerProps => {
          const {
            match: {
              params: { status },
              url,
            },
          } = routerProps
          const newUrl = url.replace(`/${status}`, '')
          return <Redirect to={newUrl} />
        }}
      />
      <Route exact path="/" render={() => <Redirect to="/experiments/all" />} />
      <Route
        exact
        path="*"
        render={() => {
          appModel.error = 'Not found'
        }}
      />
    </Switch>
  )
}

Routes = observer(Routes)

const InnerApp = observer(function InnerApp(props) {
  const { error } = props
  const history = useHistory()

  window.dxLoadCurrentUser = () => {
    appModel.loadCurrentUser()
  }

  useEffect(() => {
    appModel.authenticate()
  }, [])

  const dxNavigate = useCallback(
    (toView, shouldMerge) => appModel.navigate(toView, shouldMerge, history),
    [history]
  )

  const fillSpace = useRouteMatch('/learn/*') !== null

  const { isAuthenticated, error: appStateError, currentUser } = appModel

  let errorMessage = null
  let displayErrorPage = false
  let errorPageData = {}

  const hasClientError =
    appStateError &&
    appStateError.message &&
    (appStateError.message.includes('Keycloak error') ||
      appStateError.message.includes('Failed to fetch'))

  if (hasClientError) {
    displayErrorPage = true
    errorPageData = {
      message: copyContent.fiveHundredErrorMessage,
      hypothesis: copyContent.fiveHundredErrorHypothesis,
      conclusion: copyContent.fiveHundredErrorConclusion,
      code: '500',
    }
  } else if (appStateError && appStateError === 'Not Found') {
    displayErrorPage = true
    errorPageData = {
      message: copyContent.notFoundErrorMessage,
      hypothesis: copyContent.notFoundErrorHypothesis,
      conclusion: copyContent.notFoundErrorConclusion,
      code: '404',
    }
  } else if (error && error.message) {
    errorMessage = error.message
  } else if (!errorMessage && appStateError && appStateError.message) {
    errorMessage = appStateError.message
  }

  if (displayErrorPage) {
    return (
      <div className={'routes routes__fill'}>
        <HeaderMenu
          logoTitle="Distillery"
          logoAssetPath="/assets/images/favicon/apple-touch-icon.png"
        />
        <ErrorPage
          message={errorPageData.message}
          hypothesis={errorPageData.hypothesis}
          conclusion={errorPageData.conclusion}
          code={errorPageData.code}
        />
      </div>
    )
  }

  if (currentUser && currentUser.userToRoles.length === 0) {
    return (
      <div className="navigation-content__wrapper">
        <HeaderMenu
          logoTitle="Distillery"
          logoAssetPath="/assets/images/favicon/apple-touch-icon.png"
          currentlyViewing={{}}
        />
        <ErrorPage
          message={copyContent.notAuthorizedHeader}
          hypothesis={copyContent.notAuthorizedMessage}
          conclusion=" "
          code={copyContent.notAuthorizedCode}
        />
      </div>
    )
  }

  if (errorMessage && !displayErrorPage) {
    return (
      <div className="routes">
        <DialogContainer>
          <div className="routes__error">
            <h2 className="routes__error-header">Whoopsie!</h2>
            <p className="routes__error-message">{errorMessage}</p>
          </div>
        </DialogContainer>
      </div>
    )
  }

  if (isAuthenticated) {
    return (
      <NavigationContainer dxNavigate={dxNavigate} fillSpace={fillSpace}>
        <div className="decommissionDiv" style={{ width: '100%' }}>
          <KiteAlert
            className="decommission__banner"
            type="caution"
            level="global"
            description="The last day to launch an experiment in Distillery will be April 30, 2023. All experiments will need to be launched in Distillery Next from that point forward. Any Distillery experiment launched and running before the April 30th cutoff can still be managed as normal. Please reach out to DL-DPDATA-Distillery@charter.com or in the Distillery Webex support room if you have any questions."
          />
        </div>
        <div className={fillSpace ? 'routes routes__fill' : 'routes'}>
          <Routes dxNavigate={dxNavigate} />
        </div>
        <div id="fixed-footer" />
      </NavigationContainer>
    )
  }

  return null
})

class App extends Component {
  state = {
    error: undefined,
    browserCompatibilityAlerted:
      sessionStore.getItem('checkedBrowser') === 'true' ? true : false,
  }

  static getDerivedStateFromError(error) {
    return { error }
  }

  handleCheckUserBrowser = () => {
    sessionStore.setItem('checkedBrowser', true)
    this.setState(prevState => ({
      ...prevState,
      browserCompatibilityAlerted: true,
    }))
  }

  componentDidMount() {
    const { browserCompatibilityAlerted } = this.state
    if (
      !browserCompatibilityAlerted &&
      navigator.userAgent.includes('Chrome/') &&
      !navigator.userAgent.includes('Edg/')
    ) {
      this.handleCheckUserBrowser()
    }
  }

  render() {
    const { error, browserCompatibilityAlerted } = this.state

    return (
      <ApolloProvider client={client}>
        <BrowserRouter>
          <InnerApp key="innerApp" error={error} />
        </BrowserRouter>
        {!browserCompatibilityAlerted && (
          <Modal
            message="Browser Alert"
            subMessage="For the best experience, we recommend using Google Chrome. At this time, Distillery is not supported on your browser and performance issues may occur."
            onDeny={this.handleCheckUserBrowser}
            className="app__browser-check-modal"
            buttonProps={{
              deny: {
                value: 'Dismiss ',
                className: 'app__browser-check-btn',
              },
            }}
          />
        )}
      </ApolloProvider>
    )
  }
}

export default observer(App)
