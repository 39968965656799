import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

/* Sample Input
*
* {
  variantId: Int!
  overrideId: Int!
}
*/

const INSERT_VARIANT_TO_OVERRIDES = gql`
  mutation insertVariantToOverridess(
    $experimentId: Int!
    $input: [InsertVariantToOverridesType]!
  ) {
    insertVariantToOverrides(experimentId: $experimentId, input: $input) {
      id
      overrides {
        id
        name
        overrideIdentifier
        overrideType {
          id
          name
        }
      }
    }
  }
`

const insertVariantToOverrides = graphql(INSERT_VARIANT_TO_OVERRIDES, {
  name: 'insertVariantToOverrides',
})

export default insertVariantToOverrides
