import React, { Component } from 'react'
import { func, object, bool, number } from 'prop-types'
import flowRight from 'lodash/flowRight'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import { KiteCard, KiteLoader } from '@kite/react-kite'
import { LabeledToggle } from '@kite/react-kite-plus'
import {
  metricTypeEnum,
  checkMetrics,
  createQuantumApolloClient,
} from '@charter/distillery-rules'

import { client } from '../../../../configuration/configApiClient'
import { LOG_ERROR } from '../../../../shared/mutations'
import { formatLoggingError } from '../../../../shared/utilities'
import {
  getExperimentMetrics,
  GET_EXPERIMENT_METRICS,
} from '../../../../shared/queries'
import copyContent from '../../data/copyContent'
import GET_QUANTUM_METRIC_DEFINITIONS from './queries/getQuantumMetricDefinitions'
import updateExperimentToMetrics from './mutations/updateExperimentToMetrics'
import { ExpansionPanel } from '../../../../componentLibrary'
import './Metrics.scss'
import MetricDefModal from '../../../../components/MetricDefModal/MetricDefModal'

export class Metrics extends Component {
  static propTypes = {
    onChange: func.isRequired,
    id: number,
    experimentMetricsData: object,
    updateExperimentToMetrics: func,
    disabled: bool,
    queryQuantum: func,
  }

  static defaultProps = {
    id: null,
    experimentMetricsData: null,
    updateExperimentToMetrics: null,
    disabled: false,
    queryQuantum: null,
  }

  state = {
    metricsById: {},
    isLoadingQuantum: true,
    errorMessage: {
      mustHaveOneLevelOne: null,
      updateMetricsError: null,
      hasJustOneLevelOne: null,
      queryQuantumError: null,
    },
  }

  // LIFECYCLE METHODS
  componentDidMount() {
    const { experimentMetricsData } = this.props
    if (experimentMetricsData && experimentMetricsData.metricsForExperiment) {
      this.handleSetExperiment()
    }
  }

  componentDidUpdate(prevProps) {
    const { experimentMetricsData } = this.props
    const { experimentMetricsData: prevData } = prevProps

    if (
      prevData &&
      experimentMetricsData &&
      !isEqual(
        prevData.metricsForExperiment,
        experimentMetricsData.metricsForExperiment
      )
    ) {
      this.handleSetExperiment()
    }
  }

  // REF Methods
  getErrorMessage = () => {
    const { errorMessage } = this.state

    const messages = Object.values(errorMessage).reduce(
      (accumulator, error) => {
        if (!accumulator && error) {
          return `${error}`
        }
        if (error) {
          return `${accumulator}; ${error}`
        }
        return accumulator
      },
      ''
    )
    return messages
  }

  validateSubmission = async () => {
    const enabledSuccessMetrics = this.getEnabledMetrics(
      this.getMetricsOfType(metricTypeEnum.SUCCESS)
    )

    const enabledSafetyMetrics = this.getEnabledMetrics(
      this.getMetricsOfType(2)
    )

    const enabledMetrics = enabledSuccessMetrics.concat(enabledSafetyMetrics)

    const { errors } = checkMetrics(enabledMetrics)

    if (!isEmpty(errors)) {
      this.setState(({ errorMessage }) => ({
        errorMessage: {
          ...errorMessage,
          ...errors,
        },
      }))

      return false
    }

    return true
  }

  // FILTER METHODS
  getMetricsOfType = typeId => {
    const { metricsById } = this.state
    return Object.values(metricsById)
      .filter(metric => metric.metricType.id === typeId)
      .sort(({ name: metricAName }, { name: metricBName }) => {
        if (metricAName < metricBName) {
          return -1
        }
        if (metricAName > metricBName) {
          return 1
        }
        return 0
      })
  }

  getEnabledMetrics = metrics => metrics.filter(metric => metric.isEnabled)

  // LOCAL STATE CHANGES/TOGGLES
  handleSetExperiment = async () => {
    const { experimentMetricsData, onChange } = this.props
    const { errorMessage } = this.state

    let { queryQuantum } = this.props
    // eslint-disable-next-line prefer-destructuring
    if (!queryQuantum) queryQuantum = this.queryQuantum

    const {
      metrics: { queryQuantumError },
    } = copyContent

    // Reset any previous query error
    if (errorMessage.queryQuantumError) {
      await this.setState({
        errorMessage: { ...errorMessage, queryQuantumError: null },
      })
    }

    try {
      await this.setState({
        isLoadingQuantum: true,
      })

      const metricDefinitionData = await queryQuantum()
      const { metricDefinitions } = metricDefinitionData

      const metricsById = {}
      const { metricsForExperiment } = experimentMetricsData

      metricsForExperiment.forEach(metric => {
        const metricDef = metricDefinitions.filter(
          ({ _id }) => metric.uuid === _id
        )[0]
        if (metricDef) {
          const { description, calculation } = metricDef
          metricsById[metric.id] = {
            ...metric,
            description,
            calculation,
          }
        } else {
          metricsById[metric.id] = {
            ...metric,
            description: 'N/A',
            calculation: 'N/A',
          }
        }
      })

      await this.setState({
        metricsById,
        isLoadingQuantum: false,
      })

      onChange(false)
    } catch (error) {
      const input = formatLoggingError(error)

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input,
        },
      })

      await this.setState({
        isLoadingQuantum: false,
        errorMessage: {
          ...errorMessage,
          queryQuantumError,
        },
      })
    }
  }

  handleChange = async (event, metric) => {
    const { onChange } = this.props
    const { metricsById } = this.state

    if (event) {
      event.preventDefault()
    }

    metricsById[metric.id].isEnabled = !metricsById[metric.id].isEnabled
    metricsById[metric.id].isDirty = true

    await this.setState(({ errorMessage }) => ({
      metricsById,
      errorMessage: {
        ...errorMessage,
        mustHaveOneLevelOne: null,
        hasJustOneLevelOne: null,
      },
    }))

    onChange(true)
  }

  // API Methods
  handleSubmit = async () => {
    const { metricsById, errorMessage } = this.state
    const {
      id: experimentId,
      updateExperimentToMetrics: updateMetrics,
    } = this.props

    const experimentMetricsInput = []
    Object.values(metricsById).forEach(metric => {
      if (metric.isDirty) {
        experimentMetricsInput.push({
          metricId: metric.id,
          isEnabled: metric.isEnabled,
        })

        // simple way of keeping track of dirty fields
        // eslint-disable-next-line no-param-reassign
        metric.isDirty = false
      }
    })

    // Reset any previous submission errors
    if (errorMessage.updateMetricsError) {
      this.setState({
        errorMessage: { ...errorMessage, updateMetricsError: null },
      })
    }
    try {
      await updateMetrics({
        variables: {
          input: {
            experimentId: Number(experimentId),
            experimentMetrics: experimentMetricsInput,
          },
        },
        refetchQueries: [
          {
            query: GET_EXPERIMENT_METRICS,
            variables: { id: experimentId },
          },
        ],
      })

      await this.setState({ metricsById })
    } catch (error) {
      const input = formatLoggingError(error, { id: experimentId })

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input,
        },
      })

      this.setState(({ oldErrorMessage }) => ({
        errorMessage: {
          ...oldErrorMessage,
          updateMetricsError: copyContent.metrics.updateMetricsError,
        },
      }))
    }
    return true
  }

  queryQuantum = async () => {
    const {
      experimentMetricsData: { retrieveQuantumToken: quantumToken },
    } = this.props

    const quantumClient = createQuantumApolloClient({
      quantumToken,
      fetch,
    })

    const res = await quantumClient.query({
      query: GET_QUANTUM_METRIC_DEFINITIONS,
    })

    return res.data
  }

  // RENDER METHODS
  renderMetric = metric => {
    const { disabled } = this.props
    const { metricsById } = this.state

    const enabledMetrics = this.getEnabledMetrics(Object.values(metricsById))
    const metricIsEnabledInOtherCategory = enabledMetrics.find(
      ({ id, name, isEnabled }) =>
        isEnabled && id !== metric.id && name === metric.name
    )

    return (
      <div key={metric.id} className="experiment-metrics__metric">
        <LabeledToggle
          label={metric.displayName}
          active={metric.isEnabled}
          maxWidth="100%"
          onClick={
            disabled || metricIsEnabledInOtherCategory
              ? null
              : event => this.handleChange(event, metric)
          }
        />
        <MetricDefModal metricDef={metric} />
      </div>
    )
  }

  renderLevelOneTwoMetrics = () => {
    const {
      errorMessage: { mustHaveOneLevelOne, hasJustOneLevelOne },
    } = this.state
    const { disabled } = this.props
    const {
      metrics: {
        levelOneTitle,
        levelOneDescription,
        levelTwoTitle,
        levelTwoDescription,
      },
    } = copyContent
    let error = false

    let levelOneMetrics = this.getMetricsOfType(1)
    let levelTwoMetrics = this.getMetricsOfType(2)

    if (disabled) {
      levelOneMetrics = levelOneMetrics.filter(
        metric => metric && metric.isEnabled
      )
      levelTwoMetrics = levelTwoMetrics.filter(
        metric => metric && metric.isEnabled
      )
    }

    if (mustHaveOneLevelOne || hasJustOneLevelOne) {
      error = true
    }

    return (
      <KiteCard
        className={`experiment-metrics__card ${
          error ? 'experiment-metrics__card-error' : ''
        }`}
      >
        <div className="part experiment-metrics__level-one">
          <h5>{levelOneTitle}</h5>
          <p>{levelOneDescription}</p>
          <div className="experiment-metrics__pill-container">
            {levelOneMetrics.map(metric => metric && this.renderMetric(metric))}
          </div>
        </div>

        {levelTwoMetrics.length > 0 && (
          <div className="part experiment-metrics__level-two">
            <h5>{levelTwoTitle}</h5>
            <p>{levelTwoDescription}</p>
            <div className="experiment-metrics__pill-container">
              {levelTwoMetrics.map(
                metric => metric && this.renderMetric(metric)
              )}
            </div>
          </div>
        )}
      </KiteCard>
    )
  }

  renderLevelThreeMetrics = () => {
    const metrics = this.getMetricsOfType(3)
    const {
      metrics: { otherDescription, otherDescriptionTitle, levelThreeTitle },
    } = copyContent

    if (!metrics.length) {
      return <div />
    }

    const {
      experimentMetricsData: { loading },
    } = this.props

    if (loading) {
      return (
        <div className="app__loader">
          <KiteLoader size="7rem" />
        </div>
      )
    }

    return (
      <KiteCard>
        <div className="part experiment-metrics__description">
          <h5>{otherDescriptionTitle}</h5>
          <p>{otherDescription}</p>
        </div>
        <div className="experiment-metrics__line" />
        <div className="part experiment-metrics__level-three">
          <h5>{levelThreeTitle}</h5>
          <div className="experiment-metrics__pill-container">
            {metrics.map(metric => metric && this.renderMetric(metric))}
          </div>
        </div>
      </KiteCard>
    )
  }

  render() {
    const { experimentMetricsData } = this.props
    const {
      errorMessage: { mustHaveOneLevelOne, hasJustOneLevelOne },
      isLoadingQuantum,
    } = this.state
    let error

    if (
      !experimentMetricsData ||
      experimentMetricsData.loading ||
      isLoadingQuantum
    ) {
      return (
        <div className="app__loader">
          <KiteLoader size="7rem" />
        </div>
      )
    }

    if (mustHaveOneLevelOne || hasJustOneLevelOne) {
      error = (
        <React.Fragment>
          {!mustHaveOneLevelOne ? '' : <div>{mustHaveOneLevelOne || ''}</div>}
          {!hasJustOneLevelOne ? '' : <div>{hasJustOneLevelOne || ''}</div>}
        </React.Fragment>
      )
    }

    return (
      <div className="experiment-metrics">
        <div className="experiment-metrics__header">
          <h4 className="experiment-metrics__title">Manage Metrics</h4>
        </div>
        <ExpansionPanel
          className="set-up-experiment__error-message experiment-metrics__error-message"
          type="minimal"
          isExpanded={!!error}
        >
          {error}
        </ExpansionPanel>
        {this.renderLevelOneTwoMetrics()}
        {this.renderLevelThreeMetrics()}
      </div>
    )
  }
}

export default flowRight(
  getExperimentMetrics,
  updateExperimentToMetrics
)(Metrics)
