import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

/*
* Sample Input
* {
  input: {
    environmentSamplingId: Int!,
    environmentId: Int!,
    experimentId: Int!,
    sampleAllocation: Float!,
    variantSampling: [
      {
        variantSamplingToEnvironmentId: Int!,
        variantId: Int!,
        weight: Float!
      }
    ]
  }
}
*/

const UPDATE_EXPERIMENT_TO_ENVIRONMENT = gql`
  mutation updateExperimentToEnvironment(
    $input: UpdateEnvironmentSamplingForExperiment!
  ) {
    publishUpdateExperimentToEnvironment(input: $input) {
      id
      startTime
      durationNumDays
      variants {
        id
        name
        displayOrder
      }
      environmentSamplings {
        id
        tdcsUpdateRequired
        isDreSynced
        sampleAllocation
        environment {
          id
          name
          color
        }
        experimentStatus {
          id
          name
        }
        lastModifiedTime
        lastModifiedUser {
          id
          displayName
        }
        environmentSamplingToVariants {
          id
          weight
          variant {
            id
            name
          }
          lastModifiedTime
          lastModifiedUser {
            id
            displayName
          }
        }
        allocationHistory {
          id
          isReset
          uuid
          sampleAllocation
          variantConfig
          createdTime
          lastModifiedUser {
            id
            displayName
          }
        }
      }
    }
  }
`

const updateExperimentToEnvironment = graphql(
  UPDATE_EXPERIMENT_TO_ENVIRONMENT,
  {
    name: 'updateExperimentToEnvironment',
  }
)

export default updateExperimentToEnvironment
