import React from 'react'
import ReactDOM from 'react-dom'
import { App } from './components'
import { datadogRum } from '@datadog/browser-rum'

if(process.env.REACT_APP_DATADOG_RUM_APPLICATION_ID) {
  console.log("Setting up datadog RUM")
  let env = window.location.hostname.split(".")[0] // get the first part of hostname

  if(env === "distillery") {
    env = "prod"
  }

  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_RUM_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'distillery',
    env: env,
    sampleRate: 100,
    trackInteractions: true
  })
}
else {
  console.log("No datadog API ID")
}

ReactDOM.render(
  <App />,
  document.getElementById('root') || document.createElement('div')
)
