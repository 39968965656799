/* eslint-disable */
import * as React from 'react';

import { oneWord } from './oneWord';

const StackedRadioButton = ({
  id,
  label,
  name,
  buttonOrientation,
  value,
  checked,
  onChange,
  disabled,
  anyOthersChecked,
  subtext,
  isFirstButton,
}) => (
  <label
    htmlFor={oneWord(id || name || label)}
    className="kite-custom-control kite-custom-radio kite-radio__button"
    style={
      buttonOrientation === 'row'
        ? { marginBottom: 0, paddingLeft: '30px', marginRight: '20px' }
        : {}
    }
  >
    <input
      type="radio"
      id={oneWord(id || name || label)}
      name={name}
      value={value || label}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      className="kite-custom-control-input"
      aria-checked={checked}
      tabIndex={(isFirstButton && !anyOthersChecked) || checked ? 0 : -1}
    />
    <div className="kite-custom-control-indicator" />
    <span className="kite-custom-control-description">
      {label}
      {subtext && (
        <p className="kite-radio__subtext" aria-hidden="true">
          {subtext}
        </p>
      )}
    </span>
  </label>
);

StackedRadioButton.defaultProps = {
  id: '',
  value: '',

  subtext: '',
  disabled: false,
  buttonOrientation: 'row',
  anyOthersChecked: false,
  isFirstButton: false,
};

export default StackedRadioButton;
