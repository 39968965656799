import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

export const GET_EXPERIMENT_DETAILS = gql`
  query getExperimentDetails($id: Int!) {
    experiment(id: $id) {
      id
      name
      config
      isFinalized
      startTime
      createdTime
      activationEventUuid
      activationEvent {
        id
        name
      }
      isTechPocApproved
      isOverridesDeleted
      isCmsExperiment
      experimentType {
        id
      }
      experimentStatus {
        id
        name
      }
      experimentToProductFeatures {
        id
        productId
        productFeatureId
        product {
          id
          uuid
          displayName
        }
        productFeature {
          id
          displayName
        }
      }
      environmentSamplings {
        id
        isDreSynced
        tdcsUpdateRequired
        environment {
          id
          name
        }
        experimentStatus {
          id
          name
        }
      }
      permissions {
        id
        name
      }
      variants {
        id
        uuid
        name
        description
        url
        jsonPayload
        displayOrder
        variantUploads {
          id
          fileUrl
        }
      }
    }
  }
`

const getExperimentDetails = graphql(GET_EXPERIMENT_DETAILS, {
  name: 'experimentDetailsData',
  skip: ({
    match: {
      params: { id },
    },
  }) => !id,
  options: ({
    match: {
      params: { id },
    },
  }) => ({ variables: { id: Number(id) } }),
})

export default getExperimentDetails
