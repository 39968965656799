export default {
  modalContent: 'Cancel the network lockdown?',
  headerText: {
    notScheduled: 'No lockdowns scheduled currently.',
    scheduled:
      'A lockdown has been scheduled. Please coordinate with its intiator to avoid gaps or overlaps; you may extend the end date as needed.',
  },
  messagesDefaultInput: {
    preMessage:
      'No publishing to production TDCS will be available during that time. Please complete all setup and safety testing no later than 24 hours beforehand.',
    duringMessage:
      'A production lockdown is currently in place. No publishing to production TDCS is available, but you can make changes in lower environments and create drafts. Contact Distillery Support if you have any experiments that are negatively impacting customer experience.',
  },
  errors: {
    startDate: 'Include a start date.',
    startTime: 'Include a start time.',
    endDate: 'Please ensure the end date comes after the start date.',
    endTime: ' ',
    endDateNeeded: 'Please include a date AND a time.',
  },
}
