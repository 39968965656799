import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

export const GET_USERS = gql`
  query getUsers {
    users {
      id
      displayName
      userToRoles {
        id
        role {
          id
          name
        }
      }
    }
  }
`

const getUsers = graphql(GET_USERS, {
  name: 'allUsersData',
})

export default getUsers
