import { gql } from '@apollo/client'

const ACKNOWLEDGE_OVERRIDES_DELETION = gql`
  mutation acknowledgeOverrideDeletion($experimentId: Int!) {
    acknowledgeOverrideDeletion(experimentId: $experimentId) {
      id
      isOverridesDeleted
    }
  }
`

export default ACKNOWLEDGE_OVERRIDES_DELETION
