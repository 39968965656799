import React, { Component } from 'react'
import classNames from 'classnames'
import { string, number, object, func, bool, oneOfType } from 'prop-types'

import ExpansionPanel from '../ExpansionPanel/ExpansionPanel'
import './Select.scss'

class Select extends Component {
  static propTypes = {
    onSelect: func.isRequired,
    className: string,
    identifier: string,
    label: string,
    selectedValue: oneOfType([string, number]),
    options: object,
    optionGroups: object,
    placeholder: string,
    disabled: bool,
    errorMessage: string,
  }

  static defaultProps = {
    className: null,
    identifier: null,
    label: null,
    selectedValue: null,
    options: null,
    optionGroups: null,
    placeholder: null,
    disabled: false,
    errorMessage: null,
  }

  handleSelect = value => {
    const { identifier = null, onSelect } = this.props

    if (onSelect) {
      onSelect(value, identifier)
    }
  }

  render() {
    const {
      className,
      label,
      selectedValue,
      options,
      optionGroups,
      disabled,
      placeholder,
      errorMessage,
    } = this.props

    const selectClassNames = classNames({
      select__input: true,
      'no-selection': !selectedValue,
      warning: errorMessage,
    })

    return (
      <div className={`select ${className || ''}`}>
        {label && <span className="select__label">{label}</span>}
        <select
          className={selectClassNames}
          defaultValue={selectedValue || ''}
          onChange={({ target: { value } }) => this.handleSelect(value)}
          disabled={disabled}
        >
          <option value="" disabled>{`${placeholder || 'Select'}`}</option>
          {options &&
            !optionGroups &&
            options &&
            Object.keys(options).map(option => (
              <option
                key={`${option}-${options[option]}`}
                value={options[option]}
              >
                {option}
              </option>
            ))}
          {optionGroups &&
            !options &&
            Object.keys(optionGroups).map((group, index) => {
              const key = `${group}__${index}`
              return (
                <optgroup key={key} label={group}>
                  {Object.keys(optionGroups[group]).map(subOption => (
                    <option
                      key={`${subOption}-${optionGroups[group][subOption]}`}
                      value={optionGroups[group][subOption]}
                    >
                      {subOption}
                    </option>
                  ))}
                </optgroup>
              )
            })}
          {!options && !optionGroups && (
            <option value={null} disabled>
              No options available
            </option>
          )}
        </select>
        <ExpansionPanel isExpanded={errorMessage && true} type="minimal">
          <span className="select__error-message">{errorMessage}</span>
        </ExpansionPanel>
      </div>
    )
  }
}

export default Select
