import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

const INSERT_VARIANT_UPLOADS = gql`
  mutation insertVariantUploads(
    $variantId: Int!
    $variantUploads: [VariantUploadEntry!]!
  ) {
    insertVariantUploadsForVariant(
      variantId: $variantId
      variantUploads: $variantUploads
    ) {
      id
      variantUploads {
        id
        fileUrl
      }
    }
  }
`

const insertVariantUploads = graphql(INSERT_VARIANT_UPLOADS, {
  name: 'insertVariantUploads',
  withRef: true,
})

export default insertVariantUploads
