import React, { Component } from 'react'
import { string, func, number, object, bool } from 'prop-types'

import { KiteInput } from '@kite/react-kite'
import ExpansionPanel from '../ExpansionPanel/ExpansionPanel'
import './LabeledInput.scss'

class LabeledInput extends Component {
  static propTypes = {
    label: string.isRequired,
    onChange: func.isRequired,
    identifier: string,
    className: string,
    value: string,
    placeholder: string,
    errorMessage: string,
    maxCharCount: number,
    counterColors: object,
    trim: bool,
    noSpecialCharacters: bool,
  }

  static defaultProps = {
    identifier: null,
    value: '',
    placeholder: '',
    maxCharCount: null,
    counterColors: { progress: '#000', completed: '#d6312b' },
    trim: false,
    noSpecialCharacters: false,
    errorMessage: null,
    className: null,
  }

  // pass input value to onChange prop
  handleChange = ({ target: { value } }) => {
    const {
      onChange,
      identifier = null,
      maxCharCount,
      trim,
      noSpecialCharacters,
    } = this.props

    const specialCharactersRegex = /[^A-Za-z0-9\s]/gi
    let validValue = maxCharCount ? value.slice(0, maxCharCount) : value

    // IF starting with a space, negate value ELSE disallow double spaces
    if (trim) {
      validValue = validValue === ' ' ? '' : validValue.replace(/\s{2,}/g, ' ')
    }

    if (noSpecialCharacters && specialCharactersRegex.test(validValue)) {
      return
    }

    if (onChange) {
      onChange(validValue, identifier)
    }
  }

  render() {
    const {
      label,
      value,
      maxCharCount,
      placeholder,
      counterColors,
      errorMessage,
      className,
    } = this.props
    const validValue = maxCharCount ? value.slice(0, maxCharCount) : value

    return (
      <div className="labeled-input">
        <div className="labeled-input__label-wrapper">
          <span className={`labeled-input__label ${className || ''}`}>
            {label}
          </span>
          {maxCharCount && (
            <span
              className={`labeled-input__counter ${className || ''}`}
              style={
                counterColors && {
                  color:
                    value.length < maxCharCount
                      ? counterColors.progress
                      : counterColors.completed,
                }
              }
            >
              {validValue.length} /{maxCharCount}
            </span>
          )}
        </div>
        <KiteInput
          value={validValue || ''}
          onChange={this.handleChange}
          placeholder={placeholder}
          hasError={errorMessage && true}
          className={`labeled-input__input-field ${className || ''}`}
        />
        <ExpansionPanel isExpanded={errorMessage && true} type="minimal">
          <span className="labeled-input__error-message">{errorMessage}</span>
        </ExpansionPanel>
      </div>
    )
  }
}

export default LabeledInput
