import React from 'react'
import classNames from 'classnames'
import { func, string, array, element } from 'prop-types'
import DialogContainer from '../DialogContainer/DialogContainer'
import Button from '../Button/Button'
import './Dialog.scss'

const Dialog = ({ onClose, header, controls, children, className }) => {
  const headerClassNames = classNames({
    'dialog-header__wrapper': true,
    'with-header-title': header,
  })

  return (
    <DialogContainer>
      <div className={`dialog-wrapper ${className || ''}`}>
        <div className={headerClassNames}>
          {header && <h2 className="dialog-header-title">{header}</h2>}
          <i
            className="material-icons close-button "
            role="button"
            tabIndex="-1"
            onClick={onClose}
            onKeyDown={onClose}
          >
            close
          </i>
        </div>

        <div className="dialog-body">{children}</div>

        {controls && (
          <div className="dialog-controls__container">
            {controls.map((control, index) => (
              <Button
                {...control}
                role="button"
                tabIndex={index}
                key={control.id}
                onClick={control.onClick}
                onKeyDown={control.onKeyDown}
              />
            ))}
          </div>
        )}
      </div>
    </DialogContainer>
  )
}

export default Dialog

Dialog.propTypes = {
  onClose: func.isRequired,
  children: element.isRequired,
  header: string,
  controls: array,
  className: string,
}

Dialog.defaultProps = {
  header: null,
  controls: null,
  className: null,
}
