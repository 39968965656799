import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

/*
* Sample Input
*
* {
    name: CustomString!
    overrideIds: [Int!]
    description: CustomString
  }
*/

const INSERT_OVERRIDE_GROUP = gql`
  mutation insertOverrideGroup($input: InsertOverrideGroupType!) {
    insertOverrideGroup(input: $input) {
      id
      name
      overrides {
        id
        name
        overrideIdentifier
      }
    }
  }
`

const insertOverrideGroup = graphql(INSERT_OVERRIDE_GROUP, {
  name: 'insertOverrideGroup',
})

export default insertOverrideGroup
