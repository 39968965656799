import { decorate, observable, computed, untracked } from 'mobx'

import { client } from '../../configuration/configApiClient'
import { gql } from '@apollo/client'

// holds override types
class OverrideTypesModel {
  apiArray = []

  static localCache = []
  static apiQuery = gql`
    query {
      overrideTypes {
        id
        name
      }
    }
  `

  async apiLoad() {
    const res = await client.query({
      query: OverrideTypesModel.apiQuery,
    })
    // cache the override types statically on this model
    // so editing every single override doesn't require us loading these
    OverrideTypesModel.localCache = res.data.overrideTypes
    this.updateArrayFromCache()
  }

  updateArrayFromCache() {
    this.apiArray.replace(OverrideTypesModel.localCache)
  }

  get objects() {
    if (!this.apiArray.length) {
      if (OverrideTypesModel.localCache.length) {
        //lil side effect - update static cache
        untracked(() => this.updateArrayFromCache())
      } else {
        this.apiLoad()
      }
    }
    return this.apiArray
  }

  get objectsById() {
    return this.apiArray.reduce((byId, object) => {
      byId[object.id] = object
      return byId
    }, {})
  }
}

OverrideTypesModel = decorate(OverrideTypesModel, {
  apiArray: observable,
  objects: computed,
})

export default OverrideTypesModel
