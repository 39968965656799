import { gql } from '@apollo/client'

const APPROVE_TECH_SETTINGS = gql`
  mutation approveTechSettings($experimentId: Int!) {
    approveTechSettings(experimentId: $experimentId) {
      id
      isTechPocApproved
    }
  }
`

export default APPROVE_TECH_SETTINGS
