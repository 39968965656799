const removeTypename = (object) => {
  const clean = (key, value) =>
    (key === '__typename' ? undefined : value);

  const newObject = JSON.parse(JSON.stringify(object), clean);

  return newObject;
};

export default removeTypename;
