import { gql } from '@apollo/client'

const UPDATE_AUDIENCE = gql`
  mutation updateAudience($id: Int!, $input: AudienceUpdateInput!) {
    updateAudience(id: $id, input: $input) {
      id
      name
      description
      rules
    }
  }
`

export default UPDATE_AUDIENCE
