import { decorate, observable, computed, action, getAtom, reaction } from 'mobx'
import { createElement as h } from 'react'
import { KiteButton } from '@kite/react-kite'

import appModel from '../../components/App/AppModel'
import ListModel from '../../shared/models/ListModel'
import OREditorModel from './OREditorModel'
import ORGroupEditorModel from './ORGroupEditorModel'
import { overrideTypeEnum } from '@charter/distillery-rules/lib/enums'

class ORMainModel {
  async start() {
    this.updateCurrentlyViewing()
    await this.orListModel.submitQuery()
    await this.orGroupListModel.submitQuery()

    reaction(
      () => this.isFilterModalOpen,
      () => {
        this.orListModel.shouldFilter = this.orGroupListModel.shouldFilter =
          !this.isFilterModalOpen || this.hasFilters
      }
    )
  }

  reset() {
    if (this.orListModel.isEditing) this.orListModel.cancelEditing()
    if (this.orGroupListModel.isEditing) this.orGroupListModel.cancelEditing()
  }

  selectedTab = 'Overrides'
  isFilterModalOpen = false
  modelsById = new Map()
  isQuickAddSuccess = false
  searchText = ''
  filters = {
    accountFilter: false,
    deviceFilter: false,
    accountDeviceFilter: false,
    productFilters: new Set(),
  }

  addNewInTab(tab) {
    this.selectedTab = tab
    this.listModel.addNew()
  }

  get tabs() {
    return ['Overrides', 'Groups'].map(tab => `${tab} (${this.numForTab(tab)})`)
  }

  get hasFilters() {
    return (
      this.filters.accountFilter ||
      this.filters.deviceFilter ||
      this.filters.accountDeviceFilter ||
      this.filters.productFilters.size > 0
    )
  }

  gotoBulkImport() {
    if (this.listModel.editorModel) this.listModel.editorModel.finish()
    this.addNewInTab('Groups')
  }

  get searchObject() {
    if (this.searchText?.length === 0 && !this.hasFilters) return null

    return {
      searchText: this.searchText,
      ...this.filters,
    }
  }

  searchOverrides = ({ row }) => {
    const searchText = this.searchText
    const { type, accountGuid, clientGuid, name, productId } = row

    let isMatch = false

    if (this.hasFilters) {
      const {
        accountFilter,
        deviceFilter,
        accountDeviceFilter,
        productFilters,
      } = this.searchObject

      // check the type filters
      if (accountFilter && type === overrideTypeEnum.ACCOUNT) isMatch = true
      else if (deviceFilter && type === overrideTypeEnum.DEVICE) isMatch = true
      else if (accountDeviceFilter && type === overrideTypeEnum.ACCOUNT_DEVICE)
        isMatch = true

      // check product filters
      if (!isMatch && productId && productFilters.has(productId)) {
        isMatch = true
      }
    }

    if (this.hasFilters && !isMatch) return false

    // now apply search text
    if (searchText.length) {
      if (
        name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 ||
        accountGuid?.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 ||
        clientGuid?.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
      )
        isMatch = true
      else if (this.hasFilters) isMatch = false
    }

    return isMatch
  }

  searchGroups = ({ row }) => {
    const searchText = this.searchText
    const { name, description } = row

    let isMatch = false

    // now apply search text
    if (searchText.length) {
      if (
        name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 ||
        description.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
      )
        isMatch = true
      else if (this.hasFilters) isMatch = false
    }

    return isMatch
  }

  nameForTab(tab) {
    return tab.substring(0, tab.indexOf(' '))
  }

  selectTab = tab => {
    this.selectedTab = this.nameForTab(tab)
  }

  get numOverrides() {
    return this.orListModel.numResults
  }

  get currentTab() {
    return this.selectedTab === 'Overrides' ? this.tabs[0] : this.tabs[1]
  }

  listModelForTab(tab) {
    return tab === 'Overrides' ? this.orListModel : this.orGroupListModel
  }

  numForTab(tab) {
    return this.listModelForTab(tab).numResults
  }

  get listModel() {
    return this.selectedTab === 'Overrides'
      ? this.orListModel
      : this.orGroupListModel
  }

  updateCurrentlyViewing() {
    appModel.updateCurrentlyViewing(
      {
        title: 'Override Management',
        backPath: '/experiments/all',
        backTitle: 'All Experiments',
      },
      true
    )
  }

  get columns() {
    return this.selectedTab === 'Overrides'
      ? this.overridesColumns
      : this.groupsColumns
  }

  get selectedTabSingular() {
    return this.selectedTab.substring(0, this.selectedTab.length - 1)
  }

  toggleProductFilter = (productId, checked) => {
    checked
      ? this.filters.productFilters.add(productId)
      : this.filters.productFilters.delete(productId)
  }

  isProductFilterChecked = productId => {
    return this.filters.productFilters.has(productId)
  }

  clearAllFilters = () => {
    this.filters.productFilters.clear()
    this.filters.accountDeviceFilter = this.filters.accountFilter = this.filters.deviceFilter = false
  }

  get filterPills() {
    if (this.isFilterModalOpen || !this.hasFilters) return null
    const pills = []
    if (this.filters.deviceFilter) pills.push('Device Overrides')
    if (this.filters.accountFilter) pills.push('Account Overrides')
    if (this.filters.accountDeviceFilter) pills.push('Account-Device Overrides')
    appModel.videoProducts
      .concat(appModel.ssppProducts)
      .forEach(({ id, displayName }) => {
        if (this.isProductFilterChecked(id)) pills.push(displayName)
      })
    return pills
  }

  removePill = pillName => {
    if (pillName === 'Device Overrides') this.filters.deviceFilter = false
    else if (pillName === 'Account Overrides')
      this.filters.accountFilter = false
    else if (pillName === 'Account-Device Overrides')
      this.filters.accountDeviceFilter = false
    else {
      // product filter
      const product = appModel.videoProducts
        .concat(appModel.ssppProducts)
        .find(({ displayName }) => displayName === pillName)

      this.toggleProductFilter(product.id, false)
    }
  }

  orListModel = new ListModel({
    EditorModelClass: OREditorModel,
    search: this.searchOverrides.bind(this),
    searchObjectAtom: getAtom(this, 'searchObject'),
  })

  orGroupListModel = new ListModel({
    EditorModelClass: ORGroupEditorModel,
    search: this.searchGroups.bind(this),
    searchObjectAtom: getAtom(this, 'searchObject'),
  })

  renderItemControls = row => {
    return h('div', { className: 'orm-list__edit-controls' }, [
      h(
        KiteButton,
        {
          className: 'detailsAndEdit',
          key: '2',
          type: 'standalone-link',
          size: 'small',
          rightIcon: 'arrow-right-circle',
          onClick: () => this.listModel.editRow(row),
        },
        'Edit'
      ),
      this.selectedTab === 'Overrides'
        ? h(
            KiteButton,
            {
              key: '1',
              type: 'standalone-link',
              size: 'small',
              style: {
                marginLeft: '8px',
              },
              className: 'orm-list__quick-view-link',
            },
            'Details'
          )
        : null,
    ])
  }

  overridesColumns = [
    {
      label: 'Name',
      sortKey: 'name',
      sortEnabled: false,
      size: 1.2,
    },
    {
      label: 'Value',
      sortKey: 'identifier',
      sortEnabled: false,
      size: 1.2,
    },
    {
      label: 'Type',
      sortKey: 'overrideTypeName',
      sortEnabled: false,
      size: 0.4,
    },
    {
      label: '\xa0',
      sortKey: 'itemControls',
      sortEnabled: false,
      className: 'orm-list__edit-controls-cell',
      size: 0.7,
      render: this.renderItemControls,
    },
  ]

  groupsColumns = [
    {
      label: 'Group Name',
      sortKey: 'name',
      sortEnabled: false,
      size: 1.2,
    },
    {
      label: 'Contains',
      sortKey: 'contains',
      sortEnabled: false,
      size: 1.0,
    },
    {
      label: 'Description',
      sortKey: 'description',
      sortEnabled: false,
      size: 1.0,
    },
    {
      label: '\xa0',
      sortKey: 'itemControls',
      sortEnabled: false,
      size: 0.5,
      render: this.renderItemControls,
    },
  ]
}

ORMainModel = decorate(ORMainModel, {
  tabs: computed,
  currentTab: computed,
  selectedTab: observable,
  selectTab: action,
  columns: computed,
  listModel: computed,
  isFilterModalOpen: observable,
  filters: observable,
  searchText: observable,
  searchObject: computed,
  hasFilters: computed,
  toggleProductFilter: action,
  clearAllFilters: action,
  modelsById: observable,
  isQuickAddSuccess: observable,
  gotoBulkImport: action,
  filterPills: computed,
  removePill: action,
})

export default ORMainModel
