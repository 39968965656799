import { gql } from '@apollo/client'

export const INSERT_LOCKDOWN = gql`
  mutation insertNetworkLockdown($input: NetworkLockdownUpsertInput!) {
    insertNetworkLockdown(input: $input) {
      id
      startDate
      endDate
      isImmediate
      preMessage
      duringMessage
    }
  }
`
