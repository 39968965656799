import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { KiteRadioButton, KiteTooltip, KiteIcon, KiteButton, KiteAlert } from '@kite/react-kite'
import { TextArea } from '@kite/react-kite-plus'
import { experimentEnvironmentEnum } from '@charter/distillery-rules'
import { DialogContainer } from '../../../componentLibrary'
import { TERMINATE_EXPERIMENT } from '../mutations/terminateExperiment'
import copyContent from '../data/copyContent'
import './CancelCompleteModal.scss'

const FINAL_OUTCOME_MAX = 500
const { PRODUCTION } = experimentEnvironmentEnum

export default function CancelCompleteModal({
  experimentId,
  environmentId,
  headerName,
  canComplete,
  handleCancelAction,
  needsObservation,
  onSuccess,
  runLessThanDay,
  isRunning,
}) {
  const prodEnv = environmentId === PRODUCTION
  const canObserve = canComplete && needsObservation && prodEnv

  const [action, setAction] = useState('completed')
  const [showDisplay, setShowDisplay] = useState({
    cancel: true,
    complete: true,
    observational:false,
  })
  const [disabledState, setDisabledState] = useState({
    complete: false,
    cancel: false,
    observational: false,
  })
  const [finalOutcome, setFinalOutcome] = useState('')
  const [errorMessage, setErrorMessage] = useState({})
  const [
    terminateExperiment,
    { loading, error },
  ] = useMutation(TERMINATE_EXPERIMENT, {
    onCompleted: () => {
      const status = action.toUpperCase()
      onSuccess(status)
    },
  })

  useEffect(() => {
    if (error) {
      setErrorMessage((prevErrors) => ({
        ...prevErrors,
        terminateError: 'Issue terminating experiment. Contact Distillery Customer Support',
      }))
    }

    if ((runLessThanDay || !isRunning) && prodEnv) {
      setAction('cancelled')
      setShowDisplay({
        cancel: true,
        complete: !needsObservation,
        observational: needsObservation,
      })
      setDisabledState({
        cancel: true,
        complete: true,
        observational: true,
      })
    } else if (!prodEnv) {
      setAction('completed')
      setShowDisplay({
        cancel: true,
        complete: true,
        observational: false,
      })
      setDisabledState({
        cancel: true,
        complete: true,
        observational: true,
      })
    } else if (needsObservation && !runLessThanDay && prodEnv) {
      setAction('observational')
      setShowDisplay({
        cancel: true,
        complete: false,
        observational: true,
      })
    }
  }, [
    error, 
    runLessThanDay,
    isRunning,
    setShowDisplay,
    canObserve,
    prodEnv,
    needsObservation
  ])

  const setCancelOrCompleteAction = ({ target: { value }}) => {
    setAction(value)
  }

  const handleFinalOutcomeChange = ({ target: { value }}) => {
    setFinalOutcome(value)
  }

  const handleSubmit = async () => {
    await terminateExperiment({
      variables: {
        id: Number(experimentId),
        environmentId,
        finalOutcome,
        experimentStatus: action
      },
    })
  }

  return (
    <DialogContainer>
      <div className="cancel-complete-modal">
        <div className="cancel-complete-modal__header-container">
          <h4>
            {`End Experiment In ${headerName}`}
          </h4>
          <KiteIcon size="14px" name="x" color="#000" onClick={handleCancelAction} />
        </div>
        {errorMessage.terminateError && (
          <KiteAlert
            className=""
            description={errorMessage.terminateError}
            id=""
            level="global"
            margin="10px 0"
            onClose={null}
            title="Error"
            type="alert"
            useDark={false}
          />
        )}
        {showDisplay.complete && (<KiteRadioButton
          anyOthersChecked={false}
          buttonOrientation="column"
          checked={action === 'completed'}
          disabled={loading || disabledState.complete}
          id="completed"
          isFirstButton={false}
          label={(
            <div>
              Complete Experiment
              <KiteTooltip
                ariaControls="experimentComplete"
                ariaId=""
                ariaLabel="experimentComplete"
                className=""
                isLight={false}
              >
                Your experiment will be regarded as successfully completed and moved to completed status. No additional allocations, activations, or metrics computations will occur.
              </KiteTooltip>
            </div>
          )}
          onChange={setCancelOrCompleteAction}
          subtext=""
          value="completed"
        />)}
        {showDisplay.observational && (<KiteRadioButton
          anyOthersChecked={false}
          buttonOrientation="column"
          checked={action === 'observational'}
          disabled={loading || disabledState.observational}
          id="observational"
          isFirstButton={false}
          label={(
            <div>
              Begin Observation Window
              <KiteTooltip
                ariaControls="experimentComplete"
                ariaId=""
                ariaLabel="experimentComplete"
                className=""
                isLight={false}
              >
                Your experiment contains one or more metrics with survival windows. Distillery will continue to monitor users that have not been observed for the full duration of those windows. No new users will be allocated or activated into the experiment.
              </KiteTooltip>
            </div>
          )}
          onChange={setCancelOrCompleteAction}
          subtext=""
          value="observational"
        />)}
        {showDisplay.cancel && (<KiteRadioButton
          anyOthersChecked={false}
          buttonOrientation="row"
          checked={action === 'cancelled'}
          disabled={loading || disabledState.cancel}
          id="cancelled"
          isFirstButton={false}
          label={(
            <div>
              Cancel Experiment
              <KiteTooltip
                ariaControls="experimentComplete"
                ariaId=""
                ariaLabel="experimentComplete"
                className=""
                isLight={false}
              >
                Your experiment will be regarded as voided and moved to cancelled status. No additional allocations, activations, or metrics computations will occur.
              </KiteTooltip>
            </div>
          )}
          onChange={setCancelOrCompleteAction}
          subtext=""
          value="cancelled"
        />)}
        {action === 'completed' && prodEnv && (<TextArea
          name="final-outcome"
          label="Record the winning variant (optional)"
          tooltip=""
          value={finalOutcome}
          onChange={handleFinalOutcomeChange}
          placeholder={copyContent.finalOutcomePlaceholder}
          width="502px"
          height="94px"
          maxLength={FINAL_OUTCOME_MAX}
        />)}
        <div className="cancel-complete-modal__end-modal-btn-container">
          <KiteButton
            size="small"
            type="outline"
            onClick={handleCancelAction}
            disabled={loading}
          >
            Exit
          </KiteButton>
          <KiteButton
            size="small"
            onClick={handleSubmit}
            disabled={loading}
            loading={loading}
          >
            Save
          </KiteButton>
        </div>
      </div>
    </DialogContainer>
  )
}