import getNestedValue from './getNestedValue';
import dayjs from 'dayjs';

const defaultSort = (data, sortHeader, reverseSort) =>
  data.sort((aItem, bItem) => {
    let item1;
    let item2;

    if (typeof sortHeader === 'string') {
      item1 = aItem[sortHeader];
      item2 = bItem[sortHeader];
    } else {
      item1 = getNestedValue(aItem, sortHeader);
      item2 = getNestedValue(bItem, sortHeader);
    }

    // drive null and undefined values to least value
    item1 = item1 === null || item1 === undefined ? -Infinity : item1;
    item2 = item2 === null || item2 === undefined ? -Infinity : item2;

    if (sortHeader.includes('Date')) {
      const dateFormat = 'MM/DD/YY';

      item1 = dayjs(item1, dateFormat);
      item2 = dayjs(item2, dateFormat);

      if (!item1.isValid()) item1 = dayjs('01/01/1999', dateFormat);
      if (!item2.isValid()) item2 = dayjs('01/01/1999', dateFormat);

      return reverseSort ? item1 - item2 : item2 - item1;
    } else if (typeof item1 === 'string' && typeof item2 === 'string') {
      item1 = item1.toLowerCase().trim();
      item2 = item2.toLowerCase().trim();

      // drive all empty strings to the end of alphabet
      item1 = item1 === '' ? 'zzzzzzzzzzzzzz' : item1;
      item2 = item2 === '' ? 'zzzzzzzzzzzzzz' : item2;
    } else if (typeof item1 === 'string' && !Number.isFinite(item2)) {
      // drive all UTC invalid dates to oldest
      item2 = item1.match(
        /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}Z$/g
      )
        ? '0'
        : 'zzzzzzzzzzzzzz';
      item1 = item1.toLowerCase().trim();
    } else if (typeof item2 === 'string' && !Number.isFinite(item1)) {
      // drive all UTC invalid dates to oldest
      item1 = item2.match(
        /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}Z$/g
      )
        ? '0'
        : 'zzzzzzzzzzzzzz';
      item2 = item2.toLowerCase().trim();
    }

    if (item1 < item2) {
      return reverseSort ? 1 : -1;
    }
    if (item1 > item2) {
      return reverseSort ? -1 : 1;
    }
    return 0;
  });

export default defaultSort;
