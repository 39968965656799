import React from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import './DialogContainer.scss'

const portalRoot = document.getElementById('root-portal')

const Dialog = ({ children }) => createPortal(<div className="dialog-container">{children}</div>, portalRoot)

export default Dialog

Dialog.propTypes = {
  children: PropTypes.object.isRequired,
}
