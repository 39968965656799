import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { any, string, bool } from 'prop-types'

const PrivateRoute = ({
  component: Component,
  condition,
  redirectPath,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      condition ? (
        <Component {...props} {...rest} />
      ) : (
        <Redirect to={redirectPath} />
      )
    }
  />
)

export default PrivateRoute

PrivateRoute.propTypes = {
  component: any.isRequired,
  redirectPath: string.isRequired,
  condition: bool.isRequired,
}
