import React, { Component } from 'react'
import { func, object, bool, number } from 'prop-types'
import flowRight from 'lodash/flowRight'
import isEmpty from 'lodash/isEmpty'
import {
  KiteCard,
  KiteRadio,
  KiteLoader,
  KiteTooltip,
  KiteAlert,
} from '@kite/react-kite'
import { client } from '../../../../configuration/configApiClient'
import {
  LOG_ERROR,
  APPROVE_TECH_SETTINGS,
  SET_NEEDS_TECH_APPROVAL,
} from '../../../../shared/mutations'
import {
  formatLoggingError,
  scrollToNavigationPosition,
} from '../../../../shared/utilities'

import getExperimentActivation, {
  GET_EXPERIMENT_ACTIVATION,
} from './queries/getExperimentActivation'
import copyContent from '../../data/copyContent'
import './Activation.scss'
import updateExperimentActivationEvent from './mutations/updateActivationEvent'
import { ExpansionPanel } from '../../../../componentLibrary'
import yaml from 'js-yaml'
import { cloneDeep } from '@apollo/client/utilities'

export class Activation extends Component {
  static propTypes = {
    id: number.isRequired,
    onChange: func.isRequired,
    disabled: bool.isRequired,
    canApproveActivationEvent: bool.isRequired,
    experimentActivationData: object,
    updateExperimentActivationEvent: func,
    queryQuantum: func,
  }

  static defaultProps = {
    experimentActivationData: null,
    updateActivationEvent: null,
    updateExperimentActivationEvent: null,
    queryQuantum: null,
  }

  state = {
    selectedActivationEvent: null,
    selectedEventDetail: null,
    quantumActivationEvents: null,
    isLoadingQuantum: false,
    attemptedQuantumCount: 0,
    errorMessage: {
      queryQuantumError: null,
      quantumConnectionError: null,
      updateActivationError: null,
      activationEventUuid: null,
    },
    internalActivationEvents: false,
  }

  // LIFECYCLE METHODS
  async componentDidMount() {}

  async componentDidUpdate() {
    const { experimentActivationData, onChange } = this.props
    const { activationEvents, selectedActivationEvent } = this.state

    if (
      experimentActivationData &&
      experimentActivationData.experiment &&
      experimentActivationData.experiment.activationEvent &&
      !selectedActivationEvent
    ) {
      await this.handleSetActivationEvent()
    }

    if (
      experimentActivationData &&
      experimentActivationData.activationEvents &&
      !activationEvents
    ) {
      let enrichedActivationEvents = cloneDeep(
        experimentActivationData.activationEvents
      )
      let activationEventsById = {}

      enrichedActivationEvents.map(e => {
        return this.enrichActivationEvent(e)
      })

      enrichedActivationEvents.forEach(e => (activationEventsById[e.id] = e))

      enrichedActivationEvents.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      )

      let preAuthActivationEvents = enrichedActivationEvents.filter(ae =>
        ae.authClassification.includes('preAuth')
      )
      let postAuthActivationEvents = enrichedActivationEvents.filter(ae =>
        ae.authClassification.includes('postAuth')
      )
      if (enrichedActivationEvents && enrichedActivationEvents.length === 1) {
        onChange(true, true)
      }
      await this.setState({
        activationEvents: enrichedActivationEvents,
        activationEventsById: activationEventsById,
        sessionType:
          experimentActivationData.TdcsExperimentSettings.tdcsSessionType.name,
        preAuthActivationEvents: preAuthActivationEvents,
        postAuthActivationEvents: postAuthActivationEvents,
      })
    }

    if (selectedActivationEvent) {
      this.renderEventDetails()
    }
  }

  enrichActivationEvent(e) {
    let d = yaml.safeLoad(e.yaml)

    for (let k in d) {
      e.properties = d[k].properties
      e.description = d[k].description

      if (d[k].authClassification && d[k].authClassification.length > 0) {
        e.authClassification = d[k].authClassification
      }
    }
    return e
  }

  // REF METHODS
  getErrorMessage = () => {
    const { errorMessage } = this.state

    const messages = Object.values(errorMessage).reduce(
      (accumulator, error) => {
        if (!accumulator && error) {
          return `${error}`
        }
        if (error) {
          return `${accumulator}; ${error}`
        }
        return accumulator
      },
      ''
    )

    return messages
  }

  validateSubmission = async () => {
    const { selectedActivationEvent } = this.state
    let activationEventErrors = {}

    if (!selectedActivationEvent) {
      activationEventErrors = {
        noneSelected: 'No activation event selected.',
      }
    }

    if (!isEmpty(activationEventErrors)) {
      this.setState(({ errorMessage }) => ({
        errorMessage: {
          ...errorMessage,
          ...activationEventErrors,
        },
      }))

      if (activationEventErrors.noneSelected) {
        scrollToNavigationPosition()
      }

      return false
    }

    return true
  }

  isComplete = () => {
    const { id, canApproveActivationEvent } = this.props
    const { selectedActivationEvent } = this.state
    const experimentId = Number(id)

    if (!selectedActivationEvent) {
      return false
    }

    if (canApproveActivationEvent) {
      client.mutate({
        mutation: APPROVE_TECH_SETTINGS,
        variables: {
          experimentId,
        },
      })

      return true
    }

    client.mutate({
      mutation: SET_NEEDS_TECH_APPROVAL,
      variables: {
        experimentId,
      },
    })

    return false
  }

  // API METHODS
  handleSubmit = async () => {
    const { id, updateExperimentActivationEvent: updateActivation } = this.props

    const { selectedActivationEvent, errorMessage } = this.state
    const {
      activation: { updateActivationError },
    } = copyContent

    // Reset any previous submission errors
    if (errorMessage.updateActivationError) {
      this.setState({
        errorMessage: { ...errorMessage, updateActivationError: null },
      })
    }

    try {
      const input = {
        experimentId: Number(id),
        activationEventId: Number(selectedActivationEvent.id),
      }

      await updateActivation({
        variables: {
          input,
        },
        refetchQueries: [
          {
            query: GET_EXPERIMENT_ACTIVATION,
            variables: { id: Number(id) },
          },
        ],
      })

      return true
    } catch (error) {
      const input = formatLoggingError(error, { id })

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input,
        },
      })

      this.setState({
        errorMessage: {
          ...errorMessage,
          updateActivationError,
        },
      })

      return false
    }
  }

  // LOCAL STATE CHANGES/TOGGLES

  handleSetActivationEvent = async () => {
    const { experimentActivationData } = this.props

    const {
      experiment: { activationEvent },
    } = experimentActivationData

    const selectedActivationEvent = this.enrichActivationEvent(
      cloneDeep(activationEvent)
    )
    await this.setState({ selectedActivationEvent })
  }

  handleChange = ({ target: { value } }) => {
    const { onChange } = this.props

    const { activationEventsById } = this.state

    this.setState(({ errorMessage }) => ({
      selectedActivationEvent: activationEventsById[value],
      errorMessage: {
        ...errorMessage,
        noneSelected: null,
      },
    }))

    if (onChange) {
      onChange(true, true)
    }
  }

  renderProperties = properties => {
    let rows = []
    for (let key in properties) {
      let property = properties[key]
      rows.push(
        <div key={key}>
          <div className="event-details__subproperty-name">{key}</div>
          <div className="event-details__subproperty">
            Valid: {property.valid.join(', ')}
          </div>
        </div>
      )
    }
    return rows
  }

  renderEventDetails = () => {
    const { selectedActivationEvent } = this.state

    if (!selectedActivationEvent) {
      return (
        <div className="event-details">
          <KiteCard className="event-details__card">
            <div className="event-details__card-header">Event Details</div>
            <div className="event-details__content-empty">
              Please select an Activation Event to see its details
            </div>
          </KiteCard>
        </div>
      )
    }
    const properties = selectedActivationEvent.properties
    return (
      <div className="event-details">
        <KiteCard className="event-details__card">
          <div className="event-details__card-header">Event Details</div>
          <div>
            <div className="event-details__name-description">
              <div className="event-details__property-name">Name:</div>{' '}
              <div className="event-details__property">
                {selectedActivationEvent.name}
              </div>
              <div className="event-details__property-name">Description:</div>{' '}
              <div className="event-details__property">
                {selectedActivationEvent.description}
              </div>
            </div>
            Properties:
            {this.renderProperties(properties)}
          </div>
        </KiteCard>
      </div>
    )
  }

  render() {
    const {
      disabled,
      experimentActivationData: { loading },
    } = this.props

    const {
      selectedActivationEvent,
      errorMessage,
      activationEvents,
      sessionType,
      preAuthActivationEvents,
      postAuthActivationEvents,
    } = this.state

    let noneFound =
      !loading && (!activationEvents || activationEvents.length === 0)

    let errorAlert =
      Object.values(errorMessage).length > 0 ? this.getErrorMessage() : null
    let errorNoneSelected = null
    if (!errorAlert && noneFound) {
      errorAlert = copyContent.activation.noneFound
    } else if (errorAlert && errorMessage.noneSelected) {
      errorAlert = null
      errorNoneSelected = copyContent.activation.noneSelected
    }

    return (
      <div className="activation__container">
        <div className="activation">
          <div className="activation__header">
            <h3 className="activation__title">Set Your Activation Event</h3>
            <KiteTooltip
              ariaLabel="Activation Event Tooltip"
              ariaId="activationEventTooltip"
            >
              {copyContent.activation.tooltip}
            </KiteTooltip>
          </div>
          <div className="activation__description">
            {copyContent.activation.activationDescription}
          </div>

          <ExpansionPanel type="minimal" isExpanded={!!errorAlert}>
            <KiteAlert
              className="app__page-level-message"
              type="alert"
              description={errorAlert}
            />
          </ExpansionPanel>

          <ExpansionPanel type="minimal" isExpanded={!!errorNoneSelected}>
            <KiteAlert
              className="app__page-level-message"
              type="alert"
              description={errorNoneSelected}
            />
          </ExpansionPanel>

          <div className="activation-info">
            <KiteCard className="activation__card">
              <div className="activation__card-header">Pre-Auth</div>
              {loading && (
                <div className="app__loader">
                  <KiteLoader size="7rem" />
                </div>
              )}
              <div className="activation__card-content">
                {noneFound && (
                  <div className="activation__none-found">
                    {copyContent.activation.noneFound}
                  </div>
                )}

                {preAuthActivationEvents &&
                  preAuthActivationEvents.length === 0 && (
                    <div className="activation__content-empty">
                      {copyContent.activation.noneFoundPreAuth}
                    </div>
                  )}

                {activationEvents &&
                  preAuthActivationEvents &&
                  preAuthActivationEvents.length > 0 && (
                    <div className="activation__radio-buttons">
                      <KiteRadio
                        name="preauthActivationEvents"
                        tooltip={copyContent.activation.tooltip}
                        onChange={this.handleChange}
                        disabled={disabled || sessionType.startsWith('AUTH')}
                        buttonOrientation="column"
                        radioButtons={preAuthActivationEvents.map(event => {
                          const ret = {
                            id: event.id,
                            value: event.id,
                            label: event.name,
                            checked:
                              selectedActivationEvent &&
                              event.id === selectedActivationEvent.id,
                          }
                          return ret
                        })}
                      />
                    </div>
                  )}
              </div>
              <div className="activation__card-header">Post-Auth</div>
              {loading && (
                <div className="app__loader">
                  <KiteLoader size="7rem" />
                </div>
              )}
              <div className="activation__card-content">
                {noneFound && (
                  <div className="activation__none-found">
                    {copyContent.activation.noneFound}
                  </div>
                )}

                {postAuthActivationEvents &&
                  postAuthActivationEvents.length === 0 && (
                    <div className="activation__content-empty">
                      {copyContent.activation.noneFoundPostAuth}
                    </div>
                  )}

                {activationEvents &&
                  postAuthActivationEvents &&
                  postAuthActivationEvents.length > 0 && (
                    <div className="activation__radio-buttons">
                      <KiteRadio
                        name="postAuthActivationEvents"
                        tooltip={copyContent.activation.tooltip}
                        onChange={this.handleChange}
                        disabled={disabled || !sessionType.startsWith('AUTH')}
                        buttonOrientation="column"
                        radioButtons={postAuthActivationEvents.map(event => {
                          const ret = {
                            id: event.id,
                            value: event.id,
                            label: event.name,
                            checked:
                              selectedActivationEvent &&
                              event.id === selectedActivationEvent.id,
                          }
                          return ret
                        })}
                      />
                    </div>
                  )}
              </div>
            </KiteCard>
          </div>
        </div>

        <div className="event-details">
          {!errorAlert && this.renderEventDetails()}
        </div>
      </div>
    )
  }
}

export default flowRight(
  getExperimentActivation,
  updateExperimentActivationEvent
)(Activation)
