import React, { Component } from 'react'
import { object } from 'prop-types'
import flowRight from 'lodash/flowRight'
import {
  KiteLoader,
  KiteIcon,
  KiteFontIcon,
  KiteTooltip,
  KiteButton,
} from '@kite/react-kite'
import dayjs from 'dayjs'
import AdvancedFormat from 'dayjs/plugin/advancedFormat'
import { experimentStatusEnumReverse } from '@charter/distillery-rules'

import copyContent from '../../data/copyContent'
import { GqlBuilder } from '../../../../shared/utilities'
import './TdcsStatus.scss'
import getTdcsPublishHistoryList from "./queries/getTdcsPublishHistoryList";
import {client} from "../../../../configuration/configApiClient";
import {GET_TDCS_PUBLISH_HISTORY} from "./queries/getTdcsPublishHistory";

dayjs.extend(AdvancedFormat)

const gqlHOCQuery = new GqlBuilder().compileHOCQuery([getTdcsPublishHistoryList])

export class TdcsStatus extends Component {
  static propTypes = {
    queryData: object.isRequired,
    selectedEnvironment: object.isRequired,
  }

  state = {
    config: null,
    configLoading: null
  }

  // LIFECYCLE METHODS
  async componentDidMount() {
    const {
      queryData: { publishHistoryListForExperimentAndEnvironment, refetch },
    } = this.props

    // If re-mounting want to refetch data and re-render
    if (
        publishHistoryListForExperimentAndEnvironment &&
      !publishHistoryListForExperimentAndEnvironment.loading
    ) {
      await refetch()
    }
  }

  async componentDidUpdate(prevProps) {
    const { selectedEnvironment } = this.props
    const { selectedEnvironment: prevEnvironment } = prevProps

    if (selectedEnvironment.id !== prevEnvironment.id) {
      this.handleSetConfig(null)
    }
  }


  async handleSetConfig(statusId) {
    this.setState( { configLoading: statusId } )
    let tdcsConfig
    try {
      const response = await client.query({
        query: GET_TDCS_PUBLISH_HISTORY,
        variables: {pid: statusId},
        fetchPolicy: "network-only"
      })
      tdcsConfig = response?.data?.publishHistoryForId[0]?.tdcsConfig
    } catch (e) {
      tdcsConfig = JSON.stringify({error: 'fetchError'})
    }
    await this.setState({ config: tdcsConfig, configLoading: null })
  }

  // RENDER METHODS
  renderPublishHistory() {
    const {
      queryData: { publishHistoryListForExperimentAndEnvironment },
    } = this.props

    const columns = [
      {
        header: true,
        publishStatus: {
          displayName: <h4>Publish Status</h4>,
        },
        tooltip: <h4>More information about the publish status</h4>,
        createdTime: <h4>Date</h4>,
        publishedBy: <h4>Published By</h4>,
        tdcsConfig: <h4>Configuration</h4>,
        experimentStatusId: <h4>Experiment Status</h4>,
        isExperimentReset: false,
      },
      ...publishHistoryListForExperimentAndEnvironment,
    ]

    const historyTable = columns.map(
      ({
          id,
        header,
        tooltip,
        publishStatus: { id: publishId, displayName: publishDisplayName },
        tdcsConfig,
        experimentStatusId,
        isExperimentReset,
        createdTime,
        publishedBy,
        createdUser,
      }) => {
        const statusId = id
        const convertedDate = new Date(Number(createdTime))
        const createdDate = header
          ? createdTime
          : dayjs(convertedDate).format('MM/DD/YYYY')
        const experimentStatus = experimentStatusEnumReverse[experimentStatusId]
        let historyColumnClassName = 'tdcs-status__publish-history-header'
        let experimentStatusColor
        const timeLabel = header
          ? ''
          : convertedDate.toLocaleTimeString('en-US')
        const status = header
          ? experimentStatusId
          : experimentStatusEnumReverse[experimentStatusId]
        let getConfigBtn = tdcsConfig
        let publishStatColor
        let publishStatIcon

        if (publishId === 1 || publishId === 3) {
          publishStatIcon = 'checkmark'
          publishStatColor = 'green'
        } else if (publishId === 4 || publishId === 5) {
          publishStatIcon = 'x-circle'
          publishStatColor = '#eb4b47'
        } else if (publishId === 2) {
          publishStatIcon = 'caution-alert-f'
          publishStatColor = '#ffc107'
        }

        if (!header) {
          historyColumnClassName = 'tdcs-status__publish-history-item'
          getConfigBtn =
              <KiteButton
                  type="outline"
                  loading={ this.state.configLoading === statusId }
                  onClick={() => {
                    this.handleSetConfig(statusId)
                  }}
              > Get Config
              </KiteButton>

        }

        if (experimentStatus === 'Running') {
          experimentStatusColor = 'green'
        } else if (experimentStatus === 'Cancelled') {
          experimentStatusColor = 'red'
        }

        return (
          <div key={`${createdTime}`} className={historyColumnClassName}>
            {header ? (
              <div className="tdcs-status__history-display-name">
                <span>{publishDisplayName}</span>
                <KiteTooltip isLight={false}>{tooltip}</KiteTooltip>
              </div>
            ) : (
              <div className="allocation-history__reset-row tdcs-status__experiment-reset">
                <KiteIcon
                  name={publishStatIcon}
                  size="20px"
                  color={publishStatColor}
                />
                <div className="tdcs-status__display-name">
                  {publishDisplayName}
                </div>
              </div>
            )}
            <div className="tdcs-status__date-display">
              {createdDate}
              <span>{timeLabel}</span>
            </div>
            <div className="tdcs-status__published-by">
              {createdUser && createdUser.displayName}

              <span>{publishedBy}</span>
            </div>
            <div className="tdcs-status__config-btn">{getConfigBtn}</div>
            <div
              className="tdcs-status__experiment-status"
              style={{ color: experimentStatusColor }}
            >
              <span className="tdcs-status__status">{status}</span>
              {isExperimentReset ? (
                <div className="tdcs-status__is-reset">
                  <KiteIcon
                    name="caution-alert-f"
                    size="16px"
                    color="#eb4b47"
                  />
                  Experiment Reset
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        )
      }
    )

    return <div className="tdcs-status__table-container">{historyTable}</div>
  }

  render() {
    const {
      queryData: {
        loading: dataLoading,
        networkStatus,
        publishHistoryListForExperimentAndEnvironment,
      },
      selectedEnvironment,
      selectedEnvironment: { tdcsUpdateRequired },
    } = this.props

    const { config } = this.state

    const {
      tdcsStatus: { tdcsSyncStatus, noConfigSelected, noConfigAvaliable, noPublishHistory },
    } = copyContent

    if (dataLoading || networkStatus === 4) {
      return (
        <div className="app__loader">
          <KiteLoader size="7rem" />
        </div>
      )
    }

    let parsedConfig = null
    let configError = false
    if (config) {
      try {
        parsedConfig = JSON.parse(config)
        // Error message sent from config DB fetch operation
        if(parsedConfig.error) {
          configError =  true
          parsedConfig = null
        }
      } catch (error) {
        // eslint-disable-next-line no-empty
      }
    }

    // Verify the last attempt to sync status with TDCS
    const currentPublishStatus = publishHistoryListForExperimentAndEnvironment.length
      ? publishHistoryListForExperimentAndEnvironment[0].publishStatus.id
      : 5
    const needsToBeSynced =
      tdcsUpdateRequired ||
      currentPublishStatus === 4 ||
      currentPublishStatus === 5

    const syncStatus = needsToBeSynced ? 'x-f' : 'checkmark-f'
    const syncStatusColor = needsToBeSynced ? 'red' : 'green'

    return (
      <div className="tdcs-status">
        <div className="tdcs-status__heading">
          <h3>Publication History</h3>
          <span className="tdcs-status__heading-status">
            {tdcsSyncStatus}
            <KiteFontIcon name={syncStatus} color={syncStatusColor} />
          </span>
        </div>
        <div className="tdcs-status__publish-container">
          <div className="rollout-experiment__accent-color-container">
            <div
              className="rollout-experiment__accent-color"
              style={{
                backgroundColor: `${selectedEnvironment.color}`,
              }}
            />
            <div className="rollout-experiment__accent-color-children tdcs-status__accent-color-children">
              <div className="tdcs-status__publish-history">
                {!publishHistoryListForExperimentAndEnvironment.length ? (
                  <div className="tdcs-status__no-data">{noPublishHistory}</div>
                ) : (
                  this.renderPublishHistory()
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="tdcs-status__heading">
          <h3>Config View</h3>
        </div>
        <div className="tdcs-status__config-container">
          <div className="rollout-experiment__accent-color-container">
            <div
              className="rollout-experiment__accent-color"
              style={{
                backgroundColor: `${selectedEnvironment.color}`,
              }}
            />
            <div className="rollout-experiment__accent-color-children">
              {configError ? (
                  <div className="tdcs-status__no-data">{noConfigAvaliable}</div>
              ) : (!config ? (
                  <div className="tdcs-status__no-data">{noConfigSelected}</div>
              ) : (
                  <div className="tdcs-status__config-display">
                  <pre className="tdcs-status__config">
                    {JSON.stringify(parsedConfig, null, 4)}
                  </pre>
                  </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default flowRight(gqlHOCQuery)(TdcsStatus)
