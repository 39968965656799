const getNestedValue = (data, keys) => {
  let val = data;

  for (let i = 0; i < keys.length; i++) {
    if (val[keys[i]]) {
      val = val[keys[i]];
    } else {
      return null;
    }
  }

  return val;
};

export default getNestedValue;
