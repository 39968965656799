import React, { Component, Fragment } from 'react'
import { object, bool, func, string } from 'prop-types'
import dayjs from 'dayjs'
import flowRight from 'lodash/flowRight'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'

import { KiteLoader, KiteInput, KiteRadio } from '@kite/react-kite'
import { BasicPills, AutoSuggest, TextArea } from '@kite/react-kite-plus'
import {
  roleEnum,
  metricTypeEnum,
  createQuantumApolloClient,
} from '@charter/distillery-rules'
import copyContent from './data/copyContent'

import { formatLoggingError, GqlBuilder } from '../../../../shared/utilities'
import { client } from '../../../../configuration/configApiClient'
import { LOG_ERROR } from '../../../../shared/mutations'
import getPortfolioCategories from './queries/getPortfolioCategories'
import { GET_USERS_GQLBUILDER } from '../../../../shared/queries'
import GET_QUANTUM_METRIC_DEFINITIONS from './queries/getQuantumMetrics'

import './General.scss'

const gqlHOCQuery = new GqlBuilder().compileHOCQuery([
  GET_USERS_GQLBUILDER,
  getPortfolioCategories,
])

const metricTypes = {
  SUCCESS: 1,
  SAFETY: 2,
}

export class General extends Component {
  static propTypes = {
    experimentEvaluationCard: object.isRequired,
    queryData: object.isRequired,
    quantumEvaluator: bool.isRequired,
    onChange: func.isRequired,
    retrieveQuantumToken: string.isRequired,
    newInputs: object,
    isDisabled: bool,
  }

  static defaultProps = {
    newInputs: {
      rolesAndUsers: [],
      ids: [],
      businessOutcome: null,
      adHocEvaluation: null,
      adHocComments: null,
    },
    isDisabled: false,
  }

  state = {
    adHocEvaluator: '',
    portfolioCategoryName: '',
    portfolioCategory: null,
    adHocEvaluatorsById: {},
    businessOutcome: '',
    adHocEvaluation: false,
    adHocComments: '',
    metricDefinitions: [],
    isLoadingResults: true,
  }

  static defaultProps = {
    disabled: false,
  }

  componentDidMount() {
    const {
      queryData: { loading, users, portfolioCategory },
    } = this.props
    if (!loading && users && portfolioCategory) {
      this.handleSetEvaluation()
    }
  }

  componentDidUpdate(prevProps) {
    const {
      queryData: { users, portfolioCategory },
      queryData,
      experimentEvaluationCard,
    } = this.props
    const {
      queryData: prevQueryData,
      experimentEvaluationCard: prevData,
    } = prevProps

    if (
      (users && portfolioCategory && !isEqual(prevQueryData, queryData)) ||
      !isEqual(experimentEvaluationCard, prevData)
    ) {
      this.handleSetEvaluation()
    }
  }

  // LOCAL STATE CHANGES/TOGGLES
  handleSetEvaluation = async () => {
    const {
      experimentEvaluationCard: {
        businessOutcome,
        adHocEvaluation,
        adHocComments,
        experiment: { experimentToUsers },
      },
      newInputs: {
        rolesAndUsers,
        ids,
        portfolioCategoryId,
        businessOutcome: inputtedBusinessOutcome,
        adHocEvaluation: inputtedAdHocEvaluation,
        adHocComments: inputtedAdHocComments,
      },
      queryData: { users, portfolioCategory },
    } = this.props
    const evaluators = []
    const adHocEvaluatorsUsers = {}
    const portfolioCat = portfolioCategory.find(
      ({ id }) => id === portfolioCategoryId
    )
    let updatedAdHocEval = adHocEvaluation
    if (typeof inputtedAdHocEvaluation === 'boolean') {
      updatedAdHocEval = inputtedAdHocEvaluation
    }

    // Grab the evaluator of experiment
    experimentToUsers
      .filter(({ roleId }) => roleId === roleEnum.EXPERIMENT_EVALUATOR)
      .forEach(({ user: { firstName, lastName } }) => {
        evaluators.push(`${lastName}, ${firstName}`)
      })

    // Grab all ad hoc evaluators of experiment
    experimentToUsers
      .filter(({ roleId }) => roleId === roleEnum.AD_HOC_EXPERIMENT_EVALUATOR)
      .forEach(
        ({ id: experimentUserId, user: { id, firstName, lastName } }) => {
          if (!ids.includes(experimentUserId)) {
            adHocEvaluatorsUsers[id] = `${lastName}, ${firstName}`
          }
        }
      )

    if (users) {
      rolesAndUsers.forEach(({ roleID, userID }) => {
        const newUser = users.find(({ id }) => id === userID)
        if (roleID === roleEnum.AD_HOC_EXPERIMENT_EVALUATOR) {
          adHocEvaluatorsUsers[userID] = newUser.displayName
        }
      })
    }

    await this.setState(({ adHocEvaluatorsById }) => ({
      evaluators,
      portfolioCategory: portfolioCat,
      adHocEvaluatorsById: {
        ...adHocEvaluatorsById,
        ...adHocEvaluatorsUsers,
      },
      businessOutcome: inputtedBusinessOutcome || businessOutcome || '',
      adHocEvaluation: updatedAdHocEval,
      adHocComments: inputtedAdHocComments || adHocComments || '',
    }))

    await this.queryQuantum()
  }

  // API METHODS
  handleSubmit = () => true

  queryQuantum = async () => {
    const { retrieveQuantumToken: quantumToken } = this.props

    try {
      const quantumMetricsApiClient = createQuantumApolloClient({
        quantumToken,
      })

      const quantumQuery = quantumMetricsApiClient.query({
        query: GET_QUANTUM_METRIC_DEFINITIONS,
      })

      const response = await quantumQuery

      const {
        data: { metricDefinitions },
      } = response

      await this.setState({
        metricDefinitions,
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)

      const input = formatLoggingError(error)
      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input,
        },
      })
    } finally {
      this.setState({ isLoadingResults: false })
    }
  }

  handleChange = async element => {
    const { onChange } = this.props
    const { adHocEvaluatorsById } = this.state
    const {
      target: { name, value },
    } = element

    if (name === 'adHocEvaluation') {
      const updateValue = value === 'Yes'

      await this.setState(({ adHocComments }) => ({
        [name]: updateValue,
        adHocComments: updateValue ? adHocComments : '',
        adHocEvaluatorsById: {},
      }))
      await onChange(updateValue, name)
      await onChange('', 'adHocComments')
      Object.keys(adHocEvaluatorsById).forEach(async key => {
        await onChange(key, 'deleteUser')
      })
    } else {
      await this.setState({
        [name]: value,
      })

      if (
        name !== 'portfolioUsersById' &&
        name !== 'adHocEvaluatorsById' &&
        name !== 'portfolioCategoryName' &&
        name !== 'adHocEvaluator'
      ) {
        await onChange(value, name)
      }
    }
  }

  handlePick = async (value, data) => {
    const { onChange } = this.props
    if (value === roleEnum.AD_HOC_EXPERIMENT_EVALUATOR) {
      await this.setState(({ adHocEvaluatorsById }) => ({
        adHocEvaluatorsById: {
          ...adHocEvaluatorsById,
          [data.id]: data.displayName,
        },
        adHocEvaluator: '',
      }))

      const newUserInput = {
        roleID: value,
        userID: data.id,
      }

      await onChange(newUserInput, 'newUser')
    } else if (value === 'portfolioCategory') {
      await this.setState({
        portfolioCategory: {
          ...data,
        },
        portfolioCategoryName: '',
      })

      await onChange(data.id, 'portfolioCategoryId')
    }
  }

  handleRemovePick = async (userToRemove, roleId) => {
    const { onChange } = this.props
    const { adHocEvaluatorsById } = this.state
    let idToRemove
    let newUsersById
    let name

    if (roleId !== 'portfolioCategory') {
      if (roleId === roleEnum.AD_HOC_EXPERIMENT_EVALUATOR) {
        Object.keys(adHocEvaluatorsById).forEach(key => {
          if (adHocEvaluatorsById[key] === userToRemove) {
            idToRemove = key
          }
        })

        newUsersById = cloneDeep(adHocEvaluatorsById)
        name = 'adHocEvaluatorsById'
      }

      if (newUsersById[idToRemove]) {
        delete newUsersById[idToRemove]
      }

      await this.setState({
        [name]: newUsersById,
      })

      await onChange(idToRemove, 'deleteUser')
    } else {
      await this.setState({
        portfolioCategory: null,
      })

      await onChange(null, 'portfolioCategoryId')
    }
  }

  // RENDER METHODS
  renderEvaluationHistory = () => {
    const {
      experimentEvaluationCard: { experimentEvaluationAudits },
    } = this.props

    return (
      <div className="general__experiment-history-table">
        <div className="general__experiment-history-headers">
          <span>Date/time</span>
          <span>Person</span>
          <span className="general__change-made-header">Changes made</span>
        </div>
        {experimentEvaluationAudits
          .filter(({ changedFieldsJson }) => changedFieldsJson !== null)
          .map(
            ({
              createdTime,
              changedComment,
              createdUser: { firstName, lastName },
            }) => {
              const dayStamp = dayjs(Number(createdTime)).format('M/DD/YY')
              const timeStamp = dayjs(Number(createdTime)).format('h:mm A')

              return (
                <div
                  className="general__experiment-history-items"
                  key={`changedField-${createdTime}`}
                >
                  <div>{`${dayStamp} ${timeStamp}`}</div>
                  <div className="general__history-username">
                    {`${firstName} ${lastName}`}
                  </div>
                  <div className="general__changes-made">
                    {changedComment?.length > 0
                      ? changedComment
                      : 'No comments added.'}
                  </div>
                </div>
              )
            }
          )}
      </div>
    )
  }

  render() {
    const {
      queryData: { loading, users, portfolioCategory: portfolioCategories },
      experimentEvaluationCard: {
        experiment: { experimentToProductFeatures, metrics, experimentMetrics },
      },
      quantumEvaluator,
      isDisabled,
    } = this.props
    const {
      portfolioCategoryName,
      portfolioCategory,
      evaluators,
      adHocEvaluator,
      adHocEvaluatorsById,
      businessOutcome,
      adHocEvaluation,
      adHocComments,
      isLoadingResults,
      metricDefinitions,
    } = this.state
    const {
      product: { displayName: productName },
      productFeature: { displayName: featureName },
    } = experimentToProductFeatures[0]

    if (loading || isLoadingResults) {
      return (
        <div className="app__loader">
          <KiteLoader size="7rem" />
        </div>
      )
    }

    const adHocEvaluators = Object.values(adHocEvaluatorsById).map(name => name)
    let filteredSuccessMetrics = []
    let filteredSafteyMetrics = []
    if (
      ((experimentMetrics && experimentMetrics.length === 0) ||
        !experimentMetrics) &&
      metrics
    ) {
      filteredSuccessMetrics = metrics
        .filter(
          ({ isEnabled, metricTypeId }) =>
            isEnabled && metricTypeId === metricTypeEnum.SUCCESS
        )
        .map(({ displayName }) => displayName)
      filteredSafteyMetrics = metrics
        .filter(
          ({ isEnabled, metricTypeId }) =>
            isEnabled && metricTypeId === metricTypeEnum.SAFETY
        )
        .map(({ displayName }) => displayName)
    } else {
      filteredSuccessMetrics = metricDefinitions
        .filter(({ _id: uuid }) => {
          const metric = experimentMetrics.find(
            ({ metricTypeId, metricUuid }) =>
              uuid === metricUuid && metricTypeId === metricTypes.SUCCESS
          )
          if (metric) {
            return metric
          }
          return false
        })
        .map(({ displayName }) => displayName)
      filteredSafteyMetrics = metricDefinitions
        .filter(({ _id: uuid }) => {
          const metric = experimentMetrics.find(
            ({ metricTypeId, metricUuid }) =>
              uuid === metricUuid && metricTypeId === metricTypes.SAFETY
          )
          if (metric) {
            return metric
          }
          return false
        })
        .map(({ displayName }) => displayName)
    }

    return (
      <div className="general">
        <div className="general__evaluators">
          {evaluators.length > 0 ? (
            <Fragment>
              <span>Evaluator(s)</span>
              <div className="general__evaluator-pills">
                <BasicPills
                  className="general__evaluators-names"
                  values={evaluators.length ? evaluators : []}
                />
              </div>
            </Fragment>
          ) : (
            <span className="general__no-evaluators">
              Evaluation not yet started.
            </span>
          )}
        </div>
        <div className="general__portfolio-product-container">
          <div className="general__portfolio-input-conatiner">
            {quantumEvaluator && (
              <Fragment>
                <span>
                  Portfolio
                  <AutoSuggest
                    className="general__portfolio-hint-text"
                    id="portfolioCategoryName"
                    value={portfolioCategoryName}
                    name="portfolioCategoryName"
                    showExpandButton
                    startsWith={false}
                    initialData={portfolioCategories}
                    suggestionKey="displayName"
                    disabled={isDisabled}
                    onChange={this.handleChange}
                    onSuggestionSelected={(event, data) => {
                      event.preventDefault()
                      this.handlePick('portfolioCategory', data.suggestion)
                    }}
                    placeholder="Select a portfolio"
                  />
                </span>
                <BasicPills
                  className="general__portfolio-category"
                  values={
                    portfolioCategory ? [portfolioCategory.displayName] : []
                  }
                  onRemovePill={
                    !quantumEvaluator || isDisabled
                      ? null
                      : categoryToRemove => {
                          this.handleRemovePick(
                            categoryToRemove,
                            'portfolioCategory'
                          )
                        }
                  }
                />
              </Fragment>
            )}

            {!quantumEvaluator && (
              <span className="general__portfolio-category-no-suggestion">
                Portfolio
                <BasicPills
                  className="general__portfolio-category-no-suggestion-cat"
                  values={
                    portfolioCategory ? [portfolioCategory.displayName] : []
                  }
                  onRemovePill={
                    !quantumEvaluator || isDisabled
                      ? null
                      : categoryToRemove => {
                          this.handleRemovePick(
                            categoryToRemove,
                            'portfolioCategory'
                          )
                        }
                  }
                />
              </span>
            )}
          </div>
          <div className="general__product-feature-container">
            <div className="general__product">
              <span>Product</span>
              <BasicPills
                className="general__evaluators-names"
                values={[productName]}
              />
            </div>
            <div className="general__feature">
              <span>Feature</span>
              <BasicPills
                className="general__evaluators-names"
                values={[featureName]}
              />
            </div>
          </div>
        </div>
        <div className="general__success-metric">
          <span>Success Metric</span>
          <BasicPills
            className="general__metric-names"
            values={filteredSuccessMetrics}
          />
        </div>
        <div className="general__secondary-metrics">
          <span>Secondary Metric(s)</span>
          <BasicPills
            className="general__metric-names"
            values={filteredSafteyMetrics}
          />
        </div>
        {quantumEvaluator && (
          <Fragment>
            <div>
              <KiteInput
                id="businessOutcome"
                label="Business Outcome"
                margin=""
                maxWidth=""
                disabled={isDisabled}
                name="businessOutcome"
                onChange={this.handleChange}
                placeholder="Record related business outcome"
                value={businessOutcome}
                type="text"
              />
            </div>
            <hr className="general__line-divider" />
            <div className="general__ad-hoc-users">
              <KiteRadio
                buttonOrientation="row"
                disabled={isDisabled}
                groupLabel="Was an ad-hoc analysis performed for this experiment?"
                margin=""
                name="adHocEvaluation"
                onChange={this.handleChange}
                radioButtons={[
                  {
                    id: 'yes',
                    label: 'Yes',
                    checked: adHocEvaluation,
                  },
                  {
                    id: 'no',
                    label: 'No',
                    value: false,
                    checked: !adHocEvaluation,
                  },
                ]}
              />
              {adHocEvaluation ? (
                <div className="general__ad-hoc-users_container">
                  <AutoSuggest
                    id="adHocEvaluator"
                    value={adHocEvaluator}
                    disabled={!adHocEvaluation || isDisabled}
                    className="general__ad-hoc-input"
                    label="If so, by whom?"
                    name="adHocEvaluator"
                    showExpandButton
                    startsWith={false}
                    initialData={users}
                    suggestionKey="displayName"
                    onChange={this.handleChange}
                    onSuggestionSelected={(event, data) => {
                      event.preventDefault()
                      this.handlePick(
                        roleEnum.AD_HOC_EXPERIMENT_EVALUATOR,
                        data.suggestion
                      )
                    }}
                    placeholder="Select a team member"
                  />
                  <BasicPills
                    className="general__ad-hoc-evaluators-names"
                    values={adHocEvaluators.length ? adHocEvaluators : []}
                    onRemovePill={
                      !quantumEvaluator || isDisabled
                        ? null
                        : userToRemove => {
                            this.handleRemovePick(
                              userToRemove,
                              roleEnum.AD_HOC_EXPERIMENT_EVALUATOR
                            )
                          }
                    }
                  />
                </div>
              ) : null}
            </div>
            {adHocEvaluation ? (
              <TextArea
                name="adHocComments"
                label="Ad-Hoc Analysis Comments:"
                value={adHocComments || ''}
                disabled={isDisabled}
                onChange={this.handleChange}
                placeholder="Record any additional comments or info related to the ad-hoc-analaysis performed (optional)."
                height="4.5rem"
                width="60rem"
                maxLength="1000"
              />
            ) : null}
          </Fragment>
        )}

        {!quantumEvaluator && (
          <div>
            <div className="general__header-questions">
              {copyContent.businessOutcome}
            </div>
            <div>{businessOutcome}</div>
            <hr className="general__line-divider" />
            <div className="general__header-questions">
              {copyContent.adHocEvaluation}
            </div>
            <div>
              {/* To do add the ad hoc evaluators here */}
              {adHocEvaluation ? `Yes, by ${adHocEvaluators.join(',')}` : 'No'}
            </div>
            {adHocEvaluation && (
              <Fragment>
                <div className="general__header-questions">
                  Ad-Hoc Analysis Comments:
                </div>
                <div>{adHocComments}</div>
              </Fragment>
            )}
          </div>
        )}
        <div className="general__evaluation-history">
          <hr className="general__line-divider" />
          <span>Evaluation history:</span>
          {this.renderEvaluationHistory()}
        </div>
      </div>
    )
  }
}

export default flowRight(gqlHOCQuery)(General)
