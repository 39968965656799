import {
  decorate,
  observable,
  computed,
  action,
  reaction,
  transaction,
} from 'mobx'

import appModel from '../../components/App/AppModel'

import { gql } from '@apollo/client'
import { overrideTypeEnum } from '@charter/distillery-rules/lib/enums'
import EditorModel from '../../shared/models/EditorModel'
import ORGroupEditorModel from './ORGroupEditorModel'
import ORGuidLookupModel from './ORGuidLookupModel'
import { client } from '../../configuration/configApiClient'

class OREditorModel extends EditorModel {
  start() {
    reaction(
      () => {
        return this.groupIds.map(id => {
          return appModel.getEditorModel({
            id,
            EditorModelClass: ORGroupEditorModel,
            apiTime: this.apiTime,
          })
        })
      },
      objects => {
        this.groups.replace(objects)
      }
    )
  }

  startEditing() {
    if (this.id) {
      this.loadQuickView()
    }

    super.startEditing()
  }

  showLookupModal() {
    this.shouldShowLookupModal = true
    this.accountGuid = ''
    this.createNewLookup()
  }

  createNewLookup() {
    this.guidLookup?.disposer && this.guidLookup.disposer()
    this.guidLookup = new ORGuidLookupModel()
  }

  async performGuidLookup() {
    await this.guidLookup.lookupOverride(this)
    if (this.guidLookup.resultsFound?.length && this.type === 2) {
      const { accountGuid } = this.guidLookup.resultsFound[0]
      transaction(() => {
        this.accountGuid = accountGuid
        this.shouldShowLookupModal = false
      })
    }
  }

  id = null
  type = 2
  accountGuid = ''
  clientGuid = ''
  name = ''
  productId = ''
  productDisplayName = ''
  groupSearchValue = ''
  groupIds = []
  groups = []
  shouldShowLookupModal = false
  accountName = null
  billingID = null
  divisionID = null
  accountNumber = null
  accountType = null
  guidLookup = null
  appliedInExperiments = []
  hasCheckedAppliedInExperiments = false
  inProgressCheckAppliedInExperiments = null

  updateFromAPI({ apiObject }) {
    if (!apiObject) return

    const {
      id,
      name,
      overrideType,
      overrideIdentifier,
      products,
      overrideGroups,
    } = apiObject

    this.id = id
    this.name = name

    if (overrideType) {
      this.type = Number(overrideType.id)
    }

    if (overrideIdentifier) {
      if (this.type === 3 && overrideIdentifier.includes('_')) {
        const parts = overrideIdentifier.split('_')
        this.accountGuid = parts[0]
        this.clientGuid = parts[1]
      } else if (this.type === 1) {
        this.clientGuid = overrideIdentifier
      } else if (this.type === 2) {
        this.accountGuid = overrideIdentifier
      }
    }

    if (products && products.length && [1, 3].includes(this.type)) {
      this.productId = Number(products[0].id)
      this.productDisplayName = products[0].displayName
    }

    if (overrideGroups && overrideGroups.length)
      this.groupIds.replace(overrideGroups.map(({ id }) => id))
  }

  toAPI = () => {
    const input = {
      name: this.name,
      overrideTypeId: this.type,
      overrideIdentifier: this.identifier,
      overrideGroupIds: this.groupIds.slice(),
    }

    if (this.requiresProduct && this.productId) {
      input.productIds = [Number(this.productId)]
    }

    if (this.id) {
      input.overrideId = this.id
    }

    return input
  }

  get key() {
    return this.id
  }

  get kiteAlert() {
    return this.apiDuplicateError
      ? {
          type: 'alert',
          description:
            'This is a duplicate of an override in Distillery.  Please enter a unique accountGuid and unique name.',
        }
      : super.standardKiteAlerts()
  }

  async checkAppliedInExperiments(force = false) {
    if (this.hasCheckedAppliedInExperiments && !force) return
    this.hasCheckedAppliedInExperiments = true

    try {
      this.inProgressCheckAppliedInExperiments = client.query({
        query: OREditorModel.appliedInExperimentsQuery,
        variables: { overrideId: this.id },
      })
      const res = await this.inProgressCheckAppliedInExperiments
      if (res.data.override?.appliedInExperiments) {
        const converted = []
        res.data.override.appliedInExperiments.forEach(exp => {
          if (!exp) return
          converted.push({
            url: `/experiments/${exp.id}/set-up/plan`,
            ...exp,
          })
        })
        this.appliedInExperiments.replace(converted)
      }
    } finally {
      this.inProgressCheckAppliedInExperiments = null
    }
  }

  updateCurrentlyViewing() {
    appModel.updateCurrentlyViewing(
      {
        title: 'Override Management',
        backPath: '/experiments/all',
        backTitle: 'All Experiments',
      },
      true
    )
  }

  get identifier() {
    return OREditorModel.formatIdentifier({
      overrideTypeId: this.type,
      accountGuid: this.accountGuid,
      clientGuid: this.clientGuid,
    })
  }

  static formatIdentifier({ overrideTypeId, accountGuid, clientGuid }) {
    return overrideTypeId === 3
      ? `${accountGuid}_${clientGuid}`
      : overrideTypeId === 2
      ? accountGuid
      : clientGuid
  }

  get displayName() {
    return `${this.name} (${this.identifier})`
  }

  get identifierErrorMessage() {
    if (this.apiDuplicateError) return 'This override already exists'
    return this.isValidIdentifier ? null : 'Invalid guid'
  }

  get updateMutation() {
    return OREditorModel.updateMutation
  }

  get insertMutation() {
    return OREditorModel.insertMutation
  }

  get deleteMutation() {
    return OREditorModel.deleteMutation
  }

  get deleteMutationVariables() {
    return { overrideId: this.id }
  }

  get errorMessages() {
    const { name } = this
    return {
      name:
        name.length > 3 && name.length < 50
          ? null
          : 'Enter a name longer than 3 chars and less than 50',
      accountGuid: this.type === 2 ? this.identifierErrorMessage : null,
      clientGuid: [1, 3].includes(this.type)
        ? ORGuidLookupModel.isValidGuid(this.clientGuid)
          ? null
          : 'Invalid guid'
        : null,
      product:
        !this.requiresProduct || (this.requiresProduct && this.productId)
          ? null
          : 'Product required',
    }
  }

  get apiDuplicateError() {
    if (this.guidLookup?.resultsWithOverride?.length) {
      const { overrideId } = this.guidLookup.resultsWithOverride[0]
      if (overrideId !== this.id) {
        return true
      }
    }
    return (
      this.apiError?.message &&
      this.apiError.message.indexOf('Duplicate entry') !== -1
    )
  }

  get isValidIdentifier() {
    return ORGuidLookupModel.isValidGuid(this.accountGuid)
  }

  get requiresProduct() {
    return (
      this.type === overrideTypeEnum.DEVICE ||
      this.type === overrideTypeEnum.ACCOUNT_DEVICE
    )
  }

  get overrideTypeName() {
    return this.type === 1
      ? 'Device'
      : this.type === 2
      ? 'Account'
      : 'Account_Device'
  }

  get metadata() {
    if (this.type !== 2)
      return [
        {
          name: 'Account GUID',
          value: 'Coming Soon',
        },
        {
          name: 'Client Guid/ID',
          value: 'Coming Soon',
        },
        {
          name: 'MSO',
          value: 'Coming Soon',
        },
        {
          name: 'Client Type',
          value: 'Coming Soon',
        },
        {
          name: 'Classification',
          value: 'Coming Soon',
        },
        {
          name: 'Account Type',
          value: 'Coming Soon',
        },
        {
          name: 'App Version',
          value: 'Coming Soon',
        },
        {
          name: 'OS Version',
          value: 'Coming Soon',
        },
        {
          name: 'Technology Type',
          value: 'Coming Soon',
        },
        {
          name: 'IP Address',
          value: 'Coming Soon',
        },
      ]
    return this.guidLookup?.firstMetadata
  }

  setGroups = overrideGroups => {
    this.groupIds.replace(overrideGroups.map(({ id }) => id))
  }

  addToGroup = ({ id }) => {
    if (!id) throw Error('Must have id at this point')
    this.groupIds.push(id)
  }

  loadQuickView() {
    if (!this.guidLookup) {
      this.createNewLookup()
      this.performGuidLookup()
    }
    this.checkAppliedInExperiments()
  }

  static overrideByIdentifierQuery = gql`
    query overrideByIdentifierQuery($overrideIdentifier: String!) {
      overrideByIdentifier(overrideIdentifier: $overrideIdentifier) {
        id
        overrideIdentifier
        name
      }
    }
  `

  static typeName = 'Override'

  static fragment = gql`
    fragment ORFields on Override {
      id
      name
      overrideIdentifier
      overrideType {
        id
        name
      }
      products {
        id
        name
        displayName
      }
      overrideGroups {
        id
        name
      }
    }
  `

  static query = gql`
    query getOverrides {
      overrides {
        ...ORFields
      }
    }
    ${OREditorModel.fragment}
  `

  static appliedInExperimentsQuery = gql`
    query getORAppliedInexperiments($overrideId: Int!) {
      override(overrideId: $overrideId) {
        id
        overrideIdentifier
        name
        appliedInExperiments {
          id
          name
        }
      }
    }
  `

  static updateMutation = gql`
    mutation updateOverride($input: UpdateOverrideType!) {
      updateOverride(input: $input) {
        ...ORFields
      }
    }
    ${OREditorModel.fragment}
  `

  static deleteMutation = gql`
    mutation deleteOverride($overrideId: Int!) {
      deleteOverride(overrideId: $overrideId)
    }
  `

  static lookupAccountQuery = gql`
    query lookupIdentity($input: IdentityLookupInput!) {
      lookupAccount(input: $input) {
        accountGuid
        accountName
        accountType
        accountNumber
        billingID
        divisionID
      }
    }
  `

  static insertMutation = gql`
    mutation insertOverride($input: InsertOverrideType!) {
      insertOverride(input: $input) {
        ...ORFields
      }
    }
    ${OREditorModel.fragment}
  `
}

OREditorModel = decorate(OREditorModel, {
  type: observable,
  productId: observable,
  accountGuid: observable,
  clientGuid: observable,
  name: observable,
  guidLookup: observable.ref,
  metadata: computed,
  selectedTypeId: observable,
  updateFromAPI: action,
  kiteAlert: computed,
  errorMessages: computed,
  apiDuplicateError: computed,
  isValidIdentifier: computed,
  identifierErrorMessage: computed,
  requiresProduct: computed,
  groupSearchValue: observable,
  groupIds: observable,
  groups: observable,
  setGroups: action,
  shouldShowLookupModal: observable,
  addToGroup: action,
  showLookupModal: action,
  createNewLookup: action,
  appliedInExperiments: observable,
  hasCheckedAppliedInExperiments: observable,
  inProgressCheckAppliedInExperiments: observable.ref,
})

export default OREditorModel
