import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

const INSERT_EXPERIMENT_VARIANTS = gql`
  mutation insertExperimentVariants($input: ExperimentToVariantEntry!) {
    insertExperimentVariants(input: $input) {
      id
      config
      multipleVariantReason
      variants {
        id
        uuid
        name
        description
        url
        jsonPayload
        displayOrder
        variantUploads {
          id
          fileUrl
        }
      }
      environmentSamplings {
        id
        tdcsUpdateRequired
        isDreSynced
      }
    }
  }
`

const insertExperimentVariants = graphql(INSERT_EXPERIMENT_VARIANTS, {
  name: 'insertExperimentVariants',
  withRef: true,
})

export default insertExperimentVariants
