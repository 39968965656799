import React, { Component, Fragment } from 'react'
import flowRight from 'lodash/flowRight'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'

import { object, number, array, func, string, bool } from 'prop-types'

import {
  hasPermission,
  permissionEnum,
  checkExperimentOverrides,
  checkExperimentOverrideGroups,
} from '@charter/distillery-rules'

import {
  KiteLoader,
  KiteButton,
  KiteSelect,
  KiteIcon,
  KiteAlert,
} from '@kite/react-kite'

import { AutoSuggest, SortableTable } from '@kite/react-kite-plus'

import { client } from '../../../../configuration/configApiClient'
import { LOG_ERROR } from '../../../../shared/mutations'
import {
  getOverridesByProductAndType2,
  getOverrideGroupsByProductAndType,
} from '../../../../shared/queries'
import { formatLoggingError } from '../../../../shared/utilities'
import getExperimentOverrides, {
  GET_EXPERIMENT_OVERRIDES,
} from './queries/getExperimentOverrides'

import insertVariantToOverrides from './mutations/insertVariantToOverrides'

import { ExpansionPanel, Modal } from '../../../../componentLibrary'
import { DeleteExperimentButton } from '../../../../components'
import copyContent from './data/copyContent'
import './Overrides.scss'

const {
  OVERRIDE_CREATE,
  OVERRIDE_UPDATE,
  OVERRIDE_DELETE,
  EXPERIMENT_UPDATE_RUNNING_ALL,
} = permissionEnum

export class Overrides extends Component {
  static propTypes = {
    id: number.isRequired,
    name: string.isRequired,
    status: string.isRequired,
    user: object.isRequired,
    applicationPermissions: array.isRequired,
    experimentPermissions: array.isRequired,
    environmentSamplings: array.isRequired,
    history: object.isRequired,
    experimentOverridesData: object.isRequired,
    overridesByProductAndTypeData: object.isRequired,
    overrideGroupsByProductAndTypeData: object.isRequired,
    insertVariantToOverrides: func.isRequired,
    disabled: bool.isRequired,
    activeNetworkLockdown: bool.isRequired,
    selectedEnvironment: object.isRequired,
  }

  state = {
    autoSuggestSearchValue: '',
    savedOverrides: [],
    savedGroups: [],
    experimentOverrides: [],
    experimentOverrideGroups: [],
    viewingDetailsIds: [],
    permissions: {
      canUpdateOverrides: false,
      canDeleteOverrides: false,
      canCreateOverrides: false,
    },
    isDirty: false,
    loading: {
      submit: null,
    },
    errorMessage: {
      searchValue: null,
      submit: null,
    },
  }

  // LIFECYCLE METHODS
  componentDidMount() {
    const {
      experimentOverridesData: { experiment },
    } = this.props

    if (experiment) {
      this.handleSetSelectedOverrides()
      this.handleSetPermissions()
    }
  }

  componentDidUpdate(prevProps) {
    const {
      experimentOverridesData: { experiment },
    } = this.props
    const {
      experimentOverridesData: { experiment: prevExperiment },
    } = prevProps

    if (!isEqual(prevExperiment, experiment)) {
      this.handleSetSelectedOverrides()
      this.handleSetPermissions()
    }
  }

  // LOCAL STATE CHANGES/TOGGLES
  handleSetSelectedOverrides = () => {
    const {
      experimentOverridesData: {
        experiment: { variants },
      },
    } = this.props

    const existingOverrides = variants.reduce(
      (
        accumulator,
        { id: variantId, variantToOverrides, variantToGroupOverrides }
      ) => {
        const experimentOverrides =
          variantToOverrides.length > 0
            ? variantToOverrides.map(({ id, overrides }) => {
                accumulator.savedOverrides.push(id)
                accumulator.variantsByOverrideId[`override__${id}`] = variantId

                const {
                  id: overrideId,
                  name,
                  overrideIdentifier,
                  overrideType,
                } = overrides[0]

                return {
                  id,
                  name,
                  overrideId,
                  variantId,
                  searchValue: `${name} (${overrideIdentifier})`,
                  type: overrideType.name.toLowerCase(),
                }
              })
            : []

        const experimentOverrideGroups =
          variantToGroupOverrides.length > 0
            ? variantToGroupOverrides.map(({ id, overrideGroups }) => {
                accumulator.savedGroups.push(id)
                accumulator.variantsByOverrideId[`group__${id}`] = variantId

                const {
                  id: overrideGroupId,
                  name,
                  overrides,
                } = overrideGroups[0]

                return {
                  id,
                  name,
                  overrideGroupId,
                  variantId,
                  overrides,
                  searchValue: name,
                  type: 'group',
                }
              })
            : []

        accumulator.experimentOverrides = [
          ...accumulator.experimentOverrides,
          ...this.formatTableValues(experimentOverrides),
        ]
        accumulator.experimentOverrideGroups = [
          ...accumulator.experimentOverrideGroups,
          ...this.formatTableValues(experimentOverrideGroups),
        ]

        return accumulator
      },
      {
        isDirty: false,
        variantsByOverrideId: {},
        savedOverrides: [],
        savedGroups: [],
        experimentOverrides: [],
        experimentOverrideGroups: [],
      }
    )

    this.setState({ ...existingOverrides })
  }

  handleSetPermissions = () => {
    const {
      applicationPermissions,
      experimentPermissions,
      environmentSamplings,
    } = this.props

    const permissionsArgs = {
      applicationPermissions,
      experimentPermissions,
      environmentSamplings,
    }

    const canUpdateRunningAll = hasPermission({
      ...permissionsArgs,
      permissionId: EXPERIMENT_UPDATE_RUNNING_ALL,
    })

    const canUpdateOverrides =
      canUpdateRunningAll ||
      hasPermission({
        ...permissionsArgs,
        permissionId: OVERRIDE_UPDATE,
      })

    const canDeleteOverrides =
      canUpdateRunningAll ||
      hasPermission({
        ...permissionsArgs,
        permissionId: OVERRIDE_DELETE,
      })

    const canCreateOverrides = hasPermission({
      applicationPermissions,
      permissionId: OVERRIDE_CREATE,
    })

    this.setState({
      permissions: {
        canUpdateOverrides,
        canDeleteOverrides,
        canCreateOverrides,
      },
    })
  }

  handleSearchOverrides = (event, searchValue) => {
    this.setState({
      autoSuggestSearchValue: searchValue || '',
    })
  }

  handleSelectOverride = (event, data) => {
    const itemsToUpdate =
      data.suggestion.type === 'group'
        ? 'experimentOverrideGroups'
        : 'experimentOverrides'

    this.setState(prevState => ({
      autoSuggestSearchValue: '',
      isDirty: true,
      [itemsToUpdate]: [...prevState[itemsToUpdate], data.suggestion],
    }))
  }

  handleToggleIsViewingDetails = id => {
    this.setState(({ viewingDetailsIds }) => ({
      viewingDetailsIds: viewingDetailsIds.includes(id)
        ? viewingDetailsIds.filter(selected => selected !== id)
        : [...viewingDetailsIds, id],
    }))
  }

  handleSelectVariant = ({ target: { name, value } }) => {
    const overrideArray = name.split('__')
    const overrideId = Number(overrideArray[0])
    const overrideType = overrideArray[1]

    const itemsToUpdate =
      overrideType === 'group'
        ? 'experimentOverrideGroups'
        : 'experimentOverrides'
    const idToUse = overrideType === 'group' ? 'overrideGroupId' : 'overrideId'

    this.setState(prevState => ({
      isDirty: true,
      errorMessage: {
        ...prevState.errorMessage,
        [`${overrideType === 'group' ? 'group' : 'override'}__${overrideId}`]:
          null,
      },
      [itemsToUpdate]: prevState[itemsToUpdate].map(override => {
        if (
          override[idToUse] === overrideId &&
          overrideType === override.type
        ) {
          return {
            ...override,
            variantId: Number(value),
          }
        }

        return override
      }),
    }))
  }

  handleSelectItemToDelete = selectedItem => {
    const itemsToUpdate =
      selectedItem.type === 'group'
        ? 'experimentOverrideGroups'
        : 'experimentOverrides'
    const idToUse =
      selectedItem.type === 'group' ? 'overrideGroupId' : 'overrideId'

    this.setState(prevState => ({
      isDirty: true,
      [itemsToUpdate]: prevState[itemsToUpdate].filter(
        selected => selected[idToUse] !== selectedItem[idToUse]
      ),
    }))
  }

  handleSetLoading = (type, isLoading) => {
    this.setState(({ loading }) => ({
      loading: {
        ...loading,
        [type]: isLoading,
      },
    }))
  }

  handleShowSuccessMessage = () => {
    const { name } = this.props
    const { successSubmitTitle, successSubmitDescription } = copyContent

    this.setState({
      isDirty: false,
      success: {
        title: successSubmitTitle,
        description: successSubmitDescription(name),
      },
    })

    setTimeout(() => this.setState({ success: null }), 5000)
  }

  handleShowSubmitErrorMessage = () => {
    const { errorSubmitTitle, errorSubmitDescription, errorSubmitLink } =
      copyContent

    this.setState(({ errorMessage }) => ({
      errorMessage: {
        ...errorMessage,
        submit: {
          title: errorSubmitTitle,
          description: errorSubmitDescription,
          linkText: errorSubmitLink,
        },
      },
    }))
  }

  // API METHODS
  handleSubmit = async () => {
    const { handleUpdateOverrideBanner } = this.props
    const overrideInput = this.formatInput()

    await this.handleInsertOverrides(overrideInput)
    await handleUpdateOverrideBanner(false)
    this.handleSetLoading('submit', false)
  }

  handleInsertOverrides = async input => {
    const { id, insertVariantToOverrides: insertOverrides } = this.props

    this.handleSetLoading('submit', true)

    try {
      const response = await insertOverrides({
        variables: {
          experimentId: id,
          input,
        },
        update: (
          proxy,
          { data: { insertVariantToOverrides: newOverrides } }
        ) => {
          const variantsByOverrideId = input.reduce(
            (accumulator, { overrideId, variantId }) => {
              accumulator[overrideId] = variantId

              return accumulator
            },
            {}
          )

          const data = cloneDeep(
            proxy.readQuery({
              query: GET_EXPERIMENT_OVERRIDES,
              variables: {
                id,
              },
            })
          )

          Object.keys(variantsByOverrideId).forEach(overrideId => {
            data.experiment.variants.forEach((variant, index) => {
              if (variantsByOverrideId[overrideId] === variant.id) {
                const matchingOverrides = newOverrides.filter(
                  newOverride => newOverride.overridesId === Number(overrideId)
                )

                data.experiment.variants[index].variantToOverrides = [
                  ...data.experiment.variants[index].variantToOverrides,
                  ...matchingOverrides,
                ]
              }
            })
          })

          proxy.writeQuery({
            query: GET_EXPERIMENT_OVERRIDES,
            variables: {
              id,
            },
            data,
          })
        },
        refetchQueries: ['getExperimentRolloutDetails'],
      })

      await this.setState(({ experimentOverrides }) => ({
        savedOverrides: response.data.insertVariantToOverrides.map(
          override => override.id
        ),
        experimentOverrides: experimentOverrides.map(override => {
          const match = response.data.insertVariantToOverrides.find(
            saved => saved.overridesId === override.overrideId
          )

          if (match) {
            return { ...override, id: match.id }
          }

          return override
        }),
      }))

      this.handleShowSuccessMessage()
    } catch (error) {
      this.handleShowSubmitErrorMessage()

      const errorInput = formatLoggingError(error, { id })

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input: errorInput,
        },
      })
    }
  }

  // FORMATTING METHODS
  formatTableValues = values => {
    const {
      permissions: { canDeleteOverrides },
    } = this.state

    return values.map(
      ({
        id,
        name,
        type,
        overrideId,
        overrideGroupId,
        overrideIdentifier,
        variantId,
        searchValue,
        overrides,
      }) => {
        const idToUse = type === 'group' ? overrideGroupId : overrideId
        const itemValues = {
          id,
          name,
          type,
          variantId,
          searchValue,
          overrideId,
          overrideGroupId,
          uuid: `${name}_${overrideIdentifier}`, // Superficial prop needed for table delete/add behavior due to duplicate ids
          value:
            type === 'group'
              ? this.renderGroupValues(overrideGroupId, overrides)
              : overrideIdentifier,
          variant: this.renderVariantSelect(variantId, idToUse, type),
        }

        if (canDeleteOverrides) {
          return {
            ...itemValues,
            itemControls: this.renderItemControls(itemValues),
          }
        }

        return itemValues
      }
    )
  }

  formatSearchValues = () => {
    const { experimentOverrides, experimentOverrideGroups } = this.state
    const {
      overridesByProductAndTypeData: { overridesByProductAndType: overrides },
      overrideGroupsByProductAndTypeData: {
        overrideGroupsByProductAndType: groups,
      },
    } = this.props

    const formattedOverrides = overrides
      .filter(
        // filter out overrides that already exist on the experiment
        override =>
          !experimentOverrides.find(
            onExperiment => onExperiment.overrideId === override.id
          )
      )
      .map(({ id, name, overrideIdentifier, overrideType }) => ({
        id: null,
        uuid: `${name}_${id}`, // Superficial prop needed for table delete/add behavior
        overrideId: id,
        searchValue: `${name} (${overrideIdentifier})`,
        type: overrideType.name.toLowerCase(),
        variantId: '',
      }))
      .sort((aOverride, bOverride) =>
        aOverride.searchValue > bOverride.searchValue ? 1 : -1
      )

    const formattedGroups = groups
      .filter(
        override =>
          !experimentOverrideGroups.find(
            selected => selected.overrideGroupId === override.id
          )
      )
      .map(({ id, name }) => ({
        id: null,
        uuid: `${name}_${id}`, // Superficial prop needed for table delete/add behavior
        overrideGroupId: id,
        searchValue: name,
        type: 'group',
        variantId: '',
      }))
      .sort((aOverride, bOverride) =>
        aOverride.searchValue > bOverride.searchValue ? 1 : -1
      )

    return {
      'Account/Device ID': formattedOverrides,
      Groups: formattedGroups,
    }
  }

  formatTableData = () => {
    const { experimentOverrides, experimentOverrideGroups } = this.state
    const {
      overridesByProductAndTypeData: { overridesByProductAndType: overrides },
      overrideGroupsByProductAndTypeData: {
        overrideGroupsByProductAndType: groups,
      },
    } = this.props

    const matchingOverrides = experimentOverrides.map(selected => {
      const match = overrides.find(
        override => override.id === selected.overrideId
      )

      return {
        ...match,
        ...selected,
      }
    })

    const matchingOverrideGroups = experimentOverrideGroups.map(selected => {
      const match = groups.find(group => group.id === selected.overrideGroupId)

      return {
        ...match,
        ...selected,
      }
    })

    const tableData = [
      ...this.formatTableValues(matchingOverrides),
      ...this.formatTableValues(matchingOverrideGroups),
    ]

    const columnsData = [
      {
        label: 'Name',
        sortKey: 'name',
        sortEnabled: false,
        size: 1.25,
      },
      {
        label: 'Type',
        sortKey: 'type',
        sortEnabled: false,
        size: 0.25,
      },
      {
        label: 'Value',
        sortKey: 'value',
        sortEnabled: false,
        size: 1.7,
      },
      {
        label: 'Variant',
        sortKey: 'variant',
        sortEnabled: false,
        size: 1,
      },
      {
        label: '\xa0',
        sortKey: 'itemControls',
        sortEnabled: false,
        size: 0.25,
      },
    ]

    return { tableData, columnsData }
  }

  formatInput = () => {
    const { experimentOverrides, experimentOverrideGroups } = this.state
    const overrideInput = []
    experimentOverrides.forEach(({ overrideId, variantId }) => {
      const input = {
        overrideId,
        variantId,
      }
      overrideInput.push(input)
    })

    experimentOverrideGroups.forEach(({ overrideGroupId, variantId }) => {
      const input = {
        overrideGroupId,
        variantId,
      }
      overrideInput.push(input)
    })

    return overrideInput
  }

  // VALIDATION METHODS
  validateSubmission = () => {
    const overrideInput = this.formatInput()

    let errors = {}

    if (overrideInput.length > 0) {
      const overridesToCheck = overrideInput.filter(
        ({ overrideId, overrideGroupId }) => overrideId && !overrideGroupId
      )
      const { errors: overrideErrors } =
        checkExperimentOverrides(overridesToCheck)

      errors = { ...errors, ...overrideErrors }
    }

    if (overrideInput.length > 0) {
      const overrideGroupsToCheck = overrideInput.filter(
        ({ overrideId, overrideGroupId }) => !overrideId && overrideGroupId
      )
      const { errors: overrideGroupErrors } = checkExperimentOverrideGroups(
        overrideGroupsToCheck
      )

      errors = { ...errors, ...overrideGroupErrors }
    }

    if (!isEmpty(errors)) {
      this.setState(({ errorMessage }) => ({
        errorMessage: {
          ...errorMessage,
          ...errors,
        },
      }))

      return false
    }

    return true
  }

  // RENDER METHODS
  renderVariantSelect = (variantId, id, type) => {
    const {
      errorMessage,
      permissions: { canUpdateOverrides },
    } = this.state
    const {
      experimentOverridesData: {
        experiment: { variants },
      },
      status,
      activeNetworkLockdown,
      selectedEnvironment: { id: environmentId },
    } = this.props

    return (
      <KiteSelect
        className="experiment-overrides__variant-select"
        key={`${id}__${type}`}
        name={`${id}__${type}`}
        value={variantId}
        disabled={
          !canUpdateOverrides ||
          (status !== 'DRAFT' && environmentId === 1 && activeNetworkLockdown)
        }
        onChange={this.handleSelectVariant}
        maxWidth="100%"
        errorMessage={
          errorMessage[`${type === 'group' ? 'group' : 'override'}__${id}`]
        }
      >
        <option value="" disabled>
          Select a variant
        </option>

        {variants.map(variant => (
          <option key={variant.id} value={variant.id}>
            {variant.name}
          </option>
        ))}
      </KiteSelect>
    )
  }

  renderItemControls = selectedItem => {
    const { activeNetworkLockdown } = this.props

    return (
      <KiteIcon
        key={`${selectedItem.id}__${selectedItem.type}`}
        className="experiment-overrides__delete-button"
        name="trash-f"
        onClick={() => {
          if (!activeNetworkLockdown) {
            this.handleSelectItemToDelete(selectedItem)
          }
        }}
      />
    )
  }

  renderGroupValues = (overrideGroupId, overrides = []) => {
    const { viewingDetailsIds } = this.state

    const {
      groupViewDetails,
      groupHideDetails,
      noOverridesToGroup1,
      noOverridesToGroup2,
    } = copyContent

    const isViewingDetails = viewingDetailsIds.includes(overrideGroupId)

    return (
      <div
        className={`experiment-overrides__group-values-container ${
          isViewingDetails
            ? 'experiment-overrides__group-values-container-expanded'
            : ''
        }`}
      >
        {overrides.length === 0 && (
          <div className="experiment-overrides__group-no-overrides-container">
            <span className="experiment-overrides__group-no-overrides">
              {noOverridesToGroup1}
            </span>
            <span className="experiment-overrides__group-no-overrides">
              {noOverridesToGroup2}
            </span>
          </div>
        )}

        {overrides.length > 0 && (
          <Fragment>
            {isViewingDetails ? (
              overrides.map(override => (
                <span
                  key={override.id}
                  className="experiment-overrides__group-expanded-values"
                >
                  <span className="experiment-overrides__group-expanded-name-container">
                    <span className="experiment-overrides__group-expanded-name">
                      {override.name}
                    </span>
                    {` (${override.overrideIdentifier})`}
                  </span>
                </span>
              ))
            ) : (
              <span className="experiment-overrides__group-collapsed-names">
                {overrides.map(override => override.name).join(',\xa0')}
              </span>
            )}

            <KiteButton
              className={`experiment-overrides__group-view-more ${
                isViewingDetails
                  ? 'experiment-overrides__group-view-more-expanded'
                  : ''
              }`}
              type="standalone-link"
              onClick={() => this.handleToggleIsViewingDetails(overrideGroupId)}
            >
              {isViewingDetails ? groupHideDetails : groupViewDetails}
            </KiteButton>
          </Fragment>
        )}
      </div>
    )
  }

  renderFooterControls = () => {
    const {
      loading,
      isDirty,
      permissions: { canUpdateOverrides },
    } = this.state
    const {
      id,
      applicationPermissions,
      history,
      experimentOverridesData: { experiment },
      status,
      disabled,
      activeNetworkLockdown,
      selectedEnvironment: { id: environmentId },
    } = this.props

    const { submitButton, submitAndPublishButton, resetButton } = copyContent

    return (
      <div className="experiment-overrides__footer-controls">
        <div className="experiment-overrides__footer-left">
          <KiteButton
            className="rollout-experiment__launch-button"
            size="large"
            disabled={
              !canUpdateOverrides ||
              loading.submit ||
              disabled ||
              !isDirty ||
              (status !== 'DRAFT' &&
                environmentId === 1 &&
                activeNetworkLockdown)
            }
            loading={loading.submit}
            onClick={() => {
              if (this.validateSubmission()) {
                this.handleSubmit()
              }
            }}
          >
            {status === 'DRAFT' ? submitButton : submitAndPublishButton}
          </KiteButton>

          <KiteButton
            className={
              activeNetworkLockdown && environmentId === 1
                ? 'rollout-experiment__reset-button-disabled'
                : 'rollout-experiment__reset-button'
            }
            size="small"
            type="standalone-link"
            disabled={
              !canUpdateOverrides ||
              !isDirty ||
              (status !== 'DRAFT' &&
                environmentId === 1 &&
                activeNetworkLockdown)
            }
            onClick={this.handleSetSelectedOverrides}
          >
            {resetButton}
          </KiteButton>
        </div>

        <div className="experiment-overrides__footer-right">
          <DeleteExperimentButton
            id={id}
            experiment={experiment}
            className={
              environmentId === 1 && activeNetworkLockdown
                ? 'experiment-overrides__delete-override-btn'
                : ''
            }
            applicationPermissions={applicationPermissions}
            activeNetworkLockdown={environmentId === 1 && activeNetworkLockdown}
            onSuccess={() => {
              history.replace('/experiments/draft')
            }}
          />
        </div>
      </div>
    )
  }

  render() {
    const {
      id,
      user,
      history,
      status,
      experimentOverridesData: {
        loading: experimentOverridesLoading,
        experiment,
      },
      overridesByProductAndTypeData: { loading: overridesLoading },
      overrideGroupsByProductAndTypeData: { loading: groupsLoading },
      activeNetworkLockdown,
      selectedEnvironment: { id: environmentId },
    } = this.props

    if (experimentOverridesLoading || overridesLoading || groupsLoading) {
      return (
        <div className="app__loader">
          <KiteLoader size="7rem" />
        </div>
      )
    }

    const {
      autoSuggestSearchValue,
      success,
      errorMessage,
      permissions: { canCreateOverrides, canUpdateOverrides },
      loading,
    } = this.state

    const { tableData, columnsData } = this.formatTableData()
    const searchValues = this.formatSearchValues()

    const {
      autoSuggestSearchLabel,
      autoSuggestSearchPlaceholder,
      overrideManagementButton,
      noOverrides,
      errorAutoSuggestSearch,
    } = copyContent

    return (
      <div className="experiment-overrides">
        <ExpansionPanel type="minimal" isExpanded={!!success}>
          <KiteAlert
            className="app__page-level-message"
            type="confirm"
            title={success && success.title}
            description={success && success.description}
          />
        </ExpansionPanel>

        <ExpansionPanel type="minimal" isExpanded={!!errorMessage.submit}>
          <KiteAlert
            className="app__page-level-message"
            type="alert"
            title={errorMessage.submit && errorMessage.submit.title}
            description={errorMessage.submit && errorMessage.submit.description}
            linkText={errorMessage.submit && errorMessage.submit.linkText}
            onLinkClick={() => {
              window.location = `mailto:DL-Distillery-Support@charter.com?subject=Distillery Support for Experiment Overrides - ${user.displayName}, Experiment ${id}`
            }}
            onClose={() =>
              this.setState(prevState => ({
                errorMessage: {
                  ...prevState.errorMessage,
                  submit: null,
                },
              }))
            }
          />
        </ExpansionPanel>

        <div className="experiment-overrides__controls-container">
          <AutoSuggest
            className="experiment-overrides__auto-suggest"
            name="overrideSearch"
            value={autoSuggestSearchValue}
            label={autoSuggestSearchLabel}
            placeholder={autoSuggestSearchPlaceholder}
            disabled={
              !canUpdateOverrides ||
              (status !== 'DRAFT' &&
                environmentId === 1 &&
                activeNetworkLockdown) ||
              (!experiment.isCmsExperiment && status !== 'RUNNING')
            }
            suggestionKey="searchValue"
            minCharacters={3}
            initialData={searchValues}
            onChange={this.handleSearchOverrides}
            onSuggestionSelected={this.handleSelectOverride}
            maxWidth="18rem"
            errorMessage={
              errorMessage.searchValue &&
              errorAutoSuggestSearch(autoSuggestSearchValue)
            }
          />

          {canCreateOverrides && (
            <KiteButton
              name="experiment-overrides__management-button"
              type="standalone-link"
              leftIcon="settings-f"
              onClick={() =>
                history.push(
                  `/override-management/id/?id=${id}&status=${status.toLowerCase()}`
                )
              }
            >
              {overrideManagementButton}
            </KiteButton>
          )}
        </div>

        <SortableTable
          className="experiment-overrides__table"
          tableData={tableData}
          columns={columnsData}
          initialSortHeader="name"
          noDataMessage={noOverrides}
        />

        {loading.submit && (
          <Modal
            className="sample-launch__check-modal"
            message="Publishing Your Changes..."
            subMessage={`We’re sending your changes to ${
              experiment.isCmsExperiment ? 'DRE' : 'TDCS'
            }. 
Sorry to keep you here, but this avoids concurrent publish requests that might
lead to conflicting publish history. `}
            showLoader
          />
        )}

        {this.renderFooterControls()}
      </div>
    )
  }
}

export default flowRight(
  getExperimentOverrides,
  getOverridesByProductAndType2,
  getOverrideGroupsByProductAndType,
  insertVariantToOverrides
)(Overrides)
