import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

// eslint-disable-next-line camelcase
export const GET_PRODUCTS_GQLBUILDER = {
  query: `
    productCategoryTypes(permissionEnum: $permissionEnum) {
      id
      name
      products(permissionEnum: $permissionEnum) {
        id
        name
        displayName
        quantumApplicationName
        quantumApplicationType
        activeAccounts
        activeDevices
        sampleCalculatorEnabled
      }
    }`,
  variables: {
    $permissionEnum: 'PermissionEnum!',
  },
}

export const GET_ALL_PRODUCTS_GQLBUILDER = {
  query: `
    allProductCategoryTypes {
      id
      name
      allProducts {
        id
        name
        displayName
        quantumApplicationName
        quantumApplicationType
        activeAccounts
        activeDevices
        sampleCalculatorEnabled
      }
    }`,
}

const GET_PRODUCTS = gql`
  query getProducts($permissionEnum: PermissionEnum!) {
    productCategoryTypes(permissionEnum: $permissionEnum) {
      id
      name
      displayName
      products(permissionEnum: $permissionEnum) {
        id
        name
        displayName
        quantumApplicationName
        quantumApplicationType
        activeAccounts
        activeDevices
        sampleCalculatorEnabled
      }
    }
  }
`

const getProducts = graphql(GET_PRODUCTS, {
  name: 'productsData',
  withRef: true,
})

export default getProducts
