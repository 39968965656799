import React, { Component } from 'react'
import { object, func, array, bool } from 'prop-types'
import flowRight from 'lodash/flowRight'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import { KiteButton, KiteLoader, KiteAlert } from '@kite/react-kite'
import { LinearProgressStepper } from '@kite/react-kite-plus'
import { gql } from '@apollo/client'
import {
  hasPermission,
  permissionEnum,
  permissionEnumReverse,
  experimentTypeEnum,
  experimentStatusEnum,
  isFeatureFlagActive,
  featureFlagEnum,
} from '@charter/distillery-rules'
import {
  capitalizeString,
  formatSetUpSteps,
  formatLoggingError,
  scrollToNavigationPosition,
} from '../../shared/utilities'
import { client } from '../../configuration/configApiClient'
import {
  APPROVE_TECH_SETTINGS,
  ACKNOWLEDGE_OVERRIDES_DELETION,
  UPDATE_EXPERIMENT_CONFIG,
  LOG_ERROR,
} from '../../shared/mutations'
import {
  getExperimentDetails,
  GET_EXPERIMENT_DETAILS,
  GET_ACTIVE_LOCKDOWNS,
} from '../../shared/queries'
import { intialSetUpStepsCategories } from '../../shared/data/initialSetUpSteps'
import { ExpansionPanel, Modal } from '../../componentLibrary'
import { TdcsUpdateNotification } from '../../components'
import OldSetUpExperiment from './OldSetUpExperiment'
import { PlanStep } from './sharedSteps'
import { ContentStep, AudienceStep } from './cmsSteps'
import {
  VariantsStep,
  MetricsStep,
  TechSettingStep,
  ActivationStep,
} from './steps'

import copyContent from './data/copyContent'
import './SetUpExperiment.scss'

const {
  EXPERIMENT_CREATE,
  EXPERIMENT_UPDATE,
  EXPERIMENT_UPDATE_RUNNING,
  EXPERIMENT_UPDATE_RUNNING_ALL,
  EXPERIMENT_UPDATE_VERSION_RUNNING,
  EXPERIMENT_APPROVE_TECH,
  TECH_UPDATE,
  ACTIVATION_UPDATE,
} = permissionEnum

class SetUpExperiment extends Component {
  static propTypes = {
    user: object.isRequired,
    match: object.isRequired,
    history: object.isRequired,
    location: object.isRequired,
    currentlyViewing: object.isRequired,
    onNavigate: func.isRequired,
    applicationPermissions: array.isRequired,
    currentUser: object.isRequired,
    experimentDetailsData: object,
    networkLockdownPermission: bool.isRequired,
  }

  static defaultProps = {
    experimentDetailsData: {},
  }

  state = {
    activeStep: 'plan',
    setUpSteps: [],
    isCMSExperiment: false,
    techApprovalSteps: [],
    redirectLocation: null,
    isDirty: false,
    isValid: false,
    isDisabled: false,
    isCancelModalOpen: false,
    areAllStepsComplete: false,
    shouldCheckActivationChange: false,
    isChangeActivationEventModalOpen: false,
    isOverridesDeleted: false,
    success: null,
    errorMessage: null,
    approveTechSettingsError: null,
    approveVariantsError: null,
    loading: { submit: false },
    hashVariables: {},
    isNetworkLockdownActive: false,
  }

  // LIFECYCLE METHODS
  async componentDidMount() {
    const {
      location,
      experimentDetailsData,
      match: {
        params: { id },
      },
    } = this.props

    if (
      location &&
      (location.pathname === '/experiments/new' ||
        location.pathname === '/experiments/new-cms')
    ) {
      this.handleSetNewCurrentlyViewing()
    }

    if (location.hash) {
      const hashSplit = location.hash.split('&')
      const hashVariables = {}
      hashSplit.forEach(hashValue => {
        const removedHash = hashValue.replace('#', '').split('=')
        if (
          ['product', 'contentAreaId', 'contentId'].includes(removedHash[0])
        ) {
          hashVariables[removedHash[0]] = removedHash[1]
        }
      })
      this.setState({ hashVariables })
    }

    if (id && experimentDetailsData.experiment) {
      await this.formatExistingExperiment()
    }

    // only check for active lockdowns if user does not have network lockdown permissions
    if (!this.props.networkLockdownPermission) {
      await this.isLockdownActive()
    }
  }

  async componentDidUpdate(prevProps) {
    const { experimentDetailsData } = this.props
    const { experimentDetailsData: prevData } = prevProps
    if (!prevData.experiment && experimentDetailsData.experiment) {
      await this.formatExistingExperiment()
    }
    if (
      experimentDetailsData.experiment &&
      prevData.experiment &&
      !isEqual(
        experimentDetailsData.experiment.permissions,
        prevData.experiment.permissions
      )
    ) {
      const {
        experimentDetailsData: {
          experiment: { environmentSamplings, permissions },
        },
      } = this.props
      this.handleSetPermissions(permissions, environmentSamplings)
    }
  }

  isLockdownActive = async () => {
    const res = await client.query({
      query: GET_ACTIVE_LOCKDOWNS,
    })

    if (
      res.data &&
      res.data.activeNetworkLockdowns &&
      res.data.activeNetworkLockdowns.length
    ) {
      await this.setState({ isNetworkLockdownActive: true })
    }
  }

  // REF METHODS
  handleSetNewCurrentlyViewing = () => {
    const { location, onNavigate } = this.props

    const path = location.pathname
    let setUpSteps
    let techApprovalSteps
    let isCMSExperiment = false
    // Grab the correct set up steps
    if (location.pathname === '/experiments/new-cms') {
      setUpSteps = intialSetUpStepsCategories.cms.setUpSteps
      techApprovalSteps = intialSetUpStepsCategories.cms.techApprovalSteps
      isCMSExperiment = true
    } else {
      setUpSteps = intialSetUpStepsCategories.traditional.setUpSteps
      techApprovalSteps =
        intialSetUpStepsCategories.traditional.techApprovalSteps
    }

    const currentlyViewing = {
      path,
      title: 'Create Draft',
      backPath: '/experiments/draft',
      backTitle: 'Draft Experiments',
      tabs: {
        currentTab: 'set up',
        tabs: [
          { label: 'set up', path },
          { label: 'rollout', path, disabled: true },
          { label: 'results', path, disabled: true },
        ],
      },
    }

    onNavigate(currentlyViewing)

    this.setState({
      setUpSteps,
      techApprovalSteps,
      isCMSExperiment,
    })
  }

  handleSetCurrentlyViewing = ({
    experiment = null,
    basePath,
    path,
    isSetUpComplete,
  }) => {
    const {
      onNavigate,
      experimentDetailsData: { experiment: existingExperiment },
      history,
    } = this.props

    const currentExperiment = existingExperiment || experiment

    const { name, experimentStatus, experimentToProductFeatures } =
      currentExperiment

    const status = experimentStatus.name.toLowerCase()

    const { product } = experimentToProductFeatures[0]

    let currentlyViewing = {
      path,
      title: name,
      subTitle: `${product.displayName}`,
      backPath: `/experiments/${status}`,
      backTitle: `${status} Experiments`,
      tabs: {
        currentTab: !path.includes('rollout') ? 'set up' : 'rollout',
        tabs: [
          { label: 'set up', path: `${basePath}/set-up/plan` },
          {
            label: 'rollout',
            path: `${basePath}/rollout/overrides`,
            disabled: !isSetUpComplete,
          },
          {
            label: 'results',
            path: `${basePath}/results`,
            disabled: !isSetUpComplete,
          },
        ],
      },
    }

    if (status === 'deleted') {
      currentlyViewing = {
        path: '/experiments/all',
        backPath: path,
        backTitle: name,
      }

      history.push('/experiments/all')
    }

    onNavigate(currentlyViewing)
  }

  // Used by any 'step' component to inform this component of any changes
  handleSetDirty = isDirty => {
    this.setState({ isDirty })
  }

  handleRedirect = (event, redirectLocation) => {
    if (event) {
      event.preventDefault()
    }

    this.setState({ isCancelModalOpen: true, redirectLocation })
  }

  handleConfirmChangeSteps = async () => {
    const { redirectLocation } = this.state

    await this.setState({ isDirty: false, isCancelModalOpen: false })
    this.handleSelectStep(redirectLocation)
  }

  handleGoBack = event => {
    if (event) {
      event.preventDefault()
    }
    const { history } = this.props

    history.push('/experiments/draft')
  }

  handleUpdateProgress = ({ step, status, areAllStepsComplete }) => {
    this.setState({ areAllStepsComplete })
    this.handleSetStepStatus(step, status)
  }

  handleCheckUpdateActiveStep = async ({ id, config }) => {
    const { activeStep, isValid, setUpSteps } = this.state

    let techSettingsSessionTypeChange = false

    if (activeStep === 'tech settings') {
      techSettingsSessionTypeChange =
        this['tech settings'].ref.isSessionTypeChange()
    }

    // By default the step status is complete if the step is valid
    // Unless the step has a custom isComplete method
    const stepIsComplete = this[activeStep].isComplete
      ? await this[activeStep].isComplete()
      : isValid

    const stepStatus = stepIsComplete ? 'complete' : 'incomplete'
    const newSteps = await this.handleSetStepStatus(activeStep, stepStatus)

    let newConfig
    if (techSettingsSessionTypeChange) {
      setUpSteps.find(e => e.label === 'activation').status = 'incomplete'

      newConfig = await this.handleUpdateExperimentConfig(
        id,
        config,
        'activation',
        'incomplete'
      )
    }

    if (newConfig) {
      config = newConfig
    }

    newConfig = await this.handleUpdateExperimentConfig(
      id,
      config,
      activeStep,
      stepStatus
    )

    const { isSetUpComplete, activeStep: nextStep } = formatSetUpSteps(
      JSON.parse(newConfig),
      newSteps
    )

    return { isSetUpComplete, nextStep }
  }

  handleSetStepStatus = async (step, status) => {
    const { setUpSteps } = this.state

    const newSteps = cloneDeep(setUpSteps).map(element => {
      if (
        element.label === 'activation' &&
        this['tech settings'] &&
        this['tech settings'].ref &&
        this['tech settings'].ref.isSessionTypeChange()
      ) {
        return {
          ...element,
          status: 'incomplete',
        }
      }
      if (element.label === step) {
        return {
          ...element,
          status,
        }
      }
      return element
    })

    await this.setState({ setUpSteps: newSteps })

    return newSteps
  }

  // FORMAT Methods
  formatBlankExperimentConfig = () => {
    const { setUpSteps } = this.state

    return JSON.stringify(
      setUpSteps.reduce(
        (prevValue, step, order) => {
          // eslint-disable-next-line no-param-reassign
          prevValue.setUpSteps[step.label] = {
            order,
            status: 'initial',
          }
          return prevValue
        },
        { setUpSteps: {} }
      )
    )
  }

  formatExistingExperiment = async () => {
    const {
      history,
      match: {
        params: { id, step },
      },
      experimentDetailsData: {
        experiment,
        experiment: { activationEventUuid },
      },
    } = this.props

    const {
      config,
      environmentSamplings,
      permissions,
      isOverridesDeleted,
      isCmsExperiment,
    } = experiment

    let setUpSteps
    let techApprovalSteps

    if (isCmsExperiment) {
      setUpSteps = intialSetUpStepsCategories.cms.setUpSteps
      techApprovalSteps = intialSetUpStepsCategories.cms.techApprovalSteps
    } else {
      setUpSteps = intialSetUpStepsCategories.traditional.setUpSteps
      techApprovalSteps =
        intialSetUpStepsCategories.traditional.techApprovalSteps
    }

    const parsedConfig = JSON.parse(config)

    const { setUpSteps: newSteps, isSetUpComplete } = formatSetUpSteps(
      parsedConfig,
      setUpSteps
    )

    const shouldCheckActivationChange = newSteps.activation
      ? newSteps.activation.status !== 'initial' && activationEventUuid
      : false

    const isDisabled = this.validateIsDisabled()

    const basePath = `/experiments/${id}`
    const path = `${basePath}/set-up/${step}`

    history.replace(path)

    this.handleSetCurrentlyViewing({ basePath, path, isSetUpComplete })

    await this.handleSetPermissions(permissions, environmentSamplings)

    this.setState({
      isDisabled,
      shouldCheckActivationChange,
      areAllStepsComplete: isSetUpComplete,
      activeStep: step,
      setUpSteps: newSteps,
      isOverridesDeleted,
      isCMSExperiment: isCmsExperiment,
      techApprovalSteps,
    })
  }

  // STATE changes
  handleSelectStep = nextStep => {
    const { history } = this.props
    const { setUpSteps, isDirty, activeStep } = this.state

    // Find the step prior to the clicked step; if the step before is not complete, do no navigate
    const stepBefore =
      setUpSteps[setUpSteps.findIndex(step => step.label === nextStep) - 1]

    // Temporary variable used to determine if moving on is allowed
    let canContinue

    // If trying to move to the below steps, 'plan' must be 'complete', 'variant' must not be 'initial'
    if (
      nextStep !== 'plan' &&
      nextStep !== 'variants&content' &&
      nextStep !== 'variants'
    ) {
      const planComplete =
        setUpSteps.find(step => step.label === 'plan').status === 'complete'
      const variantComplete = setUpSteps.find(step => step.label === 'variants')
        ? setUpSteps.find(step => step.label === 'variants').status !==
          'initial'
        : setUpSteps.find(step => step.label === 'variants&content').status !==
          'initial'

      canContinue = planComplete && variantComplete

      // Else, the step prior to the next step must be completed
    } else {
      canContinue = stepBefore ? stepBefore.status === 'complete' : true
    }

    if (nextStep !== activeStep && canContinue) {
      if (isDirty) {
        // Show a double confirm for lost changes
        this.handleRedirect(null, nextStep)
      } else {
        // If the step is complete, move on and update the URL accordingly
        const pathArray = history.location.pathname.split('/')
        const newPath = `${pathArray
          .splice(0, pathArray.length - 1)
          .join('/')}/${nextStep}`

        history.replace(newPath)

        this.setState({ activeStep: nextStep, success: null })
      }
    }
  }

  handleSetPermissions = (experimentPermissions, environmentSamplings) => {
    const { applicationPermissions, experimentDetailsData } = this.props

    const permissionsArgs = {
      applicationPermissions,
      experimentPermissions,
      environmentSamplings,
    }

    if (
      experimentDetailsData.experiment &&
      experimentDetailsData.experiment.experimentType
    ) {
      permissionsArgs.experimentTypeId =
        experimentDetailsData.experiment.experimentType.id
    }

    const canUpdateTechSettings = hasPermission({
      ...permissionsArgs,
      permissionId: TECH_UPDATE,
    })

    const canUpdateActivation = hasPermission({
      ...permissionsArgs,
      permissionId: ACTIVATION_UPDATE,
    })

    const canApproveTechSettings = hasPermission({
      experimentPermissions,
      permissionId: EXPERIMENT_APPROVE_TECH,
    })

    const canUpdateVersionRunning = hasPermission({
      ...permissionsArgs,
      permissionId: EXPERIMENT_UPDATE_VERSION_RUNNING,
    })

    const canUpdateRunning = hasPermission({
      ...permissionsArgs,
      permissionId: EXPERIMENT_UPDATE_RUNNING,
    })

    this.setState({
      canUpdateTechSettings,
      canUpdateActivation,
      canApproveTechSettings,
      canUpdateVersionRunning,
      canUpdateRunning,
    })
  }

  handleUpdateOverrideBanner = async isOverridesDeleted => {
    const {
      match: {
        params: { id },
      },
    } = this.props
    if (!isOverridesDeleted) {
      await client.mutate({
        mutation: ACKNOWLEDGE_OVERRIDES_DELETION,
        variables: {
          experimentId: Number(id),
        },
      })
    }
    await this.setState({ isOverridesDeleted })
  }

  handleShowSuccessMessage = activeStep => {
    const { successSubmitTitle, successSubmitDescription } = copyContent

    scrollToNavigationPosition()

    this.setState(({ loading }) => ({
      isDirty: false,
      loading: {
        ...loading,
        submit: false,
      },
      success: {
        title: successSubmitTitle(capitalizeString(activeStep)),
        description: successSubmitDescription,
      },
    }))

    setTimeout(() => this.setState({ success: null }), 5000)
  }

  handleCompletedForm = async ({
    basePath,
    path,
    isSetUpComplete,
    experimentId,
  }) => {
    const { canApproveTechSettings } = this.state
    if (canApproveTechSettings) {
      await client.mutate({
        mutation: APPROVE_TECH_SETTINGS,
        variables: {
          experimentId,
        },
      })
    }

    this.handleSetCurrentlyViewing({ basePath, path, isSetUpComplete })
  }

  handleUpdateCompleteForm = async () => {
    const { activeStep } = this.state
    const {
      experimentDetailsData: { experiment },
    } = this.props

    await this.handleCheckUpdateActiveStep(experiment)

    this.handleShowSuccessMessage(activeStep)
  }

  handleFormSuccessActions = async response => {
    const {
      history,
      experimentDetailsData: { experiment },
    } = this.props
    const { areAllStepsComplete } = this.state

    if (areAllStepsComplete) {
      await this.handleUpdateCompleteForm()
    } else {
      const isNewlyCreated = typeof response === 'object'
      const experimentId = isNewlyCreated ? response.id : Number(experiment.id)
      const experimentConfig = isNewlyCreated
        ? this.formatBlankExperimentConfig()
        : experiment.config
      const { isSetUpComplete, nextStep } =
        await this.handleCheckUpdateActiveStep({
          id: experimentId,
          config: experimentConfig,
        })

      const basePath = `/experiments/${experimentId}`
      const path = isSetUpComplete
        ? `${basePath}/rollout/overrides`
        : `${basePath}/set-up/${nextStep}`

      this.handleSetCurrentlyViewing({
        basePath,
        path,
        isSetUpComplete,
        experiment: response,
      })

      if (isSetUpComplete) {
        await this.handleCompletedForm({
          basePath,
          path,
          isSetUpComplete,
          experimentId,
        })
      } else {
        scrollToNavigationPosition()

        await this.setState({
          activeStep: nextStep,
          isDirty: false,
        })
      }

      history.replace(path)
    }
  }

  // API METHODS
  handleSubmit = async () => {
    const { activeStep, isDirty } = this.state

    await this.setState(({ loading }) => ({
      loading: {
        ...loading,
        submit: true,
      },
      errorMessage: null,
    }))

    // Find the matching class attribute of the active step and call it's handleSubmit method
    const wasSuccessful = isDirty ? await this[activeStep].handleSubmit() : true

    if (wasSuccessful) {
      await this.handleFormSuccessActions(wasSuccessful)
    } else {
      // If the return from the step is false, get the associated error message
      //   from the step component
      const errorMessage = this[activeStep].getErrorMessage()
      await this.setState({
        errorMessage,
      })
    }

    await this.setState(({ loading }) => ({
      loading: {
        ...loading,
        submit: false,
      },
    }))
    scrollToNavigationPosition()
  }

  handleApproveTechSettings = async () => {
    const { activeStep, techApprovalSteps, techSettingsSessionTypeChange } =
      this.state
    const {
      match: {
        params: { id },
      },
      experimentDetailsData: {
        experiment: {
          config,
          activationEventUuid,
          activationEvent,
          variants,
          experimentType,
        },
      },
    } = this.props

    const parsedConfig = JSON.parse(config)

    const incompleteTechSteps = Object.keys(parsedConfig.setUpSteps).filter(
      step => {
        const match = techApprovalSteps.find(label => step === label)
        const isIncomplete =
          parsedConfig.setUpSteps[step].status === 'incomplete'

        if (match && isIncomplete) {
          return step
        }

        return false
      }
    )

    if (techSettingsSessionTypeChange) {
      incompleteTechSteps.push('activation')
    }

    incompleteTechSteps.forEach(incompleteStep => {
      // DYNEXP-2319 Metrics Architecture
      // Need to make sure if metrics is incomplete we make them go to the metrics page
      if (incompleteStep === 'metrics') {
        parsedConfig.setUpSteps[incompleteStep].status = 'incomplete'
      } else {
        parsedConfig.setUpSteps[incompleteStep].status = 'complete'
      }
      if (incompleteStep === 'variants') {
        //if it's an AA experiment, does not require a json payload
        if (experimentType.id === 1) {
          parsedConfig.setUpSteps[incompleteStep].status = 'complete'
        } else {
          variants.forEach(variant => {
            if (variant.displayOrder !== 0 && !variant.jsonPayload) {
              this.setState({
                approveVariantsError: true,
              })
              parsedConfig.setUpSteps[incompleteStep].status = 'incomplete'
            }
          })
        }
      }

      if (
        incompleteStep === 'activation' &&
        !activationEventUuid &&
        !activationEvent
      ) {
        // XXX
        parsedConfig.setUpSteps[incompleteStep].status = 'incomplete'
      }
    })

    const areAllStepsComplete =
      Object.keys(parsedConfig.setUpSteps).filter(
        step => parsedConfig.setUpSteps[step].status !== 'complete'
      ).length === 0

    const newConfig = JSON.stringify(parsedConfig)

    const configInput = {
      config: newConfig,
      id: Number(id),
    }

    try {
      await client.mutate({
        mutation: APPROVE_TECH_SETTINGS,
        variables: {
          experimentId: Number(id),
        },
      })

      await client.mutate({
        mutation: UPDATE_EXPERIMENT_CONFIG,
        variables: {
          id: Number(id),
          input: configInput,
        },
      })

      const basePath = `/experiments/${id}`
      const path = `${basePath}/set-up/${activeStep}`

      this.handleSetCurrentlyViewing({
        basePath,
        path,
        isSetUpComplete: areAllStepsComplete,
      })

      this.setState(({ setUpSteps }) => ({
        areAllStepsComplete,
        setUpSteps: Object.keys(setUpSteps).map(step => {
          const stepObject = setUpSteps[step]
          if (incompleteTechSteps.includes(stepObject.label)) {
            //handle variant step completion separately because of the check for variant overrides
            if (stepObject.label === 'variants') {
              return {
                ...stepObject,
                status: this.state.approveVariantsError
                  ? 'incomplete'
                  : 'complete',
              }
            }
            return {
              ...stepObject,
              status: 'complete',
            }
          }
          return stepObject
        }),
      }))
    } catch (error) {
      const input = formatLoggingError(error, { id })
      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input,
        },
      })

      this.setState({ approveTechSettingsError: true })
    }
  }

  handleUpdateExperimentConfig = async (
    experimentId,
    experimentConfig,
    activeStep,
    stepStatus
  ) => {
    // Additional try/catch needed as insert submission will not catch
    // an error when creating the config
    const id = Number(experimentId)

    try {
      const parsedConfig = JSON.parse(experimentConfig)
      parsedConfig.setUpSteps[activeStep].status = stepStatus

      const config = JSON.stringify(parsedConfig)

      const input = {
        id,
        config,
      }

      await client.mutate({
        mutation: UPDATE_EXPERIMENT_CONFIG,
        variables: {
          input,
        },
        refetchQueries: [
          {
            query: GET_EXPERIMENT_DETAILS,
            variables: {
              id,
            },
          },
        ],
      })

      return config
    } catch (error) {
      const input = formatLoggingError(error, { id })

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input,
        },
      })

      this.setState({
        errorMessage: copyContent.updateConfigError,
      })

      return null
    }
  }

  // VALIDATION METHODS
  validateIsDisabled = () => {
    const {
      applicationPermissions,
      experimentDetailsData: { experiment },
    } = this.props

    const {
      isFinalized,
      environmentSamplings,
      permissions: experimentPermissions,
      experimentStatus,
    } = experiment

    const permissionsArgs = {
      applicationPermissions,
      experimentPermissions,
      environmentSamplings,
    }

    const canUpdateAllRunning = hasPermission({
      ...permissionsArgs,
      permissionId: EXPERIMENT_UPDATE_RUNNING_ALL,
    })

    // This admin permission always enables the fields
    if (canUpdateAllRunning) {
      // Unless the status of the expermint is completed or cancelled
      return (
        experimentStatus.id === experimentStatusEnum.COMPLETED ||
        experimentStatus.id === experimentStatusEnum.CANCELLED
      )
    }

    const canUpdate = hasPermission({
      ...permissionsArgs,
      permissionId: EXPERIMENT_UPDATE,
    })

    if (!canUpdate || isFinalized) {
      return true
    }

    return false
  }

  validateCanSubmit = async () => {
    const { activeStep } = this.state
    const isValid = await this[activeStep].validateSubmission()
    await this.setState({ isValid })
    return isValid
  }

  render() {
    const {
      activeStep,
      setUpSteps,
      isCMSExperiment,
      redirectLocation,
      techApprovalSteps,
      isDirty,
      isDisabled,
      isCancelModalOpen,
      areAllStepsComplete,
      canUpdateRunning,
      canUpdateTechSettings,
      canUpdateActivation,
      canApproveTechSettings,
      canUpdateVersionRunning,
      shouldCheckActivationChange,
      isChangeActivationEventModalOpen,
      success,
      loading,
      errorMessage,
      approveTechSettingsError,
      approveVariantsError,
      isOverridesDeleted,
      hashVariables,
      isNetworkLockdownActive,
    } = this.state

    const {
      user,
      history,
      match: {
        params: { id },
      },
      onNavigate,
      currentlyViewing,
      applicationPermissions,
      experimentDetailsData: { loading: detailsLoading, experiment },
      currentUser,
    } = this.props

    const {
      saveButton,
      saveAndContinueButton,
      cancelButton,
      techPocApprovalTitle,
      techPocDescription,
      techPocApproveButton,
      variantsErrorOnApproval,
      needsApprovalTitle,
      needsApprovalDescription,
      errorApproveSettingsTitle,
      errorApproveSettingsDescription,
      errorApproveLinkText,
      errorApproveEmailSubject,
      overrideNotice,
      activation: { changeConfirmMessage, changeConfirmButton },
    } = copyContent

    if (detailsLoading) {
      return (
        <div className="app__loader">
          <KiteLoader size="7rem" />
        </div>
      )
    }

    const config = experiment
      ? experiment.config
      : this.formatBlankExperimentConfig()
    let isTechApprovalStepIncomplete
    let noTechApprovalStepsInitial
    let stepStatus

    if (setUpSteps.length) {
      isTechApprovalStepIncomplete =
        techApprovalSteps.includes(activeStep) &&
        setUpSteps.find(step => step.label === activeStep).status ===
          'incomplete'

      noTechApprovalStepsInitial =
        techApprovalSteps.filter(
          techStep =>
            setUpSteps.find(step => step.label === techStep).status ===
            'initial'
        ).length === 0

      stepStatus = setUpSteps.find(step => step.label === activeStep).status
    }

    const isTechPocApproved = experiment && experiment.isTechPocApproved
    let productUuid
    let experimentStartDate
    let experimentCreatedDate
    let experimentTypeId
    let experimentStatusId

    if (experiment) {
      const {
        experimentToProductFeatures,
        startTime,
        createdTime,
        experimentType: { id: typeId },
        experimentStatus: { id: statusId },
      } = experiment

      const {
        product: { uuid },
      } = experimentToProductFeatures[0]
      experimentStartDate = startTime
      experimentCreatedDate = createdTime
      productUuid = uuid
      experimentTypeId = typeId
      experimentStatusId = statusId
    }

    const planSummaryEnabled = activeStep === 'plan' && canUpdateRunning
    const variantsEnabled =
      (activeStep === 'variants' || activeStep === 'variants&content') &&
      (canUpdateRunning || canUpdateTechSettings)
    const techVersionEnabled =
      activeStep === 'tech settings' &&
      (canUpdateTechSettings || canUpdateVersionRunning)
    const activationEnabled =
      activeStep === 'activation' && (canUpdateRunning || canUpdateActivation)
    const updateRunningSubmitEnabled =
      planSummaryEnabled ||
      variantsEnabled ||
      techVersionEnabled ||
      activationEnabled
    let enabledSubmit = updateRunningSubmitEnabled
      ? false
      : isDisabled || loading.submit

    if (
      activeStep === 'variants' &&
      experimentTypeId === experimentTypeEnum.AB
    ) {
      enabledSubmit =
        (!updateRunningSubmitEnabled && (loading.submit || isDisabled)) ||
        experiment.permissions.length === 0 ||
        experimentStatusId === experimentStatusEnum.COMPLETED ||
        experimentStatusId === experimentStatusEnum.CANCELLED
    }

    return (
      <form className="set-up-experiment">
        <h4>Set Up Your Experiment</h4>
        <LinearProgressStepper
          className="set-up-experiment__stepper"
          activeStep={activeStep}
          steps={setUpSteps}
          onClick={this.handleSelectStep}
          leanLeft
        />

        <TdcsUpdateNotification
          id={id}
          environmentSampling={
            experiment ? experiment.environmentSamplings : []
          }
          history={history}
          isCmsExperiment={isCMSExperiment}
          activeNetworkLockdown={isNetworkLockdownActive}
        />

        <ExpansionPanel type="minimal" isExpanded={!!approveTechSettingsError}>
          <KiteAlert
            className="app__page-level-message"
            type="alert"
            title={errorApproveSettingsTitle}
            description={errorApproveSettingsDescription}
            linkText={errorApproveLinkText}
            onLinkClick={() => {
              window.location = errorApproveEmailSubject(user)
            }}
            onClose={() => this.setState({ approveTechSettingsError: null })}
          />
        </ExpansionPanel>

        <ExpansionPanel type="minimal" isExpanded={!!errorMessage}>
          <KiteAlert
            className="app__page-level-message"
            type="alert"
            description={errorMessage && `${errorMessage}. If this continues `}
            linkText="contact support."
            onLinkClick={() => {
              window.location = `mailto:DL-Distillery-Support@charter.com?subject=Distillery Support for Publishing - ${user.displayName}`
            }}
            onClose={() => this.setState({ errorMessage: null })}
          />
        </ExpansionPanel>

        <ExpansionPanel
          type="minimal"
          isExpanded={
            noTechApprovalStepsInitial &&
            isTechApprovalStepIncomplete &&
            !isTechPocApproved &&
            !canApproveTechSettings
          }
        >
          <KiteAlert
            className="app__page-level-message"
            type="caution"
            title={needsApprovalTitle}
            description={needsApprovalDescription}
          />
        </ExpansionPanel>

        <ExpansionPanel type="minimal" isExpanded={approveVariantsError}>
          <KiteAlert
            className="app__page-level-message"
            type="alert"
            title="Error"
            description={variantsErrorOnApproval}
            linkText={'Go to variants'}
            onLinkClick={() => this.handleSelectStep('variants')}
          />
        </ExpansionPanel>

        <ExpansionPanel type="minimal" isExpanded={isOverridesDeleted}>
          <KiteAlert
            className="app__page-level-message"
            type="caution"
            description={overrideNotice}
            onClose={() => this.handleUpdateOverrideBanner(false)}
          />
        </ExpansionPanel>
        <ExpansionPanel
          type="minimal"
          isExpanded={
            noTechApprovalStepsInitial &&
            isTechApprovalStepIncomplete &&
            !isTechPocApproved &&
            canApproveTechSettings
          }
        >
          <KiteAlert
            className="app__page-level-message"
            type="info"
            title={techPocApprovalTitle}
            description={techPocDescription}
            linkText={techPocApproveButton}
            onLinkClick={this.handleApproveTechSettings}
          />
        </ExpansionPanel>

        <ExpansionPanel type="minimal" isExpanded={!!success}>
          <KiteAlert
            className="app__page-level-message"
            type="confirm"
            title={success && success.title}
            description={success && success.description}
          />
        </ExpansionPanel>

        {activeStep === 'plan' && (
          <PlanStep
            id={Number(id)}
            hashVariables={hashVariables}
            onChange={this.handleSetDirty}
            isCMSExperiment={isCMSExperiment}
            disabled={isDisabled}
            currentUser={currentUser}
            experimentCategoryId={!isCMSExperiment ? 1 : 2}
            permissionEnum={permissionEnumReverse[EXPERIMENT_CREATE]}
            currentlyViewing={currentlyViewing}
            applicationPermissions={applicationPermissions}
            experimentPermissions={experiment ? experiment.permissions : []}
            environmentSamplings={experiment && experiment.environmentSamplings}
            experimentConfig={config}
            formatBlankExperimentConfig={this.formatBlankExperimentConfig}
            onUpdateProgress={this.handleUpdateProgress}
            handleUpdateOverrideBanner={this.handleUpdateOverrideBanner}
            onNavigate={onNavigate}
            ref={component => {
              const plan =
                component &&
                component
                  .getWrappedInstance()
                  .getWrappedInstance()
                  .getWrappedInstance()
                  .getWrappedInstance()
                  .getWrappedInstance()
                  .getWrappedInstance()
                  .getWrappedInstance()
              this.plan = plan
            }}
          />
        )}

        {activeStep === 'variants' && (
          <VariantsStep
            id={Number(id)}
            applicationPermissions={applicationPermissions}
            experimentPermissions={experiment ? experiment.permissions : []}
            environmentSamplings={experiment && experiment.environmentSamplings}
            onChange={this.handleSetDirty}
            disabled={isDisabled}
            experimentConfig={config}
            canApproveJsonPayload={canApproveTechSettings}
            ref={component => {
              const variants =
                component &&
                component
                  .getWrappedInstance()
                  .getWrappedInstance()
                  .getWrappedInstance()
                  .getWrappedInstance()
                  .getWrappedInstance()
                  .getWrappedInstance()
                  .getWrappedInstance()

              this.variants = variants
            }}
          />
        )}

        {activeStep === 'metrics' && (
          <MetricsStep
            id={Number(id)}
            onChange={this.handleSetDirty}
            disabled={isDisabled}
            productUuid={productUuid}
            experimentStartDate={experimentStartDate}
            handleSetErrorMessages={this.handleSetErrorMessages}
            experimentCreatedDate={experimentCreatedDate}
            ref={component => {
              this.metrics = component
            }}
          />
        )}

        {activeStep === 'tech settings' && (
          <TechSettingStep
            id={Number(id)}
            disabled={isDisabled && !canUpdateTechSettings}
            experimentConfig={config}
            canApproveTechSettings={canApproveTechSettings}
            applicationPermissions={applicationPermissions}
            experimentPermissions={experiment ? experiment.permissions : []}
            environmentSamplings={experiment && experiment.environmentSamplings}
            onChange={this.handleSetDirty}
            handleUpdateOverrideBanner={this.handleUpdateOverrideBanner}
            ref={component => {
              const techSettings = component && component.getWrappedInstance()
              this[activeStep] = techSettings
            }}
          />
        )}

        {activeStep === 'activation' && (
          <ActivationStep
            id={Number(id)}
            onChange={this.handleSetDirty}
            disabled={isDisabled && !canUpdateActivation}
            status={stepStatus}
            canApproveActivationEvent={canApproveTechSettings}
            ref={component => {
              const activation =
                component && component.getWrappedInstance().getWrappedInstance()
              this[activeStep] = activation
            }}
          />
        )}

        {activeStep === 'variants&content' && (
          <ContentStep
            id={Number(id)}
            onChange={this.handleSetDirty}
            disabled={isDisabled}
            hashVariables={hashVariables}
            currentUser={currentUser}
            experimentCategoryId={!isCMSExperiment ? 1 : 2}
            permissionEnum={permissionEnumReverse[EXPERIMENT_CREATE]}
            currentlyViewing={currentlyViewing}
            applicationPermissions={applicationPermissions}
            canApproveTechSettings={canApproveTechSettings}
            experimentPermissions={experiment ? experiment.permissions : []}
            environmentSamplings={experiment && experiment.environmentSamplings}
            experimentConfig={config}
            formatBlankExperimentConfig={this.formatBlankExperimentConfig}
            onUpdateProgress={this.handleUpdateProgress}
            handleUpdateOverrideBanner={this.handleUpdateOverrideBanner}
            onNavigate={onNavigate}
            ref={component => {
              const contents =
                component &&
                component
                  .getWrappedInstance()
                  .getWrappedInstance()
                  .getWrappedInstance()
                  .getWrappedInstance()
                  .getWrappedInstance()
                  .getWrappedInstance()
                  .getWrappedInstance()
              this[activeStep] = contents
            }}
          />
        )}

        {activeStep === 'audience' && (
          <AudienceStep
            id={Number(id)}
            onChange={this.handleSetDirty}
            disabled={isDisabled}
            currentUser={currentUser}
            experimentCategoryId={!isCMSExperiment ? 1 : 2}
            permissionEnum={permissionEnumReverse[EXPERIMENT_CREATE]}
            currentlyViewing={currentlyViewing}
            applicationPermissions={applicationPermissions}
            canApproveTechSettings={canApproveTechSettings}
            experimentPermissions={experiment ? experiment.permissions : []}
            environmentSamplings={experiment && experiment.environmentSamplings}
            experimentConfig={config}
            formatBlankExperimentConfig={this.formatBlankExperimentConfig}
            onUpdateProgress={this.handleUpdateProgress}
            handleUpdateOverrideBanner={this.handleUpdateOverrideBanner}
            onNavigate={onNavigate}
            ref={component => {
              const audience =
                component &&
                component
                  .getWrappedInstance()
                  .getWrappedInstance()
                  .getWrappedInstance()
                  .getWrappedInstance()
              this[activeStep] = audience
            }}
          />
        )}

        <div
          className={`set-up-experiment__footer${errorMessage ? ' error' : ''}`}
        >
          <KiteButton
            className="set-up-experiment__continue-button"
            size="large"
            disabled={enabledSubmit || !isDirty & (activeStep !== 'audience')}
            loading={loading.submit}
            onClick={async event => {
              if (await this.validateCanSubmit()) {
                if (
                  shouldCheckActivationChange &&
                  activeStep === 'activation' &&
                  isDirty
                ) {
                  this.setState({
                    isChangeActivationEventModalOpen: true,
                  })
                } else {
                  this.handleSubmit(event)
                }
              }
            }}
          >
            {areAllStepsComplete ? saveButton : saveAndContinueButton}
          </KiteButton>

          <KiteButton
            className="set-up-experiment__cancel-button"
            size="small"
            type="standalone-link"
            disabled={loading.submit}
            onClick={
              isDirty
                ? event => this.handleRedirect(event, 'back')
                : this.handleGoBack
            }
          >
            {cancelButton}
          </KiteButton>
        </div>

        {isCancelModalOpen && (
          <Modal
            message={
              redirectLocation === 'back'
                ? 'Are you sure you want to cancel?'
                : 'Did you want to save your changes?'
            }
            subMessage="Any unsaved changes will be lost"
            onConfirm={
              redirectLocation === 'back'
                ? this.handleGoBack
                : this.handleConfirmChangeSteps
            }
            onDeny={() => this.setState({ isCancelModalOpen: false })}
            buttonProps={{ confirm: { value: 'Cancel Changes' } }}
          />
        )}

        {isChangeActivationEventModalOpen && (
          <Modal
            message={changeConfirmMessage}
            onConfirm={() => {
              this.setState({
                isChangeActivationEventModalOpen: false,
              })
              this.handleSubmit()
            }}
            onDeny={() =>
              this.setState({
                isChangeActivationEventModalOpen: false,
              })
            }
            buttonProps={{
              confirm: {
                value: changeConfirmButton,
                type: 'outline',
              },
              deny: { type: 'warning-outline' },
            }}
          />
        )}
      </form>
    )
  }
}

const NewSetUpExperiment = SetUpExperiment

class SetUpSwitcher extends Component {
  ref = null

  static propTypes = {
    experimentDetailsData: object,
  }

  static defaultProps = {
    experimentDetailsData: {},
  }

  render() {
    const { applicationPermissions } = this.props

    const { featureFlags, currentUser } = client.readQuery({
      query: gql`
        query getCurrentUser {
          currentUser {
            id
            displayName
          }
          featureFlags {
            id
            name
            active
          }
        }
      `,
    })

    const isCmsActive = isFeatureFlagActive({
      featureFlags,
      featureFlagId: featureFlagEnum.CMS_EXPERIMENTATION,
      applicationPermissions,
    })

    if (!isCmsActive) {
      return (
        <OldSetUpExperiment
          {...this.props}
          ref={component => {
            this.ref = component
          }}
        />
      )
    }
    return (
      <NewSetUpExperiment
        {...this.props}
        currentUser={currentUser}
        ref={component => {
          this.ref = component
        }}
      />
    )
  }
}

export default flowRight(getExperimentDetails)(SetUpSwitcher)
