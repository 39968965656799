import { gql } from '@apollo/client'

export const GET_CONTENT_MAP = gql`
  query getContentMap($id: Int!) {
    contentMap(id: $id) {
      id
      name
      productId
      product {
        id
        displayName
      }
      featureId
      feature {
        id
        displayName
      }
      statusId
      status {
        id
        name
      }
      config
      contentAreaId
      startTime
      stopTime
      comment
      jiraTicket
      publishedError
      isApprovalReady
      hasActiveContentArea {
        id
        stopTime
      }
      contentMapToUser {
        id
        userId
        user {
          id
          displayName
          email
        }
        roleId
      }
      personalizedContent {
        id
        audienceId
        audience {
          id
          name
          rules
        }
        contentId
        contentJson
        content {
          contentId
          contentTitle
        }
        priority
        contentJson
      }
    }
  }
`
