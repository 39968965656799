/*
* Sample Input
* {
    productId: 1
  }
*/

const getMetricMeanAndSigmaValues = {
  query: `
    findMeanAndSigmaValues (productId: $id) {
      id
      name
      metricId
      productId
      metricMean
      sigma
    }
  `,
  variables: {
    $id: 'Int!',
  },
};

export default getMetricMeanAndSigmaValues;
