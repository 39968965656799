import React, { Component } from 'react'
import isEqual from 'lodash/isEqual'
import { array, func, string } from 'prop-types'
import { KiteButton, KiteIcon } from '@kite/react-kite'

import ListBox from '../ListBox/ListBox'
import './ListBuilder.scss'

class ListBuilder extends Component {
  static propTypes = {
    allOptions: array,
    assignedOptions: array,
    availableOptionsTitle: string,
    assignedOptionsTitle: string,
    className: string,
    onSave: func,
    status: string,
  }

  static defaultProps = {
    allOptions: [],
    assignedOptions: [],
    availableOptionsTitle: 'Available',
    assignedOptionsTitle: 'Assigned',
    className: '',
    onSave: null,
    status: null,
  }

  state = {
    assignedOptions: [],
    availableOptions: [],
    selectedAvailableOptions: [],
    selectedAssignedOptions: [],
    entriesModified: false,
    status: null,
  }

  componentDidMount() {
    this.handleUpdateOptions()
  }

  componentDidUpdate(prevProps) {
    const { allOptions, assignedOptions, status } = this.props
    if (
      !isEqual(allOptions, prevProps.allOptions) ||
      !isEqual(assignedOptions, prevProps.assignedOptions)
    ) {
      this.handleUpdateOptions()
    }
    if (status !== prevProps.status) {
      this.handleUpdateStatus()
    }
  }

  // TODO Refactor to use dynamic comparision prop
  compareObjects = (objectA, objectB) => {
    if (
      objectA &&
      objectA.displayName &&
      objectB &&
      objectB.displayName &&
      isEqual(objectA.displayName, objectB.displayName)
    ) {
      return true
    }
    return (
      objectA &&
      objectA.name &&
      objectB &&
      objectB.name &&
      isEqual(objectA.name, objectB.name)
    )
  }

  popItems = (whatToPop, whereToPopFrom) => {
    const store = whereToPopFrom
    for (let iIndex = 0; iIndex < whatToPop.length; iIndex += 1) {
      for (let jIndex = 0; jIndex < whereToPopFrom.length; jIndex += 1) {
        if (this.compareObjects(whatToPop[iIndex], whereToPopFrom[jIndex])) {
          store.splice(jIndex, 1)
        }
      }
    }
    return store
  }

  getAvailableOptions = (allOpts, assignedOpts) =>
    typeof allOpts[0] === 'string' && typeof assignedOpts[0] === 'string'
      ? this.getAvailableOptionsStrings(allOpts, assignedOpts)
      : this.getAvailableOptionsObjects(allOpts, assignedOpts)

  getAvailableOptionsObjects = (allOpts, assignedOpts) => {
    let group1
    let group2
    if (allOpts.length < assignedOpts.length) {
      group1 = [...assignedOpts]
      group2 = [...allOpts]
    } else {
      group1 = [...allOpts]
      group2 = [...assignedOpts]
    }
    const store = group1
    for (let iIndex = 0; iIndex < group1.length; iIndex += 1) {
      for (let jIndex = 0; jIndex < group2.length; jIndex += 1) {
        if (this.compareObjects(group1[iIndex], group2[jIndex])) {
          store.splice(iIndex, 1)
        }
      }
    }
    return store
  }

  getAvailableOptionsStrings = (allOpts, assignedOpts) =>
    allOpts.filter(opt => !assignedOpts.includes(opt))

  handleSelectedAvailableItem = selectedItem => {
    const { selectedAvailableOptions } = this.state
    const index = selectedAvailableOptions.indexOf(selectedItem)
    if (index > -1) {
      selectedAvailableOptions.splice(index, 1)
    } else {
      selectedAvailableOptions.push(selectedItem)
    }

    this.setState({ selectedAvailableOptions })
  }

  handleSelectedAssignedItem = selectedItem => {
    const { selectedAssignedOptions } = this.state
    const index = selectedAssignedOptions.indexOf(selectedItem)

    if (index > -1) {
      selectedAssignedOptions.splice(index, 1)
    } else {
      selectedAssignedOptions.push(selectedItem)
    }

    this.setState({ selectedAssignedOptions })
  }

  handleUpdateOptions = () => {
    const { allOptions, assignedOptions } = this.props
    const availableOptions = this.getAvailableOptions(
      allOptions,
      assignedOptions
    )

    this.setState({
      assignedOptions,
      availableOptions,
      selectedAvailableOptions: [],
      selectedAssignedOptions: [],
    })
  }

  handleUpdateStatus = () => {
    const { status } = this.props

    this.setState({
      entriesModified: status && status !== 'success',
      status,
    })
  }

  // TODO Refactor to updateOptions (updateAssignedOptions & updateAvailableOptions)
  updateAssignedOptions = () => {
    this.setState(
      ({ assignedOptions, selectedAvailableOptions, availableOptions }) => ({
        selectedAvailableOptions: [],
        assignedOptions: [...assignedOptions, ...selectedAvailableOptions],
        availableOptions: this.popItems(
          [...selectedAvailableOptions],
          [...availableOptions]
        ),
        entriesModified: true,
      })
    )
  }

  updateAvailableOptions = () => {
    this.setState(
      ({ availableOptions, selectedAssignedOptions, assignedOptions }) => ({
        selectedAssignedOptions: [],
        assignedOptions: this.popItems(
          [...selectedAssignedOptions],
          [...assignedOptions]
        ),
        availableOptions: [...availableOptions, ...selectedAssignedOptions],
        entriesModified: true,
      })
    )
  }

  // TODO Refactor to handle update AllOptions (allAssignedToAvailableOptions & allAvailableToAssignedOptions)
  allAssignedToAvailableOptions = () => {
    this.setState(({ availableOptions, assignedOptions }) => ({
      assignedOptions: [],
      availableOptions: [...availableOptions, ...assignedOptions],
      selectedAssignedOptions: [],
      entriesModified: true,
    }))
  }

  allAvailableToAssignedOptions = () => {
    this.setState(({ assignedOptions, availableOptions }) => ({
      availableOptions: [],
      assignedOptions: [...assignedOptions, ...availableOptions],
      selectedAvailableOptions: [],
      entriesModified: true,
    }))
  }

  render() {
    const {
      availableOptions,
      assignedOptions,
      selectedAvailableOptions,
      selectedAssignedOptions,
      entriesModified,
      status,
    } = this.state
    const {
      assignedOptionsTitle,
      availableOptionsTitle,
      className,
      onSave,
    } = this.props

    return (
      <div className={`list-builder ${className || ''}`}>
        <div className="list-builder__container">
          <ListBox
            title={availableOptionsTitle}
            values={availableOptions}
            getClickedItem={this.handleSelectedAvailableItem}
            selectedOptions={selectedAvailableOptions}
          />
          <div className="list-builder__controls">
            <KiteButton
              className="list-builder__control"
              type="outline"
              size="small"
              onClick={this.allAvailableToAssignedOptions}
            >
              <KiteIcon name="chevron-right" color="#0073D1" />
              <KiteIcon name="chevron-right" color="#0073D1" />
            </KiteButton>
            <KiteButton
              className="list-builder__control"
              type="outline"
              size="small"
              onClick={this.updateAssignedOptions}
            >
              <KiteIcon name="chevron-right" color="#0073D1" />
            </KiteButton>
            <KiteButton
              className="list-builder__control"
              type="outline"
              size="small"
              onClick={this.updateAvailableOptions}
            >
              <KiteIcon name="chevron-left" color="#0073D1" />
            </KiteButton>
            <KiteButton
              className="list-builder__control"
              type="outline"
              size="small"
              onClick={this.allAssignedToAvailableOptions}
            >
              <KiteIcon name="chevron-left" color="#0073D1" />
              <KiteIcon name="chevron-left" color="#0073D1" />
            </KiteButton>
          </div>
          <ListBox
            title={assignedOptionsTitle}
            values={assignedOptions}
            getClickedItem={this.handleSelectedAssignedItem}
            selectedOptions={selectedAssignedOptions}
          />
        </div>
        <div className="list-builder__save-container">
          <KiteButton
            className="list-builder__save-button"
            type="primary"
            size="large"
            onClick={() => onSave(assignedOptions)}
            disabled={!entriesModified}
            loading={status === 'saving'}
          >
            Save
          </KiteButton>
          {status && (
            <div
              className={`list-builder__save-status ${
                status !== 'Saving' && status
              }`}
            >
              {status === 'success' && status}
              {status === 'error' && status}
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default ListBuilder
