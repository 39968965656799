import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

export const GET_PERMISSIONS = gql`
  query getPermissions {
    permissions {
      id
      name
      permissionTypeId
    }
  }
`

const getPermissions = graphql(GET_PERMISSIONS, {
  name: 'allPermissionsData',
})

export default getPermissions
