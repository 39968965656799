import {
  decorate,
  observable,
  computed,
  action,
  toJS,
  reaction,
  transaction,
} from 'mobx'

import appModel from '../../components/App/AppModel'
import { gql } from '@apollo/client'
import EditorModel from '../../shared/models/EditorModel'
import OREditorModel from './OREditorModel'
import parseCSV from '../../shared/utilities/parseCSV'
import { client } from '../../configuration/configApiClient'
import ORGuidLookupModel from './ORGuidLookupModel'
import { model as orMainModel } from './ORMain'

class ORGroupEditorModel extends EditorModel {
  id = null
  name = ''
  description = ''
  overrideIds = new Set()
  overrides = []
  bulkImports = new Map()
  orSearchValue = ''
  foundObject = null
  fileUploadErrorMessage = null
  guidLookup = null
  appliedInExperiments = []
  hasCheckedAppliedInExperiments = false
  inProgressCheckAppliedInExperiments = null

  start() {
    this.overridesDisposer = reaction(
      () => {
        return [...this.overrideIds.values()].reverse().map(id => {
          return appModel.getEditorModel({
            id,
            EditorModelClass: OREditorModel,
            apiTime: this.apiTime,
          })
        })
      },
      objects => {
        this.overrides.replace(objects)
      }
    )
  }

  startEditing() {
    this.checkAppliedInExperiments()
    super.startEditing()
  }

  static formatNum = (num, name) => (num > 0 ? `${num} ${name}` : '')

  updateFromAPI({ apiObject }) {
    const { id, name, description, overrides } = apiObject
    this.id = id
    this.name = name
    this.description = description
    if (!this.overrideIds.size && overrides && overrides.length) {
      const ids = overrides.map(({ id }) => id)
      this.overrideIds.replace(ids)
    }
  }

  toAPI = () => {
    const input = {
      name: this.name,
      description: this.description,
      overrideIds: [...this.overrideIds.values()],
    }

    if (this.id) {
      input.overrideGroupId = this.id
    }
    return input
  }

  get kiteAlert() {
    if (this.fileUploadErrorMessage)
      return {
        type: 'alert',
        description: this.fileUploadErrorMessage,
      }

    if (this.apiDuplicateError)
      return {
        type: 'alert',
        description:
          'This is a duplicate of an group in Distillery.  Please enter a unique group name.',
      }

    return super.standardKiteAlerts()
  }

  get contains() {
    if (!this.overrides?.length) return ''
    let accounts = 0,
      devices = 0,
      account_devices = 0

    this.overrides.forEach(override => {
      let { type } = override
      type = Number(type)
      switch (type) {
        case 1:
          devices++
          break
        case 2:
          accounts++
          break
        case 3:
          account_devices++
          break
        default:
          throw Error(`invalid type`)
      }
    })

    const formatNum = ORGroupEditorModel.formatNum

    return `${formatNum(devices, 'Devices')} ${formatNum(
      accounts,
      'Accounts'
    )} ${formatNum(account_devices, 'Account_Device')}`
  }

  updateCurrentlyViewing() {
    appModel.updateCurrentlyViewing(
      {
        title: 'Override Management',
        backPath: '/experiments/all',
        backTitle: 'All Experiments',
      },
      true
    )
  }

  async checkAppliedInExperiments(force = false) {
    if (this.hasCheckedAppliedInExperiments && !force) return
    this.hasCheckedAppliedInExperiments = true

    try {
      this.inProgressCheckAppliedInExperiments = client.query({
        query: ORGroupEditorModel.appliedInExperimentsQuery,
        variables: { overrideGroupId: this.id },
      })
      const res = await this.inProgressCheckAppliedInExperiments
      if (res.data.overrideGroup?.appliedInExperiments) {
        const converted = []
        res.data.overrideGroup.appliedInExperiments.forEach(exp => {
          if (!exp) return
          converted.push({
            url: `/experiments/${exp.id}/set-up/plan`,
            ...exp,
          })
        })
        this.appliedInExperiments.replace(converted)
      }
    } finally {
      this.inProgressCheckAppliedInExperiments = null
    }
  }

  get alreadyExists() {
    const answer = !this.id && toJS(this.foundObject) !== null
    return answer
  }

  get nameErrorMessage() {
    if (this.alreadyExists) return 'This group name already exists'
    return this.name && this.name.length > 10
      ? null
      : 'Enter name with > 10 chars and no spaces'
  }

  get insertMutation() {
    return ORGroupEditorModel.insertMutation
  }
  get updateMutation() {
    return ORGroupEditorModel.updateMutation
  }

  get deleteMutation() {
    return ORGroupEditorModel.deleteMutation
  }

  get errorMessages() {
    const { name } = this

    return {
      name:
        name.length > 3 || name.length < 50
          ? null
          : 'Enter a name greater than 3 and < than 50 chars',
      description:
        this.description?.length >= 300
          ? 'Less than 300 chars please :) '
          : null,
    }
  }

  get apiDuplicateError() {
    return (
      this.apiError?.message &&
      this.apiError.message.indexOf('Duplicate entry') !== -1
    )
  }

  filterOREditorModels(orEditorModels) {
    return orEditorModels.filter(({ id }) => !this.overrideIds.has(id))
  }

  addOverride = ({ id }) => {
    this.overrideIds.add(id)
    this.orSearchValue = ''
  }

  deleteOverride = ({ id }) => {
    this.overrideIds.delete(id)
  }

  deleteToFix = ({ accountGuid }) => {}

  deleteToSave = ({ key }) => {}

  get groupSize() {
    return this.inBulkMode
      ? this.overrides.length + this.bulkImports.size
      : this.overrides.length
  }

  processCSVUpload = files => {
    this.fileUploadErrorMessage = null
    if (!files || files.length === 0) {
      this.fileUploadErrorMessage = 'You did not pick a valid CSV file'
      console.error('files', files)
      return
    }
    const reader = new FileReader()
    reader.onload = async () => {
      try {
        // map csv to input
        const lookups = parseCSV(reader.result)
          .filter(([overrideType]) => overrideType !== 'overrideType') // remove header row
          .map(ORGuidLookupModel.lineToLookup)

        if (!this.name?.length && lookups[0]?.overrideName?.includes('-')) {
          const overrideName = lookups[0].overrideName
          this.name = overrideName.substring(0, overrideName.indexOf('-'))
        }

        this.guidLookup = new ORGuidLookupModel()
        this.guidLookup.lookupBulkOverrides(lookups)
      } catch (error) {
        console.error(error)
        this.fileUploadErrorMessage =
          'There was an error processing your csv file'
      }
    }
    reader.readAsText(files[0])
  }

  get inBulkMode() {
    return this.guidLookup !== null
  }

  get inLookup() {
    if (!this.inBulkMode) return null
    return this.guidLookup?.inLookup?.map(ORGroupEditorModel.formatLookup)
  }

  get toCreate() {
    if (!this.inBulkMode) return null
    if (!this.guidLookup?.resultsFoundWithoutOverride?.length) return null
    return this.guidLookup.resultsFoundWithoutOverride.map(
      ORGroupEditorModel.formatLookup
    )
  }

  get alreadyCreated() {
    if (!this.inBulkMode) return null
    if (!this.guidLookup?.resultsWithOverride?.length) return null
    return this.guidLookup.resultsWithOverride.map(
      ORGroupEditorModel.formatLookup
    )
  }

  get toFix() {
    if (!this.inBulkMode) return null
    return this.guidLookup?.resultsNotFound?.map(
      ORGroupEditorModel.formatLookup
    )
  }

  get existingOREditorModels() {
    if (!this.guidLookup?.foundEditorModels?.length) return null

    const answer = this.guidLookup.foundEditorModels.filter(
      model => !this.startObject?.overrideIds.includes(model.id)
    )
    return answer
  }

  get existingOREditorModelsInGroup() {
    if (!this.guidLookup?.foundEditorModels?.length) return null
    return this.guidLookup.foundEditorModels.filter(model =>
      this.startObject?.overrideIds.includes(model.id)
    )
  }

  shouldDisableSave = () => {
    if (this.inBulkMode && this.toCreate?.length) return false
    return !this.isDirty
  }

  preSave = async () => {
    // add this group to existing overrides
    if (this.guidLookup?.resultsWithOverride.length)
      transaction(() => {
        return this.guidLookup?.resultsWithOverride.forEach(
          ({ overrideId }) => {
            this.overrideIds.add(overrideId)
          }
        )
      })
  }

  postSave = async () => {
    this.disposer && this.disposer()

    if (!this.id) throw Error('should have id at this point')

    // add this group to existing overrides
    if (this.existingOREditorModels)
      transaction(() => {
        return this.existingOREditorModels.forEach(model => {
          this.overrideIds.add(model.id)
          model.addToGroup(this)
        })
      })

    if (!this.guidLookup?.resultsFoundWithoutOverride.length) return

    // save of all imported overrides
    const {
      data: { bulkInsertOverrides: overrideIds },
    } = await client.mutate({
      mutation: ORGroupEditorModel.bulkInsertMutation,
      variables: {
        input: this.guidLookup.resultsFoundWithoutOverride.map(result => {
          const {
            overrideName,
            accountGuid,
            clientGuid,
            overrideTypeId,
            productId,
          } = result
          const toSave = {
            name: overrideName,
            overrideIdentifier: OREditorModel.formatIdentifier({
              overrideTypeId,
              accountGuid,
              clientGuid,
            }),
            overrideTypeId,
            productIds: Number.isInteger(productId) ? [productId] : null,
            overrideGroupIds: [this.id],
          }
          return toSave
        }),
      },
    })

    transaction(() => {
      this.guidLookup.resultsFoundWithoutOverride.forEach((result, index) => {
        const editorModel = new OREditorModel()
        editorModel.id = overrideIds[index]
        editorModel.name = result.overrideName
        editorModel.accountGuid = result.accountGuid
        editorModel.clientGuid = result.clientGuid
        editorModel.type = result.overrideTypeId
        if (result.productId) editorModel.productId = result.productId
        editorModel.addToGroup(this)
        appModel.setEditorModel(editorModel)
        this.overrideIds.add(editorModel.id)
        orMainModel.orListModel._editorModels.unshift(editorModel)
      })
      this.guidLookup = null
    })
  }

  static typeName = 'OverrideGroup'
  static queryDataName = 'overrideGroups'
  static displayName = 'Override Group'

  static filter({ row: { name }, toSearch }) {
    return name.toLowerCase().indexOf(toSearch) !== -1
  }

  static formatTypeName(type) {
    return type === 1 ? 'Device' : type === 2 ? 'Account' : 'Account_Device'
  }

  static formatLookup(lookup) {
    const { overrideName, accountGuid, clientGuid, overrideTypeId } = lookup

    return {
      name: overrideName,
      key: overrideName,
      overrideTypeName: ORGroupEditorModel.formatTypeName(overrideTypeId),
      identifier:
        !(accountGuid ?? clientGuid) && lookup.accountNumber
          ? ORGuidLookupModel.formatFQAccountId(lookup)
          : accountGuid ?? clientGuid,
    }
  }

  static fragment = gql`
    fragment ORGroupFields on OverrideGroup {
      id
      name
      description
      overrides {
        id
        name
      }
    }
  `

  static query = gql`
    query getOverrideGroups {
      overrideGroups {
        ...ORGroupFields
      }
    }
    ${ORGroupEditorModel.fragment}
  `

  static appliedInExperimentsQuery = gql`
    query getORGroupAppliedInExperiments($overrideGroupId: Int!) {
      overrideGroup(overrideGroupId: $overrideGroupId) {
        id
        name
        appliedInExperiments {
          id
          name
        }
      }
    }
  `

  static updateMutation = gql`
    mutation updateOverrideGroup($input: UpdateOverrideGroupType!) {
      updateOverrideGroup(input: $input) {
        ...ORGroupFields
      }
    }
    ${ORGroupEditorModel.fragment}
  `

  static insertMutation = gql`
    mutation insertOverrideGroup($input: InsertOverrideGroupType!) {
      insertOverrideGroup(input: $input) {
        ...ORGroupFields
      }
    }
    ${ORGroupEditorModel.fragment}
  `

  static deleteMutation = gql`
    mutation deleteOverrideGroup($id: Int!) {
      deleteOverrideGroup(overrideGroupId: $id)
    }
  `

  static bulkInsertMutation = gql`
    mutation bulkInsertOR($input: [InsertOverrideType!]) {
      bulkInsertOverrides(input: $input)
    }
  `
}

ORGroupEditorModel = decorate(ORGroupEditorModel, {
  name: observable,
  guidLookup: observable.ref,
  description: observable,
  overrideIds: observable,
  overrides: observable,
  foundObject: observable,
  updateFromAPI: action,
  kiteAlert: computed,
  errorMessages: computed,
  apiDuplicateError: computed,
  nameErrorMessage: computed,
  alreadyExists: computed,
  orSearchValue: observable,
  addOverride: action,
  deleteOverride: action,
  deleteToFix: action,
  deleteToSave: action,
  fileUploadErrorMessage: observable,
  toFix: computed,
  inLookup: computed,
  existingOREditorModels: computed,
  inBulkMode: computed,
  toCreate: computed,
  alreadyCreated: computed,
  existingOREditorModelsInGroup: computed,
  appliedInExperiments: observable,
  hasCheckedAppliedInExperiments: observable,
  inProgressCheckAppliedInExperiments: observable.ref,
})

export default ORGroupEditorModel
