import React, { Component } from 'react'
import { object, func, number, bool, array } from 'prop-types'
import flowRight from 'lodash/flowRight'
import isEmpty from 'lodash/isEmpty'
import {
  LinearAllocationChart,
  CircleChart,
  TextArea,
} from '@kite/react-kite-plus'
import { KiteLoader, KiteInput, KiteButton, KiteAlert } from '@kite/react-kite'
import { gql } from '@apollo/client'
import {
  hasPermission,
  experimentStatusEnum,
  experimentEnvironmentEnum,
  permissionEnum,
  createQuantumApolloClient,
  checkSampleLaunch,
  overrideTypeEnum,
  isFeatureFlagActive,
  featureFlagEnum,
} from '@charter/distillery-rules'

import { client } from '../../../../configuration/configApiClient'
import { GET_CURRENT_USER, GET_EXPERIMENT } from '../../../../shared/queries'
import { LOG_ERROR } from '../../../../shared/mutations'
import {
  formatLoggingError,
  scrollToNavigationPosition,
} from '../../../../shared/utilities'
import getExperimentSampling, {
  GET_EXPERIMENT_SAMPLING,
  GET_QUANTUM_METRICS,
} from './queries/getExperimentSampling'
import {
  publishExperimentToEnvironment,
  publishUpdateVariantToEnvironment,
  republishUpdateToTdcs,
  republishUpdateToDre,
  updateExperimentToEnvironment,
  resetExperiment,
  updateChangeRequestOnExperiment,
} from './mutations'
import copyContent from '../../data/copyContent'
import {
  AllocationHistory,
  CancelCompleteButton,
  EndExperimentButton,
} from '../../../../components'

import { Modal, ExpansionPanel } from '../../../../componentLibrary'
import './SampleLaunch.scss'

const variantColors = {
  0: '#478eeb',
  1: '#783677',
  2: '#ee8a2a',
  3: '#0d6031',
  4: '#ffec99',
  5: '#b1e0f3',
  6: '#d8d0d8',
  7: '#f6d3b1',
  8: '#92c1a6',
  9: '#001019',
}

const { PRODUCTION } = experimentEnvironmentEnum
const { TESTING, RUNNING, COMPLETED, CANCELLED } = experimentStatusEnum
const {
  EXPERIMENT_TERMINATE,
  EXPERIMENT_REPUBLISH,
  EXPERIMENT_CLONE,
  EXPERIMENT_DELETE,
  EXPERIMENT_DELETE_TESTING,
} = permissionEnum

export class SampleLaunch extends Component {
  static propTypes = {
    id: number.isRequired,
    user: object.isRequired,
    onNavigate: func.isRequired,
    hasInAppPreferences: bool.isRequired,
    history: object.isRequired,
    selectedEnvironment: object.isRequired,
    samplingData: object.isRequired,
    onSubmit: func.isRequired,
    disabled: bool.isRequired,
    applicationPermissions: array.isRequired,
    experimentPermissions: array.isRequired,
    environmentSamplings: array.isRequired,
    publishExperimentToEnvironment: func.isRequired,
    publishUpdateVariantToEnvironment: func.isRequired,
    republishUpdateToTdcs: func.isRequired,
    updateExperimentToEnvironment: func.isRequired,
    resetExperiment: func.isRequired,
    updateChangeRequestOnExperiment: func.isRequired,
    tdcsSessionType: object.isRequired,
    queryQuantum: func,
    activeNetworkLockdown: bool.isRequired,
  }

  static defaultProps = {
    queryQuantum: null,
  }

  state = {
    sampleAllocation: null,
    variants: null,
    comment: '',
    isDirty: false,
    isConfirmDiscardModalOpen: false,
    isConfirmCancelModalOpen: false,
    isCancelModalOpen: false,
    isExperimentResetModalOpen: false,
    resetSubMessage: null,
    isWarnAllocationModalOpen: false,
    warnAllocationMessage: null,
    warnAllocationSubMessage: null,
    quantumMetrics: [],
    allocationHistoryContainerHeight: '30rem',
    isShowingCommentArea: false,
    isReminderModalOpen: false,
    isSoakBypassModalOpen: false,
    crqRequestNumber: '',
    expsiTicketNumber: '',
    loading: {
      submit: false,
    },
    success: {
      insert: null,
      update: null,
    },
    info: {
      needsOverride: null,
    },
    errorMessage: {
      insert: null,
      update: null,
      comment: null,
      errorOneVariantWithWeightAboveZero: null,
      crqRequestNumber: false,
      expsiTicketNumber: false,
    },
  }

  // LIFECYCLE METHODS
  async componentDidMount() {
    const { samplingData } = this.props

    if (samplingData && samplingData.experiment) {
      await this.handleSetSamplingData()
      let status = 'draft'
      switch (status) {
        case samplingData.experiment.experimentId === TESTING:
          status = 'testing'
          break
        case samplingData.experiment.experimentId === RUNNING:
          status = 'running'
          break
        case samplingData.experiment.experimentId === COMPLETED:
          status = 'completed'
          break
        case samplingData.experiment.experimentId === CANCELLED:
          status = 'cancelled'
          break
        default:
          break
      }
      this.handleSetCurrentlyViewing(status)
    }
  }

  async componentDidUpdate(prevProps) {
    const { samplingData, selectedEnvironment } = this.props
    const { samplingData: prevSampling, selectedEnvironment: prevEnvironment } =
      prevProps

    if (selectedEnvironment.id !== prevEnvironment.id) {
      await this.handleSetSamplingData()
    }

    if (samplingData.experiment && !prevSampling.experiment) {
      await this.handleSetSamplingData()
      setTimeout(() => this.handleSetAllocationHistoryHeight(), 150)
      let status = 'draft'
      switch (status) {
        case samplingData.experiment.experimentId === TESTING:
          status = 'testing'
          break
        case samplingData.experiment.experimentId === RUNNING:
          status = 'running'
          break
        case samplingData.experiment.experimentId === COMPLETED:
          status = 'completed'
          break
        case samplingData.experiment.experimentId === CANCELLED:
          status = 'cancelled'
          break
        default:
          break
      }
      this.handleSetCurrentlyViewing(status)
    }

    // Use a normal non number type for the Kite circle input
    const kiteCircleInput = document.getElementById('percentage')
    if (kiteCircleInput && kiteCircleInput.type === 'number') {
      kiteCircleInput.type = 'text'
    }
  }

  // LOCAL STATE CHANGE/TOGGLE METHODS
  handleSetSamplingData = async () => {
    const {
      samplingData: { experiment },
      selectedEnvironment,
      queryQuantum,
    } = this.props
    const { crqRequestNumber, expsiTicketNumber } = experiment
    //this finds variant sampling info for the correct environment
    const {
      id: environmentSamplingId,
      sampleAllocation,
      environmentSamplingToVariants,
    } = experiment.environmentSamplings.find(
      sampling => sampling.environment.id === selectedEnvironment.id
    )

    const variants = experiment.variants
      .slice()
      .sort((aVariant, bVariant) =>
        aVariant.displayOrder > bVariant.displayOrder ? 1 : -1
      )
      .reduce((accumulator, { id: variantId, name }) => {
        if (!accumulator[name]) {
          const match =
            environmentSamplingToVariants.length > 0 &&
            environmentSamplingToVariants.find(
              sampling => sampling.variant.id === variantId
            )

          if (match) {
            const { id: variantSamplingToEnvironmentId, weight } = match

            accumulator[name] = {
              weight,
              variantId,
              variantSamplingToEnvironmentId,
            }
          } else {
            accumulator[name] = {
              weight: 1,
              variantId,
            }
          }
        }

        return accumulator
      }, {})

    let quantumMetrics = []
    if (!queryQuantum) {
      quantumMetrics = await this.queryQuantum()
    }
    this.setState({
      environmentSamplingId,
      sampleAllocation: Math.floor(sampleAllocation * 100),
      comment: '',
      variants,
      quantumMetrics,
      crqRequestNumber: crqRequestNumber !== null ? crqRequestNumber : '',
      expsiTicketNumber: expsiTicketNumber !== null ? expsiTicketNumber : '',
      isDirty: false,
    })
  }

  handleSetAllocationHistoryHeight = async () => {
    const allocationHistoryContainer = document.getElementsByClassName(
      'sample-launch__sampling-allocation'
    )[0]

    const elementHeight = (allocationHistoryContainer.clientHeight - 24) / 16

    if (elementHeight > 30) {
      this.setState({
        allocationHistoryContainerHeight: `${elementHeight}rem`,
      })
    }
  }

  handleChangeSampling = ({ target: { value } }) => {
    const sampleAllocation = Math.trunc(+Number(value))

    if (sampleAllocation >= 0 && sampleAllocation <= 100) {
      this.setState(
        {
          sampleAllocation,
        },
        () => this.validateIsDirty()
      )
    }
  }

  handleCancelSuccess = async status => {
    const { history } = this.props
    await this.setState({ isCancelModalOpen: false })
    history.replace(`/experiments/${status.toLowerCase()}`)
  }

  handleChangeVariantAllocation = async ({ target: { name, value } }) => {
    const {
      samplingData: { experiment },
      selectedEnvironment: { id: environmentId },
    } = this.props
    const {
      errorMessage: { errorOneVariantWithWeightAboveZero },
      sampleAllocation,
    } = this.state

    const { allocationHistory } = experiment.environmentSamplings.find(
      sampling => sampling.environment.id === environmentId
    )
    const weight = Math.trunc(Number(value))

    if (errorOneVariantWithWeightAboveZero) {
      this.setState(({ errorMessage }) => ({
        errorMessage: {
          ...errorMessage,
          errorOneVariantWithWeightAboveZero: null,
        },
      }))
    }

    // Verify the weight is over 0 and allocation changes are not in testing
    if (weight >= 0 && (sampleAllocation > 0 || allocationHistory.length > 0)) {
      await this.setState(
        ({ variants }) => ({
          variants: {
            ...variants,
            [name]: {
              ...variants[name],
              weight,
            },
          },
        }),
        () => this.validateIsDirty()
      )
    } else if (weight >= 0) {
      await this.setState(
        ({ variants }) => ({
          variants: {
            ...variants,
            [name]: {
              ...variants[name],
              weight,
            },
          },
        }),
        () => this.validateIsDirty()
      )
    }
  }

  handleChangeComment = async ({ target: { value } }) => {
    await this.setState(
      {
        comment: value,
        errorMessage: {
          comment:
            value.length < 35
              ? 'Please add a comment with minimum of 35 characters.'
              : null,
        },
      },
      () => this.validateIsDirty()
    )
  }

  handleDistributeEvenly = () => {
    const { variants } = this.state
    Object.keys(variants).forEach(name => {
      this.handleChangeVariantAllocation({ target: { name, value: 1 } })
    })
  }

  handleSetCrqRequestNumber = async ({ target: { value } }) => {
    await this.setState(
      {
        crqRequestNumber: value,
        errorMessage: {
          crqRequestNumber: false,
        },
      },
      () => this.validateIsDirty()
    )
  }

  handleSetExpsiTicketNumber = async ({ target: { value } }) => {
    await this.setState(
      {
        expsiTicketNumber: value,
        errorMessage: {
          expsiTicketNumber: false,
        },
      },
      () => this.validateIsDirty()
    )
  }

  handleSetDisplayedErrorMessage = (error, name, type) => {
    const { sampleAllocation } = this.state
    const {
      selectedEnvironment: { id: environmentId },
    } = this.props
    const {
      errorNotQuantumSynced,
      errorNotTdcsSynced,
      errorNotApproved,
      errorPublish,
    } = copyContent.sampleLaunch

    let displayedErrorMessage

    const isNotQuantumSynced = error.message.includes('Quantum')
    const isNotTdcsSynced = error.message.includes('TDCS')
    const isNotTechPocApproved = error.message.includes('not tech POC approved')

    if (isNotTechPocApproved) {
      displayedErrorMessage = errorNotApproved
    } else if (isNotQuantumSynced) {
      displayedErrorMessage = errorNotQuantumSynced
    } else if (isNotTdcsSynced) {
      displayedErrorMessage = errorNotTdcsSynced
    } else if (type === 'republish') {
      displayedErrorMessage = errorPublish(name, 'republish')
    } else {
      const isInProductionAboveZero =
        environmentId === PRODUCTION && sampleAllocation > 0

      displayedErrorMessage = errorPublish(
        name,
        isInProductionAboveZero ? 'launch' : 'publish'
      )
    }

    this.setState(prevState => ({
      errorMessage: {
        ...prevState.errorMessage,
        [type]: displayedErrorMessage,
      },
    }))
  }

  handleShowSuccessMessage = (type, newVariants = null) => {
    const { sampleAllocation } = this.state
    const {
      selectedEnvironment: { id: environmentId, name },
    } = this.props

    const isInProductionAboveZero =
      environmentId === PRODUCTION && sampleAllocation > 0

    this.setState(({ variants, success }) => ({
      variants: newVariants || variants,
      isDirty: false,
      success: {
        ...success,
        [type]: copyContent.sampleLaunch.successPublish(
          name,
          isInProductionAboveZero ? 'launch' : 'publish'
        ),
      },
    }))

    setTimeout(
      () =>
        this.setState(({ success }) => ({
          success: {
            ...success,
            [type]: null,
          },
        })),
      5000
    )
  }

  handleShowInfoMessage = type => {
    const message = copyContent.sampleLaunch[type]

    this.setState(({ info }) => ({
      info: {
        ...info,
        [type]: message,
      },
    }))

    setTimeout(
      () =>
        this.setState(({ info }) => ({
          info: {
            ...info,
            [type]: null,
          },
        })),
      5000
    )
  }

  // FORMATTING METHODS
  formatPercentage = weight => {
    const { sampleAllocation, variants } = this.state

    const totalWeight = Object.keys(variants).reduce(
      (accumulator, variant) => accumulator + variants[variant].weight,
      0
    )

    return totalWeight === 0
      ? 0
      : Math.round((weight / totalWeight) * sampleAllocation * 100) / 100
  }

  formatShowRunningInProdInfo = () => {
    const {
      samplingData: { experiment },
      selectedEnvironment,
    } = this.props

    // We only show this message in lower envs
    if (selectedEnvironment.name.toLowerCase() === 'production') return false

    // Make sure the experiment is actually running in prod
    if (experiment) {
      const { environmentSamplings } = experiment

      return environmentSamplings.find(
        ({
          environment: { id: environmentId },
          experimentStatus: { id: experimentStatusId },
        }) => environmentId === PRODUCTION && experimentStatusId === RUNNING
      )
    }
    return false
  }

  formatVariantSamplingInputs = () => {
    const { variants } = this.state
    const {
      samplingData: { experiment },
      selectedEnvironment,
    } = this.props

    const variantSamplingToPublish = []
    const variantSamplingToUpdate = []
    const newVariantsToInsert = []

    const hasNewVariantsToInert =
      Object.keys(variants).find(
        variant => variants[variant].variantSamplingToEnvironmentId
      ) &&
      Object.keys(variants).find(
        variant => !variants[variant].variantSamplingToEnvironmentId
      )

    Object.keys(variants).forEach(variant => {
      const { variantId, weight, variantSamplingToEnvironmentId } =
        variants[variant]

      const baseSampling = {
        variantId,
        weight,
      }

      if (variantSamplingToEnvironmentId) {
        const matchingVariant = experiment.environmentSamplings
          .find(sampling => sampling.environment.id === selectedEnvironment.id)
          .environmentSamplingToVariants.find(
            match => match.variant.id === variantId
          )

        if (matchingVariant && matchingVariant.weight !== weight) {
          variantSamplingToUpdate.push({
            ...baseSampling,
            variantSamplingToEnvironmentId,
          })
        }
      } else if (hasNewVariantsToInert && !variantSamplingToEnvironmentId) {
        newVariantsToInsert.push(baseSampling)
      } else {
        variantSamplingToPublish.push(baseSampling)
      }
    })

    return {
      variantSamplingToPublish,
      variantSamplingToUpdate,
      newVariantsToInsert,
    }
  }

  formatMutationInput = input => {
    const { id, hasInAppPreferences } = this.props

    const baseInput = {
      variables: {
        input,
      },
      refetchQueries: [{ query: GET_EXPERIMENT_SAMPLING, variables: { id } }],
      awaitRefetchQueries: true,
    }

    if (hasInAppPreferences) {
      return {
        ...baseInput,
        refetchQueries: [
          ...baseInput.refetchQueries,
          { query: GET_CURRENT_USER },
          { query: GET_EXPERIMENT, variables: { id } },
        ],
      }
    }

    return baseInput
  }

  // API METHODS
  handleSubmit = async () => {
    const {
      errorMessage,
      success,
      isDirty,
      sampleAllocation,
      crqRequestNumber,
      expsiTicketNumber,
    } = this.state
    const {
      selectedEnvironment: {
        tdcsUpdateRequired,
        isDreSynced,
        experimentStatusId,
        id: selectedEnvironmentId,
      },
      samplingData: {
        experiment,
        experiment: { isCmsExperiment },
      },
      updateChangeRequestOnExperiment,
    } = this.props

    const { variants } = experiment
    const inputs = this.formatVariantSamplingInputs()

    const {
      variantSamplingToPublish,
      variantSamplingToUpdate,
      newVariantsToInsert,
    } = inputs
    const hasOverrides = variants.find(
      ({ variantToOverrides, variantToGroupOverrides }) =>
        variantToOverrides.length || variantToGroupOverrides.length
    )

    if (
      errorMessage.insert ||
      errorMessage.update ||
      success.insert ||
      success.update
    ) {
      this.setState({
        errorMessage: { insert: null, update: null },
        success: { insert: null, update: null },
      })
    }

    await this.setState(({ loading }) => ({
      loading: {
        ...loading,
        submit: true,
      },
    }))

    if (newVariantsToInsert.length > 0) {
      await this.handleUpdateEnvironmentWithNewVariants(newVariantsToInsert)
    }

    if (variantSamplingToPublish.length > 0) {
      await this.handleInsertVariants(variantSamplingToPublish)
    }

    if (variantSamplingToPublish.length === 0 && isDirty) {
      await this.handleUpdateVariants(variantSamplingToUpdate)
    }

    const { environmentSamplingToVariants } =
      experiment.environmentSamplings.find(
        sampling => sampling.environment.id === selectedEnvironmentId
      )
    if (!isCmsExperiment) {
      try {
        updateChangeRequestOnExperiment({
          variables: {
            input: {
              experimentId: experiment.id,
              crqRequestNumber,
              expsiTicketNumber,
            },
          },
        })
      } catch (e) {
        console.log(e)
        await this.setState(({ loading }) => ({
          loading: {
            ...loading,
            submit: false,
          },
          comment: '',
          isShowingCommentArea: false,
        }))
        return
      }
    }

    // Verify not a CMS experiment to republish to TDCS
    if (
      !isCmsExperiment &&
      environmentSamplingToVariants &&
      environmentSamplingToVariants.length &&
      variantSamplingToPublish.length === 0 &&
      newVariantsToInsert.length === 0 &&
      tdcsUpdateRequired &&
      !isDirty
    ) {
      await this.handleRepublish(isCmsExperiment)
    } else if (
      isCmsExperiment &&
      environmentSamplingToVariants &&
      environmentSamplingToVariants.length &&
      variantSamplingToPublish.length === 0 &&
      newVariantsToInsert.length === 0 &&
      !isDreSynced &&
      !isDirty
    ) {
      await this.handleRepublish(isCmsExperiment)
    }

    const hasErrors = Object.keys(errorMessage).find(key => errorMessage[key])

    if (
      experimentStatusId !== RUNNING &&
      sampleAllocation === 0 &&
      !hasOverrides &&
      !hasErrors
    ) {
      this.handleShowInfoMessage('needsOverride')
    }

    scrollToNavigationPosition()

    await this.setState(({ loading }) => ({
      loading: {
        ...loading,
        submit: false,
      },
      comment: '',
      isShowingCommentArea: false,
    }))
  }

  handleInsertVariants = async variantSamplingToPublish => {
    const { environmentSamplingId, sampleAllocation, variants, comment } =
      this.state
    const {
      id: experimentId,
      selectedEnvironment: { id: environmentId, name },
      publishExperimentToEnvironment: publishToEnvironment,
      samplingData: {
        experiment: { isCmsExperiment },
      },
      onSubmit,
    } = this.props

    const baseInput = {
      environmentSamplingId,
      environmentId,
      experimentId,
      comment,
      isCmsExperiment,
    }
    const input = {
      ...baseInput,
      variantSamplings: variantSamplingToPublish,
      sampleAllocation: sampleAllocation / 100,
    }

    const mutationArguments = this.formatMutationInput(input)

    try {
      const response = await publishToEnvironment(mutationArguments)

      const newVariants =
        response.data.publishExperimentToEnvironment.environmentSamplings
          .find(environment => environment.id === environmentSamplingId)
          .environmentSamplingToVariants.reduce((accumulator, newVariant) => {
            const match = Object.keys(variants).find(
              variant => variants[variant].variantId === newVariant.variant.id
            )

            accumulator[match] = {
              ...variants[match],
              variantSamplingToEnvironmentId: newVariant.id,
            }

            return accumulator
          }, {})

      if (environmentId === PRODUCTION) {
        this.handleSetCurrentlyViewing(
          sampleAllocation > 0 ? 'running' : 'testing'
        )
      }

      this.handleShowSuccessMessage('insert', newVariants)

      onSubmit(response.data.publishExperimentToEnvironment)
    } catch (error) {
      const errorInput = formatLoggingError(error, { id: experimentId })

      try {
        await client.mutate({
          mutation: LOG_ERROR,
          variables: {
            input: errorInput,
          },
        })
      } catch (err) {
        console.log(err)
      }

      this.handleSetDisplayedErrorMessage(error, name, 'insert')
    }
  }

  handleUpdateEnvironmentWithNewVariants = async newVariantsToInsert => {
    const { environmentSamplingId, variants, sampleAllocation, comment } =
      this.state
    const {
      id: experimentId,
      selectedEnvironment: { id: environmentId, name },
      onSubmit,
      publishUpdateVariantToEnvironment: publishVariant,
      samplingData: {
        experiment: { isCmsExperiment },
      },
    } = this.props

    const input = {
      experimentId,
      environmentSamplingId,
      environmentId,
      variantSamplings: newVariantsToInsert,
      comment,
      isCmsExperiment,
    }

    const mutationArguments = this.formatMutationInput(input)

    try {
      const response = await publishVariant(mutationArguments)
      const newVariants =
        response.data.publishUpdateVariantToEnvironment.environmentSamplings
          .find(environment => environment.id === environmentSamplingId)
          .environmentSamplingToVariants.reduce((accumulator, newVariant) => {
            const match = Object.keys(variants).find(
              variant => variants[variant].variantId === newVariant.variant.id
            )

            accumulator[match] = {
              ...variants[match],
              variantSamplingToEnvironmentId: newVariant.id,
            }

            return accumulator
          }, {})

      if (environmentId === PRODUCTION) {
        this.handleSetCurrentlyViewing(
          sampleAllocation > 0 ? 'running' : 'testing'
        )
      }

      this.handleShowSuccessMessage('insert', newVariants)

      onSubmit(response.data.publishUpdateVariantToEnvironment)
    } catch (error) {
      const errorInput = formatLoggingError(error, { id: experimentId })
      try {
        await client.mutate({
          mutation: LOG_ERROR,
          variables: {
            input: errorInput,
          },
        })
      } catch (err) {
        console.log(err)
      }

      this.handleSetDisplayedErrorMessage(error, name, 'insert')
    }
  }

  handleUpdateVariants = async variantSamplingToUpdate => {
    const { environmentSamplingId, sampleAllocation, comment } = this.state
    const {
      id: experimentId,
      selectedEnvironment: { id: environmentId, name },
      samplingData: {
        experiment,
        experiment: { isCmsExperiment },
      },
      updateExperimentToEnvironment: updateToEnvironment,
      onSubmit,
    } = this.props

    let baseInput = {
      environmentSamplingId,
      environmentId,
      experimentId,
      comment,
      isCmsExperiment,
    }

    const matchingSampling = experiment.environmentSamplings.find(
      sampling => sampling.environment.id === environmentId
    ).sampleAllocation

    // If sampling allocation has changes, update it
    if (matchingSampling * 100 !== sampleAllocation) {
      baseInput = {
        ...baseInput,
        sampleAllocation: sampleAllocation / 100,
      }
    }

    try {
      const input =
        variantSamplingToUpdate.length > 0
          ? {
              ...baseInput,
              variantSamplings: variantSamplingToUpdate,
            }
          : baseInput

      const updateArguments = this.formatMutationInput(input)

      const response = await updateToEnvironment(updateArguments)

      if (environmentId === PRODUCTION) {
        this.handleSetCurrentlyViewing(
          sampleAllocation > 0 ? 'running' : 'testing'
        )
      }

      this.handleShowSuccessMessage('update')

      onSubmit(response.data.publishUpdateExperimentToEnvironment)
    } catch (error) {
      const input = formatLoggingError(error, { id: experimentId })
      try {
        await client.mutate({
          mutation: LOG_ERROR,
          variables: {
            input,
          },
        })
      } catch (err) {
        console.log(err)
      }

      this.handleSetDisplayedErrorMessage(error, name, 'update')
    }
  }

  handleRepublish = async isCmsExperiment => {
    const {
      id: experimentId,
      selectedEnvironment: { id: environmentId, name },
      republishUpdateToTdcs: republish,
      republishUpdateToDre: dreRepublish,
      onSubmit,
    } = this.props
    const { comment } = this.state

    const input = {
      experimentId,
      environmentId,
      comment,
    }

    const mutationArguments = this.formatMutationInput(input)

    try {
      let response
      let submitData
      // Verify if needs to publish to DRE or TDCS
      if (!isCmsExperiment) {
        response = await republish(mutationArguments)
        submitData = response.data.republishUpdateToTdcs
      } else {
        response = await dreRepublish(mutationArguments)
        submitData = response.data.republishUpdateToDre
      }

      onSubmit(submitData)

      this.setState(({ success }) => ({
        success: {
          ...success,
          update: copyContent.sampleLaunch.successPublish(name, 'republish'),
        },
        comment: '',
      }))

      setTimeout(
        () =>
          this.setState(({ success }) => ({
            success: {
              ...success,
              update: null,
            },
            comment: '',
          })),
        5000
      )
    } catch (error) {
      const errorInput = formatLoggingError(error, { id: experimentId })
      try {
        await client.mutate({
          mutation: LOG_ERROR,
          variables: {
            input: errorInput,
          },
        })
      } catch (err) {
        console.log(err)
      }

      this.handleSetDisplayedErrorMessage(error, name, 'republish')
    }
  }

  handleResetExperiment = async () => {
    const {
      id: experimentId,
      selectedEnvironment: { id: environmentId, name: environmentName },
      resetExperiment: resetExperimentMutation,
    } = this.props
    const { comment } = this.state

    const input = {
      experimentId,
      environmentId,
      comment,
    }

    try {
      await this.setState(({ loading }) => ({
        isExperimentResetModalOpen: false,
        loading: {
          ...loading,
          submit: true,
        },
      }))
      await resetExperimentMutation({
        variables: {
          input,
        },
        refetchQueries: [
          {
            query: GET_EXPERIMENT_SAMPLING,
            variables: { id: experimentId },
          },
        ],
        awaitRefetchQueries: true,
      })
      await this.handleSubmit()

      await this.setState(({ loading }) => ({
        loading: {
          ...loading,
          submit: false,
        },
      }))
    } catch (error) {
      const errorInput = formatLoggingError(error, { id: experimentId })

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input: errorInput,
        },
      })

      this.handleSetDisplayedErrorMessage(
        error,
        environmentName,
        'resetExperiment'
      )
    }
  }

  queryQuantum = async () => {
    const {
      samplingData: { retrieveQuantumToken: quantumToken },
    } = this.props

    const quantumClient = createQuantumApolloClient({
      quantumToken,
      fetch,
    })

    const res = await quantumClient.query({ query: GET_QUANTUM_METRICS })

    return res.data
  }

  // VALIDATION METHODS
  validateSubmission = async () => {
    const { variants, sampleAllocation, quantumMetrics } = this.state

    const {
      tdcsSessionType,
      samplingData: {
        experiment,
        experiment: { isCmsExperiment },
      },
      selectedEnvironment,
      applicationPermissions,
    } = this.props

    const { environmentSamplings } = experiment

    const { featureFlags } = client.readQuery({
      query: gql`
        query getCurrentUser {
          currentUser {
            id
            displayName
          }
          featureFlags {
            id
            name
            active
          }
        }
      `,
    })

    const isCmsResetActive = isFeatureFlagActive({
      featureFlags,
      featureFlagId: featureFlagEnum.CMS_EXPERIMENT_RESET,
      applicationPermissions,
    })
    const canResetExperiment =
      (isCmsResetActive && isCmsExperiment) || !isCmsExperiment
    const { errors, rails } = checkSampleLaunch({
      experiment: {
        ...experiment,
        quantumMetrics,
        environmentSamplings: environmentSamplings.map(sampling => {
          if (sampling.environment.id === selectedEnvironment.id) {
            return {
              ...sampling,
              environmentSamplingToVariants: Object.values(variants),
            }
          }

          return sampling
        }),
      },
      environmentId: selectedEnvironment.id,
      sampleAllocation: sampleAllocation / 100.0,
    })

    if (!isEmpty(errors)) {
      scrollToNavigationPosition()
      this.setState(({ errorMessage }) => ({
        errorMessage: {
          ...errorMessage,
          ...errors,
        },
      }))

      return false
    }

    const {
      scenario,
      needsCancel,
      warnVariantCancelled,
      warnAboutLowering,
      affectedMetrics,
      needsReset,
      needsLaunchReminder,
      needsSoakingWarn,
    } = rails

    if (needsCancel && canResetExperiment) {
      this.setState({ isConfirmCancelModalOpen: true })
      return false
    }

    if (needsSoakingWarn) {
      this.setState({ isSoakBypassModalOpen: true })
      return false
    }

    if (needsLaunchReminder) {
      this.setState({ isReminderModalOpen: true })
      return false
    }

    if (needsReset && canResetExperiment) {
      const resetSubMessage =
        scenario === 3
          ? `In order to update the variant weights in this way, 
        and still get accurate results, the experiment must be 
        reset with a new start time and results in Quantum.  Don’t worry, everything else 
        on the experiment will stay the same and it will be reset with a
        Running status and updated variant weights.`
          : `Sampling is being raised after previously been lowered. In order to still get accurate results without 
          impacting the customer experience, the experiment must be 
        reset with a new start time and results in Quantum.  Don’t worry, everything else 
        on the experiment will stay the same and it will be reset with a
        Running status and updated variant weights.`

      this.setState({
        isExperimentResetModalOpen: true,
        resetSubMessage,
      })
      return false
    }

    if (warnAboutLowering && canResetExperiment) {
      const metricsAffected = affectedMetrics.map(
        ({ displayName }) => displayName
      )
      this.setState({
        isWarnAllocationModalOpen: true,
        warnAllocationMessage: 'Lowering Sample Allocation',
        warnAllocationSubMessage: `Lowering sample allocation will negatively impact the quality\nof the following metrics:\n${metricsAffected.join(
          '\n'
        )}`,
      })
      return false
    }

    if (warnVariantCancelled && canResetExperiment) {
      // There was a variant that was dialed down to zero
      // that we should warn about.
      const isAccountBased =
        tdcsSessionType?.overrideTypeId === overrideTypeEnum.ACCOUNT
      const customerDeviceText = isAccountBased
        ? 'customers and devices'
        : 'devices'

      this.setState({
        isWarnAllocationModalOpen: true,
        warnAllocationMessage: 'Cancelling Variant',
        warnAllocationSubMessage: `This will transfer ${customerDeviceText} from the cancelled variant to other variants. 
        This will create an inconsistent experience for customers, and inaccurate results 
        due to ${customerDeviceText} being counted in both variants.`,
      })
      return false
    }

    return true
  }

  validateIsDirty = () => {
    const { sampleAllocation, variants, comment } = this.state
    const {
      samplingData: { experiment },
      selectedEnvironment: { id: environmentId },
    } = this.props

    const {
      sampleAllocation: currentSampling,
      environmentSamplingToVariants: currentVariants,
    } = experiment.environmentSamplings.find(
      sampling => sampling.environment.id === environmentId
    )

    let showCommentArea = true
    let isDirty = false
    let samplingChanged = false
    let variantWeightChanged = false

    // Check if sampling has changed
    if (currentSampling * 100 !== sampleAllocation) {
      samplingChanged = true
    }
    // Check if any allocation has changed
    Object.keys(variants).forEach(variant => {
      const { variantId, weight, variantSamplingToEnvironmentId } =
        variants[variant]

      const match = currentVariants.find(
        current => current.variant.id === variantId
      )

      if (match && match.weight !== weight) {
        variantWeightChanged = true
      } else if (!variantSamplingToEnvironmentId && weight !== 0) {
        variantWeightChanged = true
      }
    })
    // Check if sampling changed or weight changed
    if (!samplingChanged && !variantWeightChanged) {
      showCommentArea = false
    }

    // Check if comment is greater than 35 characters when not in testing
    if (showCommentArea && comment.length >= 35) {
      isDirty = true
    }

    this.setState({ isDirty, isShowingCommentArea: showCommentArea })
  }

  changeRequestIsValid = async () => {
    const { expsiTicketNumber, crqRequestNumber, sampleAllocation } = this.state
    const {
      samplingData: {
        experiment: { isCmsExperiment },
      },
    } = this.props
    if (
      (expsiTicketNumber?.length >= 1 && crqRequestNumber?.length >= 1) ||
      sampleAllocation === 0 ||
      isCmsExperiment
    ) {
      return true
    } else {
      await this.setState({
        errorMessage: {
          expsiTicketNumber: true,
          crqRequestNumber: true,
        },
      })
      scrollToNavigationPosition()
      return false
    }
  }

  // REF METHODS
  handleSetCurrentlyViewing = status => {
    const { id, onNavigate } = this.props

    const path = `/experiments/${id}/rollout/sample-launch`

    onNavigate(
      {
        path,
        backPath: `/experiments/${status}`,
        backTitle: `${status} Experiments`,
      },
      true
    )
  }

  // RENDER METHODS
  renderVariantAllocationControls = () => {
    const { variants, loading } = this.state
    const {
      disabled,
      activeNetworkLockdown,
      selectedEnvironment: { id: environmentId },
    } = this.props

    return (
      <div className="sample-launch__variants-container">
        {Object.keys(variants).map((variant, index) => (
          <div key={variant} className="sample-launch__variant">
            <span className="sample-launch__variant-name">{variant}</span>

            <div className="sample-launch__variant-wrapper">
              <KiteInput
                className={`sample-launch__variant-input ${
                  index === 0 ? 'control' : `variant-${index}`
                }`}
                name={variant}
                placeholder="0"
                value={variants[variant].weight}
                onChange={this.handleChangeVariantAllocation}
                disabled={
                  disabled ||
                  loading.submit ||
                  (activeNetworkLockdown && environmentId === 1)
                }
              />
              <span className="sample-launch__variant-percent">
                {`${this.formatPercentage(variants[variant].weight)}%`}
              </span>
            </div>
          </div>
        ))}
      </div>
    )
  }

  renderSubmitButton = () => {
    const { loading, sampleAllocation, comment, isDirty } = this.state
    const {
      disabled,
      applicationPermissions,
      experimentPermissions,
      environmentSamplings,
      samplingData: {
        experiment: { isCmsExperiment },
      },
      selectedEnvironment: {
        id: environmentId,
        experimentStatusId,
        tdcsUpdateRequired,
        isDreSynced,
      },
      activeNetworkLockdown,
    } = this.props

    const canRepublish = hasPermission({
      applicationPermissions,
      experimentPermissions,
      environmentSamplings,
      environmentId,
      permissionId: EXPERIMENT_REPUBLISH,
    })

    const republishRequiredNotInCompleteCancel =
      ((tdcsUpdateRequired && !isCmsExperiment) ||
        (!isDreSynced && isCmsExperiment)) &&
      canRepublish &&
      experimentStatusId !== COMPLETED &&
      experimentStatusId !== CANCELLED

    // Verify that they are not just testing
    const commentOver35 = sampleAllocation > 0 ? comment.length >= 35 : true

    let buttonText
    let isDisabled =
      republishRequiredNotInCompleteCancel && !disabled && commentOver35
        ? false
        : disabled || !commentOver35

    if (environmentId !== experimentEnvironmentEnum.PRODUCTION) {
      if (experimentStatusId === experimentStatusEnum.DRAFT) {
        buttonText = 'Publish to Test'
      } else {
        buttonText = 'Publish Changes'
        isDisabled =
          republishRequiredNotInCompleteCancel && (!disabled || canRepublish)
            ? false
            : !isDirty || disabled
      }
    } else if (environmentId === experimentEnvironmentEnum.PRODUCTION) {
      if (
        experimentStatusId === experimentStatusEnum.DRAFT &&
        sampleAllocation === 0
      ) {
        buttonText = 'Publish to Test'
      } else if (
        experimentStatusId !== experimentStatusEnum.RUNNING &&
        sampleAllocation > 0
      ) {
        buttonText = 'Launch to Customers'
      } else {
        buttonText = 'Publish Changes'
        isDisabled =
          republishRequiredNotInCompleteCancel && (!disabled || canRepublish)
            ? false
            : !isDirty || disabled
      }
    }

    isDisabled =
      (!isCmsExperiment &&
        experimentStatusId !== experimentStatusEnum.RUNNING) ??
      true

    return (
      <KiteButton
        className="rollout-experiment__launch-button"
        size="large"
        disabled={
          isDisabled ||
          loading.submit ||
          (activeNetworkLockdown && environmentId === 1)
        }
        loading={loading.submit}
        onClick={async () => {
          if (await this.changeRequestIsValid()) {
            if (await this.validateSubmission()) {
              this.handleSubmit()
            }
          }
        }}
      >
        {buttonText}
      </KiteButton>
    )
  }

  render() {
    const {
      sampleAllocation,
      variants,
      comment,
      isDirty,
      isConfirmDiscardModalOpen,
      isConfirmCancelModalOpen,
      isExperimentResetModalOpen,
      resetSubMessage,
      isCancelModalOpen,
      isWarnAllocationModalOpen,
      warnAllocationMessage,
      warnAllocationSubMessage,
      allocationHistoryContainerHeight,
      errorMessage,
      info,
      success,
      loading,
      isShowingCommentArea,
      isReminderModalOpen,
      isSoakBypassModalOpen,
      crqRequestNumber,
      expsiTicketNumber,
    } = this.state

    const {
      id,
      user,
      selectedEnvironment,
      disabled,
      applicationPermissions,
      samplingData: { loading: samplingLoading, experiment },
      activeNetworkLockdown,
      history,
    } = this.props

    const {
      sampleLaunch: {
        heading,
        changeRequestDescription,
        changeRequestHeading,
        lowerInProdInfo,
        description,
        samplingHeading,
        historyHeading,
        distributeButton,
      },
    } = copyContent

    if (samplingLoading || !variants) {
      return (
        <div className="app__loader">
          <KiteLoader size="7rem" />
        </div>
      )
    }

    const { environmentSamplings, permissions } = experiment
    const { allocationHistory } = environmentSamplings.find(
      sampling => sampling.environment.id === selectedEnvironment.id
    )

    const showRunningInProdInfo = disabled && this.formatShowRunningInProdInfo()

    const canTerminate = hasPermission({
      applicationPermissions,
      environmentSamplings,
      environmentId: selectedEnvironment.id,
      experimentPermissions: permissions,
      permissionId: EXPERIMENT_TERMINATE,
    })

    const hasCloneExperimentPermission = hasPermission({
      applicationPermissions,
      experimentPermissions: permissions,
      permissionId: EXPERIMENT_CLONE,
    })

    const hasDeletePermissions =
      hasPermission({
        applicationPermissions,
        experimentPermissions: experiment.permissions,
        environmentSamplings: experiment.environmentSamplings,
        permissionId: EXPERIMENT_DELETE,
      }) ||
      hasPermission({
        applicationPermissions,
        experimentPermissions: experiment.permissions,
        environmentSamplings: experiment.environmentSamplings,
        permissionId: EXPERIMENT_DELETE_TESTING,
      })

    const variantAmount = Object.keys(variants).length
    let historyClassExt = 'sample-launch__history-max-height-below5'

    // Verify variant amount to keep allocation history display correctly
    if (variantAmount === 5 || variantAmount === 6) {
      historyClassExt = 'sample-launch__history-max-height-5-6'
    } else if (variantAmount === 7 || variantAmount === 8) {
      historyClassExt = 'sample-launch__history-max-height-6-7'
    } else if (variantAmount > 8) {
      historyClassExt = 'sample-launch__history-max-height-above8'
    }

    return (
      <div className="sample-launch">
        <ExpansionPanel
          type="minimal"
          isExpanded={!!(success.insert || success.update)}
        >
          <KiteAlert
            className="app__page-level-message"
            type="confirm"
            description={success.insert || success.update}
          />
        </ExpansionPanel>

        <ExpansionPanel type="minimal" isExpanded={showRunningInProdInfo}>
          <KiteAlert
            className="app__page-level-message"
            type="info"
            description={lowerInProdInfo}
          />
        </ExpansionPanel>

        <ExpansionPanel type="minimal" isExpanded={Boolean(info.needsOverride)}>
          <KiteAlert
            className="app__page-level-message"
            type="info"
            description={info.needsOverride}
          />
        </ExpansionPanel>

        <ExpansionPanel
          type="minimal"
          isExpanded={!!errorMessage.errorOneVariantWithWeightAboveZero}
        >
          <KiteAlert
            className="app__page-level-message"
            type="alert"
            description={errorMessage.errorOneVariantWithWeightAboveZero}
          />
        </ExpansionPanel>

        <ExpansionPanel
          type="minimal"
          isExpanded={
            !!(
              errorMessage.insert ||
              errorMessage.update ||
              errorMessage.republish
            )
          }
        >
          <KiteAlert
            className="app__page-level-message "
            type="alert"
            description={
              errorMessage.insert ||
              errorMessage.update ||
              errorMessage.republish
            }
            linkText={
              (errorMessage.insert ||
                errorMessage.update ||
                errorMessage.republish) &&
              'contact support.'
            }
            onLinkClick={() => {
              window.location = `mailto:DL-Distillery-Support@charter.com?subject=Distillery Support for Publishing - ${user.displayName}`
            }}
            onClose={() =>
              this.setState({
                errorMessage: {
                  insert: null,
                  update: null,
                  republish: null,
                },
              })
            }
          />
        </ExpansionPanel>
        <div className="sample-launch__data-container">
          {!experiment.isCmsExperiment && (
            <div className="rollout-experiment__accent-color-container">
              <div
                className="rollout-experiment__accent-color"
                style={{
                  backgroundColor: `${selectedEnvironment.color}`,
                }}
              />

              <div className="rollout-experiment__accent-color-children">
                <h4 className="change-request__heading">
                  {changeRequestHeading}
                </h4>
                <p className="change-request__description">
                  {changeRequestDescription}
                </p>
                <div className="change-request__input-container">
                  <KiteInput
                    label="CRQ Request Number"
                    onChange={this.handleSetCrqRequestNumber}
                    value={crqRequestNumber}
                    errorMessage={
                      errorMessage.crqRequestNumber
                        ? 'Please enter your CRQ request number'
                        : ''
                    }
                  />
                  <KiteInput
                    label="EXPSI Ticket Number"
                    onChange={this.handleSetExpsiTicketNumber}
                    value={expsiTicketNumber}
                    errorMessage={
                      errorMessage.expsiTicketNumber
                        ? 'Please enter your EXPSI ticket number'
                        : ''
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        <h4 className="sample-launch__heading">{heading}</h4>
        <p className="sample-launch__description">{description}</p>

        <div className="sample-launch__data-container">
          <div className="rollout-experiment__accent-color-container">
            <div
              className="rollout-experiment__accent-color"
              style={{
                backgroundColor: `${selectedEnvironment.color}`,
              }}
            />
            <div className="rollout-experiment__accent-color-children">
              <div className="sample-launch__sampling-allocation">
                <h4 className="sample-launch__section-heading">
                  {samplingHeading}
                </h4>

                <LinearAllocationChart
                  className="sample-launch__allocation-chart"
                  items={Object.keys(variants).map((variant, index) => ({
                    label: variant,
                    percentage: this.formatPercentage(variants[variant].weight),
                    color: variantColors[index],
                  }))}
                />

                <div className="sample-launch__sampling-allocation-controls">
                  <div className="sample-launch__sampling-container">
                    <CircleChart
                      percentage={sampleAllocation.toString()}
                      onChange={this.handleChangeSampling}
                      centerContent="input"
                      size="14rem"
                      label="Sampling"
                      circleColor="#8bf0ac"
                      circleBackgroundColor="#c9c9c9"
                      roundedEdges={false}
                      disabled={
                        disabled ||
                        loading.submit ||
                        (activeNetworkLockdown && selectedEnvironment.id === 1)
                      }
                    />
                  </div>

                  <div className="sample-launch__allocation-controls">
                    {this.renderVariantAllocationControls()}

                    <KiteButton
                      className="sample-launch__distribute-button"
                      type="outline"
                      onClick={this.handleDistributeEvenly}
                      disabled={
                        disabled ||
                        loading.submit ||
                        (activeNetworkLockdown && selectedEnvironment.id === 1)
                      }
                    >
                      {distributeButton}
                    </KiteButton>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rollout-experiment__accent-color-container">
            <div
              className="rollout-experiment__accent-color"
              style={{
                backgroundColor: `${selectedEnvironment.color}`,
              }}
            />
            <div className="rollout-experiment__accent-color-children">
              <div className="sample-launch__history">
                <h4 className="sample-launch__section-heading">
                  {historyHeading}
                </h4>
                <div
                  className={`sample-launch__allocation-wrapper ${historyClassExt}`}
                  style={{
                    maxHeight: allocationHistoryContainerHeight,
                  }}
                >
                  <AllocationHistory
                    allocationHistory={allocationHistory}
                    selectedEnvironment={selectedEnvironment}
                  />
                </div>
                <div className="sample-launch__allocation-wrapper-fade" />
              </div>
            </div>
          </div>
        </div>
        <ExpansionPanel
          className="sample-launch__comment-container"
          type="minimal"
          isExpanded={isShowingCommentArea}
        >
          <TextArea
            name="comment"
            label="Comment"
            value={comment}
            disabled={disabled || loading.submit}
            onChange={this.handleChangeComment}
            placeholder={copyContent.sampleLaunch.comment}
            tooltip={copyContent.sampleLaunch.comment}
            height="6rem"
            maxLength="255"
            errorMessage={errorMessage.comment}
          />
        </ExpansionPanel>
        <div
          className={`rollout-experiment__footer${
            errorMessage.submit ? ' error' : ''
          }`}
        >
          {this.renderSubmitButton()}

          <KiteButton
            className={
              activeNetworkLockdown && selectedEnvironment.id === 1
                ? 'rollout-experiment__reset-button-disabled'
                : 'rollout-experiment__reset-button'
            }
            size="small"
            type="standalone-link"
            disabled={
              !isDirty ||
              (activeNetworkLockdown && selectedEnvironment.id === 1)
            }
            onClick={() => this.setState({ isConfirmDiscardModalOpen: true })}
          >
            Cancel
          </KiteButton>

          <div className="rollout-experiment__cancel-complete">
            <EndExperimentButton
              id={id}
              experiment={experiment}
              selectedEnvironment={selectedEnvironment}
              onSuccess={this.handleCancelSuccess}
              canTerminate={canTerminate}
              hasCloneExperimentPermission={hasCloneExperimentPermission}
              hasDeletePermissions={hasDeletePermissions}
              history={history}
              activeNetworkLockdown={
                activeNetworkLockdown && selectedEnvironment.id === 1
              }
              className={
                activeNetworkLockdown && selectedEnvironment.id === 1
                  ? 'sample-launch__active-lockdown-btn'
                  : ''
              }
            />
          </div>
        </div>

        {isConfirmCancelModalOpen && (
          <Modal
            className="sample-launch__check-modal"
            message="Cancelling Experiment"
            subMessage="Reducing total sampling allocation to 0 will cancel this experiment.
            But don’t worry, cancelled experiments can be cloned later; we’ve
            got you covered!"
            onConfirm={() => {
              this.setState({
                isConfirmCancelModalOpen: false,
                isCancelModalOpen: true,
              })
            }}
            onDeny={() => this.setState({ isConfirmCancelModalOpen: false })}
            buttonProps={{
              confirm: { value: 'Cancel Experiment' },
            }}
          />
        )}

        {isReminderModalOpen && (
          <Modal
            className="sample-launch__launch-modal"
            message="Launch Process Reminders"
            subMessage={copyContent.sampleLaunch.launchRemindersMessage}
            onConfirm={async () => {
              this.setState({ isReminderModalOpen: false })
              if (this.changeRequestIsValid()) {
                this.handleSubmit()
              } else {
                await this.setState({
                  errorMessage: {
                    expsiTicketNumber: true,
                    crqRequestNumber: true,
                  },
                })
                scrollToNavigationPosition()
              }
            }}
            onDeny={() => this.setState({ isReminderModalOpen: false })}
            buttonProps={{
              confirm: { value: 'Yep, launch!' },
              deny: { value: 'Cancel' },
            }}
          />
        )}

        {isSoakBypassModalOpen && (
          <Modal
            className="sample-launch__launch-modal"
            message="Soak Period Bypass"
            subMessage={copyContent.sampleLaunch.soakingMessage}
            validate="BYPASS"
            onConfirm={() => {
              this.setState({ isSoakBypassModalOpen: false })
            }}
            onDeny={async () => {
              this.setState({ isSoakBypassModalOpen: false })
              if (this.changeRequestIsValid()) {
                this.handleSubmit()
              } else {
                await this.setState({
                  errorMessage: {
                    expsiTicketNumber: true,
                    crqRequestNumber: true,
                  },
                })
                scrollToNavigationPosition()
              }
            }}
            buttonProps={{
              confirm: { value: 'Discard Changes' },
              deny: {
                value: 'Continue with sampling',
                validate: 'BYPASS',
              },
            }}
          />
        )}

        {isCancelModalOpen && (
          <CancelCompleteButton
            id={id}
            experiment={experiment}
            selectedEnvironment={selectedEnvironment}
            onSuccess={this.handleCancelSuccess}
            onNevermind={() => {
              this.setState({ isCancelModalOpen: false })
            }}
            startWithModal
          />
        )}

        {isWarnAllocationModalOpen && (
          <Modal
            className="sample-launch__check-modal"
            message={warnAllocationMessage}
            subMessage={warnAllocationSubMessage}
            usePreInSubMessage
            onConfirm={() => {
              this.setState({
                isWarnAllocationModalOpen: false,
              })
              this.handleSubmit()
            }}
            onDeny={() => this.setState({ isWarnAllocationModalOpen: false })}
            buttonProps={{
              confirm: { value: 'Continue With Changes' },
            }}
          />
        )}

        {loading.submit && !experiment.isCmsExperiment && (
          <Modal
            className="sample-launch__check-modal"
            message="Publishing Your Changes..."
            subMessage={`We’re sending your changes to TDCS (${selectedEnvironment.name}). 
Sorry to keep you here, but this avoids concurrent publish requests that might
lead to conflicting publish history. `}
            showLoader
          />
        )}

        {loading.submit && experiment.isCmsExperiment && (
          <Modal
            className="sample-launch__check-modal"
            message="Publishing Your Changes..."
            subMessage={`We’re sending your changes to Distillery Rules Engine.`}
            showLoader
          />
        )}

        {isExperimentResetModalOpen && (
          <Modal
            className="sample-launch__check-modal"
            message="Experiment Needs Reset"
            subMessage={resetSubMessage}
            onConfirm={async () => {
              await this.handleResetExperiment()
            }}
            onDeny={() => this.setState({ isExperimentResetModalOpen: false })}
            buttonProps={{
              confirm: { value: 'Reset Experiment' },
            }}
          />
        )}

        {isConfirmDiscardModalOpen && (
          <Modal
            message="Are you sure you want to reset your changes?"
            subMessage="Any unsaved changes will be lost"
            onConfirm={async () => {
              await this.handleSetSamplingData()
              this.setState({
                isConfirmDiscardModalOpen: false,
                isDirty: false,
              })
            }}
            onDeny={() => this.setState({ isConfirmDiscardModalOpen: false })}
            buttonProps={{ confirm: { value: 'Cancel Changes' } }}
          />
        )}
      </div>
    )
  }
}

export default flowRight(
  getExperimentSampling,
  publishExperimentToEnvironment,
  publishUpdateVariantToEnvironment,
  republishUpdateToTdcs,
  republishUpdateToDre,
  updateExperimentToEnvironment,
  updateChangeRequestOnExperiment,
  resetExperiment
)(SampleLaunch)
