import { gql } from '@apollo/client'

const getOverrides = {
  query: `
    overrides {
      id
      name
      overrideIdentifier
      overrideType {
        id
        name
      }
      products {
        id
        name
        displayName
        quantumApplicationType
      }
      overrideGroups {
        id
        name
      }
    }
  `,
  variables: {},
}

export const GET_OVERRIDES = gql`
  query getOverrides {
    overrides {
      id
      name
      overrideIdentifier
      overrideType {
        id
        name
      }
      products {
        id
        name
        displayName
      }
      overrideGroups {
        id
        name
      }
    }
  }
`

export default getOverrides
