export default {
  submissionTooltip:
    'To save your preferences you must have one product and one event delivery type selected.',
  environmentLabel: 'Environment:',
  platformHeading: 'Platform for Notifications',
  eventHeading: 'Notify me when these events happen:',
  events: {
    experimentChange:
      'An experiment has been published, completed, or canceled',
    allocationChange: 'Sampling or allocation has been changed',
  },
  confirmCancelMessage: 'Are you sure you want to cancel your changes?',
  confirmCancelSubMessage: 'This cannot be undone',
  successSavePreferencesTitle: 'Preferences saved',
  successSavePreferencesDescription: name => `Changes to ${name} notifications updated.`,
  errorGetPreferencesEnvironment: name =>
    `We could not get your preferences for ${name}. Please try again in a few minutes.`,
  errorUpdatePreferencesEnvironment: name =>
    `We could not update your preferences for ${name}. Please try again in a few minutes.`,
};
