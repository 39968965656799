import React from 'react'
import { string } from 'prop-types'

import './ErrorPage.scss'

import errorPageIllustration from './data/errorPageIllustration.png'

const ErrorPage = ({ message, hypothesis, conclusion, code, className }) => {
  return (
    <div className={`errorPage ${className}`}>
      <img
        alt="logo"
        style={{
          position: 'absolute',
          width: '60%',
          top: 200,
          left: 250,
          right: 0,
          bottom: 0,
          justifyContent: 'center',
          alignItems: 'center',
        }}
        src={String(errorPageIllustration)}
      />
      <div className="errorPage__messages">
        <div className="errorPage__code">{code}</div>
        <div className="errorPage__message-container">
          <div className="errorPage__message">{message}</div>
          <div className="errorPage__hypothesis">{hypothesis}</div>
          <div className="errorPage__conclusion">{conclusion}</div>
        </div>
      </div>
    </div>
  )
}

export default ErrorPage

ErrorPage.propTypes = {
  message: string.isRequired,
  hypothesis: string.isRequired,
  conclusion: string.isRequired,
  className: string,
  code: string,
}

ErrorPage.defaultProps = {
  subMessage: null,
  code: null,
}
