import { gql } from '@apollo/client'

export const GET_ALL_AUDIENCES = gql`
  query getAllAudiences {
    audiences {
      id
      name
      description
      rules
      content {
        contentId
        contentTitle
      }
    }
    userProfileSchema {
      id
      name
      type
      description
      example
      values {
        id
        name
        enum
        type
        description
        properties {
          id
          name
          enum
          type
          description
          properties {
            id
            name
            enum
            type
            description
          }
        }
      }
    }
  }
`
