import React, { useState } from 'react'
import { string, bool } from 'prop-types'
import classNames from 'classnames'
import { KiteButton, KiteIcon } from '@kite/react-kite'

import { ReactComponent as CopyIcon } from './copy-icon.svg'
import { copyTextToClipboard } from '../../shared/utilities'
import './CopyButton.scss'

const CopyButton = ({ textToCopy, useIcon }) => {
  const [wasSuccessful, handleToggleCopySuccessful] = useState(false)

  const successIconClassNames = classNames({
    'copy-button__success-icon': true,
    'copy-button__success-icon-hidden': !wasSuccessful,
    'copy-button__success-icon-with-copy-icon': useIcon,
  })

  return (
    <div className="copy-button">
      <KiteButton
        className="copy-button__button"
        type="standalone-link"
        onClick={() => {
          copyTextToClipboard(textToCopy)
          handleToggleCopySuccessful(true)

          setTimeout(() => handleToggleCopySuccessful(false), 3000)
        }}
      >
        {useIcon ? <CopyIcon className="copy-button__copy-icon" /> : 'COPY'}
      </KiteButton>

      <KiteIcon
        className={successIconClassNames}
        name="checkmark"
        size="1.5rem"
        color={wasSuccessful ? '#02BF20' : 'transparent'}
      />
    </div>
  )
}

CopyButton.propTypes = {
  textToCopy: string.isRequired,
  useIcon: bool,
}

CopyButton.defaultProps = {
  useIcon: false,
}

export default CopyButton
