import React from 'react'
import { observer } from 'mobx-react'
import { KiteButton, KiteModal } from '@kite/react-kite'
import './SaveOrExit.scss'

export default observer(function SaveOrExit({ editorModel }) {
  return (
    <div className="save-or-exit">
      <KiteButton
        id="saveButton"
        disabled={editorModel.isSaveDisabled}
        loading={editorModel.isSaving}
        onClick={() => editorModel.save()}
      >
        Save
      </KiteButton>
      <div className="save-or-exit__exit" onClick={() => editorModel.exit()}>
        Exit Without Saving
      </div>
      <KiteModal
        className="save-or-exit__exit-modal"
        canShow={editorModel.showExitModal}
        ctaCopy="Yes, Exit"
        secondaryCtaCopy="Nevermind"
        title=""
        secondaryCtaAction={editorModel.cancelExit}
        ctaAction={editorModel.clearAndFinish}
      >
        <p>
          You made some changes, are you sure you want to exit without saving?
        </p>
      </KiteModal>
    </div>
  )
})
