import dayjs from 'dayjs';
import { experimentStatusEnum } from '@charter/distillery-rules';

const { DRAFT } = experimentStatusEnum;

const formatAllocationHistory = (experiment, selectedEnvironment) => {
  const { environmentSamplings, environmentSamplingsHistory } = experiment;

  const samplings = environmentSamplings.filter(
    sampling => sampling.environment.id === selectedEnvironment.id,
  );
  const samplingsHistory = environmentSamplingsHistory
    .filter(sampling => sampling.environment.id === selectedEnvironment.id)
    .filter(sampling => sampling.lastModifiedUser.id !== 1);

  const isInDraft = samplings[0].experimentStatus.id === DRAFT;

  if (samplingsHistory.length === 0 || isInDraft) {
    return [];
  }

  const {
    environment,
    environmentSamplingToVariants,
    environmentSamplingToVariantsHistory,
  } = samplings[0];

  const combinedRecords = [
    ...samplings,
    ...samplingsHistory,
    ...environmentSamplingToVariants,
    ...environmentSamplingToVariantsHistory,
  ];

  const lastModifiedTimes = combinedRecords
    .reduce((accumulator, element) => {
      if (!accumulator.includes(element.lastModifiedTime)) {
        accumulator.push(element.lastModifiedTime);
      }

      return accumulator;
    }, [])
    .sort((aTime, bTime) => (dayjs(aTime).isBefore(dayjs(bTime)) ? 1 : -1));

  const initialSampling = samplingsHistory.find(
    samplingHistory =>
      samplingHistory.lastModifiedTime
      === lastModifiedTimes[lastModifiedTimes.length - 1],
  );
  const initialAllocation = environmentSamplingToVariantsHistory.filter(
    allocationHistory =>
      dayjs(allocationHistory.lastModifiedTime).isSame(
        lastModifiedTimes[lastModifiedTimes.length - 3],
        'millisecond',
      ),
  );

  const initialRecord = {
    environment,
    displayName: initialSampling.lastModifiedUser.displayName,
    lastModifiedTime: initialSampling.lastModifiedTime,
    sampleAllocation: initialSampling.sampleAllocation,
    variants: initialAllocation.map(({ id, weight }) => ({ id, weight })),
  };

  let templateRecord = initialRecord;

  const allocationHistory = lastModifiedTimes.reduce((accumulator, time) => {
    if (time === initialRecord.lastModifiedTime) {
      return [...accumulator, initialRecord];
    }
    if (time === lastModifiedTimes[lastModifiedTimes.length - 3]) {
      return accumulator;
    }
    if (!accumulator[time]) {
      const timeMatches = combinedRecords.filter(
        record => record.lastModifiedTime === time,
      );
      const matchingSampling = timeMatches.find(
        element => element.sampleAllocation,
      );
      const matchingAllocations = timeMatches.filter(element => element.weight);

      if (matchingSampling) {
        templateRecord = {
          ...templateRecord,
          displayName: matchingSampling.lastModifiedUser.displayName,
          lastModifiedTime: time,
          sampleAllocation: matchingSampling.sampleAllocation,
        };
      }

      if (matchingAllocations.length > 0) {
        templateRecord = {
          ...templateRecord,
          displayName: matchingSampling
            ? templateRecord.displayName
            : matchingAllocations[0].lastModifiedUser.displayName,
          lastModifiedTime: matchingSampling
            ? templateRecord.lastModifiedTime
            : matchingAllocations[0].lastModifiedTime,
          variants: templateRecord.variants.map((templateVariant) => {
            const match = matchingAllocations.find(
              variant => variant.id === templateVariant.id,
            );

            if (match) {
              return match;
            }

            return templateVariant;
          }),
        };
      }

      return [...accumulator, templateRecord];
    }

    return accumulator;
  }, []);

  return allocationHistory;
};

export default formatAllocationHistory;
