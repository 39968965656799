import React, { Fragment, useState } from 'react'
import { KiteButton, KiteIcon, KiteRadioButton } from '@kite/react-kite'
import { array } from 'prop-types'
import { roleEnum } from '@charter/distillery-rules'
import { DialogContainer } from '../../componentLibrary'
import './CreateDraftButton.scss'
import copyContent from './data/copyContent'

export default function CreateDraftButton({
  applicationPermissions,
  history,
  experimentCategories,
  userRoles,
}) {
  const [isModalOpen, setModalOpen] = useState(false)
  const [typeOfSetup, setTypeOfSetup] = useState(
    'traditional_front_end_experimentation'
  )
  const adminRoles = [
    roleEnum.DISTILLERY_ADMIN,
    roleEnum.STVA_PLATFROM_ADMIN,
    roleEnum.DASP_PLATFORM_ADMIN,
  ]

  const handleCreateDraft = () => {
    const roleIds = userRoles.map(role => role.roleId)
    if (
      !roleIds.find(
        roleId =>
          roleId === roleEnum.CMS_EXPERIMENTER || adminRoles.includes(roleId)
      )
    ) {
      history.push('/experiments/new')
    } else if (
      roleIds.every(
        roleId =>
          roleId === roleEnum.CMS_EXPERIMENTER || roleId === roleEnum.VIEW_ONLY
      )
    ) {
      history.push('/experiments/new-cms')
    } else {
      setModalOpen(true)
    }
  }

  const isCmsExperimenterOrAdmin = () => {
    const roleIds = userRoles.map(role => role.roleId)

    if (
      roleIds.find(
        roleId =>
          roleId === roleEnum.CMS_EXPERIMENTER || adminRoles.includes(roleId)
      )
    ) {
      return true
    } else {
      return false
    }
  }

  return (
    <Fragment>
      {isModalOpen && (
        <DialogContainer>
          <div className="metric-def-modal">
            <div className="metric-def-modal__title-area">
              <div className="metric-def-modal__fake-left" />
              <div className="metric-def-modal__title">{copyContent.title}</div>
              <KiteIcon
                size="14px"
                name="x"
                color="#000"
                onClick={() => setModalOpen(false)}
              />
            </div>
            <div className="create-draft-btn__modal">
              {experimentCategories
                .slice()
                .sort(
                  ({ displayOrder: first }, { displayOrder: second }) =>
                    first - second
                )
                .map(({ name, displayName, isEnabled, displayOrder }) => (
                  <div key={`id-${name}`}>
                    <KiteRadioButton
                      anyOthersChecked={false}
                      buttonOrientation="column"
                      checked={typeOfSetup === name}
                      disabled={!isEnabled}
                      id={`id-${name}`}
                      isFirstButton={displayOrder === 1}
                      label={displayName}
                      onChange={() => setTypeOfSetup(name)}
                      value={name}
                    />
                    {!isEnabled ? (
                      <span className="create-draft-btn__coming-soon">
                        {/* coming soon! */}
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                ))}
              <KiteButton
                className="sample-calculator__estimate-btn create-draft-btn__continue-btn"
                type="outline"
                size="large"
                maxWidth="50px"
                onClick={() =>
                  typeOfSetup === 'traditional_front_end_experimentation'
                    ? history.push('/experiments/new')
                    : history.push('/experiments/new-cms')
                }
              >
                {copyContent.continueButton}
              </KiteButton>
              <KiteButton
                className="create-draft-btn__cancel-btn"
                style={{ color: '#0073d1' }}
                type="standalone-link"
                size="small"
                onClick={() => setModalOpen(false)}
              >
                {copyContent.cancelButton}
              </KiteButton>
            </div>
          </div>
        </DialogContainer>
      )}
      <KiteButton
        disabled={!isCmsExperimenterOrAdmin()}
        onClick={() => {
          handleCreateDraft()
        }}
      >
        {copyContent.createDraftButton}
      </KiteButton>
    </Fragment>
  )
}

CreateDraftButton.propTypes = {
  experimentCategories: array.isRequired,
}
