import React, { Component } from 'react'
import flowRight from 'lodash/flowRight'
import { object } from 'prop-types'
import { KiteCard, KiteSelect } from '@kite/react-kite'

import { client } from '../../../../configuration/configApiClient'
import { LOG_ERROR } from '../../../../shared/mutations'
import { formatLoggingError } from '../../../../shared/utilities'
import { ListBuilder } from '../../../../componentLibrary'
import { getRoles, getUsers } from '../queries'
import { deleteUserWithRoles, insertUserWithRoles } from '../mutations'
import './Users.scss'

class Users extends Component {
  static propTypes = {
    allRolesData: object,
    allUsersData: object,
  }

  static defaultProps = {
    allRolesData: {},
    allUsersData: {},
  }

  state = {
    allRoles: [],
    assignedRoles: [],
    selectedUser: {},
    status: undefined,
  }

  filterRolesToUpdate = (assignedRoles, updatedRoles) => {
    const mergedRoles = [...new Set(assignedRoles.concat(updatedRoles))]
    return {
      rolesToDeleteIds: mergedRoles.filter(
        roleId => !updatedRoles.includes(roleId)
      ),
      rolesToInsertIds: mergedRoles.filter(
        roleId => !assignedRoles.includes(roleId)
      ),
    }
  }

  handleSelectedUser = event => {
    const {
      allRolesData: { roles },
      allUsersData: { users },
    } = this.props
    const user = users.find(obj => obj.displayName === event.target.value) || {}
    const assignedRoles =
      user.userToRoles.map(node =>
        (({ id, name, __typename }) => ({ id, name, __typename }))(node.role)
      ) || []

    this.setState({
      allRoles: roles,
      assignedRoles,
      selectedUser: user,
      status: undefined,
    })
  }

  handleUpdateStates = () => {
    const {
      allRolesData: { roles },
      allUsersData: { users },
    } = this.props
    const {
      selectedUser: { displayName: currentUser },
    } = this.state

    const user = users.find(obj => obj.displayName === currentUser) || {}
    const assignedRoles =
      user.userToRoles.map(node =>
        (({ id, name, __typename }) => ({ id, name, __typename }))(node.role)
      ) || []

    this.setState({
      allRoles: roles,
      assignedRoles,
      selectedUser: user,
      status: 'success',
    })
  }

  sortUsers = usersArray =>
    usersArray.slice().sort((left, right) =>
      left.displayName.localeCompare(right.displayName, 'en', {
        sensitivity: 'base',
      })
    )

  updateAssignedRoles = async updatedRoles => {
    this.setState({ status: 'saving' })
    const {
      selectedUser: { id, userToRoles: userRoles },
    } = this.state
    const {
      deleteUserWithRoles: deleteRoles,
      insertUserWithRoles: insertRoles,
    } = await this.props

    const updatedRolesIds = updatedRoles.map(role => role.id)
    const userAssignedRolesIds = userRoles.map(userRole => userRole.role.id)

    const { rolesToDeleteIds, rolesToInsertIds } = this.filterRolesToUpdate(
      userAssignedRolesIds,
      updatedRolesIds
    )

    try {
      if (rolesToDeleteIds.length > 0) {
        await deleteRoles({
          variables: {
            input: {
              userId: id,
              roleIds: rolesToDeleteIds,
            },
          },
        })
      }

      if (rolesToInsertIds.length > 0) {
        await insertRoles({
          variables: {
            input: {
              userId: id,
              roleIds: rolesToInsertIds,
            },
          },
        })
      }

      await this.handleUpdateStates()
    } catch (error) {
      const input = formatLoggingError(error)

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input,
        },
      })

      await this.setState({ status: 'error' })
    }
  }

  render() {
    const {
      allUsersData: { users },
    } = this.props

    const { allRoles, assignedRoles, selectedUser, status } = this.state

    return (
      <KiteCard isActive="true">
        <div className="users-tab">
          <KiteSelect
            id="user-select"
            name="user"
            label="User: "
            value={selectedUser.displayName || ''}
            onChange={this.handleSelectedUser}
          >
            <option value="" disabled>
              Select user
            </option>
            {users &&
              this.sortUsers(users).map(option => (
                <option key={option.id} value={option.displayName}>
                  {option.displayName}
                </option>
              ))}
          </KiteSelect>
          {allRoles && (
            <ListBuilder
              assignedOptionsTitle="Assigned Roles"
              assignedOptions={assignedRoles}
              allOptions={allRoles}
              availableOptionsTitle="Available Roles"
              onSave={this.updateAssignedRoles}
              selectedId={selectedUser.id}
              status={status}
            />
          )}
        </div>
      </KiteCard>
    )
  }
}

export default flowRight(
  getRoles,
  getUsers,
  deleteUserWithRoles,
  insertUserWithRoles
)(Users)
