/* eslint-disable no-underscore-dangle */
import { ApolloClient, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { createUploadLink } from 'apollo-upload-client'
import keycloak from './keycloak'

let REACT_APP_DE_EXPERIMENT_CONFIG_API_URL =
  process.env.REACT_APP_DE_EXPERIMENT_CONFIG_API_URL || 'http://localhost:4000'
const REACT_APP_DE_EXPERIMENT_CONFIG_API_AUTH_TOKEN =
  process.env.REACT_APP_DE_EXPERIMENT_CONFIG_API_AUTH_TOKEN || 'local-token'

if (window.Cypress) {
  REACT_APP_DE_EXPERIMENT_CONFIG_API_URL = window.Cypress.env(
    'CYPRESS_DX_REFRESH_URL'
  )
}

export {
  REACT_APP_DE_EXPERIMENT_CONFIG_API_URL,
  REACT_APP_DE_EXPERIMENT_CONFIG_API_AUTH_TOKEN,
}

const link = createUploadLink({
  uri: `${REACT_APP_DE_EXPERIMENT_CONFIG_API_URL}/graphql`,
  credentials: 'include',
  fetchOptions: {
    mode: 'cors',
  },
})

const authLink = setContext(async (request, { headers }) => {
  let token = window.localStorage.getItem('dxKcToken')
  if (!token) {
    try {
      await keycloak.updateToken(5)
      // eslint-disable-next-line prefer-destructuring
      token = keycloak.token
    } catch (error) {
      // eslint-disable-next-line no-restricted-globals
      location.reload()
    }
  }

  if (!token) {
    throw Error('Cannot find keycloak token')
  }

  const keycloakToken = `Bearer ${token}`

  return {
    headers: {
      ...headers,
      Authorization: keycloakToken,
    },
  }
})
const cache = new InMemoryCache({
  dataIdFromObject: object => {
    switch (object.__typename) {
      case 'ContentAreaType':
        return object.contentAreaId
      case 'ContentType':
        return object.contentId
      default:
        return `${object.__typename}:${object.id}`
    }
  },
})

export const client = new ApolloClient({
  link: authLink.concat(link),
  cache,
  connectToDevtools: true,
})
