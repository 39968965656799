import React from 'react'
import { arrayOf, func, object, oneOfType, string } from 'prop-types'
import './BreadCrumb.scss'
import { Link, BrowserRouter } from 'react-router-dom'

const BreadCrumb = ({ crumbs, onClick }) => {
  let crumbsLength
  const getPath = path => {
    const data = path.split('/')
    if (data[0] === '') {
      data.shift()
      crumbsLength = data.length - 1
    }
    return data
  }

  return (
    <BrowserRouter>
      <div className="bread-crumb__container">
        {(Array.isArray(crumbs) &&
          crumbs.map((obj, index) => (
            <span key={obj.label} className="bread-crumb__item">
              <Link to={obj.path} className="bread-crumb__label">
                {obj.label}
              </Link>
              {index !== crumbs.length - 1 && (
                <i className="material-icons bread-crumb__icon chevron-right">
                  chevron_right
                </i>
              )}
            </span>
          ))) ||
          getPath(crumbs).map((label, index) => (
            <span key={label} className="bread-crumb__item">
              <span
                role="button"
                tabIndex={index}
                className={`bread-crumb__label${onClick ? '' : 'no_click'}`}
                onKeyDown={`bread-crumb__label${onClick ? '' : 'no_click'}`}
                onClick={onClick ? () => onClick(label) : undefined}
              >
                {label}
              </span>
              {index !== crumbsLength && (
                <i className="material-icons bread-crumb__icon chevron-right">
                  chevron_right
                </i>
              )}
            </span>
          ))}
      </div>
    </BrowserRouter>
  )
}

export default BreadCrumb

BreadCrumb.propTypes = {
  crumbs: oneOfType([string, arrayOf(object)]).isRequired,
  onClick: func,
}

BreadCrumb.defaultProps = {
  onClick: null,
}
