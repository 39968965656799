// eslint-disable-next-line camelcase
export const GET_PRODUCTS_BY_EXPERIMENT_CAT_GQLBUILDER = {
  query: `
    productsByExperimentCategory(id: $id) {
      id
      name
      displayName
      quantumApplicationName
      quantumApplicationType
      activeAccounts
      activeDevices
      sampleCalculatorEnabled
      productCategoryType {
        id
        name
      }
    }
    productCategoryTypes(permissionEnum: $permissionEnum) {
      id
      name
    }`,
  variables: {
    $id: 'Int!',
    $permissionEnum: 'PermissionEnum!',
  },
  options: ({ experimentCategoryId, permissionEnum }) => ({
    variables: {
      id: Number(experimentCategoryId),
      permissionEnum: permissionEnum,
    },
  }),
}
