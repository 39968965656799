import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

/*
* Sample Input
*
* input {
  experimentId: Int!
  crqRequestNumber: String!
  expsiTicketNumber: String!
}
*/

const UPDATE_CHANGE_REQUEST_ON_EXPERIMENT = gql`
mutation updateChangeRequestOnExperiment($input: UpdateExperimentChangeRequest!) {
    updateExperimentChangeRequest(input: $input) 
  }
`

const updateChangeRequestOnExperiment = graphql(
    UPDATE_CHANGE_REQUEST_ON_EXPERIMENT,
  {
    name: 'updateChangeRequestOnExperiment',
  }
)

export default updateChangeRequestOnExperiment
