import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'
import { experimentEnvironmentEnum } from '@charter/distillery-rules'

const { PRODUCTION } = experimentEnvironmentEnum

/*
* Sample Input
* {
   input: {
       userId: 2,
       environmentId: 1
  }
}
*/

export const GET_NOTIFICATION_PREFERENCES_BY_ENVIRONMENT = gql`
  query getNotificationPreferencesByEnvironment(
    $input: NotificationForUserByEnvironment!
  ) {
    notificationPreferencesByEnvironment(input: $input) {
      id
      userId
      productId
      emailOn
      inAppOn
      onlyMine
      notificationDefinition {
        id
      }
    }
  }
`

const getNotificationPreferencesByEnvironment = graphql(
  GET_NOTIFICATION_PREFERENCES_BY_ENVIRONMENT,
  {
    name: 'notificationPreferencesByEnvironmentData',
    options: ({ user }) => {
      const input = { userId: user.id, environmentId: PRODUCTION }

      return {
        variables: {
          input,
        },
      }
    },
  }
)

export default getNotificationPreferencesByEnvironment
