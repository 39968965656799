import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

/*
Sample input:
{
    experimentId: 7,
    contentAreaId: "JTEST1",
    contentId: 998,
    contentJson: "{'foo':'baz'}"
 */

const INSERT_EXPERIMENT_CONTENT = gql`
  mutation insertExperimentContent($input: UpdateExperimentContent!) {
    insertExperimentContent(input: $input)
  }
`

const insertExperimentContent = graphql(INSERT_EXPERIMENT_CONTENT, {
  name: 'insertExperimentContent',
  withRef: true,
})

export default insertExperimentContent
