import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'
import { GET_ROLES, GET_USERS } from '../queries'

const INSERT_USER_WITH_ROLES = gql`
  mutation insertUserWithRoles($input: insertUserWithRolesType!) {
    insertUserWithRoles(input: $input) {
      id
      userToRoles {
        id
        role {
          id
        }
      }
    }
  }
`

const insertUserWithRoles = graphql(INSERT_USER_WITH_ROLES, {
  name: 'insertUserWithRoles',
  options: {
    refetchQueries: [{ query: GET_ROLES }, { query: GET_USERS }],
    awaitRefetchQueries: true,
  },
})

export default insertUserWithRoles
