import { gql } from '@apollo/client'

const getOverrideGroups = {
  query: `
    overrideGroups {
      id
      name
      overrides {
        id
        name
        overrideIdentifier
      }
    }
  `,
  variables: {},
}

export const GET_OVERRIDE_GROUPS = gql`
  query getOverrideGroups {
    overrideGroups {
      id
      name
      overrides {
        id
        name
        overrideIdentifier
      }
    }
  }
`

export default getOverrideGroups
