import React, { useEffect, useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'

import { KiteLoader, KiteAlert } from '@kite/react-kite'
import { LinearProgressStepper } from '@kite/react-kite-plus'
import {
  experimentStatusEnum,
  permissionEnum,
  permissionEnumReverse,
  hasPermission,
} from '@charter/distillery-rules'
import { Create, Review } from './steps'

import { ExpansionPanel, Modal } from '../../componentLibrary'
import { GET_CONTENT_MAP } from './queries/getContentMap'
import { CANCEL_CONTENT_MAP } from './mutations'
import { initialContentMapSteps } from '../../shared/data/initialSetUpSteps'
import {
  capitalizeString,
  scrollToNavigationPosition,
} from '../../shared/utilities'
import './ContentMap.scss'

import copyContent from './data/copyContent'
const { EXPERIMENT_CREATE } = permissionEnum

const initialErrorMessage = {
  name: null,
  description: null,
  rules: null,
  submit: null,
  getAudience: null,
}

export function ContentMap({
  location: { pathname: path },
  location,
  onNavigate,
  history,
  match: {
    params: { id, step },
  },
  user,
  applicationPermissions,
}) {
  const [activeStep, setActiveStep] = useState('Create')
  const [envPrefix, setEnvPrefix] = useState('')
  const [config, setConfig] = useState(initialContentMapSteps)
  const [contentMap, setContentMap] = useState(null)
  const [isDirty, setIsDirty] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [success, setSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState(initialErrorMessage)
  const [isModalOpen, setModalOpen] = useState(false)
  const [displayWarningDateBanner, setDisplayWarningDateBanner] = useState('')
  const [displayWarningActiveContent, setDisplayWarningActiveContent] =
    useState('')
  const [isDirtyModalOpen, setIsDirtyModalOpen] = useState(null)
  const [isCompleteMap, setCompeletedMap] = useState(null)
  const [isCancelContentModalOpen, setIsCancelContentModalOpen] = useState(null)
  const { loading, error, data } = useQuery(GET_CONTENT_MAP, {
    variables: { id: Number(id) },
    skip: id === 'new',
    fetchPolicy: 'network-only',
  })
  const [
    cancelContentMap,
    { loading: loadingCancelMap, error: errorCancelContentMap },
  ] = useMutation(CANCEL_CONTENT_MAP, {
    onCompleted: () => {
      handleSuccessDisplay(true)
      scrollToNavigationPosition()
    },
  })

  useEffect(() => {
    if (id === 'new') {
      onNavigate({
        path: `/content-maps/${id}/create`,
        title: 'Create Content Map',
        backPath: '/content-maps/draft',
        backTitle: 'Content Maps',
      })
    }

    if (
      !hasPermission({
        applicationPermissions,
        permissionId: permissionEnum.CONTENT_MAP_CREATE,
      })
    ) {
      setDisabled(true)
    }

    if (id && data && data.contentMap && !contentMap) {
      const {
        name,
        config,
        status: { name: statusName },
      } = data.contentMap

      onNavigate({
        path,
        title: name,
        backPath: `/content-maps/${statusName
          .toLowerCase()
          .replace(/_/g, '-')}`,
        backTitle: 'Content Maps',
      })

      if (Number(data.contentMap.statusId) !== experimentStatusEnum.DRAFT) {
        setDisabled(true)
        if (data.contentMap.stopTime < new Date().toISOString()) {
          setCompeletedMap(true)
        }
      }

      setConfig(JSON.parse(config))
      setActiveStep(capitalizeString(step))
      setContentMap(data.contentMap)

      if (data.contentMap) {
        if (
          data.contentMap.startTime < new Date().toISOString() &&
          data.contentMap.isApprovalReady &&
          data.contentMap.statusId !== experimentStatusEnum.APPROVED
        ) {
          setDisplayWarningDateBanner(copyContent.dateChangeWarning)
        }
        const conflictingMaps = data.contentMap.hasActiveContentArea.filter(
          ({ stopTime: activeStop }) =>
            !activeStop || activeStop > data.contentMap.startTime
        )
        if (
          data.contentMap.hasActiveContentArea.length &&
          data.contentMap.isApprovalReady &&
          data.contentMap.statusId !== 8 &&
          conflictingMaps.length
        ) {
          handleWarningDisplays(
            'activeContent',
            copyContent.activeContentWarning
          )
        }
      }
    }

    const baseUrl = window.location.href.split('//')[1].split('/')[0]

    if (baseUrl.startsWith('localhost') || baseUrl.startsWith('dev')) {
      setEnvPrefix('dev-')
    } else if (baseUrl.startsWith('staging')) {
      setEnvPrefix('stage-')
    }

    if (errorCancelContentMap) {
      setErrorMessage(oldErrs => ({
        ...oldErrs,
        submit: errorCancelContentMap,
      }))
    }
  }, [
    path,
    onNavigate,
    id,
    data,
    step,
    contentMap,
    errorCancelContentMap,
    applicationPermissions,
  ])

  const onChange = dirty => {
    setIsDirty(dirty)
  }

  const handleWarningDisplays = (type, message) => {
    if (type === 'activeContent') {
      setDisplayWarningActiveContent(message)
    } else if (type === 'date') {
      setDisplayWarningDateBanner(message)
    }
  }
  const handleSuccessDisplay = bool => {
    setIsDirty(false)
    setSuccess(bool)
    setTimeout(() => setSuccess(false), 5000)
  }

  const handleSubmit = (contentMap, nextStep) => {
    const { id: contentMapId, name, config } = contentMap

    if (nextStep) {
      setContentMap(contentMap)
      setActiveStep(nextStep)
      setConfig(JSON.parse(config))
      setIsDirty(false)
      onNavigate({
        path: `/content-maps/${contentMapId}/${nextStep.toLowerCase()}`,
        title: name,
        backPath: '/content-maps/draft',
        backTitle: 'Content Maps',
      })
    } else {
      setConfig(JSON.parse(config))
      setContentMap(contentMap)
      setIsDirty(false)
      if (contentMap.statusId !== experimentStatusEnum.DRAFT) {
        setDisabled(true)
      } else {
        setDisabled(false)
      }
    }
  }

  const cancelMap = async () => {
    try {
      await cancelContentMap({
        variables: {
          id: Number(id),
        },
      })
    } catch (err) {
      setErrorMessage(errors => ({
        ...errors,
        submit: err.message,
      }))
      scrollToNavigationPosition()
    }
    setIsCancelContentModalOpen(false)
  }

  if (loading)
    return (
      <div className="app__loader">
        <KiteLoader size="7rem" />
      </div>
    )

  if (error && !data) {
    return <div>Error has occurred: {error.message}</div>
  }

  return (
    <div className="content-map">
      <LinearProgressStepper
        className="set-up-experiment__stepper"
        activeStep={activeStep}
        steps={config.setUpSteps}
        onClick={event => {
          if (config.setUpSteps[0].status !== 'initial' && !isDirty) {
            history.replace(`/content-maps/${id}/${event.toLowerCase()}`)
            setActiveStep(event)
          } else if (isDirty && config.setUpSteps[0].status !== 'initial') {
            setIsDirtyModalOpen(event)
          }
        }}
        leanLeft
      />

      <ExpansionPanel
        type="minimal"
        isExpanded={!!success && !!!errorMessage.submit}
      >
        <KiteAlert
          className="app__page-level-message"
          type="confirm"
          title={'Successful'}
          description={copyContent.saveAndPublishedMessage}
        />
      </ExpansionPanel>

      <ExpansionPanel
        type="minimal"
        isExpanded={
          contentMap &&
          !!contentMap.publishedError &&
          Number(contentMap.statusId) === 9
        }
      >
        <KiteAlert
          className="app__page-level-message"
          type="caution"
          title="Warning"
          description={copyContent.syncWarning}
          linkText="Contact support."
          onLinkClick={() => {
            window.location = copyContent.syncEmail(contentMap)
          }}
        />
      </ExpansionPanel>
      <ExpansionPanel type="minimal" isExpanded={!!displayWarningDateBanner}>
        <KiteAlert
          className="app__page-level-message"
          type="caution"
          title="Warning"
          description={displayWarningDateBanner}
        />
      </ExpansionPanel>

      <ExpansionPanel type="minimal" isExpanded={!!displayWarningActiveContent}>
        <KiteAlert
          className="app__page-level-message"
          type="caution"
          title="Warning"
          description={displayWarningActiveContent}
        />
      </ExpansionPanel>

      <ExpansionPanel type="minimal" isExpanded={!!errorMessage.submit}>
        <KiteAlert
          className="app__page-level-message"
          type="alert"
          description={copyContent.errSave(errorMessage.submit)}
          onClose={() => setErrorMessage(initialErrorMessage)}
        />
      </ExpansionPanel>

      {activeStep === 'Create' && (
        <Create
          id={id}
          path={path}
          location={location}
          onNavigate={onNavigate}
          history={history}
          disabled={disabled}
          envPrefix={envPrefix}
          onChange={onChange}
          isDirty={isDirty}
          isCompleteMap={isCompleteMap}
          contentMap={contentMap}
          permissionEnum={permissionEnumReverse[EXPERIMENT_CREATE]}
          config={config}
          setIsCancelContentModalOpen={setIsCancelContentModalOpen}
          setSuccess={handleSuccessDisplay}
          handleSubmit={handleSubmit}
          setErrorMessage={setErrorMessage}
          setModalOpen={setModalOpen}
          hasPermission={hasPermission({
            applicationPermissions,
            permissionId: permissionEnum.CONTENT_MAP_CREATE,
          })}
        />
      )}

      {activeStep === 'Review' && (
        <Review
          id={id}
          path={path}
          location={location}
          onNavigate={onNavigate}
          history={history}
          disabled={disabled}
          envPrefix={envPrefix}
          onChange={onChange}
          isDirty={isDirty}
          isCompleteMap={isCompleteMap}
          contentMap={contentMap}
          config={config}
          handleSubmit={handleSubmit}
          setSuccess={handleSuccessDisplay}
          setIsCancelContentModalOpen={setIsCancelContentModalOpen}
          setErrorMessage={setErrorMessage}
          handleWarningDisplays={handleWarningDisplays}
          setModalOpen={setModalOpen}
          currentUser={user}
          hasPermission={hasPermission({
            applicationPermissions,
            permissionId: permissionEnum.CONTENT_MAP_CREATE,
          })}
        />
      )}

      {isModalOpen && (
        <Modal
          message={copyContent.createPage.changesMessage}
          onConfirm={() => {
            history.push('/content-maps/draft')
          }}
          onDeny={() => setModalOpen(false)}
          buttonProps={{
            confirm: {
              value: 'Cancel Changes',
              type: 'warning-outline',
            },
            deny: { type: 'outline' },
          }}
        />
      )}

      {isCancelContentModalOpen && (
        <Modal
          message={copyContent.cancelContentMapTitle}
          subMessage={copyContent.cancelContentMapMessage}
          onConfirm={cancelMap}
          onDeny={() => setIsCancelContentModalOpen(false)}
          loading={loadingCancelMap}
          buttonProps={{
            confirm: {
              value: 'Complete Content Map',
              type: 'warning-outline',
            },
            deny: {
              value: 'Never Mind',
              type: 'outline',
            },
          }}
        />
      )}

      {isDirtyModalOpen && (
        <Modal
          message={copyContent.createPage.changesMessage}
          onConfirm={() => {
            history.replace(
              `/content-maps/${id}/${isDirtyModalOpen.toLowerCase()}`
            )
            setActiveStep(isDirtyModalOpen)
            setIsDirty(false)
            setIsDirtyModalOpen(null)
          }}
          onDeny={() => {
            setIsDirtyModalOpen(null)
          }}
          buttonProps={{
            confirm: {
              value: 'Cancel Changes',
              type: 'warning-outline',
            },
            deny: { type: 'outline' },
          }}
        />
      )}
    </div>
  )
}

export default ContentMap
