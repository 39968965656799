import React from 'react'

export default {
  unexpectedError:
    'An unexpected error has occured.  The error has been logged and reported to Distillery',
  fiveHundredErrorMessage: "We're Having Issues",
  fiveHundredErrorHypothesis: "Hypothesis: It's not you. It's us.",
  fiveHundredErrorConclusion: 'Conclusion: Please try again later.',
  notFoundErrorMessage: 'Page Not Found',
  notFoundErrorHypothesis:
    'Hypothesis: If this page exists, then it will load.',
  notFoundErrorConclusion: "Conclusion: This page doesn't exist.",
  notAuthorizedCode: 'Not Authorized',
  notAuthorizedHeader: "You don't have access to Distillery",
  notAuthorizedMessage: (
    <div>
      {
        "If you hypothesize that you're receiving this message in error, please contact "
      }
      <a href="mailto:DL-Distillery-Support@charter.com?subject=Distillery Support  - Permissions to Distillery">
        Distillery Support
      </a>
      {' to request access.'}
    </div>
  ),
}
