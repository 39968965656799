import { gql } from '@apollo/client'

const getProductFeaturesById = gql`
  query getProductFeatures($id: Int!) {
    product(id: $id) {
      id
      features {
        id
        displayName
        featureType {
          id
          name
        }
      }
    }
  }
`

export default getProductFeaturesById
