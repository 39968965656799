import React, { Component } from 'react'
import classNames from 'classnames'
import { string, number, array, func, bool, oneOfType } from 'prop-types'
import './ColoredSelect.scss'

class ColoredSelect extends Component {
  static propTypes = {
    onSelect: func.isRequired,
    options: array.isRequired,
    className: string,
    value: oneOfType([string, number]),
    placeholder: string,
    disabled: bool,
    errorMessage: string,
  }

  static defaultProps = {
    className: null,
    value: null,
    placeholder: null,
    disabled: false,
    errorMessage: null,
  }

  state = {
    isActive: false,
  }

  // LOCAL STATE CHANGE/TOGGLE METHODS
  handleToggleIsActive = () => {
    const { isActive } = this.state

    if (!isActive) {
      document.addEventListener(
        'click',
        this.handleColoredSelectOutsideClick,
        false
      )
    }

    this.setState({ isActive: !isActive })
  }

  handleSelect = option => {
    const { onSelect } = this.props

    onSelect(option)
    this.setState({ isActive: false })

    if (this.options) {
      this.handleRemoveOutsideClickListener()
    }
  }

  handleColoredSelectOutsideClick = event => {
    if (this.options && !this.options.contains(event.target)) {
      this.handleRemoveOutsideClickListener()
      this.setState({ isActive: false })
    }
  }

  handleRemoveOutsideClickListener = () => {
    document.removeEventListener(
      'click',
      this.handleColoredSelectOutsideClick,
      false
    )
  }

  render() {
    const { isActive } = this.state
    const {
      className,
      value,
      options,
      disabled,
      placeholder,
      errorMessage,
    } = this.props
    const selectClassNames = classNames({
      'colored-select__input': true,
      'colored-select__input-warning': errorMessage,
    })

    const selectedValue = options.find(option => option.name === value)

    return (
      <div className={`colored-select ${className || ''}`}>
        <div
          role="listbox"
          className={selectClassNames}
          onClick={this.handleToggleIsActive}
          onKeyDown={this.handleToggleIsActive}
          disabled={disabled}
          style={{ backgroundColor: `${selectedValue.color}` }}
        >
          <span className="colored-select__value">{value || placeholder}</span>

          <div className="colored-select__input-icon" />
        </div>

        {isActive && (
          <div
            className="colored-select__options"
            ref={element => {
              this.options = element
            }}
          >
            {options
              .filter(env => env.name !== selectedValue.name)
              .map((option, index) => (
                <span
                  key={`${option.name}__${option.color.replace(/#/g, '')}`}
                  role="option"
                  aria-selected={value === option.name}
                  tabIndex={index}
                  className="colored-select__option"
                  onClick={() => this.handleSelect(option)}
                  onKeyDown={() => this.handleSelect(option)}
                >
                  {option.name}
                </span>
              ))}
          </div>
        )}
      </div>
    )
  }
}

export default ColoredSelect
