const typeEnum = {
  string: 'text',
  integer: 'number',
  array: 'multiselect',
}

const formatUserProfileFields = data => {
  const fields = {}

  data.forEach(dataValue => {
    const { name, type, values } = dataValue
    fields[name] = {
      label: name,
    }

    if (values) {
      fields[name] = handleFormatValues(values, name)
    } else if (type === 'array') {
      fields[name].type = typeEnum[type]
      fields[name].valueSources = ['value']
      fields[name].operators = ['multiselect_equals', 'multiselect_not_equals']
    } else {
      fields[name].type = typeEnum[type] || type
      fields[name].valueSources = ['value']
      fields[name].fieldSettings = { allowCustomValues: true }
    }
  })

  return fields
}

export const createUserProfile = (
  fields,
  mongoRule = {},
  contentMapData = {}
) => {
  const userProfile = {}

  Object.keys(mongoRule).forEach(key => {
    if (
      fields[key] &&
      mongoRule[key]['$elemMatch'] &&
      !mongoRule[key]['$elemMatch']['$and']
    ) {
      userProfile[key] = mongoRule[key]['$elemMatch']
    } else if (
      fields[key] &&
      mongoRule[key]['$elemMatch'] &&
      mongoRule[key]['$elemMatch']['$and']
    ) {
      userProfile[key] = mongoRule[key]['$elemMatch']['$and']
    } else if (
      fields[key] &&
      mongoRule[key]['$elemMatch'] &&
      mongoRule[key]['$elemMatch']['$or']
    ) {
      userProfile[key] = mongoRule[key]['$elemMatch']['$or']
    } else if (key === 'marketingProfiles') {
      if (mongoRule[key]['$eq']) {
        userProfile[key] = [mongoRule[key]['$eq']]
      } else {
        userProfile[key] = [mongoRule[key]]
      }
    } else if (fields[key]) {
      userProfile[key] = mongoRule[key]
    } else if (key === '$and' || key === '$or') {
      mongoRule[key].forEach(rule => {
        for (let k in rule) {
          userProfile[k] = rule[k]
        }
      })
    }
  })

  if (contentMapData && contentMapData.contentMapId) {
    userProfile.txnId = `forceContentMap:${contentMapData.contentAreaId}:${contentMapData.content.contentId}`
  }

  return userProfile
}

const handleFormatValues = (valueObj, labelName = '') => {
  const { type, enum: enumValues, properties } = valueObj

  if (enumValues) {
    return {
      type: 'select',
      label: labelName,
      valueSources: ['value'],
      excludeOperators: ['select_any_in', 'select_not_any_in'],
      fieldSettings: {
        listValues: enumValues.map(val => ({
          value: val.toUpperCase(),
          title: val,
        })),
        fieldSettings: { allowCustomValues: true },
      },
    }
  } else if (properties && type === 'object') {
    let groupObj = {
      type: '!struct',
      label: labelName,
      valueSources: ['value'],
      subfields: {},
      fieldSettings: { allowCustomValues: true },
    }

    properties.forEach(subprop => {
      groupObj.subfields[subprop.name] = handleFormatValues(
        subprop,
        subprop.name
      )
    })
    return groupObj
  } else if (properties) {
    let groupObj = {
      type: '!group',
      label: labelName,
      subfields: {},
      valueSources: ['value'],
      fieldSettings: { allowCustomValues: true },
    }

    properties.forEach(subprop => {
      groupObj.subfields[subprop.name] = handleFormatValues(
        subprop,
        subprop.name
      )
    })
    return groupObj
  } else {
    return {
      type: typeEnum[type] || type,
      label: labelName,
      valueSources: ['value'],
      fieldSettings: { allowCustomValues: true },
    }
  }
}

export default formatUserProfileFields
