import { observer } from 'mobx-react'
import React, { Fragment, useRef } from 'react'
import {
  KiteCard,
  KiteInput,
  KiteButton,
  KiteModal,
  KiteIcon,
  KiteLink,
  KiteLoader,
} from '@kite/react-kite'
import { AutoSuggest } from '@kite/react-kite-plus'

import './ORGroupEditor.scss'
import SaveOrExit from '../../components/SaveOrExit/SaveOrExit'
import kiTrashImg from './ki-trash.png'
import { REACT_APP_DE_EXPERIMENT_CONFIG_API_URL } from '../../configuration/configApiClient'

const ORGroupAdd = observer(({ editorModel, orListModel }) => {
  const uploadInputRef = useRef(null)

  return (
    // grid (1, 1)
    <KiteCard className="or-group-editor__add">
      {/* row - title */}
      <div className="or-group-editor__add-title">Add Overrides To Group</div>

      {/* row - instructions */}
      <div>Optional, add in bulk in 2 easy steps using our CSV template:</div>

      {/* row - bulk add */}
      <div className="or-group-editor__add-bulk">
        <div>
          <KiteIcon name="arrow-down-circle" />
          <KiteLink
            className="or-group-editor__add-download"
            type="inline"
            target="_blank"
            href={`${REACT_APP_DE_EXPERIMENT_CONFIG_API_URL}/Overrides_Template.xlsm`}
          >
            1. Download OverridesTemplate.xslx
          </KiteLink>
          <KiteButton
            size="large"
            type="outline"
            className="or-group-editor__add-upload"
            leftIcon="arrow-up-circle"
            onClick={() => uploadInputRef.current.click()}
          >
            2. Upload Exported CSV
          </KiteButton>
          <input
            ref={uploadInputRef}
            style={{ display: 'none' }}
            accept=".csv"
            type="file"
            onChange={({ target: { files } }) =>
              editorModel.processCSVUpload(files)
            }
          />
        </div>
      </div>

      {/* row - search add */}
      <div className="or-group-editor__add-search">
        <AutoSuggest
          className="or-group-editor__add-suggest"
          label="Or search for individual overrides:"
          placeholder="Search by name or ID"
          value={editorModel.orSearchValue}
          onChange={({ target: { value } }) => {
            editorModel.orSearchValue = value
          }}
          onSuggestionSelected={(event, { suggestion: orEditorModel }) => {
            editorModel.addOverride(orEditorModel)
          }}
          initialData={editorModel.filterOREditorModels(
            orListModel.editorModels.slice()
          )}
          suggestionKey="displayName"
        />
        <KiteButton
          size="small"
          type="outline"
          className="or-group-editor__add-button"
        >
          Add To Group
        </KiteButton>
      </div>
    </KiteCard>
  )
})

const ORHeaderRow = observer(
  ({ headerIconName, headerIconColor = 'none', headerLabel }) => {
    return (
      <div className="or-group-editor__list-header or-group-editor__list-header-row">
        {headerIconName ? (
          <KiteIcon color={headerIconColor} name={headerIconName} />
        ) : null}
        {headerLabel}
      </div>
    )
  }
)

const ORGroupRows = observer(
  ({
    overrides,
    onDelete,
    headerLabel,
    headerIconColor = 'none',
    headerIconName,
  }) => {
    const renderedOverrides = overrides.map(override => {
      const { name, key, overrideTypeName, identifier } = override

      return (
        /* grid (4, rows) - row */
        <div key={key} className="or-group-editor__list-row">
          {/* col - name */}
          <div className="or-group-editor__list-col or-group-editor__list-col-name">
            {name}
          </div>

          {/* col - type */}
          <div className="or-group-editor__list-col or-group-editor__list-col-type">
            {overrideTypeName}
          </div>

          {/* col - status  ? accountGuid */}
          <div className="or-group-editor__list-col or-group-editor__list-col-accountGuid">
            {identifier}
          </div>

          {/* col -  remove from group */}
          <div
            title="Delete from group"
            onClick={() => onDelete && onDelete(override)}
            className="or-group-editor__list-col or-group-editor__list-col-delete"
          >
            {override.inProgressLookupRequest ? (
              '...'
            ) : (
              <img
                alt="Delete from group"
                src={kiTrashImg}
                width="16"
                height="16"
              ></img>
            )}
          </div>
        </div>
      )
    })

    if (!headerLabel) return renderedOverrides
    return (
      <Fragment>
        <ORHeaderRow
          headerIconName={headerIconName}
          headerIconColor={headerIconColor}
          headerLabel={headerLabel}
        />
        {renderedOverrides}
      </Fragment>
    )
  }
)

const formatSize = size =>
  size > 0 ? `(${size} Override${size > 1 ? 's' : ''})` : ''

const ORGroupList = observer(({ editorModel }) => {
  return (
    /* grid (1, rows) */
    <KiteCard className="or-group-editor__list">
      {/* row - header */}
      <div className="or-group-editor__list-header">
        Group Contents {formatSize(editorModel.groupSize)}
      </div>

      {/* row - contents */}
      <div className="or-group-editor__list-contents">
        {!editorModel.inBulkMode && editorModel.overrides.length === 0 && (
          <div className="or-group-editor__list-empty">No Overrides Yet</div>
        )}
        {editorModel.inLookup?.length > 0 ? (
          <ORGroupRows
            overrides={editorModel.inLookup}
            onDelete={editorModel.deleteToSave}
            headerIconName="search-f"
            headerIconColor="#0062b2"
            headerLabel={`Looking up ${formatSize(
              editorModel.inLookup.length
            )}`}
          />
        ) : null}
        {editorModel.toFix?.length > 0 ? (
          <ORGroupRows
            overrides={editorModel.toFix}
            onDelete={editorModel.deleteToSave}
            headerIconName="caution-circle-f"
            headerIconColor="#d6312b"
            headerLabel={`Lookup failed and will not be added to group ${formatSize(
              editorModel.toFix.length
            )}`}
          />
        ) : null}

        {editorModel.toCreate?.length > 0 ? (
          <ORGroupRows
            overrides={editorModel.toCreate}
            onDelete={editorModel.deleteToSave}
            headerIconName="checkmark"
            headerIconColor="#02BF20"
            headerLabel={`Will be created and added to group ${formatSize(
              editorModel.toCreate.length
            )}`}
          />
        ) : null}
        {editorModel.existingOREditorModels?.length > 0 ? (
          <ORGroupRows
            overrides={editorModel.existingOREditorModels}
            onDelete={editorModel.deleteOverride}
            headerIconName="checkmark"
            headerIconColor="#02BF20"
            headerLabel={`Already created and will be added to group ${formatSize(
              editorModel.existingOREditorModels.length
            )}`}
          />
        ) : null}
        {editorModel.existingOREditorModelsInGroup?.length > 0 ? (
          <ORGroupRows
            overrides={editorModel.existingOREditorModelsInGroup}
            onDelete={editorModel.deleteOverride}
            headerLabel={`Already created and in group ${formatSize(
              editorModel.existingOREditorModelsInGroup.length
            )}`}
          />
        ) : null}
        {!editorModel.inBulkMode && editorModel.overrides?.length > 0 ? (
          <ORGroupRows
            overrides={editorModel.overrides}
            onDelete={editorModel.deleteOverride}
          />
        ) : null}
      </div>
    </KiteCard>
  )
})

const ORGroupDetailsEditor = observer(function ORGroupDetailsEditor({
  editorModel,
}) {
  return (
    /* grid (1, rows)} - edit details of override */
    <KiteCard className="or-group-editor__details">
      {/* row  - edit name */}
      <KiteInput
        name="name"
        label="Group Name"
        value={editorModel.name}
        onChange={editorModel.onChange}
        errorMessage={editorModel.errorMessage('name')}
      />

      {/* row  - edit description */}
      <KiteInput
        name="description"
        label="Group Description (Optional)"
        value={editorModel.description}
        onChange={editorModel.onChange}
        errorMessage={editorModel.errorMessage('description')}
      />

      {editorModel.id && (
        <Fragment>
          <div className="or-group-editor__details-applied disable-scrollbars">
            <div style={{ marginBottom: 5 }}>
              Currently applied to experiments:
            </div>
            {/* row  - currently applied to experiments */}
            {editorModel.inProgressCheckAppliedInExperiments ? (
              <KiteLoader />
            ) : editorModel.appliedInExperiments.length ? (
              editorModel.appliedInExperiments.map(({ id, name, url }) => (
                <a key={id} className="exp-link" href={url}>
                  <div>{name}</div>
                  <KiteIcon name="arrow-up-right" size="16px" />
                </a>
              ))
            ) : editorModel.hasCheckedAppliedInExperiments ? (
              <div>Not applied in any experiments yet</div>
            ) : (
              <div></div>
            )}
          </div>

          {/* row  - delete button */}
          <KiteButton
            className="or-group-editor__delete"
            leftIcon="trash-f"
            size="medium"
            type="outline"
            loading={editorModel.isDeleting}
            onClick={() => (editorModel.isDeleting = true)}
          >
            Delete Group
          </KiteButton>
          <KiteModal
            className="confirm-modal"
            canShow={editorModel.isDeleting}
            ctaCopy="Yes, Delete Group"
            secondaryCtaCopy="Nevermind"
            title=""
            ctaLoading={editorModel.isSaving}
            secondaryCtaAction={() => (editorModel.isDeleting = false)}
            ctaAction={editorModel.save}
          >
            <p>
              This will delete this group and all{' '}
              {editorModel.overrides?.length} overrides.
            </p>
          </KiteModal>
        </Fragment>
      )}
    </KiteCard>
  )
})

export default observer(function ORGroupEditor({ editorModel, orListModel }) {
  return (
    /* grid (2,rows) - edit a group */
    <div className="or-group-editor">
      {/* row */}
      {/*col - title */}
      <div className="or-group-editor__title">
        {editorModel.id ? 'Edit' : 'Add New '} Group
      </div>
      <div></div>

      {/* row */}
      {/*col - edit group details */}
      <ORGroupDetailsEditor editorModel={editorModel} />
      {/*col - Add overrides To Group */}
      <ORGroupAdd editorModel={editorModel} orListModel={orListModel} />

      {/* row */}
      {/* across 2 cols - table of overrides */}
      <ORGroupList editorModel={editorModel} />

      {/* row */}
      {/* col - Save or exit */}
      <SaveOrExit editorModel={editorModel} />
      <div></div>
    </div>
  )
})
