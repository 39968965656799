import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

/*
* Sample Input
* {
	"input": {
    "tdcsExperimentId": 2,
    "experimentId": 2,
    "tdcsInformCallId": 1,
    "productMinVersion": "1.0",
    "productMaxVersion": "10.0",
    "tdcsExperimentFilters": [
      {
        "tdcsExperimentFilterId": 1,
        "isEnabled": 0
      }
    ]
  }
}
*/
const UPDATE_EXPERIMENT_TECH_SETTINGS = gql`
  mutation updateExperimentTechSettings($input: UpdateExperimentTechSettings!) {
    updateExperimentTechSettings(input: $input) {
      id
      experimentId
      productMinVersion
      productMaxVersion
      tdcsInformCall {
        id
        name
        displayName
      }
      tdcsExperimentFilters {
        id
        textValue
        isEnabled
        isIncluded
        tdcsFilter {
          id
          name
          displayName
          displayOrder
          tdcsFilterType {
            id
            name
            displayName
            isBoolean
          }
        }
      }
    }
  }
`

const updateExperimentTechSettings = graphql(UPDATE_EXPERIMENT_TECH_SETTINGS, {
  name: 'updateExperimentTechSettings',
  withRef: true,
})

export default updateExperimentTechSettings
