export default function isRequestFormComplete(form) {
    const {
        email,
        supportRequestType,
    } = form
    if (email.length && supportRequestType.length){
        switch (supportRequestType) {
            case 'Experiment Support':
                if (form.experimentLink && form.experimentStep && form.description){
                    return true
                }
                break
            case 'Bugfix':
                if (form.whereBugEncountered && form.stepsToReproduce){
                    return true
                }
                break
            case 'Feature Request/Enhancement':
                if (form.featureDescription){
                    return true
                }
                break
            case 'Other':
                if (form.other){
                    return true
                }
                break
            default:
                return false
        }
    } 
    return false  
}