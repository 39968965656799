import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'
/*
* Sample Input
* {
  input: {
    experimentId: Int
    name: String
  }
}
*/

export const CLONE_EXPERIMENT = gql`
  mutation cloneExperiment($input: CloneExperimentInput!) {
    cloneExperiment(input: $input) {
      id
      uuid
      config
      name
      summary
      hypothesis
      durationNumDays
      experimentTypeId
      isTechPocApproved
      isCmsExperiment
      experimentStatus {
        id
        name
      }
      experimentToProductFeatures {
        id
        productId
        productFeatureId
        product {
          id
          displayName
        }
        productFeature {
          id
          displayName
        }
      }
    }
  }
`

const cloneExperiment = graphql(CLONE_EXPERIMENT, {
  name: 'cloneExperiment',
})

export default cloneExperiment
