import React, { Component, Fragment } from 'react'
import { object, func } from 'prop-types'
import flowRight from 'lodash/flowRight'

import {
  KiteLoader,
  KiteSelect,
  KiteInput,
  KiteButton,
  KiteAlert,
} from '@kite/react-kite'

import {
  estimateDays,
  calculateRawMeanDifference,
  estimateSampleSize,
} from '@charter/distillery-rules'

import { ExpansionPanel } from '../../componentLibrary'

import copyContent from './data/copyContent'
// eslint-disable-next-line camelcase
import { GET_ALL_PRODUCTS_GQLBUILDER } from '../../shared/queries'
import { formatLoggingError, GqlBuilder } from '../../shared/utilities'
import { client } from '../../configuration/configApiClient'
import { LOG_ERROR } from '../../shared/mutations'
import {
  getProductMetricsById,
  getMetricMeanAndSigmaValues,
  getAllZaValues,
} from './queries/index'
import './ExperimentCalculator.scss'

const gqlHOCQuery = new GqlBuilder().compileHOCQuery([
  GET_ALL_PRODUCTS_GQLBUILDER,
  getAllZaValues,
])

// These are constant values
const mdeValues = [0.01, 0.02, 0.05, 0.1, 0.15, 0.2, 0.25, 0.5]

const initialErrors = {
  name: null,
  product: null,
  metric: null,
  mde: null,
  totalSampling: null,
  zaValue: null,
  getProductMetricsById: null,
  getMdeValues: null,
  estimate: null,
  rawMeanDifference: null,
}

export class ExperimentCalculator extends Component {
  static propTypes = {
    onNavigate: func.isRequired,
    queryData: object.isRequired,
  }

  state = {
    product: '',
    metric: '',
    mde: '',
    accounts: '000',
    devices: '000',
    estimatedTraffic: 0,
    estimatedDays: 0,
    totalSampling: 0,
    zaValue: 0,
    metricMean: '000',
    sigma: 0,
    rawMeanDifference: '000',
    productMetrics: [],
    sigmaMeanValues: [],
    hasEstimate: false,
    errorMessage: initialErrors,
  }

  // LIFECYCLE METHODS
  componentDidMount() {
    const { onNavigate } = this.props

    onNavigate(
      {
        path: '/sample-calculator',
        title: 'Suggested Sample Size Calculator',
      },
      true
    )
  }

  // LOCAL STATE CHANGES/TOGGLES
  handleChange = async ({ target: { name, value } }) => {
    const {
      metric,
      errorMessage: {
        getMdeValues,
        mde: mdeErrors,
        metric: metricErrors,
        product: productErrors,
        totalSampling: samplingErrors,
        zaValue: variantErrors,
        getProductMetricsById: getMetricErrors,
        estimate: estimateErrors, // TODO decide if necessary
        rawMeanDifference: rawMeanDifferenceErrors,
      },
    } = this.state

    const {
      queryData: { allProductCategoryTypes },
    } = this.props

    if (
      getMdeValues ||
      mdeErrors ||
      metricErrors ||
      productErrors ||
      samplingErrors ||
      variantErrors ||
      getMetricErrors ||
      estimateErrors ||
      rawMeanDifferenceErrors
    ) {
      await this.setState({
        errorMessage: initialErrors,
      })
    }

    if (name === 'product') {
      let accounts = '000'
      let devices = '000'

      allProductCategoryTypes.forEach(category =>
        category.allProducts.forEach(product => {
          if (product.id === Number(value)) {
            const { activeAccounts, activeDevices } = product

            accounts = Math.round((activeAccounts / 1000000) * 100) / 100
            devices = Math.round((activeDevices / 1000000) * 100) / 100
          }
        })
      )

      await this.setState({
        product: value,
        accounts,
        devices,
      })

      this.handleGetProductMetrics(JSON.parse(value))

      // If changing product and a metric is selected, reset the metric
      if (metric !== '') {
        await this.setState({
          sigma: 0,
          metricMean: '000',
          rawMeanDifference: '000',
          estimatedTraffic: 0,
          estimatedDays: 0,
          hasEstimate: false,
          metric: '',
          productMetrics: [],
          sigmaMeanValues: [],
        })
      }
    } else if (name === 'totalSampling') {
      const sampling = Math.trunc(+Number(value))

      if (sampling >= 0 && sampling <= 100) {
        await this.setState(({ errorMessage }) => ({
          [name]: sampling,
          errorMessage: {
            ...errorMessage,
            [name]: null,
          },
        }))
      }
    } else {
      // Handles change of all other values
      // eslint-disable-next-line no-shadow
      await this.setState(({ errorMessage }) => ({
        [name]: value,
        estimatedTraffic: 0,
        estimatedDays: 0,
        hasEstimate: false,
        errorMessage: {
          ...errorMessage,
          [name]: null,
        },
      }))

      if (name === 'metric') {
        await this.handleGetMeanValues()
        await this.handleGetRawMeanDifference()
      } else if (name === 'mde') {
        await this.handleGetRawMeanDifference()
      }
    }
  }

  handleResetInput = async () => {
    this.setState({
      product: '',
      metric: '',
      mde: '',
      accounts: '000',
      devices: '000',
      estimatedTraffic: 0,
      estimatedDays: 0,
      totalSampling: 0,
      zaValue: 0,
      sigma: 0,
      metricMean: '000',
      rawMeanDifference: '000',
      productMetrics: [],
      sigmaMeanValues: [],
      hasEstimate: false,
      errorMessage: initialErrors,
    })
  }

  // API METHODS
  handleGetProductMetrics = async id => {
    const { errorMessage } = this.state
    const gqlQuery = new GqlBuilder()
    // Add gql variables to object
    getProductMetricsById.gqlVars = { id }
    getMetricMeanAndSigmaValues.gqlVars = {}

    const query = gqlQuery.compileQuery([
      getProductMetricsById,
      getMetricMeanAndSigmaValues,
    ])

    // Reset any error messages
    if (errorMessage.getProductMetricsById) {
      this.setState({
        errorMessage: {
          ...errorMessage,
          getProductMetricsById: null,
        },
      })
    }

    try {
      const response = await client.query(query)

      const productMetrics = response.data.metricsForProduct
      const sigmaMeanValues = response.data.findMeanAndSigmaValues

      this.setState({ productMetrics, sigmaMeanValues })
    } catch (error) {
      const input = formatLoggingError(error)

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input,
        },
      })
      this.setState({
        errorMessage: {
          ...errorMessage,
          getProductMetricsById:
            'There was an issue grabbing the metrics for this product.',
        },
      })
    }
  }

  handleGetMeanValues = async () => {
    const { product, metric, sigmaMeanValues } = this.state
    const productID = Number(product)
    const metricID = Number(metric)

    const { metricMean, sigma } = sigmaMeanValues.find(
      ({ productId, metricId }) =>
        productID === productId && metricID === metricId
    )

    await this.setState({
      metricMean,
      sigma,
    })
  }

  handleGetEstimate = async () => {
    const isValid = await this.validateEstimateVariables()
    const {
      sigma,
      accounts,
      totalSampling,
      zaValue,
      rawMeanDifference,
    } = this.state
    const {
      queryData: { zaValues },
    } = this.props

    if (isValid) {
      const sampleRate = Number(totalSampling) / 100
      const { variantAmount } = zaValues.find(
        ({ zaValue: za }) => Number(za) === Number(zaValue)
      )
      const variants = Number(variantAmount)
      const sampleSize = estimateSampleSize(
        Number(sigma),
        Number(zaValue),
        Number(rawMeanDifference)
      )

      // Getting entire population
      const requiredSamples = sampleSize * (variants + 1)
      // Estimating daily users
      const estimatedUsers = (accounts * 1000000) / 31
      const estimatedDays = estimateDays(
        requiredSamples,
        estimatedUsers,
        sampleRate
      )

      await this.setState({
        hasEstimate: true,
        estimatedTraffic: sampleSize,
        estimatedDays,
      })
    }
  }

  handleGetRawMeanDifference = async () => {
    const { metricMean, mde } = this.state

    if (metricMean !== '000' && Number(mde)) {
      const rawMeanDifference = calculateRawMeanDifference(
        metricMean,
        Number(mde)
      )
      await this.setState({
        rawMeanDifference,
      })
    }
  }

  // Validate Methods
  validateEstimateVariables = async () => {
    const {
      mde,
      metric,
      product,
      zaValue,
      totalSampling,
      errorMessage,
    } = this.state

    // Validate all fields are selected
    const productErr = !product.length ? 'You must select a product.' : null
    const metricErr = !metric.length ? 'You must select a metric.' : null
    const mdeErr = !mde.length ? 'You must select a MDE value.' : null
    const variantAmountErr = !zaValue
      ? 'You must select a variant amount.'
      : null
    const totalSamplingErr = !totalSampling
      ? 'You must have more than 0% total sampling.'
      : null

    // Verify if there are error messages
    if (
      productErr ||
      metricErr ||
      mdeErr ||
      variantAmountErr ||
      totalSamplingErr
    ) {
      await this.setState({
        errorMessage: {
          ...errorMessage,
          product: productErr,
          metric: metricErr,
          mde: mdeErr,
          variantAmount: variantAmountErr,
          totalSampling: totalSamplingErr,
        },
      })

      return false
    }

    return true
  }

  // RENDER METHODS
  renderProductOptions = () => {
    const {
      queryData: { allProductCategoryTypes },
    } = this.props

    // NOTE: currently in this map function we are disabling the support
    // category and spectrum guide. When data is avaliable disable
    return (
      <Fragment>
        <option value="" disabled>
          Select product
        </option>

        {allProductCategoryTypes
          .slice()
          .sort((aType, bType) => aType.name > bType.name)
          .map(category => (
            <optgroup key={category.id} label={category.name}>
              {category.allProducts
                .slice()
                .sort((aProduct, bProduct) =>
                  aProduct.displayName.toLowerCase() >
                  bProduct.displayName.toLowerCase()
                    ? 1
                    : -1
                )
                .map(product => (
                  <option
                    key={product.id}
                    value={product.id}
                    disabled={!product.sampleCalculatorEnabled}
                  >
                    {product.displayName}
                  </option>
                ))}
            </optgroup>
          ))}
      </Fragment>
    )
  }

  renderMetricOptions = () => {
    const { productMetrics, sigmaMeanValues } = this.state
    const metricsEnabled = sigmaMeanValues.map(({ metricId }) => metricId)

    return (
      <Fragment>
        <option value="" disabled>
          Select metric
        </option>
        {productMetrics
          .slice()
          .sort((aType, bType) => aType.name > bType.name)
          .filter(({ metricType: { id } }) => id === 1)
          .map(metric => (
            <option
              key={metric.id}
              value={metric.id}
              disabled={!metricsEnabled.includes(metric.id)}
            >
              {metric.displayName}
            </option>
          ))}
      </Fragment>
    )
  }

  renderVariantAmount = () => {
    const {
      queryData: { zaValues },
    } = this.props

    return (
      <Fragment>
        <option value="0" disabled>
          Select one
        </option>
        {zaValues.map(({ variantAmount, zaValue }) => (
          <option key={variantAmount} value={zaValue}>
            {variantAmount}
          </option>
        ))}
      </Fragment>
    )
  }

  render() {
    const {
      product,
      metric,
      mde,
      totalSampling,
      zaValue,
      errorMessage,
      hasEstimate,
      estimatedTraffic,
      estimatedDays,
      accounts,
      devices,
      metricMean,
      rawMeanDifference,
    } = this.state

    const {
      queryData: { loading: dataLoading },
    } = this.props

    if (dataLoading) {
      return (
        <div>
          <KiteLoader size="7rem" />
        </div>
      )
    }

    const estimatedDaysClassName =
      estimatedDays > 62
        ? 'sample-calculator__warn-number'
        : 'sample-calculator__number'
    const estimatedDaysBagdgeWrapper =
      estimatedDays > 62
        ? 'sample-calculator__estimate-warn-badge-wrap'
        : 'sample-calculator__estimate-badge-wrap'

    return (
      // TODO Change this to a kite card instead of making a class with the same thing
      <div className="sample-calculator__accent-color-container">
        <div className="sample-calculator__accent-color">
          <div className="sample-calculator__accent-color-children">
            <ExpansionPanel
              type="minimal"
              isExpanded={estimatedDays > 62 || errorMessage.estimate}
            >
              <KiteAlert
                className="app__page-level-message"
                type="alert"
                description="Please select different parameters, as your experiment won't be able to detect significance within our maximum recommended run time (62 days)."
              />
            </ExpansionPanel>
            <div className="sample-calculator__product-container">
              <KiteSelect
                className="sample-calculator__product"
                name="product"
                label="Product"
                maxWidth="350px"
                value={product}
                onChange={this.handleChange}
                errorMessage={
                  errorMessage.product || errorMessage.getProductMetricsById
                    ? `${errorMessage.product || ''} ${
                        errorMessage.getProductMetricsById || ''
                      }`
                    : false
                }
              >
                {this.renderProductOptions()}
              </KiteSelect>
              <KiteSelect
                className="sample-calculator__metric"
                name="metric"
                label="Metric"
                maxWidth="350px"
                value={metric}
                onChange={this.handleChange}
                errorMessage={errorMessage.metric}
              >
                {this.renderMetricOptions()}
              </KiteSelect>
            </div>
            <div className="sample-calculator__information-container">
              <ExpansionPanel
                type="minimal"
                isExpanded={accounts !== '000' && devices !== '000'}
              >
                <div className="sample-calculator__product-population">
                  <div>{`Accounts: ${accounts} million`}</div>
                  <div>{`Devices: ${devices} million`}</div>
                </div>
              </ExpansionPanel>
              <div>
                <ExpansionPanel
                  type="minimal"
                  isExpanded={metricMean !== '000'}
                >
                  <div className="sample-calculator__metric-score">
                    {`Average metric score currently running: ${metricMean}`}
                  </div>
                </ExpansionPanel>
              </div>
            </div>
            <div className="sample-calculator__metric-mde-container">
              <KiteSelect
                className="sample-calculator__mde"
                name="mde"
                tooltip={copyContent.mdeTooltip}
                label="MDE (%)"
                maxWidth="350px"
                value={mde}
                onChange={this.handleChange}
                errorMessage={errorMessage.mde}
              >
                <option value="" disabled>
                  Select percentage
                </option>
                {mdeValues.map(mdeValue => (
                  <option key={mdeValue} value={mdeValue}>
                    {`${mdeValue * 100}%`}
                  </option>
                ))}
              </KiteSelect>
              <KiteInput
                className="sample-calculator__total-sampling"
                id="totalSampling"
                name="totalSampling"
                label="Total Sampling (%)"
                value={totalSampling}
                onChange={this.handleChange}
                margin="0 15px 0 0"
                maxWidth="165px"
                errorMessage={errorMessage.totalSampling}
              />
              <KiteSelect
                className="sample-calculator__variant-amount"
                id="zaValue"
                name="zaValue"
                label="How many variants?"
                value={zaValue}
                onChange={this.handleChange}
                margin="0 20px 0 0"
                maxWidth="165px"
                errorMessage={errorMessage.zaValue}
              >
                {this.renderVariantAmount()}
              </KiteSelect>
            </div>
            <div className="sample-calculator__information-container">
              <ExpansionPanel
                type="minimal"
                isExpanded={rawMeanDifference !== '000'}
              >
                <div className="sample-calculator__product-population">
                  <div>{`Raw mean difference: ${rawMeanDifference}`}</div>
                </div>
              </ExpansionPanel>
            </div>
            <div className="sample-calculator__btn-container">
              <KiteButton
                type="outline"
                size="large"
                buttonType="reset"
                onClick={this.handleResetInput}
              >
                Reset
              </KiteButton>
              <KiteButton
                className="sample-calculator__estimate-btn"
                type="outline"
                size="large"
                maxWidth="50px"
                onClick={this.handleGetEstimate}
              >
                Estimate
              </KiteButton>
            </div>
            <ExpansionPanel
              type="minimal"
              className="sample-calculator__estimate-container"
              isExpanded={hasEstimate}
            >
              <span className="sample-calculator__estimate-results">
                <div className={estimatedDaysBagdgeWrapper}>
                  <div className="sample-calculator__estimate-badge">
                    <div className={estimatedDaysClassName}>
                      {estimatedDays > 62 ? '> 62' : estimatedDays}
                    </div>
                    <div className="sample-calculator__description">
                      {copyContent.durationDayLabel}
                    </div>
                  </div>
                </div>
                <div className="sample-calculator__estimate-badge-wrap">
                  <div className="sample-calculator__estimate-badge">
                    <div className="sample-calculator__number">
                      {estimatedTraffic.toLocaleString()}
                    </div>
                    <div className="sample-calculator__description-traffic">
                      {copyContent.trafficLabel}
                    </div>
                  </div>
                </div>
              </span>
              <div className="sample-calculator__foot-note">
                {copyContent.footNoteApproximation}
                <br />
                {copyContent.footNoteEstimate}
              </div>
            </ExpansionPanel>
          </div>
        </div>
      </div>
    )
  }
}

export default flowRight(gqlHOCQuery)(ExperimentCalculator)
