const initialSetUpSteps = [
  {
    label: 'plan',
    status: 'initial',
    kiteIcon: 'description',
  },
  {
    label: 'variants',
    status: 'initial',
    kiteIcon: 'dashboard',
  },
  {
    label: 'metrics',
    status: 'initial',
    kiteIcon: 'chart',
  },
  {
    label: 'tech settings',
    status: 'initial',
    kiteIcon: 'settings-f',
  },
  {
    label: 'activation',
    status: 'initial',
    kiteIcon: 'power',
  },
]

export const intialSetUpStepsCategories = {
  traditional: {
    setUpSteps: [
      {
        label: 'plan',
        status: 'initial',
        kiteIcon: 'description',
      },
      {
        label: 'variants',
        status: 'initial',
        kiteIcon: 'dashboard',
      },
      {
        label: 'metrics',
        status: 'initial',
        kiteIcon: 'chart',
      },
      {
        label: 'tech settings',
        status: 'initial',
        kiteIcon: 'settings-f',
      },
      {
        label: 'activation',
        status: 'initial',
        kiteIcon: 'power',
      },
    ],
    techApprovalSteps: ['variants', 'tech settings', 'activation'],
  },
  cms: {
    setUpSteps: [
      {
        label: 'plan',
        status: 'initial',
        kiteIcon: 'description',
      },
      {
        label: 'variants&content',
        status: 'initial',
        kiteIcon: 'dashboard',
      },
      {
        label: 'audience',
        status: 'initial',
        kiteIcon: 'person-f',
      },
      {
        label: 'metrics',
        status: 'initial',
        kiteIcon: 'chart',
      },
    ],
    techApprovalSteps: ['variants&content', 'audience'],
  },
}

export const initialContentMapSteps = {
  setUpSteps: [
    {
      label: 'Create',
      status: 'initial',
      kiteIcon: 'plus-alt',
    },
    {
      label: 'Review',
      status: 'initial',
      kiteIcon: 'dashboard',
    },
  ],
  ruleBuilderType: 'Prebuilt Audience',
}

export default initialSetUpSteps
