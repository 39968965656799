import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

/*
* Sample Input
*  {
    "experimentId": 159,
    "activationEventUuid": "ab415336422b30b007a25c526266f6edeb0886e/EXP_AE_OneApp_OVP_pageView_buyFlowLanding"
  }
*/
const UPDATE_EXPERIMENT_ACTIVATION_EVENT = gql`
  mutation updateExperimentActivationEvent($input: UpdateActivationEvent!) {
    updateExperimentActivationEvent(input: $input)
  }
`

const updateExperimentActivationEvent = graphql(
  UPDATE_EXPERIMENT_ACTIVATION_EVENT,
  {
    name: 'updateExperimentActivationEvent',
    withRef: true,
  }
)

export default updateExperimentActivationEvent
