export default {
  notifications: {
    markAllRead: 'Mark all as read',
    noNotificationPreferences:
      'You do not have any in app notification preferences yet!',
    noNotifications: 'You do not have any notifications... yet!',
    noNotificationsSubMessage:
      "Don't worry, we will let you know when one arrives 💌",
    notificationPreferencesLink: 'Set them up',
    noFilteredNotifications: 'No notifications match your filter selection',
    noFilteredNotificationsSubMessage:
      'Adjust your filters to see notifications',
    errorMarkRead:
      'We were unable to update your read notification. Please try again in a few minutes.',
  },
};
