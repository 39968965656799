import { gql } from '@apollo/client'

const GET_QUANTUM_METRIC_DEFINITIONS = gql`
  {
    metricDefinitions {
      _id
      name
      description
      calculation
      displayName
      followUpDays
    }
  }
`

export const GET_QUANTUM_METRICS = gql`
  query getMetricsByApplicationID($applicationID: ID!, $startDate: Date) {
    metricDefinitions(applicationID: $applicationID, startDate: $startDate) {
      _id
      name
      description
      calculation
      displayName
      followUpDays
      relatedMetrics {
        _id
        name
        description
        calculation
        displayName
        followUpDays
        jointMetrics {
          _id
          name
          description
          calculation
          displayName
          followUpDays
        }
      }
      jointMetrics {
        _id
        name
        description
        calculation
        displayName
        followUpDays
      }
    }
  }
`

export default GET_QUANTUM_METRIC_DEFINITIONS
