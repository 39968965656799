import React, { Component } from 'react'
import { object, func, array, bool } from 'prop-types'
import flowRight from 'lodash/flowRight'
import isEqual from 'lodash/isEqual'
import queryString from 'query-string'
import { KiteLoader, KiteAlert } from '@kite/react-kite'
import { LinearProgressStepper } from '@kite/react-kite-plus'

import {
  getSelectedEnvironment,
  hasPermission,
  permissionEnum,
  featureFlagEnum,
  overrideTypeEnum,
  experimentEnvironmentEnum,
  experimentStatusEnum,
} from '@charter/distillery-rules'

import { client } from '../../configuration/configApiClient'
import {
  getExperimentRolloutDetails,
  GET_ACTIVE_LOCKDOWNS,
} from '../../shared/queries'
import { ACKNOWLEDGE_OVERRIDES_DELETION } from '../../shared/mutations'
import { formatSetUpSteps } from '../../shared/utilities'
import { intialSetUpStepsCategories } from '../../shared/data/initialSetUpSteps'
import { ExpansionPanel, ColoredSelect } from '../../componentLibrary'
import { TdcsUpdateNotification } from '../../components'
import {
  OverridesStep,
  OverridesOldStep,
  SampleLaunchStep,
  MonitorStep,
  TdcsStatusStep,
} from './steps/index'

import copyContent from './data/copyContent'
import './RolloutExperiment.scss'
import appModel from '../../components/App/AppModel'

const { PRODUCTION } = experimentEnvironmentEnum
const { EXPERIMENT_UPDATE_RUNNING_ALL } = permissionEnum
const initialRolloutSteps = {
  traditionalExperiment: [
    {
      label: 'overrides',
      status: 'initial',
      kiteIcon: 'person-f',
    },
    {
      label: 'sample & launch',
      status: 'initial',
      kiteIcon: 'menu',
    },
    {
      label: 'tdcs status',
      status: 'initial',
      kiteIcon: 'description',
    },
    {
      label: 'monitor',
      status: 'initial',
      kiteIcon: 'chart',
    },
  ],
  cmsExperiment: [
    {
      label: 'overrides',
      status: 'initial',
      kiteIcon: 'person-f',
    },
    {
      label: 'sample & launch',
      status: 'initial',
      kiteIcon: 'menu',
    },
    {
      label: 'monitor',
      status: 'initial',
      kiteIcon: 'chart',
    },
  ],
}

export class RolloutExperiment extends Component {
  static propTypes = {
    match: object.isRequired,
    user: object.isRequired,
    hasInAppPreferences: bool.isRequired,
    history: object.isRequired,
    location: object.isRequired,
    onNavigate: func.isRequired,
    rolloutData: object.isRequired,
    applicationPermissions: array.isRequired,
  }

  state = {
    selectedEnvironment: null,
    environments: null,
    isDisabled: false,
    isOverridesDeleted: false,
    loading: {
      currentlyViewing: false,
    },
    activeStep: 'overrides',
    rolloutSteps: [],
    isNetworkLockdownActive: false,
  }

  // LIFECYCLE METHODS
  async componentDidMount() {
    const { rolloutData, networkLockdownPermission } = this.props

    if (rolloutData.experiment) {
      this.formatRolloutData()
    }

    // only check for active lockdowns if user does not have network lockdown permissions
    if (!networkLockdownPermission) {
      await this.isLockdownActive()
    }
  }

  componentDidUpdate(prevProps) {
    const { rolloutData } = this.props
    const { rolloutData: prevData } = prevProps

    if (
      (!prevData.experiment && rolloutData.experiment) ||
      !isEqual(prevData.experiment, rolloutData.experiment)
    ) {
      this.formatRolloutData()
    }
  }

  // LOCAL STATE CHANGE/TOGGLE METHODS
  handleGetSelectedEnvironment = (experiment, environments) => {
    const {
      location: { search },
    } = this.props

    if (search) {
      const values = queryString.parse(search)

      if (values.environment) {
        return environments.find(
          environment => environment.id === Number(values.environment)
        )
      }
    }

    return getSelectedEnvironment(experiment, PRODUCTION)
  }

  handleSelectEnvironment = async selectedEnvironment => {
    await this.setState({ selectedEnvironment })
    this.validateIsDisabled()
  }

  handleSetNewCurrentlyViewing = () => {
    const { onNavigate } = this.props

    const currentlyViewing = {
      backPath: '/experiments/all',
      backTitle: 'All Experiments',
      tabs: {
        currentTab: 'rollout',
        tabs: [{ label: 'set up' }, { label: 'rollout' }, { label: 'results' }],
      },
    }

    onNavigate(currentlyViewing)
  }

  handleSelectStep = nextStep => {
    const {
      history,
      match: {
        params: { id },
      },
    } = this.props

    let stepPath = nextStep

    if (nextStep === 'sample & launch') {
      stepPath = 'sample-launch'
    } else if (nextStep === 'tdcs status') {
      stepPath = 'tdcs-status'
    }

    history.replace(`/experiments/${id}/rollout/${stepPath}`)

    this.setState({ activeStep: nextStep })
  }

  handleGetEnvironmentValues = async () => {
    const {
      rolloutData: {
        experiment,
        experiment: { isOverridesDeleted },
      },
    } = this.props

    this.handleSetCurrentlyViewing(experiment)

    const environments = experiment.environmentSamplings
      .map(sampling => this.formatEnvironment(sampling))
      .sort((aEnv, bEnv) => (aEnv.order > bEnv.order ? 1 : -1))
      .map(env => ({ ...env, name: env.name.toLowerCase() }))

    const selectedEnvironment = this.handleGetSelectedEnvironment(
      experiment,
      environments
    )

    await this.setState({ environments, isOverridesDeleted })
    this.handleSelectEnvironment(selectedEnvironment)
  }

  isLockdownActive = async () => {
    const res = await client.query({
      query: GET_ACTIVE_LOCKDOWNS,
    })

    if (
      res.data &&
      res.data.activeNetworkLockdowns &&
      res.data.activeNetworkLockdowns.length
    ) {
      await this.setState({ isNetworkLockdownActive: true })
    }
  }

  // FORMATTING METHODS
  formatRolloutData = async () => {
    const {
      history,
      match: {
        params: { id, step },
      },
      rolloutData: {
        experiment: { config, isCmsExperiment },
      },
    } = this.props

    let initialSetUpSteps
    let rolloutSteps

    if (isCmsExperiment) {
      initialSetUpSteps = intialSetUpStepsCategories.cms.setUpSteps
      rolloutSteps = initialRolloutSteps.cmsExperiment
    } else {
      initialSetUpSteps = intialSetUpStepsCategories.traditional.setUpSteps
      rolloutSteps = initialRolloutSteps.traditionalExperiment
    }

    const { activeStep, isSetUpComplete } = formatSetUpSteps(
      JSON.parse(config),
      initialSetUpSteps
    )

    if (isSetUpComplete) {
      await this.handleGetEnvironmentValues()

      let stepPath = step

      if (step === 'sample-launch') {
        stepPath = 'sample & launch'
      } else if (step === 'tdcs-status') {
        stepPath = 'tdcs status'
      }

      await this.setState(({ loading }) => ({
        activeStep: stepPath,
        loading: { ...loading, currentlyViewing: false },
        rolloutSteps,
      }))
    } else {
      history.push(`/experiments/${id}/set-up/${activeStep}`)
    }
  }

  formatEnvironment = ({
    environment,
    experimentStatus,
    tdcsUpdateRequired,
    isDreSynced,
    sampleAllocation,
  }) => ({
    ...environment,
    tdcsUpdateRequired,
    isDreSynced,
    sampleAllocation,
    name: environment.name.toLowerCase(),
    status: experimentStatus.name.toLowerCase(),
    experimentStatusId: experimentStatus.id,
  })

  // VALIDATION METHODS
  validateIsDisabled = async () => {
    const { selectedEnvironment } = this.state
    const {
      applicationPermissions,
      rolloutData: {
        experiment: {
          environmentSamplings,
          permissions: experimentPermissions,
          experimentStatus,
        },
      },
    } = this.props

    // If there is a deleted experiment this still gets run,
    // the return statement prevents throwing an error
    if (!selectedEnvironment) {
      return
    }

    const { id: environmentId, name } = selectedEnvironment

    const permissionsArgs = {
      applicationPermissions,
      experimentPermissions,
      environmentSamplings,
      environmentId,
    }

    const canPublishSelectedEnvironment = hasPermission({
      ...permissionsArgs,
      permissionId: permissionEnum[`PUBLISH_${name.toUpperCase()}`],
    })

    const canUpdateRunningAll = hasPermission({
      ...permissionsArgs,
      permissionId: EXPERIMENT_UPDATE_RUNNING_ALL,
    })

    const isDisabled = canUpdateRunningAll
      ? experimentStatus.id === experimentStatusEnum.COMPLETED ||
        experimentStatus.id === experimentStatusEnum.CANCELLED ||
        experimentStatus.id === experimentStatusEnum.OBSERVATIONAL
      : !selectedEnvironment ||
        !canPublishSelectedEnvironment ||
        experimentStatus.id === experimentStatusEnum.COMPLETED ||
        experimentStatus.id === experimentStatusEnum.CANCELLED

    await this.setState({ isDisabled })
  }

  // REF METHODS
  handleSetCurrentlyViewing = ({
    name,
    experimentStatus,
    experimentToProductFeatures,
  }) => {
    const {
      onNavigate,
      match: {
        params: { id },
      },
      history,
    } = this.props

    const { product, productFeature } = experimentToProductFeatures[0]

    const status = experimentStatus.name.toLowerCase()
    const basePath = `/experiments/${id}`
    const path = `${basePath}/rollout/overrides`

    let currentlyViewing = {
      path,
      title: name,
      subTitle: `${product.displayName} - ${productFeature.displayName}`,
      backPath: `/experiments/${status}`,
      backTitle: `${status} Experiments`,
      tabs: {
        currentTab: 'rollout',
        tabs: [
          { label: 'set up', path: `${basePath}/set-up/plan` },
          { label: 'rollout', path: `${basePath}/rollout/overrides` },
          { label: 'results', path: `${basePath}/results` },
        ],
      },
    }

    if (status === 'deleted') {
      currentlyViewing = {
        path: '/experiments/all',
        backPath: path,
        backTitle: name,
      }

      history.push('/experiments/all')
    }

    onNavigate(currentlyViewing)
  }

  handleSubmit = async dataSet => {
    const { activeStep } = this.state

    if (activeStep === 'sample & launch') {
      const environments = dataSet.environmentSamplings.map(sampling =>
        this.formatEnvironment(sampling)
      )

      this.setState(({ selectedEnvironment }) => ({
        environments,
        selectedEnvironment: environments.find(
          environment => environment.id === selectedEnvironment.id
        ),
      }))
    }
  }

  handleUpdateOverrideBanner = async isOverridesDeleted => {
    const {
      match: {
        params: { id },
      },
    } = this.props
    if (!isOverridesDeleted) {
      await client.mutate({
        mutation: ACKNOWLEDGE_OVERRIDES_DELETION,
        variables: {
          experimentId: Number(id),
        },
      })
    }
    await this.setState({ isOverridesDeleted })
  }

  render() {
    const {
      activeStep,
      rolloutSteps,
      selectedEnvironment,
      environments,
      isDisabled,
      loading: { currentlyViewing },
      isOverridesDeleted,
      isNetworkLockdownActive,
    } = this.state

    const {
      user,
      hasInAppPreferences,
      onNavigate,
      match: {
        params: { id },
      },
      history,
      applicationPermissions,
      rolloutData: {
        loading,
        TDCSExperiment,
        TdcsExperimentSettings,
        experiment,
        dremExperimentSettings,
      },
    } = this.props

    const { overrideNotice } = copyContent

    if (loading || currentlyViewing) {
      return (
        <div className="app__loader">
          <KiteLoader size="7rem" />
        </div>
      )
    }

    let tdcsSessionType

    if (TDCSExperiment && !experiment.isCmsExperiment) {
      tdcsSessionType = TDCSExperiment.tdcsInformCall
    } else if (!TDCSExperiment && !experiment.isCmsExperiment) {
      tdcsSessionType = TdcsExperimentSettings.tdcsSessionType
    } else if (dremExperimentSettings) {
      tdcsSessionType = dremExperimentSettings.sessionType
    }

    const {
      name: experimentName,
      isCmsExperiment,
      experimentToProductFeatures,
      environmentSamplings,
      permissions: experimentPermissions,
      experimentStatus: { name: statusName },
    } = experiment

    const overrideTypeId = Object.keys(overrideTypeEnum).find(
      key => overrideTypeEnum[key] === tdcsSessionType.overrideTypeId
    )

    const { productId } = experimentToProductFeatures[0]
    const TheOverridesStep = appModel.hasFeatureFlag(featureFlagEnum.ORM_2)
      ? OverridesStep
      : OverridesOldStep

    //feature flag PRISM_DEEPLINK
    const prismDeeplinkActive = appModel.hasFeatureFlag(16)

    return (
      <div className="rollout-experiment">
        <h4>Quality Assurance & Publishing</h4>
        <LinearProgressStepper
          className="rollout-experiment__stepper"
          activeStep={activeStep}
          steps={rolloutSteps}
          onClick={this.handleSelectStep}
          leanLeft
        />

        <TdcsUpdateNotification
          id={id}
          environmentSampling={environmentSamplings}
          history={history}
          isCmsExperiment={isCmsExperiment}
          linkHidden
          activeNetworkLockdown={!isCmsExperiment && isNetworkLockdownActive}
        />

        <ExpansionPanel
          type="minimal"
          isExpanded={
            isOverridesDeleted &&
            (activeStep === 'overrides' || activeStep === 'sample & launch')
          }
        >
          <KiteAlert
            className="app__page-level-message"
            type="caution"
            description={overrideNotice}
            onClose={() => this.handleUpdateOverrideBanner(false)}
          />
        </ExpansionPanel>

        {(activeStep === 'sample & launch' ||
          activeStep === 'tdcs status' ||
          activeStep === 'monitor') && (
          <div className="rollout-experiment__environment-select-container">
            {!isCmsExperiment && (
              <div className="rollout-experiment__environment-wrapper">
                <h5 className="rollout-experiment__environment-label">
                  Environment:
                </h5>

                <ColoredSelect
                  className="rollout-experiment__environment-select"
                  value={selectedEnvironment.name}
                  options={environments}
                  onSelect={this.handleSelectEnvironment}
                />
              </div>
            )}

            <span className="rollout-experiment__environment-status">
              <span className="tag">Status:</span>
              <span className={`status ${selectedEnvironment.status}`}>
                {`\xa0${selectedEnvironment.status}`}
              </span>
            </span>
          </div>
        )}

        {selectedEnvironment && activeStep === 'overrides' && (
          <TheOverridesStep
            id={Number(id)}
            user={user}
            history={history}
            disabled={isDisabled}
            name={experimentName}
            status={statusName}
            productId={productId}
            overrideTypeId={overrideTypeId}
            applicationPermissions={applicationPermissions}
            experimentPermissions={experimentPermissions}
            environmentSamplings={environmentSamplings}
            selectedEnvironment={selectedEnvironment}
            handleUpdateOverrideBanner={this.handleUpdateOverrideBanner}
            activeNetworkLockdown={!isCmsExperiment && isNetworkLockdownActive}
          />
        )}

        {selectedEnvironment && activeStep === 'sample & launch' && (
          <SampleLaunchStep
            key={`sampleLaunch-${selectedEnvironment.id}`}
            id={Number(id)}
            user={user}
            hasInAppPreferences={hasInAppPreferences}
            history={history}
            disabled={isDisabled}
            selectedEnvironment={selectedEnvironment}
            onSubmit={this.handleSubmit}
            onNavigate={onNavigate}
            applicationPermissions={applicationPermissions}
            experimentPermissions={experimentPermissions}
            environmentSamplings={environmentSamplings}
            tdcsSessionType={tdcsSessionType}
            activeNetworkLockdown={!isCmsExperiment && isNetworkLockdownActive}
          />
        )}

        {!isCmsExperiment &&
          selectedEnvironment &&
          activeStep === 'tdcs status' && (
            <TdcsStatusStep
              key={`tdcsStatus-${selectedEnvironment.id}`}
              id={Number(id)}
              history={history}
              experiment={experiment}
              selectedEnvironment={selectedEnvironment}
            />
          )}

        {selectedEnvironment && activeStep === 'monitor' && (
          <MonitorStep
            id={Number(id)}
            history={history}
            experiment={experiment}
            selectedEnvironment={selectedEnvironment}
            applicationPermissions={applicationPermissions}
            tdcsSessionType={tdcsSessionType}
            environmentSamplings={environmentSamplings}
            prismDeeplinkActive={prismDeeplinkActive}
          />
        )}
      </div>
    )
  }
}

export default flowRight(getExperimentRolloutDetails)(RolloutExperiment)
