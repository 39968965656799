import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

/*
 * Sample Input
 *
 * {"input": {"experimentId":  1}}
 */

const RESET_EXPERIMENT = gql`
  mutation resetExperiment($input: ResetExperimentInput!) {
    resetExperiment(input: $input) {
      id
      startTime
      durationNumDays
      variants {
        id
        name
        displayOrder
      }
      environmentSamplings {
        id
        tdcsUpdateRequired
        isDreSynced
        sampleAllocation
        environment {
          id
          name
          color
        }
        experimentStatus {
          id
          name
        }
        lastModifiedTime
        lastModifiedUser {
          id
          displayName
        }
        environmentSamplingToVariants {
          id
          weight
          variant {
            id
            name
          }
          lastModifiedTime
          lastModifiedUser {
            id
            displayName
          }
        }
        allocationHistory {
          id
          isReset
          uuid
          sampleAllocation
          variantConfig
          createdTime
          lastModifiedUser {
            id
            displayName
          }
        }
      }
    }
  }
`

const resetExperiment = graphql(RESET_EXPERIMENT, {
  name: 'resetExperiment',
})

export default resetExperiment
