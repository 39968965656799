import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

/*
* Sample Input
*
* {
  experimentId: Int!,
  variants: [
    {
      id: Int!
      jsonPayload: String!
    }
  ]
}
*/

const UPDATE_VARIANT_JSON_PAYLOAD = gql`
  mutation updateVariantJsonPayload($input: ExperimentToVariantUpdate!) {
    updateExperimentVariants(input: $input) {
      id
      variants {
        id
        jsonPayload
      }
    }
  }
`

const updateVariantJsonPayload = graphql(UPDATE_VARIANT_JSON_PAYLOAD, {
  name: 'updateVariantJsonPayload',
  withRef: true,
})

export default updateVariantJsonPayload
