import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'

import {
  KiteInput,
  KiteCheckbox,
  KiteButton,
  KiteLoader,
} from '@kite/react-kite'
import { TextArea } from '@kite/react-kite-plus'
import { Modal } from '../../../../componentLibrary'

import { GET_SCHEDULED_LOCKDOWNS } from '../../../../shared/queries/index'
import { INSERT_LOCKDOWN } from '../mutations/insertNetworkLockdown'
import { UPDATE_NETWORK_LOCKDOWN } from '../mutations/updateNetworkLockdown'
import { CANCEL_LOCKDOWN } from '../mutations/cancelNetworkLockdown'

import './ManageNetworkLockdown.scss'

import copyContent from '../data/copyContent'
import { isEmpty } from 'lodash'

import {
  getCurrentDate,
  getCurrentTime,
  formatDateTime,
  formatDate,
  formatTime,
  formatTimeTo12Hour,
} from '../../../../shared/utilities/index'

const initialErrorMessage = {
  startDate: null,
  startTime: null,
  endDate: null,
  endDateNeeded: null,
  endTime: null,
  endTimeNeeded: null,
  submit: null,
  getLockdown: null,
}

export function ManageNetworkLockdown() {
  const [isLockdownScheduled, setIsLockdownScheduled] = useState(false)
  const [isImmediate, setIsImmediate] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [startTime, setStartTime] = useState('')
  const [endDate, setEndDate] = useState('')
  const [endTime, setEndTime] = useState('')
  const [preLockdownMessage, setPreLockdownMessage] = useState(
    copyContent.messagesDefaultInput.preMessage
  )
  const [duringLockdownMessage, setDuringLockdownMessage] = useState(
    copyContent.messagesDefaultInput.duringMessage
  )
  const [errorMessage, setErrorMessage] = useState(initialErrorMessage)
  const [modalOpen, setModalOpen] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const [isLockdownActive, setIsLockdownActive] = useState(false)

  const messageTemplates = {
    preMessage: `A lockdown is scheduled to occur from ${
      startDate ? startDate : '[start date]'
    } at ${startTime ? formatTimeTo12Hour(startTime) : '[start time]'}. `,
  }

  const { loading, error, data, refetch } = useQuery(GET_SCHEDULED_LOCKDOWNS)

  const [insertLockdown, { loading: insertLockdownLoading }] = useMutation(
    INSERT_LOCKDOWN
  )

  const [
    updateLockdown,
    { updateLoading: updateLockdownLoading },
  ] = useMutation(UPDATE_NETWORK_LOCKDOWN)

  const [
    cancelLockdown,
    { deleteLoading: deleteLockdownLoading },
  ] = useMutation(CANCEL_LOCKDOWN)

  useEffect(() => {
    if (
      data &&
      data.scheduledNetworkLockdowns &&
      data.scheduledNetworkLockdowns.length
    ) {
      const upcomingLockdown = data.scheduledNetworkLockdowns[0]
      setIsLockdownScheduled(true)
      setStartDate(formatDate(upcomingLockdown.startDate))
      setStartTime(formatTime(upcomingLockdown.startDate))
      if (upcomingLockdown.isImmediate) {
        setIsImmediate(true)
      } else if (upcomingLockdown.endDate) {
        setEndDate(formatDate(upcomingLockdown.endDate))
        setEndTime(formatTime(upcomingLockdown.endDate))
      }
    }

    if (error) {
      setErrorMessage(oldErrs => ({
        ...oldErrs,
        getLockdown: error,
      }))
    }
  }, [data, error])

  useEffect(() => {
    const startDateObject = new Date(startDate + ' ' + startTime)
    const currentDateObject = new Date(
      getCurrentDate() + ' ' + getCurrentTime()
    )
    if (startDateObject <= currentDateObject) {
      setIsLockdownActive(true)
    } else {
      setIsLockdownActive(false)
    }
  }, [startDate, startTime])

  const immediateCheckedHandler = () => {
    if (!isImmediate) {
      setStartDate(getCurrentDate())
      setStartTime(getCurrentTime())
      setEndDate('')
      setEndTime('')
    }
    setIsImmediate(prevIsImmediate => !prevIsImmediate)
    setIsDirty(true)
  }

  const onChange = ({ target: { name, value } }) => {
    setIsDirty(true)
    setErrorMessage(initialErrorMessage)
    if (name === 'startDate') {
      setStartDate(value)
    } else if (name === 'startTime') {
      setStartTime(value)
    } else if (name === 'endDate') {
      setEndDate(value)
    } else if (name === 'endTime') {
      setEndTime(value)
    } else if (name === 'preMessage') {
      setPreLockdownMessage(value)
    } else if (name === 'duringMessage') {
      setDuringLockdownMessage(value)
    }
  }

  const handleSaveLockdown = async () => {
    let startDateTime
    if (startDate && startTime) {
      startDateTime = formatDateTime(startDate, startTime)
    }

    let endDateTime
    if (endDate && endTime) {
      endDateTime = formatDateTime(endDate, endTime)
    }

    let preMessage
    if (isImmediate) {
      preMessage = ''
    } else {
      preMessage = messageTemplates.preMessage + preLockdownMessage
    }

    const input = {
      startDate: startDateTime,
      endDate: endDateTime,
      isImmediate,
      preMessage,
      duringMessage: duringLockdownMessage,
    }

    try {
      await insertLockdown({ variables: { input } })
      setIsLockdownScheduled(true)
      setIsDirty(false)
      await refetch()
    } catch (err) {
      setErrorMessage(oldErr => ({
        ...oldErr,
        submit: err,
      }))
    }
  }

  const handleUpdate = async () => {
    // if lockdown is updated to end now
    if (endDate && endTime) {
      const endDateTime = new Date(endDate + ' ' + endTime)
      const currentDateTime = new Date(
        getCurrentDate() + ' ' + getCurrentTime()
      )

      if (endDateTime <= currentDateTime) {
        handleEndLockdownNow()
        return
      }
    }

    const {
      id,
      startDate: oldStartDate,
      endDate: oldEndDate,
      isImmediate: oldIsImmediate,
      preMessage: oldPreMessage,
      duringMessage: oldDuringMessage,
    } = data.scheduledNetworkLockdowns[0]

    const input = {}

    if (oldStartDate !== formatDateTime(startDate, startTime)) {
      input.startDate = formatDateTime(startDate, startTime)
    }

    if (endDate && endTime && oldEndDate !== formatDateTime(endDate, endTime)) {
      input.endDate = formatDateTime(endDate, endTime)
    }

    if (oldIsImmediate !== isImmediate) {
      input.isImmediate = isImmediate
    }

    if (oldPreMessage !== preLockdownMessage) {
      let preMessage
      if (isImmediate) {
        preMessage = ''
      } else {
        preMessage = messageTemplates.preMessage + preLockdownMessage
      }
      input.preMessage = preMessage
    }

    if (oldDuringMessage !== duringLockdownMessage) {
      input.duringMessage = duringLockdownMessage
    }

    if (Object.keys(input).length) {
      try {
        await updateLockdown({
          variables: {
            input,
            id: Number(id),
          },
        })
        setIsDirty(false)
        await refetch()
      } catch (err) {
        setErrorMessage(oldErr => ({
          ...oldErr,
          submit: err,
        }))
      }
    }
  }

  const handleEndLockdownNow = async () => {
    const id = data.scheduledNetworkLockdowns[0].id
    const input = {
      endDate: formatDateTime(getCurrentDate(), getCurrentTime()),
    }

    try {
      await updateLockdown({
        variables: {
          input,
          id: Number(id),
        },
      })
      handleResetForm()
      setIsLockdownScheduled(false)
      setIsDirty(false)
      await refetch()
    } catch (err) {
      setErrorMessage(oldErr => ({
        ...oldErr,
        submit: err,
      }))
    }
  }

  const handleCancelLockdown = async () => {
    const id = data.scheduledNetworkLockdowns[0].id

    try {
      await cancelLockdown({
        variables: {
          id: Number(id),
        },
      })
      handleResetForm()
      setIsLockdownScheduled(false)
      setIsDirty(false)
      await refetch()
    } catch (err) {
      setErrorMessage(oldErr => ({
        ...oldErr,
        submit: err,
      }))
    }
  }

  const validateSubmission = () => {
    const errors = {}

    const startDateTime = new Date(startDate + ' ' + startTime)
    let endDateTime
    if (!isImmediate && endDate && endTime) {
      endDateTime = new Date(endDate + ' ' + endTime)
    }

    // check that there is a start date
    if (!startDate) {
      errors.startDate = copyContent.errors.startDate
    }

    // and start time
    if (!startTime) {
      errors.startTime = copyContent.errors.startTime
    }

    // check that there is both a date and time for end date
    if (endDate && !endTime) {
      errors.endTimeNeeded = copyContent.errors.endDateNeeded
    }

    if (!endDate && endTime) {
      errors.endDateNeeded = copyContent.errors.endDateNeeded
    }

    // check if end date is after start date
    if (!isImmediate && endDateTime <= startDateTime) {
      errors.endDate = copyContent.errors.endDate
      errors.endTime = copyContent.errors.endTime
    }

    if (!isEmpty(errors)) {
      setErrorMessage(oldErrs => ({
        ...oldErrs,
        ...errors,
      }))
      return false
    }
    setErrorMessage(initialErrorMessage)
    return true
  }

  const handleResetForm = () => {
    setStartTime('')
    setStartDate('')
    setEndDate('')
    setEndTime('')
    setPreLockdownMessage(copyContent.messagesDefaultInput.preMessage)
    setDuringLockdownMessage(copyContent.messagesDefaultInput.duringMessage)
    setIsImmediate(false)
    setIsDirty(false)
  }

  const isLoading =
    insertLockdownLoading || updateLockdownLoading || deleteLockdownLoading

  if (loading) {
    return (
      <div className="app__loader">
        <KiteLoader size="7rem" />
      </div>
    )
  }

  if (error) {
    return <div>Error has occurred: {error.message}</div>
  }

  return (
    <div className="lockdown-card">
      {!isLockdownScheduled && (
        <p className="kite-copy lockdown-schedule-text__not-scheduled">
          {copyContent.headerText.notScheduled}
        </p>
      )}
      {isLockdownScheduled && (
        <p className="kite-copy lockdown-schedule-text__scheduled">
          {copyContent.headerText.scheduled}
        </p>
      )}
      <hr className="divider"></hr>
      <h4 className="kite-h4 lockdown-details-header">Lockdown Details</h4>
      <div className="immediate-checkbox">
        <KiteCheckbox
          id="immediateLockdown"
          label="Immediate, indefinite lockdown"
          name="immediateLockdown"
          onChange={immediateCheckedHandler}
          checked={isImmediate}
        />
      </div>
      <div className="date-time-inputs">
        <div className="date-time-inputs__start">
          <KiteInput
            errorMessage={errorMessage.startDate}
            className="date-time-inputs__start-date"
            name="startDate"
            type="date"
            label="Lockdown Start Date"
            value={startDate}
            onChange={onChange}
            maxWidth="15rem"
            inputProps={{ min: getCurrentDate() }}
          />
          <KiteInput
            errorMessage={errorMessage.startTime}
            name="startTime"
            type="time"
            label="Start Time (MT)"
            value={startTime}
            onChange={onChange}
            maxWidth="10rem"
          />
        </div>
        {!isImmediate && (
          <div className="date-time-inputs__end">
            <KiteInput
              errorMessage={errorMessage.endDate || errorMessage.endDateNeeded}
              className="date-time-inputs__end-date"
              name="endDate"
              type="date"
              label="Lockdown End Date"
              value={endDate}
              onChange={onChange}
              maxWidth="15rem"
              inputProps={{ min: getCurrentDate() }}
            />
            <KiteInput
              errorMessage={errorMessage.endTime || errorMessage.endTimeNeeded}
              className="date-time-inputs__end-time"
              maxWidth="10rem"
              name="endTime"
              type="time"
              label="End Time (MT)"
              value={endTime}
              onChange={onChange}
            />
          </div>
        )}
        {isImmediate && isLockdownScheduled && (
          <div className="date-time-inputs__end-lockdown">
            {' '}
            <KiteButton
              type="outline"
              leftIcon="x-alt"
              className="date-time-inputs__end-lockdown-btn"
              onClick={handleEndLockdownNow}
            >
              End Lockdown Now
            </KiteButton>{' '}
          </div>
        )}
      </div>
      {!isImmediate && (
        <div>
          Pre-Lockdown Message:
          <TextArea
            name="preMessage"
            label={messageTemplates.preMessage}
            value={preLockdownMessage}
            height="5rem"
            maxLength="400"
            showCounter="true"
            className="lockdown-messages"
            onChange={onChange}
          />
        </div>
      )}
      <TextArea
        name="duringMessage"
        label="During Lockdown Message"
        value={duringLockdownMessage}
        height="5rem"
        maxLength="500"
        showCounter="true"
        className="lockdown-messages"
        onChange={onChange}
      />
      <div className="save-cancel-buttons">
        <div className="save-cancel-buttons__save-cancel">
          <KiteButton
            className="save-cancel-buttons__save"
            disabled={isLoading || !isDirty}
            onClick={() => {
              if (!isLockdownScheduled && validateSubmission()) {
                handleSaveLockdown()
              } else if (validateSubmission()) {
                handleUpdate()
              }
            }}
          >
            Save
          </KiteButton>
          <KiteButton
            className="save-cancel-buttons__cancel-changes"
            size="medium"
            type="standalone-link"
            onClick={handleResetForm}
          >
            Reset
          </KiteButton>
        </div>
        <KiteButton
          type="outline"
          leftIcon="x-alt"
          className="save-cancel-buttons__cancel-lockdown"
          disabled={!isLockdownScheduled || isLockdownActive}
          onClick={() => setModalOpen(true)}
        >
          Cancel Lockdown
        </KiteButton>
      </div>

      {modalOpen && (
        <Modal
          className="cancel-modal"
          message={copyContent.modalContent}
          onConfirm={() => {
            setModalOpen(false)
            handleCancelLockdown()
          }}
          onDeny={() => setModalOpen(false)}
          buttonProps={{
            confirm: {
              value: 'Cancel Lockdown',
              type: 'warning-outline',
            },
            deny: { type: 'outline' },
          }}
        />
      )}
    </div>
  )
}

export default ManageNetworkLockdown
