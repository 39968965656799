import React, { Component, Fragment } from 'react'
import isEqual from 'lodash/isEqual'
import classNames from 'classnames'
import { object, func, bool, string } from 'prop-types'
import { KiteIcon } from '@kite/react-kite'

import ExpansionPanel from '../ExpansionPanel/ExpansionPanel'
import './SideNavigation.scss'

const getPreSelection = (
  options,
  currentlyViewing = { path: '/' },
  optionsType = 'options'
) => {
  const locationArray = currentlyViewing.path.split('/')
  let preSelection
  // Verify type of option
  if (optionsType === 'subOptions') {
    // Grab the parent object
    const parentKey = Object.keys(options).find(optionKey => {
      const match = locationArray.find(path => optionKey === path)

      if (match) {
        return true
      }
      return false
    })

    // Verify there is a parent object
    if (parentKey) {
      // Grab the sub option
      preSelection = locationArray.find(path => {
        const match = options[parentKey].subOptions.find(
          ({ name }) => name === path
        )

        if (match) {
          return match
        }
        return false
      })
    } else {
      preSelection = false
    }
  } else {
    preSelection = locationArray.find(path => {
      const match = options.find(subOption => subOption === path)

      if (match) {
        return match
      }
      return false
    })
  }

  return preSelection || null
}

const getDefaultState = props => {
  const { currentlyViewing, menuOptions, defaultSelected, isCollapsed } = props

  if (isCollapsed) {
    return {
      isCollapsed,
      selectedOption: null,
      selectedSubOption: currentlyViewing
        ? getPreSelection(menuOptions, currentlyViewing, 'subOptions')
        : defaultSelected.option,
    }
  }

  return {
    isCollapsed,
    selectedSubOption: currentlyViewing
      ? getPreSelection(menuOptions, currentlyViewing, 'subOptions')
      : defaultSelected.subOption,
    selectedOption: currentlyViewing
      ? getPreSelection(menuOptions, currentlyViewing, 'subOptions')
      : defaultSelected.option,
  }
}

class SideNavigation extends Component {
  static propTypes = {
    menuOptions: object.isRequired,
    onGoBack: func.isRequired,
    onCollapse: func,
    currentlyViewing: object,
    showBackButton: bool,
    onNavigate: func,
    logoAssetPath: string,
    logoTitle: string,
  }

  static defaultProps = {
    isCollapsed: false,
    onCollapse: null,
    currentlyViewing: null,
    showBackButton: false,
    onNavigate: null,
    logoAssetPath: null,
    logoTitle: null,
  }

  state = getDefaultState(this.props)

  componentDidMount() {
    const { currentlyViewing, menuOptions } = this.props

    if (currentlyViewing) {
      const newOption = getPreSelection(
        Object.keys(menuOptions),
        currentlyViewing
      )
      const newSubOption = getPreSelection(
        menuOptions,
        currentlyViewing,
        'subOptions'
      )

      this.handleUpdateSelection(newOption, newSubOption)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentlyViewing: prevViewing } = prevProps
    const { currentlyViewing, menuOptions } = this.props

    if (!isEqual(prevViewing, currentlyViewing)) {
      const {
        selectedOption: prevOption,
        selectedSubOption: prevSubOption,
      } = prevState

      const newOption = getPreSelection(
        Object.keys(menuOptions),
        currentlyViewing
      )
      const newSubOption = getPreSelection(
        menuOptions,
        currentlyViewing,
        'subOptions'
      )

      if (newOption !== prevOption || newSubOption !== prevSubOption) {
        this.handleUpdateSelection(newOption, newSubOption)
      }
    }
  }

  // LOCAL STATE CHANGE/TOGGLE METHODS
  handleUpdateSelection = (newOption, newSubOption) => {
    const { isCollapsed } = this.state

    this.setState(({ selectedSubOption: prevSubOption }) => ({
      selectedOption: isCollapsed ? null : newOption,
      selectedSubOption:
        !newSubOption && newOption ? prevSubOption : newSubOption,
    }))
  }

  handleToggleCollapse = () => {
    const { menuOptions, onCollapse } = this.props
    const { selectedSubOption, isCollapsed } = this.state
    const selectedOption = Object.keys(menuOptions).find(option =>
      menuOptions[option].subOptions.find(
        ({ name }) => name === selectedSubOption
      )
    )

    this.setState(
      prevState => ({
        isCollapsed: !prevState.isCollapsed,
        selectedOption: !prevState.isCollapsed ? null : selectedOption,
      }),
      () => {
        if (onCollapse) {
          onCollapse(!isCollapsed)
        }
      }
    )
  }

  handleSelectOption = async option => {
    const { isCollapsed, selectedOption } = this.state
    const { onNavigate, menuOptions } = this.props

    let newViewing

    if (
      !isCollapsed &&
      selectedOption === option &&
      menuOptions[selectedOption].subOptions.length > 0
    ) {
      newViewing = null
    } else {
      newViewing = option
    }

    await this.setState({
      selectedOption: newViewing,
      selectedSubOption: newViewing,
    })

    if (menuOptions[newViewing]?.onNavigate) {
      onNavigate({
        path: menuOptions[newViewing].onNavigate.path,
        title: menuOptions[newViewing].onNavigate.title,
      })
    }
  }

  handleSelectSubOption = (subOption, parentOption) => {
    const { isCollapsed } = this.state
    const { onNavigate } = this.props

    if (onNavigate) {
      onNavigate({
        path: `/${parentOption}/${subOption.toLowerCase()}`,
        title: this.formatSelectedTitle(parentOption, subOption),
      })
    }

    this.setState({
      selectedSubOption: subOption,
      selectedOption: isCollapsed ? null : parentOption,
    })
  }

  handleGoBack = () => {
    const { onGoBack, currentlyViewing } = this.props

    if (onGoBack) {
      onGoBack({
        path: currentlyViewing.backPath,
        title: currentlyViewing.backTitle,
      })
    }
  }

  // FORMATTING METHODS
  formatSelectedTitle = (option, subOption) => {
    const { menuOptions } = this.props
    const { titleType } = menuOptions[option]

    let title

    switch (titleType) {
      case 'option':
        title = option
        break
      case 'subOption':
        title = subOption
        break
      case 'option subOption':
        title = `${option} ${subOption}`
        break
      case 'subOption option':
        title = `${subOption} ${option}`
        break
      default:
        title = option
        break
    }

    return title.replace('-', ' ')
  }

  // RENDER METHODS
  renderMenuOptions = () => {
    const { isCollapsed, selectedSubOption, selectedOption } = this.state
    const { menuOptions } = this.props

    return (
      <Fragment>
        {Object.keys(menuOptions).map((option, index) => {
          const key = `${option}__${index}`
          let isOptionSelected
          const { hasAccess } = menuOptions[option]
          if (menuOptions[option].subOptions.length) {
            menuOptions[option].subOptions.forEach(({ name }) => {
              if (name === selectedSubOption && selectedOption === option) {
                isOptionSelected = name
              }
            })
          } else if (option === selectedOption) {
            isOptionSelected = option
          }

          const optionMenuClassNames = classNames({
            'side-navigation__option-menu': true,
            [`side-navigation__option-menu-${option}`]: true,
            'side-navigation__option-menu-selected': isOptionSelected,
            'side-navigation__option-menu-collapsed': isCollapsed,
          })

          const optionTitleClassNames = classNames({
            'side-navigation__option-title': true,
            [`side-navigation__option-title-${option}`]: true,
            'side-navigation__option-title-selected': isOptionSelected,
            'side-navigation__option-title-hidden': isCollapsed,
          })

          const optionIconClassNames = classNames({
            'material-icons': true,
            'side-navigation__option-icon': true,
            [`side-navigation__option-icon-${option}`]: true,
            'side-navigation__option-icon-selected': isOptionSelected,
            'side-navigation__option-icon-hidden': isCollapsed,
          })

          const optionHoverTitleClassNames = classNames({
            'side-navigation__option-hover-title': true,
            'side-navigation__option-hover-title-selected': isOptionSelected,
          })

          const optionExpansionPanelClassNames = classNames({
            'side-navigation__option-expansion-panel': true,
            [`side-navigation__option-expansion-panel-${option}`]: true,
          })

          if (hasAccess) {
            return (
              <div
                key={key}
                className={optionMenuClassNames}
                onMouseEnter={() => {
                  if (isCollapsed && menuOptions[option].subOptions.length) {
                    this.handleSelectOption(option)
                  }
                }}
              >
                <div
                  role="listbox"
                  tabIndex={index}
                  className={optionTitleClassNames}
                  onClick={() => {
                    if (!isCollapsed || (isCollapsed && !menuOptions[option].subOptions.length)) {
                      this.handleSelectOption(option)
                    }
                  }}
                  onKeyDown={() => {
                    if (!isCollapsed && !isOptionSelected) {
                      this.handleSelectOption(option)
                    }
                  }}
                >
                  {menuOptions[option].kiteIcon ? (
                    <KiteIcon
                      className={optionIconClassNames}
                      size="1rem"
                      margin="0"
                      name={menuOptions[option].kiteIcon}
                    />
                  ) : (
                    <i className={optionIconClassNames}>
                      {menuOptions[option].icon}
                    </i>
                  )}
                  {option.replace('-', ' ')}
                </div>
                {isCollapsed && selectedOption === option && (
                  <div
                    className="side-navigation__option-hover-menu"
                    onMouseLeave={() => {
                      if (isCollapsed && menuOptions[option].subOptions.length) {
                        this.handleSelectOption(null)
                      }
                    }}
                  >
                    <div className={optionHoverTitleClassNames}>
                      {option.replace('-', ' ')}
                    </div>
                    {menuOptions[option].subOptions
                      ? this.renderSubOptions(
                          menuOptions[option].subOptions,
                          option
                        )
                      : ''}
                  </div>
                )}
                {menuOptions[option].subOptions.length ? (
                  <ExpansionPanel
                    className={optionExpansionPanelClassNames}
                    type="minimal"
                    isExpanded={
                      isCollapsed
                        ? false
                        : (isOptionSelected && true) ||
                          selectedOption === option
                    }
                  >
                    {this.renderSubOptions(
                      menuOptions[option].subOptions,
                      option
                    )}
                  </ExpansionPanel>
                ) : (
                  ''
                )}
              </div>
            )
          }
          return ''
        })}
      </Fragment>
    )
  }

  renderSubOptions = (subOptions, parentOption) => {
    const { selectedSubOption, isCollapsed } = this.state

    return subOptions.map(({ name, label, hasAccess }, subIndex) => {
      const key = `${name}__${subIndex}`
      const isSelected = name === selectedSubOption
      const subOptionClassNames = classNames({
        'side-navigation__sub-option': true,
        [`side-navigation__sub-option-${name}`]: true,
        'side-navigation__sub-option-selected': isSelected,
        'side-navigation__sub-option-hover': isCollapsed,
      })

      if (hasAccess) {
        return (
          <div
            key={key}
            role="option"
            tabIndex={subIndex}
            aria-selected={isSelected}
            className={subOptionClassNames}
            onClick={() => this.handleSelectSubOption(name, parentOption)}
            onKeyDown={() => this.handleSelectSubOption(name, parentOption)}
          >
            <div
              className={`side-navigation__sub-option-text ${
                isSelected ? 'selected' : ''
              }`}
            >
              {label}
            </div>
          </div>
        )
      }
      return ''
    })
  }

  render() {
    const { isCollapsed } = this.state
    const { showBackButton, logoAssetPath, logoTitle } = this.props

    const logoWrapperClassNames = classNames({
      'side-navigation__logo-wrapper': true,
      'side-navigation__logo-wrapper-home': !showBackButton,
      'side-navigation__logo-wrapper-away': showBackButton,
      'side-navigation__logo-wrapper-collapsed-away':
        isCollapsed && showBackButton,
    })

    const backIconClassNames = classNames({
      'material-icons': true,
      'side-navigation__logo-back': true,
      'side-navigation__logo-back-hidden': !showBackButton,
      'side-navigation__logo-back-collapsed-away':
        isCollapsed && showBackButton,
    })

    const collapseIconClassNames = classNames({
      'material-icons': true,
      'side-navigation__collapse-icon': true,
      'side-navigation__collapse-icon-collapsed': isCollapsed,
    })

    return (
      <div className={`side-navigation ${isCollapsed ? 'collapsed' : ''}`}>
        <div className="side-navigation__header">
          <div
            role="link"
            tabIndex="-1"
            className="side-navigation__logo-link"
            onClick={() => showBackButton && this.handleGoBack()}
            onKeyDown={() => showBackButton && this.handleGoBack()}
          >
            <i className={backIconClassNames}>keyboard_arrow_left</i>

            <div className={logoWrapperClassNames}>
              {logoAssetPath && (
                <img
                  className="side-navigation__logo-image"
                  alt="distillery-logo"
                  src={`${process.env.PUBLIC_URL}${logoAssetPath}`}
                />
              )}
              {logoTitle && (
                <span
                  className={`side-navigation__logo-text ${
                    isCollapsed ? 'hidden-text' : ''
                  }`}
                >
                  {logoTitle}
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="side-navigation__menu">{this.renderMenuOptions()}</div>

        <div
          role="switch"
          tabIndex="-2"
          aria-checked={isCollapsed}
          className={`side-navigation__collapse-toggle ${
            isCollapsed ? 'collapsed' : ''
          }`}
          onClick={this.handleToggleCollapse}
          onKeyDown={this.handleToggleCollapse}
        >
          <i className={collapseIconClassNames}>arrow_back_ios</i>
          <span
            className={`side-navigation__collapse-text ${
              isCollapsed ? 'hidden-text' : ''
            }`}
          >
            Collapse
          </span>
        </div>
      </div>
    )
  }
}

export default SideNavigation
