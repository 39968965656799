export default {
  backToExperiment: 'Back to my experiment',
  headerGroup: 'Groups',
  headerID: 'Account/Device ID',
  filterSearchLabel: 'Search for overrides',
  filterSearchPlaceholderGroup: 'Search by group',
  filterSearchPlaceholderID: 'Search by ID or name',
  errorFilterSearch: (type, searchValue) =>
    `No ${
      type === 'groups' ? 'groups' : 'accounts/devices'
    } match "${searchValue}"`,
  createGroup: 'Create Group',
  cancelCreateGroup: 'Discard Changes',
  addId: 'Add ID',
  cancelAddId: 'Discard Changes',
  accountIdLabel: 'Account ID',
  accountIdPlaceholder: 'Search or enter an Account ID',
  accountIdTooltip: 'Select an Account based override',
  deviceIdLabel: 'Device ID',
  deviceIdPlaceholder: 'Search or enter an Device ID',
  deviceIdTooltip: 'Select a Device based override',
  errorAccountDeviceSearch: (searchValue, type = 'Account') =>
    `No ${type} IDs match "${searchValue}"`,
  accountProductLabel: 'Products',
  accountProductPlaceholder: 'Select product(s)',
  accountProductTooltip:
    'Select one or more products to associate with this override',
  accountGroupLabel: 'Add to group (Optional)',
  accountGroupEmpty: 'No groups available',
  accountGroupPlaceholder: 'Select group(s)',
  accountGroupTooltip:
    'Select one or more groups to associate with this override',
  groupNameLabel: 'Group Name',
  groupIDLabel: 'Search for ID',
  groupIDPlaceholder: 'Enter any type of override name or ID',
  groupIDEmpty: 'No overrides available',
  groupIDTooltip: 'Select one or more IDs to associate with this group',
  groupViewDetails: 'View Details',
  groupHideDetails: 'Hide Details',
  saveOverrideGroup: 'Save',
  noOverrides: 'No overrides available. Click "Add ID" to create one!',
  noOverrideGroups:
    'No override groups available. Click "Create Group" to build one!',
  successSubmitTitle: (action, type) =>
    `${action === 'create' ? 'New ' : ''}${
      type === 'groups' ? 'Override Group' : 'Override'
    }${action === 'update' ? ' Change' : ''}${
      action === 'delete' ? ' Removed' : ''
    }`,
  successSubmitDescription: (action, name) =>
    `${name} successfully ${action === 'create' ? 'created' : ''}${
      action === 'update' ? 'updated' : ''
    }${action === 'delete' ? 'deleted' : ''}.`,
  deleteConfirmMessage: name => `Are you sure you want to delete ${name}?`,
  deleteConfirmSubMessage: 'This cannot be undone',
  errorDuplicateID:
    'An override with this ID already exists. Please try another one.',
  errorDuplicateGroup:
    'A group with this name already exists. Please try another one.',
  errorSubmitTitle: (action, name) => `Unable to ${action} ${name}`,
  errorSubmitDescription:
    'Please try again in a few minutes. If this continues, ',
  errorSubmitLink: 'contact support.',
  errorNoChanges: type => `You have not made any changes to this ${type}.`,
}
