import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './InventoryList.scss'

class InventoryList extends Component {
  state = {
    isSelected: this.props.isSelected,
  }

  static propTypes = {
    values: PropTypes.object.isRequired,
    onSelect: PropTypes.func,
    isSelected: PropTypes.string,
    type: PropTypes.string,
  }

  static defaultProps = {
    onSelect: null,
    isSelected: null,
    type: null,
  }

  getSelected = () => this.state.isSelected

  handleSelectItem = item => {
    const { onSelect } = this.props

    this.setState({ isSelected: item })

    if (onSelect) {
      onSelect(item)
    }
  }

  renderListValues = () => {
    const { values } = this.props
    const { isSelected } = this.state

    return Object.keys(values).map((item, index) => {
      const key = `${item}__${index}`
      const listItemClassNames = classNames({
        'list-item-container': true,
        selected: isSelected === item,
      })
      const itemCountClassNames = classNames({
        'item-count__container': true,
        'selected-number': isSelected === item,
      })
      return (
        <li
          key={key}
          role="menuitem"
          tabIndex={index}
          onClick={() => this.handleSelectItem(item)}
          onKeyDown={() => this.handleSelectItem(item)}
          className={listItemClassNames}
        >
          {item}
          <div className={itemCountClassNames}>{values[item].length}</div>
        </li>
      )
    })
  }

  render() {
    const { type } = this.props
    const listContainerClassNames = classNames({
      list__container: true,
      primary: !type,
      secondary: type && type === 'secondary',
      blue: type && type === 'blue',
    })

    return (
      <div className={listContainerClassNames}>
        <ul>{this.renderListValues()}</ul>
      </div>
    )
  }
}

export default InventoryList
