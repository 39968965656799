import React, { useEffect, useState, useReducer, useCallback } from 'react'
import { useQuery } from '@apollo/client'

import {
  permissionEnum,
  hasPermission,
  experimentStatusEnum,
} from '@charter/distillery-rules'
import { FilterSearch, SortableTable } from '@kite/react-kite-plus'
import {
  KiteLoader,
  KiteButton,
  KiteTabs,
  KitePagination,
} from '@kite/react-kite'
import { GET_ALL_CONTENT_MAPS } from './queries/getAllContentMaps'
import { capitalizeString } from '../../shared/utilities'

import './ContentMapList.scss'

const PAGE_LENGTH = 5
const DATE_FORMAT = {
  timeZoneName: 'short',
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: '2-digit',
}

export function ContentMapList(props) {
  const {
    location: { pathname: path },
    onNavigate,
    applicationPermissions,
    history,
  } = props
  const initContentMapState = {
    allContentMaps: [],
    filteredContentMaps: [],
    initialContentMaps: [],
    searchValue: '',
    currentPage: 1,
    pageNumbers: 1,
  }
  const [status, setStatus] = useState('Draft')
  const { loading, error, data } = useQuery(GET_ALL_CONTENT_MAPS, {
    fetchPolicy: 'network-only',
  })

  const reducer = (state, action) => {
    if (action.type === 'setInitContentMaps') {
      const firstSlice = (state.currentPage - 1) * 5
      const lastSlice = state.currentPage * 5
      const pageNum =
        action.state.contentMaps.length % PAGE_LENGTH === 0
          ? action.state.contentMaps.length / PAGE_LENGTH
          : Math.trunc(action.state.contentMaps.length / PAGE_LENGTH) + 1

      let maps = action.state.contentMaps
      if (state.searchValue) {
        maps = action.state.contentMaps.filter(
          ({ name, contentAreaId, jiraTicket }) => {
            const searchVal = state.searchValue.toLowerCase()
            if (
              name.toLowerCase().includes(searchVal) ||
              contentAreaId.toLowerCase().includes(searchVal) ||
              (jiraTicket && jiraTicket.toLowerCase().includes(searchVal))
            ) {
              return true
            }
            return false
          }
        )
      }

      return {
        initialContentMaps: action.state.contentMaps,
        allContentMaps: action.state.allContentMaps,
        filteredContentMaps: maps.slice(firstSlice, lastSlice),
        pageNumbers: pageNum,
        currentPage: action.state.currentPage,
        searchValue: state.searchValue ? state.searchValue : '',
      }
    } else if (action.type === 'setContentMaps') {
      const firstSlice = 0
      const lastSlice = 1 * 5
      const pageNum =
        action.state.contentMaps.length % PAGE_LENGTH === 0
          ? action.state.contentMaps.length / PAGE_LENGTH
          : Math.trunc(action.state.contentMaps.length / PAGE_LENGTH) + 1

      return {
        ...state,
        searchValue: action.state.searchValue,
        currentPage: 1,
        filteredContentMaps: action.state.contentMaps.slice(
          firstSlice,
          lastSlice
        ),
        pageNumbers: pageNum,
      }
    } else if (action.type === 'goToNextPage') {
      const firstSlice = state.currentPage * 5
      const lastSlice = (state.currentPage + 1) * 5
      return {
        ...state,
        currentPage: state.currentPage + 1,
        filteredContentMaps: state.initialContentMaps.slice(
          firstSlice,
          lastSlice
        ),
      }
    } else if (action.type === 'goToLastPage') {
      const firstSlice = (state.currentPage - 2) * 5
      const lastSlice = (state.currentPage - 1) * 5

      return {
        ...state,
        currentPage: state.currentPage - 1,
        filteredContentMaps: state.initialContentMaps.slice(
          firstSlice,
          lastSlice
        ),
      }
    } else if (action.type === 'clickedNumber') {
      const firstSlice = (action.state.currentPage - 1) * 5
      const lastSlice = action.state.currentPage * 5

      return {
        ...state,
        currentPage: action.state.currentPage,
        filteredContentMaps: state.initialContentMaps.slice(
          firstSlice,
          lastSlice
        ),
      }
    } else {
      return state
    }
  }

  const [contentMapsState, dispatch] = useReducer(reducer, initContentMapState)

  const {
    allContentMaps,
    initialContentMaps,
    filteredContentMaps,
    searchValue,
    currentPage,
    pageNumbers,
  } = contentMapsState

  const filterContentMaps = useCallback(
    filterStatus => {
      const statusEnum = filterStatus.replace(/ /g, '_').toUpperCase()

      const contentMapsFiltered = data.contentMaps
        .filter(({ statusId, startTime, stopTime }) => {
          if (
            (statusEnum === 'DRAFT' &&
              statusId === experimentStatusEnum.DRAFT) ||
            (statusEnum === 'SCHEDULED' &&
              statusId === experimentStatusEnum.APPROVED &&
              startTime > new Date().toISOString() &&
              (stopTime === null || new Date().toISOString() < stopTime)) ||
            (statusEnum === 'ACTIVE' &&
              statusId === experimentStatusEnum.APPROVED &&
              startTime < new Date().toISOString() &&
              (stopTime === null || new Date().toISOString() < stopTime)) ||
            (statusEnum === 'COMPLETED' &&
              statusId === experimentStatusEnum.APPROVED &&
              new Date().toISOString() > stopTime)
          ) {
            return true
          }
          return false
        })
        .map(
          ({
            id,
            name,
            statusId,
            status: { name: statusName },
            product: { displayName: productName },
            config,
            startTime,
            stopTime,
            contentAreaId,
            comment,
            isExperimenting,
          }) => {
            return {
              id,
              name,
              statusId,
              statusName,
              productName,
              config,
              dateRange: formatDateRange(startTime, stopTime),
              contentAreaId,
              comment,
              isExperimenting: isExperimenting ? 'yes' : null,
            }
          }
        )

      const allContentMaps = data.contentMaps.map(
        ({
          id,
          name,
          statusId,
          status: { name: statusName },
          product: { displayName: productName },
          config,
          contentAreaId,
          comment,
          jiraTicket,
          isExperimenting,
        }) => {
          return {
            id,
            name,
            statusId,
            statusName,
            productName,
            config,
            contentAreaId,
            comment,
            jiraTicket,
            isExperimenting: isExperimenting ? 'Yes' : null,
          }
        }
      )

      dispatch({
        type: 'setInitContentMaps',
        state: {
          allContentMaps,
          contentMaps: contentMapsFiltered,
          currentPage: 1,
        },
      })
    },
    [data]
  )

  useEffect(() => {
    onNavigate({
      path,
      title: 'Content Maps',
    })

    const filterStatus = capitalizeString(
      path.split('/').pop().replace(/-/g, ' ')
    )
    setStatus(filterStatus)
    if (data && data.contentMaps) {
      filterContentMaps(filterStatus)
    }
  }, [path, onNavigate, data, filterContentMaps])

  const filterContentMapsByStatus = statusSelected => {
    setStatus(statusSelected)
    filterContentMaps(statusSelected)
  }

  const filterSearch = (contentMaps, searchVal) => {
    dispatch({
      type: 'setContentMaps',
      state: {
        contentMaps,
        searchValue: searchVal,
      },
    })
  }

  const handlePageChange = action => {
    if (action.type === 'nextPage') {
      dispatch({
        type: 'goToNextPage',
      })
    } else if (action.type === 'lastPage') {
      dispatch({
        type: 'goToLastPage',
      })
    } else if (action.type === 'clickedNumber') {
      dispatch({
        type: 'clickedNumber',
        state: { currentPage: action.page },
      })
    }
  }

  const formatDateRange = (startDate, stopDate) => {
    const formattedStartDate = startDate
      ? new Date(startDate).toLocaleString('en-US', DATE_FORMAT)
      : 'N/A'
    const formattedStopDate = stopDate
      ? new Date(stopDate).toLocaleString('en-US', DATE_FORMAT)
      : 'No end date'
    return `${formattedStartDate}-${formattedStopDate}`
  }

  if (error) {
    return <div>There was an issue grabbing the content maps.</div>
  }

  return (
    <div className="content-maps">
      <div className="experiment-list">
        <div className="experiment-list__controls">
          <div className="experiment-list__filter">
            <FilterSearch
              name="searchValue"
              label="Search Content Maps"
              initialData={
                searchValue.length ? allContentMaps : initialContentMaps
              }
              filterKeys={['name', 'contentAreaId', 'jiraTicket']}
              placeholder="Try name, content area id, or jira tickets"
              showInvalidText={true}
              maxWidth="100%"
              onChange={filterSearch}
              value={searchValue}
            />
          </div>
          <KiteButton
            disabled={
              !hasPermission({
                applicationPermissions,
                permissionId: permissionEnum.CONTENT_MAP_CREATE,
              })
            }
            onClick={() => {
              history.push('/content-maps/new/create')
            }}
            leftIcon="document"
          >
            New Content Map
          </KiteButton>
        </div>

        <div className="experiment-list__table">
          <div className="content-maps__tab-page-container">
            <KiteTabs
              className=""
              currentTab={searchValue.length ? 'Search Results' : status}
              justify="flex-start"
              margin=""
              maxWidth=""
              onSelectTab={event => {
                history.replace(
                  `/content-maps/${event.toLowerCase().replace(/\s/g, '-')}`
                )
                filterContentMapsByStatus(event)
              }}
              tabs={
                searchValue.length
                  ? ['Search Results']
                  : ['Draft', 'Scheduled', 'Active', 'Completed']
              }
            />
          </div>
          <SortableTable
            tableData={filteredContentMaps}
            loader={<KiteLoader />}
            loading={loading}
            columns={[
              {
                label: 'Name',
                sortKey: 'name',
                sortEnabled: false,
                size: 0.8,
              },
              {
                label: 'Content Area',
                sortKey: 'contentAreaId',
                sortEnabled: false,
                size: 1.5,
              },
              {
                label: 'Product',
                sortKey: 'productName',
                sortEnabled: false,
                size: 1,
              },
              {
                label: 'Dates Active',
                sortKey: 'dateRange',
                sortEnabled: false,
                size: 1.3,
              },
              {
                label: 'Experimenting',
                sortKey: 'isExperimenting',
                sortEnabled: false,
                size: 0.9,
              },
              {
                label: '\xa0',
                sortKey: 'itemControls',
                sortEnabled: false,
                className: 'orm-list__edit-controls-cell',
                size: 0.9,
                render: row => (
                  <div className="orm-list__edit-controls">
                    <KiteButton
                      className="detailsAndEdit"
                      key="2"
                      type="standalone-link"
                      size="small"
                      rightIcon="arrow-right-circle"
                      onClick={() => {
                        const { id, config } = row
                        const step = JSON.parse(config).setUpSteps.find(
                          ({ status }) =>
                            status === 'incomplete' || status === 'initial'
                        )
                        history.push(
                          `/content-maps/${id}/${
                            step ? step.label.toLowerCase() : 'review'
                          }`
                        )
                      }}
                    >
                      View & Edit
                    </KiteButton>
                  </div>
                ),
              },
            ]}
          />
        </div>
      </div>
      <KitePagination
        currentPage={currentPage}
        onNextPage={() => {
          handlePageChange({
            type: 'nextPage',
          })
        }}
        onPageSelect={event => {
          handlePageChange({
            type: 'clickedNumber',
            page: event,
          })
        }}
        onPrevPage={() => {
          handlePageChange({
            type: 'lastPage',
          })
        }}
        totalPages={pageNumbers > 1 ? pageNumbers : 1}
      />
    </div>
  )
}

export default ContentMapList
