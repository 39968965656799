import { gql } from '@apollo/client'

const UPDATE_USER_CONFIG = gql`
  mutation updateUserConfig($input: UpdateUserConfig!) {
    updateUserConfig(input: $input) {
      id
      config
    }
  }
`

export default UPDATE_USER_CONFIG
