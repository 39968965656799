import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

const DELETE_VARIANT_UPLOAD = gql`
  mutation deleteVariantUpload($variantId: Int!, $variantUploadId: Int!) {
    deleteVariantUpload(
      variantId: $variantId
      variantUploadId: $variantUploadId
    )
  }
`

const deleteVariantUpload = graphql(DELETE_VARIANT_UPLOAD, {
  name: 'deleteVariantUpload',
  withRef: true,
})

export default deleteVariantUpload
