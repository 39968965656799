import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'
import { GET_EXPERIMENTS } from '../../../shared/queries'

export const TERMINATE_EXPERIMENT = gql`
  mutation terminateExperiment(
    $id: Int!
    $environmentId: Int!
    $finalOutcome: CustomString!
    $winnerVariantId: Int
    $experimentStatus: CustomString
  ) {
    terminateExperiment(experimentId: $id, environmentId: $environmentId, experimentStatus: $experimentStatus)
    updateExperimentFinalOutcome(
      id: $id
      finalOutcome: $finalOutcome
      winnerVariantId: $winnerVariantId
    )
  }
`

const terminateExperiment = graphql(TERMINATE_EXPERIMENT, {
  name: 'terminateExperiment',
  options: {
    refetchQueries: [{ query: GET_EXPERIMENTS }],
    awaitRefetchQueries: true,
  },
})

export default terminateExperiment
