import React, { Component } from 'react'
import { func } from 'prop-types'
import { KiteTabs } from '@kite/react-kite'

import RolesTab from './tabs/Roles/Roles'
import UsersTab from './tabs/Users/Users'

import './Administration.scss'

const tabs = ['Users', 'Roles']

class Administration extends Component {
  static propTypes = {
    onNavigate: func.isRequired,
  }

  state = {
    currentTab: 'Users',
  }

  componentDidMount() {
    const { onNavigate } = this.props

    onNavigate(
      {
        path: '/administration',
        title: 'Administration',
      },
      true
    )
  }

  handleSelectTab = value => this.setState({ currentTab: value })

  render() {
    const { currentTab } = this.state

    return (
      <div className="administration-page">
        <KiteTabs
          className="administration-page__tabs"
          currentTab={currentTab}
          tabs={tabs}
          onSelectTab={value => this.handleSelectTab(value)}
        />
        {currentTab === 'Users' && <UsersTab />}
        {currentTab === 'Roles' && <RolesTab />}
      </div>
    )
  }
}

export default Administration
