const rolesToCheck = [2, 3, 4, 5];
const applyUserFilters = (experiments, filters, statusFilter) => {
  const { product, feature, experimenter, types } = filters;

  let filteredExperiments = experiments;

  if (product && product.length) {
    filteredExperiments = filteredExperiments.filter(experiment => {
      const match = product.find(productFilter => {
        return productFilter.selectedFilters.find((filter) => {
          if (productFilter.name === 'CMS' && experiment.isCmsExperiment) {
            return (filter === experiment.product)
          } else if (productFilter.name !== 'CMS' && !experiment.isCmsExperiment) {
            return (filter === experiment.product)
          }
          return false
        })
      });

      if (match) {
        return experiment;
      }
      return false;
    });
  }

  if (feature && feature.length) {
    filteredExperiments = filteredExperiments.filter(experiment => {
      const match = feature.find(featureFilter => {
        const featureMatch = featureFilter.selectedFilters.find(
          filter => filter === experiment.feature
        );
        const categoryMatch = featureFilter.name === experiment.featureType;

        return featureMatch && categoryMatch;
      });

      if (match) {
        return experiment;
      }
      return false;
    });
  }

  if (experimenter && experimenter.length) {
    filteredExperiments = filteredExperiments.filter(experiment => {
      const match = experimenter.find(experimenterFilter => {
        const { experimentToUsers } = experiment;

        if (!experimentToUsers) return false;

        let experimentLevelUsers = experimentToUsers.filter(({ roleId }) =>
          rolesToCheck.includes(roleId)
        );

        if (experimentLevelUsers.length === 0) {
          return false;
        }
        experimentLevelUsers = experimentLevelUsers.map(
          ({ user: { displayName } }) => displayName
        );

        return experimenter.some(r => experimentLevelUsers.includes(r));
      });

      if (match) {
        return experiment;
      }
      return false;
    });
  }

  if (statusFilter && statusFilter !== 'all') {
    filteredExperiments = filteredExperiments.filter(experiment => {
      if (statusFilter.toLowerCase() === experiment.status.toLowerCase()) {
        return experiment;
      }
      return false;
    });
  }

  if (types && types.length) {
    filteredExperiments = filteredExperiments.filter(experiment => {
      const match = types.find((typeFilter) => {
        if (typeFilter !== 'Mutally Exclusive') {
          return (experiment.type === typeFilter)
        } else {
          return experiment.exclusive
        }
      });

      if (match) {
        return experiment;
      }

      return false;
    });
  }

  return filteredExperiments;
};

export default applyUserFilters;
