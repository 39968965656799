import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

const DELETE_OVERRIDE = gql`
  mutation deleteOverride($overrideId: Int!) {
    deleteOverride(overrideId: $overrideId)
  }
`

const deleteOverride = graphql(DELETE_OVERRIDE, {
  name: 'deleteOverride',
})

export default deleteOverride
