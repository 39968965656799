/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useState } from 'react'
import isEmpty from 'lodash/isEmpty'

import { metricTypeEnum } from '@charter/distillery-rules'
import { KiteLink, KiteIcon } from '@kite/react-kite'
import { SmartExpansionPanel } from '@kite/react-kite-plus'
import { ExpansionPanel } from '../../../../../componentLibrary'
import { AllocationHistory } from '../../../../../components'

import './copyContent.scss'

const metricTypes = {
  SUCCESS: 1,
  SAFETY: 2,
}

const Variants = ({ variants }) => {
  const [activePanel, setActivePanel] = useState(null)

  return (
    <div className="variant-container">
      {variants.map((variant, index) => {
        const {
          id,
          uuid,
          name: variantName,
          description,
          displayOrder,
          jsonPayload,
        } = variant

        const key = id || `${variantName}__${index}`
        const header =
          displayOrder === 0 ? 'Control' : `Variant ${displayOrder}`
        const hasJson = !isEmpty(jsonPayload) || !isEmpty(jsonPayload)
        const titleColumns = [
          { content: header, heading: true, size: 0.25 },
          { content: variantName, size: 0.5 },
          `ID: ${uuid}`,
        ]
        let payload = ''
        let isCustomJson = false
        if (hasJson) {
          isCustomJson = !Array.isArray(JSON.parse(jsonPayload))
          payload = isCustomJson
            ? JSON.stringify(JSON.parse(jsonPayload), null, 2)
            : JSON.parse(jsonPayload)
        }

        return (
          <SmartExpansionPanel
            key={key}
            title={variantName}
            active={activePanel === variantName}
            onClick={rowTitle => {
              setActivePanel(rowTitle)
            }}
            titleColumns={titleColumns}
            showTitle={false}
            className="variant-container__expansion-panel"
          >
            <ExpansionPanel
              key={key}
              className="expansion-variant"
              isExpanded={activePanel === variantName}
            >
              <div key={key}>
                <span className="variant-container__header-info">Name:</span>
                <span>{variantName}</span>
              </div>
              <div>
                <span className="variant-container__header-info">
                  Description:
                </span>
                <span>{description}</span>
              </div>
              <div>
                <span className="variant-container__header-info">
                  JSON Payload:
                </span>
                {hasJson && isCustomJson ? (
                  <pre className="experiment-variants__custom-json-presentation">
                    {payload}
                  </pre>
                ) : (
                  ''
                )}
                {hasJson && !isCustomJson && (
                  <div className="experiment-variants__json-builder-values variant-container__json">
                    {payload.map(({ name, type, value }) => (
                      <div
                        key={`${name}__${type}__${value}`}
                        className="experiment-variants__json-line"
                      >
                        <span>{`Name: ${name}`}</span>
                        <span>{`Type: ${type}`}</span>
                        <span>{`Value: ${value}`}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </ExpansionPanel>
          </SmartExpansionPanel>
        )
      })}
    </div>
  )
}

const hypothesis = {
  displayItem: (thesis, className) => (
    <div className={className} key="hypothesis">
      <div>Hypothesis</div>
      <div className="evaluation-experiment-summary">{thesis}</div>
    </div>
  ),
}

const copyContent = {
  objectives: {
    summary: {
      displayItem: (summary, className) => (
        <div className={className} key="objectives">
          <div>Problem/Objective Statement</div>
          <div className="evaluation-experiment-summary">{summary}</div>
        </div>
      ),
    },
    hypothesis,
  },
  success_metrics: {
    metrics: {
      displayItem: (
        { metrics, experimentMetrics, metricDefinitions },
        className
      ) => {
        let enabledSuccessMetrics
        let enabledSafteyMetrics
        if (
          ((experimentMetrics && experimentMetrics.length === 0) ||
            !experimentMetrics) &&
          metrics
        ) {
          enabledSuccessMetrics = metrics
            .filter(
              ({ isEnabled, metricTypeId }) =>
                isEnabled && metricTypeId === metricTypeEnum.SUCCESS
            )
            .map(({ displayName }) => displayName)
          enabledSafteyMetrics = metrics
            .filter(
              ({ isEnabled, metricTypeId }) =>
                isEnabled && metricTypeId === metricTypeEnum.SAFETY
            )
            .map(({ displayName }) => displayName)
        } else {
          enabledSuccessMetrics = metricDefinitions
            .filter(({ _id: uuid }) => {
              const metric = experimentMetrics.find(
                ({ metricTypeId, metricUuid }) =>
                  uuid === metricUuid && metricTypeId === metricTypes.SUCCESS
              )
              if (metric) {
                return metric
              }
              return false
            })
            .map(({ displayName }) => displayName)
          enabledSafteyMetrics = metricDefinitions
            .filter(({ _id: uuid }) => {
              const metric = experimentMetrics.find(
                ({ metricTypeId, metricUuid }) =>
                  uuid === metricUuid && metricTypeId === metricTypes.SAFETY
              )
              if (metric) {
                return metric
              }
              return false
            })
            .map(({ displayName }) => displayName)
        }

        return (
          <div className={className} key="metrics">
            <div>Metrics</div>
            <div>
              Primary:
              <span className="metric-list">
                {enabledSuccessMetrics.join('; ')}
              </span>
            </div>
            <div>
              Secondary/Safety:
              <span className="metric-list">
                {enabledSafteyMetrics.join('; ')}
              </span>
            </div>
          </div>
        )
      },
    },
    hypothesis,
  },
  power_and_sampling: {
    targetSampleSize: {
      displayItem: (targetSampleSize, className) => (
        <div className={className} key="targetSampleSize">
          <div>Target Sample Size</div>
          <div className="sample-size__number">
            {targetSampleSize || 'None Specified'}
          </div>
        </div>
      ),
    },
    mde: {
      displayItem: (mde, className) => (
        <div className={className} key="mde">
          <div>Precalculated MDE</div>
          <div className="sample-size__number">{mde || 'None Specified'}</div>
        </div>
      ),
    },
  },
  setup: {
    variants: {
      displayItem: (variants, className) => (
        <Variants variants={variants} className={className} key="variants" />
      ),
    },
    multipleVariantReason: {
      displayItem: (multipleVariantReason, className) => (
        <div className={className} key="multipleVariantReason">
          <div>Multiple Variant Reason</div>
          <div className="evaluation-experiment-reasoning">
            {multipleVariantReason?.length > 0
              ? multipleVariantReason
              : 'No reasoning provided.'}
          </div>
        </div>
      ),
    },
    simultaneouslyRunningExperiment: {
      displayItem: (experiments, className) => {
        const experimentList = []
        experiments.forEach(({ name, id }) => {
          experimentList.push(
            <KiteLink
              newTab
              href={`/experiments/${id}/results`}
              type="standalone"
              style={{ 'padding-left': '10px' }}
            >
              {name}
              <KiteIcon margin="5px" name="arrow-up-right" size="1rem" />
            </KiteLink>
          )
        })
        return (
          <div className={className} key="simultaneouslyRunningExperiment">
            <div>Experiments simultaneously running on same product:</div>
            <div style={{ paddingTop: '10px' }}>
              {experimentList.length > 0
                ? experimentList
                : 'There were no simulatenously running experiments.'}
            </div>
          </div>
        )
      },
    },
  },
  rollout: {
    environmentSamplings: {
      displayItem: (environmentSamplings, className) => {
        const { allocationHistory } = environmentSamplings.find(
          sampling => sampling.environment.id === 1
        )
        const selectedEnvironment = {
          name: 'production',
          color: '#00bdb7',
        }

        const height = `${allocationHistory.length * 100}px`

        return (
          <div
            style={{ height }}
            className={className}
            key="samplingAndAllocation"
          >
            <div>Sampling & Allocation History</div>
            <div className="experiment-allocation-history eval__allocation-history">
              <AllocationHistory
                allocationHistory={allocationHistory}
                selectedEnvironment={selectedEnvironment}
                key="allocationHistory"
              />
            </div>
          </div>
        )
      },
    },
  },
  ending_conclusions: {
    durationNumDays: {
      displayItem: ({ durationNumDays, actualDurationDays }, className) => (
        <div className={`${className} sample-size`} key="durationNumDays">
          <div className="sample-size__planned-length">
            <div>Planned Run Length:</div>
            <div className="sample-size__number">
              {durationNumDays || 'None Specified'}
            </div>
          </div>
          <div>
            <div>Actual Run Length:</div>
            <div className="sample-size__number">
              {actualDurationDays || 'Less than a day'}
            </div>
          </div>
        </div>
      ),
    },
    finalOutcome: {
      displayItem: (finalOutcome, className) => (
        <div className={className} key="finalOutcome">
          <div>Final Outcome</div>
          <div className="evaluation-experiment-reasoning">
            {finalOutcome || 'No reasoning provided.'}
          </div>
        </div>
      ),
    },
    metricCounts: {
      displayItem: (metricCounts, className) => {
        let totalCounts = 0

        for (
          let countIndex = 0;
          countIndex < metricCounts.length;
          countIndex++
        ) {
          const {
            cumulativeUniqueAccounts: totalAccountsOnDate,
          } = metricCounts[countIndex]

          totalCounts += totalAccountsOnDate
        }

        return (
          <div className={className}>
            <div>Final Sample Size</div>
            <div className="sample-size__number">
              {totalCounts
                ? totalCounts.toLocaleString()
                : 'Sample size not avaliable. Check monitor page on experiment.'}
            </div>
          </div>
        )
      },
    },
  },
}

export default copyContent
