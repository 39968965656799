const getTdcsPublishHistoryList = {
  query: `
    publishHistoryListForExperimentAndEnvironment(experimentId: $experimentId, environmentId: $environmentId) {
      id
      publishStatus {
        id
        displayName
      }     
      experimentStatusId
      isExperimentReset
      createdTime
      createdUser {
        id
        displayName
      }
    }`,
  variables: {
    $experimentId: 'Int!',
    $environmentId: 'Int!',
  },
  options: ({ id, selectedEnvironment }) => ({
    variables: {
      experimentId: id,
      environmentId: selectedEnvironment.id,
    },
  }),
}

export default getTdcsPublishHistoryList
