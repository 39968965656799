import Keycloak from 'keycloak-js'

const keycloak = new Keycloak({
  clientId: 'dx-distillery',
  realm: process.env.REACT_APP_KEYCLOAK_REALM_NAME,
  'auth-server-url': 'https://keycloak.spectrumtoolbox.com/auth',
  'ssl-required': 'external',
  resource: 'dx-distillery',
  'public-client': true,
  'confidential-port': 0,
})

export default keycloak
