const getSimultaneousExperiments = {
  query: `
    findExperimentsRunSimultaneously(experimentId: $experimentId) {
      id
      name
      experimentStatusId
    }
  `,
  variables: {
    $experimentId: 'Int!',
  },
  options: ({ experiment: { id: experimentId } }) => ({
    variables: {
      experimentId,
    },
    fetchPolicy: 'cache-and-network',
  }),
};

export default getSimultaneousExperiments;
