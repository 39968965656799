const mockExperimentVariantsData = {
  experiment: {
    id: 1,
    uuid: '0d86f101e42d41d783f235a8134ed6ee',
    startTime: 'Thu Jul 04 2019 23:33:31 GMT-0600 (Mountain Daylight Time)',
    name: 'JJ ',
    experimentTypeId: 1,
    summary: 'sdfds',
    hypothesis: 'sfds',
    winnerVariantId: null,
    finalOutcome:
      "It's the final outcome,  It's the final outcome, It's the final outcomeIt's the final outcomeIt's the final outcomeIt's the final outcomeIt's the final outcomeIt's the final outcomeIt's the final outcomeIt's the final outcomeIt's the final outcomeIt's the final outcomeIt's the final outcomeIt's the final outcome",
    variants: [
      {
        id: 1,
        uuid: '46014751906746c79c9e7f6d032332f4',
        name: 'Control 1',
        description: 'sdfsdfs',
        variantUploads: [],
      },
      {
        id: 2,
        uuid: '50cc1314cede4014b855bf0bd9101562',
        name: 'Var 1',
        description: 'sdfsdf',
        variantUploads: [],
      },
    ],
    experimentToProductFeatures: [
      {
        product: {
          id: 1,
          uuid: '5ccb9fa12982f825e5a1c11e',
          displayName: 'Android',
        },
        productFeature: {
          id: 2,
          displayName: 'No/Low Volume',
        },
      },
    ],
    environmentSamplings: [
      {
        id: 1,
        lastModifiedTime:
          'Thu Jul 04 2019 23:33:31 GMT-0600 (Mountain Daylight Time)',
        lastModifiedUser: {
          id: 6,
          firstName: 'John',
          lastName: 'Wright',
        },
        environment: {
          id: 4,
          name: 'DEVELOPMENT',
        },
        experimentStatus: {
          id: 1,
          name: 'DRAFT',
        },
      },
      {
        id: 2,
        lastModifiedTime:
          'Thu Jul 04 2019 23:33:31 GMT-0600 (Mountain Daylight Time)',
        lastModifiedUser: {
          id: 6,
          firstName: 'John',
          lastName: 'Wright',
        },
        environment: {
          id: 3,
          name: 'ENGNEW',
        },
        experimentStatus: {
          id: 1,
          name: 'DRAFT',
        },
      },
      {
        id: 3,
        lastModifiedTime:
          'Thu Jul 04 2019 23:33:31 GMT-0600 (Mountain Daylight Time)',
        lastModifiedUser: {
          id: 6,
          firstName: 'John',
          lastName: 'Wright',
        },
        environment: {
          id: 2,
          name: 'ENGPROD',
        },
        experimentStatus: {
          id: 1,
          name: 'DRAFT',
        },
      },
      {
        id: 4,
        lastModifiedTime:
          'Thu Jul 04 2019 23:33:31 GMT-0600 (Mountain Daylight Time)',
        lastModifiedUser: {
          id: 6,
          firstName: 'John',
          lastName: 'Wright',
        },
        environment: {
          id: 1,
          name: 'PRODUCTION',
        },
        experimentStatus: {
          id: 1,
          name: 'DRAFT',
        },
      },
    ],
  },
  retrieveQuantumToken: 'atoken',
}
const mockExperimentMetricsData = {
  metricsForExperiment: [
    {
      id: 1,
      name: 'TuneTime',
      uuid: '5ccba09f2982f84f79a1c136',
      displayName: 'Tune Time',
      description: 'Average tune time over all streams per target device.',
      isRequired: true,
      isEnabled: true,
      metricType: {
        id: 1,
        name: 'Success',
      },
      metricUnitType: {
        name: 'Minutes',
        abbreviation: 'MIN',
      },
      metricImpactDirectionType: {
        id: 1,
        name: 'Positive',
      },
    },
    {
      id: 2,
      name: 'MOS',
      uuid: '5ccba09f2982f88211a1c133',
      displayName: 'MOS',
      description: 'Average unweighted MOS score for each stream.',
      isRequired: true,
      isEnabled: true,
      metricType: {
        id: 1,
        name: 'Success',
      },
      metricUnitType: {
        name: 'Mean Opinion Score',
        abbreviation: 'MOS',
      },
      metricImpactDirectionType: {
        id: 1,
        name: 'Positive',
      },
    },
    {
      id: 3,
      name: 'BufferingRatio',
      uuid: '5ccba09f2982f86967a1c130',
      displayName: 'Buffering Ratio',
      description:
        'Average buffering ratio over all streams per target device weighted by stream length.',
      isRequired: true,
      isEnabled: true,
      metricType: {
        id: 1,
        name: 'Success',
      },
      metricUnitType: {
        name: 'Average',
        abbreviation: 'AVG',
      },
      metricImpactDirectionType: {
        id: 1,
        name: 'Positive',
      },
    },
    {
      id: 4,
      name: 'StreamFailureRate',
      uuid: '5ccba09f2982f81ba7a1c134',
      displayName: 'Stream Failure Rate',
      description:
        'Fraction of all streams that break during playback on target device.',
      isRequired: true,
      isEnabled: true,
      metricType: {
        id: 1,
        name: 'Success',
      },
      metricUnitType: {
        name: 'Count',
        abbreviation: 'Count',
      },
      metricImpactDirectionType: {
        id: 1,
        name: 'Positive',
      },
    },
    {
      id: 5,
      name: 'AverageBitRate',
      uuid: '5ccba09f2982f8c230a1c12f',
      displayName: 'Average Bit Rate',
      description:
        'Average Bitrate over all streams per target device weighted by stream length.',
      isRequired: true,
      isEnabled: true,
      metricType: {
        id: 1,
        name: 'Success',
      },
      metricUnitType: {
        name: 'Average',
        abbreviation: 'AVG',
      },
      metricImpactDirectionType: {
        id: 1,
        name: 'Positive',
      },
    },
    {
      id: 6,
      name: 'WeightedMos',
      uuid: '5ccba09f2982f825dba1c137',
      displayName: 'Weighted Mos',
      description: 'Average weighted MOS score for each stream.',
      isRequired: true,
      isEnabled: true,
      metricType: {
        id: 1,
        name: 'Success',
      },
      metricUnitType: {
        name: 'Average',
        abbreviation: 'AVG',
      },
      metricImpactDirectionType: {
        id: 1,
        name: 'Positive',
      },
    },
    {
      id: 7,
      name: 'Level2DownshiftsPerHour',
      uuid: '5ccba09f2982f8b259a1c132',
      displayName: 'Level 2 Downshifts Per Hour',
      description:
        'Level 2 Down Shifts per hour of streamed content on target device.',
      isRequired: true,
      isEnabled: true,
      metricType: {
        id: 1,
        name: 'Success',
      },
      metricUnitType: {
        name: 'Count',
        abbreviation: 'Count',
      },
      metricImpactDirectionType: {
        id: 1,
        name: 'Positive',
      },
    },
    {
      id: 8,
      name: 'StreamInitFailureRate',
      uuid: '5ccba09f2982f85aa6a1c135',
      displayName: 'Stream Init Failure Rate',
      description:
        'Fraction of all play events that result in initialization failure on target device.',
      isRequired: true,
      isEnabled: true,
      metricType: {
        id: 1,
        name: 'Success',
      },
      metricUnitType: {
        name: 'Count',
        abbreviation: 'Count',
      },
      metricImpactDirectionType: {
        id: 1,
        name: 'Positive',
      },
    },
    {
      id: 9,
      name: 'ContentConsumed',
      uuid: '5ccba09f2982f809b2a1c131',
      displayName: 'Content Consumed',
      description: 'Average content consumed per device per day.',
      isRequired: true,
      isEnabled: true,
      metricType: {
        id: 1,
        name: 'Success',
      },
      metricUnitType: {
        name: 'Average',
        abbreviation: 'AVG',
      },
      metricImpactDirectionType: {
        id: 1,
        name: 'Positive',
      },
    },
  ],
}

const mockQuantumMetricResults = {
  loading: false,
  "comparisonMetrics": [
    {
      "metricName": "number_of_calls_sspp",
      "metricId": "611ac62145fbb02561cd5639",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 0.7050739050181006,
      "meanB": 0.5324918150534611,
      "meanEffect": -0.24477163136566374,
      "meanEffectConfidenceInterval": {
        "lower": -0.25785849805346606,
        "upper": -0.23168476467786142,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": -0.17258208996463953,
      "meanDifferenceConfidenceInterval": {
        "lower": -0.1818092981646596,
        "upper": -0.16335488176461946,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 3.5276991716743364e-293,
      "subdimensionName": "mso",
      "subdimensionValue": "BHN",
      "lowerConfidenceInterval": -0.25785849805346606,
      "upperConfidenceInterval": -0.23168476467786142,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "number_of_calls_final_sspp",
      "metricId": "628fd7e635a9933d6af6d250",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 0.621122233936564,
      "meanB": 0.4647488230595793,
      "meanEffect": -0.2517594803939272,
      "meanEffectConfidenceInterval": {
        "lower": -0.25508438260248334,
        "upper": -0.24843457818537107,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": -0.15637341087698464,
      "meanDifferenceConfidenceInterval": {
        "lower": -0.15843858156438365,
        "upper": -0.15430824018958564,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 0,
      "subdimensionName": null,
      "subdimensionValue": null,
      "lowerConfidenceInterval": -0.25508438260248334,
      "upperConfidenceInterval": -0.24843457818537107,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "number_of_calls_final_sspp",
      "metricId": "628fd7e635a9933d6af6d250",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 0.6202130666891059,
      "meanB": 0.4640856496521188,
      "meanEffect": -0.25173190540864415,
      "meanEffectConfidenceInterval": {
        "lower": -0.2550634413483452,
        "upper": -0.24840036946894314,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": -0.15612741703698713,
      "meanDifferenceConfidenceInterval": {
        "lower": -0.15819367915893406,
        "upper": -0.1540611549150402,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 0,
      "subdimensionName": "mso",
      "subdimensionValue": null,
      "lowerConfidenceInterval": -0.2550634413483452,
      "upperConfidenceInterval": -0.24840036946894314,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "total_call_minutes_sspp",
      "metricId": "611ac62145fbb02561cd5629",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 10.229502702998408,
      "meanB": 6.976591565399733,
      "meanEffect": -0.3179930864718577,
      "meanEffectConfidenceInterval": {
        "lower": -0.32547740009865445,
        "upper": -0.31050877284506095,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": -3.252911137598675,
      "meanDifferenceConfidenceInterval": {
        "lower": -3.32947194407408,
        "upper": -3.17635033112327,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 0,
      "subdimensionName": "mso",
      "subdimensionValue": "TWC",
      "lowerConfidenceInterval": -0.32547740009865445,
      "upperConfidenceInterval": -0.31050877284506095,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "number_of_calls_sspp",
      "metricId": "611ac62145fbb02561cd5639",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 0.6150325812648311,
      "meanB": 0.4146184085481535,
      "meanEffect": -0.32585944033163317,
      "meanEffectConfidenceInterval": {
        "lower": -0.33190463164999057,
        "upper": -0.3198142490132758,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": -0.20041417271667755,
      "meanDifferenceConfidenceInterval": {
        "lower": -0.20413216233744663,
        "upper": -0.19669618309590847,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 0,
      "subdimensionName": "mso",
      "subdimensionValue": "TWC",
      "lowerConfidenceInterval": -0.33190463164999057,
      "upperConfidenceInterval": -0.3198142490132758,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "total_call_minutes_with_agent_sspp",
      "metricId": "611ac62045fbb02561cd55ff",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 10.470858334209655,
      "meanB": 7.347461995452154,
      "meanEffect": -0.2982942027353154,
      "meanEffectConfidenceInterval": {
        "lower": -0.30218223532422833,
        "upper": -0.29440617014640247,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": -3.1233963387575017,
      "meanDifferenceConfidenceInterval": {
        "lower": -3.1641073771947994,
        "upper": -3.082685300320204,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 0,
      "subdimensionName": null,
      "subdimensionValue": null,
      "lowerConfidenceInterval": -0.30218223532422833,
      "upperConfidenceInterval": -0.29440617014640247,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "number_of_calls_sspp",
      "metricId": "611ac62145fbb02561cd5639",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 0.5600918993925998,
      "meanB": 0.40792838587313146,
      "meanEffect": -0.2716759761826308,
      "meanEffectConfidenceInterval": {
        "lower": -0.2798368845832458,
        "upper": -0.2635150677820158,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": -0.15216351351946839,
      "meanDifferenceConfidenceInterval": {
        "lower": -0.15673437220633787,
        "upper": -0.1475926548325989,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 0,
      "subdimensionName": "mso",
      "subdimensionValue": "CHTR",
      "lowerConfidenceInterval": -0.2798368845832458,
      "upperConfidenceInterval": -0.2635150677820158,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "any_call_sspp",
      "metricId": "611ac62045fbb02561cd5619",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 0.2813490419332447,
      "meanB": 0.2098804334593941,
      "meanEffect": -0.25402115458707647,
      "meanEffectConfidenceInterval": {
        "lower": -0.257921863567906,
        "upper": -0.2501204456062469,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": -0.07146860847385061,
      "meanDifferenceConfidenceInterval": {
        "lower": -0.0725660692084674,
        "upper": -0.07037114773923382,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 0,
      "subdimensionName": "mso",
      "subdimensionValue": "TWC",
      "lowerConfidenceInterval": -0.257921863567906,
      "upperConfidenceInterval": -0.2501204456062469,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "total_call_minutes_with_agent_sspp",
      "metricId": "611ac62045fbb02561cd55ff",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 10.58716494458059,
      "meanB": 6.824814928085453,
      "meanEffect": -0.35536898085459856,
      "meanEffectConfidenceInterval": {
        "lower": -0.3708625985712782,
        "upper": -0.33987536313791894,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": -3.7623500164951365,
      "meanDifferenceConfidenceInterval": {
        "lower": -3.9263835028499,
        "upper": -3.5983165301403734,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 0,
      "subdimensionName": "mso",
      "subdimensionValue": "BH",
      "lowerConfidenceInterval": -0.3708625985712782,
      "upperConfidenceInterval": -0.33987536313791894,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "number_of_calls_final_sspp",
      "metricId": "628fd7e635a9933d6af6d250",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 0.6274913289754012,
      "meanB": 0.4345307716477403,
      "meanEffect": -0.3075111135682726,
      "meanEffectConfidenceInterval": {
        "lower": -0.3207532261165757,
        "upper": -0.29426900101996945,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": -0.19296055732766088,
      "meanDifferenceConfidenceInterval": {
        "lower": -0.20126986812903744,
        "upper": -0.1846512465262843,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 0,
      "subdimensionName": "mso",
      "subdimensionValue": "BH",
      "lowerConfidenceInterval": -0.3207532261165757,
      "upperConfidenceInterval": -0.29426900101996945,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "number_of_calls_sspp",
      "metricId": "611ac62145fbb02561cd5639",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 0.6851501849683188,
      "meanB": 0.4818791256112958,
      "meanEffect": -0.2966810252943626,
      "meanEffectConfidenceInterval": {
        "lower": -0.29987440723635017,
        "upper": -0.29348764335237504,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": -0.203271059357023,
      "meanDifferenceConfidenceInterval": {
        "lower": -0.2054590055852503,
        "upper": -0.20108311312879573,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 0,
      "subdimensionName": "mso",
      "subdimensionValue": null,
      "lowerConfidenceInterval": -0.29987440723635017,
      "upperConfidenceInterval": -0.29348764335237504,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "number_of_calls_final_sspp",
      "metricId": "628fd7e635a9933d6af6d250",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 0.5053365628297701,
      "meanB": 0.39304311004261827,
      "meanEffect": -0.22221517508714192,
      "meanEffectConfidenceInterval": {
        "lower": -0.23076119929618802,
        "upper": -0.2136691508780958,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": -0.11229345278715186,
      "meanDifferenceConfidenceInterval": {
        "lower": -0.11661207128681123,
        "upper": -0.1079748342874925,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 0,
      "subdimensionName": "mso",
      "subdimensionValue": "CHTR",
      "lowerConfidenceInterval": -0.23076119929618802,
      "upperConfidenceInterval": -0.2136691508780958,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "call_in_rate",
      "metricId": "5ea320c7ef609318226186cb",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 0.043045051400776,
      "meanB": 0.050561826373626925,
      "meanEffect": 0.17462576366479654,
      "meanEffectConfidenceInterval": {
        "lower": 0.16069760401871874,
        "upper": 0.18855392331087437,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": 0.007516774972850929,
      "meanDifferenceConfidenceInterval": {
        "lower": 0.006917236624967296,
        "upper": 0.008116313320734562,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 2.7342407938543037e-133,
      "subdimensionName": "mso",
      "subdimensionValue": "CHTR",
      "lowerConfidenceInterval": 0.16069760401871874,
      "upperConfidenceInterval": 0.18855392331087437,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "total_call_minutes_sspp",
      "metricId": "611ac62145fbb02561cd5629",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 11.3834802135823,
      "meanB": 7.369245307271238,
      "meanEffect": -0.35263687650824416,
      "meanEffectConfidenceInterval": {
        "lower": -0.3684184110519204,
        "upper": -0.33685534196456796,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": -4.014234906311063,
      "meanDifferenceConfidenceInterval": {
        "lower": -4.193883692528967,
        "upper": -3.834586120093159,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 0,
      "subdimensionName": "mso",
      "subdimensionValue": "BH",
      "lowerConfidenceInterval": -0.3684184110519204,
      "upperConfidenceInterval": -0.33685534196456796,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "call_in_rate",
      "metricId": "5ea320c7ef609318226186cb",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 0.06883470131460465,
      "meanB": 0.07851875223445821,
      "meanEffect": 0.14068559512727782,
      "meanEffectConfidenceInterval": {
        "lower": 0.12050592191775486,
        "upper": 0.1608652683368008,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": 0.009684050919853568,
      "meanDifferenceConfidenceInterval": {
        "lower": 0.008294989141849726,
        "upper": 0.01107311269785741,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 1.7255467213796168e-42,
      "subdimensionName": "mso",
      "subdimensionValue": "BHN",
      "lowerConfidenceInterval": 0.12050592191775486,
      "upperConfidenceInterval": 0.1608652683368008,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "number_of_calls_sspp",
      "metricId": "611ac62145fbb02561cd5639",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 0.6861236630974694,
      "meanB": 0.48250852134185124,
      "meanEffect": -0.29676157915383394,
      "meanEffectConfidenceInterval": {
        "lower": -0.29994889392218105,
        "upper": -0.2935742643854869,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": -0.20361514175561818,
      "meanDifferenceConfidenceInterval": {
        "lower": -0.20580203383992113,
        "upper": -0.20142824967131523,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 0,
      "subdimensionName": null,
      "subdimensionValue": null,
      "lowerConfidenceInterval": -0.29994889392218105,
      "upperConfidenceInterval": -0.2935742643854869,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "total_call_minutes_with_agent_sspp",
      "metricId": "611ac62045fbb02561cd55ff",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 9.466589787257696,
      "meanB": 6.442402437319873,
      "meanEffect": -0.3194590045518257,
      "meanEffectConfidenceInterval": {
        "lower": -0.3268155633068969,
        "upper": -0.31210244579675456,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": -3.024187349937823,
      "meanDifferenceConfidenceInterval": {
        "lower": -3.093828873917941,
        "upper": -2.9545458259577053,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 0,
      "subdimensionName": "mso",
      "subdimensionValue": "TWC",
      "lowerConfidenceInterval": -0.3268155633068969,
      "upperConfidenceInterval": -0.31210244579675456,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "total_call_minutes_with_agent_sspp",
      "metricId": "611ac62045fbb02561cd55ff",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 10.807702672409272,
      "meanB": 8.135317228829505,
      "meanEffect": -0.24726674341273622,
      "meanEffectConfidenceInterval": {
        "lower": -0.26327532546233984,
        "upper": -0.23125816136313257,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": -2.672385443579767,
      "meanDifferenceConfidenceInterval": {
        "lower": -2.8454014385787514,
        "upper": -2.4993694485807825,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 7.685461980076501e-201,
      "subdimensionName": "mso",
      "subdimensionValue": "BHN",
      "lowerConfidenceInterval": -0.26327532546233984,
      "upperConfidenceInterval": -0.23125816136313257,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "call_in_rate",
      "metricId": "5ea320c7ef609318226186cb",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 0.07588993972850383,
      "meanB": 0.07303883662456898,
      "meanEffect": -0.03756892038832384,
      "meanEffectConfidenceInterval": {
        "lower": -0.05646553990162254,
        "upper": -0.018672300875025118,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": -0.0028511031039348544,
      "meanDifferenceConfidenceInterval": {
        "lower": -0.004285166419871563,
        "upper": -0.0014170397879981456,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 0.00009755176957546122,
      "subdimensionName": "mso",
      "subdimensionValue": "BH",
      "lowerConfidenceInterval": -0.05646553990162254,
      "upperConfidenceInterval": -0.018672300875025118,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "number_of_calls_final_sspp",
      "metricId": "628fd7e635a9933d6af6d250",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 0.561091298961084,
      "meanB": 0.40237935170699063,
      "meanEffect": -0.2828629628510801,
      "meanEffectConfidenceInterval": {
        "lower": -0.28912138590119113,
        "upper": -0.27660453980096905,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": -0.15871194725409338,
      "meanDifferenceConfidenceInterval": {
        "lower": -0.16222349397272817,
        "upper": -0.15520040053545858,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 0,
      "subdimensionName": "mso",
      "subdimensionValue": "TWC",
      "lowerConfidenceInterval": -0.28912138590119113,
      "upperConfidenceInterval": -0.27660453980096905,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "call_in_rate",
      "metricId": "5ea320c7ef609318226186cb",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 0.04752388819654815,
      "meanB": 0.047995561745517494,
      "meanEffect": 0.009924978087201218,
      "meanEffectConfidenceInterval": {
        "lower": -0.003148385927445866,
        "upper": 0.0229983421018483,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": 0.00047167354896934105,
      "meanDifferenceConfidenceInterval": {
        "lower": -0.00014962354081552289,
        "upper": 0.0010929706387542049,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 0.1367620727126343,
      "subdimensionName": "mso",
      "subdimensionValue": "CHARTER",
      "lowerConfidenceInterval": -0.003148385927445866,
      "upperConfidenceInterval": 0.0229983421018483,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "call_in_rate",
      "metricId": "5ea320c7ef609318226186cb",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 0.02568715190331317,
      "meanB": 0.026413658258270432,
      "meanEffect": 0.028282869104828915,
      "meanEffectConfidenceInterval": {
        "lower": 0.020773476507457506,
        "upper": 0.035792261702200324,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": 0.0007265063549572633,
      "meanDifferenceConfidenceInterval": {
        "lower": 0.0005336114466069684,
        "upper": 0.0009194012633075581,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 1.5610426803234416e-13,
      "subdimensionName": "mso",
      "subdimensionValue": null,
      "lowerConfidenceInterval": 0.020773476507457506,
      "upperConfidenceInterval": 0.035792261702200324,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "number_of_calls_final_sspp",
      "metricId": "628fd7e635a9933d6af6d250",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 0.49536279209170037,
      "meanB": 0.33375067747131726,
      "meanEffect": -0.3262500074702136,
      "meanEffectConfidenceInterval": {
        "lower": -0.33457824257328656,
        "upper": -0.31792177236714064,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": -0.1616121146203831,
      "meanDifferenceConfidenceInterval": {
        "lower": -0.16573761241423743,
        "upper": -0.15748661682652879,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 0,
      "subdimensionName": "mso",
      "subdimensionValue": "CHARTER",
      "lowerConfidenceInterval": -0.33457824257328656,
      "upperConfidenceInterval": -0.31792177236714064,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "total_call_minutes_with_agent_sspp",
      "metricId": "611ac62045fbb02561cd55ff",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 8.66352659764518,
      "meanB": 6.442607845538687,
      "meanEffect": -0.25635273662230784,
      "meanEffectConfidenceInterval": {
        "lower": -0.2663603053991916,
        "upper": -0.24634516784542407,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": -2.2209187521064937,
      "meanDifferenceConfidenceInterval": {
        "lower": -2.3076195903827896,
        "upper": -2.1342179138301978,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 0,
      "subdimensionName": "mso",
      "subdimensionValue": "CHTR",
      "lowerConfidenceInterval": -0.2663603053991916,
      "upperConfidenceInterval": -0.24634516784542407,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "number_of_calls_sspp",
      "metricId": "611ac62145fbb02561cd5639",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 0.5484271219549002,
      "meanB": 0.34858401225970526,
      "meanEffect": -0.3643931922674477,
      "meanEffectConfidenceInterval": {
        "lower": -0.37237030848282465,
        "upper": -0.3564160760520707,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": -0.19984310969519492,
      "meanDifferenceConfidenceInterval": {
        "lower": -0.2042179765826939,
        "upper": -0.19546824280769595,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 0,
      "subdimensionName": "mso",
      "subdimensionValue": "CHARTER",
      "lowerConfidenceInterval": -0.37237030848282465,
      "upperConfidenceInterval": -0.3564160760520707,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "any_call_sspp",
      "metricId": "611ac62045fbb02561cd5619",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 0.3171740821802264,
      "meanB": 0.2309407619174257,
      "meanEffect": -0.27188009710642347,
      "meanEffectConfidenceInterval": {
        "lower": -0.2801959566707036,
        "upper": -0.26356423754214336,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": -0.0862333202628007,
      "meanDifferenceConfidenceInterval": {
        "lower": -0.08887089538764091,
        "upper": -0.08359574513796049,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 0,
      "subdimensionName": "mso",
      "subdimensionValue": "BH",
      "lowerConfidenceInterval": -0.2801959566707036,
      "upperConfidenceInterval": -0.26356423754214336,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "number_of_calls_final_sspp",
      "metricId": "628fd7e635a9933d6af6d250",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 0.6414211512870777,
      "meanB": 0.5195564596359074,
      "meanEffect": -0.18999169485857498,
      "meanEffectConfidenceInterval": {
        "lower": -0.20376001218651113,
        "upper": -0.1762233775306388,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": -0.12186469165117031,
      "meanDifferenceConfidenceInterval": {
        "lower": -0.13069598160294094,
        "upper": -0.11303340169939967,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 8.103787541071921e-161,
      "subdimensionName": "mso",
      "subdimensionValue": "BHN",
      "lowerConfidenceInterval": -0.20376001218651113,
      "upperConfidenceInterval": -0.1762233775306388,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "number_of_calls_sspp",
      "metricId": "611ac62145fbb02561cd5639",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 0.6914787641699448,
      "meanB": 0.45097666378565254,
      "meanEffect": -0.3478083678722837,
      "meanEffectConfidenceInterval": {
        "lower": -0.3604978826775398,
        "upper": -0.33511885306702754,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": -0.24050210038429226,
      "meanDifferenceConfidenceInterval": {
        "lower": -0.24927663039974696,
        "upper": -0.2317275703688375,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 0,
      "subdimensionName": "mso",
      "subdimensionValue": "BH",
      "lowerConfidenceInterval": -0.3604978826775398,
      "upperConfidenceInterval": -0.33511885306702754,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "any_call_sspp",
      "metricId": "611ac62045fbb02561cd5619",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 0.25348777306001713,
      "meanB": 0.20093270501307878,
      "meanEffect": -0.20732782261058064,
      "meanEffectConfidenceInterval": {
        "lower": -0.21270305128292372,
        "upper": -0.20195259393823756,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": -0.05255506804693835,
      "meanDifferenceConfidenceInterval": {
        "lower": -0.05391762279277895,
        "upper": -0.05119251330109775,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 0,
      "subdimensionName": "mso",
      "subdimensionValue": "CHTR",
      "lowerConfidenceInterval": -0.21270305128292372,
      "upperConfidenceInterval": -0.20195259393823756,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "total_call_minutes_sspp",
      "metricId": "611ac62145fbb02561cd5629",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 11.301492307462329,
      "meanB": 7.973879307652189,
      "meanEffect": -0.2944401419990288,
      "meanEffectConfidenceInterval": {
        "lower": -0.29839871065781004,
        "upper": -0.2904815733402476,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": -3.32761299981014,
      "meanDifferenceConfidenceInterval": {
        "lower": -3.3723507330559173,
        "upper": -3.282875266564363,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 0,
      "subdimensionName": null,
      "subdimensionValue": null,
      "lowerConfidenceInterval": -0.29839871065781004,
      "upperConfidenceInterval": -0.2904815733402476,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "any_call_sspp",
      "metricId": "611ac62045fbb02561cd5619",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 0.3201096055060312,
      "meanB": 0.2658611247817189,
      "meanEffect": -0.16946845640122526,
      "meanEffectConfidenceInterval": {
        "lower": -0.1779700706248387,
        "upper": -0.16096684217761184,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": -0.054248480724312265,
      "meanDifferenceConfidenceInterval": {
        "lower": -0.05696992909959762,
        "upper": -0.051527032349026905,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 0,
      "subdimensionName": "mso",
      "subdimensionValue": "BHN",
      "lowerConfidenceInterval": -0.1779700706248387,
      "upperConfidenceInterval": -0.16096684217761184,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "total_call_minutes_with_agent_sspp",
      "metricId": "611ac62045fbb02561cd55ff",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 8.468529269776862,
      "meanB": 5.477124145949656,
      "meanEffect": -0.3532378561296544,
      "meanEffectConfidenceInterval": {
        "lower": -0.36298997259803517,
        "upper": -0.3434857396612736,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": -2.9914051238272066,
      "meanDifferenceConfidenceInterval": {
        "lower": -3.073991207581962,
        "upper": -2.908819040072451,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 0,
      "subdimensionName": "mso",
      "subdimensionValue": "CHARTER",
      "lowerConfidenceInterval": -0.36298997259803517,
      "upperConfidenceInterval": -0.3434857396612736,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "any_call_sspp",
      "metricId": "611ac62045fbb02561cd5619",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 0.3019035838096494,
      "meanB": 0.23542621107552986,
      "meanEffect": -0.2201940496871796,
      "meanEffectConfidenceInterval": {
        "lower": -0.22222338457353913,
        "upper": -0.21816471480082006,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": -0.06647737273411952,
      "meanDifferenceConfidenceInterval": {
        "lower": -0.06709003620906141,
        "upper": -0.06586470925917763,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 0,
      "subdimensionName": "mso",
      "subdimensionValue": null,
      "lowerConfidenceInterval": -0.22222338457353913,
      "upperConfidenceInterval": -0.21816471480082006,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "total_call_minutes_sspp",
      "metricId": "611ac62145fbb02561cd5629",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 9.067823645673302,
      "meanB": 5.900789821103072,
      "meanEffect": -0.3492606327959824,
      "meanEffectConfidenceInterval": {
        "lower": -0.3591701046119223,
        "upper": -0.3393511609800426,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": -3.16703382457023,
      "meanDifferenceConfidenceInterval": {
        "lower": -3.2568911674189422,
        "upper": -3.0771764817215175,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 0,
      "subdimensionName": "mso",
      "subdimensionValue": "CHARTER",
      "lowerConfidenceInterval": -0.3591701046119223,
      "upperConfidenceInterval": -0.3393511609800426,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "total_call_minutes_with_agent_sspp",
      "metricId": "611ac62045fbb02561cd55ff",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 10.448124555675333,
      "meanB": 7.337756477380135,
      "meanEffect": -0.29769630537240027,
      "meanEffectConfidenceInterval": {
        "lower": -0.301592591312207,
        "upper": -0.29380001943259354,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": -3.110368078295198,
      "meanDifferenceConfidenceInterval": {
        "lower": -3.151076959098825,
        "upper": -3.069659197491571,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 0,
      "subdimensionName": "mso",
      "subdimensionValue": null,
      "lowerConfidenceInterval": -0.301592591312207,
      "upperConfidenceInterval": -0.29380001943259354,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "total_call_minutes_sspp",
      "metricId": "611ac62145fbb02561cd5629",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 11.62661618040551,
      "meanB": 8.788508392064163,
      "meanEffect": -0.2441043674533995,
      "meanEffectConfidenceInterval": {
        "lower": -0.2603994492552724,
        "upper": -0.2278092856515266,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": -2.838107788341347,
      "meanDifferenceConfidenceInterval": {
        "lower": -3.0275644500800336,
        "upper": -2.64865112660266,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 4.579949935248249e-189,
      "subdimensionName": "mso",
      "subdimensionValue": "BHN",
      "lowerConfidenceInterval": -0.2603994492552724,
      "upperConfidenceInterval": -0.2278092856515266,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "any_call_sspp",
      "metricId": "611ac62045fbb02561cd5619",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 0.25057061236295347,
      "meanB": 0.17646621391213604,
      "meanEffect": -0.2957425763220654,
      "meanEffectConfidenceInterval": {
        "lower": -0.3010088842236531,
        "upper": -0.2904762684204777,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": -0.07410439845081743,
      "meanDifferenceConfidenceInterval": {
        "lower": -0.07542398044661011,
        "upper": -0.07278481645502474,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 0,
      "subdimensionName": "mso",
      "subdimensionValue": "CHARTER",
      "lowerConfidenceInterval": -0.3010088842236531,
      "upperConfidenceInterval": -0.2904762684204777,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "call_in_rate",
      "metricId": "5ea320c7ef609318226186cb",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 0.05703211485824065,
      "meanB": 0.05376511308091313,
      "meanEffect": -0.05728354604152413,
      "meanEffectConfidenceInterval": {
        "lower": -0.06645586794103421,
        "upper": -0.04811122414201405,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": -0.0032670017773275203,
      "meanDifferenceConfidenceInterval": {
        "lower": -0.0037901186934171356,
        "upper": -0.0027438848612379055,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 1.8994402878973941e-34,
      "subdimensionName": "mso",
      "subdimensionValue": "TWC",
      "lowerConfidenceInterval": -0.06645586794103421,
      "upperConfidenceInterval": -0.04811122414201405,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "any_call_sspp",
      "metricId": "611ac62045fbb02561cd5619",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 0.3022281193361417,
      "meanB": 0.23563361913482617,
      "meanEffect": -0.22034514970874808,
      "meanEffectConfidenceInterval": {
        "lower": -0.22237008569295583,
        "upper": -0.21832021372454033,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": -0.06659450020131552,
      "meanDifferenceConfidenceInterval": {
        "lower": -0.0672064927955987,
        "upper": -0.06598250760703234,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 0,
      "subdimensionName": null,
      "subdimensionValue": null,
      "lowerConfidenceInterval": -0.22237008569295583,
      "upperConfidenceInterval": -0.21832021372454033,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "total_call_minutes_sspp",
      "metricId": "611ac62145fbb02561cd5629",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 11.279282501299056,
      "meanB": 7.962280000330362,
      "meanEffect": -0.29407921120750974,
      "meanEffectConfidenceInterval": {
        "lower": -0.2980467239688489,
        "upper": -0.2901116984461706,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": -3.3170025009686936,
      "meanDifferenceConfidenceInterval": {
        "lower": -3.361753198231347,
        "upper": -3.27225180370604,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 0,
      "subdimensionName": "mso",
      "subdimensionValue": null,
      "lowerConfidenceInterval": -0.2980467239688489,
      "upperConfidenceInterval": -0.2901116984461706,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "total_call_minutes_sspp",
      "metricId": "611ac62145fbb02561cd5629",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 9.286342567588154,
      "meanB": 6.946094075377458,
      "meanEffect": -0.25200970943919254,
      "meanEffectConfidenceInterval": {
        "lower": -0.2621853254049049,
        "upper": -0.2418340934734802,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": -2.340248492210696,
      "meanDifferenceConfidenceInterval": {
        "lower": -2.43474274790452,
        "upper": -2.2457542365168717,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 0,
      "subdimensionName": "mso",
      "subdimensionValue": "CHTR",
      "lowerConfidenceInterval": -0.2621853254049049,
      "upperConfidenceInterval": -0.2418340934734802,
      "__typename": "ComparisonMetric"
    },
    {
      "metricName": "call_in_rate",
      "metricId": "5ea320c7ef609318226186cb",
      "applicationCoords": "SpecNet@Web",
      "denverDate": "2022-06-21",
      "experimentId": "ca731888af694dca97e9c4161b531be0",
      "variantAId": "d2cd863a32654de0965b6c5b82876e3d",
      "variantBId": "5cf42c48f3e84c609df8ed839c6a508a",
      "meanA": 0.023465827247688344,
      "meanB": 0.023690831628130606,
      "meanEffect": 0.009588597839201575,
      "meanEffectConfidenceInterval": {
        "lower": 0.001969735727016848,
        "upper": 0.017207459951386304,
        "__typename": "ConfidenceInterval"
      },
      "differenceInMeans": 0.0002250043804422619,
      "meanDifferenceConfidenceInterval": {
        "lower": 0.00004622147829377716,
        "upper": 0.00040378728259074666,
        "__typename": "ConfidenceInterval"
      },
      "pValue": 0.013637173020347122,
      "subdimensionName": null,
      "subdimensionValue": null,
      "lowerConfidenceInterval": 0.001969735727016848,
      "upperConfidenceInterval": 0.017207459951386304,
      "__typename": "ComparisonMetric"
    }
  ],
  "metricDefinitions": [
    {
      "_id": "608862eead379a1040f7af66",
      "name": "idm_ad_hoc_analysis",
      "displayName": "IDM Ad Hoc Analysis",
      "description": "Ad hoc analysis performed by the Experimentation Data Science team",
      "calculation": " ",
      "direction": "POSITIVE",
      "unit": {
        "name": "Minutes",
        "abbreviation": "min",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5f1b43bac3b95d39978bf2d5",
      "name": "auto_pay_submit_rate",
      "displayName": "Auto-Pay Submit Rate",
      "description": "Percentage of accounts submitting auto-pay enrollmnet at least once during the experiment",
      "calculation": "Number of accounts or devices submitting auto-pay enrollment at least once divided by the number of accounts or devices in the variant",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5edab1e2ceec950bdf4006b0",
      "name": "paperless_toggle_notifications",
      "displayName": "Paperless toggle from Notifications card",
      "description": "Number of accounts flipping toggle  with beginning state of “FALSE” at least once during experiment divided by Number of activated accounts (those who reach Billing and Order page)",
      "calculation": "accounts_with_positive_toggles / total_accounts",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5eb33636e99cef74db16c441",
      "name": "ad_hoc_analysis",
      "displayName": "Ad Hoc Analysis",
      "description": "Ad hoc analysis performed by the Experimentation Data Science team",
      "calculation": " ",
      "direction": "POSITIVE",
      "unit": {
        "name": "Minutes",
        "abbreviation": "min",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5edab1e2ceec950bdf4006b4",
      "name": "uncheck_paperless_box",
      "displayName": "Un-Check paperless box from Account Status Dashboard",
      "description": "Number of accounts un-checking paperless enrollment box in account status dashboard at least once during experiment divided by Number of activated accounts (those visiting account status dashboard)",
      "calculation": "accounts_with_negative_check / total_accounts",
      "direction": "NEGATIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5edab1e2ceec950bdf4006af",
      "name": "paperless_uncheck_manage",
      "displayName": "Paperless un-check from the Notifications -> Manage tab",
      "description": "Number of accounts unchecking paperless on manage Notification -> Manage page at least once during experiment divided by Number of activated accounts",
      "calculation": "accounts_unchecking_paperless / total_accounts",
      "direction": "NEGATIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5f079353c3b95d39978bebe0",
      "name": "buyflow_ad_hoc_analysis",
      "displayName": "Buy Flow Ad Hoc Analysis",
      "description": "Ad hoc analysis performed by the Experimentation Data Science team",
      "calculation": " ",
      "direction": "POSITIVE",
      "unit": {
        "name": "Minutes",
        "abbreviation": "min",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5f29c8dcc3b95d39978c3d38",
      "name": "buyflow_fallout_rate",
      "displayName": "Buyflow Fallout Rate",
      "description": "Percentage of accounts who complete the buyflow purchase event but never make it to the biller and therefore don't connect to a video package",
      "calculation": "COUNT(purchase_group = fallout) / COUNT(purchase_group) WHERE purchase_group IS NOT NULL",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5f357bbee981530bcd5622b7",
      "name": "buyflow_channel_addon_conversion_rate",
      "displayName": "Buyflow Channel Add-on Conversion Rate",
      "description": "Accounts who have a purchase success on buyflow WITH either an a-la-carte or bundled channel addon compared to total accounts with purchase success",
      "calculation": "Number of accounts with a buyflow purchase success who also purchased either an a-la-carte or bundled channel addon divided by total accounts with a buyflow purchase success",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5f357bbee981530bcd5622b6",
      "name": "buyflow_cdvr_addon_conversion_rate",
      "displayName": "Buyflow cDVR Add-on Conversion Rate",
      "description": "Accounts who have a purchase success on buyflow WITH a cDVR addon compared to total accounts with purchase success",
      "calculation": "Number of accounts with a buyflow purchase success who also purchased cDVR add-on divided by total accounts with a buyflow purchase success",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5f723a4693c6582ef0cbb355",
      "name": "cms_ad_hoc_analysis",
      "displayName": "CMS Ad Hoc Analysis",
      "description": "Ad hoc analysis performed by the Experimentation Data Science team",
      "calculation": " ",
      "direction": "POSITIVE",
      "unit": {
        "name": "Minutes",
        "abbreviation": "min",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5f690a68f42ecb0bf5f5dfc8",
      "name": "call_in_rate_cms",
      "displayName": "Call In Rate",
      "description": " Number of visits with a call divided by total number of authenticated visits",
      "calculation": "COUNT(visits_with_a_call)/COUNT(total_authenticated_visits)",
      "direction": "NEGATIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "611ac61f45fbb02561cd5279",
      "name": "internet_7_day_survival_cms",
      "displayName": "7-Day internet Survival",
      "description": "Percent of accounts surviving with a Charter internet account, 7 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "611ac62145fbb02561cd580a",
      "name": "video_30_day_survival_sspp",
      "displayName": "30-Day Video Survival",
      "description": "Percent of accounts surviving with a Charter video account, 30 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "611ac61f45fbb02561cd528d",
      "name": "internet_30_day_survival_cms",
      "displayName": "30-Day Internet Survival",
      "description": "Percent of accounts surviving with a Charter internet account, 30 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "611ac61f45fbb02561cd52ed",
      "name": "internet_14_day_survival_cms",
      "displayName": "14-Day Internet Survival",
      "description": "Percent of accounts surviving with a Charter internet account, 14 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "611ac61f45fbb02561cd5324",
      "name": "video_7_day_survival_cms",
      "displayName": "7-Day Video Survival",
      "description": "Percent of accounts surviving with a Charter video account, 7 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5fb57bc11d934f7d89ae2db5",
      "name": "buyflow_persistence_90_day",
      "displayName": "Buyflow Persistence Rate 90 Day",
      "description": "Percentage of accounts who have survived after ninety days compared to the number of total buyflow entries",
      "calculation": "count(accounts_with_service_for_90_days)/count(buyflow_entries)",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5fb57bc11d934f7d89ae2db6",
      "name": "buyflow_persistence_60_day",
      "displayName": "Buyflow Persistence Rate 60 Day",
      "description": "Percentage of accounts who have survived after sixty days compared to the number of total buyflow entries",
      "calculation": "count(accounts_with_service_for_60_days)/count(buyflow_entries)",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "6111547345fbb02561cc682a",
      "name": "any_promo_tile_capture",
      "displayName": "Accounts Clicking Support Home Promo Tile",
      "description": "Accounts Clicking on Any Promo Tile on the Support Home Page at Least Once",
      "calculation": "MAX(PageClick)",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "61143c6645fbb02561cccd6f",
      "name": "video_60_day_survival",
      "displayName": "60-Day Video Survival",
      "description": "Percent of accounts surviving with a Charter video account, 14 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "611ac61f45fbb02561cd5185",
      "name": "video_30_day_survival_cms",
      "displayName": "30-Day Video Survival",
      "description": "Percent of accounts surviving with a Charter video account, 30 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "6111547345fbb02561cc67f8",
      "name": "msa_download_capture",
      "displayName": "Accounts Clicking Download MSA Tile",
      "description": "Accounts Clicking on Download MSA Tile on the Support Homepage at Least Once",
      "calculation": "MAX(PageClick)",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "6111547345fbb02561cc680d",
      "name": "community_forums_capture",
      "displayName": "Accounts Clicking Community Forums Tile",
      "description": "Accounts Clicking on Community Forums Tile on Support Home Page at Least Once",
      "calculation": "MAX(PageClick)",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "6111547345fbb02561cc6825",
      "name": "any_action_tile_capture",
      "displayName": "Accounts Clicking Support Home Action Tile",
      "description": "Accounts Clicking on Any Action Tile on the Support Home Page At Least Once",
      "calculation": "MAX(PageClick)",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "61143c6545fbb02561cccc52",
      "name": "video_7_day_survival",
      "displayName": "7-Day Video Survival",
      "description": "Percent of accounts surviving with a Charter video account, 7 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "61143c6645fbb02561cccdea",
      "name": "internet_14_day_survival",
      "displayName": "14-Day Internet Survival",
      "description": "Percent of accounts surviving with a Charter internet account, 14 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "611ac62145fbb02561cd57ca",
      "name": "internet_30_day_survival_sspp",
      "displayName": "30-Day Internet Survival",
      "description": "Percent of accounts surviving with a Charter internet account, 30 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "61143c6645fbb02561cccd70",
      "name": "internet_60_day_survival",
      "displayName": "60-Day Internet Survival",
      "description": "Percent of accounts surviving with a Charter internet account, 60 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "61143c6645fbb02561ccccc5",
      "name": "voice_30_day_survival",
      "displayName": "30-Day Voice Survival",
      "description": "Percent of accounts surviving with a Charter voice account, 30 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "61143c6545fbb02561cccc24",
      "name": "voice_60_day_survival",
      "displayName": "60-Day Voice Survival",
      "description": "Percent of accounts surviving with a Charter voice account, 60 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "61143c6645fbb02561cccd6c",
      "name": "internet_30_day_survival",
      "displayName": "30-Day Internet Survival",
      "description": "Percent of accounts surviving with a Charter internet account, 30 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "61143c6645fbb02561ccccc2",
      "name": "voice_7_day_survival",
      "displayName": "7-Day Voice Survival",
      "description": "Percent of accounts surviving with a Charter voice account, 7 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "61143c6645fbb02561cccc83",
      "name": "internet_7_day_survival",
      "displayName": "7-Day internet Survival",
      "description": "Percent of accounts surviving with a Charter internet account, 7 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "61143c6645fbb02561cccde9",
      "name": "video_30_day_survival",
      "displayName": "30-Day Video Survival",
      "description": "Percent of accounts surviving with a Charter video account, 30 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "611ac61f45fbb02561cd5195",
      "name": "voice_30_day_survival_cms",
      "displayName": "30-Day Voice Survival",
      "description": "Percent of accounts surviving with a Charter voice account, 30 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "61143c6645fbb02561cccccc",
      "name": "video_14_day_survival",
      "displayName": "14-Day Video Survival",
      "description": "Percent of accounts surviving with a Charter video account, 14 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "61143c6645fbb02561ccccc6",
      "name": "voice_14_day_survival",
      "displayName": "14-Day Voice Survival",
      "description": "Percent of accounts surviving with a Charter voice account, 14 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "611ac61f45fbb02561cd52fd",
      "name": "voice_14_day_survival_cms",
      "displayName": "14-Day Voice Survival",
      "description": "Percent of accounts surviving with a Charter voice account, 14 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "611ac61f45fbb02561cd52ab",
      "name": "voice_7_day_survival_cms",
      "displayName": "7-Day Voice Survival",
      "description": "Percent of accounts surviving with a Charter voice account, 7 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "611ac62145fbb02561cd5771",
      "name": "video_14_day_survival_sspp",
      "displayName": "14-Day Video Survival",
      "description": "Percent of accounts surviving with a Charter video account, 14 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "611ac61f45fbb02561cd51c4",
      "name": "voice_60_day_survival_cms",
      "displayName": "60-Day Voice Survival",
      "description": "Percent of accounts surviving with a Charter voice account, 60 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "611ac62145fbb02561cd568d",
      "name": "internet_14_day_survival_sspp",
      "displayName": "14-Day Internet Survival",
      "description": "Percent of accounts surviving with a Charter internet account, 14 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "611ac62145fbb02561cd56f3",
      "name": "video_60_day_survival_sspp",
      "displayName": "60-Day Video Survival",
      "description": "Percent of accounts surviving with a Charter video account, 14 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "611ac62145fbb02561cd57df",
      "name": "internet_60_day_survival_sspp",
      "displayName": "60-Day Internet Survival",
      "description": "Percent of accounts surviving with a Charter internet account, 60 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "611ac61f45fbb02561cd526a",
      "name": "internet_60_day_survival_cms",
      "displayName": "60-Day Internet Survival",
      "description": "Percent of accounts surviving with a Charter internet account, 60 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "611ac62145fbb02561cd56f5",
      "name": "voice_60_day_survival_sspp",
      "displayName": "60-Day Voice Survival",
      "description": "Percent of accounts surviving with a Charter voice account, 60 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "611ac62145fbb02561cd57e1",
      "name": "voice_14_day_survival_sspp",
      "displayName": "14-Day Voice Survival",
      "description": "Percent of accounts surviving with a Charter voice account, 14 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "611ac62145fbb02561cd5773",
      "name": "voice_30_day_survival_sspp",
      "displayName": "30-Day Voice Survival",
      "description": "Percent of accounts surviving with a Charter voice account, 30 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "611ac61f45fbb02561cd51fd",
      "name": "video_60_day_survival_cms",
      "displayName": "60-Day Video Survival",
      "description": "Percent of accounts surviving with a Charter video account, 14 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "611ac61f45fbb02561cd51a5",
      "name": "video_14_day_survival_cms",
      "displayName": "14-Day Video Survival",
      "description": "Percent of accounts surviving with a Charter video account, 14 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "611ac62245fbb02561cd581d",
      "name": "voice_7_day_survival_sspp",
      "displayName": "7-Day Voice Survival",
      "description": "Percent of accounts surviving with a Charter voice account, 7 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "611ac62145fbb02561cd5743",
      "name": "video_7_day_survival_sspp",
      "displayName": "7-Day Video Survival",
      "description": "Percent of accounts surviving with a Charter video account, 7 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "611ac62145fbb02561cd56f7",
      "name": "internet_7_day_survival_sspp",
      "displayName": "7-Day internet Survival",
      "description": "Percent of accounts surviving with a Charter internet account, 7 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5e4ef630ff87a22d17da23cb",
      "name": "buyflow_survival_rate_30_day",
      "displayName": "Buyflow Survival Rate 30 Day",
      "description": "Number of accounts with service after 30 days divided by number of buy flow orders",
      "calculation": "COUNT(accounts_with_service_for_30_days) / COUNT(purchase_successes > 0)\n    WHERE page_name in ('stream2SignUp','signUp')",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5e4ef60aff87a22d17da23ca",
      "name": "buyflow_survival_rate_14_day",
      "displayName": "Buyflow Survival Rate 14 Day",
      "description": "Number of accounts with service after 14 days divided by number of buy flow orders",
      "calculation": "COUNT(accounts_with_service_for_14_days) / COUNT(purchase_successes > 0)\n    WHERE page_name in ('stream2SignUp','signUp')",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5e4ef655ff87a22d17da23cc",
      "name": "buyflow_survival_rate_60_day",
      "displayName": "Buyflow Survival Rate 60 Day",
      "description": "Number of accounts with service after 60 days divided by number of buy flow orders",
      "calculation": "COUNT(accounts_with_service_for_60_days) / COUNT(purchase_successes > 0)\n    WHERE page_name in ('stream2SignUp','signUp')",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5e3c314767e0940c18b72ffb",
      "name": "buyflow_conversion_rate",
      "displayName": "Buyflow Conversion Rate",
      "description": "Number of successful purchases divided by number of buy flow entrances",
      "calculation": "COUNT(purchase_successes > 0) / COUNT(buyflow_orders)",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "6100988745fbb02561cbb20c",
      "name": "digital_bill_compare_statements",
      "displayName": "Digital Bill Compare Statements Click Rate",
      "description": "Percent of Page Views Comparing Statements on the Digital Bill",
      "calculation": "SUM(PageClicks)/SUM(PageViews)",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "6100988745fbb02561cbb1d7",
      "name": "digital_bill_comparison_toggle",
      "displayName": "Bill Comparison Toggle Click Rate",
      "description": "Percent of Page Views Clicking Comparison Toggle",
      "calculation": "SUM(PageClicks)/SUM(PageViews)",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "628fd99635a9933d6af6d268",
      "name": "any_call_final",
      "displayName": "Any Call (Final)",
      "description": "Whether or not an enrolled account called during the experiment with the most recent 7 days of incomplete data removed",
      "calculation": "max(1 -  yes call, 0 – no call)",
      "direction": "NEGATIVE",
      "unit": {
        "name": "Count",
        "abbreviation": "count",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "624f40676df9e646896c1bb0",
      "name": "msa_visiting_sspp",
      "displayName": "Accounts Visiting MSA",
      "description": "The percentage of accounts who visit MSA at least once after activating into the experiment",
      "calculation": "account_max_message_timestamp > experiment_activation_timestamp",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "62a2282d8f890a05f1a70f0e",
      "name": "msa_adoption_cms",
      "displayName": "MSA Adoption",
      "description": "Total minutes spent on a call for an an enrolled account with the most recent 7 days of incomplete data removed",
      "calculation": "account_max_message_timestamp > experiment_activation_timestamp where account not previously visited MSA within 15 days prior to experiment start",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "611ac62145fbb02561cd5639",
      "name": "number_of_calls_sspp",
      "displayName": "Number of Calls",
      "description": "Sum of calls for an enrolled account",
      "calculation": "sum(count_distinct_calls)",
      "direction": "NEGATIVE",
      "unit": {
        "name": "Count",
        "abbreviation": "count",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "6094457502665054a1e07d11",
      "name": "total_call_minutes",
      "displayName": "Total Call Minutes",
      "description": "Total minutes spent on a call for an an enrolled account",
      "calculation": "sum(call_length_minutes)",
      "direction": "NEGATIVE",
      "unit": {
        "name": "Minutes",
        "abbreviation": "min",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "609444cd02665054a1e07d10",
      "name": "any_call",
      "displayName": "Any Call",
      "description": "Whether or not an enrolled account called during the experiment",
      "calculation": "max(1 -  yes call, 0 – no call)",
      "direction": "NEGATIVE",
      "unit": {
        "name": "Count",
        "abbreviation": "count",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "6111547345fbb02561cc682c",
      "name": "any_home_tile_capture",
      "displayName": "Accounts Clicking Support Home Tile",
      "description": "Accounts Clicking on Any Tile on the Support Home Page at Least Once",
      "calculation": "MAX(PageClick)",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5e7cddd424007e5fc0bdb6fe",
      "name": "tactic_persistence_rate_14_day",
      "displayName": "Tactic Persistence Rate 14 Day",
      "description": "Number of accounts with service after 14 days divided by number of tactic entries",
      "calculation": "COUNT(accounts_with_service_for_14_days) / COUNT(tactic_entries)",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5fb57bc11d934f7d89ae2db4",
      "name": "buyflow_persistence_30_day",
      "displayName": "Buyflow Persistence Rate 30 Day",
      "description": "Percentage of accounts who have survived after thirty days compared to the number of total buyflow entries",
      "calculation": "count(accounts_with_service_for_30_days)/count(buyflow_entries)",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5e7cde0024007e5fc0bdb6ff",
      "name": "tactic_persistence_rate_30_day",
      "displayName": "Tactic Persistence Rate 30 Day",
      "description": "Number of accounts with service after 30 days divided by number of tactic entries",
      "calculation": "COUNT(accounts_with_service_for_30_days) / COUNT(tactic_entries)",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "624f3ffe6df9e646896c1ba6",
      "name": "msa_adoption_sspp",
      "displayName": "MSA Adoption",
      "description": "Total minutes spent on a call for an an enrolled account with the most recent 7 days of incomplete data removed",
      "calculation": "account_max_message_timestamp > experiment_activation_timestamp where account not previously visited MSA within 15 days prior to experiment start",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "609445e902665054a1e07d12",
      "name": "number_of_calls",
      "displayName": "Number of Calls",
      "description": "Sum of calls for an enrolled account",
      "calculation": "sum(count_distinct_calls)",
      "direction": "NEGATIVE",
      "unit": {
        "name": "Count",
        "abbreviation": "count",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "616db0e2d03bce216a5c76b1",
      "name": "distillery_training",
      "displayName": "Distillery Training",
      "description": "metric for Distillery Training",
      "calculation": " ",
      "direction": "POSITIVE",
      "unit": {
        "name": "Minutes",
        "abbreviation": "min",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "612400f7a762f0460f705739",
      "name": "internet_14_day_survival_stva",
      "displayName": "14-Day Internet Survival",
      "description": "Percent of accounts surviving with a Charter internet account, 14 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "60aeaaf6c16520129f19f88c",
      "name": "total_call_minutes_with_agent",
      "displayName": "Total Call Minutes with Agent",
      "description": "Total minutes spent on a call with an agent for an an enrolled account",
      "calculation": "sum(segment_end - segment_start)",
      "direction": "NEGATIVE",
      "unit": {
        "name": "Minutes",
        "abbreviation": "min",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "628fdb9f35a9933d6af6d280",
      "name": "total_call_minutes_final",
      "displayName": "Total Call Minutes (Final)",
      "description": "Total minutes spent on a call for an an enrolled account with the most recent 7 days of incomplete data removed",
      "calculation": "sum(segment_end - segment_start)",
      "direction": "NEGATIVE",
      "unit": {
        "name": "Count",
        "abbreviation": "count",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "612400f7a762f0460f705633",
      "name": "internet_7_day_survival_stva",
      "displayName": "7-Day internet Survival",
      "description": "Percent of accounts surviving with a Charter internet account, 7 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "6100988745fbb02561cbb21f",
      "name": "digital_bill_upgrade_tv_click",
      "displayName": "Digital Bill TV Upgrade Click Rate",
      "description": "Percent of  Digital Bill Views Clicking on Upgrade TV",
      "calculation": "SUM(PageClicks)/SUM(PageViews)",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "612400f7a762f0460f705658",
      "name": "voice_30_day_survival_stva",
      "displayName": "30-Day Voice Survival",
      "description": "Percent of accounts surviving with a Charter voice account, 30 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "612400f7a762f0460f705794",
      "name": "internet_60_day_survival_stva",
      "displayName": "60-Day Internet Survival",
      "description": "Percent of accounts surviving with a Charter internet account, 60 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "6100988745fbb02561cbb1e4",
      "name": "digital_bill_line_item_click",
      "displayName": "Digital Bill Line Item Click Rate",
      "description": "Percent of Page Views Clicking on a Line Item Explanation on Digital Bill",
      "calculation": "SUM(PageClicks)/SUM(PageViews)",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "6100988745fbb02561cbb1f3",
      "name": "digital_bill_upgrade_internet_click",
      "displayName": "Digital Bill Internet Upgrade Click Rate",
      "description": "Percent of  Digital Bill Views Clicking on Upgrade Internet",
      "calculation": "SUM(PageClicks)/SUM(PageViews)",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "6100988745fbb02561cbb1fe",
      "name": "digital_bill_line_item_modal_dismiss",
      "displayName": "Digital Bill Line Item Modal Dismiss Rate",
      "description": "Percent of Line Item Modal Viewers Clicking Dismiss",
      "calculation": "SUM(PageClicks)/SUM(PageViews)",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "6100988745fbb02561cbb1ca",
      "name": "digital_bill_collapse_all",
      "displayName": "Digital Bill Collapse All Click Rate",
      "description": "Percent of Page Views Collapsing All Items on the Digital Bill",
      "calculation": "SUM(PageClicks)/SUM(PageViews)",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "6100988745fbb02561cbb21c",
      "name": "digital_bill_expand_or_collapse",
      "displayName": "Digital Bill Collapse/Expand Click Rate",
      "description": "Percent of Page Views Collapsing or Expanding Items on the Digital Bill",
      "calculation": "SUM(PageClicks)/SUM(PageViews)",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5de93c45c22b6d0bcf8cd70f",
      "name": "average_daily_visits",
      "displayName": "Average Daily Visits",
      "description": "Total number of visits divided by total days of tenure.",
      "calculation": "COUNT(distinct visit_id) / total_days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Average",
        "abbreviation": "avg",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5e9727fa8e30e202fde07380",
      "name": "ipvideo_survival_30_day",
      "displayName": "IP Video 30 Day Survival",
      "description": "Number of accounts with a video package after 30 days of experiment tenure divided by the total number of accounts",
      "calculation": "COUNT(accounts_with_video_package_for_30_days) / COUNT(total_accounts)",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5f7b38cc0df2f403f279e812",
      "name": "linear_average_daily_visits",
      "displayName": "Linear Average Daily Visits",
      "description": "Total number of visits with a linear stream divided by total days of tenure.",
      "calculation": "COUNT(distinct visit_id) WHERE playback_type = linear/ total_days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Average",
        "abbreviation": "avg",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5ccba09f2982f88211a1c133",
      "name": "mos",
      "displayName": "MOS",
      "description": "Average MOS score for each stream.",
      "calculation": "SUM(mos_score) / COUNT(stream_id)\nWHERE grouping_id = 1\n   AND mos_score IS NOT NULL",
      "direction": "POSITIVE",
      "unit": {
        "name": "Average",
        "abbreviation": "avg",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5f7b38cb0df2f403f279e80f",
      "name": "content_consumed",
      "displayName": "Content Consumed",
      "description": "Average content consumed per device per day.",
      "calculation": "SUM(watch_time_ms) / total_days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Minutes per day",
        "abbreviation": "min/day",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5de93c44c22b6d0bcf8cd70c",
      "name": "average_watch_time_per_visit",
      "displayName": "Average Watch Time Per Visit",
      "description": "Total watch time in minutes divided by total number of visits.",
      "calculation": "SUM(total_minutes) / COUNT(distinct visit_id)",
      "direction": "POSITIVE",
      "unit": {
        "name": "Minutes",
        "abbreviation": "min",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5f7b38cc0df2f403f279e814",
      "name": "linear_content_consumed",
      "displayName": "Linear Content Consumed",
      "description": "Average linear content consumed per device per day.",
      "calculation": "SUM(watch_time_ms) where playback_type = linear / total_days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Minutes per day",
        "abbreviation": "min/day",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5f7b38cb0df2f403f279e810",
      "name": "vod_average_watch_time_per_visit",
      "displayName": "VOD Average Watch Time Per Visit",
      "description": "Total VOD watch time in minutes divided by total number of visits.",
      "calculation": "SUM(total_minutes) where playback_type = vod / COUNT(distinct visit_id)",
      "direction": "POSITIVE",
      "unit": {
        "name": "Minutes",
        "abbreviation": "min",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5f7b38cc0df2f403f279e815",
      "name": "vod_average_daily_visits",
      "displayName": "VOD Average Daily Visits",
      "description": "Total number of visits with a VOD stream divided by total days of tenure.",
      "calculation": "COUNT(distinct visit_id) WHERE playback_type = vod/ total_days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Average",
        "abbreviation": "avg",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5f7b38cb0df2f403f279e80c",
      "name": "vod_content_consumed",
      "displayName": "VOD Content Consumed",
      "description": "Average VOD content consumed per device per day.",
      "calculation": "SUM(watch_time_ms) where playback_type = vod / total_days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Minutes per day",
        "abbreviation": "min/day",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5edab1e2ceec950bdf4006b3",
      "name": "paperless_toggle_bill_delivery",
      "displayName": "Paperless toggle from Bill Delivery Card",
      "description": "Number of accounts flipping toggle  with beginning state of “FALSE” at least once during experiment divided by Number of activated accounts (those who reach billing home page)",
      "calculation": "accounts_with_positive_toggles / total_accounts",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5f1b43bac3b95d39978bf2da",
      "name": "auto_pay_success_wo_payment_rate",
      "displayName": "Auto-Pay Enrollment Success w/o Payment Rate",
      "description": "Percentage of accounts successfully enrolling in auto-pay without payment at least once during the experiment",
      "calculation": "Number of accounts or devices successfully enrolling in auto-pay without a payment at least once divided by the number of accounts or devices in the variant",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "6111547345fbb02561cc6841",
      "name": "msa_download_clickthrough",
      "displayName": "Download MSA Tile Clickthrough Rate",
      "description": "Click Through Rate for the Download MSA Tile on Support Home",
      "calculation": "SUM(PageClicks)/SUM(PageViews)",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5edab1e2ceec950bdf4006b2",
      "name": "paperless_check_manage",
      "displayName": "Paperless check from the Notifications-> Manage tab",
      "description": "Number of accounts checking paperless on Notification -> Manage page at least once during experiment divided by Number of activated accounts (those who reach Billing and Order page)",
      "calculation": "accounts_checking_paperless / total_accounts",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5e7cdd4624007e5fc0bdb6fd",
      "name": "tactic_conversion_rate",
      "displayName": "Tactic Conversion Rate",
      "description": "Number of buy flow orders divided by number of tactic entries",
      "calculation": "COUNT(buyflow_orders) / COUNT(tactic_entries)",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5ccba09f2982f809b2a1c131",
      "name": "content_consumed_ms",
      "displayName": "Content Consumed",
      "description": "Average content consumed per device per day.",
      "calculation": "SUM(watch_time_ms) / total_days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Minutes per day",
        "abbreviation": "min/day",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5edab1e2ceec950bdf4006ad",
      "name": "paperless_untoggle_bill_delivery",
      "displayName": "Paperless un-toggle from Bill Delivery Card",
      "description": "Number of accounts clicking on Get a Paper Bill in modal on Billing Home Page at least once during experiment divided by Number of activated accounts",
      "calculation": "accounts_with_negative_toggles / total_accounts",
      "direction": "NEGATIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5e7cde0b24007e5fc0bdb700",
      "name": "tactic_persistence_rate_60_day",
      "displayName": "Tactic Persistence Rate 60 Day",
      "description": "Number of accounts with service after 60 days divided by number of tactic entries",
      "calculation": "COUNT(accounts_with_service_for_60_days) / COUNT(tactic_entries)",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5edab1e2ceec950bdf4006ae",
      "name": "paperless_saves",
      "displayName": "Accounts Selecting Keep Paperless Billing in Modal",
      "description": "Number of accounts clicking on Keep Paperless in Modal  at least once during experiment divided by Number of activated accounts",
      "calculation": "accounts_that_clicked_keeppaperless_in_modal/accounts_accessing_modal",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5f1b43bac3b95d39978bf2d6",
      "name": "auto_pay_failure_per_submit",
      "displayName": "Auto-Pay Failure per Submit",
      "description": "Per Account Average: Number of auto-pay enrollment failures divided by the number of auto-pay submits",
      "calculation": "Number of auto-pay failures divided by the number of auto-pay submits, averaged across accounts.",
      "direction": "NEGATIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5f1b43bac3b95d39978bf2d3",
      "name": "auto_pay_success_wo_payment_per_start",
      "displayName": "Auto-Pay Success w/o Payment per Start Rate",
      "description": "Per-account average: The count of successful completes of the auto-pay process without payment divided by the number of starts of the process during the experiment",
      "calculation": "Number of successful auto-pay enrollments without a payment divided by the number of auto-pay starts, averaged across accounts.",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5f1b43bac3b95d39978bf2d4",
      "name": "auto_pay_success_w_payment_per_start",
      "displayName": "Auto-Pay Success with Payment per Start Rate",
      "description": "Per-account average: The count of successful completes of the auto-pay process with payment divided by the number of starts of the process during the experiment",
      "calculation": "Number of successful auto-pay enrollments with a payment divided by the number of auto-pay starts, averaged across accounts.",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5f1b43bac3b95d39978bf2d2",
      "name": "auto_pay_failure_per_start",
      "displayName": "Auto-Pay Failure per Start",
      "description": "Per Account Average: Number of auto-pay enrollment failures divided by the number of auto-pay  starts",
      "calculation": "Number of auto-pay failures divided by the number of auto-pay starts, averaged across accounts.",
      "direction": "NEGATIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5f1b43bac3b95d39978bf2d1",
      "name": "auto_pay_success_per_start",
      "displayName": "Auto-Pay Success per Start Rate",
      "description": "Per-account average: The count of successful completes of the auto-pay process divided by the number of starts of the process during the experiment",
      "calculation": "Number of successful auto-pay enrollments divided by the number of auto-pay starts, averaged across accounts.",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5f1b43bac3b95d39978bf2d7",
      "name": "auto_pay_success_w_payment_rate",
      "displayName": "Auto-Pay Enrollment Success with Payment Rate",
      "description": "Percentage of accounts successfully enrolling in auto-pay with payment at least once during the experiment",
      "calculation": "Number of accounts or devices successfully enrolling in auto-pay with a payment at least once divided by the number of accounts or devices in the variant",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5f1b43bac3b95d39978bf2d9",
      "name": "auto_pay_success_rate",
      "displayName": "Auto-Pay Success Rate",
      "description": "Percentage of accounts successfully enrolling in auto-pay at least once during the experiment",
      "calculation": "Number of accounts or devices successfully enrolling in auto-pay at least once divided by the number of accounts or devices in the variant",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5f1b43bac3b95d39978bf2d8",
      "name": "auto_pay_start_rate",
      "displayName": "Auto-Pay Start Rate",
      "description": "Percentage of accounts or devices starting the auto-pay process at least once during the experiment",
      "calculation": "Number of accounts or devices starting auto-pay process at least once divided by the number of accounts or devices in the variant",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5f079353c3b95d39978bebde",
      "name": "sspp_ad_hoc_analysis",
      "displayName": "SSPP Ad Hoc Analysis",
      "description": "Ad hoc analysis performed by the Experimentation Data Science team",
      "calculation": " ",
      "direction": "POSITIVE",
      "unit": {
        "name": "Minutes",
        "abbreviation": "min",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "611ac62145fbb02561cd5629",
      "name": "total_call_minutes_sspp",
      "displayName": "Total Call Minutes",
      "description": "Total minutes spent on a call for an an enrolled account",
      "calculation": "sum(call_length_minutes)",
      "direction": "NEGATIVE",
      "unit": {
        "name": "Minutes",
        "abbreviation": "min",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "62a228e88f890a05f1a70f33",
      "name": "msa_visiting_cms",
      "displayName": "Accounts Visiting MSA",
      "description": "The percentage of accounts who visit MSA at least once after activating into the experiment",
      "calculation": "account_max_message_timestamp > experiment_activation_timestamp",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "612400f7a762f0460f705796",
      "name": "voice_60_day_survival_stva",
      "displayName": "60-Day Voice Survival",
      "description": "Percent of accounts surviving with a Charter voice account, 60 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "612400f7a762f0460f70573c",
      "name": "voice_7_day_survival_stva",
      "displayName": "7-Day Voice Survival",
      "description": "Percent of accounts surviving with a Charter voice account, 7 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "612400f7a762f0460f7056a1",
      "name": "internet_30_day_survival_stva",
      "displayName": "30-Day Internet Survival",
      "description": "Percent of accounts surviving with a Charter internet account, 30 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "612400f7a762f0460f7057f3",
      "name": "voice_14_day_survival_stva",
      "displayName": "14-Day Voice Survival",
      "description": "Percent of accounts surviving with a Charter voice account, 14 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "612400f7a762f0460f705799",
      "name": "video_7_day_survival_stva",
      "displayName": "7-Day Video Survival",
      "description": "Percent of accounts surviving with a Charter video account, 7 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5ccba09f2982f8c230a1c12f",
      "name": "average_bit_rate_mbps",
      "displayName": "Average Bit Rate",
      "description": "Average Bitrate over all streams per target device weighted by stream length.",
      "calculation": "SUM(bandwidth_consumed_mb) / (SUM(watch_time_ms) / 1e3)\nWHERE grouping_id = 1\n   AND watch_time_ms > 1000\n   AND bandwidth_consumed_mb IS NOT NULL",
      "direction": "POSITIVE",
      "unit": {
        "name": "Megabits per second",
        "abbreviation": "mbps",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5de93c44c22b6d0bcf8cd70d",
      "name": "average_weekly_days_of_viewing",
      "displayName": "Average Weekly Days With Viewing",
      "description": "Total number of days with streaming activity divided by total weeks in experiment.",
      "calculation": "SUM(active_day)/(7 * CEILING(SUM(total_days/7))) * 7\nWHERE active_day = CASE\n   WHEN MAX(watch_time_ms) > 30000 THEN 1 \n   ELSE 0\nEND\n(GROUPING BY visit_id)",
      "direction": "POSITIVE",
      "unit": {
        "name": "Average Days",
        "abbreviation": "avg days",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "612400f7a762f0460f7056ee",
      "name": "video_14_day_survival_stva",
      "displayName": "14-Day Video Survival",
      "description": "Percent of accounts surviving with a Charter video account, 14 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "612400f7a762f0460f70575a",
      "name": "video_30_day_survival_stva",
      "displayName": "30-Day Video Survival",
      "description": "Percent of accounts surviving with a Charter video account, 30 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5f7b38cc0df2f403f279e816",
      "name": "linear_percent_visits_with_engaged_streams",
      "displayName": "Linear % of Visits With an Engaged Stream",
      "description": "Percent of app visits with at least one linear stream more than 5 minutes.",
      "calculation": "SUM(has_engaged_stream) WHERE playback_type = linear / COUNT(distinct visit_id)",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "612400f7a762f0460f7056f5",
      "name": "video_60_day_survival_stva",
      "displayName": "60-Day Video Survival",
      "description": "Percent of accounts surviving with a Charter video account, 14 days afer activation",
      "calculation": "(disconnect date - first day user is in experiment) > survival days",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5edab1e2ceec950bdf4006b1",
      "name": "check_paperless_box",
      "displayName": "Check paperless box from Account Status Dashboard",
      "description": "Number of accounts checking paperless enrollment box in account status dashboard at least once during experiment divided by Number of activated accounts (those visiting account status dashboard)",
      "calculation": "accounts_with_positive_check / total_accounts",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "624f2e646df9e646896bf4be",
      "name": "significant_downshift_rate",
      "displayName": "Significant Downshift Rate",
      "description": "# of downshifts where percent change exceeds 70% divided by watch time",
      "calculation": "avg(per70_downshift_rate) WHERE grouping_id = 1",
      "direction": "NEGATIVE",
      "unit": {
        "name": "Average",
        "abbreviation": "avg",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5ea320c7ef609318226186cb",
      "name": "call_in_rate",
      "displayName": "Call In Rate",
      "description": " Number of visits with a call divided by total number of authenticated visits",
      "calculation": "COUNT(visits_with_a_call)/COUNT(total_authenticated_visits)",
      "direction": "NEGATIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "6282c71c7e76527268636551",
      "name": "call_in_rate_final",
      "displayName": "Call In Rate (Final)",
      "description": "Number of visits with a call divided by total number of authenticated visits with the most recent 7 days of incomplete call data removed",
      "calculation": "COUNT(visits_with_a_call)/COUNT(total_authenticated_visits)",
      "direction": "NEGATIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "611ac62045fbb02561cd5619",
      "name": "any_call_sspp",
      "displayName": "Any Call",
      "description": "Whether or not an enrolled account called during the experiment",
      "calculation": "max(1 -  yes call, 0 – no call)",
      "direction": "NEGATIVE",
      "unit": {
        "name": "Count",
        "abbreviation": "count",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "628fdbf235a9933d6af6d299",
      "name": "total_call_minutes_with_agent_final",
      "displayName": "Total Call Minutes with Agent (Final)",
      "description": "Total minutes spent on a call for an an enrolled account with the most recent 7 days of incomplete data removed",
      "calculation": "sum(segment_end - segment_start)",
      "direction": "NEGATIVE",
      "unit": {
        "name": "Count",
        "abbreviation": "count",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "611ac61f45fbb02561cd5288",
      "name": "number_of_calls_cms",
      "displayName": "Number of Calls",
      "description": "Sum of calls for an enrolled account",
      "calculation": "sum(count_distinct_calls)",
      "direction": "NEGATIVE",
      "unit": {
        "name": "Count",
        "abbreviation": "count",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "60da4922cdaf851f46abaa4b",
      "name": "storm_center_click_through_rate",
      "displayName": "Storm Center Click Through Rate",
      "description": "Click Through Rate of Storm Center Promo Tile on Support Home",
      "calculation": "SUM(PageClicks)/SUM(PageViews)",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "6111547345fbb02561cc6854",
      "name": "any_home_tile_clickthrough",
      "displayName": "Support Home Tile Clickthrough Rate",
      "description": "Click Through Rate for Any Tile on the Support Home Page",
      "calculation": "SUM(PageClicks)/SUM(PageViews)",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "611ac61f45fbb02561cd515f",
      "name": "total_call_minutes_with_agent_cms",
      "displayName": "Total Call Minutes with Agent",
      "description": "Total minutes spent on a call with an agent for an an enrolled account",
      "calculation": "sum(segment_end - segment_start)",
      "direction": "POSITIVE",
      "unit": {
        "name": "Minutes",
        "abbreviation": "min",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "6111547345fbb02561cc6852",
      "name": "any_action_tile_clickthrough",
      "displayName": "Support Home Action Tile Clickthrough Rate",
      "description": "Click Through Rate for Any Action Tile on the Support Home Page",
      "calculation": "SUM(PageClicks)/SUM(PageViews)",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "6111547345fbb02561cc67e6",
      "name": "community_forums_clickthrough",
      "displayName": "Community Forums Tile Clickthrough Rate",
      "description": "Click Through Rate of Community Forums Tile on Support Home Page",
      "calculation": "SUM(PageClicks)/SUM(PageViews)",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "62b3746e3c4f4d05ec614fd0",
      "name": "app_entry",
      "displayName": "App Entry",
      "description": "percentage of visits with a critical API failure over the total number of visits with a login success",
      "calculation": "avg(app_entry_performance)",
      "direction": "NEGATIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "6111547345fbb02561cc67fa",
      "name": "any_promo_tile_clickthrough",
      "displayName": "Support Home Promo Tile Clickthrough Rate",
      "description": "Click Through Rate for Any Promo Tile on the Support Home Page",
      "calculation": "SUM(PageClicks)/SUM(PageViews)",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "611ac61f45fbb02561cd5176",
      "name": "total_call_minutes_cms",
      "displayName": "Total Call Minutes",
      "description": "Total minutes spent on a call for an an enrolled account",
      "calculation": "sum(call_length_minutes)",
      "direction": "NEGATIVE",
      "unit": {
        "name": "Minutes",
        "abbreviation": "min",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "624f2e4b6df9e646896bf483",
      "name": "weighted_mos4",
      "displayName": "MOS 4.0 - WEIGHTED",
      "description": "Mean Opinion Score v4, but weighted by stream length",
      "calculation": "CASE WHEN SUM(bitrate_content_elapsed_ms) = 0 THEN 0 ELSE SUM(mos_scores_map['MOS4_0-mos_score'] * bitrate_content_elapsed_ms) / SUM(bitrate_content_elapsed_ms) END WHERE grouping_id = 1 AND bitrate_content_elapsed_ms IS NOT NULL AND mos_scores_map['MOS4_0-mos_score'] IS NOT NULL",
      "direction": "POSITIVE",
      "unit": {
        "name": "Average",
        "abbreviation": "avg",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5f079353c3b95d39978bebdf",
      "name": "ipvideo_ad_hoc_analysis",
      "displayName": "IPVideo Ad Hoc Analysis",
      "description": "Ad hoc analysis performed by the Experimentation Data Science team",
      "calculation": " ",
      "direction": "POSITIVE",
      "unit": {
        "name": "Minutes",
        "abbreviation": "min",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5ccba09f2982f81ba7a1c134",
      "name": "stream_failure_rate",
      "displayName": "Broken Stream Failure Rate",
      "description": "Fraction of all streams that break after initialization during playback on target device.",
      "calculation": "CASE\n   WHEN SUM(stream_init_starts) = 0 THEN 0\n   ELSE\n      SUM(stream_noninit_failures) /\n         SUM(stream_init_starts)\nEND\nWHERE grouping_id = 1",
      "direction": "NEGATIVE",
      "unit": {
        "name": "Average",
        "abbreviation": "avg",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5ccba09f2982f84f79a1c136",
      "name": "tune_time_ms",
      "displayName": "Tune Time",
      "description": "Average tune time over all streams per target device.",
      "calculation": "SUM(tune_time_ms) / COUNT(stream_id)\nWHERE grouping_id = 1\n   AND tune_time_ms IS NOT NULL",
      "direction": "NEGATIVE",
      "unit": {
        "name": "Milliseconds",
        "abbreviation": "ms",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5de93c44c22b6d0bcf8cd70e",
      "name": "percent_visits_with_engaged_streams",
      "displayName": "% of Visits With an Engaged Stream",
      "description": "Percent of app visits with at least one stream more than 5 minutes.",
      "calculation": "SUM(has_engaged_stream) / COUNT(distinct visit_id)",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5ccba09f2982f825dba1c137",
      "name": "weighted_mos",
      "displayName": "MOS - Weighted",
      "description": "Average MOS score weighted by stream length.",
      "calculation": "CASE\n   WHEN SUM(watch_time_ms) = 0 THEN 0\n   ELSE\n      SUM(mos_score * watch_time_ms) /\n         SUM(watch_time_ms)\nEND\nWHERE grouping_id = 1\n   AND watch_time_ms IS NOT NULL\n   AND mos_score IS NOT NULL",
      "direction": "POSITIVE",
      "unit": {
        "name": "Average",
        "abbreviation": "avg",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5f7b38cb0df2f403f279e80b",
      "name": "linear_average_watch_time_per_visit",
      "displayName": "Linear Average Watch Time Per Visit",
      "description": "Total linear watch time in minutes divided by total number of visits.",
      "calculation": "SUM(total_minutes) where playback_type = linear / COUNT(distinct visit_id)",
      "direction": "POSITIVE",
      "unit": {
        "name": "Minutes",
        "abbreviation": "min",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5ccba09f2982f85aa6a1c135",
      "name": "stream_init_failure_rate",
      "displayName": "Stream Init Failure Rate",
      "description": "Fraction of all play events that result in initialization failure on target device.",
      "calculation": "CASE\n   WHEN SUM(stream_init_starts) = 0\n      AND SUM(stream_init_failures) = 0 THEN 0\n   ELSE\n      SUM(stream_init_failures) / \n         (SUM(stream_init_starts) +\n            SUM(stream_init_failures))\nEND\nWHERE grouping_id = 1",
      "direction": "NEGATIVE",
      "unit": {
        "name": "Average",
        "abbreviation": "avg",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5f7b38cb0df2f403f279e80e",
      "name": "vod_percent_visits_with_engaged_streams",
      "displayName": "VOD % of Visits With an Engaged Stream",
      "description": "Percent of app visits with at least one VOD stream more than 5 minutes.",
      "calculation": "SUM(has_engaged_stream) WHERE playback_type = vod / COUNT(distinct visit_id)",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5ccba09f2982f8b259a1c132",
      "name": "level2_downshifts_per_hour",
      "displayName": "Level 2 Downshifts Per Hour",
      "description": "Level 2 Down Shifts per hour of streamed content on target device.",
      "calculation": "CASE\n   WHEN SUM(watch_time_ms) = 0 THEN 0\n   ELSE\n      SUM(COALESCE(level2_downshifts, 0)) /\n         (SUM(watch_time_ms) / (60 * 60 * 1e3))\nEND\nWHERE grouping_id = 1",
      "direction": "NEGATIVE",
      "unit": {
        "name": "Count",
        "abbreviation": "count",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5ccba09f2982f86967a1c130",
      "name": "buffering_ratio",
      "displayName": "Buffering Ratio",
      "description": "Average buffering ratio over all streams per target device weighted by stream length.",
      "calculation": "CASE\n   WHEN SUM(watch_time_ms) = 0\n      AND SUM(buffering_duration_ms) = 0 THEN 0\n   ELSE\n      SUM(buffering_duration_ms) / \n         (SUM(watch_time_ms) + SUM(buffering_duration_ms))\nEND\nWHERE grouping_id = 1\n   AND watch_time_ms > 0\n   AND buffering_duration_ms > 0",
      "direction": "NEGATIVE",
      "unit": {
        "name": "Average",
        "abbreviation": "avg",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5f7b38cb0df2f403f279e80d",
      "name": "vod_average_weekly_days_of_viewing",
      "displayName": "VOD Average Weekly Days With Viewing",
      "description": "Total number of days with VOD streaming activity divided by total weeks in experiment.",
      "calculation": "SUM(active_day)/(7 * CEILING(SUM(total_days/7))) * 7\\nWHERE active_day = CASE\\n   WHEN MAX(watch_time_ms) > 30000  AND playback_type = vod THEN 1 \\n   ELSE 0\\nEND\\n(GROUPING BY visit_id)",
      "direction": "POSITIVE",
      "unit": {
        "name": "Average Days",
        "abbreviation": "avg days",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5f7b38cb0df2f403f279e811",
      "name": "percent_households_with_engaged_vod_streams",
      "displayName": "% of Households With an Engaged VOD Stream",
      "description": "Percent of accounts with at least one VOD stream more than 5 minutes",
      "calculation": "Count(has_engaged_stream) WHERE playback_type = vod / total accounts",
      "direction": "POSITIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "5f7b38cc0df2f403f279e813",
      "name": "linear_average_weekly_days_of_viewing",
      "displayName": "Linear Average Weekly Days With Viewing",
      "description": "Total number of days with linear streaming activity divided by total weeks in experiment.",
      "calculation": "SUM(active_day)/(7 * CEILING(SUM(total_days/7))) * 7\\nWHERE active_day = CASE\\n   WHEN MAX(watch_time_ms) > 30000  AND playback_type = linear THEN 1 \\n   ELSE 0\\nEND\\n(GROUPING BY visit_id)",
      "direction": "POSITIVE",
      "unit": {
        "name": "Average Days",
        "abbreviation": "avg days",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "624dc0196df9e646896b036c",
      "name": "mos4",
      "displayName": "MOS 4.0",
      "description": "Average MOS score for each stream.",
      "calculation": "SUM(mos_scores_map['MOS4_0-mos_score']) / COUNT(stream_id) WHERE grouping_id = 1 AND  mos_scores_map['MOS4_0-mos_score'] IS NOT NULL",
      "direction": "POSITIVE",
      "unit": {
        "name": "Average",
        "abbreviation": "avg",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "628fd7e635a9933d6af6d250",
      "name": "number_of_calls_final",
      "displayName": "Number of Calls (Final)",
      "description": "Sum of calls for an enrolled account with the most recent 7 days of incomplete data removed",
      "calculation": "sum(count_distinct_calls)",
      "direction": "NEGATIVE",
      "unit": {
        "name": "Count",
        "abbreviation": "count",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "611ac62045fbb02561cd55ff",
      "name": "total_call_minutes_with_agent_sspp",
      "displayName": "Total Call Minutes with Agent",
      "description": "Total minutes spent on a call with an agent for an an enrolled account",
      "calculation": "sum(segment_end - segment_start)",
      "direction": "NEGATIVE",
      "unit": {
        "name": "Minutes",
        "abbreviation": "min",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "611ac61f45fbb02561cd51b5",
      "name": "any_call_cms",
      "displayName": "Any Call",
      "description": "Whether or not an enrolled account called during the experiment",
      "calculation": "max(1 -  yes call, 0 – no call)",
      "direction": "NEGATIVE",
      "unit": {
        "name": "Count",
        "abbreviation": "count",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "6282c71c7e76527268636551",
      "name": "call_in_rate_final",
      "displayName": "Call In Rate (Final)",
      "description": "Number of visits with a call divided by total number of authenticated visits with the most recent 7 days of incomplete call data removed",
      "calculation": "COUNT(visits_with_a_call)/COUNT(total_authenticated_visits)",
      "direction": "NEGATIVE",
      "unit": {
        "name": "Percentage",
        "abbreviation": "%",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "628fd7e635a9933d6af6d250",
      "name": "number_of_calls_final",
      "displayName": "Number of Calls (Final)",
      "description": "Sum of calls for an enrolled account with the most recent 7 days of incomplete data removed",
      "calculation": "sum(count_distinct_calls)",
      "direction": "NEGATIVE",
      "unit": {
        "name": "Count",
        "abbreviation": "count",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "628fd99635a9933d6af6d268",
      "name": "any_call_final",
      "displayName": "Any Call (Final)",
      "description": "Whether or not an enrolled account called during the experiment with the most recent 7 days of incomplete data removed",
      "calculation": "max(1 -  yes call, 0 – no call)",
      "direction": "NEGATIVE",
      "unit": {
        "name": "Count",
        "abbreviation": "count",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "628fdb9f35a9933d6af6d280",
      "name": "total_call_minutes_final",
      "displayName": "Total Call Minutes (Final)",
      "description": "Total minutes spent on a call for an an enrolled account with the most recent 7 days of incomplete data removed",
      "calculation": "sum(segment_end - segment_start)",
      "direction": "NEGATIVE",
      "unit": {
        "name": "Count",
        "abbreviation": "count",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    },
    {
      "_id": "628fdbf235a9933d6af6d299",
      "name": "total_call_minutes_with_agent_final",
      "displayName": "Total Call Minutes with Agent (Final)",
      "description": "Total minutes spent on a call for an an enrolled account with the most recent 7 days of incomplete data removed",
      "calculation": "sum(segment_end - segment_start)",
      "direction": "NEGATIVE",
      "unit": {
        "name": "Count",
        "abbreviation": "count",
        "__typename": "MetricUnit"
      },
      "__typename": "MetricDefinition"
    }
  ]
}

export {
  mockExperimentVariantsData,
  mockExperimentMetricsData,
  mockQuantumMetricResults,
}
