import { gql } from '@apollo/client'

/*
* Sample Input
* 1
*/
const CANCEL_CONTENT_MAP = gql`
  mutation cancelContentMap($id: Int!) {
    cancelContentMap(id: $id) {
      id
      startTime
      stopTime
    }
  }
`

export default CANCEL_CONTENT_MAP
