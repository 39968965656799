import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

export const GET_NOTIFICATION_DEFINITIONS = gql`
  query getNotificationDefinitions {
    notificationDefinitions {
      id
      name
      emailAvailable
      inAppAvailable
      smsAvailable
    }
  }
`

const getNotificationDefinitions = graphql(GET_NOTIFICATION_DEFINITIONS, {
  name: 'notificationDefinitionsData',
})

export default getNotificationDefinitions
