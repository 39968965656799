import { gql } from '@apollo/client'

export const GET_USER_PROFILE = gql`
  query getUserProfile {
    userProfileSchema {
      id
      name
      type
      description
      example
      values {
        id
        name
        enum
        type
        description
        properties {
          id
          name
          enum
          type
          description
          properties {
            id
            name
            enum
            type
            description
          }
        }
      }
    }
  }
`
