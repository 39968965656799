const getAllZaValues = {
  query: `
    zaValues {
      id
      variantAmount
      zaValue
    }`,
  variables: {},
};

export default getAllZaValues;
