const mockUser = {
  id: 'P123C00K13',
  role: 'ADMIN',
  firstName: 'Cookie',
  lastName: 'Monster',
  displayName: 'Monster, Cookie',
  email: 'c-monster@charter.com',
  config: JSON.stringify({
    filters: {
      product: [
        {
          name: 'video',
          selectedFilters: ['Samsung', 'Android', 'Roku'],
        },
      ],
      feature: [],
      experimenter: ['Grouch, Oscar', 'Frog, Kermit', 'Monster, Cookie'],
    },
  }),
}

const mockMatch = {
  path: '/',
  params: {},
}

const mockHistory = {
  location: {
    pathname: '/experiments/123/test/set up/plan',
  },
  replace: path => {
    mockHistory.location.pathname = path
  },
  push: path => {
    mockHistory.location.pathname = path
  },
}

const mockEvent = {
  preventDefault: () => {},
}

const mockInitialStepState = {
  activeStep: 'plan',
  setUpSteps: [
    {
      label: 'plan',
      status: 'initial',
      kiteIcon: 'description',
    },
    {
      label: 'variants',
      status: 'initial',
      kiteIcon: 'dashboard',
    },
    {
      label: 'metrics',
      status: 'initial',
      kiteIcon: 'chart',
    },
    {
      label: 'tech settings',
      status: 'initial',
      kiteIcon: 'settings-f',
    },
    {
      label: 'activation',
      status: 'initial',
      kiteIcon: 'power',
    },
  ],
}

const mockExperimentDetailsData = {
  variables: { id: 6 },
  loading: false,
  networkStatus: 7,
  experiment: {
    id: 6,
    name: 'rgeregrw',
    config:
      '{"setUpSteps":{"plan":{"order":0,"status":"complete"},"variants":{"order":2,"status":"initial"},"metrics":{"order":3,"status":"initial"},"tech settings":{"order":4,"status":"initial"},"activation":{"order":5,"status":"initial"}}}',
    isFinalized: false,
    isTechPocApproved: false,
    experimentStatus: {
      id: 1,
      name: 'DRAFT',
    },
    experimentType: {
      id: 1,
      name: 'AA',
    },
    experimentToProductFeatures: [
      {
        id: 6,
        productId: 1,
        productFeatureId: 1,
        product: { id: 1, displayName: 'Android' },
        productFeature: { id: 1, displayName: 'Other' },
      },
    ],
    environmentSamplings: [
      {
        id: 39,
        tdcsUpdateRequired: true,
        environment: { id: 1, name: 'PRODUCTION' },
        experimentStatus: {
          id: 1,
          name: 'DRAFT',
        },
      },
      {
        id: 38,
        tdcsUpdateRequired: true,
        environment: { id: 2, name: 'ENGPROD' },
        experimentStatus: {
          id: 1,
          name: 'DRAFT',
        },
      },
      {
        id: 37,
        tdcsUpdateRequired: true,
        environment: { id: 3, name: 'ENGNEW' },
        experimentStatus: {
          id: 1,
          name: 'DRAFT',
        },
      },
      {
        id: 36,
        tdcsUpdateRequired: true,
        environment: { id: 4, name: 'DEVELOPMENT' },
        experimentStatus: {
          id: 1,
          name: 'DRAFT',
        },
      },
    ],
  },
  experimentPermissions: [
    { id: 1, name: 'EXPERIMENT CREATE' },
    { id: 2, name: 'EXPERIMENT READ' },
    { id: 3, name: 'EXPERIMENT UPDATE' },
    { id: 4, name: 'EXPERIMENT DELETE' },
    { id: 5, name: 'EXPERIMENT TERMINATE' },
    { id: 6, name: 'PUBLISH DEVELOPMENT' },
    { id: 7, name: 'PUBLISH ENGNEW' },
    { id: 8, name: 'PUBLISH ENGPROD' },
    { id: 9, name: 'PUBLISH PRODUCTION' },
    { id: 10, name: 'ALLOCATION DEVELOPMENT' },
    { id: 11, name: 'ALLOCATION ENGNEW' },
    { id: 12, name: 'ALLOCATION ENGPROD' },
    { id: 13, name: 'ALLOCATION PRODUCTION' },
    { id: 14, name: 'OVERRIDE CREATE' },
    { id: 15, name: 'OVERRIDE READ' },
    { id: 16, name: 'OVERRIDE UPDATE' },
    { id: 17, name: 'OVERRIDE DELETE' },
    { id: 28, name: 'EXPERIMENT UPDATE RUNNING' },
    { id: 1, name: 'EXPERIMENT CREATE' },
    { id: 2, name: 'EXPERIMENT READ' },
    { id: 3, name: 'EXPERIMENT UPDATE' },
    { id: 4, name: 'EXPERIMENT DELETE' },
    { id: 5, name: 'EXPERIMENT TERMINATE' },
    { id: 6, name: 'PUBLISH DEVELOPMENT' },
    { id: 7, name: 'PUBLISH ENGNEW' },
    { id: 8, name: 'PUBLISH ENGPROD' },
    { id: 9, name: 'PUBLISH PRODUCTION' },
    { id: 10, name: 'ALLOCATION DEVELOPMENT' },
    { id: 11, name: 'ALLOCATION ENGNEW' },
    { id: 12, name: 'ALLOCATION ENGPROD' },
    { id: 13, name: 'ALLOCATION PRODUCTION' },
    { id: 14, name: 'OVERRIDE CREATE' },
    { id: 15, name: 'OVERRIDE READ' },
    { id: 16, name: 'OVERRIDE UPDATE' },
    { id: 17, name: 'OVERRIDE DELETE' },
    { id: 18, name: 'NOTIFICATION CREATE' },
    { id: 19, name: 'NOTIFICATION READ' },
    { id: 20, name: 'NOTIFICATION UPDATE' },
    { id: 21, name: 'NOTIFICATION DELETE' },
    { id: 22, name: 'SUBSCRIPTION CREATE' },
    { id: 23, name: 'SUBSCRIPTION READ' },
    { id: 24, name: 'SUBSCRIPTION UPDATE' },
    { id: 25, name: 'SUBSCRIPTION DELETE' },
    { id: 26, name: 'USER ADMINISTRATION' },
    { id: 27, name: 'SUBSCRIPTION ADMINISTRATION' },
    { id: 28, name: 'EXPERIMENT UPDATE RUNNING' },
    { id: 29, name: 'EXPERIMENT APPROVE TECH' },
    { id: 30, name: 'TECH UPDATE' },
    { id: 31, name: 'ACTIVATION UPDATE' },
    { id: 32, name: 'EXPERIMENT UPDATE ALL RUNNING' },
  ],
}

export {
  mockUser,
  mockMatch,
  mockHistory,
  mockInitialStepState,
  mockEvent,
  mockExperimentDetailsData,
}
