import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

export const GET_ROLES = gql`
  query getRoles {
    roles {
      id
      name
      permissions {
        id
        name
        permissionTypeId
      }
      users {
        id
        displayName
        userToRoles {
          id
          role {
            id
            name
          }
        }
      }
    }
  }
`

const getRoles = graphql(GET_ROLES, {
  name: 'allRolesData',
})

export default getRoles
