import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

export const GET_EXPERIMENT_CONTENT = gql`
  query experimentContent($id: Int!) {
    experimentContent(experimentId: $id) {
      contentAreaId
      contentId
      contentJson
    }
    experiment(id: $id) {
      id
      experimentTypeId
      experimentStatusId
      config
      variants {
        id
        uuid
        name
        description
        url
        jsonPayload
        displayOrder
        variantUploads {
          id
          fileUrl
        }
      }
    }
  }
`

const getExperimentContent = graphql(GET_EXPERIMENT_CONTENT, {
  name: 'experimentContentData',
  withRef: true,
  options: {
    fetchPolicy: 'network-only',
  },
})

export default getExperimentContent
