import React, { Component } from 'react'
import { object, number, array, func, bool } from 'prop-types'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { KiteButton } from '@kite/react-kite'
import {
  LinearProgressStepper,
  SmartExpansionPanel,
} from '@kite/react-kite-plus'
import {
  getSelectedEnvironment,
  experimentEnvironmentEnum,
  hasPermission,
  permissionEnum,
  roleEnum,
  roleEnumReverse,
} from '@charter/distillery-rules'

import initialSetUpSteps from '../../shared/data/initialSetUpSteps'
import AllocationHistory from '../AllocationHistory/AllocationHistory'
import { ColoredSelect, CopyButton } from '../../componentLibrary'
import EndExperimentButton from '../EndExperimentButton/EndExperimentButton'
import './ExperimentDetails.scss'

const { PRODUCTION } = experimentEnvironmentEnum
const {
  EXPERIMENT_TERMINATE,
  EXPERIMENT_CLONE,
  EXPERIMENT_DELETE,
  EXPERIMENT_DELETE_TESTING,
} = permissionEnum

class ExperimentDetails extends Component {
  static propTypes = {
    id: number.isRequired,
    applicationPermissions: array.isRequired,
    userRoles: array.isRequired,
    experiment: object.isRequired,
    history: object.isRequired,
    onSuccess: func.isRequired,
    onNavigate: func.isRequired,
    onOpenExperiment: func.isRequired,
    activeNetworkLockdown: bool,
    handleNextExperiments: func,
  }

  state = {
    selectedEnvironment: getSelectedEnvironment(
      this.props.experiment,
      PRODUCTION
    ),
  }

  // LOCAL STATE TOGGLES/CHANGES
  handleSelectEnvironment = selectedEnvironment => {
    this.setState({ selectedEnvironment })
  }

  handleSelectStep = nextStep => {
    const {
      history,
      experiment: {
        id,
        config: { setUpSteps },
      },
    } = this.props

    // Find the step prior to the clicked step; if the step before is not complete, do not navigate
    const stepBefore =
      initialSetUpSteps[
        initialSetUpSteps.findIndex(step => step.label === nextStep) - 1
      ]

    // Temporary variable used to determine if moving on is allowed
    let canContinue

    // If trying to move to the below steps, 'plan' must be 'complete', 'variant' must not be 'initial'
    if (
      nextStep !== 'plan' &&
      nextStep !== 'variants' &&
      nextStep !== 'variants&content'
    ) {
      const planComplete = setUpSteps.plan.status === 'complete'

      const variantComplete = setUpSteps.variants
        ? setUpSteps.variants.status !== 'initial'
        : setUpSteps['variants&content'].status !== 'initial'

      canContinue = planComplete && variantComplete

      // Else, the step prior to the next step must be completed
    } else {
      const label = stepBefore ? stepBefore.label : 'plan'
      canContinue = setUpSteps[label].status === 'complete'
    }

    if (canContinue) {
      // If the step is complete, move on and update the URL accordingly
      const pathArray = history.location.pathname.split('/')
      const newPath = `${pathArray.join('/')}/${id}/set-up/${nextStep}`

      history.replace(newPath)
    }
  }

  // FORMATTING METHODS
  formatUuidList = ({ uuid, variants }) =>
    variants.reduce(
      (accumulator, { name, uuid: variantUuid }) => [
        ...accumulator,
        {
          label: name,
          textToCopy: variantUuid,
        },
      ],
      [
        {
          label: 'Experiment',
          textToCopy: uuid,
        },
      ]
    )

  // RENDER METHODS
  renderUuidList = () => {
    const { experiment } = this.props
    const formattedUuidList = this.formatUuidList(experiment)

    return (
      <SmartExpansionPanel
        className="experiment-details__uuid-expansion"
        title="UUIDs"
      >
        <ul className="experiment-details__uuid-list">
          {formattedUuidList.map(listItem => this.renderUuid(listItem))}
        </ul>
      </SmartExpansionPanel>
    )
  }

  renderUuid = ({ label, textToCopy }) => (
    <li key={label} className="experiment-details__uuid-container">
      <span className="experiment-details__uuid-label-wrapper">
        <span className="experiment-details__uuid">{textToCopy}</span>
        <span className="experiment-details__uuid-label">{`${label}`}</span>
      </span>

      <CopyButton textToCopy={textToCopy} useIcon />
    </li>
  )

  renderNotifications = () => {
    const { selectedEnvironment } = this.state
    const {
      history,
      experiment: { notifications, name },
    } = this.props

    const notificationsByEnvironment = notifications.filter(
      notification => notification.environment.id === selectedEnvironment.id
    )

    if (notificationsByEnvironment.length === 0) {
      return (
        <div className="experiment-details__empty">
          No notifications available
        </div>
      )
    }

    return notificationsByEnvironment.map(
      ({ id: notificationId, isRead, subject, notificationTime }) => {
        const notificationsLink = `/notifications/?experiment=${name}`
        const selectedNotificationLink = `${notificationsLink}&selectedNotification=${notificationId}`
        const notificationClassNames = classNames({
          'experiment-details__notification': true,
          'experiment-details__notification-unread': !isRead,
        })
        const indicatorClassNames = classNames({
          'experiment-details__read-indicator': true,
          'experiment-details__read-indicator-read': isRead,
        })

        return (
          <div
            key={notificationId}
            className={notificationClassNames}
            role="link"
            tabIndex="0"
            onClick={() => history.push(selectedNotificationLink)}
            onKeyDown={event =>
              event.key === 'Enter' && history.push(selectedNotificationLink)
            }
          >
            <div className={indicatorClassNames} />

            <div className="experiment-details__notification-left ">
              <span
                className={`experiment-details__notification-subject ${
                  !isRead
                    ? 'experiment-details__notification-subject-unread'
                    : ''
                }`}
              >
                {subject}
              </span>
            </div>

            <div className="experiment-details__notification-right">
              <span className="experiment-details__notification-date">
                {dayjs().isSame(notificationTime, 'day')
                  ? 'Today'
                  : dayjs(notificationTime).format('MM/DD/YYYY')}
              </span>

              <span className="experiment-details__notification-time">
                {dayjs(notificationTime).format('h:mma')}
              </span>
            </div>
          </div>
        )
      }
    )
  }

  render() {
    const { selectedEnvironment } = this.state
    const {
      id,
      history,
      experiment,
      applicationPermissions,
      onOpenExperiment,
      onSuccess,
      userRoles,
      activeNetworkLockdown,
      handleNextExperiments,
    } = this.props

    const {
      status,
      name,
      notifications,
      environmentSamplings,
      permissions,
      setUpSteps,
      isSetUpComplete,
      isCmsExperiment,
      isNextExperiment,
    } = experiment
    const envSampling = environmentSamplings?.find(
      sampling => sampling?.environment?.id === selectedEnvironment?.id
    )
    const allocationHistory = envSampling ? envSampling.allocationHistory : []
    // Filter CMS experiment for production env only
    const experimentEnvironments = !isCmsExperiment
      ? environmentSamplings
          .map(sampling => sampling?.environment)
          .map(env => ({ ...env, name: env?.name?.toLowerCase() }))
      : environmentSamplings
          .map(sampling => sampling?.environment)
          .map(env => ({ ...env, name: env?.name?.toLowerCase() }))
          .filter(({ id }) => id === PRODUCTION)

    const baseLink = `/experiments/${id}`
    const allocationLink = `${baseLink}/rollout/sample-launch?environment=${selectedEnvironment.id}`
    const notificationsLink = `/notifications/?experiment=${name}`

    const shouldRedirectToResults =
      status === 'cancelled' || status === 'completed'

    const canTerminate = hasPermission({
      applicationPermissions,
      environmentSamplings: environmentSamplings || [],
      environmentId: selectedEnvironment.id,
      experimentPermissions: permissions,
      permissionId: EXPERIMENT_TERMINATE,
    })

    const hasCloneExperimentPermission = hasPermission({
      applicationPermissions,
      experimentPermissions: permissions,
      permissionId: EXPERIMENT_CLONE,
    })

    const hasDeletePermissions =
      hasPermission({
        applicationPermissions,
        experimentPermissions: experiment.permissions,
        environmentSamplings: experiment.environmentSamplings,
        permissionId: EXPERIMENT_DELETE,
      }) ||
      hasPermission({
        applicationPermissions,
        experimentPermissions: experiment.permissions,
        environmentSamplings: experiment.environmentSamplings,
        permissionId: EXPERIMENT_DELETE_TESTING,
      })

    const productRoles = Object.values(roleEnum).filter(roleId =>
      userRoles.find(userToRole => userToRole.roleId === roleId)
    )

    const canCloneProduct = productRoles
      .map(n => roleEnumReverse[n])
      .find(
        roleName =>
          roleName.includes(experiment.product.toUpperCase()) ||
          productRoles.includes(1)
      )

    if (status === 'draft') {
      return (
        <div className="experiment-details__draft-details">
          <LinearProgressStepper
            className="experiment-details__stepper"
            steps={setUpSteps}
            leanLeft
            onClick={this.handleSelectStep}
          />

          <div className="experiment-details__draft-controls">
            <KiteButton
              className="experiment-details__open-button"
              onClick={onOpenExperiment}
            >
              Open Experiment
            </KiteButton>

            <div className="experiment-details__footer-controls">
              <EndExperimentButton
                id={id}
                experiment={experiment}
                selectedEnvironment={selectedEnvironment}
                onSuccess={onSuccess}
                canTerminate={canTerminate}
                hasCloneExperimentPermission={
                  hasCloneExperimentPermission &&
                  isSetUpComplete &&
                  canCloneProduct
                }
                hasDeletePermissions={hasDeletePermissions}
                history={history}
                activeNetworkLockdown={
                  activeNetworkLockdown && selectedEnvironment.id === 1
                }
                className={
                  activeNetworkLockdown && selectedEnvironment.id === 1
                    ? 'sample-launch__active-lockdown-btn'
                    : ''
                }
              />
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="experiment-details">
        {!isCmsExperiment && (
          <ColoredSelect
            className="experiment-details__environment-select"
            value={selectedEnvironment.name}
            options={experimentEnvironments.sort((aEnv, bEnv) =>
              aEnv.order > bEnv.order ? 1 : -1
            )}
            onSelect={this.handleSelectEnvironment}
          />
        )}

        {this.renderUuidList()}

        <div className="experiment-details__content">
          <div className="experiment-details__allocation-history">
            <div className="experiment-details__sub-title">
              Sampling & Allocation History
              {allocationHistory.length > 0 && (
                <KiteButton
                  className="experiment-details__view-more"
                  type="standalone-link"
                  onClick={() => {
                    if (isNextExperiment) {
                      handleNextExperiments()
                    } else {
                      history.push(allocationLink)
                    }
                  }}
                >
                  View More
                </KiteButton>
              )}
            </div>

            <AllocationHistory
              allocationHistory={allocationHistory}
              selectedEnvironment={selectedEnvironment}
              limit={5}
            />
          </div>

          <div className="experiment-details__notifications">
            <div className="experiment-details__sub-title">
              Notifications
              {notifications.length > 0 && (
                <KiteButton
                  className="experiment-details__view-more"
                  type="standalone-link"
                  onClick={() => history.push(notificationsLink)}
                >
                  View More
                </KiteButton>
              )}
            </div>

            <div className="experiment-details__notifications-container">
              {this.renderNotifications()}
            </div>
          </div>
        </div>

        <div className="experiment-details__footer">
          <div className="experiment-details__environment-controls">
            {shouldRedirectToResults && selectedEnvironment.id === PRODUCTION && (
              <KiteButton
                onClick={() => {
                  if (isNextExperiment) {
                    handleNextExperiments()
                  } else {
                    history.push(`${baseLink}/results`)
                  }
                }}
              >
                View Results
              </KiteButton>
            )}

            {!shouldRedirectToResults &&
              experimentEnvironments.map(environment => (
                <button
                  key={environment.id}
                  className="experiment-details__environment-button"
                  style={{
                    backgroundColor: environment.color,
                  }}
                  onClick={() => {
                    if (isNextExperiment) {
                      handleNextExperiments()
                    } else {
                      history.push(
                        `${baseLink}/rollout/sample-launch/?environment=${environment.id}`
                      )
                    }
                  }}
                >
                  <span className="experiment-details__environment-button-value">
                    {environment.name.toLowerCase()}
                  </span>

                  <span className="experiment-details__environment-button-icon" />
                </button>
              ))}
          </div>

          <div className="experiment-details__footer-controls">
            <EndExperimentButton
              id={id}
              experiment={experiment}
              selectedEnvironment={selectedEnvironment}
              onSuccess={onSuccess}
              canTerminate={canTerminate}
              hasCloneExperimentPermission={
                hasCloneExperimentPermission && !isNextExperiment
              }
              hasDeletePermissions={hasDeletePermissions}
              history={history}
              activeNetworkLockdown={
                activeNetworkLockdown && selectedEnvironment.id === 1
              }
              className={
                activeNetworkLockdown && selectedEnvironment.id === 1
                  ? 'sample-launch__active-lockdown-btn'
                  : ''
              }
            />
          </div>
        </div>
      </div>
    )
  }
}

export default ExperimentDetails
