import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

export const GET_EXPERIMENT_ROLES_GQL = {
  query: `
    experiment(id: $id) {
      id
      name
      experimentToUsers {
        id
        userId
        roleId
        user {
          id
          displayName
        }
        role {
          id
          name
        }
      }
    }
  `,
  variables: {
    $id: 'Int!',
  },
}

export const GET_EXPERIMENT_ROLES = gql`
  query getExperimentRoles($id: Int!) {
    experiment(id: $id) {
      id
      name
      experimentToUsers {
        id
        userId
        roleId
        user {
          id
          displayName
        }
        role {
          id
          name
        }
      }
    }
  }
`

const getExperimentRoles = graphql(GET_EXPERIMENT_ROLES, {
  name: 'experimentRolesData',
  withRef: true,
  skip: ownProps => !ownProps.id,
})

export default getExperimentRoles
