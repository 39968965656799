import { gql } from '@apollo/client'

const UPDATE_CONTENT_MAP = gql`
  mutation updateContentMap($id: Int!, $input: UpdateContentMapInput!) {
    updateContentMap(id: $id, input: $input) {
      id
      name
      productId
      product {
        id
        displayName
      }
      featureId
      feature {
        id
        displayName
      }
      statusId
      status {
        id
        name
      }
      config
      contentAreaId
      startTime
      stopTime
      comment
      jiraTicket
      isApprovalReady
      publishedError
      contentMapToUser {
        id
        userId
        user {
          id
          displayName
        }
        roleId
      }
      hasActiveContentArea {
        id
        stopTime
      }
      personalizedContent {
        id
        audienceId
        audience {
          id
          name
          rules
        }
        contentId
        contentJson
        content {
          contentId
          contentTitle
        }
        priority
        contentJson
      }
    }
  }
`

export default UPDATE_CONTENT_MAP
