import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

/*
* Sample Input
* {
   input: {
       subArn: 'arn:aws:sns:us-west-2:369361176069:dx-experiment-status-development:b15ddd39-7ab3-4cd1-b094-fcb12e9d2de0'
  }
}
*/

const UNSUBSCRIBE_FROM_SNS = gql`
  mutation unsubscribeFromSns($input: DeleteSubscription!) {
    unsubscribeFromSns(input: $input)
  }
`

const unsubscribeFromSns = graphql(UNSUBSCRIBE_FROM_SNS, {
  name: 'unsubscribeFromSns',
})

export default unsubscribeFromSns
