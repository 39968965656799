import React from 'react'

export default {
  needsNotice: 'NOTE',
  overrideNotice:
    "Changing core variables, such as the Product, clears any existing Overrides as they may not apply to the new selection. You'll need to set up new Overrides before you publish.",
  monitor: {
    noMonitoringData:
      'No monitoring data to display yet; please check back after the experiment has been running for more than 24 hours.\nIf your experiment has been running for longer than 24 hours and you still are not seeing results, please double check your experiment setup configs for accuracy, and then reach out to the Distillery team for further support.',
    notFound: 'No counts were returned by Quantum for this experiment',
    noResults:
      'Once your experiment has begun collecting data, your statistics will show up here.',
    quantumQueryError:
      'There was an error querying Quantum for metric counts.  Please try again.',
    allocationMethodTooltip:
      'As selected on the Tech Settings page, the experiment population will be allocated by account (which may include multiple devices, but each account will display as one count in the monitor graphs), by device (individual devices), or by account-device (individual devices within an auth account, which will display as devices).',
  },
  sampleLaunch: {
    changeRequestHeading: 'Change Request Tracking',
    changeRequestDescription: 'In order to ensure the safety of our customers’ experience, you must enter both your CRQ & EXPSI ticket numbers in order to launch your experiment at more than 0% sampling in production. For any questions about those processes, please refer to the CRQ Chalk Page and EXPSI Chalk Page',
    heading: 'Set Customer Sampling and Allocations',
    description:
      'Select a sampling percentage of total customers to participate in the experiment. Then, from the sampling, allocate percentages to see Control or Variant.',
    samplingHeading: 'Sampling & Allocation Selector',
    historyHeading: 'History',
    distributeButton: 'Distribute Evenly',
    successPublish: (name, type) => `Experiment ${type}ed to ${name}`,
    errorPublish: (name, type) =>
      `Experiment failed to ${type} in ${name}. Please try again in a few minutes. If this continues, `,
    errorNotApproved:
      'A technical contact or admin must review the settings of your experiment before publication. If this continues after approval, ',
    errorNotQuantumSynced:
      'Your experiment is not currently synced with Quantum. Please try again in a few minutes. If this continues, ',
    errorNotTdcsSynced:
      'A sync with TDCS has failed, please try again in a few minutes. If this continues, ',
    lowerInProdInfo:
      'This experiment has been launched in production and cannot be changed here.',
    needsOverride:
      "This experiment has been launched to 'Testing' without any overrides. To add overrides, visit the 'Overrides' step.",
    comment:
      'When making a sampling & allocation change, you must record the reason for making an allocation change. (Please write at least 35 characters.) Comment will be saved when you click "Publish Changes."',
    launchRemindersMessage: (
      <div style={{ textAlign: 'left' }}>
        <div style={{ paddingBottom: '30px' }}>
          Before sending your experiment out to customers, have you...
        </div>
        <div>Completed your EXPSI ticket?</div>
        <div>Completed the CRQ process?</div>
        <div>Tested all variant experiences with overrides?</div>
        <div>Launched with a soak period at low allocation?</div>
      </div>
    ),
    soakingMessage:
      'Foregoing a soak period at low allocation has taken down several applications and severely harmed customer experiences. If you have completed the EXPSI & CRQ processes, sufficiently tested your experiment’s contents, and want to continue with the selected sampling percentage, enter ‘BYPASS’ (all caps, no spaces) below:',
  },
  tdcsStatus: {
    tdcsSyncStatus: 'Current Distillery-TDCS Sync Status: ',
    noPublishHistory:
      "You don't have any TDCS publication history yet! We'll show it here when you do.",
    noConfigSelected:
      'Config will be shown once "Get Config" is selected for a specific TDCS publish date.',
    noConfigAvaliable: 'No config created due to publish failure.',
  },
}
