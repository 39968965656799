import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

export const GET_ALL_CONTENT = gql`
  query getAllContent {
    contents {
      contentId
      contentTitle
      contentAreaId
      personalization
    }
  }
`

const getContents = graphql(GET_ALL_CONTENT, {
  name: 'contentsData',
  withRef: true,
  options: {
    fetchPolicy: 'network-only',
  },
})

export default getContents
