import React, { Component, Fragment } from 'react'
import { func, object, number, array, bool } from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import flowRight from 'lodash/flowRight'
import isEqual from 'lodash/isEqual'
import {
  getContentAreas,
  getExperimentContent,
  GET_EXPERIMENT_CONTENT,
} from '../queries'
import { getContents } from '../../../../shared/queries'
import insertExperimentContent from './mutations/insertExperimentContent'
import {
  insertExperimentVariants,
  updateExperimentVariants,
  deleteExperimentVariant,
  APPROVE_TECH_SETTINGS,
  SET_NEEDS_TECH_APPROVAL,
  LOG_ERROR
} from '../../../../shared/mutations'
import {
  checkVariants,
  hasPermission,
  permissionEnum,
  experimentStatusEnum,
  experimentTypeEnum,
} from '@charter/distillery-rules'
import {
  ExpansionPanel,
  CopyButton,
  RadioSearchContainer,
  Modal,
} from '../../../../componentLibrary'
import copyContent from '../../data/copyContent'

import { KiteLoader, KiteInput, KiteButton, KiteAlert } from '@kite/react-kite'

import { SmartExpansionPanel } from '@kite/react-kite-plus'

import './Contents.scss'
import { formatLoggingError } from '../../../../shared/utilities'
import { client } from '../../../../configuration/configApiClient'

const {
  EXPERIMENT_UPDATE_VERSION_RUNNING,
  EXPERIMENT_UPDATE_RUNNING,
  TECH_UPDATE,
} = permissionEnum
const initialVariants = {
  control: {
    id: null,
    uuid: null,
    name: null,
    content: '',
    isDirty: false,
  },
  'variant 1': {
    id: null,
    uuid: null,
    name: null,
    content: '',
    isDirty: false,
  },
}
const { RUNNING } = experimentStatusEnum
const initialErrors = {
  duplicateName: null,
  createUpdateVariant: null,
  deleteVariant: null,
  variantFieldErrors: null,
  errorAlert: null,
}

export class Contents extends Component {
  static propTypes = {
    id: number,
    applicationPermissions: array.isRequired,
    experimentPermissions: array.isRequired,
    environmentSamplings: array.isRequired,
    contentAreaData: object.isRequired,
    contentsData: object.isRequired,
    experimentContentData: object.isRequired,
    onChange: func.isRequired,
    disabled: bool,
    canApproveTechSettings: bool,
    insertExperimentVariants: func,
    updateExperimentVariants: func,
    deleteExperimentVariant: func,
  }

  static defaultProps = {
    id: null,
    contentAreaData: {},
    contentsData: {},
    disabled: false,
    canApproveTechSettings: false,
    insertExperimentVariants: null,
    updateExperimentVariants: null,
    deleteExperimentVariant: null,
  }

  state = {
    activePanel: null,
    variantToDelete: null,
    isDeleteVariantModalOpen: false,
    isLoading: false,
    isUpdating: false,
    isDeleting: false,
    newVariant: null,
    contentArea: null,
    contentAreaId: null,
    content: null,
    errorMessage: initialErrors,
    variants: initialVariants,
    contentAreaContent: [], // content that's placed in a content area. Populated once a content area is selected.
    contentAreas: [],
    contents: [],
  }

  componentDidMount() {
    const { experimentContentData, contentAreaData, contentsData } = this.props

    if (
      contentAreaData &&
      experimentContentData &&
      contentsData &&
      experimentContentData.experimentContent
    ) {
      let contentAreaId = experimentContentData.experimentContent.contentAreaId
      let contentArea = this.getContentAreaById(contentAreaId)

      this.setState({
        contentAreaId: contentAreaId,
        contentArea: contentArea,
      })
      this.handleSetExperiment()
      if (contentArea && contentArea.contentIds) {
        this.populateMatchingContent(contentArea)
      }
    }
  }

  // LIFECYCLE METHODS
  async componentDidUpdate(prevProps) {
    const {
      experimentContentData,
      contentAreaData,
      contentsData,
      hashVariables,
    } = this.props
    const {
      experimentContentData: prevExpeirmentContentData,
      contentAreaData: prevContentAreaData,
      contentsData: prevContentsData,
    } = prevProps

    if (
      experimentContentData.loading ||
      contentAreaData.loading ||
      contentsData.loading
    ) {
      return
    }

    if (
      !isEqual(
        prevExpeirmentContentData.experiment,
        experimentContentData.experiment
      ) ||
      !isEqual(
        prevContentAreaData.contentAreas,
        contentAreaData.contentAreas
      ) ||
      !isEqual(prevContentsData.contents, contentsData.contents)
    ) {
      if (
        experimentContentData.experimentContent &&
        experimentContentData.experimentContent.contentAreaId
      ) {
        let contentAreaId =
          experimentContentData.experimentContent.contentAreaId
        let contentArea = this.getContentAreaById(contentAreaId)

        this.setState({
          contentAreaId: contentAreaId,
          contentArea: contentArea,
        })

        await this.handleSetExperiment()
        if (contentArea && contentArea.contentIds) {
          this.populateMatchingContent(contentArea)
        }
      }

      // Verify if errors occurred in querying content
      if (contentAreaData.error || contentsData.error) {
        this.setState(({ prevErrorMessage }) => ({
          errorMessage: {
            ...prevErrorMessage,
            errorAlert: copyContent.cmsVariants.dreConnectionError,
          },
        }))
      }
    }

    if (
      (!isEqual(
        prevContentAreaData.contentAreas,
        contentAreaData.contentAreas
      ) ||
        !isEqual(prevContentsData.contents, contentsData.contents)) &&
      hashVariables
    ) {
      let contentAreaHash = this.getContentAreaById(hashVariables.contentAreaId)
      const content = contentsData.contents.find(
        ({ contentId }) => contentId === hashVariables.contentId
      )

      if (content) {
        const { contentTitle } = content
        this.setState(({ variants }) => {
          variants.control = {
            ...variants.control,
            content: hashVariables.contentId,
            name: contentTitle,
            isDirty: true,
          }

          return {
            contentAreaId: hashVariables.contentAreaId,
            contentArea: contentAreaHash,
            content: hashVariables.contentId,
            variants,
          }
        })
        this.populateMatchingContent(contentAreaHash)
      }
    }
  }

  handleSetExperiment = async () => {
    const {
      onChange,
      experimentContentData: { experiment },
      contentsData: { contents },
    } = this.props

    const { variants, experimentStatusId, experimentTypeId } = experiment

    let isRunningCanaryExperiment = false

    if (
      experimentStatusId === RUNNING &&
      experimentTypeId === experimentTypeEnum.CANARY
    ) {
      isRunningCanaryExperiment = true
    }

    await this.setState({
      isLoading: true,
      isRunningCanaryExperiment,
    })

    if (variants && variants.length) {
      let content
      // Format the variants according to order
      const formattedVariants = variants.reduce((accumulator, variant) => {
        const { id, uuid, name, jsonPayload, displayOrder } = variant

        const variantKey =
          displayOrder === 0 ? 'control' : `variant ${displayOrder}`
        if (displayOrder === 0) {
          content =
            contents &&
            contents.find(({ contentId }) => contentId === jsonPayload)
        }
        accumulator[variantKey] = {
          id,
          uuid,
          name,
          content: jsonPayload,
          isDirty: false,
        }

        return accumulator
      }, {})

      this.setState(({ variants: prevVariants }) => {
        let variantsObj = formattedVariants
        if (formattedVariants.length < 2) {
          variantsObj = {
            ...formattedVariants,
            prevVariants,
          }
        }
        return {
          content,
          variants: variantsObj,
        }
      })
    }

    // Pass 'false' to inform parent that the form does not have changes
    //  and is only loading
    await onChange(false)
    this.setState({ isLoading: false })
  }

  handleFormatVariantInputs = () => {
    const { variants } = this.state

    const variantsToInsert = []
    const variantsToUpdate = []

    Object.keys(variants).forEach(async (variant, index) => {
      const { id: variantId, name, content, isDirty } = variants[variant]
      let variantPayload = {
        name: name,
        description: name,
        jsonPayload: `${content}`,
        displayOrder: index,
      }

      // Only insert/update variants that have changes
      if (isDirty) {
        // If there's an existing id, add the variant to updates
        if (variantId) {
          variantsToUpdate.push({
            ...variantPayload,
            id: variantId,
          })
          // Else add the variant to inserts, spread in variant uploads
        } else {
          variantsToInsert.push({
            ...variantPayload,
          })
        }
      }
    })

    return { variantsToInsert, variantsToUpdate }
  }

  handleFormatTitleColumns = variant => {
    const { variants, errorMessage } = this.state
    const { uuid, name } = variants[variant]
    const columnTitle =
      variant !== 'control'
        ? copyContent.cmsVariants.variantColumnTitle
        : copyContent.cmsVariants.controlColumnTitle
    const titleColumns = [{ content: columnTitle, heading: true, size: 1 }]

    const hasFieldErrors =
      errorMessage.variantFieldErrors &&
      Object.keys(errorMessage.variantFieldErrors)
        .filter(field => field.includes(variant))
        .find(field => errorMessage.variantFieldErrors[field] !== null)

    const nameContent = `${name}`
    const idContent = `UUID: ${uuid}`

    const fieldErrorObject = {
      content: (
        <KiteAlert type="alert" level="inline" description="Missing Fields" />
      ),
      size: 0.5,
    }

    if (name) {
      titleColumns.push(nameContent)
    }

    if (!uuid && hasFieldErrors) {
      titleColumns.push(fieldErrorObject)
    }

    // If a uuid exists the variant has been saved
    if (uuid) {
      titleColumns.push(idContent)
    }

    return { titleColumns }
  }

  populateMatchingContent = async ({ contentIds }) => {
    const { contentsData } = this.props
    const matchingContent = []
    const otherContent = []
    for (let contentId of contentIds) {
      const match = contentsData.contents.find(
        content => content.contentId === contentId
      )
      if (match) {
        matchingContent.push(match)
      }
    }

    // Implemented for later use of other content not related
    contentsData.contents.forEach(content => {
      const { contentId } = content
      const match = matchingContent.find(
        matchingContent => matchingContent.contentId === contentId
      )

      if (!match) {
        otherContent.push(content)
      }
    })

    await this.setState({
      contentAreaContent: matchingContent,
      contents: otherContent,
    })
  }

  handleContentAreaValueChange = async ({ target: { name, value } }) => {
    const { onChange } = this.props

    let selectedContentAreaId = value
    let selectedContentArea = this.getContentAreaById(selectedContentAreaId)

    this.setState({
      contentArea: selectedContentArea,
      contentAreaId: selectedContentAreaId,
      errorMessage: initialErrors,
      contentId: null,
      content: null,
    })

    if (selectedContentArea && selectedContentArea.contentIds) {
      this.populateMatchingContent(selectedContentArea)
    }
    onChange(true)
  }

  handleAddVariant = async event => {
    if (event) {
      event.preventDefault()
    }

    const { onChange } = this.props
    const { variants } = this.state

    const newVariant = `variant ${Object.keys(variants).length}`
    // Add a new variant to the variants
    await this.setState(prevState => ({
      activePanel: newVariant,
      newVariant,
      variants: {
        ...prevState.variants,
        [newVariant]: {
          id: null,
          uuid: null,
          name: null,
          content: '',
          isDirty: false,
        },
      },
    }))

    onChange(true)
  }

  handleCheckDeleteVariant = (event, variant) => {
    if (event) {
      event.preventDefault()
    }

    const { variants } = this.state

    const { id, name } = variants[variant]
    this.setState({
      variantToDelete: { id, name, state: variant },
      isDeleteVariantModalOpen: true,
    })
  }

  getContentAreaById = contentAreaId => {
    const { contentAreaData } = this.props

    if (contentAreaData.loading) {
      return
    }

    let selectedContentArea =
      contentAreaData.contentAreas &&
      contentAreaData.contentAreas.find(
        contentArea => contentArea.contentAreaId === contentAreaId
      )
    return selectedContentArea
  }

  handleContentValueChange = async event => {
    const {
      onChange,
      contentsData: { contents },
    } = this.props
    const {
      target: { name, value },
    } = event

    const variantProps = name.split('__')

    if (event && event.persist) {
      event.persist()
    }

    let content
    let contentTitle
    if (value === 'null') {
      content = 'null'
      contentTitle = 'No/Empty Content'
    } else {
      content = contents.find(({ contentId }) => contentId === value)
      contentTitle = content.contentTitle
    }

    await this.setState(prevState => ({
      variants: {
        ...prevState.variants,
        [variantProps[0]]: {
          ...prevState.variants[variantProps[0]],
          [variantProps[1]]: value,
          name: contentTitle,
          isDirty: true,
        },
      },
      errorMessage: initialErrors,
      content: variantProps[0] === 'control' ? content : prevState.content,
    }))

    onChange(true)
  }

  validateSubmission = async () => {
    const { contentAreaId, variants } = this.state

    await this.setState({ errorMessage: initialErrors })

    const input = Object.keys(variants).map((variant, index) => {
      const { name, content, description } = variants[variant]

      return {
        name,
        description,
        content,
        displayOrder: index,
      }
    })

    const { errors } = checkVariants({ variants: input, isCmsExperiment: true })

    if (!isEmpty(errors)) {
      this.setState(({ errorMessage }) => ({
        errorMessage: {
          ...errorMessage,
          variantFieldErrors: errors,
        },
      }))

      return false
    }

    if (!contentAreaId) {
      return false
    }

    return true
  }

  isComplete = () => {
    const { id, canApproveTechSettings } = this.props
    const experimentId = Number(id)

    if (canApproveTechSettings) {
      client.mutate({
        mutation: APPROVE_TECH_SETTINGS,
        variables: {
          experimentId,
        },
      })

      return true
    }

    client.mutate({
      mutation: SET_NEEDS_TECH_APPROVAL,
      variables: {
        experimentId,
      },
    })

    return false
  }

  // Used by parent and sends any existing error messages
  getErrorMessage = () => {
    const { errorMessage } = this.state

    const messages = Object.values(errorMessage).reduce(
      (accumulator, error) => {
        if (!accumulator && error) {
          return `${error}`
        }
        if (error) {
          return `${accumulator}; ${error}`
        }
        return accumulator
      },
      ''
    )

    return messages
  }

  handleInsertExperimentContent = async input => {
    const { insertExperimentContent } = this.props

    try {
      const response = await insertExperimentContent({
        variables: {
          input: input,
        },
      })

      if (response) {
        return true
      }
    } catch (error) {
      const errorInput = formatLoggingError(error)

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input: errorInput,
        },
      })

      this.setState(({ errorMessage }) => ({
        errorMessage: {
          ...errorMessage,
        },
      }))

      // Informs parent at the insert failed
      return false
    }
  }

  handleValidateRequiredFields = variants => {
    let isValid = true

    // Name and description are required
    Object.keys(variants).forEach(variant => {
      const { name, content } = variants[variant]

      if (name === '' || isEmpty(content)) {
        isValid = false
      }
    })

    return isValid
  }

  handleCancelDeleteVariant = () => {
    this.setState({
      variantToDelete: null,
      isDeleteVariantModalOpen: false,
    })
  }

  handleValidatePermissions = permId => {
    const {
      applicationPermissions,
      experimentPermissions,
      environmentSamplings,
      experimentContentData: {
        experiment: { experimentTypeId },
      },
    } = this.props

    const canUpdateRunning =
      experimentPermissions &&
      hasPermission({
        experimentTypeId,
        applicationPermissions,
        experimentPermissions,
        environmentSamplings,
        permissionId: permId,
      })

    return canUpdateRunning
  }

  handleValidateDisable = () => {
    const { disabled } = this.props
    const { isRunningCanaryExperiment } = this.state
    const canUpdateRunning = this.handleValidatePermissions(
      EXPERIMENT_UPDATE_RUNNING
    )
    const canUpdateVersionRunning = this.handleValidatePermissions(
      EXPERIMENT_UPDATE_VERSION_RUNNING
    )
    const techUpdate = this.handleValidatePermissions(TECH_UPDATE)

    let disabledField = disabled

    if (disabled && techUpdate) {
      disabledField = !techUpdate
    }

    // Verify if the disabled prop needs to be
    // overridden due to user having ability to update
    // and experiment being a running canary experiment
    if (
      (canUpdateRunning || canUpdateVersionRunning) &&
      disabled &&
      isRunningCanaryExperiment &&
      techUpdate
    ) {
      disabledField = !(canUpdateRunning || canUpdateVersionRunning)
    }

    return disabledField
  }

  // API METHODS
  handleConfirmDeleteVariant = async () => {
    const {
      id: experimentId,
      deleteExperimentVariant: deleteVariant,
      updateExperimentVariants: updateVariants,
    } = this.props

    const {
      variantToDelete: { id: variantId },
      variantToDelete: variantDeleted,
      errorMessage,
      variants: currentVariants,
    } = this.state

    if (errorMessage.deleteVariant) {
      this.setState(prevState => ({
        errorMessage: {
          ...prevState.errorMessage,
          deleteVariant: null,
        },
      }))
    }

    await this.setState({ isDeleting: true })

    try {
      // If the deleted variant has an idea, remove from the DB and update cache
      if (variantId) {
        const input = { experimentId, variantId }

        await deleteVariant({
          variables: { input },
          update: (
            proxy,
            { data: { deleteExperimentVariant: deletedExperiment } }
          ) => {
            // Read data from our cache for removal of variant
            const data = proxy.readQuery({
              query: GET_EXPERIMENT_CONTENT,
              variables: {
                id: experimentId,
              },
            })

            // Remove deleted variant from experiment in cache
            const newVariants = data.experiment.variants.filter(
              cachedVariant => cachedVariant.id !== deletedExperiment
            )

            data.experiment.variants = newVariants

            // Write data back to the cache
            proxy.writeQuery({
              query: GET_EXPERIMENT_CONTENT,
              variables: { id: experimentId },
              data,
            })
          },
        })
      }

      const variantsToUpdate = []
      let shouldUpdateVariants = Object.keys(currentVariants)[0].id

      // Update the variants display order
      Object.keys(currentVariants)
        .filter(currentVariant => currentVariant !== variantDeleted.state)
        .forEach((variant, index) => {
          if (variant !== variantDeleted.state) {
            variantsToUpdate.push({
              id: currentVariants[variant].id,
              displayOrder: index,
            })
            if (!variantsToUpdate.id && !shouldUpdateVariants) {
              shouldUpdateVariants = true
            }
          }
        })

      if (variantId && shouldUpdateVariants) {
        await updateVariants({
          variables: {
            input: {
              experimentId,
              variants: variantsToUpdate,
            },
          },
        })
      }

      // Filter the deleted variant from state and update the labels on all variants
      await this.setState(
        ({ variants, variantToDelete, multipleVariantReason: prevReason }) => ({
          activePanel: null,
          isDeleting: false,
          variantToDelete: null,
          isDeleteVariantModalOpen: false,
          variants: Object.keys(variants)
            .filter(currentVariant => currentVariant !== variantToDelete.state)
            .reduce((accumulator, variant, index) => {
              if (variant !== variantToDelete.state) {
                if (variant === 'control') {
                  accumulator[variant] = variants[variant]
                } else {
                  accumulator[`variant ${index}`] = variants[variant]
                }
              }

              return accumulator
            }, {}),
        })
      )
    } catch (error) {
      const input = formatLoggingError(error, { id: experimentId })

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input,
        },
      })

      await this.setState(prevState => ({
        isDeleting: false,
        errorMessage: {
          ...prevState.errorMessage,
          deleteVariant: copyContent.variants.deleteVariantError,
        },
      }))
    }
  }

  handleSubmit = async () => {
    const {
      id,
      contentsData: { contents },
    } = this.props
    const { contentAreaId, variants } = this.state
    const {
      id: experimentId,
      insertExperimentVariants: insertVariants,
      updateExperimentVariants: updateVariants,
    } = this.props

    await this.setState({ isUpdating: true })
    const contentId = variants.control.content
    const controlIsDirty = variants.control.isDirty

    let content
    if (contentId === 'null') {
      content = 'null'
    } else {
      content = contents.find(cont => cont.contentId === contentId)
    }

    const contentInput = {
      experimentId: id,
      contentId: contentId,
      contentAreaId,
      contentJson: JSON.stringify(content),
    }

    try {
      if (contentId && controlIsDirty) {
        await this.handleInsertExperimentContent(contentInput)
      }

      const { variantsToInsert, variantsToUpdate } =
        this.handleFormatVariantInputs()

      // Update any variants
      if (variantsToUpdate.length > 0) {
        await updateVariants({
          variables: {
            input: {
              experimentId,
              variants: variantsToUpdate,
            },
          },
        })
      }

      // Insert any variants
      if (variantsToInsert.length > 0) {
        const insertResponse = await insertVariants({
          variables: {
            input: {
              experimentId,
              variants: variantsToInsert,
            },
          },
        })

        const { variants: newVariants } =
          insertResponse.data.insertExperimentVariants

        await this.setState(prevState => ({
          activePanel: null,
          variants: Object.keys(prevState.variants).reduce(
            (accumulator, variant) => {
              const stateVariant = variants[variant]

              if (!stateVariant.uuid) {
                accumulator[variant] = {
                  ...stateVariant,
                  uuid: newVariants.find(
                    newVariant =>
                      newVariant.jsonPayload === stateVariant.content
                  ).uuid,
                }
              } else {
                accumulator[variant] = stateVariant
              }

              return accumulator
            },
            {}
          ),
        }))
      }

      await this.setState({ isUpdating: false })

      return true
    } catch (error) {
      const input = formatLoggingError(error, { id: experimentId })

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input,
        },
      })

      this.setState(prevState => ({
        errorMessage: {
          ...prevState.errorMessage,
          createUpdateVariant: copyContent.variants.insertUpdateVariantsError,
        },
      }))

      await this.setState({ isUpdating: false })

      return false
    }
  }

  // RENDER METHODS
  renderVariants = () => {
    const {
      experimentContentData: { experiment },
      contentAreaData: { contentAreas },
      contentsData: { contents },
      disabled,
    } = this.props
    const {
      activePanel,
      variants,
      newVariant,
      contentAreaContent,
      contentAreaId: contentAreaCurrentId,
    } = this.state

    return Object.keys(variants).map((variant, index) => {
      const { id, uuid, content, name } = variants[variant]

      const isControl = variant === 'control'
      let disabledFields = disabled
      let canUpdateRunning = this.handleValidatePermissions(
        EXPERIMENT_UPDATE_RUNNING
      )

      // If someone was able to add a variant they need to
      // to be allowed to add information to it as well
      if (newVariant === variant) {
        disabledFields = false
        canUpdateRunning = false
      }
      const key = id || `${variant}__${index}`

      // Delete button is only shown if at least 3 variants exist in the DB || a new variant without an id
      const showDeleteButton =
        (variant.includes('variant') && experiment.variants.length > 2) ||
        (!id && !isControl && variant !== 'variant 1')

      const { titleColumns } = this.handleFormatTitleColumns(variant)

      // Format radio buttons for content area
      const contentAreaOpts =
        contentAreas &&
        contentAreas.map(({ contentAreaId }) => ({
          id: contentAreaId,
          label: contentAreaId.replace(/-/g, ' '),
          value: contentAreaId,
          checked: contentAreaCurrentId
            ? contentAreaCurrentId === contentAreaId
            : null,
        }))

      // Formate radio buttons for content
      const contentOpts =
        contentAreaContent &&
        contentAreaContent.map(({ contentTitle, contentId }) => {
          const disabled = Object.values(variants).find(
            ({ name: variantName, content: searchContent }) =>
              name !== variantName && searchContent === contentId
          )

          return {
            id: `${variant} - ${contentId}`,
            label: `${contentTitle} - ${contentId}`,
            value: contentId,
            checked: content && content === contentId ? true : null,
            disabled: !isEmpty(disabled),
          }
        })
      if (contentOpts.length) {
        const disabled = Object.values(variants).find(
          ({ name: variantName, content: searchContent }) =>
            name !== variantName && searchContent === 'null'
        )
        contentOpts.push({
          id: 'null',
          label: 'No/Empty Content',
          value: 'null',
          checked: content && content === 'null' ? true : null,
          disabled: !isEmpty(disabled),
        })
      }

      // Format radio buttons for all possible content
      const variantContentOpts =
        contents &&
        contents.map(({ contentTitle, contentId }) => {
          const disabled = Object.values(variants).find(
            ({ name: variantName, content: searchContent }) =>
              name !== variantName && searchContent === contentId
          )

          return {
            id: `${variant} - ${contentId}`,
            label: `${contentTitle} - ${contentId}`,
            value: contentId,
            checked: content && content === contentId ? true : null,
            disabled: !isEmpty(disabled),
          }
        })

      if (variantContentOpts.length) {
        const disabled = Object.values(variants).find(
          ({ name: variantName, content: searchContent }) =>
            name !== variantName && searchContent === 'null'
        )
        variantContentOpts.push({
          id: 'null',
          label: 'No/Empty Content',
          value: 'null',
          checked: content && content === 'null' ? true : null,
          disabled: !isEmpty(disabled),
        })
      }

      return (
        <SmartExpansionPanel
          key={key}
          title={variant}
          active={activePanel === variant}
          onClick={rowTitle => this.setState({ activePanel: rowTitle })}
          titleColumns={titleColumns}
          showTitle={false}
        >
          {uuid && (
            <div className="experiment-plan__uuid-container">
              <KiteInput
                className="experiment-plan__uuid"
                label="UUID"
                value={uuid}
                disabled
              />

              <CopyButton textToCopy={uuid} />
            </div>
          )}

          {isControl && (
            <Fragment>
              <div className="content__control-content-container">
                <RadioSearchContainer
                  id={`${variant}-content-area`}
                  radioBtnOpt={contentAreaOpts || []}
                  onChange={this.handleContentAreaValueChange}
                  name={`${variant}__content-area`}
                  disabledContainer={disabledFields && !canUpdateRunning}
                  label={copyContent.cmsVariants.contentAreaTitle}
                  searchPlaceholder={
                    copyContent.cmsVariants.contentAreaSearchPlaceholder
                  }
                  width="100%"
                />

                <RadioSearchContainer
                  id={`${variant}-content`}
                  className="content__control-content"
                  radioBtnOpt={contentOpts || []}
                  onChange={this.handleContentValueChange}
                  name={`${variant}__content`}
                  disabledContainer={disabledFields && !canUpdateRunning}
                  width="100%"
                  label={copyContent.cmsVariants.contentTitle}
                  searchPlaceholder={
                    copyContent.cmsVariants.contentSearchPlaceholder
                  }
                  noDataMessage="Please select a content area."
                />
              </div>
            </Fragment>
          )}

          {!isControl && (
            <Fragment>
              <div className="content__control-content-container">
                <RadioSearchContainer
                  id={`${variant}-content`}
                  radioBtnOpt={variantContentOpts || []}
                  onChange={this.handleContentValueChange}
                  name={`${variant}__content`}
                  disabledContainer={disabledFields && !canUpdateRunning}
                  width="100%"
                  label={copyContent.cmsVariants.contentVariantTitle}
                  searchPlaceholder={
                    copyContent.cmsVariants.contentVariantSearchPlaceholder
                  }
                  noDataMessage="Please select a content area."
                />
              </div>
            </Fragment>
          )}

          {showDeleteButton && (
            <KiteButton
              className="experiment-variants__delete-variant"
              type="standalone-link"
              size="medium"
              onClick={event => this.handleCheckDeleteVariant(event, variant)}
            >
              Delete
            </KiteButton>
          )}
        </SmartExpansionPanel>
      )
    })
  }

  render() {
    const {
      contentAreaData: { loading: contentAreaLoading },
      contentsData: { loading: contentsLoading },
      experimentContentData: { loading: experimentContentDataLoading },
    } = this.props
    const {
      isLoading,
      variants,
      isDeleteVariantModalOpen,
      variantToDelete,
      isDeleting,
      errorMessage: { errorAlert },
    } = this.state

    if (contentAreaLoading || contentsLoading || experimentContentDataLoading) {
      return (
        <div className="app__loader">
          <KiteLoader size="7rem" />
        </div>
      )
    }

    const displayNewVariant = !this.handleValidateDisable()
    const showAddVariant = this.handleValidateRequiredFields(variants)

    return (
      <div className="content">
        <ExpansionPanel type="minimal" isExpanded={!!errorAlert}>
          <KiteAlert
            className="app__page-level-message"
            type="alert"
            description={errorAlert}
            linkText="Contact us."
            onLinkClick={() => {
              window.location = copyContent.cmsVariants.errorEmailSubject
            }}
          />
        </ExpansionPanel>

        {this.renderVariants()}

        <ExpansionPanel
          type="minimal"
          isExpanded={!isLoading && displayNewVariant && showAddVariant}
        >
          <KiteButton
            className="experiment-variants__add-variant"
            type="standalone-link"
            size="medium"
            onClick={this.handleAddVariant}
            leftIcon="plus-circle-f"
          >
            Add Another Variant
          </KiteButton>
        </ExpansionPanel>

        {isDeleteVariantModalOpen && variantToDelete && (
          <Modal
            message={copyContent.variants.deleteVariantWarning(variantToDelete)}
            subMessage={copyContent.variants.deleteVariantSubMessage}
            onConfirm={this.handleConfirmDeleteVariant}
            onDeny={this.handleCancelDeleteVariant}
            loading={isDeleting}
            buttonProps={{ confirm: { value: 'Delete Variant' } }}
          />
        )}
      </div>
    )
  }
}

export default flowRight(
  getContentAreas,
  getContents,
  getExperimentContent,
  insertExperimentVariants,
  updateExperimentVariants,
  deleteExperimentVariant,
  insertExperimentContent
)(Contents)
