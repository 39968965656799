import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { KiteIcon } from '@kite/react-kite'
import CircleLoader from '../CircleLoader/CircleLoader'
import './Button.scss'

const Button = props => {
  const {
    value,
    onClick,
    className,
    type,
    disabled,
    loading,
    success,
    setRight,
    leftIcon,
    rightIcon,
  } = props

  const buttonClassNames = classNames({
    'perfect-button': true,
    primary: !type,
    icon: type && type.includes('icon'),
    [type]: type,
    disabled: !success && disabled,
    loading,
    success,
    'set-right': setRight,
    flex: leftIcon || rightIcon,
    [className]: className,
    [value
      .replace(/[&#,+()$~%.'":*?<Fragment>{}]/g, '')
      .trim()
      .replace(/\s+/g, '-')]: true,
  })

  const buttonValueClassNames = classNames({
    button__value: true,
    left: leftIcon,
    right: rightIcon,
  })

  let loaderType

  switch (type) {
    case 'secondary': {
      loaderType = 'dark'
      break
    }
    case 'outline': {
      loaderType = null
      break
    }
    case 'warning-outline': {
      loaderType = 'warning'
      break
    }
    default: {
      loaderType = 'light'
      break
    }
  }

  const materialIcon = type && type.includes('icon')

  return (
    <button
      className={buttonClassNames}
      onClick={!disabled ? onClick : () => {}}
    >
      {success && <i className="material-icons success__icon">check</i>}
      {loading && !success && <CircleLoader size={20} type={loaderType} />}
      {leftIcon && <KiteIcon name={leftIcon} />}
      {!materialIcon && (
        <span className={buttonValueClassNames}>{value || 'Submit'}</span>
      )}
      {rightIcon && <KiteIcon name={rightIcon} />}
      {materialIcon && <i className="material-icons button__value">{value}</i>}
    </button>
  )
}

export default Button

Button.propTypes = {
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  success: PropTypes.bool,
  setRight: PropTypes.bool,
  leftIcon: PropTypes.string,
  rightIcon: PropTypes.string,
}

Button.defaultProps = {
  className: null,
  type: 'primary',
  disabled: false,
  loading: false,
  success: null,
  setRight: null,
  leftIcon: null,
  rightIcon: null,
}
