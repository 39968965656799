import React, { Component } from 'react'
import { object, func, array } from 'prop-types'
import flowRight from 'lodash/flowRight'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import { KiteButton, KiteLoader, KiteAlert } from '@kite/react-kite'
import { LinearProgressStepper } from '@kite/react-kite-plus'
import {
  hasPermission,
  permissionEnum,
  permissionEnumReverse,
  experimentTypeEnum,
  experimentStatusEnum,
} from '@charter/distillery-rules'

import { client } from '../../configuration/configApiClient'
import {
  getExperimentDetails,
  GET_EXPERIMENT_DETAILS,
  GET_ACTIVE_LOCKDOWNS,
} from '../../shared/queries'
import {
  APPROVE_TECH_SETTINGS,
  ACKNOWLEDGE_OVERRIDES_DELETION,
  UPDATE_EXPERIMENT_CONFIG,
  LOG_ERROR,
} from '../../shared/mutations'
import {
  capitalizeString,
  formatSetUpSteps,
  formatLoggingError,
  scrollToNavigationPosition,
} from '../../shared/utilities'
import initialSetUpSteps from '../../shared/data/initialSetUpSteps'
import { ExpansionPanel, Modal } from '../../componentLibrary'
import { TdcsUpdateNotification } from '../../components'

import {
  PlanStep,
  RolesStep,
  VariantsStep,
  MetricsStep,
  TechSettingStep,
  ActivationStep,
} from './steps'

import copyContent from './data/copyContent'
import './SetUpExperiment.scss'

const {
  EXPERIMENT_CREATE,
  EXPERIMENT_UPDATE,
  EXPERIMENT_UPDATE_RUNNING,
  EXPERIMENT_UPDATE_RUNNING_ALL,
  EXPERIMENT_UPDATE_VERSION_RUNNING,
  EXPERIMENT_APPROVE_TECH,
  TECH_UPDATE,
  ACTIVATION_UPDATE,
} = permissionEnum

export class SetUpExperiment extends Component {
  static propTypes = {
    user: object.isRequired,
    match: object.isRequired,
    history: object.isRequired,
    location: object.isRequired,
    currentlyViewing: object.isRequired,
    onNavigate: func.isRequired,
    applicationPermissions: array.isRequired,
    experimentDetailsData: object,
  }

  static defaultProps = {
    experimentDetailsData: {},
  }

  state = {
    activeStep: 'plan',
    setUpSteps: initialSetUpSteps,
    techApprovalSteps: ['variants', 'tech settings', 'activation'],
    redirectLocation: null,
    isDirty: false,
    isValid: false,
    isDisabled: false,
    isCancelModalOpen: false,
    areAllStepsComplete: false,
    shouldCheckActivationChange: false,
    isChangeActivationEventModalOpen: false,
    isOverridesDeleted: false,
    success: null,
    errorMessage: null,
    approveTechSettingsError: null,
    loading: { submit: false },
    isNetworkLockdownActive: false,
  }

  // LIFECYCLE METHODS
  async componentDidMount() {
    const {
      location,
      experimentDetailsData,
      match: {
        params: { id },
      },
    } = this.props

    if (location && location.pathname === '/experiments/new') {
      this.handleSetNewCurrentlyViewing()
    }

    if (id && experimentDetailsData.experiment) {
      await this.formatExistingExperiment()
    }

    // only check for active lockdowns if person does not have network lockdown permissions
    if (!this.props.networkLockdownPermission) {
      await this.isLockdownActive()
    }
  }

  async componentDidUpdate(prevProps) {
    const { experimentDetailsData } = this.props
    const { experimentDetailsData: prevData } = prevProps

    if (!prevData.experiment && experimentDetailsData.experiment) {
      await this.formatExistingExperiment()
    }

    if (
      experimentDetailsData.experiment &&
      prevData.experiment &&
      !isEqual(
        experimentDetailsData.experiment.permissions,
        prevData.experiment.permissions
      )
    ) {
      const {
        experimentDetailsData: {
          experiment: { environmentSamplings, permissions },
        },
      } = this.props

      this.handleSetPermissions(permissions, environmentSamplings)
    }
  }

  isLockdownActive = async () => {
    const res = await client.query({
      query: GET_ACTIVE_LOCKDOWNS,
    })

    if (
      res.data &&
      res.data.activeNetworkLockdowns &&
      res.data.activeNetworkLockdowns.length
    ) {
      await this.setState({ isNetworkLockdownActive: true })
    }
  }

  // LOCAL STATE CHANGES/TOGGLES
  handleSetPermissions = (experimentPermissions, environmentSamplings) => {
    const { applicationPermissions } = this.props

    const permissionsArgs = {
      applicationPermissions,
      experimentPermissions,
      environmentSamplings,
    }

    const canUpdateTechSettings = hasPermission({
      ...permissionsArgs,
      permissionId: TECH_UPDATE,
    })

    const canUpdateActivation = hasPermission({
      ...permissionsArgs,
      permissionId: ACTIVATION_UPDATE,
    })

    const canApproveTechSettings = hasPermission({
      experimentPermissions,
      permissionId: EXPERIMENT_APPROVE_TECH,
    })

    const canUpdateVersionRunning = hasPermission({
      ...permissionsArgs,
      permissionId: EXPERIMENT_UPDATE_VERSION_RUNNING,
    })

    const canUpdateRunning = hasPermission({
      ...permissionsArgs,
      permissionId: EXPERIMENT_UPDATE_RUNNING,
    })

    this.setState({
      canUpdateTechSettings,
      canUpdateActivation,
      canApproveTechSettings,
      canUpdateVersionRunning,
      canUpdateRunning,
    })
  }

  // Used from any 'step' components to inform this component of it's own status
  handleSetStepStatus = async (step, status) => {
    const { setUpSteps } = this.state

    const newSteps = cloneDeep(setUpSteps).map(element => {
      if (element.label === step) {
        return {
          ...element,
          status,
        }
      }
      return element
    })

    await this.setState({ setUpSteps: newSteps })

    return newSteps
  }

  handleSelectStep = nextStep => {
    const { history } = this.props
    const { setUpSteps, isDirty, activeStep } = this.state

    // Find the step prior to the clicked step; if the step before is not complete, do no navigate
    const stepBefore =
      setUpSteps[setUpSteps.findIndex(step => step.label === nextStep) - 1]

    // Temporary variable used to determine if moving on is allowed
    let canContinue

    // If trying to move to the below steps, 'plan' and 'roles' must be 'complete', 'variant' must not be 'initial'
    if (
      nextStep !== 'plan' &&
      nextStep !== 'roles' &&
      nextStep !== 'variants'
    ) {
      const planComplete =
        setUpSteps.find(step => step.label === 'plan').status === 'complete'
      const rolesComplete =
        setUpSteps.find(step => step.label === 'roles').status === 'complete'
      const variantComplete =
        setUpSteps.find(step => step.label === 'variants').status !== 'initial'

      canContinue = planComplete && rolesComplete && variantComplete

      // Else, the step prior to the next step must be completed
    } else {
      canContinue = stepBefore ? stepBefore.status === 'complete' : true
    }

    if (nextStep !== activeStep && canContinue) {
      if (isDirty) {
        // Show a double confirm for lost changes
        this.handleRedirect(null, nextStep)
      } else {
        // If the step is complete, move on and update the URL accordingly
        const pathArray = history.location.pathname.split('/')
        const newPath = `${pathArray
          .splice(0, pathArray.length - 1)
          .join('/')}/${nextStep}`

        history.replace(newPath)

        this.setState({ activeStep: nextStep, success: null })
      }
    }
  }

  // Used by any 'step' component to inform this component of any changes
  handleSetDirty = isDirty => {
    this.setState({ isDirty })
  }

  handleSetErrorMessages = async errorMessage => {
    await this.setState({ errorMessage })
  }

  handleRedirect = (event, redirectLocation) => {
    if (event) {
      event.preventDefault()
    }

    this.setState({ isCancelModalOpen: true, redirectLocation })
  }

  handleConfirmChangeSteps = async () => {
    const { redirectLocation } = this.state

    await this.setState({ isDirty: false, isCancelModalOpen: false })
    this.handleSelectStep(redirectLocation)
  }

  handleGoBack = event => {
    if (event) {
      event.preventDefault()
    }
    const { history } = this.props

    history.push('/experiments/draft')
  }

  handleUpdateProgress = ({ step, status, areAllStepsComplete }) => {
    this.setState({ areAllStepsComplete })
    this.handleSetStepStatus(step, status)
  }

  handleUpdateOverrideBanner = async isOverridesDeleted => {
    const {
      match: {
        params: { id },
      },
    } = this.props
    if (!isOverridesDeleted) {
      await client.mutate({
        mutation: ACKNOWLEDGE_OVERRIDES_DELETION,
        variables: {
          experimentId: Number(id),
        },
      })
    }
    await this.setState({ isOverridesDeleted })
  }

  handleShowSuccessMessage = activeStep => {
    const { successSubmitTitle, successSubmitDescription } = copyContent

    scrollToNavigationPosition()

    this.setState(({ loading }) => ({
      isDirty: false,
      loading: {
        ...loading,
        submit: false,
      },
      success: {
        title: successSubmitTitle(capitalizeString(activeStep)),
        description: successSubmitDescription,
      },
    }))

    setTimeout(() => this.setState({ success: null }), 5000)
  }

  handleFormSuccessActions = async response => {
    const {
      history,
      experimentDetailsData: { experiment },
    } = this.props
    const { areAllStepsComplete } = this.state

    if (areAllStepsComplete) {
      await this.handleUpdateCompleteForm()
    } else {
      const isNewlyCreated = typeof response === 'object'
      const experimentId = isNewlyCreated ? response.id : Number(experiment.id)
      const experimentConfig = isNewlyCreated
        ? this.formatBlankExperimentConfig()
        : experiment.config

      const {
        isSetUpComplete,
        nextStep,
      } = await this.handleCheckUpdateActiveStep({
        id: experimentId,
        config: experimentConfig,
      })

      const basePath = `/experiments/${experimentId}`
      const path = isSetUpComplete
        ? `${basePath}/rollout/overrides`
        : `${basePath}/set-up/${nextStep}`

      this.handleSetCurrentlyViewing({
        basePath,
        path,
        isSetUpComplete,
        experiment: response,
      })

      if (isSetUpComplete) {
        await this.handleCompletedForm({
          basePath,
          path,
          isSetUpComplete,
          experimentId,
        })
      } else {
        scrollToNavigationPosition()

        await this.setState({
          activeStep: nextStep,
          isDirty: false,
        })
      }

      history.replace(path)
    }
  }

  handleCompletedForm = async ({
    basePath,
    path,
    isSetUpComplete,
    experimentId,
  }) => {
    const { canApproveTechSettings } = this.state
    if (canApproveTechSettings) {
      await client.mutate({
        mutation: APPROVE_TECH_SETTINGS,
        variables: {
          experimentId,
        },
      })
    }

    this.handleSetCurrentlyViewing({ basePath, path, isSetUpComplete })
  }

  handleUpdateCompleteForm = async () => {
    const { activeStep } = this.state
    const {
      experimentDetailsData: { experiment },
    } = this.props

    await this.handleCheckUpdateActiveStep(experiment)

    this.handleShowSuccessMessage(activeStep)
  }

  handleCheckUpdateActiveStep = async ({ id, config }) => {
    const { activeStep, isValid } = this.state

    // By default the step status is complete if the step is valid
    // Unless the step has a custom isComplete method
    const stepIsComplete = this[activeStep].isComplete
      ? await this[activeStep].isComplete()
      : isValid

    const stepStatus = stepIsComplete ? 'complete' : 'incomplete'
    const newSteps = await this.handleSetStepStatus(activeStep, stepStatus)

    const newConfig = await this.handleUpdateExperimentConfig(
      id,
      config,
      activeStep,
      stepStatus
    )

    const { isSetUpComplete, activeStep: nextStep } = formatSetUpSteps(
      JSON.parse(newConfig),
      newSteps
    )

    return { isSetUpComplete, nextStep }
  }

  // FORMATTING METHODS
  formatExistingExperiment = async () => {
    const { setUpSteps } = this.state
    const {
      history,
      match: {
        params: { id, step },
      },
      experimentDetailsData: {
        experiment,
        experiment: { activationEventUuid },
      },
    } = this.props

    const {
      config,
      environmentSamplings,
      permissions,
      isOverridesDeleted,
    } = experiment

    const parsedConfig = JSON.parse(config)

    const { setUpSteps: newSteps, isSetUpComplete } = formatSetUpSteps(
      parsedConfig,
      setUpSteps
    )

    const shouldCheckActivationChange =
      newSteps.find(newStep => newStep.label === 'activation').status !==
        'initial' && activationEventUuid

    const isDisabled = this.validateIsDisabled()

    const basePath = `/experiments/${id}`
    const path = `${basePath}/set-up/${step}`

    history.replace(path)

    this.handleSetCurrentlyViewing({ basePath, path, isSetUpComplete })

    await this.handleSetPermissions(permissions, environmentSamplings)
    this.setState({
      isDisabled,
      shouldCheckActivationChange,
      areAllStepsComplete: isSetUpComplete,
      activeStep: step,
      setUpSteps: newSteps,
      isOverridesDeleted,
    })
  }

  formatBlankExperimentConfig = () => {
    const { setUpSteps } = this.state

    return JSON.stringify(
      setUpSteps.reduce(
        (prevValue, step, order) => {
          // eslint-disable-next-line no-param-reassign
          prevValue.setUpSteps[step.label] = {
            order,
            status: 'initial',
          }
          return prevValue
        },
        { setUpSteps: {} }
      )
    )
  }

  // API METHODS
  handleSubmit = async () => {
    const { activeStep, isDirty } = this.state

    await this.setState(({ loading }) => ({
      loading: {
        ...loading,
        submit: true,
      },
      errorMessage: null,
    }))

    // Find the matching class attribute of the active step and call it's handleSubmit method
    const wasSuccessful = isDirty ? await this[activeStep].handleSubmit() : true

    if (wasSuccessful) {
      await this.handleFormSuccessActions(wasSuccessful)
    } else {
      // If the return from the step is false, get the associated error message
      //   from the step component
      const errorMessage = this[activeStep].getErrorMessage()

      await this.setState({
        errorMessage,
      })
    }

    await this.setState(({ loading }) => ({
      loading: {
        ...loading,
        submit: false,
      },
    }))
  }

  handleUpdateExperimentConfig = async (
    experimentId,
    experimentConfig,
    activeStep,
    stepStatus
  ) => {
    // Additional try/catch needed as insert submission will not catch
    // an error when creating the config
    const id = Number(experimentId)

    try {
      const parsedConfig = JSON.parse(experimentConfig)
      parsedConfig.setUpSteps[activeStep].status = stepStatus

      const config = JSON.stringify(parsedConfig)

      const input = {
        id,
        config,
      }

      await client.mutate({
        mutation: UPDATE_EXPERIMENT_CONFIG,
        variables: {
          id,
          input,
        },
        refetchQueries: [
          {
            query: GET_EXPERIMENT_DETAILS,
            variables: {
              id,
            },
          },
        ],
      })

      return config
    } catch (error) {
      const input = formatLoggingError(error, { id })

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input,
        },
      })

      this.setState({
        errorMessage: copyContent.updateConfigError,
      })

      return null
    }
  }

  handleApproveTechSettings = async () => {
    const { activeStep, techApprovalSteps } = this.state
    const {
      match: {
        params: { id },
      },
      experimentDetailsData: {
        experiment: { config, experimentStatus, activationEventUuid },
      },
    } = this.props

    const parsedConfig = JSON.parse(config)

    const incompleteTechSteps = Object.keys(parsedConfig.setUpSteps).filter(
      step => {
        const match = techApprovalSteps.find(label => step === label)
        const isIncomplete =
          parsedConfig.setUpSteps[step].status === 'incomplete'

        if (match && isIncomplete) {
          return step
        }

        return false
      }
    )

    incompleteTechSteps.forEach(incompleteStep => {
      // DYNEXP-2319 Metrics Architecture
      // Need to make sure if metrics is incomplete we make them go to the metrics page
      if (incompleteStep === 'metrics') {
        parsedConfig.setUpSteps[incompleteStep].status = 'incomplete'
      } else {
        parsedConfig.setUpSteps[incompleteStep].status = 'complete'
      }

      if (incompleteStep === 'activation' && !activationEventUuid) {
        parsedConfig.setUpSteps[incompleteStep].status = 'incomplete'
      }
    })

    const areAllStepsComplete =
      Object.keys(parsedConfig.setUpSteps).filter(
        step => parsedConfig.setUpSteps[step].status !== 'complete'
      ).length === 0

    const newConfig = JSON.stringify(parsedConfig)

    const configInput = {
      config: newConfig,
      id: Number(id),
    }

    try {
      await client.mutate({
        mutation: APPROVE_TECH_SETTINGS,
        variables: {
          experimentId: Number(id),
        },
      })

      await client.mutate({
        mutation: UPDATE_EXPERIMENT_CONFIG,
        variables: {
          id: Number(id),
          input: configInput,
        },
      })

      const basePath = `/experiments/${experimentStatus.name.toLowerCase()}/${id}`
      const path = `${basePath}/set-up/${activeStep}`

      this.handleSetCurrentlyViewing({
        basePath,
        path,
        isSetUpComplete: areAllStepsComplete,
      })

      this.setState(({ setUpSteps }) => ({
        areAllStepsComplete,
        setUpSteps: Object.keys(setUpSteps).map(step => {
          const stepObject = setUpSteps[step]

          if (incompleteTechSteps.includes(stepObject.label)) {
            return {
              ...stepObject,
              status: 'complete',
            }
          }

          return stepObject
        }),
      }))
    } catch (error) {
      const input = formatLoggingError(error, { id })

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input,
        },
      })

      this.setState({ approveTechSettingsError: true })
    }
  }

  // VALIDATION METHODS
  validateIsDisabled = () => {
    const {
      applicationPermissions,
      experimentDetailsData: { experiment },
    } = this.props

    const {
      isFinalized,
      environmentSamplings,
      permissions: experimentPermissions,
      experimentStatus,
    } = experiment

    const permissionsArgs = {
      applicationPermissions,
      experimentPermissions,
      environmentSamplings,
    }

    const canUpdateAllRunning = hasPermission({
      ...permissionsArgs,
      permissionId: EXPERIMENT_UPDATE_RUNNING_ALL,
    })

    // This admin permission always enables the fields
    if (canUpdateAllRunning) {
      // Unless the status of the expermint is completed or cancelled
      return (
        experimentStatus.id === experimentStatusEnum.COMPLETED ||
        experimentStatus.id === experimentStatusEnum.CANCELLED
      )
    }

    const canUpdate = hasPermission({
      ...permissionsArgs,
      permissionId: EXPERIMENT_UPDATE,
    })

    if (!canUpdate || isFinalized) {
      return true
    }

    return false
  }

  validateCanSubmit = async () => {
    const { activeStep } = this.state
    const isValid = await this[activeStep].validateSubmission()
    await this.setState({ isValid })
    return isValid
  }

  // REF METHODS
  handleSetNewCurrentlyViewing = () => {
    const { onNavigate } = this.props

    const path = '/experiments/new'

    const currentlyViewing = {
      path,
      title: 'Create Draft',
      backPath: '/experiments/draft',
      backTitle: 'Draft Experiments',
      tabs: {
        currentTab: 'set up',
        tabs: [
          { label: 'set up', path },
          { label: 'rollout', path, disabled: true },
          { label: 'results', path, disabled: true },
        ],
      },
    }

    onNavigate(currentlyViewing)
  }

  handleSetCurrentlyViewing = ({
    experiment = null,
    basePath,
    path,
    isSetUpComplete,
  }) => {
    const {
      onNavigate,
      experimentDetailsData: { experiment: existingExperiment },
      history,
    } = this.props

    const currentExperiment = existingExperiment || experiment

    const {
      name,
      experimentStatus,
      experimentToProductFeatures,
    } = currentExperiment

    const status = experimentStatus.name.toLowerCase()

    const { product, productFeature } = experimentToProductFeatures[0]

    let currentlyViewing = {
      path,
      title: name,
      subTitle: `${product.displayName} - ${productFeature.displayName}`,
      backPath: `/experiments/${status}`,
      backTitle: `${status} Experiments`,
      tabs: {
        currentTab: 'set up',
        tabs: [
          { label: 'set up', path: `${basePath}/set-up/plan` },
          {
            label: 'rollout',
            path: `${basePath}/rollout/overrides`,
            disabled: !isSetUpComplete,
          },
          {
            label: 'results',
            path: `${basePath}/results`,
            disabled: !isSetUpComplete,
          },
        ],
      },
    }

    if (status === 'deleted') {
      currentlyViewing = {
        path: '/experiments/all',
        backPath: path,
        backTitle: name,
      }

      history.push('/experiments/all')
    }

    onNavigate(currentlyViewing)
  }

  render() {
    const {
      activeStep,
      setUpSteps,
      redirectLocation,
      techApprovalSteps,
      isDirty,
      isDisabled,
      isCancelModalOpen,
      areAllStepsComplete,
      canUpdateRunning,
      canUpdateTechSettings,
      canUpdateActivation,
      canApproveTechSettings,
      canUpdateVersionRunning,
      shouldCheckActivationChange,
      isChangeActivationEventModalOpen,
      success,
      loading,
      errorMessage,
      approveTechSettingsError,
      isOverridesDeleted,
      isNetworkLockdownActive,
    } = this.state

    const {
      user,
      history,
      match: {
        params: { id },
      },
      onNavigate,
      currentlyViewing,
      applicationPermissions,
      experimentDetailsData: { loading: detailsLoading, experiment },
    } = this.props

    const {
      saveButton,
      saveAndContinueButton,
      cancelButton,
      techPocApprovalTitle,
      techPocDescription,
      techPocApproveButton,
      needsApprovalTitle,
      needsApprovalDescription,
      errorApproveSettingsTitle,
      errorApproveSettingsDescription,
      errorApproveLinkText,
      errorApproveEmailSubject,
      overrideNotice,
      activation: { changeConfirmMessage, changeConfirmButton },
    } = copyContent

    if (detailsLoading) {
      return (
        <div className="app__loader">
          <KiteLoader size="7rem" />
        </div>
      )
    }

    const config = experiment
      ? experiment.config
      : this.formatBlankExperimentConfig()

    const isTechApprovalStepIncomplete =
      techApprovalSteps.includes(activeStep) &&
      setUpSteps.find(step => step.label === activeStep).status === 'incomplete'

    const noTechApprovalStepsInitial =
      techApprovalSteps.filter(
        techStep =>
          setUpSteps.find(step => step.label === techStep).status === 'initial'
      ).length === 0

    const stepStatus = setUpSteps.find(step => step.label === activeStep).status

    const isTechPocApproved = experiment && experiment.isTechPocApproved
    let techUpdate = false
    let productUuid
    let experimentStartDate
    let experimentCreatedDate
    let experimentTypeId
    let experimentStatusId

    if (experiment) {
      const {
        environmentSamplings,
        permissions: experimentPermissions,
        experimentToProductFeatures,
        startTime,
        createdTime,
        experimentType: { id: typeId },
        experimentStatus: { id: statusId },
      } = experiment

      const permissionsArgs = {
        applicationPermissions,
        experimentPermissions,
        environmentSamplings,
      }

      const {
        product: { uuid },
      } = experimentToProductFeatures[0]
      experimentStartDate = startTime
      experimentCreatedDate = createdTime
      productUuid = uuid
      experimentTypeId = typeId
      experimentStatusId = statusId

      // Verify tech POC can save variants
      techUpdate =
        hasPermission({
          ...permissionsArgs,
          permissionId: TECH_UPDATE,
        }) && activeStep === 'variants'
    }

    const planSummaryEnabled = activeStep === 'plan' && canUpdateRunning
    const rolesEnabled = activeStep === 'roles' && canUpdateRunning
    const variantsEnabled =
      activeStep === 'variants' && (canUpdateRunning || canUpdateVersionRunning)
    const techVersionEnabled =
      activeStep === 'tech settings' &&
      (canUpdateTechSettings || canUpdateVersionRunning)
    const activationEnabled =
      activeStep === 'activation' && (canUpdateRunning || canUpdateActivation)
    const updateRunningSubmitEnabled =
      planSummaryEnabled ||
      rolesEnabled ||
      variantsEnabled ||
      techVersionEnabled ||
      activationEnabled
    let enabledSubmit = updateRunningSubmitEnabled
      ? false
      : (isDisabled && !techUpdate) || loading.submit

    if (
      activeStep === 'variants' &&
      experimentTypeId === experimentTypeEnum.AB
    ) {
      enabledSubmit =
        loading.submit ||
        (isDisabled && !techUpdate && experiment.permissions.length === 0) ||
        experimentStatusId === experimentStatusEnum.COMPLETED ||
        experimentStatusId === experimentStatusEnum.CANCELLED
    }

    return (
      <form className="set-up-experiment">
        <h4>Set Up Your Experiment</h4>
        <LinearProgressStepper
          className="set-up-experiment__stepper"
          activeStep={activeStep}
          steps={setUpSteps}
          onClick={this.handleSelectStep}
          leanLeft
        />

        <TdcsUpdateNotification
          id={id}
          environmentSampling={
            experiment ? experiment.environmentSamplings : []
          }
          history={history}
          activeNetworkLockdown={isNetworkLockdownActive}
        />

        <ExpansionPanel type="minimal" isExpanded={!!approveTechSettingsError}>
          <KiteAlert
            className="app__page-level-message"
            type="alert"
            title={errorApproveSettingsTitle}
            description={errorApproveSettingsDescription}
            linkText={errorApproveLinkText}
            onLinkClick={() => {
              window.location = errorApproveEmailSubject(user)
            }}
            onClose={() => this.setState({ approveTechSettingsError: null })}
          />
        </ExpansionPanel>

        <ExpansionPanel type="minimal" isExpanded={!!errorMessage}>
          <KiteAlert
            className="app__page-level-message"
            type="alert"
            description={errorMessage && `${errorMessage}. If this continues `}
            linkText="contact support."
            onLinkClick={() => {
              window.location = `mailto:DL-Distillery-Support@charter.com?subject=Distillery Support for Publishing - ${user.displayName}`
            }}
            onClose={() => this.setState({ errorMessage: null })}
          />
        </ExpansionPanel>

        <ExpansionPanel
          type="minimal"
          isExpanded={
            noTechApprovalStepsInitial &&
            isTechApprovalStepIncomplete &&
            !isTechPocApproved &&
            !canApproveTechSettings
          }
        >
          <KiteAlert
            className="app__page-level-message"
            type="caution"
            title={needsApprovalTitle}
            description={needsApprovalDescription}
          />
        </ExpansionPanel>

        <ExpansionPanel type="minimal" isExpanded={isOverridesDeleted}>
          <KiteAlert
            className="app__page-level-message"
            type="caution"
            description={overrideNotice}
            onClose={() => this.handleUpdateOverrideBanner(false)}
          />
        </ExpansionPanel>
        <ExpansionPanel
          type="minimal"
          isExpanded={
            noTechApprovalStepsInitial &&
            isTechApprovalStepIncomplete &&
            !isTechPocApproved &&
            canApproveTechSettings
          }
        >
          <KiteAlert
            className="app__page-level-message"
            type="info"
            title={techPocApprovalTitle}
            description={techPocDescription}
            linkText={techPocApproveButton}
            onLinkClick={this.handleApproveTechSettings}
          />
        </ExpansionPanel>

        <ExpansionPanel type="minimal" isExpanded={!!success}>
          <KiteAlert
            className="app__page-level-message"
            type="confirm"
            title={success && success.title}
            description={success && success.description}
          />
        </ExpansionPanel>

        {activeStep === 'plan' && (
          <PlanStep
            id={Number(id)}
            onChange={this.handleSetDirty}
            disabled={isDisabled}
            permissionEnum={permissionEnumReverse[EXPERIMENT_CREATE]}
            currentlyViewing={currentlyViewing}
            applicationPermissions={applicationPermissions}
            experimentPermissions={experiment ? experiment.permissions : []}
            environmentSamplings={experiment && experiment.environmentSamplings}
            experimentConfig={config}
            formatBlankExperimentConfig={this.formatBlankExperimentConfig}
            onUpdateProgress={this.handleUpdateProgress}
            handleUpdateOverrideBanner={this.handleUpdateOverrideBanner}
            onNavigate={onNavigate}
            ref={component => {
              const plan =
                component &&
                component
                  .getWrappedInstance()
                  .getWrappedInstance()
                  .getWrappedInstance()
                  .getWrappedInstance()
                  .getWrappedInstance()
              this.plan = plan
            }}
          />
        )}
        {activeStep === 'roles' && id && (
          <RolesStep
            id={Number(id)}
            applicationPermissions={applicationPermissions}
            experimentPermissions={experiment ? experiment.permissions : []}
            environmentSamplings={experiment && experiment.environmentSamplings}
            disabled={isDisabled}
            onChange={this.handleSetDirty}
            ref={component => {
              if (component && component.getWrappedInstance) {
                this.roles = component
                  .getWrappedInstance()
                  .getWrappedInstance()
                  .getWrappedInstance()
                  .getWrappedInstance()
              }
            }}
          />
        )}
        {activeStep === 'variants' && (
          <VariantsStep
            id={Number(id)}
            applicationPermissions={applicationPermissions}
            experimentPermissions={experiment ? experiment.permissions : []}
            environmentSamplings={experiment && experiment.environmentSamplings}
            onChange={this.handleSetDirty}
            disabled={isDisabled}
            canApproveJsonPayload={canApproveTechSettings}
            ref={component => {
              const variants =
                component &&
                component
                  .getWrappedInstance()
                  .getWrappedInstance()
                  .getWrappedInstance()
                  .getWrappedInstance()
                  .getWrappedInstance()
                  .getWrappedInstance()
                  .getWrappedInstance()

              this.variants = variants
            }}
          />
        )}

        {activeStep === 'metrics' && (
          <MetricsStep
            id={Number(id)}
            onChange={this.handleSetDirty}
            disabled={isDisabled}
            productUuid={productUuid}
            experimentStartDate={experimentStartDate}
            handleSetErrorMessages={this.handleSetErrorMessages}
            experimentCreatedDate={experimentCreatedDate}
            ref={component => {
              this.metrics = component
            }}
          />
        )}

        {activeStep === 'tech settings' && (
          <TechSettingStep
            id={Number(id)}
            disabled={isDisabled && !canUpdateTechSettings}
            experimentConfig={config}
            canApproveTechSettings={canApproveTechSettings}
            applicationPermissions={applicationPermissions}
            experimentPermissions={experiment ? experiment.permissions : []}
            environmentSamplings={experiment && experiment.environmentSamplings}
            onChange={this.handleSetDirty}
            handleUpdateOverrideBanner={this.handleUpdateOverrideBanner}
            ref={component => {
              const techSettings = component && component.getWrappedInstance()
              this[activeStep] = techSettings
            }}
          />
        )}

        {activeStep === 'activation' && (
          <ActivationStep
            id={Number(id)}
            onChange={this.handleSetDirty}
            disabled={isDisabled && !canUpdateActivation}
            status={stepStatus}
            canApproveActivationEvent={canApproveTechSettings}
            ref={component => {
              const activation =
                component && component.getWrappedInstance().getWrappedInstance()
              this[activeStep] = activation
            }}
          />
        )}

        <div
          className={`set-up-experiment__footer${errorMessage ? ' error' : ''}`}
        >
          <KiteButton
            className="set-up-experiment__continue-button"
            size="large"
            disabled={enabledSubmit}
            loading={loading.submit}
            onClick={async event => {
              if (await this.validateCanSubmit()) {
                if (
                  shouldCheckActivationChange &&
                  activeStep === 'activation' &&
                  isDirty
                ) {
                  this.setState({
                    isChangeActivationEventModalOpen: true,
                  })
                } else {
                  this.handleSubmit(event)
                }
              }
            }}
          >
            {areAllStepsComplete ? saveButton : saveAndContinueButton}
          </KiteButton>

          <KiteButton
            className="set-up-experiment__cancel-button"
            size="small"
            type="standalone-link"
            disabled={loading.submit}
            onClick={
              isDirty
                ? event => this.handleRedirect(event, 'back')
                : this.handleGoBack
            }
          >
            {cancelButton}
          </KiteButton>
        </div>

        {isCancelModalOpen && (
          <Modal
            message={
              redirectLocation === 'back'
                ? 'Are you sure you want to cancel?'
                : 'Did you want to save your changes?'
            }
            subMessage="Any unsaved changes will be lost"
            onConfirm={
              redirectLocation === 'back'
                ? this.handleGoBack
                : this.handleConfirmChangeSteps
            }
            onDeny={() => this.setState({ isCancelModalOpen: false })}
            buttonProps={{ confirm: { value: 'Cancel Changes' } }}
          />
        )}

        {isChangeActivationEventModalOpen && (
          <Modal
            message={changeConfirmMessage}
            onConfirm={() => {
              this.setState({
                isChangeActivationEventModalOpen: false,
              })
              this.handleSubmit()
            }}
            onDeny={() =>
              this.setState({
                isChangeActivationEventModalOpen: false,
              })
            }
            buttonProps={{
              confirm: {
                value: changeConfirmButton,
                type: 'outline',
              },
              deny: { type: 'warning-outline' },
            }}
          />
        )}
      </form>
    )
  }
}

export default flowRight(getExperimentDetails)(SetUpExperiment)
