import React, { Component } from 'react'
import dayjs from 'dayjs'
import { object, array, func, string } from 'prop-types'
import flowRight from 'lodash/flowRight'
import isEqual from 'lodash/isEqual'

import { permissionEnum, evaluationStatusEnum } from '@charter/distillery-rules'

import { KiteLoader } from '@kite/react-kite'
import { SortableTable } from '@kite/react-kite-plus'

import copyContent from './data/copyContent'

import { GqlBuilder } from '../../shared/utilities'
import getExperimentEvaluationCards from './queries/getExperimentEvaluationCards'
import './ExperimentEvaluationList.scss'

const gqlHOCQuery = new GqlBuilder().compileHOCQuery([
  getExperimentEvaluationCards,
])

export class ExperimentEvaluationList extends Component {
  static propTypes = {
    queryData: object.isRequired,
    applicationPermissions: array.isRequired,
    onNavigate: func.isRequired,
    location: object.isRequired,
    statusFilter: string,
  }

  static defaultProps = {
    statusFilter: null,
  }

  state = {
    quantumEvaluator: false,
  }

  // LIFECYCLE METHODS
  componentDidMount() {
    const {
      location: { pathname: path },
      onNavigate,
      statusFilter,
    } = this.props

    this.setState({
      quantumEvaluator: this.checkExperimentEvaluatorPermission(),
    })

    const title =
      statusFilter === 'to-evaluate'
        ? statusFilter.replace('-', ' ')
        : `${statusFilter} Evaluations`

    onNavigate({
      path,
      title,
      backPath: path,
      backTitle: title,
    })
  }

  componentDidUpdate(prevProps) {
    const {
      queryData,
      queryData: { experimentEvaluationCards },
    } = this.props
    const { queryData: prevData } = prevProps

    if (experimentEvaluationCards && !isEqual(queryData, prevData)) {
      this.render()
    }
  }

  checkExperimentEvaluatorPermission = () => {
    const { applicationPermissions } = this.props

    const perm = applicationPermissions.find(
      permission => permission.id === permissionEnum.EVALUATE_EXPERIMENT
    )

    return perm && perm.id === permissionEnum.EVALUATE_EXPERIMENT
  }

  handleFormatTableColumns = () => {
    const { quantumEvaluator } = this.state
    const { statusFilter } = this.props
    let columns = [
      {
        sortKey: 'name',
        label: 'Experiment Name',
        size: 1.2,
      },
    ]

    if (quantumEvaluator && statusFilter !== 'not-evaluating') {
      columns = [
        ...columns,
        {
          sortKey: 'startDate',
          label: 'Launched',
          size: 0.8,
        },
        {
          sortKey: 'stopDate',
          label: 'Completed/Cancelled',
          size: 0.8,
        },
      ]
    } else if (quantumEvaluator && statusFilter === 'not-evaluating') {
      columns = [
        ...columns,
        {
          sortKey: 'changedComment',
          label: 'Reason Not Evaluating',
          sortEnabled: false,
          size: 1.75,
        },
        {
          sortKey: 'stopDate',
          label: 'Completed/Cancelled',
          size: 0.8,
        },
      ]
    } else {
      columns = [
        ...columns,
        {
          sortKey: 'product',
          label: 'Product',
          size: 0.75,
        },
        {
          sortKey: 'outcome',
          label: 'Outcome',
          size: 1,
        },
        {
          sortKey: 'stopDate',
          label: 'Completed/Cancelled',
          size: 0.75,
        },
      ]
    }

    return columns
  }

  filterFormatExperiments = () => {
    const {
      queryData: { experimentEvaluationCards },
      statusFilter,
    } = this.props

    const { quantumEvaluator } = this.state
    let statusFilterToId

    // Verify if user has permissions on evaluations and what section user is on
    if (quantumEvaluator && statusFilter === 'to-evaluate') {
      statusFilterToId = evaluationStatusEnum.PENDING
    } else if (quantumEvaluator && statusFilter === 'in-progress') {
      statusFilterToId = evaluationStatusEnum.IN_PROGRESS
    } else if (quantumEvaluator && statusFilter === 'not-evaluating') {
      statusFilterToId = evaluationStatusEnum.NOT_EVALUATING
    } else {
      statusFilterToId = evaluationStatusEnum.COMPLETE
    }

    // Filter experiments
    const filteredExperiments = experimentEvaluationCards
      .filter(
        ({ evaluationStatusId }) => evaluationStatusId === statusFilterToId
      )
      .map(experimentCard => {
        const {
          id,
          experiment: {
            id: experimentId,
            name,
            experimentStatus: { name: experimentStatus },
            experimentToProductFeatures,
            finalOutcome,
            startTime,
            stopTime,
          },
          evaluationStatusId,
          experimentEvaluationAudits,
        } = experimentCard

        const auditComments = []
        experimentEvaluationAudits.forEach(({ changedComment }) => {
          if (changedComment) {
            auditComments.push(changedComment)
          }
        })

        return {
          id,
          experimentId,
          experimentStatus,
          name,
          startTime,
          stopTime,
          evaluationStatusId,
          changedComment: auditComments.join(';'),
          startDate: dayjs(startTime).isValid()
            ? dayjs(startTime).format('MM/DD/YY')
            : '-',
          stopDate: dayjs(stopTime).isValid()
            ? dayjs(stopTime).format('MM/DD/YY')
            : '-',
          product: experimentToProductFeatures[0].product.displayName,
          outcome: finalOutcome,
        }
      })

    return filteredExperiments
  }

  renderExperimentEvaluationCards() {
    const {
      onNavigate,
      statusFilter,
      location: { pathname: path },
    } = this.props
    const { quantumEvaluator } = this.state
    const backTitle =
      statusFilter === 'to-evaluate'
        ? statusFilter.replace('-', ' ')
        : `${statusFilter} Evaluations`

    const formattedExperimentsCards = this.filterFormatExperiments()

    return (
      <SortableTable
        tableData={formattedExperimentsCards}
        columns={this.handleFormatTableColumns()}
        onRowClick={evaluationCard => {
          const { id } = evaluationCard
          onNavigate({
            path: `/experiment-evaluation/${id}/general`,
            title: ' ',
            backPath: path,
            backTitle,
          })
        }}
        noDataMessage={
          quantumEvaluator
            ? copyContent.noExperimentsQuantumEvaluator
            : copyContent.noExperimentsOtherUsers
        }
        ref={element => {
          this.table = element
        }}
      />
    )
  }

  render() {
    const {
      queryData: { loading },
      statusFilter,
    } = this.props

    const { quantumEvaluator } = this.state

    if (loading) {
      return (
        <div>
          <h4>View Results</h4>
          <div className="app__loader">
            <KiteLoader size="7rem" />
          </div>
        </div>
      )
    }

    const classAccentColorExt =
      !quantumEvaluator || statusFilter === 'not-evaluating'
        ? '__accent-color'
        : '__no-accent-color'
    const classAccentChildExt =
      !quantumEvaluator || statusFilter === 'not-evaluating'
        ? ''
        : 'experiment-evaluation__border-rounded'

    return (
      <div className="experiment-evaluation">
        <div className="experiment-evaluation__accent-color-container">
          <div className={`experiment-evaluation${classAccentColorExt}`}>
            <div
              className={`experiment-evaluation__accent-color-children ${classAccentChildExt}`}
            >
              {this.renderExperimentEvaluationCards()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default flowRight(gqlHOCQuery)(ExperimentEvaluationList)
