import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

/*
 *  Input: the id of the experiment
 */
export const GET_TDCS_SETTINGS = gql`
  query getTdcsSettings($id: Int!) {
    experiment(id: $id) {
      id
      isTechPocApproved
      experimentTypeId
      experimentStatusId
      isOverridesDeleted
      experimentToProductFeatures {
        productId
      }
      exclusive
    }
    TdcsExperimentSettings(experimentId: $id) {
      id
      productMinVersion
      productMaxVersion
      minAvPlatformVersion
      maxAvPlatformVersion
      experimentId
      tdcsSessionType {
        id
        name
        displayName
      }
      tdcsExperimentTechSettings {
        id
        tdcsExperimentSettingsId
        textValue
        isSelected
        isIncluded
        tdcsSessionTypeToProductId
        tdcsSessionTypeToProduct {
          id
          productId
          isEnabled
          tdcsSessionTypeId
          tdcsFilter {
            id
            displayName
            displayOrder
            tdcsFilterType {
              id
              displayName
            }
          }
        }
      }
    }
    TDCSExperiment(experimentId: $id) {
      id
      experimentId
      productMinVersion
      productMaxVersion
      tdcsInformCall {
        id
        name
        displayName
      }
      tdcsExperimentFilters {
        id
        textValue
        isEnabled
        isIncluded
        tdcsFilter {
          id
          name
          displayName
          displayOrder
          tdcsFilterType {
            id
            name
            displayName
            isBoolean
          }
        }
      }
    }
    countExclusiveExperimentsByExperimentProduct(experimentId: $id)
  }
`

const getTdcsSettings = graphql(GET_TDCS_SETTINGS, {
  name: 'tdcsSettingsData',
  withRef: true,
  options: {
    fetchPolicy: 'network-only',
  },
})

export default getTdcsSettings
