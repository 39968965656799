import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

export const GET_CURRENT_USER = gql`
  query getCurrentUser {
    currentUser {
      id
      firstName
      lastName
      displayName
      email
      config
      userToRoles {
        id
        roleId
      }
      inAppNotifications {
        id
        subject
        body
        isRead
        notificationTime
        notificationDefinition {
          id
          name
        }
        environment {
          id
          name
        }
        experiment {
          id
          name
          experimentToProductFeatures {
            id
            product {
              id
              displayName
              productCategoryType {
                id
                name
              }
            }
          }
          environmentSamplings {
            id
            environmentId
            allocationHistory {
              id
              comment
              createdTime
            }
          }
        }
      }
    }
    applicationPermissions {
      id
      name
    }
    featureFlags {
      id
      name
      active
      lastModifiedTime
    }
    hasInAppPreferences
  }
`

const getCurrentUser = graphql(GET_CURRENT_USER)

export default getCurrentUser
