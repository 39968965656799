import { decorate, observable, computed, action } from 'mobx'

class PagedArrayModel {
  pageLength = 10
  currentPage = 1
  arrayData = null

  constructor(arrayData, pageLength = 10) {
    this.arrayData = arrayData
    this.pageLength = pageLength
  }

  get numOfPages() {
    if (!this.arrayData) return 0
    return Math.ceil(this.arrayData.length / this.pageLength)
  }

  get page() {
    const startIndex = (this.currentPage - 1) * this.pageLength
    const endIndex = Math.min(
      startIndex + this.pageLength,
      this.arrayData.length
    )

    const page = this.arrayData.slice(
      startIndex,
      Math.min(endIndex, this.arrayData.length)
    )
    return page
  }

  gotoLastPage = () => {
    if (this.hasLastPage) this.currentPage = this.currentPage - 1
    else throw Error('There is no page before this one')
  }

  gotoNextPage = () => {
    if (this.hasNextPage) this.currentPage = this.currentPage + 1
    else throw Error('There is no page after this one')
  }

  get hasNextPage() {
    return this.currentPage < this.numOfPages
  }

  get hasLastPage() {
    return this.currentPage > 1
  }

  numOfLinks = 6
}

PagedArrayModel = decorate(PagedArrayModel, {
  arrayData: observable.ref,
  currentPage: observable,
  hasNextPage: computed,
  hasLastPage: computed,
  numOfPages: computed,
  gotoNextPage: action,
  gotoLastPage: action,
})

export default PagedArrayModel
