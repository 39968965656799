const formatNumberArray = number =>
  number
    .toString()
    .split('')
    .filter(element => element !== '-')

const commaSeparateNumber = number => {
  if (!number) {
    number = 0
  }
  const decimalSplitArray = number.toString().split('.')
  const beforeDecimal = Number(decimalSplitArray[0])
  const afterDecimal =
    decimalSplitArray.length > 1 ? Number(decimalSplitArray[1]) : false

  const numberArray = formatNumberArray(beforeDecimal)
  const newNumberArray = []

  for (let i = numberArray.length - 1; i >= 0; i--) {
    const countArray = newNumberArray.filter(element => element !== ',')

    if (i !== numberArray.length - 1 && countArray.length % 3 === 0) {
      newNumberArray.unshift(',')
    }

    newNumberArray.unshift(numberArray[i])
  }

  const combinedNumber = afterDecimal
    ? `${newNumberArray.join('')}.${afterDecimal}`
    : newNumberArray.join('')

  const returnedValue = number < 0 ? `-${combinedNumber}` : combinedNumber

  return returnedValue
}

export default commaSeparateNumber
