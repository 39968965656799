import React, { Component, Fragment } from 'react'
import {
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
  Font,
  BlobProvider,
} from '@react-pdf/renderer'
import { string, object } from 'prop-types'
import dayjs from 'dayjs'
import { KiteFontIcon } from '@kite/react-kite'

import {
  experimentEnvironmentEnum,
  experimentStatusEnum,
} from '@charter/distillery-rules'

const { PRODUCTION } = experimentEnvironmentEnum
const { CANCELLED } = experimentStatusEnum

Font.register({
  family: 'Rutledge',
  fontWeight: 400,
  src: '/assets/fonts/rutledge-regular.woff',
})

Font.register({
  family: 'Rutledge',
  fontWeight: 500,
  src: '/assets/fonts/rutledge-medium.woff',
})

Font.register({
  family: 'Rutledge',
  fontStyle: 'italic',
  src: '/assets/fonts/rutledge-lightitalic.woff',
})

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    paddingLeft: 40,
    paddingRight: 30,
    paddingTop: 30,
    paddingBottom: 30,
    fontWeight: 400,
    fontSize: 12,
    fontFamily: 'Rutledge',
  },
  header: {
    display: 'flex',
    marginBottom: 25,
  },
  headerInner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logo: {
    width: 112,
    height: 25,
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
  },
  experimentName: {
    fontSize: 16,
  },
  section: {
    marginBottom: 25,
    width: '100%',
  },
  line: {
    width: '100%',
    height: 1,
    backgroundColor: '#e7e7e7',
    marginTop: 30,
  },
  subtitle: {
    fontSize: 14,
    marginBottom: 12,
  },
  paragraphText: {
    fontSize: 12,
    lineHeight: 1.42,
    color: '#3f3f3f',
  },
  variantsSection: {
    display: 'flex',
    flexDirection: 'row',
  },
  variants: {
    display: 'flex',
    marginLeft: 10,
    flex: 1,
  },
  variant: {
    display: 'flex',
    marginBottom: 25,
    marginRight: 10,
  },
  variantName: {
    fontSize: 14,
    marginBottom: 12,
  },
  variantDescription: {
    fontSize: 10,
    marginRight: 10,
    marginBottom: 10,
  },
  variantWinner: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 12,
  },
  variantWinnerName: {
    fontSize: 14,
    color: '#00bf1f',
  },
  variantWinnerText: {
    color: '#00bf1f',
    fontSize: 9,
    fontWeight: 500,
    marginLeft: 6,
  },
})

export const PDFDownloadLink = ({
  document: doc,
  className,
  children,
  fileName = 'document.pdf',
}) => {
  const downloadOnIE = blob => () => {
    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(blob, fileName)
    }
  }

  return (
    <BlobProvider document={doc}>
      {params => (
        <a
          className={className}
          href={params.url}
          target="_blank"
          rel="noopener noreferrer"
          onClick={downloadOnIE(params.blob)}
        >
          {typeof children === 'function' ? children(params) : children}
        </a>
      )}
    </BlobProvider>
  )
}

PDFDownloadLink.propTypes = {
  document: object.isRequired,
  className: string,
  children: object.isRequired,
  fileName: string,
}

PDFDownloadLink.defaultProps = {
  className: null,
  fileName: null,
}
export default class FinalOutcomePDF extends Component {
  static propTypes = {
    experiment: object.isRequired,
    className: string,
  }

  static defaultProps = {
    className: null,
  }

  shouldComponentUpdate() {
    return !this.isLoading
  }

  render() {
    const { experiment, className } = this.props

    const {
      name,
      hypothesis,
      finalOutcome,
      variants,
      winnerVariantId,
      environmentSamplings,
    } = experiment

    const {
      experimentStatus: { id: prodStatus },
      lastModifiedTime,
      lastModifiedUser: { firstName, lastName },
    } = environmentSamplings.find(
      ({ environment: { id } }) => id === PRODUCTION
    )

    const isCancelled = prodStatus === CANCELLED

    return (
      <PDFDownloadLink
        className="experiment-results__report-link"
        fileName={`${name} Results.pdf`}
        document={
          <Document>
            <Page size="A4" style={styles.page}>
              <View style={styles.header}>
                <View style={styles.headerInner}>
                  <Image
                    style={styles.logo}
                    src="/assets/images/png/distillery_logo.png"
                  />
                  <View>
                    <Text style={styles.experimentName}>{name}</Text>
                    <Text
                      style={{
                        fontSize: 11,
                        marginTop: 2,
                      }}
                    >
                      {`${
                        isCancelled ? 'Cancelled' : 'Completed'
                      } by ${firstName} ${lastName} on ${dayjs(
                        lastModifiedTime
                      ).format('MMMM DD, YYYY')}`}
                    </Text>
                  </View>
                </View>
                <View style={styles.line} />
              </View>
              <View style={styles.section}>
                <Text style={styles.subtitle}>Hypothesis</Text>
                <Text style={styles.paragraphText}>{hypothesis}</Text>
                <View style={styles.line} />
              </View>
              <View style={styles.section}>
                <Text style={styles.subtitle}>Final Outcome</Text>
                <Text style={styles.paragraphText}>{finalOutcome}</Text>
                <View style={styles.line} />
              </View>
              <View style={styles.variantsSection}>
                <Text style={{ fontSize: 14, width: 120 }}>
                  Variant&apos;s tested:
                </Text>
                <View style={styles.variants}>
                  {variants.map(
                    ({
                      id,
                      name: variantName,
                      description,
                      variantUploads,
                    }) => {
                      const isWinner = id === winnerVariantId
                      return (
                        <View key={id} style={styles.variant}>
                          {isWinner ? (
                            <View style={styles.variantWinner}>
                              <Text style={styles.variantWinnerName}>
                                {variantName}
                              </Text>
                              <Text style={styles.variantWinnerText}>
                                WINNER
                              </Text>
                            </View>
                          ) : (
                            <Text style={styles.variantName}>
                              {variantName}
                            </Text>
                          )}
                          <Text style={styles.variantDescription}>
                            {description}
                          </Text>
                          {variantUploads.map(({ id: variantId, fileUrl }) => (
                            <View
                              key={variantId}
                              style={{
                                height: 200,
                                width: 300,
                              }}
                            >
                              <Image src={fileUrl} />
                            </View>
                          ))}
                        </View>
                      )
                    }
                  )}
                </View>
              </View>
            </Page>
          </Document>
        }
      >
        {({ loading }) => {
          // See https://github.com/diegomura/react-pdf/issues/420
          // Prevent multiple concurrent pdf renders
          // Works with shouldComponentUpdate above
          this.isLoading = loading
          return (
            <div id="distillery-pdf-report-link" className={className}>
              {loading && 'Generating report...'}
              {!loading && (
                <Fragment>
                  <KiteFontIcon size="16px" name="download-f" color="#0073d1" />
                  Experiment Summary
                </Fragment>
              )}
            </div>
          )
        }}
      </PDFDownloadLink>
    )
  }
}
