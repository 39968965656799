export default {
  dateChangeWarning:
    'Cannot activate content map with a date that has passed. Please choose a future date so the content map can be scheduled',
  activeContentWarning:
    "This content map's date overlap an existing, active content map on the same content area. You must deactivate any other content maps before you'll be able to publish this one.",
  syncWarning:
    'Unable to update Distillery Rules Engine, if this continues please contact Distillery Support.',
  cancelContentMapTitle: 'Confirm Cancelling Content Map',
  cancelContentMapMessage:
    'Are you sure you want to cancel this content map? This action will remove any audiecne definition and content prioritization, and fall back to the default content set in Drupal for this content area. If there is an experiment tied to it, that experiment will also be cancelled.',
  syncEmail: contentMap =>
    `mailto:DL-Distillery-Support@charter.com?subject=Distillery Conteent Map Publishing Error - ${contentMap.name}&body=Error occurred from Content Map "${contentMap.name}" (ID: ${contentMap.id}) - ${contentMap.publishedError}`,
  errSave: message =>
    `Error occurred: ${message}. If this continues, contact Distillery Support.`,
  saveAndPublishedMessage:
    'Content Map saved and published to DRE sucessfully.',
  createPage: {
    infoTitle: 'Content Map Info',
    nameLabel: 'Content Map Name',
    nameInput: 'Choose a unique, concise name',
    contentAreaSelect: 'Content Area',
    contentMapDescription: 'Status, business unit, purpose, time length, etc.',
    commentLabel: 'Notes/Description (Optional)',
    commentPlaceholder: 'Status, business unit, purpose, time length, etc.',
    contentSelect: 'Content',
    drupalComment:
      'If this content needs modification, please update it in Drupal: ',
    audienceSelect: 'Audience ',
    saveBtn: 'Save',
    exitBtn: 'Cancel',
    changesMessage: 'Did you want to save your changes?',
    nameError: 'Please enter a unique name.',
    nameDuplicateError:
      'A content map with this name already exists. Please try another one.',
    contentError: 'Please select a Content to deliver to this Audience.',
    audienceError: 'Please select at least one non-default audience.',
    personalizedContentBtn: 'Add Content + Audience ',
    audienceMangamentLabel: 'Need to prep one or more audiences?',
    mapContentLabel: 'Map audiences, priorities, and content:',
    defaultLabel: 'Default Content (final priority)',
    defaultAudienceLabel: 'All Users (Default)',
    audienceErrorMessage: 'Please select at least one non-default Audience.',
    contentErrorMessage: 'Please select a Content to deliver to this Audience.',
    productError: 'Please select a product.',
    featureError: 'Please select a business use case.',
    contentAreaIdError: 'Please select a content area.',
    approverHeader: 'Approver(s)',
    approverTooltip:
      'Choose the Distillery user(s) who should review the personalization and approve it for publishing to customers. One is required, but you can set multiple Approvers.',
    approverPlaceholder: 'Type names or scroll',
    noApproverError: 'Please select an approver.',
    jiraHeader: 'JIRA Ticket Link (optional)',
    jiraPlaceholder: 'Paste full URL to ticket',
  },
  reviewPage: {
    publishOrSave:
      'If everything is correct, choose "Publish" to go live. Or, keep in draft status for release only to your test accounts.',
    startDate: 'Content Map Active Date',
    stopDate: 'Content Map Deactive Date',
    dateError: 'Please set a date for scheduled publication.',
    timeError: 'Please set a time for scheduled publication.',
    timePlaceholder: '00:00',
    approvalMessage: 'Ready for Approval',
    experimentPreviewLabel: 'Preview this Experience',
    approvalBtn: 'Approve & Schedule',
    experimentContentLabel: 'Start Experiment',
  },
}
