import { gql } from '@apollo/client'

export const GET_ACTIVE_LOCKDOWNS = gql`
  query getActiveNetworkLockdowns {
    activeNetworkLockdowns {
      id
      startDate
      endDate
      isImmediate
      preMessage
      duringMessage
    }
  }
`
