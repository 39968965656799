import React, { Component } from 'react'
import classNames from 'classnames'
import { string, func, bool } from 'prop-types'
import './Checkbox.scss'

class Checkbox extends Component {
  static propTypes = {
    label: string.isRequired,
    onChange: func.isRequired,
    checked: bool.isRequired,
    identifier: string,
    leftLabel: bool,
    disabled: bool,
    type: string,
    tight: bool,
    className: string,
  }

  static defaultProps = {
    identifier: null,
    leftLabel: null,
    disabled: false,
    type: null,
    tight: null,
    className: null,
  }

  handleToggleCheck = () => {
    const { onChange, identifier = null, checked } = this.props

    onChange(!checked, identifier)
  }

  render() {
    const {
      label,
      leftLabel,
      disabled,
      type,
      tight,
      checked,
      className,
    } = this.props

    const containerClassNames = classNames({
      checkbox__container: true,
      'has-left-label': leftLabel,
      disabled,
      [className]: className,
      tight,
    })

    const indicatorClassNames = classNames({
      checkbox__indicator: true,
      checked,
      disabled,
    })

    const labelClassNames = classNames({
      checkbox__label: true,
      'left-label': leftLabel,
      [type]: type,
    })

    const iconClassNames = classNames({
      'material-icons': true,
      'checkbox__indicator-icon': true,
      checked,
    })

    return (
      <div
        role="presentation"
        className={containerClassNames}
        onClick={!disabled && this.handleToggleCheck}
        onKeyDown={!disabled && this.handleToggleCheck}
      >
        <input
          className="checkbox__input"
          type="checkbox"
          aria-checked={checked}
          defaultChecked={checked}
        />
        <div className={indicatorClassNames}>
          <i className={iconClassNames}>check</i>
        </div>
        <span className={labelClassNames}>{label}</span>
      </div>
    )
  }
}

export default Checkbox
