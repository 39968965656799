export default {
  audienceBtn: 'New Audience',
  noSearchValue: searchValue => `No audiences match "${searchValue}"`,
  noAudiences: 'There are no audiences.',
  searchHeader: 'Search Audiences',
  searchPlaceholder: 'Try audience name or content.',
  audienceContent: {
    backBtn: 'Audience Management',
    descriptionPlaceholder:
      'Record a summary of the audience to help findability.',
    audienceNameEmpty: 'Please enter a unique name.',
    audienceNameDuplicate:
      'An audience with this name already exists. Please try another.',
    ruleBuilderError: 'Please add a rule.',
    modalContent: 'Exit and discard all audience changes?',
  },
}
