import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'
import { GET_PERMISSIONS, GET_ROLES } from '../queries'

const INSERT_ROLE_WITH_PERMISSIONS = gql`
  mutation insertRoleWithPermissions($input: InsertRoleToPermissionConfig!) {
    insertRoleWithPermissions(input: $input) {
      id
      roleTypeId
      permissions {
        id
      }
      users {
        id
        userToRoles {
          id
        }
      }
    }
  }
`

const insertRoleWithPermissions = graphql(INSERT_ROLE_WITH_PERMISSIONS, {
  name: 'insertRoleWithPermissions',
  options: {
    refetchQueries: [{ query: GET_ROLES }, { query: GET_PERMISSIONS }],
    awaitRefetchQueries: true,
  },
})

export default insertRoleWithPermissions
