import React, { Component, Fragment } from 'react'
import { func, object, bool, array, number } from 'prop-types'
import flowRight from 'lodash/flowRight'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import {
  checkVariants,
  hasPermission,
  permissionEnum,
  experimentTypeEnum,
  experimentStatusEnum,
} from '@charter/distillery-rules'

import {
  KiteTooltip,
  KiteInput,
  KiteSelect,
  KiteButton,
  KiteIcon,
  KiteLoader,
  KiteAlert,
} from '@kite/react-kite'

import {
  SmartExpansionPanel,
  CounterInput,
  TextArea,
  LabeledSwitch,
  FileDrop,
} from '@kite/react-kite-plus'

import {
  client,
  REACT_APP_DE_EXPERIMENT_CONFIG_API_URL as API_URL,
} from '../../../../configuration/configApiClient'
import {
  LOG_ERROR,
  APPROVE_TECH_SETTINGS,
  SET_NEEDS_TECH_APPROVAL,
  insertExperimentVariants,
  updateExperimentVariants,
  deleteExperimentVariant,
} from '../../../../shared/mutations'
import { formatLoggingError } from '../../../../shared/utilities'
import getExperimentVariants, {
  GET_EXPERIMENTS_VARIANTS,
} from './queries/getExperimentsVariants'

import {
  insertVariantUploads,
  deleteVariantUpload,
  updateExperimentPlan,
} from './mutations'

import {
  saveVariantUpload,
  deleteVariantUploadFromS3,
} from './utilities/variantUploadCalls'
import copyContent from '../../data/copyContent'
import { ExpansionPanel, Modal } from '../../../../componentLibrary'
import './Variants.scss'

const { RUNNING } = experimentStatusEnum
const {
  EXPERIMENT_UPDATE_VERSION_RUNNING,
  EXPERIMENT_UPDATE_RUNNING,
  TECH_UPDATE,
} = permissionEnum

const emptyJsonForm = { name: '', type: '', value: '' }
const initialVariants = {
  control: {
    id: null,
    uuid: null,
    name: '',
    description: '',
    variantUploads: [],
    isUsingJsonBuilder: false,
    jsonBuilderValues: [],
    jsonBuilderForm: emptyJsonForm,
    customJson: '',
    prettyJson: '{}',
    isEditingCustomJson: false,
    isDirty: false,
  },
  'variant 1': {
    id: null,
    uuid: null,
    name: '',
    description: '',
    variantUploads: [],
    isUsingJsonBuilder: false,
    jsonBuilderValues: [],
    jsonBuilderForm: emptyJsonForm,
    customJson: '',
    prettyJson: '{}',
    isEditingCustomJson: false,
    isDirty: false,
  },
}

const initialErrors = {
  jsonError: null,
  jsonErrorOnSubmit: null,
  duplicateName: null,
  createUpdateVariant: null,
  deleteVariant: null,
  insertUpload: null,
  deleteUpload: null,
  saveUpload: null,
  variantFieldErrors: null,
  multipleVariantReason: null,
}

export class Variants extends Component {
  static propTypes = {
    onChange: func.isRequired,
    id: number,
    applicationPermissions: array.isRequired,
    experimentPermissions: array.isRequired,
    environmentSamplings: array.isRequired,
    canApproveJsonPayload: bool.isRequired,
    experimentVariantsData: object,
    insertExperimentVariants: func,
    updateExperimentVariants: func,
    deleteExperimentVariant: func,
    insertVariantUploads: func,
    deleteVariantUpload: func,
    updateExperimentPlan: func,
    disabled: bool,
  }

  static defaultProps = {
    id: null,
    experimentVariantsData: null,
    insertExperimentVariants: null,
    updateExperimentVariants: null,
    deleteExperimentVariant: null,
    insertVariantUploads: null,
    deleteVariantUpload: null,
    updateExperimentPlan: null,
    disabled: false,
  }

  state = {
    activePanel: null,
    jsonToRestart: null,
    isJsonRestartModalOpen: false,
    variantToDelete: null,
    isDeleteVariantModalOpen: false,
    isLoading: false,
    isUpdating: false,
    isDeleting: false,
    isSavingUpload: false,
    isRunningCanaryExperiment: false,
    isDeletingUpload: false,
    newVariant: null,
    errorMessage: initialErrors,
    variants: initialVariants,
    multipleVariantReason: '',
    isDirty: false,
  }

  // LIFECYCLE METHODS
  componentDidMount() {
    const { experimentVariantsData } = this.props

    // Set the experiment to state if one exists
    // CDM used when navigating to Variants inside the app
    if (
      experimentVariantsData &&
      experimentVariantsData.experiment &&
      experimentVariantsData.experiment.variants.length > 0
    ) {
      this.handleSetExperiment()
    }
  }

  componentDidUpdate(prevProps) {
    const { isUpdating, isDeleting, isSavingUpload, isDeletingUpload } =
      this.state
    const { experimentVariantsData } = this.props
    const { experimentVariantsData: prevVariantsData } = prevProps

    // Set the experiment to state if one exists
    // CDU used when hitting the 'variants' URL directly
    // Extra checks to avoid handleSetExperiment being called when updating/deleting
    if (
      !isUpdating &&
      !isDeleting &&
      !isSavingUpload &&
      !isDeletingUpload &&
      prevVariantsData &&
      experimentVariantsData &&
      experimentVariantsData.experiment &&
      experimentVariantsData.experiment.variants.length > 0 &&
      !isEqual(prevVariantsData.experiment, experimentVariantsData.experiment)
    ) {
      this.handleSetExperiment()
    }
  }

  // LOCAL STATE CHANGES/TOGGLES
  handleSetExperiment = async () => {
    const {
      onChange,
      experimentVariantsData: { experiment },
    } = this.props

    const {
      variants,
      experimentStatusId,
      experimentTypeId,
      multipleVariantReason,
    } = experiment

    let isRunningCanaryExperiment = false

    if (
      experimentStatusId === RUNNING &&
      experimentTypeId === experimentTypeEnum.CANARY
    ) {
      isRunningCanaryExperiment = true
    }

    await this.setState({
      isLoading: true,
      isRunningCanaryExperiment,
      multipleVariantReason: multipleVariantReason || '',
    })

    // Format the variants according to order
    const formattedVariants = variants.reduce((accumulator, variant) => {
      const { id, uuid, name, description, jsonPayload, displayOrder } = variant

      const variantKey =
        displayOrder === 0 ? 'control' : `variant ${displayOrder}`
      const variantUploads = isEmpty(variant.variantUploads)
        ? []
        : this.handleFormatVariantUploads(variant.variantUploads)

      const { jsonBuilderValues, isUsingJsonBuilder, customJson, prettyJson } =
        this.handleFormatJsonValues(jsonPayload)

      accumulator[variantKey] = {
        id,
        uuid,
        name,
        description,
        variantUploads,
        isUsingJsonBuilder,
        jsonBuilderValues,
        customJson,
        prettyJson,
        jsonBuilderForm: emptyJsonForm,
        isEditingCustomJson: false,
        isDirty: false,
      }

      return accumulator
    }, {})

    this.setState({ variants: formattedVariants })

    // Pass 'false' to inform parent that the form does not have changes
    //  and is only loading
    await onChange(false)

    this.setState({ isLoading: false })
  }

  handleNameChange = async event => {
    const { errorMessage, variants } = this.state
    const {
      target: { name, value },
    } = event
    const variantProps = name.split('__')

    if (event && event.persist) {
      event.persist()
    }

    // Disallows error to be shown when deleting the value
    if (value !== '') {
      const match = Object.keys(variants).find(
        existingVariant =>
          variantProps[0] !== existingVariant &&
          variants[existingVariant].name.toLowerCase() === value.toLowerCase()
      )

      // If the matching names are found, set an error
      if (match) {
        await this.setState(prevState => ({
          errorMessage: {
            ...prevState.errorMessage,
            duplicateName: copyContent.variants.duplicateNameError,
          },
        }))
        // Else if there's an error and no match, remove the error
      } else if (errorMessage.duplicateName) {
        await this.setState(prevState => ({
          errorMessage: {
            ...prevState.errorMessage,
            duplicateName: null,
          },
        }))
      }
    }

    this.handleFieldChange(event)
  }

  handleChangeCustomJson = async event => {
    if (event && event.persist) {
      event.persist()
    }

    const { errorMessage } = this.state

    // Reset any json errors
    if (errorMessage.jsonError || errorMessage.jsonErrorOnSubmit) {
      await this.setState(prevState => ({
        errorMessage: {
          ...prevState.errorMessage,
          jsonError: null,
          jsonErrorOnSubmit: null,
        },
      }))
    }

    this.handleFieldChange(event)
  }

  handleFieldChange = async ({ target: { name, value } }) => {
    const { onChange } = this.props

    if (name === 'multipleVariantReason') {
      await this.setState(prevState => ({
        multipleVariantReason: value,
        errorMessage: {
          ...prevState.errorMessage,
          multipleVariantReason: null,
        },
        isDirty: true,
      }))
    } else {
      const variantProps = name.split('__')

      await this.setState(prevState => ({
        variants: {
          ...prevState.variants,
          [variantProps[0]]: {
            ...prevState.variants[variantProps[0]],
            [variantProps[1]]: value,
            isDirty: true,
          },
        },
        errorMessage: {
          ...prevState.errorMessage,
          variantFieldErrors: {
            ...prevState.errorMessage.variantFieldErrors,
            [name]: null,
          },
        },
      }))
    }

    onChange(true)
  }

  handleAddUploads = async (event, uploads, variant) => {
    if (event) {
      event.preventDefault()
    }

    const { onChange } = this.props
    const { variants } = this.state

    const { id: variantId } = variants[variant]

    // For each upload passed, save it to AWS
    uploads.forEach(async file => {
      const newUpload = await this.handleSaveVariantUpload(file, variant)
      if (newUpload && variantId) {
        // If this is an existing variant, insert the upload to the DB
        await this.handleInsertUploads(variantId, [newUpload], variant)
      }
    })

    onChange(true)
  }

  handleJsonBuilderValueChange = async ({ target: { name, value } }) => {
    const { onChange } = this.props
    const { variants } = this.state

    const variantProps = name.split('__')

    // If switching the 'type' of a json value, reset any existing value (type checking)
    if (
      variantProps[1] === 'type' &&
      variants[variantProps[0]].jsonBuilderForm.value !== ''
    ) {
      await this.setState(prevState => ({
        variants: {
          ...prevState.variants,
          [variantProps[0]]: {
            ...prevState.variants[variantProps[0]],
            jsonBuilderForm: {
              ...prevState.variants[variantProps[0]].jsonBuilderForm,
              value: '',
            },
          },
        },
      }))
    }

    await this.setState(prevState => ({
      variants: {
        ...prevState.variants,
        [variantProps[0]]: {
          ...prevState.variants[variantProps[0]],
          jsonBuilderForm: {
            ...prevState.variants[variantProps[0]].jsonBuilderForm,
            [variantProps[1]]: value,
          },
        },
      },
    }))

    onChange(true)
  }

  handleAddJsonBuilderValue = (event, variant) => {
    if (event) {
      event.preventDefault()
    }

    const { onChange } = this.props

    this.setState(({ variants }) => {
      let newValues = variants[variant].jsonBuilderForm
      if (newValues.type === 'string') {
        // Trim the white spaces of the json data
        newValues.name = variants[variant].jsonBuilderForm.name.trim()
        newValues.value = variants[variant].jsonBuilderForm.value.trim()
      }

      return {
        variants: {
          ...variants,
          [variant]: {
            ...variants[variant],
            jsonBuilderForm: emptyJsonForm,
            jsonBuilderValues: [
              ...variants[variant].jsonBuilderValues,
              newValues,
            ],
            isDirty: true,
          },
        },
      }
    })

    onChange(true)
  }

  handleEditJsonBuilderValue = (event, variant, name) => {
    if (event) {
      event.preventDefault()
    }

    const { onChange } = this.props

    // Find the select json value to edit
    const matchingValue = this.state.variants[variant].jsonBuilderValues.find(
      jsonValue => jsonValue.name === name
    )

    // Set the form values to the match and filter it from the saved values
    this.setState(({ variants }) => ({
      variants: {
        ...variants,
        [variant]: {
          ...variants[variant],
          jsonBuilderForm: matchingValue,
          jsonBuilderValues: variants[variant].jsonBuilderValues.filter(
            jsonValue => jsonValue.name !== name
          ),
          isDirty: true,
        },
      },
    }))

    onChange(true)
  }

  handleDeleteJsonBuilderValue = (event, variant, name) => {
    if (event) {
      event.preventDefault()
    }

    const { onChange } = this.props

    this.setState(({ variants }) => ({
      variants: {
        ...variants,
        [variant]: {
          ...variants[variant],
          jsonBuilderValues: variants[variant].jsonBuilderValues.filter(
            jsonValue => jsonValue.name !== name
          ),
          isDirty: true,
        },
      },
    }))

    onChange(true)
  }

  handleToggleJsonBuilder = variant => {
    this.setState(({ variants }) => ({
      variants: {
        ...variants,
        [variant]: {
          ...variants[variant],
          isUsingJsonBuilder: !variants[variant].isUsingJsonBuilder,
        },
      },
    }))
  }

  handleEditCustomJson = (event, variant) => {
    if (event) {
      event.preventDefault()
    }

    const { onChange } = this.props

    this.setState(({ variants }) => ({
      variants: {
        ...variants,
        [variant]: {
          ...variants[variant],
          isEditingCustomJson: true,
          customJson: variants[variant].prettyJson,
        },
      },
    }))

    onChange(true)
  }

  handleSaveCustomJson = async (event, variant) => {
    if (event) {
      event.preventDefault()
    }

    const { onChange } = this.props

    // Validate the JSON before attempting to save
    const isValid = await this.handleValidateJson(
      this.state.variants[variant].customJson
    )

    // If valid, set a pretty JSON for display purposes
    // If the JSON is an empty object, reset customJson to an empty string
    if (isValid) {
      this.setState(({ variants }) => ({
        variants: {
          ...variants,
          [variant]: {
            ...variants[variant],
            isEditingCustomJson: false,
            customJson:
              variants[variant].customJson === '{}'
                ? ''
                : variants[variant].customJson,
            prettyJson: JSON.stringify(
              JSON.parse(variants[variant].customJson),
              null,
              2
            ),
          },
        },
      }))

      onChange(true)
    } else {
      // Set a JSON error is the JSON is invalid
      this.setState(({ errorMessage }) => ({
        errorMessage: {
          ...errorMessage,
          jsonError: variant,
        },
      }))
    }
  }

  handleCheckRestartJson = variant => {
    const { variants } = this.state

    // Variables used for a dynamic warning message
    const currentType = !isEmpty(variants[variant].jsonBuilderValues)
      ? 'JSON Builder'
      : 'Custom JSON'
    const nextType = !isEmpty(variants[variant].jsonBuilderValues)
      ? 'Custom JSON'
      : 'JSON Builder'

    this.setState({
      jsonToRestart: { variant, currentType, nextType },
      isJsonRestartModalOpen: true,
    })
  }

  handleConfirmRestartJson = () => {
    const { onChange } = this.props

    this.setState(({ variants, jsonToRestart: { variant } }) => ({
      isJsonRestartModalOpen: false,
      jsonToRestart: null,
      variants: {
        ...variants,
        [variant]: {
          ...variants[variant],
          customJson: '',
          prettyJson: '{}',
          jsonBuilderValues: [],
          isUsingJsonBuilder: !variants[variant].isUsingJsonBuilder,
          isEditingCustomJson: false,
          isDirty: true,
        },
      },
    }))

    onChange(true)
  }

  handleCancelRestartJson = () => {
    this.setState({ jsonToRestart: null, isJsonRestartModalOpen: false })
  }

  handleCheckDeleteUpload = (event, file, variant) => {
    if (event) {
      event.preventDefault()
    }

    this.setState({
      uploadToDelete: { ...file, variant },
      isDeleteUploadModalOpen: true,
    })
  }

  handleCancelDeleteUpload = () => {
    this.setState({ uploadToDelete: null, isDeleteUploadModalOpen: false })
  }

  handleCheckDeleteVariant = (event, variant) => {
    if (event) {
      event.preventDefault()
    }

    const { variants } = this.state

    const { id, name } = variants[variant]
    this.setState({
      variantToDelete: { id, name, state: variant },
      isDeleteVariantModalOpen: true,
    })
  }

  handleCancelDeleteVariant = () => {
    this.setState({
      variantToDelete: null,
      isDeleteVariantModalOpen: false,
    })
  }

  handleAddVariant = async event => {
    if (event) {
      event.preventDefault()
    }

    const { onChange } = this.props
    const { variants } = this.state

    const newVariant = `variant ${Object.keys(variants).length}`

    await this.setState(prevState => ({
      activePanel: newVariant,
      newVariant,
      variants: {
        ...prevState.variants,
        [newVariant]: initialVariants.control,
      },
    }))

    onChange(true)
  }

  // FORMATTING METHODS
  handleFormatVariantUploads = uploads =>
    uploads.map(({ id, fileUrl }) => {
      return {
        variantUploadId: id,
        name: '',
        fileUrl,
        preview: fileUrl,
      }
    })

  handleFormatJsonValues = jsonPayload => {
    const parsedJson = !isEmpty(jsonPayload) && JSON.parse(jsonPayload)

    // If a jsonPayload exists
    if (parsedJson) {
      // Check to see if it was made with the json builder and return values
      if (parsedJson[0] && parsedJson[0].name) {
        return {
          isUsingJsonBuilder: true,
          jsonBuilderValues: parsedJson,
          customJson: '',
          prettyJson: '{}',
        }
      }
      // Otherwise it is custom json
      return {
        isUsingJsonBuilder: false,
        jsonBuilderValues: [],
        customJson: jsonPayload,
        prettyJson: JSON.stringify(JSON.parse(jsonPayload), null, 2),
      }
    }

    // If not payload, set default json values
    const { isUsingJsonBuilder, jsonBuilderValues, customJson, prettyJson } =
      initialVariants.control

    return {
      isUsingJsonBuilder,
      jsonBuilderValues,
      customJson,
      prettyJson,
    }
  }

  handleFormatVariantInputs = () => {
    const { variants } = this.state

    const variantsToInsert = []
    const variantsToUpdate = []

    Object.keys(variants).forEach(async (variant, index) => {
      const {
        id: variantId,
        name,
        description,
        customJson,
        jsonBuilderValues,
        variantUploads,
        isDirty,
      } = variants[variant]
      let variantPayload = {
        name,
        description,
        jsonPayload: '',
        displayOrder: index,
      }

      // Only inset/update variants that have changes
      if (isDirty) {
        if (!isEmpty(customJson)) {
          variantPayload = {
            ...variantPayload,
            jsonPayload: JSON.stringify(JSON.parse(customJson)),
          }
        } else if (!isEmpty(jsonBuilderValues)) {
          variantPayload = {
            ...variantPayload,
            jsonPayload: JSON.stringify(jsonBuilderValues),
          }
        }

        // If there's an existing id, add the variant to updates
        if (variantId) {
          // Remove all props besides fileUuid, variantUploadId from the uploads
          const cleanUpdateUploads = variantUploads.map(
            ({ fileUrl, variantUploadId }) => ({
              fileUrl,
              variantUploadId,
            })
          )
          variantsToUpdate.push({
            ...variantPayload,
            id: variantId,
            variantUploads: cleanUpdateUploads,
          })
          // Else add the variant to inserts, spread in variant uploads
        } else {
          // Remove all props besides fileUuid from the uploads
          const cleanInsertUploads = variantUploads.map(({ fileUrl }) => ({
            fileUrl,
          }))

          variantsToInsert.push({
            ...variantPayload,
            variantUploads: cleanInsertUploads,
          })
        }
      }
    })

    return { variantsToInsert, variantsToUpdate }
  }

  handleFormatTitleColumns = variant => {
    const {
      experimentVariantsData: {
        experiment: { experimentTypeId },
      },
    } = this.props
    const { variants, errorMessage } = this.state
    const { name, uuid } = variants[variant]

    // Used to flag whether or not the json field should have an error shown
    let showJsonWarning = false
    const titleColumns = [{ content: variant, heading: true, size: 0.25 }]

    const hasFieldErrors =
      errorMessage.variantFieldErrors &&
      Object.keys(errorMessage.variantFieldErrors)
        .filter(field => field.includes(variant))
        .find(field => errorMessage.variantFieldErrors[field] !== null)

    // Do not show json warning if it is an AA test
    const isAaTest = experimentTypeId === experimentTypeEnum.AA

    const idContent = `ID: ${uuid}`
    const nameObject = { content: name, size: 0.5 }
    const spacerObject = { content: ' ', size: 0.5 }

    const fieldErrorObject = {
      content: (
        <KiteAlert type="alert" level="inline" description="Missing Fields" />
      ),
      size: 0.5,
    }

    const jsonAlertObject = {
      content: (
        <KiteAlert type="caution" level="inline" description="Add override" />
      ),
      size: 0.5,
    }

    if (name) {
      titleColumns.push(nameObject)
    }

    if (!uuid && hasFieldErrors) {
      titleColumns.push(fieldErrorObject)
    }

    // If a uuid exists the variant has been saved
    if (uuid) {
      titleColumns.push(idContent)
      // If the control does not have a json payload, show an alert
      if (variant === 'control') {
        titleColumns.push(spacerObject)
      } else if (variant.includes('variant')) {
        let flagVariantOverrideError = false
        if (
          isEmpty(variants[variant].customJson) &&
          isEmpty(variants[variant].jsonBuilderValues)
        ) {
          flagVariantOverrideError = true
        }
        // If variant does not have a json payload, show an alert
        if (!isAaTest && flagVariantOverrideError) {
          showJsonWarning = true
          titleColumns.push(jsonAlertObject)
        } else {
          titleColumns.push(spacerObject)
        }
      }
    }

    return { titleColumns, showJsonWarning }
  }

  // REF METHODS

  // Defaults to isComplete === isValid
  // This step as incomplete if
  // is not an A/A test and there are missing json overrides on variants
  isComplete = () => {
    const { variants } = this.state
    const {
      id,
      canApproveJsonPayload,
      experimentVariantsData: { experiment },
      experimentConfig,
    } = this.props

    const experimentId = Number(id)
    const isAaTest = experiment.experimentTypeId === experimentTypeEnum.AA

    let isComplete = true

    // Confirm setups are not just getting approved without all being completed
    const config = JSON.parse(experimentConfig)
    const hasIncompleteSteps =
      Object.values(config.setUpSteps).filter(
        ({ status }) => status === 'incomplete'
      ).length > 0

    // If not permissions to update JSON and is not an AA test
    if (!canApproveJsonPayload && !isAaTest) {
      isComplete = false

      client.mutate({
        mutation: SET_NEEDS_TECH_APPROVAL,
        variables: {
          experimentId,
        },
      })
      return isComplete
    }

    if (canApproveJsonPayload && !hasIncompleteSteps) {
      client.mutate({
        mutation: APPROVE_TECH_SETTINGS,
        variables: {
          experimentId,
        },
      })
    }

    // Only run through JSON checks if type is not A/A
    if (!isAaTest) {
      let variantOverridePresent = true
      experiment.variants.forEach(variant => {
        const { name, id: variantId } = variant
        const match = Object.keys(variants).find(
          stateVariant =>
            variants[stateVariant].id === variantId ||
            variants[stateVariant].name === name
        )
        // The control is optional, so only check other variants
        if (match !== 'control') {
          if (!variant.jsonPayload || isEmpty(variant.jsonPayload)) {
            variantOverridePresent = false
          }

          if (!variantOverridePresent) {
            isComplete = false
          }
        }
      })
    }
    return isComplete
  }

  // Used by parent and sends any existing error messages
  getErrorMessage = () => {
    const { errorMessage } = this.state

    const messages = Object.values(errorMessage).reduce(
      (accumulator, error) => {
        if (!accumulator && error) {
          return `${error}`
        }
        if (error) {
          return `${accumulator}; ${error}`
        }
        return accumulator
      },
      ''
    )

    return messages
  }

  validateSubmission = async () => {
    const { variants } = this.state

    await this.setState({ errorMessage: initialErrors })

    const input = Object.keys(variants).map((variant, index) => {
      const { name, description } = variants[variant]

      return {
        name,
        description,
        displayOrder: index,
      }
    })

    const { errors } = checkVariants({ variants: input })

    if (!isEmpty(errors)) {
      this.setState(({ errorMessage }) => ({
        errorMessage: {
          ...errorMessage,
          variantFieldErrors: errors,
        },
      }))

      return false
    }

    return true
  }

  // API METHODS
  handleSaveVariantUpload = async (file, variant) => {
    const { id } = this.props
    const { variants, errorMessage } = this.state
    const { id: variantId } = variants[variant]

    if (errorMessage.saveUpload) {
      await this.setState(prevState => ({
        errorMessage: {
          ...prevState.errorMessage,
          saveUpload: null,
        },
      }))
    }

    // Set saving state for uploads
    await this.setState({ isSavingUpload: true })

    try {
      const { data } = await saveVariantUpload(id, file)
      const newUpload = {
        fileUuid: data.key,
        fileUrl: `${API_URL}/file/${data.key}`,
        preview: `${API_URL}/file/${data.key}`,
        name: file.name,
      }
      if (!data.key) {
        throw Error()
      }

      if (!variantId) {
        await this.setState(prevState => ({
          isSavingUpload: false,
          variants: {
            ...prevState.variants,
            [variant]: {
              ...prevState.variants[variant],
              variantUploads: [
                ...prevState.variants[variant].variantUploads,
                newUpload,
              ],
              isDirty: true,
            },
          },
        }))
      }

      return newUpload
    } catch (error) {
      const input = formatLoggingError(error, { id })

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input,
        },
      })

      await this.setState(prevState => ({
        isSavingUpload: false,
        errorMessage: {
          ...prevState.errorMessage,
          saveUpload: copyContent.variants.uploadSaveError,
        },
      }))

      return false
    }
  }

  handleInsertUploads = async (variantId, uploads, variant) => {
    const { insertVariantUploads: insertUploads, id: experimentId } = this.props
    const { errorMessage } = this.state

    if (errorMessage.insertUpload) {
      this.setState(prevState => ({
        errorMessage: {
          ...prevState.errorMessage,
          insertUpload: null,
        },
      }))
    }

    try {
      const variantUploads = uploads.map(({ fileUrl }) => ({
        fileUrl,
      }))
      const response = await insertUploads({
        variables: {
          variantId,
          variantUploads,
        },
      })
      const formattedUploads = this.handleFormatVariantUploads(
        response.data.insertVariantUploadsForVariant.variantUploads
      )

      await this.setState(prevState => ({
        variants: {
          ...prevState.variants,
          [variant]: {
            ...prevState.variants[variant],
            variantUploads: formattedUploads,
            isDirty: true,
          },
        },
      }))
    } catch (error) {
      const input = formatLoggingError(error, { id: experimentId })

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input,
        },
      })

      this.setState(prevState => ({
        errorMessage: {
          ...prevState.errorMessage,
          insertUpload: copyContent.variants.uploadSaveError,
        },
      }))
    } finally {
      await this.setState({ isSavingUpload: false })
    }
  }

  handleConfirmDeleteUpload = async () => {
    const { id: experimentId, deleteVariantUpload: deleteUpload } = this.props
    const {
      uploadToDelete: { variantUploadId, variant: stateVariant, fileUrl },
      errorMessage,
    } = this.state

    await this.setState({ isDeletingUpload: true })

    if (errorMessage.deleteUpload) {
      await this.setState(prevState => ({
        errorMessage: {
          ...prevState.errorMessage,
          deleteUpload: null,
        },
      }))
    }

    try {
      if (variantUploadId) {
        const variantId = this.state.variants[stateVariant].id

        await deleteUpload({
          variables: {
            variantId,
            variantUploadId,
          },
          update: (
            proxy,
            { data: { deleteVariantUpload: deletedVariant } }
          ) => {
            // Read data from our cache for removal of upload
            const data = proxy.readQuery({
              query: GET_EXPERIMENTS_VARIANTS,
              variables: {
                id: experimentId,
              },
            })

            // Remove deleted upload from variant in cache
            const newVariants = data.experiment.variants.map(cachedVariant => {
              if (cachedVariant.id === Number(experimentId)) {
                return {
                  ...cachedVariant,
                  variantUploads: cachedVariant.variantUploads.filter(
                    cachedUpload => cachedUpload.id !== deletedVariant
                  ),
                }
              }

              return cachedVariant
            })

            // Write data back to the cache
            proxy.writeQuery({
              query: GET_EXPERIMENTS_VARIANTS,
              variable: { id: experimentId },
              data: { experiments: { variants: newVariants } },
            })
          },
        })
      }
      // send 32-digit variant upload uuid
      await deleteVariantUploadFromS3(fileUrl.slice(-32))

      await this.setState(({ variants, uploadToDelete: { variant } }) => ({
        isDeletingUpload: false,
        uploadToDelete: null,
        isDeleteUploadModalOpen: false,
        variants: {
          ...variants,
          [variant]: {
            ...variants[variant],
            isDirty: true,
            variantUploads: variants[variant].variantUploads.filter(
              upload => variantUploadId !== upload.variantUploadId
            ),
          },
        },
      }))
    } catch (error) {
      const input = formatLoggingError(error, { id: experimentId })

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input,
        },
      })

      await this.setState(prevState => ({
        errorMessage: {
          ...prevState.errorMessage,
          deleteUpload: copyContent.variants.uploadDeleteError,
        },
      }))
    }
  }

  handleSubmit = async () => {
    const { variants, errorMessage, isDirty, multipleVariantReason } =
      this.state
    const {
      id: experimentId,
      insertExperimentVariants: insertVariants,
      updateExperimentVariants: updateVariants,
      updateExperimentPlan: updateExperiment,
    } = this.props

    await this.setState({ isUpdating: true })

    try {
      let errorsInJson = false
      Object.keys(variants).forEach(async variant => {
        if (variants[variant].customJson.length > 0) {
          try {
            JSON.parse(variants[variant].customJson)
          } catch (error) {
            errorsInJson = true
            // Set a JSON error is the JSON is invalid
            await this.setState(prevState => ({
              activePanel: variant,
              errorMessage: {
                ...prevState.errorMessage,
                jsonErrorOnSubmit: `${variant} has invalid JSON, please enter valid JSON`,
                jsonError: variant,
              },
            }))
          }
        }
      })

      if (errorsInJson === true) {
        throw new Error('Error in JSON')
      }
    } catch (error) {
      await this.setState({ isUpdating: false })
      return false
    }

    if (errorMessage.createUpdateVariant || errorMessage.jsonErrorOnSubmit) {
      this.setState(prevState => ({
        errorMessage: {
          ...prevState.errorMessage,
          createUpdateVariant: null,
          jsonErrorOnSubmit: null,
        },
      }))
    }

    try {
      const { variantsToInsert, variantsToUpdate } =
        this.handleFormatVariantInputs()

      // Update the multiple variant reason
      if (isDirty) {
        await updateExperiment({
          variables: {
            input: {
              experimentId,
              multipleVariantReason,
            },
          },
        })
      }

      // Update any variants
      if (variantsToUpdate.length > 0) {
        await updateVariants({
          variables: {
            input: {
              experimentId,
              variants: variantsToUpdate,
            },
          },
        })
      }

      // Insert any variants
      if (variantsToInsert.length > 0) {
        const insertResponse = await insertVariants({
          variables: {
            input: {
              experimentId,
              variants: variantsToInsert,
            },
          },
        })
        const { variants: newVariants } =
          insertResponse.data.insertExperimentVariants

        await this.setState(prevState => ({
          activePanel: null,
          variants: Object.keys(prevState.variants).reduce(
            (accumulator, variant) => {
              const stateVariant = variants[variant]

              if (!stateVariant.uuid) {
                accumulator[variant] = {
                  ...stateVariant,
                  uuid: newVariants.find(
                    newVariant => newVariant.name === stateVariant.name
                  ).uuid,
                }
              } else {
                accumulator[variant] = stateVariant
              }

              return accumulator
            },
            {}
          ),
        }))
      }

      await this.setState({ isUpdating: false })

      return true
    } catch (error) {
      const input = formatLoggingError(error, { id: experimentId })

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input,
        },
      })

      this.setState(prevState => ({
        errorMessage: {
          ...prevState.errorMessage,
          createUpdateVariant: copyContent.variants.insertUpdateVariantsError,
        },
      }))
    }

    await this.setState({ isUpdating: false })

    return false
  }

  handleConfirmDeleteVariant = async () => {
    const {
      id: experimentId,
      deleteExperimentVariant: deleteVariant,
      updateExperimentVariants: updateVariants,
      updateExperimentPlan: updateExperiment,
    } = this.props

    const {
      variantToDelete: { id: variantId },
      variantToDelete: variantDeleted,
      errorMessage,
      variants: currentVariants,
      multipleVariantReason,
    } = this.state
    let deleteMultipleVariantReason = false

    if (errorMessage.deleteVariant) {
      this.setState(prevState => ({
        errorMessage: {
          ...prevState.errorMessage,
          deleteVariant: null,
        },
      }))
    }

    if (Object.keys(currentVariants).length - 1 <= 3) {
      deleteMultipleVariantReason = true
    }

    await this.setState({ isDeleting: true })

    try {
      // If the deleted variant has an id, remove from the DB and update cache
      if (variantId) {
        const input = { experimentId, variantId }

        await deleteVariant({
          variables: { input },
          update: (
            proxy,
            { data: { deleteExperimentVariant: deletedExperiment } }
          ) => {
            // Read data from our cache for removal of variant
            const data = proxy.readQuery({
              query: GET_EXPERIMENTS_VARIANTS,
              variables: {
                id: experimentId,
              },
            })

            // Remove deleted variant from experiment in cache
            const newVariants = data.experiment.variants.filter(
              cachedVariant => cachedVariant.id !== deletedExperiment
            )

            // Write data back to the cache
            proxy.writeQuery({
              query: GET_EXPERIMENTS_VARIANTS,
              variables: { id: experimentId },
              data: { ...data, variants: newVariants },
            })
          },
        })
      }

      if (
        variantId &&
        deleteMultipleVariantReason &&
        multipleVariantReason.length > 0
      ) {
        await updateExperiment({
          variables: {
            input: {
              experimentId,
              multipleVariantReason: null,
            },
          },
        })
      }

      const variantsToUpdate = []
      let shouldUpdateVariants = Object.keys(currentVariants)[0].id

      // Update the variants display order
      Object.keys(currentVariants)
        .filter(currentVariant => currentVariant !== variantDeleted.state)
        .forEach((variant, index) => {
          if (variant !== variantDeleted.state) {
            variantsToUpdate.push({
              id: currentVariants[variant].id,
              displayOrder: index,
            })
            if (!variantsToUpdate.id && !shouldUpdateVariants) {
              shouldUpdateVariants = true
            }
          }
        })

      if (variantId && shouldUpdateVariants) {
        await updateVariants({
          variables: {
            input: {
              experimentId,
              variants: variantsToUpdate,
            },
          },
        })
      }

      // Filter the deleted variant from state and update the labels on all variants
      await this.setState(
        ({ variants, variantToDelete, multipleVariantReason: prevReason }) => ({
          activePanel: null,
          isDeleting: false,
          variantToDelete: null,
          multipleVariantReason: deleteMultipleVariantReason ? '' : prevReason,
          isDeleteVariantModalOpen: false,
          variants: Object.keys(variants)
            .filter(currentVariant => currentVariant !== variantToDelete.state)
            .reduce((accumulator, variant, index) => {
              if (variant !== variantToDelete.state) {
                if (variant === 'control') {
                  accumulator[variant] = variants[variant]
                } else {
                  accumulator[`variant ${index}`] = variants[variant]
                }
              }

              return accumulator
            }, {}),
        })
      )
    } catch (error) {
      const input = formatLoggingError(error, { id: experimentId })

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input,
        },
      })

      await this.setState(prevState => ({
        isDeleting: false,
        errorMessage: {
          ...prevState.errorMessage,
          deleteVariant: copyContent.variants.deleteVariantError,
        },
      }))
    }
  }

  // VALIDATION METHODS
  handleValidateJson = async json => {
    let isValid = true

    try {
      JSON.parse(json)
    } catch (error) {
      isValid = false
    }

    return isValid
  }

  handleValidateRequiredFields = variants => {
    let isValid = true

    // Name and description are required
    Object.keys(variants).forEach(variant => {
      const { name, description, isEditingCustomJson } = variants[variant]

      if (name === '' || description === '' || isEditingCustomJson) {
        isValid = false
      }
    })

    return isValid
  }

  handleValidatePermissions = permId => {
    const {
      applicationPermissions,
      experimentPermissions,
      environmentSamplings,
      experimentVariantsData: {
        experiment: { experimentTypeId },
      },
    } = this.props

    const canUpdateRunning =
      experimentPermissions &&
      hasPermission({
        experimentTypeId,
        applicationPermissions,
        experimentPermissions,
        environmentSamplings,
        permissionId: permId,
      })

    return canUpdateRunning
  }

  handleValidateDisable = () => {
    const { disabled } = this.props
    const { isRunningCanaryExperiment } = this.state
    const canUpdateRunning = this.handleValidatePermissions(
      EXPERIMENT_UPDATE_RUNNING
    )
    const canUpdateVersionRunning = this.handleValidatePermissions(
      EXPERIMENT_UPDATE_VERSION_RUNNING
    )
    const techUpdate = this.handleValidatePermissions(TECH_UPDATE)
    let disabledField = disabled

    if (disabled && techUpdate) {
      disabledField = !techUpdate
    }

    // Verify if the disabled prop needs to be
    // overridden due to user having ability to update
    // and experiment being a running canary experiment
    if (canUpdateRunning && disabled && isRunningCanaryExperiment) {
      disabledField = !(canUpdateRunning || canUpdateVersionRunning)
    }

    return disabledField
  }

  // RENDER METHODS
  renderVariants = () => {
    const {
      experimentVariantsData: { experiment },
      disabled,
    } = this.props
    const {
      activePanel,
      variants,
      errorMessage,
      isSavingUpload,
      isDeletingUpload,
      newVariant,
    } = this.state

    return Object.keys(variants).map((variant, index) => {
      const {
        id,
        name,
        description,
        isUsingJsonBuilder,
        jsonBuilderValues,
        customJson,
        variantUploads,
      } = variants[variant]
      let disabledFields = disabled
      let canUpdateRunning = this.handleValidatePermissions(
        EXPERIMENT_UPDATE_RUNNING
      )
      let allowJsonChange = !this.handleValidateDisable()

      // If someone was able to add a variant they need to
      // to be allowed to add information to it as well
      if (newVariant === variant) {
        disabledFields = false
        allowJsonChange = true
        canUpdateRunning = false
      }
      const key = id || `${variant}__${index}`

      const titlePlaceholder =
        variant === 'control'
          ? copyContent.variants.controlTitle
          : copyContent.variants.variantTitle
      const descriptionPlaceholder =
        variant === 'control'
          ? copyContent.variants.controlDescription
          : copyContent.variants.variantDescription

      const hasJson = !isEmpty(customJson) || !isEmpty(jsonBuilderValues)
      // Delete button is only shown if at least 3 variants exist in the DB || a new variant without an id
      const showDeleteButton =
        (variant.includes('variant') && experiment.variants.length > 2) ||
        (!id && variant !== 'control' && variant !== 'variant 1')

      const { titleColumns, showJsonWarning } =
        this.handleFormatTitleColumns(variant)

      return (
        <SmartExpansionPanel
          key={key}
          title={variant}
          active={activePanel === variant}
          onClick={rowTitle => this.setState({ activePanel: rowTitle })}
          titleColumns={titleColumns}
          showTitle={false}
        >
          <CounterInput
            id={`variant-${index}__name`}
            className="experiment-variants__name"
            label="Title"
            name={`${variant}__name`}
            maxCharCount={35}
            value={name}
            onChange={this.handleNameChange}
            placeholder={titlePlaceholder}
            tooltip={titlePlaceholder}
            disabled={disabledFields}
            errorMessage={
              (errorMessage.variantFieldErrors &&
                errorMessage.variantFieldErrors[`${variant}__name`]) ||
              errorMessage.duplicateName
                ? `${
                    (errorMessage.variantFieldErrors &&
                      errorMessage.variantFieldErrors[`${variant}__name`]) ||
                    ''
                  } ${errorMessage.duplicateName || ''}`
                : false
            }
            noSpecialCharacters
          />

          <TextArea
            id={`variant-${index}__description`}
            className={`experiment-variants__${variant}-description`}
            label="Description"
            name={`${variant}__description`}
            height="8.125rem"
            maxLength="255"
            value={description}
            onChange={this.handleFieldChange}
            placeholder={descriptionPlaceholder}
            tooltip={descriptionPlaceholder}
            disabled={disabledFields && !canUpdateRunning}
            errorMessage={
              errorMessage.variantFieldErrors &&
              errorMessage.variantFieldErrors[`${variant}__description`]
            }
          />

          {experiment.experimentTypeId !== 1 && (
            <Fragment>
              <div className="experiment-variants__feature-flag">
                <div className="experiment-variants__feature-flag-heading">
                  Feature Flag Overrides
                  <KiteTooltip
                    ariaLabel="Feature Flag Overrides Tooltip"
                    ariaId="featureFlagTooltip"
                  >
                    {copyContent.variants.featureFlagOverride}
                  </KiteTooltip>
                </div>
                <ExpansionPanel
                  className="experiment-variants__error-message"
                  type="minimal"
                  isExpanded={showJsonWarning}
                >
                  {variant === 'control'
                    ? copyContent.variants.addControlOverrideWarning
                    : copyContent.variants.addVariantOverrideWarning}
                </ExpansionPanel>
              </div>

              <LabeledSwitch
                className={`experiment-variants__${variant}-json-switch`}
                id={`${variant}__json-switch`}
                name={`${variant}__json-switch`}
                options={['Custom JSON', 'JSON Builder']}
                checked={isUsingJsonBuilder}
                maxWidth="20rem"
                minWidth="20rem"
                backgroundColor="#B8BFC9"
                onChange={() => {
                  if (allowJsonChange) {
                    if (hasJson) {
                      this.handleCheckRestartJson(variant)
                    } else {
                      this.handleToggleJsonBuilder(variant)
                    }
                  }
                }}
              />

              <div className="experiment-variants__json-container">
                {isUsingJsonBuilder
                  ? this.renderJsonBuilder(variant)
                  : this.renderCustomJson(variant)}
              </div>
            </Fragment>
          )}

          <div className="experiment-variants__file-drop-container">
            {(isSavingUpload || isDeletingUpload) && (
              <div className="experiment-variants__file-drop-loader-wrapper">
                <KiteLoader />
              </div>
            )}
            <FileDrop
              label={copyContent.variants.screenshots}
              iconName="image-upload"
              accept="image/jpg, image/png, image/jpeg"
              maxFileSize={5}
              disabled={disabledFields && !canUpdateRunning}
              files={variantUploads}
              onAdd={(event, uploads) =>
                this.handleAddUploads(event, uploads, variant)
              }
              onRemove={(event, upload) => {
                if (!disabledFields) {
                  this.handleCheckDeleteUpload(event, upload, variant)
                }
              }}
              errorMessage={copyContent.variants.screenshotError}
              allowMultiple
            />

            <ExpansionPanel
              className="experiment-variants__error-message"
              type="minimal"
              isExpanded={
                (errorMessage.saveUpload || errorMessage.deleteUpload) && true
              }
            >
              {errorMessage.saveUpload}
              {errorMessage.deleteUpload}
            </ExpansionPanel>
          </div>

          {showDeleteButton && (
            <KiteButton
              className="experiment-variants__delete-variant"
              type="standalone-link"
              size="medium"
              onClick={event => this.handleCheckDeleteVariant(event, variant)}
            >
              Delete
            </KiteButton>
          )}
        </SmartExpansionPanel>
      )
    })
  }

  renderJsonBuilder = variant => {
    const { variants } = this.state
    const { jsonBuilderForm, jsonBuilderValues } = variants[variant]
    const isValid =
      !isEmpty(jsonBuilderForm.name) &&
      !isEmpty(jsonBuilderForm.type) &&
      !isEmpty(jsonBuilderForm.value)
    const disableJson = this.handleValidateDisable()

    return (
      <Fragment>
        <div className="experiment-variants__json-builder">
          <KiteInput
            label="Name"
            name={`${variant}__name`}
            value={jsonBuilderForm.name}
            onChange={this.handleJsonBuilderValueChange}
            placeholder="Name"
            disabled={disableJson}
          />

          <KiteSelect
            label="Type"
            name={`${variant}__type`}
            value={jsonBuilderForm.type}
            onChange={this.handleJsonBuilderValueChange}
            disabled={disableJson}
          >
            <option value="" disabled>
              Select a type
            </option>
            <option value="string">string</option>
            <option value="number">number</option>
            <option value="boolean">boolean</option>
          </KiteSelect>

          {jsonBuilderForm.type && jsonBuilderForm.type === 'boolean' ? (
            <KiteSelect
              label="Value"
              name={`${variant}__value`}
              value={jsonBuilderForm.value}
              onChange={this.handleJsonBuilderValueChange}
              disabled={disableJson}
            >
              <option value="" disabled>
                Select a value
              </option>
              <option value="true">true</option>
              <option value="false">false</option>
            </KiteSelect>
          ) : (
            <KiteInput
              label="Value"
              name={`${variant}__value`}
              value={jsonBuilderForm.value}
              type={jsonBuilderForm.type === 'number' ? 'number' : 'text'}
              disabled={disableJson || !jsonBuilderForm.type}
              onChange={this.handleJsonBuilderValueChange}
              placeholder={
                !jsonBuilderForm.type
                  ? 'Please select a type'
                  : 'Override value'
              }
            />
          )}

          <KiteButton
            type="outline"
            size="medium"
            leftIcon="plus"
            disabled={disableJson || !isValid}
            onClick={event => this.handleAddJsonBuilderValue(event, variant)}
          />
        </div>

        {jsonBuilderValues.length > 0 && (
          <div className="experiment-variants__json-builder-values">
            {jsonBuilderValues.map(({ name, type, value }) => (
              <div
                key={`${name}__${type}__${value}`}
                className="experiment-variants__json-line"
              >
                <span>{`Name: ${name}`}</span>
                <span>{`Type: ${type}`}</span>
                <span>{`Value: ${value}`}</span>

                <div className="experiment-variants__json-line-controls">
                  <KiteIcon
                    name="edit-f"
                    size="1rem"
                    margin="0 1.5rem 0 0"
                    color="#2f313a"
                    onClick={event => {
                      if (!disableJson) {
                        this.handleEditJsonBuilderValue(event, variant, name)
                      }
                    }}
                  />
                  <KiteIcon
                    name="trash-f"
                    size="1rem"
                    margin="0"
                    color="#2f313a"
                    onClick={event => {
                      if (!disableJson) {
                        this.handleDeleteJsonBuilderValue(event, variant, name)
                      }
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </Fragment>
    )
  }

  renderCustomJson = variant => {
    const {
      variants,
      errorMessage: { jsonError },
    } = this.state
    const { customJson, prettyJson, isEditingCustomJson } = variants[variant]
    const disabledClass = this.handleValidateDisable()
      ? 'experiment-variants__custom-disabled'
      : ''
    return (
      <div className="experiment-variants__custom-json">
        <KiteButton
          className={`experiment-variants__custom-json-controls ${disabledClass}`}
          size="small"
          type="standalone-link"
          disabled={this.handleValidateDisable()}
          onClick={
            isEditingCustomJson
              ? event => this.handleSaveCustomJson(event, variant)
              : event => this.handleEditCustomJson(event, variant)
          }
        >
          {isEditingCustomJson ? 'Validate' : 'Edit'}
        </KiteButton>

        {isEditingCustomJson ? (
          <TextArea
            label=""
            tooltip=""
            name={`${variant}__customJson`}
            value={customJson}
            height="14.25rem"
            onChange={this.handleChangeCustomJson}
            errorMessage={
              jsonError && jsonError === variant
                ? copyContent.variants.invalidJsonWarning
                : null
            }
          />
        ) : (
          <pre className="experiment-variants__custom-json-presentation">
            {prettyJson}
          </pre>
        )}
      </div>
    )
  }

  render() {
    const {
      experimentPermissions,
      experimentVariantsData: { loading, experiment },
    } = this.props

    const {
      isJsonRestartModalOpen,
      jsonToRestart,
      isDeleteVariantModalOpen,
      variantToDelete,
      isDeleteUploadModalOpen,
      uploadToDelete,
      isLoading,
      variants,
      isDeleting,
      multipleVariantReason,
    } = this.state

    if (loading || isLoading) {
      return (
        <div className="app__loader">
          <KiteLoader size="7rem" />
        </div>
      )
    }
    const { experimentTypeId, experimentStatusId } = experiment

    const displayNewVariant = !this.handleValidateDisable()
    const showAddVariant = this.handleValidateRequiredFields(variants)
    const multipleVariantDisabled =
      experimentPermissions.length === 0 ||
      experimentStatusId === experimentStatusEnum.COMPLETED ||
      experimentStatusId === experimentStatusEnum.CANCELLED

    return (
      <div className="experiment-variants">
        <div className="experiment-variants__header">
          <h4 className="experiment-variants__title">
            Set Your Control and Variants
          </h4>
          <KiteTooltip>{copyContent.variants.header}</KiteTooltip>
        </div>

        {this.renderVariants()}

        {experimentTypeId === experimentTypeEnum.AB && (
          <ExpansionPanel
            className="sample-launch__comment-container"
            type="minimal"
            isExpanded={Object.keys(variants).length > 4}
          >
            <TextArea
              name="multipleVariantReason"
              label="Multiple Variant Reasoning"
              value={multipleVariantReason}
              disabled={multipleVariantDisabled}
              onChange={this.handleFieldChange}
              placeholder={copyContent.variants.multipleVariantReason}
              height="6rem"
              maxLength="255"
            />
          </ExpansionPanel>
        )}

        <ExpansionPanel
          type="minimal"
          isExpanded={!isLoading && displayNewVariant && showAddVariant}
        >
          <KiteButton
            className="experiment-variants__add-variant"
            type="standalone-link"
            size="medium"
            onClick={this.handleAddVariant}
            leftIcon="plus-circle-f"
          >
            Add Another Variant
          </KiteButton>
        </ExpansionPanel>

        {isJsonRestartModalOpen && jsonToRestart && (
          <Modal
            message={copyContent.variants.loseJsonWarning(jsonToRestart)}
            subMessage={copyContent.variants.loseJsonWarningSubMessage}
            onConfirm={this.handleConfirmRestartJson}
            onDeny={this.handleCancelRestartJson}
            buttonProps={{ confirm: { value: 'Restart JSON' } }}
          />
        )}

        {isDeleteUploadModalOpen && uploadToDelete && (
          <Modal
            message={copyContent.variants.deleteUploadWarning}
            subMessage={copyContent.variants.deleteUploadSubMessage}
            onConfirm={this.handleConfirmDeleteUpload}
            onDeny={this.handleCancelDeleteUpload}
            buttonProps={{ confirm: { value: 'Delete Upload' } }}
          />
        )}

        {isDeleteVariantModalOpen && variantToDelete && (
          <Modal
            message={copyContent.variants.deleteVariantWarning(variantToDelete)}
            subMessage={copyContent.variants.deleteVariantSubMessage}
            onConfirm={this.handleConfirmDeleteVariant}
            onDeny={this.handleCancelDeleteVariant}
            loading={isDeleting}
            buttonProps={{ confirm: { value: 'Delete Variant' } }}
          />
        )}
      </div>
    )
  }
}

export default flowRight(
  getExperimentVariants,
  insertExperimentVariants,
  updateExperimentVariants,
  deleteExperimentVariant,
  insertVariantUploads,
  deleteVariantUpload,
  updateExperimentPlan
)(Variants)
