import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

/*
* Sample Input
*
* {
  productId: Int!
  overrideTypeId: OverrideTypeEnum! (DEVICE || ACCOUNT)
}
*/

export const GET_OVERRIDE_GROUPS_BY_PRODUCT_AND_TYPE = gql`
  query getOverrideGroupsByProductAndType($input: TypeAndProduct!) {
    overrideGroupsByProductAndType(input: $input) {
      id
      name
      overrides {
        id
        name
        overrideIdentifier
      }
    }
  }
`

export const GET_ALL_OVERRIDE_GROUPS = gql`
  query getAllOverrideGroups {
    overrideGroups {
      id
      name
      overrides {
        id
        name
        overrideIdentifier
      }
    }
  }
`

const getOverrideGroupsByProductAndType = graphql(
  GET_OVERRIDE_GROUPS_BY_PRODUCT_AND_TYPE,
  {
    name: 'overrideGroupsByProductAndTypeData',
    options: ({ productId, overrideTypeId }) => {
      const input = { productId, overrideTypeId }

      return {
        variables: {
          input,
        },
        fetchPolicy: 'cache-and-network',
      }
    },
  }
)

export default getOverrideGroupsByProductAndType

export const getAllOverrideGroups = graphql(GET_ALL_OVERRIDE_GROUPS, {
  name: 'overrideGroupsByProductAndTypeData',
  options: () => {
    return {
      fetchPolicy: 'cache-and-network',
    }
  },
})
