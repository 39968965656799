import React, { Component, Fragment } from 'react'
import { object, string, bool, func, array, number } from 'prop-types'
import { flowRight, isEqual, cloneDeep, isEmpty } from 'lodash'
import {
  CounterInput,
  TextArea,
  AutoSuggest,
  BasicPills,
} from '@kite/react-kite-plus'

import {
  KiteLoader,
  KiteSelect,
  KiteInput,
  KiteRadio,
  KiteLink,
  KiteIcon,
} from '@kite/react-kite'
import { gql } from '@apollo/client'
import {
  isFeatureFlagActive,
  checkPlan,
  permissionEnum,
  roleEnum,
  featureFlagEnum,
  hasPermission,
  experimentTypeEnum,
  checkRoles,
} from '@charter/distillery-rules'

import { client } from '../../../../configuration/configApiClient'
import { GET_PRODUCTS_BY_EXPERIMENT_CAT_GQLBUILDER } from './queries'
import {
  getExperimentPlan,
  getProductFeaturesById,
  GET_EXPERIMENT_ROLES,
} from '../../queries'
import {
  GET_USERS_GQLBUILDER,
  GET_EXPERIMENTS,
} from '../../../../shared/queries'
import {
  LOG_ERROR,
  UPDATE_EXPERIMENT_CONFIG,
  insertExperimentToUsers,
  deleteExperimentToUser,
} from '../../../../shared/mutations'
import {
  insertExperimentPlan,
  updateExperimentPlan,
  updateVariantJsonPayload,
} from '../../mutations'
import { formatLoggingError, GqlBuilder } from '../../../../shared/utilities'
import { CopyButton, ExpansionPanel } from '../../../../componentLibrary'
import { Grid, Slider, Input } from '@material-ui/core'
import { KNOWN_ROLES } from '../../data'
import copyContent from '../../data/copyContent'
import './Plan.scss'

const { EXPERIMENT_UPDATE_RUNNING } = permissionEnum
const { EXPERIMENTER_ADMIN } = roleEnum

const dayMarks = [
  {
    value: 7,
    label: '7',
  },
  {
    value: 14,
    label: '14',
  },
  {
    value: 30,
    label: '30',
  },
  {
    value: 60,
    label: '60',
  },
  {
    value: 90,
    label: '90',
  },
]

const initialErrors = {
  name: null,
  product: null,
  feature: null,
  summary: null,
  hypothesis: null,
  targetSampleSize: null,
  durationNumDays: null,
  getProductFeatures: null,
  duplicateName: null,
  insertPlan: null,
  updatePlan: null,
}

const gqlHOCQuery = new GqlBuilder().compileHOCQuery([
  GET_PRODUCTS_BY_EXPERIMENT_CAT_GQLBUILDER,
  GET_USERS_GQLBUILDER,
])

export class Plan extends Component {
  static propTypes = {
    id: number,
    applicationPermissions: array.isRequired,
    experimentPermissions: array,
    environmentSamplings: array,
    experimentPlanData: object,
    productsData: object,
    disabled: bool,
    experimentConfig: string,
    currentlyViewing: object.isRequired,
    onChange: func.isRequired,
    onNavigate: func.isRequired,
    onUpdateProgress: func.isRequired,
    formatBlankExperimentConfig: func.isRequired,
    insertExperimentPlan: func.isRequired,
    updateExperimentPlan: func.isRequired,
    updateVariantJsonPayload: func.isRequired,
    handleUpdateOverrideBanner: func.isRequired,
  }

  static defaultProps = {
    id: null,
    experimentPlanData: null,
    experimentPermissions: null,
    environmentSamplings: null,
    queryData: null,
    disabled: false,
    experimentConfig: null,
  }

  state = {
    uuid: null,
    product: '',
    productDisplayName: '',
    feature: '',
    name: '',
    summary: '',
    hypothesis: '',
    targetSampleSize: '',
    length: '28',
    otherLength: '',
    type: 2,
    productFeatures: null,
    variantIds: [],
    isLoading: false,
    hasMatchingProductPermissions: true,
    errorMessage: initialErrors,
    rolesById: {},
    inputValuesById: {},
    originalRolesById: {},
    experimentStatus: null,
  }

  // LIFECYCLE METHODS
  componentDidMount() {
    const { experimentPlanData } = this.props
    // Set the experiment to state if one exists
    // CDM used when navigating to Plan inside the app
    if (experimentPlanData && experimentPlanData.experiment) {
      this.handleSetExperiment()
    }
    this.handleSetExperimentUsers()
  }

  componentDidUpdate(prevProps) {
    const { uuid, isLoading } = this.state
    const { experimentPlanData, queryData, hashVariables } = this.props
    const { experimentPlanData: prevPlanData, hashVariables: prevHash } =
      prevProps

    // Set the experiment to state if one exists
    // CDU used when hitting the 'plan' URL directly
    if (
      prevPlanData &&
      queryData.productsByExperimentCategory &&
      experimentPlanData &&
      experimentPlanData.experiment &&
      isEqual(prevPlanData.experiment, experimentPlanData.experiment) &&
      !uuid &&
      !isLoading
    ) {
      this.handleSetExperiment()
      this.handleSetExperimentUsers()
      return
    }

    if (
      prevPlanData &&
      queryData.productsByExperimentCategory &&
      experimentPlanData &&
      !isEqual(prevPlanData.experiment, experimentPlanData.experiment)
    ) {
      this.handleSetExperiment()
      this.handleSetExperimentUsers()
    }

    if (prevHash !== hashVariables) {
      this.setState({
        product: hashVariables.product,
      })
    }
  }

  // LOCAL STATE CHANGES/TOGGLES
  handleSetExperiment = async () => {
    const {
      onChange,
      experimentPlanData: { experiment },
      queryData: { productsByExperimentCategory },
    } = this.props
    const {
      uuid,
      durationNumDays,
      experimentToProductFeatures,
      experimentTypeId,
      hypothesis,
      targetSampleSize,
      name,
      summary,
      variants,
      experimentStatusId,
    } = experiment
    const {
      productId,
      productFeatureId,
      product: { displayName: productDisplayName },
    } = experimentToProductFeatures[0]

    await this.setState({ isLoading: true })
    // Fetch associated features from API to select appropriate feature
    await this.handleGetProductFeatures(productId)

    const hasMatchingProductPermissions =
      productsByExperimentCategory &&
      productsByExperimentCategory.find(product => product.id === productId)
    const variantIds = variants.map(({ id }) => id)
    await this.setState({
      uuid,
      name,
      summary,
      hypothesis,
      targetSampleSize,
      variantIds,
      productDisplayName,
      hasMatchingProductPermissions,
      product: productId,
      feature: productFeatureId,
      length: durationNumDays,
      type: experimentTypeId,
      isLoading: false,
      experimentStatus: experimentStatusId,
    })

    // Pass 'false' to inform parent that the form does not have changes
    //  and is only loading
    onChange(false)
  }

  handleSetExperimentUsers = () => {
    const { experimentPlanData, currentUser } = this.props

    let experimentToUsers = []
    if (
      experimentPlanData &&
      experimentPlanData.experiment &&
      experimentPlanData.experiment.experimentToUsers
    ) {
      experimentToUsers = experimentPlanData.experiment.experimentToUsers
    }

    const rolesById = {}
    const inputValuesById = {}

    Object.values(KNOWN_ROLES).forEach(knownRole => {
      const usersById = {}
      rolesById[knownRole.id] = { ...knownRole, usersById }
      experimentToUsers.forEach(({ role, user, id: experimentToUserId }) => {
        if (Number(role.id) === knownRole.id) {
          const theUser = { ...user, ...{ experimentToUserId } }
          rolesById[knownRole.id].usersById[user.id] = theUser
          inputValuesById[knownRole.id] = theUser.displayName
        }
      })
      if (
        knownRole.allowMultipleUsers ||
        Object.values(usersById).length === 0
      ) {
        inputValuesById[knownRole.id] = ''
      }
    })

    const ogRoles = cloneDeep(rolesById)

    if (experimentToUsers.length === 0) {
      rolesById[2].usersById[currentUser.id] = currentUser
      inputValuesById[2] = currentUser.displayName
    }

    this.setState({
      rolesById,
      originalRolesById: ogRoles,
      inputValuesById,
    })
  }

  handleChange = async ({ target: { name, value } }, roleId = null) => {
    const { onChange, isCMSExperiment } = this.props
    const { feature } = this.state
    if (name === 'product') {
      // Product id must be parsed for API
      this.handleGetProductFeatures(JSON.parse(value))

      // If changing product and a feature is selected, reset the feature
      if (feature !== '' && !isCMSExperiment) {
        await this.setState({ feature: '' })
      }
    }
    if (name === 'role-6') {
      let { inputValuesById } = this.state
      let dsContacts = this.filterDataScienceContacts()
      const userObject = dsContacts.find(user => user.displayName === value)
      this.handlePick(roleId, userObject)
      inputValuesById[roleId] = value
      await this.setState(({ errorMessage }) => ({
        inputValuesById,
        errorMessage: { ...errorMessage, [`role${roleId}Required`]: null },
      }))
    } else if (name.includes('role') && roleId) {
      let { inputValuesById } = this.state
      inputValuesById = { ...inputValuesById }
      inputValuesById[roleId] = value
      // Also clear the role for this user in local state
      // to force the user to make a selection
      const rolesById = this.assignUserToRole(roleId, null)
      await this.setState(({ errorMessage }) => ({
        inputValuesById,
        rolesById,
        errorMessage: { ...errorMessage, [`role${roleId}Required`]: null },
      }))
    } else if (name === 'summary' || name === 'hypothesis') {
      await this.setState(({ errorMessage }) => ({
        [name]: value,
        errorMessage: {
          ...errorMessage,
          [name]:
            value.length < 35
              ? `Please add ${name} with minimum of 35 characters.`
              : null,
        },
      }))
    } else {
      // Handles change of all other values
      await this.setState(({ errorMessage }) => ({
        [name]: value,
        errorMessage: {
          ...errorMessage,
          [name]: null,
        },
      }))
    }

    // Pass 'true' to inform parent that changes have been made
    onChange(true)
  }

  // Handle change of slider
  handleSliderChange = async (event, newValue) => {
    await this.setState({
      length: newValue,
    })
  }

  // Handle pick of roles
  handlePick = (roleId, user) => {
    const { onChange } = this.props

    this.setState({ rolesById: this.assignUserToRole(roleId, user) })
    const role = KNOWN_ROLES[roleId]
    if (role.allowMultipleUsers) {
      this.handleChange(
        { target: { name: `role-${roleId}`, value: '' } },
        roleId
      )
    }
    onChange(true)
  }

  // Handle removal of roles
  handleRemovePick = (userToRemove, roleId) => {
    const { onChange } = this.props
    const { rolesById } = this.state

    const newRolesById = cloneDeep(rolesById)
    const role = newRolesById[roleId]
    if (role.usersById && role.usersById[userToRemove.id]) {
      delete role.usersById[userToRemove.id]
    }
    this.setState({ rolesById: newRolesById })
    onChange(true)
  }

  assignUserToRole = (roleId, user) => {
    const rolesById = { ...this.state.rolesById }
    const role = rolesById[roleId]
    if (!user) {
      if (!role.allowMultipleUsers) role.usersById = {}
    } else if (role.allowMultipleUsers) {
      role.usersById[user.id] = user
    } else role.usersById = { [user.id]: user }
    return rolesById
  }

  // REF METHODS
  // Used by parent and sends any existing error messages
  getErrorMessage = () => {
    const { errorMessage } = this.state

    const messages = Object.values(errorMessage).reduce(
      (accumulator, error) => {
        if (!accumulator && error) {
          return `${error}`
        }
        if (error) {
          return `${accumulator}; ${error}`
        }
        return accumulator
      },
      ''
    )
    return messages
  }

  validateSubmission = async () => {
    const {
      product,
      feature,
      name,
      summary,
      hypothesis,
      targetSampleSize,
      length,
      type,
      rolesById,
      experimentStatus,
    } = this.state

    const { isCMSExperiment } = this.props

    await this.setState({ errorMessage: initialErrors })
    try {
      JSON.parse(length)
    } catch (err) {
      await this.setState({
        errorMessage: {
          durrationNumDays: 'Please select a day length.',
        },
      })
    }
    const input = {
      name,
      summary,
      hypothesis,
      targetSampleSize:
        targetSampleSize !== null &&
        targetSampleSize !== undefined &&
        targetSampleSize !== ''
          ? Number(targetSampleSize)
          : null,
      durationNumDays: JSON.parse(length),
      experimentTypeId: Number(type),
      productToFeature: {
        productId: product ? JSON.parse(product) : null,
        productFeatureId: feature ? JSON.parse(feature) : null,
      },
      isCMSExperiment,
    }

    const experimentToUsers = Object.keys(rolesById).reduce(
      (accumulator, id) => {
        if (!isEmpty(rolesById[id].usersById)) {
          return [...accumulator, { role: { id: Number(id) } }]
        }

        return accumulator
      },
      []
    )

    const { errors: userErrors } = checkRoles({ experimentToUsers })

    const { errors } = checkPlan(input)

    //DYNEXP-2677: allow experimenter to change running experiment without adding a new DS contact
    if (
      (isEmpty(errors) && isEmpty(userErrors)) ||
      (userErrors.role6Required && experimentStatus === 3)
    ) {
      return true
    }
    this.setState(({ errorMessage }) => ({
      errorMessage: {
        ...errorMessage,
        ...errors,
        ...userErrors,
      },
    }))
    return false
  }

  // API METHODS
  handleSubmit = async () => {
    const { id, experimentConfig, isCMSExperiment } = this.props
    const {
      product,
      feature,
      summary,
      hypothesis,
      targetSampleSize,
      length,
      type,
      errorMessage,
    } = this.state

    const durationNumDays = JSON.parse(length)

    const input = {
      summary,
      hypothesis,
      targetSampleSize:
        targetSampleSize !== undefined &&
        targetSampleSize !== null &&
        targetSampleSize !== ''
          ? Number(targetSampleSize)
          : null,
      durationNumDays,
      experimentTypeId: Number(type),
      productToFeature: {
        productId: JSON.parse(product),
        productFeatureId: isCMSExperiment ? 1 : JSON.parse(feature),
      },
      config: experimentConfig,
    }

    // Reset any previous submission errors
    if (errorMessage.insertPlan || errorMessage.updatePlan) {
      this.setState({
        errorMessage: {
          ...errorMessage,
          insertPlan: null,
          updatePlan: null,
          duplicateName: null,
        },
      })
    }

    try {
      if (id) {
        const updatedPlanRes = await this.handleUpdatePlan(input)
        await this.handleUpdateUsers(updatedPlanRes)
        return true
      }
      const insertedPlanRes = await this.handleInsertPlan(input)

      const insertedUsersRes = await this.handleUpdateUsers(insertedPlanRes)
      return { ...insertedPlanRes, ...insertedUsersRes }
    } catch (error) {
      const input = formatLoggingError(error, { id })

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input,
        },
      })

      this.setState(prevState => ({
        errorMessage: {
          ...prevState.errorMessage,
          updateDremExperimentSettingsError: error,
        },
      }))

      return false
    }
  }

  handleInsertPlan = async input => {
    const { name } = this.state
    const {
      onChange,
      formatBlankExperimentConfig,
      insertExperimentPlan: insertPlan,
      isCMSExperiment,
    } = this.props

    const config = formatBlankExperimentConfig()

    try {
      const response = await insertPlan({
        variables: {
          input: {
            ...input,
            isCMSExperiment,
            name: name.trim(),
            config,
          },
        },
        update: (
          proxy,
          { data: { insertExperimentPlan: newExperimentData } }
        ) => {
          // Read data from our cache for insert query
          const data = proxy.readQuery({ query: GET_EXPERIMENTS })

          // Add new experiment from mutation to existing experiments cache and
          // Write data back to the cache
          if (data && data.experiments) {
            proxy.writeQuery({
              query: GET_EXPERIMENTS,
              data: { experiments: [...data.experiments, newExperimentData] },
            })
          }
        },
      })

      // Return the new experiment to update the URL from parent component
      return response.data.insertExperimentPlan
    } catch (error) {
      const errorInput = formatLoggingError(error)

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input: errorInput,
        },
      })

      const isDuplicateName =
        error.message === 'GraphQL error: Duplicate Experiment Name'

      if (isDuplicateName) {
        onChange(false, true)
      }

      this.setState(({ errorMessage }) => ({
        errorMessage: {
          ...errorMessage,
          insertPlan: copyContent.plan.insertPlanError,
          duplicateName: isDuplicateName
            ? copyContent.plan.duplicateNameError
            : null,
        },
      }))

      // Informs parent at the insert failed
      throw new Error(`There was an issue creating your experiment: ${error}`)
    }
  }

  handleUpdatePlan = async updateInput => {
    const { name, variantIds, type, product, feature } = this.state
    const {
      id,
      currentlyViewing,
      onNavigate,
      onChange,
      experimentPlanData: { experiment },
      experimentConfig,
      updateExperimentPlan: updatePlan,
      updateVariantJsonPayload: updateJsonPayload,
      handleUpdateOverrideBanner,
      isCMSExperiment,
    } = this.props

    let input = updateInput

    const experimentToProductFeatureId = Number(
      experiment.experimentToProductFeatures[0].id
    )

    const productIsChanging =
      experiment.experimentToProductFeatures.length > 0 &&
      product !== experiment.experimentToProductFeatures[0].productId
    const featureIsChanging =
      experiment.experimentToProductFeatures.length > 0 &&
      feature !== experiment.experimentToProductFeatures[0].productFeatureId
    const parsedConfig = JSON.parse(experimentConfig)

    if (name !== experiment.name) {
      const trimmedName = name.trim()

      input = {
        ...input,
        name: trimmedName,
      }

      onNavigate({ ...currentlyViewing, title: trimmedName })
    }

    try {
      input = {
        ...input,
        experimentId: Number(id),
        productToFeature:
          productIsChanging || featureIsChanging
            ? {
                ...input.productToFeature,
                experimentToProductFeatureId,
              }
            : undefined,
        activationEventUuid:
          productIsChanging || featureIsChanging
            ? undefined
            : experiment.activationEventUuid,
      }

      await updatePlan({
        variables: {
          input,
        },
        update: async cache => {
          if (productIsChanging || featureIsChanging) {
            // Invalidate metrics and tech settings
            Object.keys(cache.data.data).forEach(
              key =>
                (key.match(/^Metric/) ||
                  key.match(/^TDCS/) ||
                  key.match(/^Tdcs/)) &&
                cache.data.delete(key)
            )

            // Update correct config for type of experiment
            if (!isCMSExperiment) {
              const {
                setUpSteps: {
                  metrics: { status: metricStatus },
                  'tech settings': { status: techStatus },
                  activation: { status: activationStatus },
                },
              } = parsedConfig

              parsedConfig.setUpSteps.metrics.status =
                metricStatus === 'initial' ? 'initial' : 'incomplete'
              parsedConfig.setUpSteps['tech settings'].status =
                techStatus === 'initial' ? 'initial' : 'incomplete'
              parsedConfig.setUpSteps.activation.status =
                activationStatus === 'initial' ? 'initial' : 'incomplete'

              // Mark metrics step as incomplete
              await this.handleUpdateExperimentConfig({
                experimentConfig: JSON.stringify(parsedConfig),
                step: 'metrics',
                status: metricStatus === 'initial' ? 'initial' : 'incomplete',
                areAllStepsComplete: false,
              })
              await this.handleUpdateExperimentConfig({
                experimentConfig: JSON.stringify(parsedConfig),
                step: 'tech settings',
                status: techStatus === 'initial' ? 'initial' : 'incomplete',
                areAllStepsComplete: false,
              })
              await this.handleUpdateExperimentConfig({
                experimentConfig: JSON.stringify(parsedConfig),
                step: 'activation',
                status:
                  activationStatus === 'initial' ? 'initial' : 'incomplete',
                areAllStepsComplete: false,
              })
            } else {
              const {
                setUpSteps: {
                  metrics: { status: metricStatus },
                },
              } = parsedConfig

              parsedConfig.setUpSteps.metrics.status =
                metricStatus === 'initial' ? 'initial' : 'incomplete'
              // Mark metrics step as incomplete
              await this.handleUpdateExperimentConfig({
                experimentConfig: JSON.stringify(parsedConfig),
                step: 'metrics',
                status: metricStatus === 'initial' ? 'initial' : 'incomplete',
                areAllStepsComplete: false,
              })
            }
          }
        },
      })

      // DYNEXP-2366
      // Update the display banner for caution on overrides
      const isExperimentSetUpComplete =
        Object.keys(parsedConfig.setUpSteps).filter(
          key => parsedConfig.setUpSteps[key].status === 'initial'
        ).length === 0

      if (productIsChanging && isExperimentSetUpComplete) {
        await handleUpdateOverrideBanner(true)
      }

      // If the experiment type has changed
      if (Number(type) !== experiment.experimentTypeId) {
        // Update config if going to AB and variants is complete
        if (
          Number(type) !== experimentTypeEnum.AA &&
          parsedConfig.setUpSteps.variants.status === 'complete'
        ) {
          parsedConfig.setUpSteps.variants.status = 'incomplete'

          await this.handleUpdateExperimentConfig({
            experimentConfig: JSON.stringify(parsedConfig),
            step: 'variants',
            status: 'incomplete',
            areAllStepsComplete: false,
          })
        }

        // Remove jsonPayloads if going to AA and variants is complete
        if (variantIds.length > 0 && Number(type) === experimentTypeEnum.AA) {
          await updateJsonPayload({
            variables: {
              input: {
                experimentId: Number(id),
                variants: variantIds.map(variantId => ({
                  id: variantId,
                  jsonPayload: '',
                })),
              },
            },
          })

          if (parsedConfig.setUpSteps.variants.status === 'incomplete') {
            parsedConfig.setUpSteps.variants.status = 'complete'

            const areAllStepsComplete =
              Object.keys(parsedConfig.setUpSteps).filter(
                step =>
                  parsedConfig.setUpSteps[step].status === 'incomplete' ||
                  parsedConfig.setUpSteps[step].status === 'initial'
              ).length === 0

            await this.handleUpdateExperimentConfig({
              areAllStepsComplete,
              experimentConfig: JSON.stringify(parsedConfig),
              step: 'variants',
              status: 'complete',
            })
          }
        }
      }

      return true
    } catch (error) {
      const errorInput = formatLoggingError(error, { id })

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input: errorInput,
        },
      })

      const isDuplicateName =
        error.message === 'GraphQL error: Experiment name already exists'

      if (isDuplicateName) {
        onChange(false, true)
      }

      this.setState(({ errorMessage }) => ({
        errorMessage: {
          ...errorMessage,
          updatePlan: copyContent.plan.updatePlanError,
          duplicateName: isDuplicateName
            ? copyContent.plan.duplicateNameError
            : null,
        },
      }))

      // Informs parent that the update failed
      return false
    }
  }

  handleUpdateUsers = async (experiment = {}) => {
    const {
      id,
      insertExperimentToUsers: insertUsers,
      deleteExperimentToUser: deleteUser,
    } = this.props
    const { rolesById, originalRolesById, errorMessage } = this.state

    // Reset any previous submission errors
    if (errorMessage.insertRoles) {
      this.setState({
        errorMessage: { ...errorMessage, insertRoles: null },
      })
    }
    const experimentID = id ? Number(id) : experiment.id
    const input = { experimentID, rolesAndUsers: [] }
    const deleteInput = { ids: [] }

    Object.values(rolesById).forEach(role => {
      Object.values(role.usersById).forEach(user => {
        input.rolesAndUsers.push({
          roleID: Number(role.id),
          userID: Number(user.id),
        })
      })

      if (role.id === 3) {
        // Take the original admin roles, filter to users who are not found in the new
        // admin roles, to build a delete list
        const usersToDelete = Object.values(
          originalRolesById[3].usersById
        ).filter(
          user =>
            Object.values(role.usersById).find(
              otherUser => otherUser.id === user.id
            ) === undefined
        )
        usersToDelete.forEach(userToDelete =>
          deleteInput.ids.push(userToDelete.experimentToUserId)
        )
      }
    })

    try {
      await insertUsers({
        variables: {
          input,
        },
        refetchQueries: [
          { query: GET_EXPERIMENT_ROLES, variables: { id: experimentID } },
        ],
      })

      if (deleteInput.ids.length) {
        await deleteUser({ variables: { input: deleteInput } })
      }

      return true
    } catch (error) {
      const errorInput = formatLoggingError(error, { experimentID })

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input: errorInput,
        },
      })

      this.setState({
        errorMessage: {
          ...errorMessage,
          insertRoles: copyContent.roles.insertRolesError,
        },
      })

      return false
    }
  }

  handleUpdateExperimentConfig = async progressConfig => {
    const { id, onUpdateProgress } = this.props
    const { experimentConfig: config } = progressConfig
    const configInput = {
      id: Number(id),
      config,
    }
    await client.mutate({
      mutation: UPDATE_EXPERIMENT_CONFIG,
      variables: {
        input: configInput,
      },
    })
    await onUpdateProgress(progressConfig)
  }

  handleGetProductFeatures = async id => {
    const { errorMessage } = this.state

    // Reset any error messages
    if (errorMessage.getProductFeatures) {
      this.setState({
        errorMessage: { ...errorMessage, getProductFeatures: null },
      })
    }

    try {
      const response = await client.query({
        query: getProductFeaturesById,
        variables: {
          id,
        },
      })

      const productFeatures = response.data.product.features

      this.setState({ productFeatures })
    } catch (error) {
      const input = formatLoggingError(error)

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input,
        },
      })
      this.setState({
        errorMessage: {
          ...errorMessage,
          getProductFeatures: copyContent.getFeaturesError,
        },
      })
    }
  }

  // RENDER METHODS
  renderProductOptions = () => {
    const {
      queryData: { productsByExperimentCategory, productCategoryTypes },
      isCMSExperiment,
    } = this.props
    return (
      <Fragment>
        <option value="" disabled>
          Select One
        </option>

        {productCategoryTypes
          .slice()
          .sort((aType, bType) => aType.name > bType.name)
          .map(({ id: categoryId, name }) => {
            const productOpts = productsByExperimentCategory
              .slice()
              .filter(({ productCategoryType: { id } }) => id === categoryId)
              .sort((aProduct, bProduct) =>
                aProduct.displayName.toLowerCase() >
                bProduct.displayName.toLowerCase()
                  ? 1
                  : -1
              )
              .map(product => (
                <option key={product.id} value={product.id}>
                  {product.displayName}
                </option>
              ))
            if (productOpts.length && !isCMSExperiment) {
              return (
                <optgroup key={categoryId} label={name}>
                  {productOpts}
                </optgroup>
              )
            } else if (productOpts.length) {
              return productOpts
            } else {
              return ''
            }
          })}
      </Fragment>
    )
  }

  renderFeatureOptions = () => {
    const { productFeatures } = this.state

    const featuresByType = productFeatures
      .slice()
      .reduce((accumulator, feature) => {
        if (!accumulator[feature.featureType.name]) {
          accumulator[feature.featureType.name] = []
        }

        accumulator[feature.featureType.name].push(feature)

        return accumulator
      }, {})

    return (
      <Fragment>
        <option value="" disabled>
          Select One
        </option>
        {Object.keys(featuresByType)
          .sort((aType, bType) => (aType > bType ? 1 : -1))
          .map(type => (
            <optgroup key={type} label={type}>
              {featuresByType[type]
                .sort((aFeature, bFeature) =>
                  aFeature.displayName > bFeature.displayName ? 1 : -1
                )
                .map(feature => (
                  <option key={feature.id} value={feature.id}>
                    {feature.displayName}
                  </option>
                ))}
            </optgroup>
          ))}
      </Fragment>
    )
  }

  renderDataScienceContactOptions = () => {
    const {
      queryData: { users },
    } = this.props
    const dataScienceUsers = users.filter(user =>
      user.userToRoles.some(role => role.roleId === 33)
    )
    return (
      <Fragment>
        <option value="" disabled>
          Select One
        </option>
        {dataScienceUsers.map(option => (
          <option key={option.id} value={option.displayName}>
            {option.displayName === 'Contact, Data Science'
              ? "I don't have a contact"
              : option.displayName}
          </option>
        ))}
      </Fragment>
    )
  }

  filterDataScienceContacts = () => {
    const {
      queryData: { users },
    } = this.props
    const dataScienceUsers = users.filter(user =>
      user.userToRoles.some(role => role.roleId === 33)
    )
    return dataScienceUsers
  }

  renderRoles = canUpdateRunning => {
    const {
      queryData: { users },
      disabled,
    } = this.props
    const { rolesById, inputValuesById, errorMessage } = this.state

    const { featureFlags } = client.readQuery({
      query: gql`
        query getCurrentUser {
          featureFlags {
            id
            name
            active
          }
        }
      `,
    })

    const dataScienceContactActive = isFeatureFlagActive({
      featureFlags,
      featureFlagId: featureFlagEnum.DATA_SCIENCE_CONTACT,
      applicationPermissions: [],
    })

    const roleInputs = []
    const sortedRoles = Object.values(rolesById).sort(
      (firstRole, secondRole) => firstRole.order - secondRole.order
    )

    sortedRoles.forEach(role => {
      const filteredUsers = users.filter(
        user =>
          Object.values(role.usersById).find(
            otherUser => otherUser.id === user.id
          ) === undefined
      )

      const placeholder = role.allowMultipleUsers
        ? `Names of the ${role.displayName}.`
        : `Name of the ${role.displayName}.`

      const enableField = role.id === EXPERIMENTER_ADMIN && canUpdateRunning
      let autoSuggest = ''
      if (role.displayName !== 'Data Science Contact') {
        autoSuggest = (
          <AutoSuggest
            key={`role-${role.id}`}
            label={role.displayName}
            value={inputValuesById[role.id]}
            name={`role-${role.id}`}
            disabled={disabled && !enableField}
            initialData={filteredUsers}
            suggestionKey="displayName"
            minCharacters={3}
            showIcon={false}
            className="plan__roles-input"
            tooltip={copyContent.roles[`role${role.id}Tooltip`]}
            errorMessage={errorMessage[`role${role.id}Required`] || false}
            onChange={event => this.handleChange(event, role.id)}
            onSuggestionSelected={(event, data) => {
              event.preventDefault()
              this.handlePick(role.id, data.suggestion)
            }}
            placeholder={placeholder}
          />
        )
      } else if (dataScienceContactActive) {
        autoSuggest = (
          <KiteSelect
            key={`role-${role.id}`}
            label={role.displayName}
            value={inputValuesById[role.id]}
            name={`role-${role.id}`}
            disabled={disabled && !enableField}
            className="plan__roles-input"
            tooltip={copyContent.roles[`role${role.id}Tooltip`]}
            errorMessage={errorMessage[`role${role.id}Required`]}
            onChange={event => {
              this.handleChange(event, role.id)
            }}
          >
            {this.renderDataScienceContactOptions()}
          </KiteSelect>
        )
      }

      if (!role.allowMultipleUsers) {
        roleInputs.push(autoSuggest)
      } else {
        // A little needed formatting for pills.  Per input, so can't hoist.
        const usersByName = {}
        Object.values(role.usersById).forEach(user => {
          usersByName[user.displayName] = user
        })

        roleInputs.push(
          <div className="role-multiple" key={`role-${role.id}`}>
            {autoSuggest}
            <div className="role-multiple__pills">
              <BasicPills
                values={usersByName}
                onRemovePill={
                  disabled
                    ? null
                    : userToRemove =>
                        this.handleRemovePick(userToRemove, role.id)
                }
              />
            </div>
          </div>
        )
      }
    })

    return roleInputs
  }

  render() {
    const {
      isCMSExperiment,
      disabled,
      applicationPermissions,
      experimentPermissions,
      environmentSamplings,
      queryData: { loading: productsLoading },
      experimentRolesData,
    } = this.props

    const {
      uuid,
      product,
      productDisplayName,
      feature,
      name,
      hypothesis,
      targetSampleSize,
      summary,
      length,
      type,
      productFeatures,
      hasMatchingProductPermissions,
      isLoading,
      errorMessage,
    } = this.state

    const canUpdateRunning =
      experimentPermissions &&
      hasPermission({
        applicationPermissions,
        experimentPermissions,
        environmentSamplings,
        permissionId: EXPERIMENT_UPDATE_RUNNING,
      })

    if (
      productsLoading ||
      isLoading ||
      (experimentRolesData && experimentRolesData.loading)
    ) {
      return (
        <div className="app__loader">
          <KiteLoader size="7rem" />
        </div>
      )
    }

    return (
      <div className="plan">
        <h3>General</h3>
        {uuid && (
          <div className="experiment-plan__uuid-container">
            <KiteInput
              className="experiment-plan__uuid"
              label="UUID"
              id="UUID"
              name="UUID"
              value={uuid}
              disabled
            />

            <CopyButton textToCopy={uuid} />
          </div>
        )}

        <div className="plan__product-type-container">
          {!hasMatchingProductPermissions ? (
            <KiteInput
              className="experiment-plan__uuid"
              label="Product"
              value={productDisplayName}
              disabled
            />
          ) : (
            <KiteSelect
              className="experiment-plan__product"
              name="product"
              label="Product"
              value={product}
              disabled={disabled}
              onChange={this.handleChange}
              errorMessage={
                errorMessage.product || errorMessage.getProductFeatures
                  ? `${errorMessage.product || ''} ${
                      errorMessage.getProductFeatures || ''
                    }`
                  : false
              }
            >
              {this.renderProductOptions()}
            </KiteSelect>
          )}

          {!isCMSExperiment && (
            <KiteRadio
              buttonOrientation="row"
              className="experiment-plan__type"
              name="type"
              groupLabel="What type of experiment is this?"
              disabled={disabled}
              tooltip={copyContent.plan.type}
              onChange={this.handleChange}
              radioButtons={[
                {
                  id: 'AB',
                  label: 'A/B',
                  value: 2,
                  checked: type && JSON.parse(type) === 2,
                },
                {
                  id: 'AA',
                  label: 'A/A',
                  value: 1,
                  checked: type && JSON.parse(type) === 1,
                },
                {
                  label: 'Canary',
                  value: 3,
                  checked: type && JSON.parse(type) === 3,
                },
              ]}
            />
          )}
        </div>

        <ExpansionPanel
          isExpanded={!isCMSExperiment && productFeatures && product !== ''}
          type="minimal"
        >
          <KiteSelect
            className="experiment-plan__feature"
            name="feature"
            label="Feature"
            value={feature}
            disabled={disabled}
            onChange={this.handleChange}
            errorMessage={errorMessage.feature}
          >
            {productFeatures && this.renderFeatureOptions()}
          </KiteSelect>
        </ExpansionPanel>

        <CounterInput
          className={`experiment-plan__name ${
            isCMSExperiment ? 'plan__name' : ''
          }`}
          name="name"
          label="Descriptive Name"
          value={name}
          disabled={disabled}
          maxCharCount={35}
          onChange={this.handleChange}
          noSpecialCharacters
          errorMessage={
            errorMessage.duplicateName || errorMessage.name
              ? `${errorMessage.duplicateName || ''} ${errorMessage.name || ''}`
              : false
          }
        />

        <TextArea
          className="experiment-plan__description"
          name="summary"
          label="Problem Statement/Objective"
          value={summary}
          disabled={disabled && !canUpdateRunning}
          onChange={this.handleChange}
          placeholder={copyContent.plan.summary}
          tooltip={copyContent.plan.summary}
          height="8.125rem"
          width="50rem"
          maxLength="60000"
          errorMessage={errorMessage.summary}
        />

        <TextArea
          className="experiment-plan__hypothesis"
          name="hypothesis"
          label="Hypothesis"
          value={hypothesis}
          disabled={disabled}
          onChange={this.handleChange}
          placeholder={copyContent.plan.hypothesis}
          tooltip={copyContent.plan.hypothesis}
          height="8.125rem"
          width="50rem"
          maxLength="425"
          errorMessage={errorMessage.hypothesis}
        />

        <div className="plan__length-container">
          <div>Planned Experiment Length (days)</div>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <Slider
                name="length"
                value={Number(length)}
                onChange={this.handleSliderChange}
                aria-labelledby="input-slider"
                marks={dayMarks}
                disabled={disabled}
              />
            </Grid>
            <Grid item>
              <Input
                className="plan__length-input"
                value={Number(length)}
                margin="dense"
                name="length"
                id="length"
                onChange={this.handleChange}
                inputProps={{
                  step: 10,
                  min: 0,
                  max: 100,
                  type: 'number',
                  'aria-labelledby': 'input-slider',
                }}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </div>

        <div className="plan__target-sample-container">
          <KiteInput
            label="Target Sample Size"
            name="targetSampleSize"
            value={targetSampleSize === null ? '' : targetSampleSize}
            onChange={this.handleChange}
            disabled={disabled}
            placeholder="Optional from calculator"
            errorMessage={errorMessage.targetSampleSize}
            tooltip={
              <div>
                The{' '}
                <a href="mailto://DL-DPDATA-Experimentation@charter.com">
                  Experimentation Team
                </a>{' '}
                can calculate Target Sample Sizes and Experiment durations for
                platforms and metrics not yet available in the Distillery
                Suggested Sample Size Calculator.
              </div>
            }
          />

          <div className="plan__calculator-link-container">
            <div>Still need these numbers?</div>
            <KiteLink newTab href={`/sample-calculator`} type="standalone">
              Sample Size Calculator
              <KiteIcon margin="5px" name="arrow-up-right" size="1rem" />
            </KiteLink>
          </div>
        </div>

        <h3>Roles</h3>

        <div className="plan__roles-container">
          {this.renderRoles(canUpdateRunning)}
        </div>
      </div>
    )
  }
}

export default flowRight(
  gqlHOCQuery,
  getExperimentPlan,
  insertExperimentPlan,
  updateExperimentPlan,
  updateVariantJsonPayload,
  insertExperimentToUsers,
  deleteExperimentToUser
)(Plan)
