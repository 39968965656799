import React, { useEffect, useState, useReducer, useCallback } from 'react'
import { useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import barChartImage from './data/empty-bar-chart.png'
import lineChartImage from './data/empty-line-graph.png'

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  Cell,
  ReferenceArea,
} from 'recharts'
import { createQuantumApolloClient } from '@charter/distillery-rules'
import { GET_QUANTUM_METRIC_COUNTS } from './queries'
import GET_EXPERIMENTS_DETAILS from './queries/getExperimentDetails'
import copyContent from '../../data/copyContent'
import yaml from 'js-yaml'
import { commaSeparateNumber } from '../../../../shared/utilities'
import {
  KiteTooltip,
  KiteTabs,
  KiteCheckbox,
  KiteIcon,
  KiteLoader,
  KiteLink,
  KiteButton,
  KiteModal,
} from '@kite/react-kite'
import './Monitor.scss'
import formatDataForRecharts from './formatChartUtilities.js'

dayjs.extend(customParseFormat)
dayjs.extend(localizedFormat)

export function Monitor({
  experiment: {
    id,
    uuid,
    startTime,
    experimentStatus: { name: experimentStatus },
    variants,
    environmentSamplings,
    activationEvent,
    experimentToProductFeatures: [experimentToProductFeatures],
  },
  tdcsSessionType: { id: sessionTypeId },
  prismDeeplinkActive,
}) {
  const experimentRunning = experimentStatus === 'RUNNING'
  const spectrumGuide =
    experimentToProductFeatures.product.displayName === 'Spectrum Guide'
      ? true
      : false
  const aeName = activationEvent ? activationEvent.name : 'N/A'
  const aeYaml = activationEvent ? activationEvent.yaml : ''
  const activationEventJson = yaml.safeLoad(aeYaml)
  const QUANTUM_DATE_FORMAT = 'YYYY-MM-DD'
  const allocationMethod = sessionTypeId === 1 ? 'Account' : 'Device'
  const variantColors = [
    '#478eeb',
    '#783677',
    '#ee8a2a',
    '#0d6031',
    '#ffec99',
    '#b1e0f3',
    '#d8d0d8',
    '#f6d3b1',
    '#92c1a6',
    '#001019',
  ]

  const {
    monitor: { allocationMethodTooltip, noMonitoringData },
  } = copyContent
  const activationEventTooltip =
    aeName !== 'N/A'
      ? activationEventJson[aeName].description
      : 'No activation event for CMS experiment'
  const tabs = ['Total', 'By Variant']

  const initGraphData = {
    quantumMetricsData: {},
    quantumMetricsDataAvailable: false,
    missingDates: [],
    barChartData: null,
    lineChartData: null,
    graphDataComplete: false,
  }

  const [loading, setLoading] = useState(true)
  const [activeTabLineGraph, setActiveTabLineGraph] = useState('Total')
  const [activationEventName, setActivationEventName] = useState()
  const [viewingActivatedDevices, setViewingActivatedDevices] = useState(true)
  const [viewingAllocatedDevices, setViewingAllocatedDevices] = useState(true)
  const [activationEventModal, setActivationEventModal] = useState(false)
  const [prismUrl, setPrismUrl] = useState(null)

  const { data } = useQuery(GET_EXPERIMENTS_DETAILS, {
    variables: { id: id },
    fetchPolicy: 'network-only',
  })

  const reducer = (state, action) => {
    switch (action.type) {
      case 'fetchQuantumData':
        return {
          ...state,
          quantumMetricsData: action.state.quantumMetricsData,
          quantumMetricsDataAvailable: action.state.quantumMetricsDataAvailable,
        }
      case 'formatInitialCharts':
        return {
          ...state,
          barChartData: action.state.barChartData,
          lineChartData: action.state.lineChartData,
          missingDates: action.state.missingDates,
        }
      case 'enrichVariantInformation':
        return {
          ...state,
          barChartData: action.state.barChartData,
          lineChartData: action.state.lineChartData,
          graphDataComplete: action.state.graphDataComplete,
        }
      case 'failedQuantumQuery':
        return {
          ...state,
          quantumMetricsDataAvailable: false,
          graphDataComplete: true,
        }
      default:
    }
  }

  const [graphsState, dispatch] = useReducer(reducer, initGraphData)

  const {
    quantumMetricsData,
    quantumMetricsDataAvailable,
    missingDates,
    barChartData,
    lineChartData,
    graphDataComplete,
  } = graphsState

  const queryQuantum = useCallback(async () => {
    const {
      experiment: {
        uuid: experimentId,
        startTime,
        experimentToProductFeatures,
      },
      retrieveQuantumToken: quantumToken,
    } = data

    try {
      const quantumMetricsApiClient = createQuantumApolloClient({
        quantumToken,
      })

      // Grab the app coords for quantum to filter search. For example: OneApp@FireTV
      const [
        {
          product: { quantumApplicationType, quantumApplicationName },
        },
      ] = experimentToProductFeatures

      const appCoords = getAppCoords(
        quantumApplicationName,
        quantumApplicationType
      )
      // Grab the start and end dates
      const start = dayjs(startTime).format(QUANTUM_DATE_FORMAT)
      const end = dayjs().format(QUANTUM_DATE_FORMAT)
      const denverDateRange = { start, end }

      const quantumQuery = quantumMetricsApiClient.query({
        query: GET_QUANTUM_METRIC_COUNTS,
        variables: {
          quantumToken,
          denverDateRange,
          experimentId,
          subdimensions: [null],
          applicationCoords: [appCoords],
        },
      })

      const response = await quantumQuery

      const {
        data: { counts },
      } = response

      if (counts.length < 1) {
        throw Error('No quantum data')
      }

      setLoading(false)
      return counts
    } catch (error) {
      setLoading(false)
      dispatch({
        type: 'failedQuantumQuery',
      })
    }
  }, [data])

  const addAllocationHistoryToBarChartData = useCallback(
    experimentAllocationChanges => {
      let runningAllocation = 0
      return barChartData.reduce((acc, barChartEntry) => {
        if (barChartEntry.date in experimentAllocationChanges) {
          const [{ sample_allocation: sampleAllocation }] =
            experimentAllocationChanges[barChartEntry.date]
          barChartEntry['allocationData'] = sampleAllocation
          runningAllocation = sampleAllocation
        } else {
          barChartEntry['allocationData'] = runningAllocation
        }
        acc.push(barChartEntry)
        return acc
      }, [])
    },
    [barChartData]
  )

  const addAllocationHistoryToLineChartData = useCallback(
    (experimentAllocationChanges, variantConfigsByDate) => {
      let runningAllocation = 0
      let runningVariantPercentages = variants.reduce((acc, variant) => {
        acc[variant.uuid] = 0
        return acc
      }, {})
      //add overall sampleAllocation history to line chart data
      return (
        lineChartData
          .map(lineChartEntry => {
            if (lineChartEntry.date in experimentAllocationChanges) {
              const [{ sample_allocation: sampleAllocation }] =
                experimentAllocationChanges[lineChartEntry.date]
              lineChartEntry['allocationData'] = sampleAllocation
              runningAllocation = sampleAllocation
            } else {
              lineChartEntry['allocationData'] = runningAllocation
            }
            return lineChartEntry
          })
          //add allocation percentages based on each variants' weight into the line chart data
          .reduce((acc, lineChartEntry) => {
            if (lineChartEntry.date in variantConfigsByDate) {
              let totalWeight = Object.values(
                variantConfigsByDate[lineChartEntry.date]
              ).reduce((acc, val) => {
                return acc + val
              }, 0)
              Object.entries(variantConfigsByDate[lineChartEntry.date]).forEach(
                ([key, value]) => {
                  let percentage =
                    totalWeight === 0
                      ? 0
                      : (value / totalWeight).toFixed(2) * 100
                  lineChartEntry[key + '-AllocationPercentage'] = percentage
                  lineChartEntry = { ...lineChartEntry }
                  runningVariantPercentages[key] = percentage
                }
              )
            } else {
              Object.keys(runningVariantPercentages).forEach(key => {
                lineChartEntry[key + '-AllocationPercentage'] =
                  runningVariantPercentages[key]
              })
            }
            acc.push(lineChartEntry)
            return acc
          }, [])
      )
    },
    [lineChartData, variants]
  )

  const handleAllocationEnrichment = useCallback(
    ([environmentSamplings]) => {
      //get changes to overall allocation for the selected experiment
      const experimentAllocationChanges =
        getExperimentAllocationChanges(environmentSamplings)
      //get variants and their historical weighting for line graph visualization 'by variant'
      const variantConfigsByDate = getVariantConfigsByDate(
        experimentAllocationChanges
      )

      dispatch({
        type: 'enrichVariantInformation',
        state: {
          lineChartData: addAllocationHistoryToLineChartData(
            experimentAllocationChanges,
            variantConfigsByDate
          ),
          barChartData: addAllocationHistoryToBarChartData(
            experimentAllocationChanges
          ),
          graphDataComplete: true,
        },
      })
    },
    [addAllocationHistoryToLineChartData, addAllocationHistoryToBarChartData]
  )

  const createPrismUrl = useCallback(() => {
    let url = 'https://prism.spectrumtoolbox.com/'

    const {
      experiment: {
        uuid: experimentId,
        startTime,
        experimentToProductFeatures,
        variants,
      },
    } = data

    const [
      {
        product: { quantumApplicationType, quantumApplicationName },
      },
    ] = experimentToProductFeatures

    url =
      url +
      getAppCoords(quantumApplicationName, quantumApplicationType) +
      '/client-analytics/experiment-snapshot/details'

    url =
      url +
      `?activatedExperiments[]=${experimentId}&environment=production&groupBy=variantUuids&period=`

    let period = '24h'
    let step = '3600000'
    //change 'step' and 'period' url params if experiment is >24 hours old
    if (dayjs().diff(dayjs(startTime), 'hour') > 24) {
      period = '7d'
      step = '86400000'
    }

    url = url + `${period}&step=${step}`

    //add query param for each variant uuid
    url += variants.reduce((acc, variant) => {
      acc += '&variantUuids[]=' + variant.uuid
      return acc
    }, '')

    return url
  }, [data])

  useEffect(() => {
    if (variants && aeName) {
      setActivationEventName(aeName)
    }
    if (data && !quantumMetricsDataAvailable) {
      async function makeQuantumQuery() {
        const quantumData = await queryQuantum()
        let quantumMetricsDataAvailable = false
        if (quantumData && quantumData.length >= 1) {
          quantumMetricsDataAvailable = true
          setLoading(false)
        }
        dispatch({
          type: 'fetchQuantumData',
          state: {
            quantumMetricsData: quantumData,
            quantumMetricsDataAvailable,
          },
        })
      }

      setPrismUrl(createPrismUrl())
      makeQuantumQuery()
    }
    if (quantumMetricsDataAvailable && (!barChartData || !lineChartData)) {
      const { barChartData, lineChartData } =
        formatDataForRecharts(quantumMetricsData)
      const missingDates = lineChartData
        .filter(entry => {
          return entry.totalByAccount === 0
        })
        //recharts takes a range for ReferenceArea, so rather than calculating sequential range of missing dates, each missing data point has a range of one day and then we map through these dates to draw ReferenceAreas on the chart
        .reduce((acc, match) => {
          let obj = {
            start: match.date.slice(-5),
            end: dayjs(match.date)
              .add(1, 'day')
              .format(QUANTUM_DATE_FORMAT)
              .slice(-5),
          }
          acc.push(obj)
          return acc
        }, [])
      dispatch({
        type: 'formatInitialCharts',
        state: {
          barChartData,
          lineChartData,
          missingDates,
        },
      })
    }
    if (barChartData && lineChartData && !graphDataComplete) {
      const {
        experiment: { environmentSamplings },
      } = data
      handleAllocationEnrichment(environmentSamplings)
    }
  }, [
    data,
    activationEvent,
    variants,
    aeName,
    graphDataComplete,
    environmentSamplings,
    quantumMetricsDataAvailable,
    quantumMetricsData,
    lineChartData,
    barChartData,
    handleAllocationEnrichment,
    queryQuantum,
    createPrismUrl,
    id,
  ])

  const getAppCoords = (quantumApplicationName, quantumApplicationType) => {
    let quantumName = quantumApplicationName

    if (quantumName.toLowerCase() === 'spectrummobile') {
      quantumName = 'SpecU'
    }
    const appCoords =
      quantumApplicationName && quantumApplicationType
        ? `${quantumName}@${quantumApplicationType}`
        : null

    return appCoords
  }
  const getVariantConfigsByDate = experimentAllocationChanges => {
    return Object.entries(experimentAllocationChanges).reduce(
      (variantConfigsByDate, [key, value]) => {
        variantConfigsByDate[key] = value.reduce((acc, configEntries) => {
          acc[configEntries.uuid] = configEntries.weight
          return acc
        }, {})
        return variantConfigsByDate
      },
      {}
    )
  }

  const getExperimentAllocationChanges = environmentSamplings => {
    return (
      environmentSamplings.allocationHistory
        .reduce((allocationChanges, entry) => {
          let obj = {
            date: entry.createdTime.slice(0, 10),
            config: JSON.parse(entry.variantConfig),
          }
          allocationChanges.push(obj)
          return allocationChanges
        }, [])
        // if a user changes allocation twice in the same day, the first item in this array is the last allocation change value for that day
        .reduce((experimentAllocationChanges, entry) => {
          if (experimentAllocationChanges[entry.date]) {
            return experimentAllocationChanges
          } else {
            experimentAllocationChanges[entry.date] = entry.config
          }
          return experimentAllocationChanges
        }, {})
    )
  }

  const formatMissingAllocatedData = data => {
    if (data[`new${allocationMethod}sAllocated`] <= 0) {
      return data[`new${allocationMethod}sActivated`]
    }
    return data[`new${allocationMethod}sAllocated`]
  }

  const formatMissingActivatedData = data => {
    if (data[`new${allocationMethod}sActivated`] <= 0) {
      return data[`new${allocationMethod}sAllocated`]
    }
    return data[`new${allocationMethod}sActivated`]
  }

  const CustomTooltip = ({ active, payload, chartType }) => {
    if (active && payload && payload.length) {
      const [
        {
          payload: {
            totalByAccount,
            totalByDevice,
            allocationData,
            newAccountsAllocated,
            newAccountsActivated,
            newDevicesAllocated,
            newDevicesActivated,
            date,
          },
        },
      ] = payload
      const [formattedDate] = dayjs(date).format('ll').split(',')
      if (chartType === 'bar') {
        return (
          <div className="custom-tooltip">
            <p>{formattedDate}</p>
            <div>
              <p>{`New ${allocationMethod}s Allocated: ${
                allocationMethod === 'Account'
                  ? commaSeparateNumber(newAccountsAllocated)
                  : commaSeparateNumber(newDevicesAllocated)
              }`}</p>
              {activationEvent && (
                <p>{`New ${allocationMethod}s Activated: ${
                  allocationMethod === 'Account'
                    ? commaSeparateNumber(newAccountsActivated)
                    : commaSeparateNumber(newDevicesActivated)
                }`}</p>
              )}
            </div>
            <p>{`Sampling: ${allocationData * 100}%`} </p>
          </div>
        )
      } else {
        return (
          <div className="custom-tooltip">
            <p>{formattedDate}</p>
            <p>{`Total ${allocationMethod}s Activated: ${
              allocationMethod === 'Account'
                ? commaSeparateNumber(totalByAccount)
                : commaSeparateNumber(totalByDevice)
            }`}</p>
            <p>{`Sampling: ${allocationData * 100}%`} </p>
            {activeTabLineGraph !== 'Total' &&
              // eslint-disable-next-line
              variants.map((variant, index) => {
                if (payload[index]) {
                  const variantColor = payload[index].stroke
                  return (
                    <div key={variant.name}>
                      <p style={{ color: variantColor }}>
                        <KiteIcon
                          name="record-f"
                          size=".5rem"
                          color={variantColor}
                        />
                        {` ${variant.name}: ${commaSeparateNumber(
                          payload[0].payload[
                            variant.uuid + '-' + allocationMethod
                          ]
                        )}, variant weight: ${
                          payload[0].payload[
                            variant.uuid + '-AllocationPercentage'
                          ]
                        }%`}
                      </p>
                    </div>
                  )
                }
              })}
          </div>
        )
      }
    }
    return null
  }

  const renderLegendText = (value, entry) => {
    let {
      payload: { name },
      color,
    } = entry

    if (name.includes('Allocated')) {
      return (
        <span style={{ color }}>
          <KiteIcon
            className="allocated-devices-icon-legend"
            name="stop-f"
            size="1.5rem"
            color="#003561"
          />
          {value}
        </span>
      )
    } else {
      color = '#0073d1'
      return (
        <span style={{ color }}>
          {' '}
          <KiteIcon
            className="activated-devices-icon-legend"
            name="stop-f"
            size="1.5rem"
            color="#0073d1"
          />
          {value}
        </span>
      )
    }
  }

  const handleActivationEventInfoModal = () => {
    activationEventModal
      ? setActivationEventModal(false)
      : setActivationEventModal(true)
  }

  return (
    <div className="monitor">
      <h3>Monitoring</h3>
      <div className="experiment-info-header">
        <h4>
          <span className="experiment-descriptor">Allocation Method:</span>{' '}
          {allocationMethod}{' '}
          <KiteTooltip
            ariaLabel="Experiment Type"
            ariaId="allocationMethodTooltip"
          >
            {allocationMethodTooltip}
          </KiteTooltip>
        </h4>

        <h4>
          <div className="experiment-descriptor">
            {activationEvent && `Activation Event: `}
            <KiteButton
              type="standalone-link"
              size="small"
              className="activation-event-modal-button"
              onClick={handleActivationEventInfoModal}
            >
              {activationEvent && activationEventName}
            </KiteButton>{' '}
            {
              <KiteModal
                title="Activation Event Details"
                canShow={activationEventModal}
                onHide={handleActivationEventInfoModal}
                showCloseButton={false}
              >
                <p>{activationEventTooltip}</p>
                {aeName !== 'N/A' &&
                  Object.entries(activationEventJson[aeName].properties).map(
                    ([key, value], i) => {
                      return (
                        <li
                          className="activation-event-modal-list"
                          tabIndex={i}
                          key={key}
                        >
                          {key}: {value.valid.toString()}
                        </li>
                      )
                    }
                  )}
              </KiteModal>
            }
          </div>
        </h4>
      </div>
      {prismUrl &&
        prismDeeplinkActive &&
        activationEvent &&
        !spectrumGuide &&
        experimentRunning && (
          <div className="prism-link-out-box">
            <KiteIcon margin="5px" name="chart" size="1rem" color="#0073D1" />
            <p className="prism-link-description">
              Realtime experiment health dashboard:
            </p>
            <div className="prism-link-button">
              <KiteLink type="standalone-link" newTab href={prismUrl}>
                Open in Prism
                <KiteIcon
                  margin="1px"
                  name="arrow-up-right"
                  size="1rem"
                  color="#0073D1"
                />
              </KiteLink>
            </div>
          </div>
        )}

      <div className="bar-chart-title">
        <h4>New {allocationMethod}s in Experiment</h4>
        <div className="learn-page-link">
          <KiteLink newTab href={'/learn/distillery/'} type="standalone">
            What's a normal range for allocated/activated?
            <KiteIcon
              margin="10px 20px 0px 15px"
              name="arrow-up-right"
              size="1rem"
            />
          </KiteLink>
        </div>
        <div className="checkboxes">
          {activationEvent && (
            <div className="activated-devices-checkbox">
              <KiteCheckbox
                id="activatedDevices"
                key="activatedDevices"
                label={`Activated ${allocationMethod}s`}
                checked={viewingActivatedDevices}
                name="activatedDevices"
                disabled={!quantumMetricsDataAvailable}
                onChange={e => {
                  setViewingActivatedDevices(!viewingActivatedDevices)
                }}
              />
              <KiteIcon
                className="activated-devices-icon"
                name="stop-f"
                size="1.5rem"
                color="#0073d1"
              />
            </div>
          )}

          <div className="allocated-devices-checkbox">
            <KiteCheckbox
              disabled={!quantumMetricsDataAvailable}
              id="allocatedDevices"
              key="allocatedDevices"
              label={`Allocated ${allocationMethod}s`}
              checked={viewingAllocatedDevices}
              name="allocatedDevices"
              onChange={e => {
                setViewingAllocatedDevices(!viewingAllocatedDevices)
              }}
            />
            <KiteIcon name="stop-f" size="1.5rem" color="#003561" />
          </div>
        </div>
      </div>
      <div className="bar-chart">
        <div className="loader">{loading && <KiteLoader size="7rem" />}</div>
        <KiteTabs
          currentTab={'Total'}
          tabs={['Total']}
          justify={'flex-start'}
        />
        {!quantumMetricsDataAvailable && graphDataComplete && (
          <div className="no-monitoring-data-container">
            <img
              className="bar-chart-empty"
              src={barChartImage}
              alt="empty bar chart"
            ></img>
            <div className="no-monitoring-data-text">{noMonitoringData}</div>
          </div>
        )}
        {barChartData && quantumMetricsDataAvailable && (
          <BarChart
            width={1200}
            height={400}
            data={barChartData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis
              dataKey="truncatedDate"
              interval={Math.round(barChartData.length / 10)}
            />
            <YAxis />
            <Tooltip content={<CustomTooltip chartType={'bar'} />} />

            <Legend
              formatter={renderLegendText}
              wrapperStyle={{
                position: 'relative',
              }}
              iconSize={0}
            />
            {viewingActivatedDevices && activationEvent && (
              <Bar
                name={`New ${allocationMethod}s Activated`}
                dataKey={formatMissingActivatedData}
              >
                {barChartData.map(entry => (
                  <Cell
                    key={entry.truncatedDate}
                    fill={
                      entry[`new${allocationMethod}sActivated`] === 0
                        ? '#808080'
                        : '#0073d1'
                    }
                  />
                ))}
              </Bar>
            )}
            {viewingAllocatedDevices && (
              <Bar
                name={`New ${allocationMethod}s Allocated`}
                dataKey={formatMissingAllocatedData}
                fill="#003561"
              >
                {barChartData.map(entry => (
                  <Cell
                    key={entry.truncatedDate}
                    fill={
                      entry[`new${allocationMethod}sAllocated`] === 0
                        ? '#808080'
                        : '#003561'
                    }
                  />
                ))}
              </Bar>
            )}
          </BarChart>
        )}
      </div>
      {activationEvent && quantumMetricsDataAvailable && (
        <p className="gray-bar-explainer">
          Days with a bar in gray are missing Quantum data, but we have
          calculated what we expect that value to be based on following days’
          information.
        </p>
      )}
      <h4>Total {allocationMethod}s in Experiment</h4>
      {
        <div className="line-graph">
          <div className="loader">{loading && <KiteLoader size="7rem" />}</div>
          <KiteTabs
            currentTab={activeTabLineGraph}
            tabs={tabs}
            onSelectTab={value => setActiveTabLineGraph(value)}
            justify={'flex-start'}
          />
          {!quantumMetricsDataAvailable && graphDataComplete && (
            <div className="no-monitoring-data-container">
              <img
                className="bar-chart-empty"
                src={lineChartImage}
                alt="empty bar chart"
              ></img>
              <div className="no-monitoring-data-text">{noMonitoringData}</div>
            </div>
          )}
          {lineChartData &&
            activeTabLineGraph === 'By Variant' &&
            quantumMetricsDataAvailable && (
              <LineChart
                className="variants"
                width={1200}
                height={400}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
                data={lineChartData}
              >
                <Tooltip content={<CustomTooltip chartType={'line'} />} />
                <Legend
                  wrapperStyle={{
                    padding: '15px',
                  }}
                />
                {variants.map((variant, index) => (
                  <Line
                    type="monotone"
                    dataKey={`${variant.uuid}-${allocationMethod}`}
                    stroke={variantColors[index]}
                    name={variant.name}
                    dot={false}
                  />
                ))}
                <XAxis
                  dataKey="truncatedDate"
                  interval={Math.round(lineChartData.length / 8)}
                />
                {missingDates.map(missingDate => (
                  <ReferenceArea x1={missingDate.start} x2={missingDate.end} />
                ))}
                <YAxis tick={{ fontSize: 14, width: 250 }} />
              </LineChart>
            )}
          {lineChartData &&
            activeTabLineGraph === 'Total' &&
            quantumMetricsDataAvailable && (
              <LineChart
                className="variants"
                width={1200}
                height={400}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
                data={lineChartData}
              >
                <Tooltip content={<CustomTooltip chartType={'line'} />} />
                <Legend
                  wrapperStyle={{
                    padding: '15px',
                  }}
                />
                <Line
                  type="monotone"
                  dataKey={`totalBy${allocationMethod}`}
                  stroke="#478eeb"
                  name="Total"
                  dot={false}
                />
                <XAxis
                  dataKey="truncatedDate"
                  interval={Math.round(lineChartData.length / 8)}
                  type="category"
                />
                <YAxis tick={{ fontSize: 14, width: 250 }} />
                {missingDates.map(missingDate => (
                  <ReferenceArea x1={missingDate.start} x2={missingDate.end} />
                ))}
              </LineChart>
            )}
        </div>
      }
      {quantumMetricsDataAvailable && missingDates.length > 0 && (
        <p className="gray-bar-explainer">
          Days with a bar in gray are missing Quantum data, but we have
          calculated what we expect that value to be based on following days’
          information.
        </p>
      )}
    </div>
  )
}
export default Monitor
