import React, { Fragment, useState } from 'react'
import { KiteIcon } from '@kite/react-kite'
import { object } from 'prop-types'

import { DialogContainer, CopyButton } from '../../componentLibrary'
import './MetricDefModal.scss'

export default function MetricDefModal({ metricDef }) {
  const [shouldShowModal, setShouldShowModal] = useState(false)
  const {
    displayName,
    name,
    uuid,
    id,
    _id,
    description,
    calculation,
  } = metricDef

  const textToCopy = `name: ${name}\nid: ${
    uuid || id || _id
  }\ndescription: ${description}\ncalculation: ${calculation}\n`

  return (
    <Fragment>
      {shouldShowModal && (
        <DialogContainer>
          <div className="metric-def-modal">
            <div className="metric-def-modal__title-area">
              <div className="metric-def-modal__fake-left" />
              <div className="metric-def-modal__title">
                {displayName || name}
              </div>
              <KiteIcon
                size="14px"
                name="x"
                color="#000"
                onClick={() => setShouldShowModal(false)}
              />
            </div>
            <div className="metric-def-modal__main">
              <CopyButton textToCopy={textToCopy} useIcon />{' '}
              <div className="metric-def-modal__rows">
                <div className="metric-def-modal__row">
                  <div>name:</div>
                  <div>{name}</div>
                </div>
                <div className="metric-def-modal__row">
                  <div>id:</div>
                  <div>{uuid || id || _id}</div>
                </div>
                <div className="metric-def-modal__row">
                  <div>description:</div>
                  <div>{description}</div>
                </div>
                <div className="metric-def-modal__row metric-def-modal__calculation">
                  <div>calculation:</div>
                  <div className="metric-def-modal__calculation">
                    <pre>{calculation}</pre>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContainer>
      )}
      <KiteIcon
        size="1rem"
        name="question-circle"
        color="#000000"
        onClick={() => setShouldShowModal(!shouldShowModal)}
      />
    </Fragment>
  )
}

MetricDefModal.propTypes = {
  metricDef: object.isRequired,
}
