import React, { Component, Fragment } from 'react'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'
import { object, func, string, array, bool } from 'prop-types'
import { KiteButton } from '@kite/react-kite'
import { FilterSearch } from '@kite/react-kite-plus'

import Button from '../Button/Button'
import Checkbox from '../Checkbox/Checkbox'
import ExpansionPanel from '../ExpansionPanel/ExpansionPanel'
import InventoryList from '../InventoryList/InventoryList'
import Select from '../Select/Select'

import './FilterControls.scss'

// Method used to set selected filters to state and adjust as needed locally without making API calls
const getSelectedFiltersInitialState = (filterOptions, userFilters) => {
  // Initial state based on filterOptions prop
  const initialState = Object.keys(filterOptions).reduce(
    (accumulator, option) => {
      if (!accumulator[option]) {
        // Used to determine if the filterOption is an array
        if (filterOptions[option][0]) {
          // Check to see if passed pre-selected filters match this option || set to an empty array
          accumulator[option] = (userFilters && userFilters[option]) || []
          // If the filterOption is not an array, it must be an array of objects
        } else {
          accumulator[option] = Object.keys(filterOptions[option]).map(
            subOption => {
              // Object filters have a 'name' and 'selectedFilters' prop; check for pre-selected matches
              const preSelected =
                userFilters &&
                userFilters[option] &&
                userFilters[option].find(element => element.name === subOption)
              // Return an object with the 'name' and any pre-selected filters || an empty array
              return {
                name: subOption,
                selectedFilters: preSelected ? preSelected.selectedFilters : [],
              }
            }
          )
        }
      }

      return accumulator
    },
    {}
  )

  return initialState
}

const getInitialCurrentlyViewing = filterOptions => {
  const name = Object.keys(filterOptions).sort()[0]
  const type = filterOptions[name][0] ? 'array' : 'object'
  let subType
  let selectedSubSection
  let searchValues

  if (type === 'object') {
    const filterKeys = Object.keys(filterOptions[name]).sort()

    // If there are more than 5 sub filters, render as a 'Select' component, else as and 'ExpansionPanel'
    subType = filterKeys.length > 5 ? 'select' : 'expansion'
    // If the subType is 'select', set the defaultValue to the first filter option
    selectedSubSection = subType === 'select' ? filterKeys[0] : null
  } else if (type === 'array') {
    // If there are more than 10 filter options, a 'FilterSearch' is rendered
    subType = filterOptions[name].length > 10 ? 'search' : null
    // If the subType is search, set a 'searchValue' prop to all of the original values
    searchValues = subType === 'search' ? filterOptions[name] : null
  }

  return {
    name,
    type,
    subType,
    selectedSubSection,
    searchValues,
  }
}

// Initial state prop used for resetting all props except for selectedFilters
const initialState = {
  areControlsOpen: false,
  isDirty: false,
  isLoading: false,
}

class FilterControls extends Component {
  static propTypes = {
    filterOptions: object.isRequired,
    onApplyFilters: func,
    onChange: func,
    onClearError: func,
    selectedFilters: object,
    filterCategory: string,
    user: string,
    userFields: array,
    errorMessage: string,
    className: string,
    hideApplyButton: bool,
  }

  static defaultProps = {
    onApplyFilters: null,
    onChange: null,
    onClearError: null,
    selectedFilters: {},
    filterCategory: null,
    user: null,
    userFields: [],
    errorMessage: null,
    className: null,
    hideApplyButton: false,
  }

  state = {
    ...initialState,
    currentlyViewing: getInitialCurrentlyViewing(this.props.filterOptions),
    // Pass filterOptions and selectedFilters to cross check and set initial state
    selectedFilters: getSelectedFiltersInitialState(
      this.props.filterOptions,
      this.props.selectedFilters
    ),
  }

  // LIFECYCLE METHODS
  componentDidUpdate({
    selectedFilters: prevFilters,
    filterOptions: prevOptions,
  }) {
    const { selectedFilters, filterOptions } = this.props

    // Check to see if filters have been updated in parent component and update local state
    // Useful when another component can alter filters and FilterControls needs to know about it
    if (
      !isEqual(selectedFilters, prevFilters) ||
      !isEqual(filterOptions, prevOptions)
    ) {
      this.handleUpdateFilters()
    }
  }

  // LOCAL STATE CHANGE/TOGGLE METHODS
  handleUpdateFilters = () => {
    const { filterOptions, selectedFilters } = this.props

    this.setState({
      selectedFilters: getSelectedFiltersInitialState(
        filterOptions,
        selectedFilters
      ),
    })
  }

  handleToggleFilterControls = () => {
    const { areControlsOpen } = this.state

    // If the controls are not open, add the outside click event listener
    if (!areControlsOpen) {
      document.addEventListener('click', this.handleFiltersOutsideClick, false)
    }

    this.setState({ areControlsOpen: !areControlsOpen, isDirty: false })
  }

  handleSelectFilterCategory = name => {
    const { filterOptions } = this.props

    // Determine if a filterOption is an array or object -> renders accordingly
    let type

    if (isEmpty(filterOptions[name])) {
      type = 'empty'
    } else if (filterOptions[name][0]) {
      type = 'array'
    } else {
      type = 'object'
    }
    let subType = null
    let selectedSubSection = null
    let searchValues = null

    if (type === 'object') {
      const filterKeys = Object.keys(filterOptions[name]).sort()

      // If there are more than 5 sub filters, render as a 'Select' component, else as and 'ExpansionPanel'
      subType = filterKeys.length > 5 ? 'select' : 'expansion'
      // If the subType is 'select', set the defaultValue to the first filter option
      selectedSubSection = subType === 'select' ? filterKeys[0] : null
    } else if (type === 'array') {
      // If there are more than 10 filter options, a 'FilterSearch' is rendered
      subType = filterOptions[name].length > 10 ? 'search' : null
      // If the subType is search, set a 'searchValue' prop to all of the original values
      searchValues = subType === 'search' ? filterOptions[name] : null
    }

    const currentlyViewing = {
      name,
      type,
      subType,
      selectedSubSection,
      searchValues,
    }

    this.setState({ currentlyViewing })
  }

  handleSelectSubSection = subSection => {
    const { currentlyViewing } = this.state
    const newViewing = cloneDeep(currentlyViewing)

    newViewing.selectedSubSection = subSection

    this.setState({ currentlyViewing: newViewing })
  }

  handleSearchFilters = filteredData => {
    const { currentlyViewing } = this.state

    const newViewing = cloneDeep(currentlyViewing)
    newViewing.searchValues = filteredData

    this.setState({ currentlyViewing: newViewing })
  }

  handleSelectFilter = (isSelected, filterName) => {
    const { onChange } = this.props
    const { filterOptions } = this.props
    const {
      currentlyViewing: { name, type },
      selectedFilters,
    } = this.state
    const newSelectedFilters = cloneDeep(selectedFilters)

    if (type === 'array') {
      // If it's an array and isSelected is true, add the filter
      if (isSelected) {
        newSelectedFilters[name].push(filterName)
        // Else filter it out of the existing selected filters
      } else {
        newSelectedFilters[name] = newSelectedFilters[name].filter(
          selected => selected !== filterName
        )
      }
      // If it's an object, find the matching parent (sub option) and child (selected filter)
    } else if (type === 'object') {
      const filterArray = filterName.split('__')
      const filterParent = filterArray[0]
      const filterChild = filterArray[1]

      // Find the matching sub option object
      const matchingFilters = newSelectedFilters[name].find(
        option => option.name === filterParent
      ).selectedFilters

      let updatedFilters

      if (isSelected) {
        // If isSelect is true and 'all' is the selection, select all possible filter values
        if (filterChild === 'all') {
          updatedFilters = filterOptions[name][filterParent]
        } else {
          // Else add the new incoming filter to the existing selected filters
          updatedFilters = [...matchingFilters, filterChild]
        }
      } else if (!isSelected) {
        // If isSelect is false and 'all' is the selection, set filters to an empty array
        if (filterChild === 'all') {
          updatedFilters = []
        } else {
          // Else filter the incoming filter from the existing selected filters
          updatedFilters = matchingFilters.filter(
            filter => filter !== filterChild
          )
        }
      }

      // Filter out the existing filter...
      newSelectedFilters[name] = newSelectedFilters[name].filter(
        option => option.name !== filterParent
      )
      // Add it back in to avoid data mutation
      newSelectedFilters[name].push({
        name: filterParent,
        selectedFilters: updatedFilters,
      })
    }

    if (onChange) {
      onChange(newSelectedFilters)
    }

    // Mark isDirty true to note that a change to the filters has been made
    this.setState({ selectedFilters: newSelectedFilters, isDirty: true })
  }

  handleClearViewingFilters = () => {
    const { onChange } = this.props
    const {
      currentlyViewing: { name, type },
      selectedFilters,
    } = this.state

    const newSelectedFilters = cloneDeep(selectedFilters)

    // If cleared filters is an array...
    if (type === 'array') {
      // Set that option to an empty array
      newSelectedFilters[name] = []
      // Else if it's an object...
    } else if (type === 'object') {
      // Find that sub option and set it's selectedFilters prop to an empty array
      newSelectedFilters[name] = newSelectedFilters[name].map(
        ({ name: filterName }) => ({
          name: filterName,
          selectedFilters: [],
        })
      )
    }

    if (onChange) {
      onChange(newSelectedFilters)
    }

    // Mark isDirty true to note that a change to the filters has been made
    this.setState({ selectedFilters: newSelectedFilters, isDirty: true })
  }

  handleFiltersOutsideClick = event => {
    // If there is a reference to the filters menu && the click event is not inside the menu
    if (this.filtersMenu && !this.filtersMenu.contains(event.target)) {
      // Remove the outside click event listener and reset changes
      this.handleCancel()
    }
  }

  handleCancel = () => {
    const { filterOptions, selectedFilters, onClearError } = this.props
    // Generated original filters
    const initialSelectedFilters = getSelectedFiltersInitialState(
      filterOptions,
      selectedFilters
    )

    // If there is a reference to the filters menu && the click event is not inside the menu
    if (this.filtersMenu) {
      // Remove the outside click event listener
      this.handleRemoveOutsideClickListener()
    }

    // Optional method to call to parent if present
    if (onClearError) {
      onClearError()
    }

    // Reset state and original filters
    this.setState({
      ...initialState,
      selectedFilters: initialSelectedFilters,
    })
  }

  handleApplyFilters = async () => {
    const { selectedFilters } = this.state
    const { onApplyFilters, onClearError, errorMessage } = this.props

    if (errorMessage && onClearError) {
      onClearError()
    }

    await this.setState({ isLoading: true })

    const wasSuccessful =
      onApplyFilters && (await onApplyFilters(selectedFilters))

    if (wasSuccessful) {
      // Remove the outside click event listener
      if (this.filtersMenu) {
        this.handleRemoveOutsideClickListener()
      }

      // Reset initialState except for newly selected filters
      this.setState({ ...initialState })
    } else {
      // FilterControls remains open and displays an error if application of filters fails
      this.setState({ isLoading: false })
    }
  }

  handleRemoveOutsideClickListener = () => {
    document.removeEventListener('click', this.handleFiltersOutsideClick, false)
  }

  // RENDER METHODS
  renderControls = () => {
    const { filterCategory, errorMessage, hideApplyButton } = this.props
    const {
      currentlyViewing: { type, name },
      selectedFilters,
      isDirty,
      isLoading,
    } = this.state

    // Populates InventoryList component based on filter option and number of selected filters
    const inventoryValues = Object.keys(selectedFilters)
      .sort()
      .reduce((accumulator, filter) => {
        // If the filter is an array of objects, set the count to the total number of selected filters by sub option
        if (typeof selectedFilters[filter][0] === 'object') {
          accumulator[filter] = selectedFilters[filter].reduce(
            (subAccumulator, subFilter) => [
              ...subAccumulator,
              ...subFilter.selectedFilters,
            ],
            []
          )
        } else {
          // Else set the count to the number of selected filters
          accumulator[filter] = selectedFilters[filter]
        }

        return accumulator
      }, {})

    return (
      <div
        className="filter-controls"
        ref={element => {
          this.filtersMenu = element
        }}
      >
        <div className="filter-controls__header">
          <span className="filter-controls__title">
            {filterCategory ? `Filtering ${filterCategory}` : 'Filters'}
          </span>
          <ExpansionPanel isExpanded={errorMessage && true} type="minimal">
            <span className="select__error-message">{errorMessage}</span>
          </ExpansionPanel>
        </div>

        <div className="filter-controls__content">
          <div className="filter-controls__nav">
            <InventoryList
              values={inventoryValues}
              isSelected={name}
              onSelect={this.handleSelectFilterCategory}
            />
          </div>

          <div className="filter-controls__filter-scroll-box">
            <div className="filter-controls__filter-selection">
              <Button
                value="Clear"
                type="link"
                onClick={this.handleClearViewingFilters}
              />
              {type === 'array' && this.renderFilterArray()}
              {type === 'object' && this.renderFilterObject()}
              {type === 'empty' && (
                <div className="filter-controls__empty-state">
                  {`No ${name}s to choose from`}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="filter-controls__footer">
          <Button
            value={hideApplyButton ? 'Close' : 'Cancel'}
            type="link"
            onClick={this.handleCancel}
          />

          {!hideApplyButton && (
            <Button
              value="Apply All"
              disabled={!isDirty}
              onClick={this.handleApplyFilters}
              loading={isLoading}
            />
          )}
        </div>
      </div>
    )
  }

  // Renders an ExpansionPanel or Select component based on number of sub-options
  renderFilterObject = () => {
    const {
      currentlyViewing: { name, subType, selectedSubSection },
    } = this.state
    const { filterOptions } = this.props

    if (subType === 'expansion') {
      return Object.keys(filterOptions[name]).map((option, index) => {
        const key = `${option}__${index}`

        return (
          <ExpansionPanel key={key} title={option} type="light" isExpanded>
            {this.renderFilterObjectChildren(name, option)}
          </ExpansionPanel>
        )
      })
    }
    if (subType === 'select') {
      const options = Object.keys(filterOptions[name])
        .sort()
        .reduce((accumulator, subOption) => {
          accumulator[subOption] = subOption

          return accumulator
        }, {})

      return (
        <Fragment>
          <Select
            label="Category"
            selectedValue={selectedSubSection}
            options={options}
            onSelect={this.handleSelectSubSection}
          />
          {this.renderFilterObjectChildren(name, selectedSubSection)}
        </Fragment>
      )
    }

    // Returns null if no subType exists
    return null
  }

  // Renders Checkbox components for each sub-option filter
  renderFilterObjectChildren = (name, option) => {
    const { filterOptions } = this.props
    const { selectedFilters } = this.state
    const shouldRenderAll = filterOptions[name][option].length > 1
    const allChecked =
      filterOptions[name][option].length ===
      selectedFilters[name].find(element => element.name === option)
        .selectedFilters.length

    return (
      <Fragment>
        {shouldRenderAll && (
          <Checkbox
            label="All"
            type="all"
            identifier={`${option}__all`}
            checked={allChecked}
            onChange={this.handleSelectFilter}
            tight
          />
        )}
        {filterOptions[name][option].map((subOption, index) => {
          const key = `${option}__${index}`
          const isChecked =
            selectedFilters[name]
              .find(element => element.name === option)
              .selectedFilters.find(element => element === subOption) && true

          return (
            <Checkbox
              key={key}
              label={subOption}
              identifier={`${option}__${subOption}`}
              checked={isChecked || false}
              onChange={this.handleSelectFilter}
              tight
            />
          )
        })}
      </Fragment>
    )
  }

  // Renders Checkbox components for each filter of an array option
  renderFilterArray = () => {
    const {
      currentlyViewing: { name, subType, searchValues },
      selectedFilters,
    } = this.state
    const { filterOptions, userFields, user, filterCategory } = this.props
    const arrayValues =
      subType === 'search' ? searchValues : filterOptions[name]

    return (
      <Fragment>
        {subType === 'search' && (
          <FilterSearch
            initialData={filterOptions[name]}
            onChange={this.handleSearchFilters}
          />
        )}
        {arrayValues.map((option, index) => {
          const key = `${option}__${index}`
          const isChecked = !!selectedFilters[name].find(
            filter => filter === option
          )

          // Narrow use case based on passed 'user' and 'userFields' props:
          // Checks against a filter category to see if one of the matching filter options
          // matches a passed user value (ie first name)
          const considerUser =
            user && userFields && userFields.includes(name) && user === option
          let label = option

          // Renders `My <category>` for the Checkbox label; passed value is still the initial filter value
          if (considerUser) {
            label = `My ${filterCategory}`
          }

          // User Checkbox is given a `priority` class that pushes it to the top order
          return (
            <Checkbox
              key={key}
              className={considerUser ? 'priority' : ''}
              label={label}
              identifier={option}
              checked={isChecked}
              onChange={this.handleSelectFilter}
              tight
            />
          )
        })}
      </Fragment>
    )
  }

  render() {
    const { className } = this.props
    const { areControlsOpen } = this.state

    return (
      <div className={`filter__container ${className || ''}`}>
        <KiteButton
          onClick={this.handleToggleFilterControls}
          type="outline"
          leftIcon="filter-f"
          size="small"
          maxWidth="105px"
        >
          Filter
        </KiteButton>
        {areControlsOpen && this.renderControls()}
      </div>
    )
  }
}

export default FilterControls
