import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

const UPDATE_EXPERIMENT_EVALUATION_CARD = gql`
  mutation updateExperimentEvaluationCard(
    $input: UpdateExperimentEvaluationCardValues!
  ) {
    updateExperimentEvaluationCardAndItems(input: $input) {
      id
      experiment {
        id
        uuid
        name
        hypothesis
        summary
        durationNumDays
        finalOutcome
        winnerVariantId
        targetSampleSize
        multipleVariantReason
        experimentStatus {
          id
          name
        }
        experimentMetrics {
          id
          createdUser {
            displayName
          }
          metricTypeId
          metricUuid
          experimentId
        }
        experimentToProductFeatures {
          product {
            id
            displayName
            quantumApplicationName
            quantumApplicationType
          }
          productFeature {
            id
            displayName
          }
        }
        metrics {
          id
          displayName
          isEnabled
          metricTypeId
        }
        variants {
          id
          uuid
          name
          displayOrder
          description
          jsonPayload
        }
        environmentSamplings {
          id
          environment {
            id
          }
          allocationHistory {
            id
            sampleAllocation
            variantConfig
            createdTime
            isReset
            comment
            uuid
            lastModifiedUser {
              id
              displayName
            }
          }
        }
        finalOutcome
        startTime
        stopTime
        experimentToUsers {
          id
          user {
            id
            firstName
            lastName
          }
          roleId
        }
      }
      evaluationStatusId
      experimentQualityScore
      lastModifiedTime
      businessOutcome
      adHocEvaluation
      generalComments
      adHocComments
      portfolioCategoryId
      portfolioCategory {
        id
        displayName
      }
      lastModifiedUser {
        id
        firstName
        lastName
      }
      evaluatedUser {
        id
        firstName
        lastName
      }
      experimentEvaluationItems {
        id
        score
        commentary
        evaluationItem {
          id
          name
          evaluationSubCategory {
            id
            evaluationCategoryId
          }
          displayName
          displayOrder
        }
      }
      experimentEvaluationAudits {
        id
        changedFieldsJson
        changedComment
        createdTime
        createdUser {
          id
          firstName
          lastName
        }
      }
    }
  }
`

const updateExperimentEvaluationCard = graphql(
  UPDATE_EXPERIMENT_EVALUATION_CARD,
  {
    name: 'updateExperimentEvaluationCard',
  }
)

export default updateExperimentEvaluationCard
