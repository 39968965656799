import { gql } from '@apollo/client'

export const GET_CONTENT_MAP_APPROVERS = gql`
  query getContentMap($id: Int!) {
    contentMap(id: $id) {
      id
      contentMapToUser {
        id
        userId
        user {
          id
          displayName
          email
        }
        roleId
      }
    }
  }
`
