/* eslint no-extend-native: ["error", { "exceptions": ["Error"] }] */
if (!('toJSON' in Error.prototype))
  Object.defineProperty(Error.prototype, 'toJSON', {
    value: function () {
      var alt = {}

      Object.getOwnPropertyNames(this).forEach(function (key) {
        alt[key] = this[key]
      }, this)

      return alt
    },
    configurable: true,
    writable: true,
  })

const formatLoggingError = (error, experiment = null) => {
  let input = {
    applicationName: 'Distillery UI',
    logLevel: 'ERROR',
    logMessage: JSON.stringify(error),
  }

  if (experiment) {
    input = {
      ...input,
      experimentId: experiment.id,
      experimentName: experiment.name,
    }
  }

  return input
}

export default formatLoggingError
