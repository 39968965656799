import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

const UPDATE_EXPERIMENT_VARIANTS = gql`
  mutation updateExperimentVariants($input: ExperimentToVariantUpdate!) {
    updateExperimentVariants(input: $input) {
      id
      config
      multipleVariantReason
      variants {
        id
        uuid
        name
        description
        url
        jsonPayload
        displayOrder
        variantUploads {
          id
          fileUrl
        }
      }
      environmentSamplings {
        id
        tdcsUpdateRequired
        isDreSynced
      }
    }
  }
`

const updateExperimentVariants = graphql(UPDATE_EXPERIMENT_VARIANTS, {
  name: 'updateExperimentVariants',
  withRef: true,
})

export default updateExperimentVariants
