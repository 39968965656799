import { gql } from '@apollo/client'

const GET_QUANTUM_METRIC_DEFINITIONS = gql`
  {
    metricDefinitions {
      _id
      name
      description
      calculation
      displayName
    }
  }
`

export default GET_QUANTUM_METRIC_DEFINITIONS
