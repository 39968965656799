const getCurrentDate = () => {
  const currentDate = new Date()
  const date = currentDate.toLocaleDateString().split('/')
  const dateString =
    date[2] + '-' + date[0].padStart(2, 0) + '-' + date[1].padStart(2, 0)
  return dateString
}

const getCurrentTime = () => {
  const currentDate = new Date()
  return currentDate
    .toLocaleTimeString('en-US', {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
    })
    .split(' ')[0]
}

export { getCurrentTime, getCurrentDate }
