import { gql } from '@apollo/client'

/*
* Sample Input
* [
    {contentMapId: 1, userID: 1, roleID: 4},
    {contentMapId: 1, userID: 2, roleID: 3}
  ]
*/
const INSERT_CONTENT_MAP_TO_USER = gql`
  mutation insertContentMapToUser($input: ContentMapToUserInput!) {
    insertContentMapToUser(input: $input) {
      id
      userId
      contentMapId
      user {
        id
        displayName
      }
      roleId
    }
  }
`

export default INSERT_CONTENT_MAP_TO_USER
