import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

const DELETE_EXPERIMENT_VARIANT = gql`
  mutation deleteExperimentVariant($input: ExperimentVariantDelete!) {
    deleteExperimentVariant(input: $input)
  }
`

const deleteExperimentVariant = graphql(DELETE_EXPERIMENT_VARIANT, {
  name: 'deleteExperimentVariant',
  withRef: true,
})

export default deleteExperimentVariant
