import {gql} from "@apollo/client";

export const GET_TDCS_PUBLISH_HISTORY = gql`
query publishHistoryForId($pid: Int!) {
    publishHistoryForId(pid: $pid) {
      id
      tdcsConfig
    }
}`

const getTdcsPublishHistory = {
  query: `
    publishHistoryForId(id: $pid) {
      id
      tdcsConfig
    }`,
  variables: {
    $id: 'Int!',
  },
  options: ({ pid, selectedEnvironment }) => ({
    variables: {
      pid: pid,
    },
  }),
}

export default getTdcsPublishHistory
