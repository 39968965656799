import React, { Component, Fragment } from 'react'
import { object, func } from 'prop-types'
import flowRight from 'lodash/flowRight'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import differenceBy from 'lodash/differenceBy'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import { experimentEnvironmentEnum } from '@charter/distillery-rules'

import {
  KiteButton,
  KiteRadio,
  KiteCheckbox,
  KiteLoader,
  KiteTooltip,
  KiteAlert,
} from '@kite/react-kite'

import { client } from '../../configuration/configApiClient'
import {
  getProducts,
  getEnvironments,
  GET_CURRENT_USER,
} from '../../shared/queries'
import { LOG_ERROR } from '../../shared/mutations'
import {
  formatLoggingError,
  removeTypename,
  scrollToNavigationPosition,
} from '../../shared/utilities'
import {
  getNotificationDefinitions,
  getNotificationPreferencesByEnvironment,
  GET_NOTIFICATION_PREFERENCES_BY_ENVIRONMENT,
} from './queries'
import {
  insertNotificationPreferences,
  updateNotificationPreferences,
  deleteNotificationPreferences,
} from './mutations'
import copyContent from './data/copyContent'
import { ColoredSelect, Modal, ExpansionPanel } from '../../componentLibrary'
import './NotificationPreferences.scss'

const { PRODUCTION } = experimentEnvironmentEnum

export const initialSelectedEvents = [
  {
    notificationDefinitionId: 2,
    label: copyContent.events.experimentChange,
    emailOn: false,
    inAppOn: false,
    smsOn: false,
  },
  {
    notificationDefinitionId: 5,
    label: copyContent.events.allocationChange,
    emailOn: false,
    inAppOn: false,
    smsOn: false,
  },
]

export class NotificationPreferences extends Component {
  static propTypes = {
    user: object.isRequired,
    onNavigate: func.isRequired,
    productsData: object.isRequired,
    environmentsData: object.isRequired,
    notificationDefinitionsData: object.isRequired,
    notificationPreferencesByEnvironmentData: object.isRequired,
    insertNotificationPreferences: func.isRequired,
    updateNotificationPreferences: func.isRequired,
    deleteNotificationPreferences: func.isRequired,
  }

  state = {
    selectedEnvironment: null,
    selectedProducts: [],
    selectedEvents: [],
    isDirty: false,
    isValid: false,
    isCancelModalOpen: false,
    wasSuccessful: null,
    loading: {
      environment: false,
      submission: false,
    },
    errorMessage: {
      getPreferences: null,
      updatePreferences: null,
    },
  }

  // LIFECYCLE METHODS
  componentDidMount() {
    const {
      notificationPreferencesByEnvironmentData,
      environmentsData,
      onNavigate,
    } = this.props

    onNavigate(
      {
        path: '/notifications/preferences',
        title: 'Notification Preferences',
      },
      true
    )

    if (environmentsData.environments) {
      this.handleSetInitialEnvironment()
    }

    if (
      notificationPreferencesByEnvironmentData.notificationPreferencesByEnvironment
    ) {
      this.handleSetPreferences()
    }
  }

  componentDidUpdate(prevProps) {
    const {
      notificationPreferencesByEnvironmentData,
      environmentsData,
    } = this.props
    const {
      notificationPreferencesByEnvironmentData: prevPreferencesData,
      environmentsData: prevEnvironmentsData,
    } = prevProps

    if (!prevEnvironmentsData.environments && environmentsData.environments) {
      this.handleSetInitialEnvironment()
    }

    if (
      prevPreferencesData &&
      notificationPreferencesByEnvironmentData &&
      !isEqual(
        prevPreferencesData.notificationPreferencesByEnvironment,
        notificationPreferencesByEnvironmentData.notificationPreferencesByEnvironment
      )
    ) {
      this.handleSetPreferences()
    }
  }

  // LOCAL STATE CHANGE/TOGGLE METHODS
  handleSetInitialEnvironment = () => {
    const { environmentsData } = this.props

    const selectedEnvironment = environmentsData.environments.find(
      environment => environment.id === PRODUCTION
    )

    this.setState({
      selectedEnvironment: {
        ...selectedEnvironment,
        name: selectedEnvironment.name.toLowerCase(),
      },
    })
  }

  handleSetPreferences = () => {
    const {
      notificationPreferencesByEnvironmentData: {
        notificationPreferencesByEnvironment,
      },
    } = this.props

    const preselectedEvents = initialSelectedEvents.map(event => {
      const match = notificationPreferencesByEnvironment.find(
        preference =>
          preference.notificationDefinition.id ===
          event.notificationDefinitionId
      )

      if (match) {
        const { inAppOn, emailOn, smsOn } = match

        return {
          ...event,
          inAppOn,
          emailOn,
          smsOn,
        }
      }

      return event
    })

    const selectedProducts = notificationPreferencesByEnvironment.reduce(
      (accumulator, preference) => {
        const alreadyPlaced =
          accumulator.length > 0 &&
          accumulator.find(placed => placed.productId === preference.productId)

        if (!alreadyPlaced) {
          const { productId, onlyMine } = preference
          return [...accumulator, { productId, onlyMine }]
        }

        return accumulator
      },
      []
    )

    this.setState({ selectedEvents: preselectedEvents, selectedProducts })
  }

  handleChangeProductNotification = async ({ target: { name, value } }) => {
    const { selectedProducts } = this.state
    const productId = Number(name)

    if (value === 'None') {
      await this.handleRemoveSelectedProduct(productId)
    } else {
      const onlyMine = value === 'My Experiments' || false
      const match = selectedProducts.find(
        product => product.productId === productId
      )

      await this.setState(prevState => ({
        isDirty: true,
        selectedProducts: match
          ? prevState.selectedProducts.map(product =>
              product.productId === productId
                ? { productId, onlyMine }
                : product
            )
          : [
              ...prevState.selectedProducts,
              {
                productId,
                onlyMine,
              },
            ],
      }))
    }

    this.handleValidateSubmission()
  }

  handleRemoveSelectedProduct = productId => {
    this.setState(({ selectedProducts }) => ({
      isDirty: true,
      selectedProducts: selectedProducts.filter(
        product => product.productId !== productId
      ),
    }))
  }

  handleChangeEventNotification = async ({ target: { name, checked } }) => {
    const eventValues = name.split('__')
    const eventId = Number(eventValues[0])
    const eventType = eventValues[1]

    await this.setState(({ selectedEvents }) => ({
      isDirty: true,
      selectedEvents: selectedEvents.map(event => {
        if (event.notificationDefinitionId === eventId) {
          return {
            ...event,
            [eventType]: checked,
          }
        }

        return event
      }),
    }))

    this.handleValidateSubmission()
  }

  handleCheckLoseChanges = () => {
    this.setState({ isCancelModalOpen: true })
  }

  handleConfirmLoseChanges = async () => {
    await this.handleSetPreferences()
    this.setState({ isCancelModalOpen: false, isValid: false })
  }

  handleCancelLoseChanges = () => {
    this.setState({ isCancelModalOpen: false })
  }

  handleClearAllPreferences = async () => {
    await this.setState({
      selectedProducts: [],
      selectedEvents: initialSelectedEvents,
    })

    this.handleValidateSubmission()
  }

  // FORMATTING METHODS
  handleFormatPreferencesInputs = () => {
    const preferencesToInsert = this.handleFormatPreferencesToInsert()
    const preferencesToUpdate = this.handleFormatPreferencesToUpdate()
    const preferencesToDelete = this.handleFormatPreferencesToDelete()

    return { preferencesToInsert, preferencesToUpdate, preferencesToDelete }
  }

  handleFormatPreferencesToInsert = () => {
    const {
      selectedProducts,
      selectedEvents,
      selectedEnvironment: { id: environmentId },
    } = this.state
    const {
      notificationPreferencesByEnvironmentData: {
        notificationPreferencesByEnvironment,
      },
    } = this.props

    const filteredEvents = selectedEvents
      .filter(
        selected => selected.emailOn || selected.inAppOn || selected.smsOn
      )
      .reduce((newEvents, filtered) => {
        if (filtered.notificationDefinitionId === 2) {
          return [
            ...newEvents,
            filtered,
            {
              ...filtered,
              notificationDefinitionId: 3,
            },
            {
              ...filtered,
              notificationDefinitionId: 4,
            },
          ]
        }

        return [...newEvents, filtered]
      }, [])

    const productsToInsertByEvent = filteredEvents.reduce(
      (accumulator, event) => {
        const match = notificationPreferencesByEnvironment.find(
          preference =>
            preference.notificationDefinition.id ===
            event.notificationDefinitionId
        )

        if (!match) {
          const newInsertions = notificationPreferencesByEnvironment
            .map(preference =>
              this.handleFormatPreferenceObject(
                environmentId,
                preference,
                event
              )
            )
            .reduce(
              (unique, item) =>
                unique.some(
                  individual =>
                    individual.notificationDefinitionId ===
                      item.notificationDefinitionId &&
                    individual.productId === item.productId
                )
                  ? unique
                  : [...unique, item],
              []
            )

          return [...accumulator, ...newInsertions]
        }

        return accumulator
      },
      []
    )

    const productToInsertByProduct = selectedProducts.reduce(
      (accumulator, product) => {
        const match = notificationPreferencesByEnvironment.find(
          preference => preference.productId === product.productId
        )

        if (!match) {
          const insertByEventType = filteredEvents.map(event =>
            this.handleFormatPreferenceObject(environmentId, product, event)
          )

          return [...accumulator, ...insertByEventType]
        }

        return accumulator
      },
      []
    )

    const productsToInsert = [
      ...productsToInsertByEvent,
      ...productToInsertByProduct,
    ]

    return productsToInsert
  }

  handleFormatPreferencesToUpdate = () => {
    const { selectedProducts, selectedEvents } = this.state
    const {
      notificationPreferencesByEnvironmentData: {
        notificationPreferencesByEnvironment,
      },
    } = this.props

    const preferencesToUpdateByEvent = selectedEvents.reduce(
      (accumulator, event) => {
        const isNotDeleting = event.emailOn || event.inAppOn || event.smsOn
        const match = notificationPreferencesByEnvironment.find(
          preference =>
            preference.notificationDefinition.id ===
            event.notificationDefinitionId
        )

        if (
          isNotDeleting &&
          match &&
          (event.emailOn !== match.emailOn ||
            event.inAppOn !== match.inAppOn ||
            event.smsOn !== match.smsOn)
        ) {
          const updatedEvents = notificationPreferencesByEnvironment
            .filter(preference =>
              event.notificationDefinitionId === 2
                ? preference.notificationDefinition.id ===
                    event.notificationDefinitionId ||
                  preference.notificationDefinition.id === 3 ||
                  preference.notificationDefinition.id === 4
                : preference.notificationDefinition.id ===
                  event.notificationDefinitionId
            )
            .map(preference => ({
              ...preference,
              emailOn: event.emailOn,
              inAppOn: event.inAppOn,
              smsOn: event.smsOn,
            }))

          return [...accumulator, ...updatedEvents]
        }

        return accumulator
      },
      []
    )

    const preferencesToUpdateByProduct = selectedProducts.reduce(
      (accumulator, product) => {
        const match = notificationPreferencesByEnvironment.find(
          preference => preference.productId === product.productId
        )

        if (match && match.onlyMine !== product.onlyMine) {
          const updatedEvents = notificationPreferencesByEnvironment
            .filter(preference => preference.productId === product.productId)
            .map(preference => ({
              ...preference,
              onlyMine: product.onlyMine,
            }))

          return [...accumulator, ...updatedEvents]
        }

        return accumulator
      },
      []
    )

    const preferencesToUpdate = removeTypename(
      uniqBy(
        [...preferencesToUpdateByEvent, ...preferencesToUpdateByProduct],
        'id'
      ).map(({ id, userId, inAppOn, emailOn, smsOn, onlyMine }) => ({
        notificationId: id,
        userId,
        inAppOn,
        emailOn,
        smsOn,
        onlyMine,
      }))
    )

    return preferencesToUpdate
  }

  handleFormatPreferencesToDelete = () => {
    const { selectedProducts, selectedEvents } = this.state
    const {
      notificationPreferencesByEnvironmentData: {
        notificationPreferencesByEnvironment,
      },
    } = this.props

    const preferencesToDeleteByEvent = selectedEvents
      .filter(event => !event.emailOn && !event.inAppOn && !event.smsOn)
      .reduce((accumulator, event) => {
        const deletedPreferences = notificationPreferencesByEnvironment
          .filter(preference =>
            event.notificationDefinitionId === 2
              ? preference.notificationDefinition.id ===
                  event.notificationDefinitionId ||
                preference.notificationDefinition.id === 3 ||
                preference.notificationDefinition.id === 4
              : preference.notificationDefinition.id ===
                event.notificationDefinitionId
          )
          .map(preference => preference.id)

        return [...accumulator, ...deletedPreferences]
      }, [])

    const preferencesToDeleteByProduct = differenceBy(
      notificationPreferencesByEnvironment,
      selectedProducts,
      'productId'
    ).map(toDelete => toDelete.id)

    const preferencesToDelete = uniq([
      ...preferencesToDeleteByEvent,
      ...preferencesToDeleteByProduct,
    ])

    return preferencesToDelete
  }

  handleFormatPreferenceObject = (
    environmentId,
    { productId, onlyMine },
    { notificationDefinitionId, emailOn, inAppOn, smsOn }
  ) => {
    const { user } = this.props

    return {
      userId: user.id,
      environmentId,
      productId,
      notificationDefinitionId,
      emailOn,
      inAppOn,
      smsOn,
      onlyMine,
    }
  }

  // API METHODS
  handleSelectEnvironment = async selectedEnvironment => {
    const { errorMessage } = this.state
    const {
      user: { id: userId },
      notificationPreferencesByEnvironmentData: { refetch },
    } = this.props
    const { errorGetPreferencesEnvironment } = copyContent

    if (errorMessage.getPreferences) {
      await this.setState({
        errorMessage: { ...errorMessage, getPreferences: null },
      })
    }

    await this.setState(({ loading }) => ({
      loading: { ...loading, environment: true },
    }))

    try {
      const input = { userId, environmentId: selectedEnvironment.id }

      await refetch({ input })

      await setTimeout(
        () =>
          this.setState(({ loading }) => ({
            selectedEnvironment,
            loading: { ...loading, environment: false },
            isValid: false,
            isDirty: false,
          })),
        200
      )
    } catch (error) {
      const input = formatLoggingError(error)

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input,
        },
      })

      await this.setState(prevState => ({
        errorMessage: {
          ...prevState.errorMessage,
          getPreferences: errorGetPreferencesEnvironment(
            selectedEnvironment.name
          ),
        },
        loading: { ...prevState.loading, environment: false },
      }))

      scrollToNavigationPosition()
    }
  }

  handleSubmit = async () => {
    const {
      errorMessage,
      selectedEnvironment: { id: environmentId, label },
    } = this.state
    const {
      user: { id: userId },
      insertNotificationPreferences: insertPreferences,
      updateNotificationPreferences: updatePreferences,
      deleteNotificationPreferences: deletePreferences,
    } = this.props
    const { errorUpdatePreferencesEnvironment } = copyContent

    const {
      preferencesToInsert,
      preferencesToUpdate,
      preferencesToDelete,
    } = this.handleFormatPreferencesInputs()

    if (errorMessage.updatePreferences) {
      this.setState({
        errorMessage: {
          ...errorMessage,

          updatePreferences: null,
        },
      })
    }

    await this.setState(({ loading }) => ({
      loading: { ...loading, submission: true },
    }))

    if (preferencesToInsert.length > 0) {
      try {
        await insertPreferences({
          variables: {
            input: preferencesToInsert,
          },
          update: (
            proxy,
            { data: { insertNotificationPreferences: newPreferences } }
          ) => {
            const userData = cloneDeep(
              proxy.readQuery({
                query: GET_CURRENT_USER,
              })
            )

            const data = cloneDeep(
              proxy.readQuery({
                query: GET_NOTIFICATION_PREFERENCES_BY_ENVIRONMENT,
                variables: {
                  input: {
                    userId,
                    environmentId,
                  },
                },
              })
            )

            const stillHasInAppPreferences = !!newPreferences.find(
              preference => preference.inAppOn
            )

            data.notificationPreferencesByEnvironment = newPreferences
            userData.hasInAppPreferences = stillHasInAppPreferences

            proxy.writeQuery({
              query: GET_NOTIFICATION_PREFERENCES_BY_ENVIRONMENT,
              variables: {
                input: {
                  userId,
                  environmentId,
                },
              },
              data,
            })

            proxy.writeQuery({
              query: GET_CURRENT_USER,
              data: userData,
            })
          },
        })

        await this.setState(loading => ({
          loading: { ...loading, submission: false },
          wasSuccessful: true,
          isDirty: false,
          isValid: false,
        }))
      } catch (error) {
        const input = formatLoggingError(error)

        await client.mutate({
          mutation: LOG_ERROR,
          variables: {
            input,
          },
        })

        this.setState(prevState => ({
          errorMessage: {
            ...prevState.errorMessage,
            updatePreferences: errorUpdatePreferencesEnvironment(label),
          },
        }))
      }
    }

    if (preferencesToUpdate.length > 0) {
      try {
        await updatePreferences({
          variables: {
            input: preferencesToUpdate,
          },
        })

        await this.setState(loading => ({
          loading: { ...loading, submission: false },
          wasSuccessful: true,
          isDirty: false,
          isValid: false,
        }))
      } catch (error) {
        const input = formatLoggingError(error)

        await client.mutate({
          mutation: LOG_ERROR,
          variables: {
            input,
          },
        })

        this.setState(prevState => ({
          errorMessage: {
            ...prevState.errorMessage,
            updatePreferences: errorUpdatePreferencesEnvironment(label),
          },
        }))
      }
    }

    if (preferencesToDelete.length > 0) {
      try {
        await deletePreferences({
          variables: {
            input: {
              userId,
              notificationIds: preferencesToDelete,
            },
          },
          update: (
            proxy,
            { data: { deleteNotificationPreferences: deletedPreferences } }
          ) => {
            const userData = cloneDeep(
              proxy.readQuery({
                query: GET_CURRENT_USER,
              })
            )

            const data = cloneDeep(
              proxy.readQuery({
                query: GET_NOTIFICATION_PREFERENCES_BY_ENVIRONMENT,
                variables: {
                  input: {
                    userId,
                    environmentId,
                  },
                },
              })
            )

            const newPreferences = data.notificationPreferencesByEnvironment.filter(
              preference => {
                const match = deletedPreferences.find(
                  id => id === preference.id
                )

                if (match) {
                  return false
                }

                return true
              }
            )

            const stillHasInAppPreferences = !!newPreferences.find(
              preference => preference.inAppOn
            )

            data.notificationPreferencesByEnvironment = newPreferences
            userData.hasInAppPreferences = stillHasInAppPreferences

            proxy.writeQuery({
              query: GET_NOTIFICATION_PREFERENCES_BY_ENVIRONMENT,
              variables: {
                input: {
                  userId,
                  environmentId,
                },
              },
              data,
            })

            proxy.writeQuery({
              query: GET_CURRENT_USER,
              data: userData,
            })
          },
        })

        await this.setState(loading => ({
          loading: { ...loading, submission: false },
          wasSuccessful: true,
          isDirty: false,
          isValid: false,
        }))
      } catch (error) {
        const input = formatLoggingError(error)

        await client.mutate({
          mutation: LOG_ERROR,
          variables: {
            input,
          },
        })

        this.setState(prevState => ({
          errorMessage: {
            ...prevState.errorMessage,
            updatePreferences: errorUpdatePreferencesEnvironment(label),
          },
        }))
      }
    }

    scrollToNavigationPosition()
    setTimeout(() => this.setState({ wasSuccessful: null }), 5000)
  }

  // VALIDATION METHODS
  handleValidateSubmission = () => {
    const { selectedEvents, selectedProducts } = this.state
    const {
      notificationPreferencesByEnvironmentData: {
        notificationPreferencesByEnvironment,
      },
    } = this.props
    let isValid = false

    const typeSelected = selectedEvents.find(
      ({ inAppOn, emailOn, smsOn }) => inAppOn || emailOn || smsOn
    )

    // If preferences exist, but are all being removed
    // OR no preferences and a product AND type are selected
    if (
      (notificationPreferencesByEnvironment.length > 0 &&
        selectedProducts.length === 0 &&
        !typeSelected) ||
      (selectedProducts.length > 0 && typeSelected)
    ) {
      isValid = true
    }

    this.setState({ isValid })
  }

  // RENDER METHODS
  renderNotificationSelections = () => {
    const { selectedEnvironment, isValid, isDirty, loading } = this.state
    const {
      notificationPreferencesByEnvironmentData: {
        notificationPreferencesByEnvironment,
      },
      productsData: { productCategoryTypes },
    } = this.props
    const { submissionTooltip, platformHeading, eventHeading } = copyContent

    return (
      <Fragment>
        {selectedEnvironment && (
          <div
            className="notification-preferences__accent-color"
            style={{
              backgroundColor: `${selectedEnvironment.color}`,
            }}
          />
        )}
        <div className="notification-preferences__selections">
          {loading.environment && (
            <div className="notification-preferences__environment-loader-container">
              <KiteLoader size="7rem" />
            </div>
          )}

          {notificationPreferencesByEnvironment.length > 0 && (
            <KiteButton
              className="notification-preferences__clear-all"
              type="standalone-link"
              size="small"
              onClick={this.handleClearAllPreferences}
            >
              Clear All
            </KiteButton>
          )}

          <div className="notification-preferences__platform">
            <div className="notification-preferences__heading-wrapper">
              <h4 className="notification-preferences__selection-heading">
                {platformHeading}
              </h4>

              <KiteTooltip
                ariaLabel="Submission Requirements"
                ariaId="submission"
              >
                {submissionTooltip}
              </KiteTooltip>
            </div>

            {productCategoryTypes
              .slice()
              .sort((aType, bType) => (aType.name > bType.name ? 1 : -1))
              .map(productType => (
                  <Fragment key={productType.id}>
                    <h5 className="notification-preferences__platform-type">
                      {productType.displayName}
                    </h5>

                    <div className="notification-preferences__option-container">
                      {this.renderProductOptions(productType)}
                    </div>
                  </Fragment>
              ))}
          </div>

          <div className="notification-preferences__event">
            <h4 className="notification-preferences__selection-heading">
              {eventHeading}
            </h4>

            <div className="notification-preferences__option-container">
              {selectedEnvironment && this.renderEventOptions()}
            </div>
          </div>

          <div className="notification-preferences__controls">
            <KiteButton
              className="notification-preferences__save-button"
              disabled={loading.submission || !isValid}
              loading={loading.submission}
              onClick={this.handleSubmit}
            >
              Save
            </KiteButton>

            <KiteButton
              className="notification-preferences__cancel-button"
              type="standalone-link"
              disabled={!isDirty}
              onClick={this.handleCheckLoseChanges}
            >
              Reset
            </KiteButton>
          </div>
        </div>
      </Fragment>
    )
  }

  renderProductOptions = type => {
    const { selectedProducts } = this.state
    const platformOptions = ['None', 'All', 'My Experiments']

    return type.products
      .slice()
      .sort((aProduct, bProduct) =>
        aProduct.displayName.toLowerCase() > bProduct.displayName.toLowerCase()
          ? 1
          : -1
      )
      .map(({ displayName, id }) => {
        const isSelected = selectedProducts.find(
          product => product.productId === id
        )
        let notificationType

        if (isSelected) {
          notificationType =
            isSelected && isSelected.onlyMine ? 'My Experiments' : 'All'
        } else {
          notificationType = 'None'
        }

        return (
          <span
            key={`product__${id}`}
            className="notification-preferences__option"
          >
            <span className="notification-preferences__option-title">
              {displayName}
            </span>

            <KiteRadio
              name={`${id}`}
              buttonOrientation="row"
              onChange={this.handleChangeProductNotification}
              radioButtons={platformOptions.map(option => ({
                id: `${id}__${option}`,
                value: option,
                label: option,
                checked: option === notificationType,
              }))}
            />
          </span>
        )
      })
  }

  renderEventOptions = () => {
    const { selectedEvents, selectedEnvironment } = this.state
    const {
      notificationDefinitionsData: { notificationDefinitions },
    } = this.props

    return selectedEvents
      .filter(
        event =>
          !(
            event.notificationDefinitionId === 1 &&
            selectedEnvironment.id !== PRODUCTION
          )
      )
      .map(({ notificationDefinitionId, label, inAppOn, emailOn, smsOn }) => {
        const {
          emailAvailable,
          inAppAvailable,
          smsAvailable,
        } = notificationDefinitions.find(
          definition => definition.id === notificationDefinitionId
        )

        return (
          <span
            key={`event__${notificationDefinitionId}`}
            className="notification-preferences__option"
          >
            <span className="notification-preferences__option-title">
              {label}
            </span>

            <span className="notification-preferences__option-checkbox-container">
              {emailAvailable && (
                <KiteCheckbox
                  id={`${notificationDefinitionId}__emailOn`}
                  name={`${notificationDefinitionId}__emailOn`}
                  label="Email"
                  checked={emailOn}
                  onChange={this.handleChangeEventNotification}
                />
              )}

              {inAppAvailable && (
                <KiteCheckbox
                  id={`${notificationDefinitionId}__inAppOn`}
                  name={`${notificationDefinitionId}__inAppOn`}
                  label="In App"
                  checked={inAppOn}
                  onChange={this.handleChangeEventNotification}
                />
              )}

              {smsAvailable && (
                <KiteCheckbox
                  id={`${notificationDefinitionId}__smsOn`}
                  name={`${notificationDefinitionId}__smsOn`}
                  label="SMS"
                  checked={smsOn}
                  onChange={this.handleChangeEventNotification}
                />
              )}
            </span>
          </span>
        )
      })
  }

  render() {
    const {
      selectedEnvironment,
      isCancelModalOpen,
      errorMessage,
      loading,
      wasSuccessful,
    } = this.state
    const {
      productsData: { loading: productsLoading },
      environmentsData: { loading: environmentsLoading, environments },
      notificationDefinitionsData: { loading: definitionsLoading },
      notificationPreferencesByEnvironmentData: { loading: preferencesLoading },
    } = this.props
    const {
      environmentLabel,
      confirmCancelMessage,
      confirmCancelSubMessage,
      successSavePreferencesTitle,
      successSavePreferencesDescription,
    } = copyContent

    if (
      !loading.environment &&
      (productsLoading ||
        environmentsLoading ||
        definitionsLoading ||
        preferencesLoading)
    ) {
      return (
        <div className="app__loader">
          <KiteLoader size="7rem" />
        </div>
      )
    }

    const filteredEnvironments = environments
      .slice()
      .sort((aEnv, bEnv) => (aEnv.order > bEnv.order ? 1 : -1))
      .map(env => ({ ...env, name: env.name.toLowerCase() }))

    return (
      <div className="notification-preferences">
        <ExpansionPanel
          type="minimal"
          isExpanded={!!errorMessage.updatePreferences}
        >
          <KiteAlert
            className="app__page-level-message"
            type="alert"
            description={errorMessage.updatePreferences}
            onClose={() =>
              this.setState(prevState => ({
                errorMessage: {
                  ...prevState.errorMessage,
                  updatePreferences: null,
                },
              }))
            }
          />
        </ExpansionPanel>

        <ExpansionPanel
          type="minimal"
          isExpanded={!!errorMessage.getPreferences}
        >
          <KiteAlert
            className="app__page-level-message"
            type="alert"
            description={errorMessage.getPreferences}
            onClose={() =>
              this.setState(prevState => ({
                errorMessage: {
                  ...prevState.errorMessage,
                  getPreferences: null,
                },
              }))
            }
          />
        </ExpansionPanel>

        <ExpansionPanel type="minimal" isExpanded={wasSuccessful}>
          <KiteAlert
            className="app__page-level-message"
            type="confirm"
            title={successSavePreferencesTitle}
            description={successSavePreferencesDescription(
              selectedEnvironment && selectedEnvironment.name
            )}
          />
        </ExpansionPanel>

        <div className="notification-preferences__environment-select-container">
          <div className="notification-preferences__environment-wrapper">
            <h5 className="notification-preferences__environment-label">
              {environmentLabel}
            </h5>

            {selectedEnvironment && (
              <ColoredSelect
                className="notification-preferences__environment-select"
                value={selectedEnvironment.name}
                options={filteredEnvironments}
                onSelect={this.handleSelectEnvironment}
              />
            )}
          </div>
        </div>

        {this.renderNotificationSelections()}

        {isCancelModalOpen && (
          <Modal
            message={confirmCancelMessage}
            subMessage={confirmCancelSubMessage}
            onConfirm={this.handleConfirmLoseChanges}
            onDeny={this.handleCancelLoseChanges}
            buttonProps={{ confirm: { value: 'Cancel Changes ' } }}
          />
        )}
      </div>
    )
  }
}

export default flowRight(
  getProducts,
  getEnvironments,
  getNotificationDefinitions,
  getNotificationPreferencesByEnvironment,
  insertNotificationPreferences,
  updateNotificationPreferences,
  deleteNotificationPreferences
)(NotificationPreferences)
