import React, { Component, Fragment } from 'react'
import { func, object, bool, array, number } from 'prop-types'
import flowRight from 'lodash/flowRight'
import { gql } from '@apollo/client'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import {
  KiteLoader,
  KiteInput,
  KiteRadio,
  KiteCard,
  KiteCheckbox,
  KiteSwitch,
  KiteIcon,
  KiteLink,
} from '@kite/react-kite'
import { LabeledSwitch } from '@kite/react-kite-plus'
import {
  isFeatureFlagActive,
  featureFlagEnum,
  hasPermission,
  permissionEnum,
  checkTechSettings,
  experimentTypeEnum,
  experimentStatusEnum,
} from '@charter/distillery-rules'

import { StackedRadio } from '../../../../componentLibrary'
import { client } from '../../../../configuration/configApiClient'
import {
  LOG_ERROR,
  APPROVE_TECH_SETTINGS,
  SET_NEEDS_TECH_APPROVAL,
} from '../../../../shared/mutations'
import { formatLoggingError } from '../../../../shared/utilities'
import { getTdcsSettings } from './queries'
import updateTdcsSettings from './mutations/updateTdcsSettings'
import OldTechSettings from './OldTechSettings'

import copyContent from '../../data/copyContent'
import './TechSettings.scss'

const {
  EXPERIMENT_UPDATE_VERSION_RUNNING,
  TECH_UPDATE,
  EXPERIMENT_UPDATE_RUNNING,
  EXPERIMENT_TOGGLE_EXCLUSIVITY,
} = permissionEnum
const { RUNNING } = experimentStatusEnum
const { CANARY } = experimentTypeEnum
const { DYNAMIC_TECH_SETTINGS, EXCLUSIVE_EXPERIMENTS } = featureFlagEnum

const initialErrors = {
  productMinVersionRequired: null,
  productMinVersionFormatError: null,
  productMaxVersionRequired: null,
  productMaxVersionFormatError: null,
  minAvPlatformVersionRequired: null,
  maxAvPlatformVersionRequired: null,
  updateExperimentTechSettingsError: null,
  approveTechSettings: null,
}

function filterDisplayName(filter) {
  const isOld = filter.tdcsFilter !== undefined
  const displayName = !isOld
    ? filter.tdcsSessionTypeToProduct.tdcsFilter.displayName
    : filter.tdcsFilter.displayName
  return displayName
}

class TechSettings extends Component {
  static propTypes = {
    id: number.isRequired,
    disabled: bool.isRequired,
    onChange: func.isRequired,
    canApproveTechSettings: bool.isRequired,
    tdcsSettingsData: object.isRequired,
    updateTdcsSettings: func.isRequired,
    handleUpdateOverrideBanner: func.isRequired,
    applicationPermissions: array,
    experimentPermissions: array,
    environmentSamplings: array,
  }

  static defaultProps = {
    applicationPermissions: [],
    experimentPermissions: [],
    environmentSamplings: [],
  }

  state = {
    techSettings: null,
    errorMessage: initialErrors,
    techSettingsSessionTypeChange: false,
  }

  // LIFECYCLE METHODS
  componentDidMount() {
    const {
      tdcsSettingsData: { TdcsExperimentSettings },
    } = this.props
    if (TdcsExperimentSettings) {
      this.handleSetExperiment()
    }
  }

  componentDidUpdate(prevProps) {
    const {
      tdcsSettingsData: { TdcsExperimentSettings },
    } = this.props

    const {
      tdcsSettingsData: { TdcsExperimentSettings: prevTdcsExperiment },
    } = prevProps

    if (
      TdcsExperimentSettings &&
      !isEqual(prevTdcsExperiment, TdcsExperimentSettings)
    ) {
      this.handleSetExperiment()
    }
  }

  // REF Methods
  getErrorMessage = () => {
    const { errorMessage } = this.state

    const messages = Object.values(errorMessage).reduce(
      (accumulator, error) => {
        if (!accumulator && error) {
          return `${error}`
        }
        if (error) {
          return `${accumulator}; ${error}`
        }
        return accumulator
      },
      ''
    )
    return messages
  }

  validateSubmission = async () => {
    const { techSettings } = this.state
    const { tdcsSettingsData } = this.props
    const {
      experiment: { experimentToProductFeatures },
    } = tdcsSettingsData
    const { productId } = experimentToProductFeatures[0]

    await this.setState({ errorMessage: initialErrors })

    const { errors } = checkTechSettings(techSettings)

    if (productId === 4 && !techSettings.minAvPlatformVersion) {
      errors.minAvPlatformVersionRequired = 'Please enter a min version'
    }

    if (productId === 4 && !techSettings.maxAvPlatformVersion) {
      errors.maxAvPlatformVersionRequired = 'Please enter a max version'
    }

    if (!isEmpty(errors)) {
      this.setState(({ errorMessage }) => ({
        errorMessage: {
          ...errorMessage,
          ...errors,
        },
      }))

      return false
    }

    return true
  }

  validateDisable = () => {
    const { disabled } = this.props
    const canUpdateTech = this.checkPermission(TECH_UPDATE)
    const canUpdateRunning = this.checkPermission(EXPERIMENT_UPDATE_RUNNING)
    const canUpdateVersionRunning = this.checkPermission(
      EXPERIMENT_UPDATE_VERSION_RUNNING
    )
    const updateAbility =
      canUpdateTech || canUpdateRunning || canUpdateVersionRunning
    let disabledField = disabled

    // Verify if the disabled prop needs to be
    // overridden due to user having ability to update
    // and experiment being a running canary experiment
    if (updateAbility && disabled && this.isRunningCanaryExperiment()) {
      disabledField = !updateAbility
    }

    return disabledField
  }

  isComplete = () => {
    const { id, canApproveTechSettings } = this.props
    const experimentId = Number(id)

    if (canApproveTechSettings) {
      client.mutate({
        mutation: APPROVE_TECH_SETTINGS,
        variables: {
          experimentId,
        },
      })

      return true
    }

    client.mutate({
      mutation: SET_NEEDS_TECH_APPROVAL,
      variables: {
        experimentId,
      },
    })

    return false
  }

  // LOCAL STATE CHANGES/TOGGLES
  handleSetExperiment = async () => {
    const {
      onChange,
      tdcsSettingsData: { TdcsExperimentSettings: tdcsExperiment, experiment },
    } = this.props

    const {
      productMinVersion,
      productMaxVersion,
      minAvPlatformVersion,
      maxAvPlatformVersion,
      experimentId,
    } = tdcsExperiment

    const techSettings = {
      tdcsExperimentSettingsId: tdcsExperiment.id,
      productMinVersion,
      productMaxVersion,
      experimentId,
      minAvPlatformVersion,
      maxAvPlatformVersion,
      tdcsSessionTypeId: tdcsExperiment.tdcsSessionType.id,
      experimentTechSettings: tdcsExperiment.tdcsExperimentTechSettings,
      exclusive: experiment.exclusive,
    }

    // dynamic: default all disabled boolean filters to selected so that they aren't sent to TDCS
    techSettings.experimentTechSettings.map(filter => {
      const newObj = Object.assign({}, filter)
      if (!filter.isSelected && !filter.tdcsSessionTypeToProduct.isEnabled) {
        newObj.isSelected = true
      }
      return newObj
    })

    let disableSession1 = false
    let disableSession2 = false
    let disableSession3 = false

    disableSession1 = !this.areAnyBooleanFiltersEnabled(
      1,
      techSettings.experimentTechSettings
    )
    disableSession2 = !this.areAnyBooleanFiltersEnabled(
      2,
      techSettings.experimentTechSettings
    )
    disableSession3 = !this.areAnyBooleanFiltersEnabled(
      3,
      techSettings.experimentTechSettings
    )

    let selectedSession = techSettings.tdcsSessionTypeId
    if (disableSession1) {
      selectedSession = 2
    }
    if (disableSession2) {
      selectedSession = 3
    }

    techSettings.tdcsSessionTypeId = selectedSession

    await this.setState({
      techSettings,
      disableSession1,
      disableSession2,
      disableSession3,
    })

    onChange(!!(disableSession1 || disableSession2))
  }

  handleProductVersionChange = async ({ target: { name, value } }) => {
    const { onChange } = this.props
    const { techSettings } = this.state

    techSettings[name] = value

    await this.setState(({ errorMessage }) => ({
      techSettings,
      errorMessage: {
        ...errorMessage,
        [`${name}Required`]: null,
        [`${name}FormatError`]: null,
      },
    }))

    onChange(true)
  }

  handleSessionTypeChange = async event => {
    const { onChange } = this.props
    const { techSettings } = this.state

    techSettings.tdcsSessionTypeId = Number(event.target.value)

    await this.setState({ techSettings })

    onChange(true)
  }

  handleBooleanFilterChange = async (
    filter,
    fieldName = 'isSelected',
    value = undefined
  ) => {
    const { onChange } = this.props
    const { techSettings } = this.state

    let newTechSetting = Object.assign({}, techSettings)
    newTechSetting.experimentTechSettings = newTechSetting.experimentTechSettings
      .slice()
      .map(currentFilter => {
        const newfilt = Object.assign({}, currentFilter)
        if (newfilt.id === filter.id) {
          newfilt[fieldName] = value !== undefined ? value : !filter.isSelected
        }
        return newfilt
      })

    await this.setState({ techSettings: newTechSetting })

    onChange(true)
  }

  handleTextFilterChange = async (event, filter) => {
    const { techSettings } = this.state
    const { onChange } = this.props

    // Create new object to write to
    let newTechSetting = Object.assign({}, techSettings)
    newTechSetting.experimentTechSettings = newTechSetting.experimentTechSettings
      .slice()
      .map(filt => {
        if (filt.id === filter.id) {
          // Create new object to write to
          const newfilt = Object.assign({}, filt)
          newfilt.textValue = event.target.value
          // Return updated object
          return newfilt
        }
        return filt
      })

    await this.setState({ techSettings: newTechSetting })
    onChange(true)
  }

  // FILTER METHODS
  getFiltersOfType = filterTypeId => {
    const {
      techSettings: { experimentTechSettings, tdcsSessionTypeId },
    } = this.state

    const answer = experimentTechSettings.filter(setting => {
      const { id } = setting.tdcsSessionTypeToProduct.tdcsFilter.tdcsFilterType

      return (
        id === filterTypeId &&
        setting.tdcsSessionTypeToProduct.tdcsSessionTypeId === tdcsSessionTypeId
      )
    })
    return answer
  }

  areAnyBooleanFiltersEnabled = (tdcsSessionTypeId, experimentTechSettings) => {
    const settingsForType = experimentTechSettings.filter(
      ({
        tdcsSessionTypeToProduct: {
          tdcsFilter: {
            tdcsFilterType: { id: tdcsFilterTypeId },
          },
          tdcsSessionTypeId: tdcsSessionTypeIdToCompare,
        },
      }) =>
        tdcsFilterTypeId !== 4 &&
        tdcsSessionTypeIdToCompare === tdcsSessionTypeId
    )

    if (settingsForType.length === 0) {
      return true
    }

    return (
      settingsForType.find(setting => {
        // The old and new settings store the filters at different levels in the response
        const {
          tdcsSessionTypeToProduct: { isEnabled },
        } = setting

        const answer = isEnabled === true
        return answer
      }) !== undefined
    )
  }

  handleExperimentExclusiveChange = async () => {
    const { onChange } = this.props
    const { techSettings } = this.state

    if (techSettings.exclusive === null) {
      techSettings.exclusive = true
    } else {
      techSettings.exclusive = !techSettings.exclusive
    }

    await this.setState({ techSettings })

    onChange(true)
  }

  isSessionTypeChange = () => {
    const { techSettingsSessionTypeChange } = this.state

    return techSettingsSessionTypeChange
  }

  // API Methods
  handleSubmit = async () => {
    const { techSettings, errorMessage } = this.state
    const {
      id,
      updateTdcsSettings: updateTechSettings,
      tdcsSettingsData: {
        TdcsExperimentSettings: {
          tdcsSessionType: { id: oldTdcsSessionTypeId },
        },
      },
      handleUpdateOverrideBanner,
      experimentConfig,
    } = this.props

    // Reset any previous submission errors
    if (errorMessage.updateExperimentTechSettingsError) {
      this.setState({
        errorMessage: {
          ...errorMessage,
          updateExperimentTechSettingsError: null,
        },
      })
    }

    try {
      const input = {
        experimentId: id,
        tdcsExperimentSettingsId: techSettings.tdcsExperimentSettingsId,
        productMinVersion: techSettings.productMinVersion,
        productMaxVersion: techSettings.productMaxVersion,
        minAvPlatformVersion: techSettings.minAvPlatformVersion || '',
        maxAvPlatformVersion: techSettings.maxAvPlatformVersion || '',
        tdcsSessionTypeId: techSettings.tdcsSessionTypeId,
        experimentTechSettings: [],
        exclusive: techSettings.exclusive,
      }

      const updateBooleanFilters = filterTypeId => {
        this.getFiltersOfType(filterTypeId).forEach(filter => {
          input.experimentTechSettings.push({
            tdcsExperimentTechSettingsId: filter.id,
            isSelected: filter.isSelected,
          })
        })
      }

      const updateTextFilters = filterTypeId => {
        this.getFiltersOfType(filterTypeId).forEach(filter => {
          input.experimentTechSettings.push({
            tdcsExperimentTechSettingsId: filter.id,
            textValue: filter.textValue === null ? '' : filter.textValue,
            isIncluded: filter.isIncluded,
          })
        })
      }

      updateBooleanFilters(1)
      updateBooleanFilters(2)
      updateBooleanFilters(3)
      updateBooleanFilters(5)
      updateTextFilters(4)

      await updateTechSettings({
        variables: {
          input,
        },
      })

      const parsedConfig = JSON.parse(experimentConfig).setUpSteps

      parsedConfig.activation.status = 'incomplete'

      if (
        oldTdcsSessionTypeId &&
        oldTdcsSessionTypeId !== techSettings.tdcsSessionTypeId
      ) {
        this.setState({ techSettingsSessionTypeChange: true })
      }

      const isExperimentSetUpComplete =
        Object.keys(parsedConfig).filter(
          key => parsedConfig[key].status === 'initial'
        ).length === 0

      // DYNEXP-2238
      // Throw banner up for override deletion
      if (
        isExperimentSetUpComplete &&
        oldTdcsSessionTypeId &&
        oldTdcsSessionTypeId !== techSettings.tdcsSessionTypeId
      ) {
        await handleUpdateOverrideBanner(true)
      }
      return true
    } catch (error) {
      const input = formatLoggingError(error, { id })

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input,
        },
      })

      this.setState(({ oldErrorMessage }) => ({
        errorMessage: {
          ...oldErrorMessage,
          updateExperimentTechSettingsError:
            copyContent.techSettings.updateExperimentTechSettingsError,
        },
      }))
    }

    return true
  }

  // DERIVED STATE

  checkPermission = permId => {
    const {
      applicationPermissions,
      experimentPermissions,
      environmentSamplings,
      tdcsSettingsData: {
        experiment: { experimentTypeId },
      },
    } = this.props

    if (!experimentPermissions) return false

    return hasPermission({
      experimentTypeId,
      applicationPermissions,
      experimentPermissions,
      environmentSamplings,
      permissionId: permId,
    })
  }

  isRunningCanaryExperiment() {
    const {
      tdcsSettingsData: { experiment },
    } = this.props
    if (!experiment) return false

    const { experimentStatusId, experimentTypeId } = experiment
    return experimentStatusId === RUNNING && experimentTypeId === CANARY
  }

  // RENDER METHODS
  renderBooleanFilter = (filterName, filters) => {
    const additionalClassName =
      filterName === 'Network Type' ? 'network-type' : 'regular-type'

    return (
      <div
        className="experiment-tech-settings__filter-category"
        role="presentation"
      >
        <div className="experiment-tech-settings__filter-category-title">
          {filterName}
        </div>
        <div className={`experiment-tech-settings__${additionalClassName}`}>
          {filters.map(filter => {
            const displayName = filterDisplayName(filter)

            const isEnabled = filter.tdcsSessionTypeToProduct
              ? filter.tdcsSessionTypeToProduct.isEnabled
              : true

            return (
              <KiteCheckbox
                label={displayName}
                key={`filter-${filter.id}`}
                checked={filter.isSelected}
                onChange={() => this.handleBooleanFilterChange(filter)}
                className="experiment-tech-settings__filter-checkbox"
                disabled={this.validateDisable() || !isEnabled}
              />
            )
          })}
        </div>
      </div>
    )
  }

  renderTextFilter = filter => {
    const isOld = filter.tdcsFilter !== undefined

    const isEnabled =
      !isOld && filter.tdcsSessionTypeToProduct
        ? filter.tdcsSessionTypeToProduct.isEnabled
        : true

    return (
      <div
        className="experiment-tech-settings__input-wrapper"
        key={`filter-${filter.id}`}
      >
        <LabeledSwitch
          className="experiment-tech-settings__include-exclude-switch"
          id={`${filter.id}__include-exclude-switch`}
          name={`${filter.id}__include-exclude-switch`}
          options={['Include', 'Exclude']}
          checked={!filter.isIncluded}
          disabled={this.validateDisable() || !isEnabled}
          onChange={() =>
            this.handleBooleanFilterChange(
              filter,
              'isIncluded',
              !filter.isIncluded
            )
          }
          backgroundColor="#63738A"
          maxWidth="10rem"
          minWidth="10rem"
        />
        <KiteInput
          label={filterDisplayName(filter)}
          placeholder="Enter comma delimited values"
          value={
            filter.textValue !== null && filter.textValue !== undefined
              ? filter.textValue
              : ''
          }
          onChange={event => this.handleTextFilterChange(event, filter)}
          disabled={this.validateDisable() || !isEnabled}
        />
      </div>
    )
  }

  renderOldUi = () => {
    const {
      disabled,
      techSettings: { tdcsSessionTypeId },
    } = this.state

    const filters1 = this.getFiltersOfType(1)
    const filters2 = this.getFiltersOfType(2)
    const filters3 = this.getFiltersOfType(3)

    const textFilters = this.getFiltersOfType(4)

    return (
      <Fragment>
        <KiteRadio
          radioButtons={[
            {
              label: 'Authenticated Customers by Account',
              value: '1',
              checked: tdcsSessionTypeId === 1,
            },
            {
              label: 'Authenticated Customers by Device',
              value: '2',
              checked: tdcsSessionTypeId === 2,
            },
            {
              label: 'Unauthenticated Customers by Device',
              value: '3',
              checked: tdcsSessionTypeId === 3,
            },
          ]}
          name="includedCustomers"
          onChange={this.handleSessionTypeChange}
          margin="18px 0 0 0"
          disabled={disabled}
        />
        <h3 className="experiment-tech-settings__group-label">
          Filter to Specific Customers <span>(optional)</span>
        </h3>
        <div className="experiment-tech-settings__subtext">
          TDCS Advanced Filters
        </div>
        <div
          className="experiment-tech-settings__filter-categories-wrapper"
          role="presentation"
        >
          {this.renderBooleanFilter('MSO', filters1)}
          {this.renderBooleanFilter('Classification', filters2)}
          {this.renderBooleanFilter('Account Type', filters3)}
        </div>
        <div className="experiment-tech-settings__custom-filters-wrapper">
          {textFilters.map(this.renderTextFilter)}
        </div>
      </Fragment>
    )
  }

  renderNewUi = () => {
    const {
      disabled,
      techSettings: { tdcsSessionTypeId },
      disableSession1,
      disableSession2,
      disableSession3,
    } = this.state
    const { disabled: isDisabled } = this.props

    const {
      techSettings: { noAvailableSettings },
    } = copyContent

    const textFilters = this.getFiltersOfType(4)

    const filters1 = this.getFiltersOfType(1)
    const filters2 = this.getFiltersOfType(2)
    const filters3 = this.getFiltersOfType(3)
    const filters5 = this.getFiltersOfType(5)
    const noFilters = !filters1.length && !filters2.length && !filters3.length

    return (
      <div className="dynamic-tech-settings">
        <KiteCard className="dynamic-tech-settings__filters-card">
          <div className="dynamic-tech-settings__left">
            <StackedRadio
              radioButtons={[
                {
                  label: 'Authenticated Customers by Account',
                  value: '1',
                  checked: tdcsSessionTypeId === 1,
                  disabled: disableSession1,
                },
                {
                  label: 'Authenticated Customers by Device',
                  value: '2',
                  checked: tdcsSessionTypeId === 2,
                  disabled: disableSession2,
                },
                {
                  label: 'Unauthenticated Customers by Device',
                  value: '3',
                  checked: tdcsSessionTypeId === 3,
                  disabled: disableSession3,
                },
              ]}
              name="includedCustomers"
              onChange={this.handleSessionTypeChange}
              margin="18px 0 0 0"
              disabled={disabled || isDisabled}
            />
          </div>
          <div className="dynamic-tech-settings__right">
            <h3 className="experiment-tech-settings__group-label">
              Filter to Specific Customers
              <span>(optional)</span>
            </h3>
            <div className="experiment-tech-settings__subtext">
              TDCS Advanced Filters
            </div>
            <div
              className="experiment-tech-settings__filter-categories-wrapper"
              role="presentation"
            >
              {noFilters && (
                <div className="dynamic-tech-settings__no-available">
                  {noAvailableSettings}
                </div>
              )}
              {!noFilters && (
                <Fragment>
                  {filters1.length > 0 &&
                    this.renderBooleanFilter('MSO', filters1)}
                  {filters3.length > 0 &&
                    this.renderBooleanFilter('Account Type', filters3)}
                  {filters2.length > 0 &&
                    this.renderBooleanFilter('Classification', filters2)}
                </Fragment>
              )}
            </div>
          </div>
        </KiteCard>
        <KiteCard className="dynamic-tech-settings__additional-card">
          <div
            className="dynamic-tech-settings__left"
            style={{ marginRight: 0 }}
          >
            <h3 className="experiment-tech-settings__group-label">
              Additional Advanced Filtering
            </h3>
            <div className="experiment-tech-settings__subtext">
              Optional TDCS Advanced Filters
            </div>
          </div>
          <div className="dynamic-tech-settings__right">
            <div className="experiment-tech-settings__custom-filters-wrapper">
              {textFilters.map(this.renderTextFilter)}
              <div className="experiment-tech-settings__network-type">
                {filters5.length > 0 &&
                  this.renderBooleanFilter('Network Type', filters5)}
              </div>
            </div>
          </div>
        </KiteCard>
      </div>
    )
  }

  render() {
    const {
      disabled,
      tdcsSettingsData: { loading: techSettingsLoading },
      tdcsSettingsData,
    } = this.props

    const { techSettings, errorMessage } = this.state

    const {
      productMinVersionRequired,
      productMinVersionFormatError,
      productMaxVersionRequired,
      productMaxVersionFormatError,
      minAvPlatformVersionRequired,
      maxAvPlatformVersionRequired,
    } = errorMessage

    if (!techSettings || techSettingsLoading) {
      return (
        <div className="app__loader">
          <KiteLoader size="7rem" />
        </div>
      )
    }

    const {
      productMinVersion,
      productMaxVersion,
      minAvPlatformVersion,
      maxAvPlatformVersion,
      exclusive,
    } = techSettings

    const {
      experiment: { experimentToProductFeatures },
      countExclusiveExperimentsByExperimentProduct,
    } = tdcsSettingsData
    const { productId } = experimentToProductFeatures[0]
    const canUpdateVersionRunning = this.checkPermission(
      EXPERIMENT_UPDATE_VERSION_RUNNING
    )
    const hasExclusiveExperimentsPermission = this.checkPermission(
      EXPERIMENT_TOGGLE_EXCLUSIVITY
    )
    const { featureFlags, applicationPermissions } = client.readQuery({
      query: gql`
        query getCurrentUser {
          applicationPermissions {
            id
            name
          }
          featureFlags {
            id
            name
            active
          }
        }
      `,
    })
    const isExclusiveExperimentsOn = isFeatureFlagActive({
      featureFlags,
      featureFlagId: EXCLUSIVE_EXPERIMENTS,
      applicationPermissions,
    })

    return (
      <div className="experiment-tech-settings">
        <h2 className="experiment-tech-settings__title">
          Enter Your (TDCS) Settings
        </h2>
        <h4 className="experiment-tech-settings__subtext">
          Work with your Technical Contact to complete this step.
        </h4>
        <div className="experiment-tech-settings__version-header-wrapper">
          <h3 className="experiment-tech-settings__group-label">
            Product Versions
          </h3>
          {productId === 4 && (
            <h3 className="experiment-tech-settings__group-label">
              AV Platform Versions
            </h3>
          )}
        </div>
        <div className="experiment-tech-settings__version-wrapper">
          <KiteInput
            type="text"
            name="productMinVersion"
            label="Min"
            value={productMinVersion || ''}
            errorMessage={
              productMinVersionRequired || productMinVersionFormatError
            }
            onChange={this.handleProductVersionChange}
            margin="0 20px 0 0"
            maxWidth="155px"
            disabled={canUpdateVersionRunning ? false : disabled}
          />

          <KiteInput
            type="text"
            name="productMaxVersion"
            label="Max"
            value={productMaxVersion || ''}
            errorMessage={
              productMaxVersionRequired || productMaxVersionFormatError
            }
            onChange={this.handleProductVersionChange}
            margin="0 20px 0 0"
            maxWidth="155px"
            disabled={canUpdateVersionRunning ? false : disabled}
          />

          {productId === 4 && (
            <div className="experiment-tech-settings__av-platform-versions">
              <KiteInput
                type="text"
                name="minAvPlatformVersion"
                label="Min"
                value={minAvPlatformVersion || ''}
                errorMessage={minAvPlatformVersionRequired}
                onChange={this.handleProductVersionChange}
                margin="0 20px 0 0"
                maxWidth="155px"
                disabled={canUpdateVersionRunning ? false : disabled}
              />

              <KiteInput
                type="text"
                name="maxAvPlatformVersion"
                label="Max"
                value={maxAvPlatformVersion || ''}
                errorMessage={maxAvPlatformVersionRequired}
                onChange={this.handleProductVersionChange}
                margin="0 20px 0 0"
                maxWidth="155px"
                disabled={canUpdateVersionRunning ? false : disabled}
              />
            </div>
          )}
        </div>
        <h3 className="experiment-tech-settings__group-label">
          Customers to Include
        </h3>
        <div className="experiment-tech-settings__filter-info-wrapper">
          <div className="experiment-tech-settings__subtext">
            TDCS Inform Call Filters
          </div>
          <div className="experiment-tech-settings__help-text">
            Wondering what all these options mean? Explore our
          </div>
          <div className="experiment-tech-settings__wiki-link-out">
            <KiteLink
              newTab
              href={'/learn/distillery/draft-experiment/tech-settings'}
              type="standalone"
            >
              Tech Settings Learn Article
              <KiteIcon margin="5px" name="arrow-up-right" size="1rem" />
            </KiteLink>
          </div>
        </div>

        {this.renderNewUi()}

        {isExclusiveExperimentsOn && (
          <KiteCard
            className="dynamic-tech-settings__exclusive-card"
            margin="20px 0 0 0"
          >
            <div
              className="dynamic-tech-settings__left"
              style={{ marginRight: 0 }}
            >
              <h3 className="experiment-tech-settings__group-label">
                Set exclusivity for this experiment:
              </h3>

              <div className="experiment-tech-settings__subtext">
                {exclusive
                  ? 'This experiment should run independently of other experiments of the same product.'
                  : "This experiment's population may overlap with other experiments of the same product."}
              </div>
            </div>
            {(disabled || !hasExclusiveExperimentsPermission
              ? false
              : countExclusiveExperimentsByExperimentProduct <= 4) && (
              <KiteSwitch
                className="experiment-tech-settings__exclusive-switch"
                id="experiment-tech-settings__exclusive-switch"
                name="experiment-tech-settings__exclusive-switch"
                isOn={exclusive}
                onClick={this.handleExperimentExclusiveChange}
              />
            )}

            <div>
              Available segments for exclusive experiments on this product:{' '}
              {4 - countExclusiveExperimentsByExperimentProduct} of 4.
            </div>
          </KiteCard>
        )}
      </div>
    )
  }
}

const NewTechSettings = flowRight(
  getTdcsSettings,
  updateTdcsSettings
)(TechSettings)

class TechSettingsSwitcher extends Component {
  ref = null

  static propTypes = {
    tdcsSettingsData: object.isRequired,
  }

  // Ref methods
  getErrorMessage = () => this.ref.getErrorMessage()

  validateSubmission = async () => this.ref.validateSubmission()

  validateDisable = () => this.ref.validateDisable()

  isComplete = () => this.ref.isComplete()

  handleSubmit = () => this.ref.handleSubmit()

  render() {
    const {
      tdcsSettingsData: { TdcsExperimentSettings, loading },
    } = this.props

    const { featureFlags, applicationPermissions } = client.readQuery({
      query: gql`
        query getCurrentUser {
          applicationPermissions {
            id
            name
          }
          featureFlags {
            id
            name
            active
          }
        }
      `,
    })
    const isDynamicSettingsOn = isFeatureFlagActive({
      featureFlags,
      featureFlagId: DYNAMIC_TECH_SETTINGS,
      applicationPermissions,
    })

    const shouldShowOldUi =
      (!loading && !TdcsExperimentSettings) || !isDynamicSettingsOn
    if (shouldShowOldUi) {
      return (
        <OldTechSettings
          {...this.props}
          ref={component => {
            this.ref =
              component && component.getWrappedInstance().getWrappedInstance()
          }}
        />
      )
    }
    return (
      <NewTechSettings
        {...this.props}
        ref={component => {
          this.ref =
            component && component.getWrappedInstance().getWrappedInstance()
        }}
      />
    )
  }
}

export default flowRight(getTdcsSettings)(TechSettingsSwitcher)
