import { observer } from 'mobx-react'
import React from 'react'
import { MultiSelectSearch } from '@kite/react-kite-plus'

const ORAddToGroups = observer(function ORAddToGroups({
  orEditorModel,
  orGroupsListModel,
  isOptional = false,
  showTooltip = true,
  label = 'Add To Groups',
  saveAfterSelect = false,
  onSaveSuccess = null,
}) {
  return (
    <MultiSelectSearch
      className="orm__add-to-groups"
      name={`groupSearchValue(${orEditorModel.id})`}
      label={isOptional ? `Optional: ${label}` : label}
      tooltip={
        showTooltip ? 'Add this override to one or more override groups' : null
      }
      initialData={orGroupsListModel.allData.map(m => {
        return { ...m.updatedAPIObject, id: m.id }
      })}
      suggestionKey="name"
      value={orEditorModel.groupSearchValue}
      defaultSelections={orEditorModel.groups.map(m => {
        return { ...m.updatedAPIObject, id: m.id }
      })}
      onChange={({ target: { value } }) =>
        (orEditorModel.groupSearchValue = value)
      }
      onSelectionsChange={params => {
        orEditorModel.setGroups(params)
        if (saveAfterSelect && orEditorModel.isDirty) {
          orEditorModel.save()
          onSaveSuccess && onSaveSuccess()
        }
      }}
      minCharacters={3}
    />
  )
})

export default ORAddToGroups
