import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

/*
* Sample Input
* {
	"input": {
    "tdcsExperimentSettingsId": 2,
    "experimentId": 2,
    "tdcsSessionTypeId": 1,
    "productMinVersion": "1.0",
    "productMaxVersion": "10.0",
    "experimentTechSettings": [
      {
        "tdcsExperimentTechSettingsId": 1,
        "isSelected": true
      }
    ],
    "exclusive": true,
  }
}
*/
const UPDATE_TDCS_SETTINGS = gql`
  mutation updateTdcsSettings($input: UpdateTdcsExperimentSettings!) {
    updateTdcsExperimentSettings(input: $input) {
      id
      productMinVersion
      productMaxVersion
      minAvPlatformVersion
      maxAvPlatformVersion
      experimentId
      tdcsSessionType {
        id
        name
        displayName
      }
      tdcsExperimentTechSettings {
        id
        tdcsExperimentSettingsId
        textValue
        isSelected
        tdcsSessionTypeToProductId
        tdcsSessionTypeToProduct {
          id
          productId
          isEnabled
          tdcsFilter {
            id
            displayName
            displayOrder
            tdcsFilterType {
              id
              displayName
            }
          }
        }
      }
      exclusive
    }
  }
`

const updateTdcsSettings = graphql(UPDATE_TDCS_SETTINGS, {
  name: 'updateTdcsSettings',
  withRef: true,
})

export default updateTdcsSettings
