import {
  decorate,
  observable,
  computed,
  reaction,
  untracked,
  action,
  runInAction,
  transaction,
} from 'mobx'

import { client } from '../../configuration/configApiClient'
import PagedArrayModel from './PagedArrayModel'
import appModel from '../../components/App/AppModel'

class ListModel {
  objects = []
  isEditing = false
  apiError = null
  lastEditorModel = null
  editorDispose = null
  alertTimeout = 7000
  querySubmittedAt = null
  inFlightAPIRequest = null
  alertTimer = null
  editorModel = null
  _editorModels = []
  apiTime = null
  searchObjectAtom = null
  search = null
  shouldFilter = true

  constructor({ EditorModelClass, search, searchObjectAtom }) {
    if (!EditorModelClass) throw Error('Must supply a EditorModelClass')
    this.EditorModelClass = EditorModelClass
    this.search = search
    this.searchObjectAtom = searchObjectAtom
    reaction(
      () => this.editorModel && this.editorModel.done,
      () => {
        if (this.editorModel?.done) this.endEditing()
      }
    )

    reaction(
      () => this.kiteAlertReady,
      () => {
        runInAction(() => {
          this.alertTimer = setTimeout(() => {
            runInAction(() => {
              this.alertTimer = null
            })
          }, this.alertTimeout)
        })
      }
    )

    reaction(() => this.inFlightAPIRequest, this.processQuery)
  }

  client = client

  get data() {
    return this.pageModel ? this.pageModel.page : []
  }

  get isLoading() {
    return this.inFlightAPIRequest instanceof Promise
  }

  addNew() {
    this.isEditing = true
    this.editorModel = new this.EditorModelClass()
    this.editorModel.start()
    this.editorModel.startEditing()
  }

  async submitQuery() {
    const { query } = this.EditorModelClass
    if (!query)
      throw Error(
        'Must have static query property on subclass to send graphql '
      )

    try {
      if (!this.inFlightAPIRequest) {
        transaction(() => {
          this.querySubmittedAt = new Date()
          this.inFlightAPIRequest = client.query({
            query,
            fetchPolicy: 'network-only',
          })
        })
      } else
        throw Error(
          'query already in process, did you mean to submit 2 queries at once?'
        )
    } catch (error) {
      console.error('Error submitting query to api')
      console.error(error)
      this.apiError = error
      this.inFlightAPIRequest = null
    }
  }

  processQuery = async () => {
    const { typeName, queryDataName } = this.EditorModelClass

    if (!typeName && !queryDataName) {
      throw Error(
        'Must have static typeName or queryDataName property on subclass to process array from graphql response'
      )
    }

    try {
      if (!this.inFlightAPIRequest) return
      const res = await this.inFlightAPIRequest

      if (res.errors && res.errors.length) {
        const [error] = res.errors
        console.error('GraphQL errors returned')
        console.error(error)
        this.apiError = error
      } else {
        runInAction(() => {
          this.apiTime = new Date()
          const objects =
            res.data[queryDataName ?? `${typeName.toLowerCase()}s`]

          this._editorModels.replace(
            objects.map(apiObject => {
              return appModel.getEditorModel({
                EditorModelClass: this.EditorModelClass,
                id: apiObject.id,
                apiTime: this.apiTime,
              })
            })
          )
        })
      }
    } catch (error) {
      console.error('Error processing API Query')
      console.error(error)
      this.apiError = error
    } finally {
      this.inFlightAPIRequest = null
    }
  }

  get allData() {
    return this._editorModels
  }

  get editorModels() {
    return this.filtered && this.shouldFilter
      ? this.filtered
      : this._editorModels
  }

  get hasResults() {
    return this.editorModels && this.editorModels.length
  }

  get pageModel() {
    return this.hasResults
      ? untracked(() => new PagedArrayModel(this.editorModels))
      : null
  }

  get filtered() {
    if (
      !this.searchObjectAtom?.get() ||
      !this.search ||
      !this._editorModels.length
    )
      return null

    const answer = this._editorModels.filter((row, index) => {
      return this.search({
        listModel: this,
        row,
        index,
        searchObject: this.searchObjectAtom.get(),
      })
    })

    return answer
  }

  // all of this will run in one mobx transaction
  // as do all actions
  endEditing() {
    // save off the last model in an observable so we can show alerts for it
    this.lastEditorModel = this.editorModel

    // close the editor
    this.isEditing = false

    // unselect
    this.editorModel = null

    if (this.lastEditorModel?.isDeleting) {
      this._editorModels.remove(
        this._editorModels.find(({ id }) => id === this.lastEditorModel.id)
      )
    } else if (this.lastEditorModel.wasCreated) {
      if (!appModel.modelsById.has(this.lastEditorModel.cacheKey))
        appModel.setEditorModel(this.lastEditorModel)
      appModel.updateFromCache(this.lastEditorModel)
      this._editorModels.unshift(this.lastEditorModel)
    }
  }

  get kiteAlertReady() {
    return this.lastEditorModel?.kiteAlert
      ? this.lastEditorModel?.kiteAlert
      : this.editorModel?.kiteAlert
      ? this.editorModel.kiteAlert
      : this.apiError
      ? {
          type: 'alert',
          description:
            'There was an error returned from the Distillery API.  Distillery support has been notified',
        }
      : null
  }

  get kiteAlert() {
    if (this.alertTimer && this.kiteAlertReady) return this.kiteAlertReady
    return null
  }

  exitEditing() {
    if (!this.isEditing) throw Error('not currently editing')
    // editor will need to confirm changes etc...
    // and then will set done flag true
    // triggering reaction
    this.editorModel.exit()
  }

  cancelEditing() {
    this.editorModel = null
    this.isEditing = false
  }

  editRow(row) {
    this.editorModel = row
    this.editorModel.startEditing()
    this.isEditing = true
  }

  get numResults() {
    return this.editorModels ? this.editorModels.length : 0
  }
}

export default decorate(ListModel, {
  _editorModels: observable,
  editorModel: observable.ref,
  lastEditorModel: observable.ref,
  editorModels: computed,
  pageModel: computed({ keepAlive: true }),
  filtered: computed,
  inFlightAPIRequest: observable.ref,
  isLoading: computed,
  apiError: observable.ref,
  searchObject: observable,
  isEditing: observable,
  editRow: action,
  kiteAlertReady: computed,
  alertTimer: observable.ref,
  kiteAlert: computed,
  exitEditing: action,
  endEditing: action,
  hasResults: computed,
  numResults: computed,
  addNew: action,
  apiTime: observable.ref,
  querySubmittedAt: observable.ref,
  shouldFilter: observable,
  cancelEditing: action,
})
