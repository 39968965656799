import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

/* Sample Input
*
* {
  experimentId: Int!
  sessionTypeId: Int
  rule: String
}
*/

const UPDATE_DREM_EXPERIMENT_SETTINGS = gql`
  mutation updateDremExperimentSettings($input: UpdateDremExperimentSettings!) {
    updateDremExperimentSettings(input: $input) {
      id
      rule
      sessionTypeId
      sessionType {
        id
        name
        displayName
        overrideTypeId
      }
    }
  }
`

const updateDremExperimentSettings = graphql(UPDATE_DREM_EXPERIMENT_SETTINGS, {
  name: 'updateDremExperimentSettings',
  withRef: true,
})

export default updateDremExperimentSettings
