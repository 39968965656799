import React, { Component } from 'react'
import isEqual from 'lodash/isEqual'
import flowRight from 'lodash/flowRight'
import { object, func } from 'prop-types'
import classNames from 'classnames'
import { KiteLoader, KiteSelect, KiteInput, KiteButton } from '@kite/react-kite'
import { SortableTable } from '@kite/react-kite-plus'

import { client } from '../../configuration/configApiClient'
import { LOG_ERROR } from '../../shared/mutations'
import { formatLoggingError, isURL } from '../../shared/utilities'
import getSubscriptionsData from './queries/getSubscriptionsData'
import { subscribeToSns, unsubscribeFromSns } from './mutations'
import SubscriptionDetails from './SubscriptionDetails/SubscriptionDetails'

import { ExpansionPanel, Modal } from '../../componentLibrary'
import './Subscriptions.scss'

export class Subscriptions extends Component {
  static propTypes = {
    onNavigate: func.isRequired,
    subscriptionsData: object.isRequired,
    subscribeToSns: func.isRequired,
    unsubscribeFromSns: func.isRequired,
  }

  state = {
    selectedTopic: '',
    selectedEndpoint: '',
    selectedSubscription: { id: null },
    subscriptionToDelete: null,
    isValid: false,
    loading: {
      add: false,
      delete: false,
      environment: false,
    },
    isUpdating: false,
    wasSuccessful: null,
    errorMessage: {
      add: null,
      delete: null,
      input: null,
      subscriptions: null,
    },
  }

  // LIFECYCLE METHODS
  componentDidMount() {
    const { onNavigate } = this.props

    onNavigate(
      {
        path: '/subscriptions',
        title: 'Subscriptions',
      },
      true
    )
  }

  // LOCAL STATE CHANGE/TOGGLE METHODS
  handleChange = async ({ target: { value, name } }) => {
    const { errorMessage } = this.state

    await this.setState({
      [name]: value,
      errorMessage: { ...errorMessage, input: null },
    })

    this.handleValidation()
  }

  handleSelectSubscription = selectedSubscription => {
    this.setState(prevState => ({
      selectedSubscription: isEqual(
        prevState.selectedSubscription,
        selectedSubscription
      )
        ? { id: null }
        : selectedSubscription,
    }))
  }

  handleCheckUnsubscribe = subscriptionToDelete => {
    this.setState({ subscriptionToDelete })
  }

  handleCancelUnsubscribe = () => {
    this.setState({ subscriptionToDelete: null })
  }

  // VALIDATION METHODS
  handleValidation = () => {
    const { selectedTopic, selectedEndpoint } = this.state
    const isValid = selectedTopic !== '' && isURL(selectedEndpoint)

    this.setState({ isValid })
  }

  handleCheckError = () => {
    const { selectedTopic, selectedEndpoint, errorMessage } = this.state

    const inputErrorMessage = `You must ${
      !selectedTopic ? 'select a topic' : ''
    }${!selectedTopic && !selectedEndpoint ? ' and ' : ''}${
      !selectedEndpoint || (selectedEndpoint && !isURL(selectedEndpoint))
        ? 'provide a valid endpoint'
        : ''
    }`

    this.setState({
      errorMessage: { ...errorMessage, input: inputErrorMessage },
    })
  }

  // API METHODS
  handleSubmit = async () => {
    const {
      isValid,
      loading,
      errorMessage,
      selectedTopic,
      selectedEndpoint,
    } = this.state
    const {
      subscribeToSns: subscribe,
      subscriptionsData: { refetch },
    } = this.props

    if (!isValid) {
      this.handleCheckError()
    } else {
      await this.setState({
        loading: { ...loading, add: true },
      })

      try {
        await subscribe({
          variables: {
            input: {
              subscriptionTopic: selectedTopic,
              endpoint: selectedEndpoint,
            },
          },
        })

        await this.setState({
          loading: { ...loading, add: false },
          isUpdating: true,
          wasSuccessful: true,
          selectedEndpoint: '',
          isValid: null,
        })
        setTimeout(async () => {
          await refetch()
          this.setState({ wasSuccessful: null, isUpdating: false })
        }, 1000)
      } catch (error) {
        const input = formatLoggingError(error)

        await client.mutate({
          mutation: LOG_ERROR,
          variables: {
            input,
          },
        })

        this.setState({
          loading: { ...loading, add: false },
          errorMessage: { ...errorMessage, add: error.message },
        })
      }
    }
  }

  handleConfirmUnsubscribe = async () => {
    const {
      unsubscribeFromSns: unsubscribe,
      subscriptionsData: { refetch },
    } = this.props
    const {
      loading,
      errorMessage,
      subscriptionToDelete: { subArn },
    } = this.state

    await this.setState({
      loading: { ...loading, delete: true },
    })

    try {
      await unsubscribe({
        variables: {
          input: {
            subArn,
          },
        },
      })

      await this.setState({
        loading: { ...loading, delete: false },
        subscriptionToDelete: null,
        selectedSubscription: { id: null },
        isUpdating: true,
      })
      await refetch()
      this.setState({ isUpdating: false })
    } catch (error) {
      const input = formatLoggingError(error)

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input,
        },
      })

      this.setState({
        loading: { ...loading, delete: false },
        errorMessage: { ...errorMessage, delete: error.message },
      })
    }
  }

  // FORMATTING METHODS
  formatTopicName = topicArn => {
    const arnArray = topicArn.split(':')
    const wordArray = arnArray
      .find(arn => arn.includes('dx'))
      .split('-')
      .map(word => {
        const letterArray = word.split('')
        const firstLetter = letterArray.shift().toUpperCase()

        letterArray.unshift(firstLetter)

        const newWord = letterArray.join('')

        return newWord
      })
    wordArray.shift()

    const topic = wordArray.join(' ')

    return topic
  }

  formatTopics = () => {
    const {
      subscriptionsData: { subscriptionTopics },
    } = this.props

    return subscriptionTopics.reduce((options, topic) => {
      const topicKey = this.formatTopicName(topic.topicArn)

      return { ...options, [topicKey]: topic.topicArn }
    }, {})
  }

  formatSubscriptions = () => {
    const {
      subscriptionsData: { subscriptions },
    } = this.props

    return subscriptions.map(
      ({ id, subjectArn, endpoint, subscriptionTopic }) => ({
        id,
        endpoint,
        subArn: subjectArn,
        subscriptionTopic: subscriptionTopic.topicArn,
        topic: this.formatTopicName(subscriptionTopic.topicArn),
        status: subjectArn === 'PendingConfirmation' ? 'Pending' : 'Active',
      })
    )
  }

  render() {
    const {
      subscriptionsData: { subscriptions, loading: subscriptionsLoading },
    } = this.props
    const {
      selectedTopic,
      selectedEndpoint,
      selectedSubscription,
      subscriptionToDelete,
      isValid,
      loading,
      isUpdating,
      wasSuccessful,
      errorMessage,
    } = this.state

    if (subscriptionsLoading) {
      return (
        <div className="app__loader">
          <KiteLoader size="7rem" />
        </div>
      )
    }

    const topicOptions = this.formatTopics()
    const formattedSubscriptions =
      subscriptions.length > 0 ? this.formatSubscriptions() : []

    const messageClassNames = classNames({
      subscriptions__message: true,
      'subscriptions__message-success': wasSuccessful,
      'subscriptions__message-error': errorMessage.add || errorMessage.input,
    })

    return (
      <div className="subscriptions">
        <div className="subscriptions__form-container">
          <h4 className="subscriptions__sub-title">Add a New Subscription</h4>

          <div className="subscriptions__form">
            <div className="subscriptions__form-wrapper">
              <KiteSelect
                className="subscriptions__topic-select"
                name="selectedTopic"
                label="Topics"
                value={selectedTopic}
                onChange={this.handleChange}
              >
                <option value="" disabled>
                  Select a topic
                </option>

                {Object.keys(topicOptions).map(topic => (
                  <option key={topic} value={topicOptions[topic]}>
                    {topic}
                  </option>
                ))}
              </KiteSelect>

              <KiteInput
                className="subscriptions__endpoint-input"
                name="selectedEndpoint"
                label="Endpoint"
                value={selectedEndpoint}
                onChange={this.handleChange}
              />

              <KiteButton
                className="subscriptions__submit-button"
                type="outline"
                size="small"
                disabled={!isValid}
                loading={loading.add}
                onClick={this.handleSubmit}
              >
                Submit
              </KiteButton>
            </div>

            <ExpansionPanel
              className={messageClassNames}
              type="minimal"
              isExpanded={
                (wasSuccessful || errorMessage.add || errorMessage.input) &&
                true
              }
            >
              {wasSuccessful && 'Success!'}
              {errorMessage.add}
              {errorMessage.input}
            </ExpansionPanel>
          </div>

          <h4 className="subscriptions__sub-title">Active Subscriptions</h4>

          <div className="subscriptions__container">
            {isUpdating && (
              <div className="app__loader-overlay">
                <KiteLoader size="7rem" />
              </div>
            )}

            <SortableTable
              tableData={formattedSubscriptions}
              columns={[
                { label: 'Topic', sortKey: 'topic', size: 1.5 },
                {
                  label: 'Endpoint',
                  sortKey: 'endpoint',
                  size: 2.5,
                },
                {
                  label: 'Status',
                  sortKey: 'status',
                  size: 0.5,
                },
              ]}
              noDataMessage="You do not have any subscriptions"
              onRowClick={this.handleSelectSubscription}
              isRowExpandable
              expansionRender={() => (
                <SubscriptionDetails
                  subscription={selectedSubscription}
                  topicName={selectedSubscription.topic}
                  onUnsubscribe={this.handleCheckUnsubscribe}
                />
              )}
            />
          </div>
        </div>

        {subscriptionToDelete && (
          <Modal
            message="Are you sure you want to unsubscribe?"
            subMessage="This cannot be undone"
            onConfirm={this.handleConfirmUnsubscribe}
            onDeny={this.handleCancelUnsubscribe}
            buttonProps={{ confirm: { value: 'Unsubscribe' } }}
            loading={loading.delete}
            errorMessage={errorMessage.delete}
          />
        )}
      </div>
    )
  }
}

export default flowRight(
  getSubscriptionsData,
  subscribeToSns,
  unsubscribeFromSns
)(Subscriptions)
