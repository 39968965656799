const scrollToNavigationPosition = (yAxis = 0, xAxis = 0) => {
  const navigationContainer = document.getElementsByClassName(
    'navigation-content__children-wrapper',
  )[0];

  // Check to prevent failure in test environment
  if (navigationContainer) {
    navigationContainer.scroll({
      left: yAxis,
      top: xAxis,
      behavior: 'smooth',
    });
  }
};

export default scrollToNavigationPosition;
