import { gql } from '@apollo/client'

export const GET_ALL_NETWORK_LOCKDOWNS = gql`
  query getNetworkLockdowns {
    networkLockdowns {
      id
      startDate
      endDate
      isImmediate
      preMessage
      duringMessage
    }
  }
`
