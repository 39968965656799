import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

export const GET_PERMISSION_TYPES = gql`
  query permissionTypes {
    permissionTypes {
      id
      name
    }
  }
`

const getPermissionTypes = graphql(GET_PERMISSION_TYPES, {
  name: 'allPermissionTypesData',
})

export default getPermissionTypes
