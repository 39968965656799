const getProductMetricsById = {
  query: `
    metricsForProduct(productId: $id) {
      id
      name
      displayName
      metricType {
        id
        name
      }
    }
  `,
  variables: {
    $id: 'Int!',
  },
};

export default getProductMetricsById;
