import 'react-awesome-query-builder/lib/css/styles.css'
import 'react-awesome-query-builder/lib/css/compact_styles.css'
import './QueryBuilder.scss'

import React, { useReducer, useEffect } from 'react'
import { Query, Builder, Utils as QbUtils } from 'react-awesome-query-builder'
import loadedConfig from './config'

const { loadFromJsonLogic, loadTree, checkTree, uuid, jsonLogicFormat } =
  QbUtils

export default function QueryBuilder({
  handleChange,
  fields,
  rules,
  disabled,
  error,
}) {
  if (disabled) {
    loadedConfig.settings = {
      ...loadedConfig.settings,
      immutableGroupsMode: true,
      immutableFieldsMode: true,
      immutableOpsMode: true,
      immutableValuesMode: true,
      canReorder: false,
      canRegroup: false,
    }
  } else {
    loadedConfig.settings = {
      ...loadedConfig.settings,
      immutableGroupsMode: false,
      immutableFieldsMode: false,
      immutableOpsMode: false,
      immutableValuesMode: false,
      canReorder: true,
      canRegroup: true,
    }
  }

  const initQueryState = {
    config: {
      ...loadedConfig,
      fields,
    },
    tree:
      rules && rules.logic && Object.keys(rules.logic).length > 0
        ? checkTree(
            loadFromJsonLogic(rules.logic, { ...loadedConfig, fields }),
            { ...loadedConfig, fields }
          )
        : checkTree(loadTree({ id: uuid(), type: 'group' }), {
            ...loadedConfig,
            fields,
          }),
  }

  const reducer = (state, action) => {
    if (action.type === 'setFieldsInit') {
      return {
        ...state,
        tree: initQueryState.tree,
        config: {
          ...state.config,
          ...action.state.config,
        },
      }
    } else if (action.type === 'onChange') {
      return {
        ...action.state,
      }
    } else {
      return state
    }
  }

  const [queryState, dispatch] = useReducer(reducer, initQueryState)
  const { config, tree } = queryState

  const onChange = async (immutableTree, newConfig) => {
    await dispatch({
      type: 'onChange',
      state: { config: newConfig, tree: immutableTree },
    })
    const jsonTree = jsonLogicFormat(immutableTree, newConfig)
    if (!jsonTree.logic && rules.logic) {
      await dispatch({
        type: 'setFieldsInit',
        state: {
          config: {
            ...loadedConfig,
            fields,
          },
        },
      })
    } else {
      await dispatch({
        type: 'onChange',
        state: { config: newConfig, tree: immutableTree },
      })
    }

    handleChange(jsonTree)
  }

  useEffect(() => {
    if (fields && Object.keys(fields).length) {
      dispatch({
        type: 'setFieldsInit',
        state: {
          config: {
            ...loadedConfig,
            fields,
          },
        },
      })
    }
  }, [fields])

  const renderBuilder = props => {
    return (
      <div
        className={`query-builder-container query-builder-rules${
          error ? '-error-state' : ''
        }`}
        style={{ padding: '10px' }}
      >
        <div className={`query-builder qb-lite ${error}`}>
          <Builder {...props} />
        </div>
        {error && (
          <div className="query-builder-rules-error-state__error-message">
            {error}
          </div>
        )}
      </div>
    )
  }

  return (
    <div>
      <Query
        {...config}
        value={tree}
        onChange={onChange}
        renderBuilder={renderBuilder}
      />
    </div>
  )
}
