import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

const GET_EXPERIMENT_PLAN = gql`
  query getExperimentPlan($id: Int!) {
    experiment(id: $id) {
      id
      uuid
      name
      summary
      hypothesis
      targetSampleSize
      durationNumDays
      activationEventUuid
      experimentTypeId
      experimentStatusId
      isCmsExperiment
      experimentToProductFeatures {
        id
        productId
        productFeatureId
        product {
          id
          displayName
        }
      }
      variants {
        id
      }
      experimentToUsers {
        id
        userId
        roleId
        user {
          id
          displayName
        }
        role {
          id
          name
        }
      }
    }
  }
`

const getExperimentPlan = graphql(GET_EXPERIMENT_PLAN, {
  name: 'experimentPlanData',
  withRef: true,
  skip: ownProps => !ownProps.id,
})

export default getExperimentPlan
