export default {
  saveButton: 'Save',
  saveAndContinueButton: 'Save & Continue',
  cancelButton: 'Cancel',
  updateConfigError:
    'Unable to update experiment. please try again in a few minutes, if this continues please contact Distillery Support',
  successSubmitTitle: step => `${step} saved`,
  successSubmitDescription: 'Changes successfully updated',
  techPocApprovalTitle: 'Awaiting Approval',
  techPocDescription:
    'Please approve all settings in the "Variants", "Tech Settings", and "Activation" steps.',
  variantsErrorOnApproval:
    'Experiment cannot be approved due to errors in one or more Variants. Please resolve them and try again.',
  techPocApproveButton: 'Approve',
  needsApprovalTitle: 'Needs Approval',
  needsNotice: 'ATTENTION',
  overrideNotice:
    "Changing core variables, such as the Product, clears any existing Overrides as they may not apply to the new selection. You'll need to set up new Overrides before you publish.",
  needsApprovalDescription:
    'A technical contact or admin must review these settings before publication.',
  errorApproveSettingsTitle: 'Unable to approve tech settings',
  errorApproveSettingsDescription: 'If this continues ',
  errorApproveLinkText: 'contact support.',
  errorApproveEmailSubject: user =>
    `mailto:DL-Distillery-Support@charter.com?subject=Distillery Support for Approving Tech Settings - ${user.displayName}`,
  plan: {
    summary:
      'Some context of the “Why” behind what your experiment is meant to accomplish, and an overview of the experiment’s goal. Whether that’s to fix an issue or make an improvement, the new variant should solve a business problem or address a specific outcome.',
    hypothesis:
      'A hypothesis gives specifics about what we’ll be testing to address our objective or problem statement. It can be stated in terms of what we expect (A will do better than B), and how we are measuring “better” or “success” - the more detail, the better. For example, rather than just saying we’re going to increase engagement, specify how we’ll measure engagement (e.g. content consumed, weekly days viewing, etc).',
    type: 'Select A/B for  comparing control and multiple variants. Select A/A for testing before rolling out to customers. Select Canary testing for rolling out to a limited set of devices.',
    lengthLimitError: 'An experiment must be between 1-180 days in length',
    getFeaturesError:
      'We were unable to get the features for this product. Please choose another product or try again',
    insertPlanError:
      'We were unable to create your experiment. Please try again in a few minutes',
    updatePlanError:
      'We were unable to update your experiment plan. Please try again in a few minutes',
    duplicateNameError:
      'An experiment with this name already exists. Please try another one.',
  },
  variants: {
    header:
      'Control represents the current version of your application. Variant represents the modified version of your application.',
    controlTitle: 'Name your control',
    controlDescription:
      'Please enter a detailed description of your control. It will be valuable for interpreting your results.',
    featureFlagOverride:
      'This section writes the code to tell the system whether your feature is enabled or not.',
    featureFlagWarning: 'Please add a feature flag override',
    variantTitle: 'Name your variant',
    variantDescription:
      'Please enter a detailed description of your control. It will be valuable for interpreting your results.',
    screenshots: 'Screenshots (Optional)',
    screenshotError: 'All uploads must be JPG/PNG and less than 5MB',
    loseJsonWarning: ({ currentType, nextType }) =>
      `Switching to ${nextType} will erase your current ${currentType} values.
      Are you sure you want to change how you enter your JSON?`,
    loseJsonWarningSubMessage: 'Any current JSON settings will be lost',
    addControlOverrideWarning: 'Please add an override for your control',
    addVariantOverrideWarning:
      'Please add an override for all of your variants',
    deleteVariantWarning: ({ name }) =>
      `Are you sure you want to delete ${name}?`,
    deleteVariantSubMessage: 'All current variant settings will be lost',
    deleteUploadWarning: 'Are you sure you want to delete this upload?',
    deleteUploadSubMessage: 'This cannot be undone',
    invalidJsonWarning: 'Invalid JSON input, please enter valid JSON',
    invalidJsonOnSubmit:
      'A custom JSON field has an invalid input. Please validate your JSON before submitting.',
    duplicateNameError:
      'You cannot have multiple variants with the same name. Please update your variant names.',
    insertUpdateVariantsError:
      'We were unable to create/update your variant(s). Please try again in a few minutes.',
    deleteVariantError:
      'We were unable to delete your variant. Please try again in a few minutes',
    uploadSaveError:
      'We were unable to save your variant upload. Please try again in a few minutes.',
    uploadDeleteError:
      'We were unable to delete your variant upload. Please try again in a few minutes.',
    multipleVariantReason:
      'Since you are testing more than 3 variants, please record the reason that works for your experiment. The more variants you are testing, the more challenging it can be to determine if there is a clear best among them. (Optional, but is a rated aspect of the Experiment Evaluation.)',
  },
  roles: {
    role2Tooltip:
      'The experimenter is the user who sets up the experiment and may overlap with other roles below.',
    role3Tooltip:
      'Administrators can do anything to the experiment, including launching, stopping and changing allocations.',
    role4Tooltip:
      'The Technical Contact for the experiment is a developer or somebody who can add technical details like JSON to the experiment.',
    role5Tooltip: 'The Product owner of the product for this experiment.',
    role6Tooltip:
      'If you have been working with a member of the Experimentation Team, please select their name here. Otherwise, select "I don\'t have a contact".  Please note that if you have not engaged with the Experimentation Team prior to launch, they will be limited in their ability to troubleshoot issues or provide additional support during or following the launch.',
    insertRolesError:
      'We were unable to update the roles on this experiment. Please try again in a few minutes',
  },
  metrics: {
    levelOneTitle: '1st Priority:  Success Metrics',
    levelOneDescription:
      'Choose a single success metric that defines success for your experiment.  It cannot be changed afer the experiment has begun.',
    levelTwoTitle: '2nd Priority:  Safety Metrics',
    levelTwoDescription:
      'Safeguarding our business and customers from being jeopardized by an experiment is our top priority.',
    otherDescriptionTitle: 'Add Other Metrics',
    otherDescription:
      'Add any other metrics that will help you understand customer behavior within the experience and provide more context for the experiment’s results. These metrics won’t determine the variant’s success or failure.',
    levelThreeTitle: '3rd Priority Metrics',
    mustHaveOneLevelOne: 'You must select at least one success metric',
    mustHaveOneLevelTwo: 'You must have at least one safety metric',
    deleteAndInsertExperimentMetrics:
      'We were unable to update the metrics on your experiment.  Please try again in a few minutes.',
    updateMetricsError:
      'We were unable to update the metrics on your experiment.  Please try again in a few minutes.',
    noQuantumMetricsError: 'Quantum returned no metrics',
    quantumConnectionError:
      'Unable to connect to Quantum, please try again in a few minute.',
    queryQuantumError:
      'Unable to query Quantum for metrics, please try again in a few minutes.',
  },
  techSettings: {
    productMinVersionRequired: 'Please enter a Min version',
    productMinVersionFormatError: 'Min Version must match x.x.x format',
    productMaxVersionRequired: 'Please enter a Max version',
    productMaxVersionFormatError: 'Max Version must match x.x.x format',
    updateExperimentTechSettingsError:
      'We were unable to update the tech settings on your experiment.  Please try again in a few minutes.',
    noAvailableSettings:
      'No specific customer filters are available for this session type;\nplease use the additional advanced filters below to refine the devices to be included.',
  },
  activation: {
    noneFound:
      'There are no activation events mapped for the selected product and feature',
    noneFoundPreAuth:
      'No pre-auth Activation Events available for selected product.',
    noneFoundPostAuth:
      'No post-auth Activation Events available for selected product.',
    tooltip: 'Please enter your activation event',
    updateActivationError:
      'We were unable to update the activation event on your experiment.  Please try again in a few minutes.',
    quantumConnectionError:
      'Unable to connect to Quantum, please try again in a few minutes, if this continues please contact Distillery Support',
    queryQuantumError:
      'Unable to query Quantum for activation events, please try again in a few minutes, if this continues please contact Distillery Support',
    changeConfirmMessage:
      'Are you sure you want to change the activation event?',
    changeConfirmButton: 'Change Activation Event',
    noneSelected:
      'No activation event was selected. Please select an activation event.',
    activationDescription:
      "Activation events tell Quantum to begin monitoring user behavior and collecting data for metrics. Select the one that most closely aligns to the metrics you're measuring, and the area of the application under experiment.",
  },
  cmsVariants: {
    controlColumnTitle: 'Set up your Control (default content): ',
    variantColumnTitle: 'Set up at least one Variant (experimental content): ',
    contentAreaTitle: "Choose the Content Area you're experimenting on:",
    contentTitle: 'Then, choose Content to base your experiment on:',
    contentAreaSearchPlaceholder: 'Type or scroll to find your Content Area...',
    contentSearchPlaceholder:
      'Search name or ID, or scroll to find your Content...',
    contentVariantTitle: 'Choose content for experimentation: ',
    contentVariantSearchPlaceholder: 'Type or scroll to find Content...',
    dreConnectionError:
      'Unable to connect to DRE, please try again in a few minutes, if this continues please contact Distillery Support.',
    errorEmailSubject:
      'mailto:DL-Distillery-Support@charter.com?subject=Distillery Support for DRE Connection Error',
  },
  audience: {
    variantSummaryHeader: 'Verify your target population for experiment: ',
    authOrUnathRuleHeader: 'Set experiment-level auth/unauth: ',
    authSelector: 'Authenticated (Target users who are logged in)',
    unauthSelector:
      'All users (users who are logged in and users who are not logged in)',
  },
}
