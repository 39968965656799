import { gql } from '@apollo/client'

const UPDATE_EXPERIMENT_CONFIG = gql`
  mutation updateExperimentConfig($input: UpdateExperimentConfigEntry!) {
    updateExperimentConfig(input: $input) {
      id
      config
    }
  }
`

export default UPDATE_EXPERIMENT_CONFIG
