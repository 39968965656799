import { gql } from '@apollo/client'

export const UPDATE_NETWORK_LOCKDOWN = gql`
  mutation updateNetworkLockdown(
    $id: Int!
    $input: NetworkLockdownUpsertInput!
  ) {
    updateNetworkLockdown(id: $id, input: $input) {
      id
      startDate
      endDate
      isImmediate
      preMessage
      duringMessage
    }
  }
`
