import React from 'react'
import classNames from 'classnames'
import { number, string, oneOfType } from 'prop-types'
import './CircleLoader.scss'

const CircleLoader = ({ size, type }) => {
  const wrapperStyles = {
    height: size,
    width: size,
  }

  const loaderClassNames = classNames({
    'circle-path': true,
    [type]: type,
  })

  return (
    <div className="circle-loader__wrapper" style={wrapperStyles}>
      <div className="circle-loader__container">
        <svg className="circle-loader" viewBox=" 25 25 50 50">
          <circle
            className={loaderClassNames}
            cx="50"
            cy="50"
            r="20"
            fill="none"
            strokeWidth="3"
            strokeMiterlimit="10"
          />
        </svg>
      </div>
    </div>
  )
}

export default CircleLoader

CircleLoader.propTypes = {
  size: oneOfType([number, string]),
  type: string,
}

CircleLoader.defaultProps = {
  size: '100',
  type: 'primary',
}
