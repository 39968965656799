import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

export const GET_EXPERIMENT_OVERRIDES = gql`
  query getExperimentOverrides($id: Int!) {
    experiment(id: $id) {
      id
      name
      isCmsExperiment
      variants {
        id
        name
        variantToOverrides {
          id
          overrides {
            id
            name
            overrideIdentifier
            overrideType {
              id
              name
            }
            products {
              id
            }
          }
        }
        variantToGroupOverrides {
          id
          overrideGroups {
            id
            name
            overrides {
              id
            }
          }
        }
      }
      environmentSamplings {
        id
        sampleAllocation
        tdcsUpdateRequired
        isDreSynced
        experimentId
        environment {
          id
          name
          order
          color
        }
        experimentStatus {
          id
          name
        }
      }
      permissions {
        id
        name
      }
    }
  }
`

const getExperimentOverrides = graphql(GET_EXPERIMENT_OVERRIDES, {
  name: 'experimentOverridesData',
  options: {
    fetchPolicy: 'cache-and-network',
  },
})

export default getExperimentOverrides
