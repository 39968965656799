/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

export default class GqlBuilder {
  constructor() {
    this.stringTags = ''
    this.inputVariables = {}
    this.variables = {}
    this.options = () => {}
  }

  createGQLValues = gqlObjects => {
    gqlObjects.forEach(gqlObj => {
      const {
        query: gqlString,
        variables: inputVariables,
        gqlVars,
        options,
      } = gqlObj

      // Add a gql string
      this.stringTags += gqlString
      // Add input variables
      this.inputVariables = {
        ...this.inputVariables,
        ...inputVariables,
      }
      // Add variables
      this.variables = {
        ...this.variables,
        ...gqlVars,
      }
      // Add variables
      if (options) {
        this.options = options
      }
    })
  }

  compileQuery = gqlObjects => {
    this.createGQLValues(gqlObjects)
    const vars = this.variables
    const varInputs = Object.keys(this.inputVariables).map(
      key => `${key}: ${this.inputVariables[key]}`
    )
    const inputs = varInputs.join(',')
    const gqlTag = gql`
      query getData(${inputs}) {
        ${this.stringTags}
      }
    `
    const graphQLQuery = {
      name: 'queryData',
      query: gqlTag,
      variables: vars,
    }

    return graphQLQuery
  }

  compileMutations = () => {
    const varInputs = Object.keys(this.inputVariables).map(
      key => `${key}: ${this.inputVariables[key]}`
    )
    const inputs = varInputs.join(',')
    const vars = this.variables
    const gqlTag = gql`
      mutation mutateData(${inputs}) {
        ${this.stringTags}
      }
    `
    return {
      name: 'queryData',
      muation: gqlTag,
      variables: vars,
    }
  }

  compileHOCQuery = (gqlObjects, opts = {}) => {
    this.createGQLValues(gqlObjects)
    const varInputs = Object.keys(this.inputVariables).map(
      key => `${key}: ${this.inputVariables[key]}`
    )
    const inputs = varInputs.join(',')
    const gqlTag = gql`
      query getData${varInputs.length ? `(${inputs})` : ''} {
        ${this.stringTags}
      }
    `
    return graphql(gqlTag, {
      name: 'queryData',
      options: this.options ? this.options : opts,
      withRef: true,
      notifyOnNetworkStatusChange: true,
    })
  }
}
