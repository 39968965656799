import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

/*
* Sample Input
*
* {
  productId: Int!
  overrideTypeId: OverrideTypeEnum! (DEVICE || ACCOUNT)
}
*/

export const getOverridesByProductAndType = {
  query: `overridesByProductAndType(input: $input) {
    id
    name
    overrideIdentifier
    overrideType {
      id
      name
    }
    products {
      id
    }
    overrideGroups {
      id
    }
  }`,
  variables: {
    $input: 'TypeAndProduct!',
  },
}

export const GET_OVERRIDES_BY_PRODUCT_AND_TYPE = gql`
  query getOverridesByProductsAndType($input: TypeAndProduct!) {
    overridesByProductAndType(input: $input) {
      id
      name
      overrideIdentifier
      overrideType {
        id
        name
      }
      products {
        id
      }
      overrideGroups {
        id
      }
    }
  }
`

export const GET_ALL_OVERRIDES = gql`
  query getAllOverrides {
    overrides {
      id
      name
      overrideIdentifier
      overrideType {
        id
        name
      }
      products {
        id
      }
      overrideGroups {
        id
      }
    }
  }
`

const getOverridesByProductAndType2 = graphql(
  GET_OVERRIDES_BY_PRODUCT_AND_TYPE,
  {
    name: 'overridesByProductAndTypeData',
    options: ({ productId, overrideTypeId }) => {
      const input = { productId, overrideTypeId }

      return {
        variables: {
          input,
        },
        fetchPolicy: 'cache-and-network',
      }
    },
  }
)

export const getAllOverrides = graphql(GET_ALL_OVERRIDES, {
  name: 'overridesByProductAndTypeData',
  options: () => {
    return {
      fetchPolicy: 'cache-and-network',
    }
  },
})

export default getOverridesByProductAndType2
