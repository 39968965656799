import React, { Component, Fragment } from 'react'
import { object, bool, func } from 'prop-types'
import flowRight from 'lodash/flowRight'
import dayjs from 'dayjs'
import classNames from 'classnames'
import queryString from 'query-string'
import { KiteButton } from '@kite/react-kite'
import { BasicPills } from '@kite/react-kite-plus'

import { client } from '../../configuration/configApiClient'
import { LOG_ERROR } from '../../shared/mutations'
import {
  formatPillValues,
  formatLoggingError,
  removeFilterOption,
  scrollToNavigationPosition,
} from '../../shared/utilities'
import updateNotificationsAsRead from './mutations/updateNotificationsAsRead'
import { FilterControls, ExpansionPanel } from '../../componentLibrary'
import copyContent from './data/copyContent'
import './Notifications.scss'

const initialFilters = {
  products: [],
  environments: [],
  experiments: [],
}

export class Notifications extends Component {
  static propTypes = {
    user: object.isRequired,
    hasInAppPreferences: bool.isRequired,
    history: object.isRequired,
    updateNotificationsAsRead: func.isRequired,
    location: object.isRequired,
    onNavigate: func.isRequired,
    currentlyViewing: object.isRequired,
  }

  state = {
    selectedFilters: initialFilters,
    selectedNotification: null,
    errorMessage: {
      markRead: null,
    },
  }

  // LIFECYCLE METHODS
  componentDidMount() {
    const {
      location: { pathname, search },
      onNavigate,
      currentlyViewing,
      user: { inAppNotifications },
    } = this.props

    if (search) {
      const values = queryString.parse(search)

      if (values.experiment) {
        this.setState(({ selectedFilters }) => ({
          selectedFilters: {
            ...selectedFilters,
            experiments: [values.experiment],
          },
        }))

        let path = `${pathname}${search}`
        if (path.includes('//')) {
          path = path.replace('//', '/')
        }

        onNavigate({
          ...currentlyViewing,
          path,
          title: `Notifications for "${values.experiment}"`,
        })
      }

      if (values.selectedNotification) {
        const { id: notificationId, isRead } = inAppNotifications.find(
          notification =>
            notification.id === Number(values.selectedNotification)
        )

        this.setState({
          selectedNotification: notificationId,
        })

        setTimeout(
          () =>
            scrollToNavigationPosition(
              0,
              this[`notification__${notificationId}`].offsetTop - 100
            ),

          1000
        )

        if (!isRead) {
          this.handleMarkNotificationAsRead([notificationId])
        }
      }
    } else {
      onNavigate(
        {
          path: '/notifications',
          title: 'Notifications',
        },
        true
      )
    }
  }

  // LOCAL STATE CHANGES/TOGGLES
  handleUpdateFilters = pendingFilters => {
    const selectedFilters = this.handleFormatPendingFilters(pendingFilters)

    this.setState({ selectedFilters })
    return true
  }

  handleRemoveFilter = filter => {
    const { selectedFilters } = this.state
    const {
      history,
      location: { search },
      onNavigate,
      currentlyViewing,
    } = this.props

    const newFilters = removeFilterOption(filter, selectedFilters)

    this.setState({ selectedFilters: newFilters })

    if (search) {
      const values = queryString.parse(search)
      const filterArray = filter.split('__')

      if (values.experiment && values.experiment === filterArray[1]) {
        const path = '/notifications'

        history.replace(path)
        onNavigate({
          ...currentlyViewing,
          path,
          title: 'Notifications',
        })
      }
    }
  }

  handleClearAllFilters = () => {
    this.setState({ selectedFilters: initialFilters })
  }

  handleSelectNotification = (notificationId, isRead) => {
    this.setState(({ selectedNotification }) => ({
      selectedNotification:
        selectedNotification === notificationId ? null : notificationId,
    }))

    if (!isRead) {
      this.handleMarkNotificationAsRead([notificationId])
    }
  }

  handleKeyDownNotification = (event, notificationId, isRead) => {
    if (event.key === 'Enter') {
      this.handleSelectNotification(notificationId, isRead)
    }
  }

  // FORMATTING METHODS
  handleFormatPendingFilters = pendingFilters => {
    const hasExperimentSelected = pendingFilters.experiments.length > 0
    const hasProductsSelected =
      pendingFilters.products &&
      pendingFilters.products.find(group => group.selectedFilters.length !== 0)

    const emptyProductFilters = {
      ...pendingFilters,
      products: [],
    }

    if (hasProductsSelected && !hasExperimentSelected) {
      return pendingFilters
    }

    if (hasExperimentSelected) {
      return emptyProductFilters
    }

    return emptyProductFilters
  }

  handleFormatFilterGroups = () => {
    const { selectedFilters } = this.state
    const {
      user: { inAppNotifications },
    } = this.props

    const experiments = this.handleFormatExperimentFilters()
    const environments = inAppNotifications.reduce(
      (accumulator, { environment }) => {
        const environmentName = environment.name.toLowerCase()

        if (!accumulator.includes(environmentName)) {
          return [...accumulator, environmentName]
        }

        return accumulator
      },
      []
    )

    let filters = {
      environments,
      experiments,
    }

    if (selectedFilters.experiments.length === 0) {
      const products = this.handleFormatProductFilters()

      filters = {
        products,
        ...filters,
      }
    }

    return filters
  }

  handleFormatExperimentFilters = () => {
    const { selectedFilters } = this.state
    const {
      user: { inAppNotifications },
    } = this.props

    const allExperiments = inAppNotifications.reduce(
      (accumulator, { experiment }) => {
        const experimentName = experiment.name

        if (!accumulator.includes(experimentName)) {
          return [...accumulator, experimentName]
        }

        return accumulator
      },
      []
    )

    const experiments =
      selectedFilters.products && selectedFilters.products.length === 0
        ? allExperiments
        : allExperiments.filter(name => {
            const { experiment } = inAppNotifications.find(
              notification => notification.experiment.name === name
            )

            const { product } = experiment.experimentToProductFeatures[0]

            const type = product.productCategoryType.name.toLowerCase()
            const productTypeFilters = selectedFilters.products.find(
              productType => productType.name === type
            )

            const productIsSelected =
              productTypeFilters &&
              productTypeFilters.selectedFilters.find(
                filter =>
                  filter.toLowerCase() === product.displayName.toLowerCase()
              )

            if (productIsSelected) {
              return true
            }

            return false
          })

    return experiments
  }

  handleFormatProductFilters = () => {
    const {
      user: { inAppNotifications },
    } = this.props

    const products = inAppNotifications.reduce(
      (accumulator, { experiment: { experimentToProductFeatures } }) => {
        if (
          !experimentToProductFeatures.length ||
          experimentToProductFeatures[0] === undefined
        ) {
          const input = formatLoggingError(
            'Notifications bug, experimentToProductFeatures has an undefined object'
          )
          client.mutate({
            mutation: LOG_ERROR,
            variables: {
              input,
            },
          })

          return accumulator
        }

        const { product } = experimentToProductFeatures[0]
        const type = product.productCategoryType.name.toLowerCase()

        if (!accumulator[type]) {
          accumulator[type] = []
        }

        if (!accumulator[type].includes(product.displayName)) {
          accumulator[type].push(product.displayName)
        }

        return accumulator
      },
      {}
    )

    return products
  }

  handleFormatRenderedNotifications = () => {
    const {
      user: { inAppNotifications },
    } = this.props
    const {
      selectedFilters: { products, experiments, environments },
    } = this.state
    let filteredNotifications = inAppNotifications

    if (products.length > 0) {
      filteredNotifications = filteredNotifications.filter(notification => {
        const productMatch = products.find(type => {
          const typeMatch = type.selectedFilters.find(
            product =>
              product.toLowerCase() ===
              notification.experiment.experimentToProductFeatures[0].product.displayName.toLowerCase()
          )

          return typeMatch
        })

        if (productMatch) {
          return notification
        }

        return false
      })
    }

    if (experiments.length > 0) {
      filteredNotifications = filteredNotifications.filter(notification => {
        const experimentMatch = experiments.find(
          experiment =>
            experiment.toLowerCase() ===
            notification.experiment.name.toLowerCase()
        )

        if (experimentMatch) {
          return notification
        }

        return false
      })
    }

    if (environments.length > 0) {
      filteredNotifications = filteredNotifications.filter(notification => {
        const environmentMatch = environments.find(
          environment =>
            environment.toLowerCase() ===
            notification.environment.name.toLowerCase()
        )

        if (environmentMatch) {
          return notification
        }

        return false
      })
    }

    return filteredNotifications
  }

  // API Methods
  handleMarkAllRead = () => {
    const {
      user: { inAppNotifications },
    } = this.props
    const inAppNotificationIds = inAppNotifications.map(
      notification => notification.id
    )

    this.handleMarkNotificationAsRead(inAppNotificationIds)
  }

  handleMarkNotificationAsRead = async inAppNotificationIds => {
    const { errorMessage } = this.state
    const {
      user: { id: userId },
      updateNotificationsAsRead: updateNotification,
    } = this.props

    if (errorMessage.markRead) {
      await this.setState({
        errorMessage: { ...errorMessage, markRead: null },
      })
    }

    try {
      const input = {
        userId,
        inAppNotificationIds,
      }

      await updateNotification({
        variables: {
          input,
        },
      })

      if (window.dxLoadCurrentUser) {
        await window.dxLoadCurrentUser()
      }
    } catch (error) {
      const input = formatLoggingError(error)

      await client.mutate({
        mutation: LOG_ERROR,
        variables: {
          input,
        },
      })

      this.setState({
        errorMessage: {
          ...errorMessage,
          markRead: copyContent.notifications.errorMarkRead,
        },
      })
    }
  }

  // RENDER METHODS
  renderNotificationControls = () => {
    const { selectedFilters, errorMessage } = this.state
    const {
      user: { inAppNotifications },
    } = this.props

    const filterOptions = this.handleFormatFilterGroups()
    const pillValues = formatPillValues(selectedFilters)

    const hasUnreadNotifications = inAppNotifications.find(
      notification => !notification.isRead
    )

    return (
      <Fragment>
        <FilterControls
          className="notifications__filter-controls"
          filterCategory="Notifications"
          filterOptions={filterOptions}
          selectedFilters={selectedFilters}
          onChange={this.handleUpdateFilters}
          hideApplyButton
        />

        <BasicPills
          values={pillValues}
          onClearAll={this.handleClearAllFilters}
          onRemovePill={this.handleRemoveFilter}
        />

        <KiteButton
          className="notifications__mark-all-read-button"
          type="standalone-link"
          size="small"
          onClick={this.handleMarkAllRead}
          disabled={!hasUnreadNotifications}
        >
          {copyContent.notifications.markAllRead}
        </KiteButton>

        <ExpansionPanel
          className="notifications__error"
          isExpanded={errorMessage.markRead && true}
          type="minimal"
        >
          {errorMessage.markRead}
        </ExpansionPanel>
      </Fragment>
    )
  }

  renderNotifications = () => {
    const { selectedNotification } = this.state
    const {
      user: { inAppNotifications },
      hasInAppPreferences,
      history,
    } = this.props

    if (inAppNotifications.length > 0) {
      const renderedNotifications = this.handleFormatRenderedNotifications()

      if (renderedNotifications.length === 0) {
        return (
          <div className="notifications__empty-container">
            <h4 className="notifications__empty-message">
              {copyContent.notifications.noFilteredNotifications}
            </h4>
            <p className="notifications__empty-sub-message">
              {copyContent.notifications.noFilteredNotificationsSubMessage}
            </p>
          </div>
        )
      }

      return (
        <div className="notifications__container">
          {renderedNotifications.map(
            ({
              id,
              isRead,
              subject,
              body,
              environment,
              experiment,
              experiment: { environmentSamplings },
              notificationTime,
              notificationDefinition: { id: notificationDefId },
            }) => {
              if (experiment.experimentToProductFeatures[0] === undefined) {
                return <div />
              }
              const environmentData = environmentSamplings.filter(
                ({ environmentId }) => environmentId === environment.id
              )
              let comment
              if (
                environmentData.length > 0 &&
                environmentData[0].allocationHistory.length > 0 &&
                notificationDefId === 5
              ) {
                const allocationElement = environmentData[0].allocationHistory.find(
                  historyElement => {
                    const diffInTime = dayjs(notificationTime).diff(
                      dayjs(historyElement.createdTime),
                      'seconds'
                    )
                    if (diffInTime === 1) {
                      return historyElement
                    }
                    return null
                  }
                )
                comment = allocationElement ? allocationElement.comment : ''
              }
              const { product } = experiment.experimentToProductFeatures[0]
              const notificationClassNames = classNames({
                notifications__notification: true,
                'notifications__notification-unread': !isRead,
                'notifications__notification-selected':
                  selectedNotification === id,
              })
              const indicatorClassNames = classNames({
                'notifications__read-indicator': true,
                'notifications__read-indicator-read': isRead,
              })

              return (
                <Fragment key={id}>
                  <div
                    role="option"
                    tabIndex="0"
                    aria-selected={selectedNotification === id}
                    className={notificationClassNames}
                    key={`notification-${id}`}
                    ref={element => {
                      this[`notification__${id}`] = element
                    }}
                    onClick={() => this.handleSelectNotification(id, isRead)}
                    onKeyDown={event =>
                      this.handleKeyDownNotification(event, id, isRead)
                    }
                  >
                    <div className={indicatorClassNames} />

                    <div
                      className={`notifications__notification-left ${
                        !isRead ? 'notifications__notification-left-unread' : ''
                      }`}
                    >
                      <span className="notifications__notification-subject">
                        {subject}
                      </span>
                      {comment && comment.length > 0 ? (
                        <span className="notification__notification-sample-comment">
                          {comment}
                        </span>
                      ) : (
                        ''
                      )}
                    </div>

                    <div className="notifications__notification-right">
                      <span className="notifications__notification-details">
                        {`${
                          product.displayName
                        }\xa0\xa0|\xa0\xa0${environment.name.toLowerCase()}\xa0\xa0|\xa0\xa0${
                          experiment.name
                        }`}
                      </span>

                      <span className="notifications__notification-date">
                        {`${
                          dayjs().isSame(notificationTime, 'day')
                            ? 'Today'
                            : dayjs(notificationTime).format('MM/DD/YYYY')
                        } ${dayjs(notificationTime).format('h:mma')}`}
                      </span>
                    </div>
                  </div>

                  <ExpansionPanel isExpanded={id === selectedNotification}>
                    <div className="notifications__message-wrapper">
                      <iframe
                        style={{
                          minHeight: '55rem',
                          width: '50rem',
                          border: 'none',
                        }}
                        title={`Notification ${subject} Body`}
                        srcDoc={body}
                      />
                    </div>
                  </ExpansionPanel>
                </Fragment>
              )
            }
          )}
        </div>
      )
    }

    return (
      <div className="notifications__empty-container">
        <h4 className="notifications__empty-message">
          {hasInAppPreferences
            ? copyContent.notifications.noNotifications
            : copyContent.notifications.noNotificationPreferences}
        </h4>
        {hasInAppPreferences && (
          <p className="notifications__empty-sub-message">
            {copyContent.notifications.noNotificationsSubMessage}
          </p>
        )}

        {!hasInAppPreferences && (
          <KiteButton
            className="notifications__preferences-link"
            type="quick-link"
            onClick={() => history.push('/notifications/preferences')}
          >
            {copyContent.notifications.notificationPreferencesLink}
          </KiteButton>
        )}
      </div>
    )
  }

  render() {
    const {
      user: { inAppNotifications },
    } = this.props

    return (
      <div className="notifications">
        {inAppNotifications.length > 0 && this.renderNotificationControls()}
        {this.renderNotifications()}
      </div>
    )
  }
}

export default flowRight(updateNotificationsAsRead)(Notifications)
