import { observer } from 'mobx-react'
import React from 'react'
import { KiteIcon } from '@kite/react-kite'
import classnames from 'classnames'

import './PageLinks.scss'

const PageLinks = observer(function PageLinks({ model, className }) {
  const { numOfPages, currentPage } = model

  // do not show page links if only one page
  if (numOfPages <= 1) return <div className="page-links"></div>

  // Not exact, we show up to 9
  // but calculate off this num
  const numOfLinks = model.numOfLinks

  // below we will need these functions
  // Push a link
  const pushLink = index => {
    children.push(
      <div
        key={`child${index}`}
        onClick={() => {
          model.goToClickedPage
            ? model.goToClickedPage(index)
            : (model.currentPage = index)
        }}
        className={classnames({
          'page-link-item': true,
          'page-link': true,
          'page-link-selected': index === currentPage,
        })}
      >
        {index}
      </div>
    )
  }

  // we have to add the last 3 links in multiple places
  const pushLastTwo = () => {
    pushLink(numOfPages - 1)
    pushLink(numOfPages)
  }

  // ... are shown when there are lots of pages
  // we show at most 2
  // index is just for React keys
  const pushEllipses = index => {
    children.push(
      <div key={`ellipses${index}`} className="page-link-item">
        {'. . .'}
      </div>
    )
  }

  // Start building our page string of links
  // Just a flex box from left to right
  const children = []

  // chevron right to go back a page
  if (model.hasLastPage) {
    children.push(
      <KiteIcon
        key="leftIcon"
        className={classnames({
          'page-link': model.hasLastPage,
          'page-link-item': true,
        })}
        name="chevron-left"
        onClick={model.hasLastPage ? model.gotoLastPage : null}
      ></KiteIcon>
    )
  }

  if (numOfPages < numOfLinks) {
    // Just print all the page links
    for (let i = 1; i <= numOfPages; i++) {
      pushLink(i)
    }
  } else {
    // always show first 2 page links
    for (let i = 1; i <= 2; i++) {
      pushLink(i)
    }
    if (currentPage <= 2 || currentPage > numOfPages - 2) {
      // if user is on first or last few links,
      // show one ellipsis between first and last 3 links
      pushEllipses(1)
      pushLastTwo()
    } else if (currentPage < numOfLinks) {
      // links 4-5 can be flattened
      for (let i = 3; i < numOfLinks; i++) {
        pushLink(i)
      }
      pushEllipses(1)
      pushLastTwo()
    } else if (
      currentPage >= numOfLinks &&
      currentPage <= numOfPages - numOfLinks
    ) {
      // if the user is in middle pages
      // show middle links with ellipses around them
      pushEllipses(1)
      pushLink(currentPage - 1)
      pushLink(currentPage)
      pushEllipses(2)
      pushLastTwo()
    } else if (
      currentPage >= numOfPages - numOfLinks &&
      currentPage <= numOfPages - 2
    ) {
      // More flattening when near the end, but before last 3
      pushEllipses(1)
      for (let i = currentPage; i <= numOfPages; i++) {
        pushLink(i)
      }
    }
  }

  // chevron right to advance forward a page
  if (model.hasNextPage) {
    children.push(
      <KiteIcon
        key="rightIcon"
        className={classnames({
          'page-link': model.hasNextPage,
          'page-link-item': true,
        })}
        name="chevron-right"
        onClick={model.hasNextPage ? model.gotoNextPage : null}
      ></KiteIcon>
    )
  }

  return <div className={`page-links ${className}`}>{children}</div>
})

export default PageLinks
