import React from 'react'
import PropTypes from 'prop-types'
// TODO: Update to use RK
import { Button } from '../../../componentLibrary'
import './SubscriptionDetails.scss'

const SubscriptionDetails = ({ subscription, topicName, onUnsubscribe }) => {
  const { subjectArn, endpoint } = subscription
  const isActive = subjectArn !== 'PendingConfirmation'

  return (
    <div className="subscription-details__container">
      <div className="detail__wrapper">
        <span className="label">Topic</span>
        <h4 className="sub-title">{topicName}</h4>
      </div>

      <div className="detail__wrapper">
        <span className="label">Endpoint</span>
        <h4 className="sub-title">{endpoint}</h4>
      </div>

      <div className="detail__wrapper">
        <span className="label">Status</span>
        <h4 className="sub-title">{isActive ? 'Active' : 'Pending'}</h4>
      </div>

      {isActive && (
        <Button
          value="Unsubscribe"
          type="warning-outline"
          onClick={() => onUnsubscribe(subscription)}
          setRight
        />
      )}
    </div>
  )
}

export default SubscriptionDetails

SubscriptionDetails.propTypes = {
  subscription: PropTypes.object.isRequired,
  onUnsubscribe: PropTypes.func.isRequired,
  topicName: PropTypes.string,
}

SubscriptionDetails.defaultProps = {
  topicName: null,
}
