const formatSetUpSteps = (config, steps) => {
  if(config.planPrepare) {
    return { activeStep: '', setUpSteps: [], isSetUpComplete: false }
  }
  const setUpSteps = steps.map(step => {
    const { label } = step
    const match = config.setUpSteps[label]

    return {
      ...step,
      status: match.status,
    }
  })

  const sortedSteps = Object.values(config.setUpSteps)
    .sort((aStep, bStep) => (aStep.order > bStep.order ? 1 : -1))
    .map(step => {
      const match = Object.keys(config.setUpSteps).find(
        key => config.setUpSteps[key].order === step.order
      )

      return {
        ...step,
        label: match,
      }
    })

  const hasInitialStep = sortedSteps.find(step => step.status === 'initial')
  const hasIncompleteStep = sortedSteps.find(
    step => step.status === 'incomplete'
  )

  let activeStep = 'plan'

  if (hasInitialStep) {
    activeStep = hasInitialStep.label
  } else if (hasIncompleteStep) {
    activeStep = hasIncompleteStep.label
  }

  const isSetUpComplete = !hasInitialStep && !hasIncompleteStep

  return { activeStep, setUpSteps, isSetUpComplete }
}

export default formatSetUpSteps
