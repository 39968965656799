import { gql } from '@apollo/client'

export const GET_ALL_CONTENT_MAPS = gql`
  query getAllContentMaps {
    contentMaps {
      id
      name
      productId
      product {
        id
        displayName
      }
      featureId
      feature {
        id
        displayName
      }
      statusId
      status {
        id
        name
      }
      config
      contentAreaId
      startTime
      stopTime
      comment
      jiraTicket
      publishedError
      isApprovalReady
      isExperimenting
    }
  }
`
